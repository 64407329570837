import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import SELECT from './select';

function FORM_SELECT({ property, label, value, onChange, onOpen, onClose, placeholder, options, disabled, use_popup, errors, description }) {


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER ==========================================================================================================
    return (
        <div className='detailsgroup'>
            <div className='detailsgroup__label text--sm-medium'>{label}</div>
            <Form.Field className='detailsgroup__value'>
                {description && <div className='detailsgroup__description text--sm-regular'>{description}</div>}
                <SELECT
                    property={property}
                    value={value}
                    onChange={onChange}
                    onOpen={onOpen}
                    onClose={onClose}
                    placeholder={placeholder}
                    options={options}
                    disabled={disabled}
                    use_popup={use_popup}
                    errors={errors}
                />
            </Form.Field>
        </div>
    );
}


FORM_SELECT.propTypes = {
    property: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    onOpen: propTypes.func,
    onClose: propTypes.func,
    placeholder: propTypes.string,
    options: propTypes.array
};

export default FORM_SELECT;
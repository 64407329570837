import React from 'react';
import { useTranslation } from 'react-i18next';
import CMP_TRANSCRIPT from 'components/cmp_transcript/cmp_transcript';
import 'i18n';



export default function CRD_DETAILS({ transcript }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');


    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_transcript_details_details' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text' style={{ maxWidth: '50rem' }}>
                    <div className='text--xl-medium' id='hdr_transcript_details_details'>{t('Transcript preview')}</div>
                    <div className='text--sm-regular'>{t('This is a live preview of your transcript. Your credentials will automatically appear here once they\'ve been issued to you.')}</div>
                    <span style={{ marginTop: '1rem' }}>
                        <div style={{ display: 'inline' }} className='text--sm-bold'>{t('Note')}: </div>
                        <div style={{ display: 'inline' }} className='text--sm-regular'>{t('Only verified credentials will be displayed on your transcript. Please ensure that any credentials with a status of \'In progress\' are completed to have them included.')}</div>
                    </span>
                </div>
            </div>
            <CMP_TRANSCRIPT display_type='inline' transcript={transcript} ready={!!transcript} />

        </div>
    );
}
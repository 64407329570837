import React, { useState } from 'react';
import { Form, Button, Message } from 'semantic-ui-react'
import { FORM_INPUT, INPUT, SELECT } from 'components/cmp_form/cmp_form';
import form_helper from 'libs/form-lib';
import API from 'libs/api-lib';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import { useTranslation } from 'react-i18next';
import 'i18n';

import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';

import './tab_info.css';

export default function TAB_INFO({ individual, onChange, isLoading }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_updated_individual, set_updated_individual ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_processing, set_processing ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------

    async function update_individual(updated_individual) {
        set_processing(true);
        try {
            await API_put_indv_details(updated_individual);
            onChange && onChange();
            set_mode('VIEW');
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_indv_details(updated_individual) {
        return API.put('indv', '/put-indv-details/' + individual.id, {
            body: {
                firstname: updated_individual.firstname,
                lastname: updated_individual.lastname,
                job_title: updated_individual.job_title,
                phone: updated_individual.phone,
                city: updated_individual.city,
                country: updated_individual.country,
                province: updated_individual.province,
                custom_field_data: updated_individual.custom_field_data
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_save_error(null);
        set_updated_individual({ ...individual, custom_field_data: individual.custom_field_data ? { ...individual.custom_field_data } : null });
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_save_error(null);
        set_errors([]);
        set_mode('VIEW');
    }

    function onChange_input(event) {
        set_save_error(null);
        let updated_individual = { ...var_updated_individual, custom_field_data: var_updated_individual.custom_field_data ? { ...var_updated_individual.custom_field_data } : null };
        updated_individual[event.target.name] = event.target.value;
        set_updated_individual(updated_individual);
        if (var_errors.length > 0) {
            let errors = var_errors.filter(error => error.property !== event.target.name)
            set_errors(errors);
        }
    }

    function onChange_custom_field(event, { name, value }) {
        set_save_error(null);
        let updated_individual = { ...var_updated_individual, custom_field_data: { ...var_updated_individual.custom_field_data } };
        updated_individual.custom_field_data[name] = value;
        set_updated_individual(updated_individual);
        set_errors(var_errors.filter(error => error.property !== name));
    }

    function onClick_save() {
        if (var_processing) return;
        set_save_error(null);
        let updated_individual = { ...var_updated_individual, custom_field_data: var_updated_individual.custom_field_data ? { ...var_updated_individual.custom_field_data } : null };
        let errors = [];
        //validation
        if (!form_helper.validate_required_string(updated_individual.firstname)) {
            errors.push({ property: 'firstname', description: t('Please add a first name.') });
        }
        if (!form_helper.validate_required_string(updated_individual.lastname)) {
            errors.push({ property: 'lastname', description: t('Please add a last name.') });
        }
        if (individual.custom_fields) {
            individual.custom_fields.filter(item => item.required).forEach(item => {
                if (!form_helper.validate_required_string(updated_individual.custom_field_data[item.property])) {
                    errors.push({ property: item.property, description: t('Please enter a value') });
                }
            });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(updated_individual, 'firstname');
        form_helper.cleanse_string_property(updated_individual, 'lastname');
        if (individual.custom_fields) {
            individual.custom_fields.forEach(item => form_helper.cleanse_string_property(updated_individual.custom_field_data, item.property));
        }

        set_updated_individual(updated_individual);
        update_individual(updated_individual);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_workerprofile_info'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_workerprofile_info'>{t('Information')}</div>
                </div>
                {var_mode === 'VIEW' && individual?.can_edit &&
                    <div className='card__header__right card__header__btns'>
                        <Button className='secondary' onClick={onClick_edit} disabled={individual?.invitestatus !== 'Active' || individual?.ssoenabled === 'YES'}>{t('Edit')}</Button>
                    </div>
                }
            </div>
            {var_mode === 'VIEW' ?
                (individual.id
                ? <>
                    <div className='card__content'>
                        {!individual.issuer_only && render_view_item('invitestatus', t('Status'))}
                        {render_view_item('name', t('Name'))}
                        {!individual.issuer_only && render_view_item('job_title', t('Title'))}
                        {!individual.issuer_only && render_view_item('phone', t('Phone'))}
                        {render_view_item('email', t('Email'))}
                        {!individual.issuer_only && render_view_item('location', t('Location'))}
                        {individual?.custom_fields &&
                            individual.custom_fields.map(item => render_custom_view_item(item))
                        }
                    </div>
                    {var_mode === 'VIEW' && individual?.can_edit &&
                        <div className='card__footer--mobilebtns'>
                            <Button className='secondary' onClick={onClick_edit} disabled={individual?.invitestatus !== 'Active' || individual?.ssoenabled === 'YES'}>{t('Edit')}</Button>
                        </div>
                    }
                </>
                : !isLoading && <Message
                    error
                    icon={<Icon name='error' className='icon' />}
                    content={t('You do not have access to view this profile, or the profile does not exist.')}
                />)
            : var_mode === 'EDIT' ?
                <>
                <Form className='card__content' id='form_workerprofile_info' aria-labelledby='hdr_workerprofile_info'>

                    {var_save_error &&
                        <Message error header={var_save_error} />
                    }

                    {var_errors.length > 0 &&
                        <Message
                            error icon={<Icon name='error' className='icon' />}
                            header={t('There are some errors with your inputs')}
                        />
                    }

                    {render_view_item('invitestatus', t('Status'))}


                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'>{t('Name')}</div>
                        <Form.Field className='detailsgroup__value name'>

                            <div className='name_wrapper'>
                                <div className='text--sm-medium'>{t('First name')}</div>
                                <INPUT
                                    property='firstname'
                                    label={t('First name')}
                                    value={var_updated_individual.firstname}
                                    onChange={onChange_input}
                                    placeholder={t('First name')}
                                    maxLength={100}
                                    disabled={false}
                                    errors={var_errors}
                                />
                            </div>

                            <div className='name_wrapper'>
                                <div className='text--sm-medium'>{t('Last name')}</div>
                                <INPUT
                                    property='lastname'
                                    label={t('Last name')}
                                    value={var_updated_individual.lastname}
                                    onChange={onChange_input}
                                    placeholder={t('Last name')}
                                    maxLength={100}
                                    disabled={false}
                                    errors={var_errors}
                                />
                            </div>
                        </Form.Field>
                    </div>


                    <FORM_INPUT
                        property='job_title'
                        label={t('Title')}
                        value={var_updated_individual.job_title}
                        onChange={onChange_input}
                        placeholder={t('Title')}
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='phone'
                        placeholder={t('Phone')}
                        label={t('Phone')}
                        value={individual.phone}
                        disabled={true}
                    />

                    <FORM_INPUT
                        property='email'
                        label={t('Email')}
                        value={individual.email}
                        disabled={true}
                    />

                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'>{t('Location')}</div>
                        <Form.Field className='detailsgroup__value'>

                            <div className='text--sm-medium'>{t('Country')}</div>
                            <SELECT
                                property='country'
                                placeholder={t('Country')}
                                value={individual.country}
                                disabled={true}
                                options={[{ value: individual.country, text: individual.country }]}
                            />

                            <div className='text--sm-medium' style={{ marginTop: '1rem' }}>{t('Province/State')}</div>
                            <SELECT
                                property='province'
                                placeholder={t('Province/State')}
                                value={individual.province}
                                disabled={true}
                                options={[{ value: individual.province, text: individual.province }]}
                            />

                            <div className='text--sm-medium' style={{ marginTop: '1rem' }}>{t('City')}</div>
                            <INPUT
                                property='city'
                                placeholder={t('City')}
                                value={individual.city}
                                disabled={true}
                            />
                        </Form.Field>
                    </div>

                    {individual?.custom_fields &&
                        individual.custom_fields.map(item =>
                            <FORM_INPUT
                                key={`customfield-${item.property}`}
                                property={item.property}
                                label={item.translate ? t(item.label) : item.label}
                                value={var_updated_individual.custom_field_data[item.property]}
                                onChange={onChange_custom_field}
                                placeholder={(item.translate && item.placeholder) ? t(item.placeholder) : item.placeholder}
                                maxLength={100}
                                errors={var_errors}
                            />)
                    }
                </Form>

                <div className='card__footer'>
                    <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />
                </>
            :
                null
            }
        </div>
    )

    function render_view_item(property, name) {
        if (!individual) return null;
        return (
            <div className='detailsgroup'>
                <div className='detailsgroup__label text--sm-medium'>{name}</div>
                {property === 'location'
                    ?   <div className='detailsgroup__value' style={{ flexDirection: 'column' }}>
                            <div className='text--sm-regular'>{individual.city || ''}{individual.city && individual.province ? ', ' : ''}{individual.province ? individual.province : ''}</div>
                            {(individual.country && individual.country !== '') && <div className='text--sm-regular'>{individual.country}</div>}
                        </div>
                    :   property === 'email'
                    ?   <div className='detailsgroup__value text--sm-regular truncate'>
                            {individual[property] || ''} </div>
                    :   <div className='detailsgroup__value text--sm-regular'>
                            {property ? (property === 'invitestatus' && individual[property]
                                            ? <>
                                            <div className={'badge text--sm-medium  ' +
                                            (   individual[property] === 'Active' ?
                                                'badge--green'
                                                : individual[property] === 'Archive' ?
                                                'badge--gray'
                                                : individual[property] === 'Pending Invite' ?
                                                'badge--yellow'
                                                : (individual[property] === 'Expired' || individual[property] === 'Declined') ?
                                                'badge--red'
                                                : 'badge--gray')}>
                                            {individual[property] === 'Archive'
                                                ? t('Inactive')
                                                : individual[property] === 'Pending Invite'
                                                ? t('Pending')
                                                : t(individual[property])}
                                            </div>
                                            </>
                                            : property === 'name'
                                            ?
                                                <CMP_VERIFIED_IDENTITY_DISPLAY
                                                    individual_name={(individual.firstname || '') + ' ' + (individual.lastname || '')}
                                                    verified_identity_credential_id={individual.identity_verification_credential_id}
                                                />
                                            : individual[property] || ''
                                        )
                                      : individual[property] || ''}
                        </div>
                }
            </div>
        );
    }

    function render_custom_view_item(item) {
        return (
            <div className='detailsgroup' key={`customfield-${item.property}`}>
                <div className='detailsgroup__label text--sm-medium'>{item.translate ? t(item.label) : item.label}</div>
                <div className='detailsgroup__value text--sm-regular'>{individual.custom_field_data[item.property]}</div>
            </div>
        );
    }
}
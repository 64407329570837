import React, { useState } from 'react';
import { FORM_INPUT } from 'components/cmp_form/cmp_form';
import { Button, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';


export default function CONTENT_SCAN_2({ set_content, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_code, set_code ] = useState('');
    const [ var_errors, set_errors ] = useState([]);

    const [ var_code1, set_code1 ] = useState('');
    const [ var_code2, set_code2 ] = useState('');
    const [ var_code3, set_code3 ] = useState('');
    const [ var_code4, set_code4 ] = useState('');

    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        if (event.target.name === 'code1') {
            set_code1(event.target.value);
        }
        if (event.target.name === 'code2') {
            set_code2(event.target.value);
        }
        if (event.target.name === 'code3') {
            set_code3(event.target.value);
        }
        if (event.target.name === 'code4') {
            set_code4(event.target.value);
        }
    }

    function onClick_cancel() {
        onClose();
    }

    function onClick_validate() {
        set_content('SCAN_2')
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='modal__content center padding--lg'>

            <div className='content__description'>
                <div className='text--sm-regular'>{t('Enter the verification code to add this credential.')}</div>
            </div>

            <div className='code__wrapper'>
                <FORM_INPUT
                    property='code1'
                    value={var_code1 || null}
                    onChange={onChange_input}
                    placeholder=''
                    maxLength={1}
                    disabled={false}
                    errors={var_errors}
                    description=''
                />

                <FORM_INPUT
                    property='code2'
                    value={var_code2 || null}
                    onChange={onChange_input}
                    placeholder=''
                    maxLength={1}
                    disabled={false}
                    errors={var_errors}
                    description=''
                />

                <FORM_INPUT
                    property='code3'
                    value={var_code3 || null}
                    onChange={onChange_input}
                    placeholder=''
                    maxLength={1}
                    disabled={false}
                    errors={var_errors}
                    description=''
                />

                <FORM_INPUT
                    property='code4'
                    value={var_code4 || null}
                    onChange={onChange_input}
                    placeholder=''
                    maxLength={1}
                    disabled={false}
                    errors={var_errors}
                    description=''
                />
            </div>

        </div>
    );

}

import React, { useEffect } from 'react';
import CRD_PREQUALIFICATIONS from './crd_prequalifications/crd_prequalifications';



export default function TAB_PREQUALIFICATIONS({ activetab, credential, has_verifiable_credential_feature, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <CRD_PREQUALIFICATIONS activetab={activetab} credential={credential} has_verifiable_credential_feature={has_verifiable_credential_feature} onChange={onChange} />
        </>
    )
};

import React, { useEffect, useState, useCallback } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import QRCode from 'qrcode.react';
import { Button, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import Logo from 'components/cmp_logo';
import 'i18n';

import './crd_verification.css';

function CRD_VERIFICATION({ token }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_verification_request, set_verification_request ] = useState(null);
    const [ var_current_status, set_current_status ] = useState(null);
    const [ var_qrcode_expiry_seconds, set_qrcode_expiry_seconds ] = useState(null);
    const [ var_refresh_seconds, set_refresh_seconds ] = useState(null);
    const [ var_check_status, set_check_status ] = useState(true);
    const [ var_load_error, set_load_error ] = useState(false);
    const check_status_callback = useCallback(() => set_check_status(!var_check_status), [ var_check_status ]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        create_verification_request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        setTimeout(check_status_callback, 1000);
        if (!var_current_status) return;
        if (['REQUEST CREATED', 'REQUEST SENT', 'EXPIRED'].includes(var_current_status)) {
            if (var_qrcode_expiry_seconds > 0) {
                set_qrcode_expiry_seconds(var_qrcode_expiry_seconds - 1);
                if (var_qrcode_expiry_seconds === 1) {
                    set_current_status('EXPIRED');
                } else {
                    check_status();
                }
            }
        } else if (var_refresh_seconds > 0) {
            set_refresh_seconds(var_refresh_seconds - 1);
        } else {
            create_verification_request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_check_status]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function create_verification_request() {
        set_current_status(null);
        set_verification_request(null);
        set_load_error(false);
        try {
            let verification_request = await API_post_verification_request();
            set_verification_request(verification_request);
            set_current_status('REQUEST CREATED');
            set_qrcode_expiry_seconds(verification_request.expiry_time_in_seconds);
            set_refresh_seconds(verification_request.refresh_seconds);
        } catch (e) {
            set_load_error(true);
        }
    }

    async function check_status() {
        set_current_status(await API_get_verification_request_status());
    }


    //  API calls ------------------------------------------------------------------------------------------------------


    function API_post_verification_request() {
        return API.post('verifiable-credentials', `/post-verification-request/${token}`);
    }

    function API_get_verification_request_status() {
        return API.get('verifiable-credentials', `/get-verification-request-status/${var_verification_request.token}`);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_openLink() {
        window.open(var_verification_request.url);
    }


    // RENDER APP ======================================================================================================

    return (
        <div id='crd__verification' style={{ backgroundColor: var_verification_request?.background_color }}>
            {(var_verification_request && var_verification_request.logo && var_verification_request.logo !== '') &&
                <div className='verification__logo'>
                    <Logo targetid={var_verification_request.issuer_id} classification={var_verification_request.logo_classification} filename={var_verification_request.logo} />
                </div>
            }
            {var_load_error
            ?
                <Message error
                    icon={<Icon name='error' className='icon' />}
                    header={t('The page you are looking for could not be found. Please check the URL.')}
                />
            : var_verification_request
            ?
                <div className='verification__wrapper rounded-lg shadow-sm'>
                    <div className='verification__title display--sm-medium' style={{ color: var_verification_request.text_color }}>{var_verification_request.title}</div>
                    <div className='verification__description text--sm-regular' style={{ color: var_verification_request.text_color }}>{var_verification_request.description}</div>
                    {['REQUEST CREATED', 'REQUEST SENT', 'EXPIRED'].includes(var_current_status)
                        ? render_qrcode()
                        : render_result()
                    }
                </div>
            :
                <Processing display={true} processingtext={t('Processing')} />
            }
        </div>
    );

    function render_qrcode() {
        return (
            <div className='qrcode_body'>
                <div className='qrcode_wrapper' style={{position: 'relative'}}>
                    <QRCode
                        className='qrcode'
                        id='canvasQRcode'
                        value={var_verification_request.url}
                        size={200}
                        level='H'
                    />
                    {var_qrcode_expiry_seconds === 0 &&
                        <div className='qrcode_expired'>
                            <Icon name='qr_code' />
                        </div>
                    }
                    {var_current_status === 'REQUEST SENT' &&
                        <Processing display={true} processingtext={t('Processing')} style={{ color: var_verification_request.text_color }} />
                    }
                </div>
                <div className='qrcode_expiry text--sm-regular'>
                    {var_qrcode_expiry_seconds > 0 ? t('This QR code is valid for {{minutes}} minutes.', { minutes: var_verification_request.expiry_time_in_seconds / 60 }) : t('This QR code has expired.')}
                    <span className='text--anchor' onClick={create_verification_request}>{t('Generate a new one')}</span>
                    .
                </div>
                {/Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
                    // Not sure if that is the complete list, but should cover 99% or the cases?
                    <Button className='secondary' onClick={onClick_openLink}>{t(`Can't scan QR code?`)}</Button>
                }
            </div>
        );
    }

    function render_result() {
        return (
            <div className='verification_result'>
                <div className={'verification_result_body ' + var_current_status.toLowerCase()}>
                    {var_current_status === 'FULFILLED'
                    ?
                        <>
                            <Icon name='checkmark__whiteBG' alt={t('Pass icon')} className='icon' />
                            <div className='text--lg-bold'>{t('PASS')}</div>
                        </>
                    : var_current_status === 'FAIL'
                    ?
                        <>
                            <Icon name='xicon__whiteBG' alt={t('Fail icon')} className='icon' />
                            <div className='text--lg-bold'>{t('FAIL')}</div>
                        </>
                    : var_current_status === 'ERROR'
                    ?
                        <>
                            <Icon name='error__whiteBG' alt={t('Unable to verify icon')} className='icon' />
                            <div className='text--lg-bold'>{t('UNABLE TO VERIFY')}</div>
                        </>
                    : null
                    }
                </div>
                <Button id='btn_refresh' className='secondary' onClick={create_verification_request} aria-label={t('Refresh QR code')}>
                    {t('Refresh page')}
                </Button>
                <div className='text--xs-regular' style={{ color: var_verification_request.text_color }}>
                    {var_refresh_seconds > 60
                        ? t('This page will refresh in {{minutes}}:{{seconds}} minutes.', { minutes: Math.floor(var_refresh_seconds / 60), seconds: (var_refresh_seconds % 60).toString().padStart(2, '0') })
                        : var_refresh_seconds > 1
                        ? t('This page will refresh in {{seconds}} seconds.', { seconds: var_refresh_seconds })
                        : t('This page will refresh in 1 second.')
                    }
                </div>
            </div>
        );
    }

}

CRD_VERIFICATION.propTypes = {
    token: propTypes.string.isRequired
};

export default CRD_VERIFICATION;

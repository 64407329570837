import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import API from 'libs/api-lib';

import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';

import 'i18n';

import MDL_COUNT_DETAILS from "./mdl_count_details/mdl_count_details";
import MDL_ADD_HOLDER from './mdl_add_holder/mdl_add_holder';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';


export default function TAB_HOLDERS({ activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_holders, set_holders ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_countdetails_open, set_countdetails_open ] = useState(false);
    const [ var_individual, set_individual ] = useState('');
    const [ var_addholder_open, set_addholder_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'HOLDERS') {
            set_refreshtable(!var_refreshtable);
            populate_holders({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);

    useEffect(() => {
        if (!var_addholder_open && var_focuselement?.id === 'btn_addholder') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addholder_open]);

    useEffect(() => {
        if (!var_countdetails_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_cred_holders').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_countdetails_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_holders(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_holders(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_holders(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_holders(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_holders(limit, offset) {
        try {
            let {sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_holders(limit, offset, sortby, sortorder, filter)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_holders(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('credentials', '/get-org-holders',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_individual(id) {
        // TODO: UPDATE once we know where to route this
        navigate('/individuals/' + id);
    }

    function onClick_credentials(individual) {
        set_focuselement(document.activeElement);
        if (individual.credentials > '0') {
            set_individual(individual);
            set_countdetails_open(true);
        }
    }

    function onChange_addholder() {
        populate_holders();
        set_addholder_open(false);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_cred_holders' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_holders' className='text--xl-medium'>{t('Holders')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_addholder' className='primary' onClick={() => {set_focuselement(document.activeElement); set_addholder_open(true);}}>{t('Add holder')}</Button>
                    </div>
                </div>

                <Table id='holders' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('holders')} downloadfunction={download_holders}
                        onChange={populate_holders}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='email' datatype='text' filtertype='text'>{t('EMAIL')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                            <Table.HeaderCell field='credentials' datatype='number' filtertype='number'>{t('CREDENTIALS')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_holders.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <CMP_VERIFIED_IDENTITY_DISPLAY
                                        individual_name={item.individual_name}
                                        onClick_individual={() => onClick_individual(item.id)}
                                        verified_identity_credential_id={item.identity_verification_credential_id}
                                    />
                                </Table.Cell>
                                <Table.Cell>{item.email}</Table.Cell>
                                <Table.Cell>{item.organization}</Table.Cell>
                                <Table.Cell className={item.credentials > 0 ? 'text--anchor' : ''}>
                                    <Button className='tertiary' onClick={() => onClick_credentials(item)} aria-disabled={item.credentials === 0 && "true"}>
                                        {item.credentials}
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
                <div className='card__footer--mobilebtns'>
                    <Button id='btn_addholder' className='primary' onClick={() => set_addholder_open(true)}>{t('Add holder')}</Button>
                </div>
            </div>

            <MDL_COUNT_DETAILS
                display={var_countdetails_open}
                onClose={() => set_countdetails_open(false)}
                individual={var_individual}
            />

            <MDL_ADD_HOLDER
                is_open={var_addholder_open}
                onClose={() => set_addholder_open(false)}
                onChange={onChange_addholder}
            />
        </>
    )
}
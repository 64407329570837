import React, { useEffect, useState } from 'react';
import form_helper from 'libs/form-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_INPUT } from 'components/cmp_form/cmp_form';
import 'i18n';
import Processing from "components/cmp_processing";


export default function CRD_DETAILS({ step, onChange_step, requirement_package, onChange_package, display_processing }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_opened, set_opened ] = useState(false)
    const [ var_errors, set_errors ] = useState([]);
    const [ var_show_checkmark, set_show_checkmark ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_errors([]);
        if(step === 'DETAILS') {
            set_opened(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        form_helper.validate_required_string(requirement_package.name)
        ? set_show_checkmark(true)
        : set_show_checkmark(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement_package]);


    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        onChange_step('DETAILS');
    }

    function onChange_input(event) {
        let updated_package = {...requirement_package};
        updated_package[event.target.name] = event.target.value;
        onChange_package(updated_package);
        if (var_errors.length > 0) {
            const errors = var_errors.filter(error => error.property !== event.target.name);
            set_errors(errors);
        }
    }

    function onClick_nextstep() {

        let updated_package = {...requirement_package};
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(updated_package.name)) {
            errors.push({ property: 'name', description: t('Name is required') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        form_helper.cleanse_string_property(updated_package, 'name');
        onChange_package(updated_package);
        onChange_step('REQUIREMENTS');
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_cr8package_details' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__icon'>
                    {(var_opened && step !== 'DETAILS' && var_show_checkmark) ?
                        <Icon name='checkmark' className='icon__checkmark' alt='checkmark icon' />
                        :
                        <div className='card__header__icon__step text--xl-bold icon--dark_gray'>1</div>
                    }
                </div>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_cr8package_details'>{t('Package details')}</div>
                    {(!var_opened || step === 'DETAILS') &&
                        <div className='text--sm-regular'>{t('Set the name and description for the package.')}</div>
                    }
                </div>
                <div className='card__header__right card__header__btns'>
                    {(var_opened && step !== 'DETAILS') &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {(step !== 'DETAILS' && var_opened) ?
                <>
                    <div className='card__content create_summary'>
                        <FORM_DETAILSGROUP label={t('Name')} value={requirement_package.name} />
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    </div>

                </>
                : step === 'DETAILS' ?
                    <>
                        <Form className='card__content' id='form_cr8package_step1' aria-labelledby='hdr_cr8package_details'>

                            {var_errors.length > 0 &&
                                <Message
                                    error icon={<Icon name='error' className='icon'/>}
                                    header={t('There are some errors with your inputs')}
                                />
                            }

                            <FORM_INPUT
                                property='name'
                                label={t('Name')}
                                value={requirement_package.name}
                                onChange={onChange_input}
                                placeholder={t('Name')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                                description=''
                            />

                        </Form>
                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_nextstep}>{t('Continue to step 2')}</Button>
                        </div>

                    </>
                    : null
            }

            <Processing display={display_processing} processingtext='' />

        </div>
    );
}
import React, { useState, useEffect } from 'react';
import auth from 'libs/auth-lib';

import CMP_DOCUMENTS_INDIVIDUAL from './cmp_documents_individual';
import CMP_DOCUMENTS_ADMIN from './cmp_documents_admin';



export default function TAB_DOCUMENTS({ assignment, onChange, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_view, set_view ] = useState('LOADING');
    const [ var_focuselement, set_focuselement ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'DOCUMENTS' && assignment.location_id && !auth.has_access(assignment, 'INDV-DOC', 'document')) {
            set_view('ALL');
        } else if (activetab === 'DOCUMENTS' && assignment.location_id) {
            set_view('INDIVIDUAL');
        }
    }, [activetab, assignment]);

    // RENDER APP ======================================================================================================

    return (
        <>
            {var_view === 'INDIVIDUAL'
                ? <CMP_DOCUMENTS_INDIVIDUAL assignment={assignment} onChange={onChange} activetab={activetab + '-' + var_view} onChangeView={() => set_view('ALL')} var_focuselement={var_focuselement} set_focuselement={set_focuselement} />
                : var_view === 'ALL'
                ? <CMP_DOCUMENTS_ADMIN assignment={assignment} onChange={onChange} activetab={activetab + '-' + var_view} onChangeView={() => set_view('INDIVIDUAL')} var_focuselement={var_focuselement} set_focuselement={set_focuselement} />
                : null
            }
        </>
    );
};
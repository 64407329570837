import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import CRD_DETAILS from './crd_details/crd_details';
import CRD_SETTINGS from './crd_settings/crd_settings';
// import CRD_FEATURES from './crd_features/crd_features';



export default function ASSIGNMENT_CREATE() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_step, set_step ] = useState('DETAILS');
    const [ var_assignment, set_assignment ] = useState({ status: 'ACTIVE' });

    const [ var_processing, set_processing ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }, { path: '/assignments', text: t('Assignments') }]}
                current_page_text={t('Create assignment')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background2'>
                    <h1 className='display--sm-regular'>{t('Create assignment')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <CRD_DETAILS
                    step={var_step}
                    onChange_step={set_step}
                    assignment={var_assignment}
                    onChange_assignment={set_assignment}
                    display_processing={var_processing}
                />

                <CRD_SETTINGS
                    step={var_step}
                    onChange_step={set_step}
                    assignment={var_assignment}
                    onChange_assignment={set_assignment}
                    onChange_processing={set_processing}
                    display_processing={var_processing}
                />

                {/*<CRD_FEATURES*/}
                {/*    var_step={var_step}*/}
                {/*    set_step={set_step}*/}
                {/*    var_assignment={var_assignment}*/}
                {/*    set_assignment={set_assignment}*/}
                {/*    insert_assignment={insert_assignment}*/}
                {/*/>*/}

            </div>
        </div>
    )
};
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import 'i18n';



export default function TAB_ASSIGNMENTS({ individual_id, location_id, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_is_assigned_areas_only, set_is_assigned_areas_only ] = useState(true);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'ASSIGNMENTS') {
            set_refreshtable(!var_refreshtable);
            populate_records();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_individual_locations(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_assignments(results.results === undefined ? [] : results.results);
            set_is_assigned_areas_only(results.is_assigned_areas_only);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_individual_locations(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_individual_locations(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_individual_locations(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('org-indv', `/get-org-individual-locations/${individual_id}${location_id ? '/' + location_id : ''}`,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_assignment(id) {
        navigate('/assignments/' + id);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_workerprofile_assignments'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('Assignments')}</div>
                </div>
            </div>

            <Table id='assignments' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname={('Assignments')} downloadfunction={download_records}
                    onChange={populate_records}>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('ASSIGNMENT')}</Table.HeaderCell>
                        <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {var_assignments.map(item =>
                       <Table.Row key={item.id}>
                            <Table.Cell className={(!var_is_assigned_areas_only || item.user_in_location === 'YES') ? 'text--anchor' : null}>
                                <Button className='tertiary' onClick={(!var_is_assigned_areas_only || item.user_in_location === 'YES') ? () => onClick_assignment(item.id) : null}>
                                    {item.name}
                                </Button>
                            </Table.Cell>
                            <Table.Cell>{item.organization}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>

            </Table>

        </div>
    );

}
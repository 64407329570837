import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';

import CRD_PASSWORD from './crd_password/crd_password';
import CRD_MFA from './crd_mfa/crd_mfa';
import CRD_LANGUAGE from './crd_language/crd_language';



export default function TAB_SETTINGS({activetab, reset_authentication }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_settings, set_settings ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (activetab === 'SETTINGS') {
            populate_settings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_settings() {
        set_settings(await API_get_profile_settings());
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_profile_settings() {
        return API.get('admin', '/get-profile-settings');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    // RENDER APP ======================================================================================================

    return(
        <>
            <CRD_PASSWORD sso_enabled={!!var_settings ? var_settings.is_sso === 'YES' : null} />
            {var_settings?.display_mfa === 'YES' &&
                <CRD_MFA sso_enabled={var_settings.is_sso === 'YES'} is_mfa_set={var_settings.is_mfa_set === 'YES'} />
            }
            <CRD_LANGUAGE activetab={activetab} reset_authentication={reset_authentication} />
        </>
    )
};

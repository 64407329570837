import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import 'i18n';



export default function CRD_CREDENTIALS({ transcript }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'credential_name', sortorder: 'ascending' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect( () => {
        transcript.id && populate_credentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcript.id]);


    async function populate_credentials(populateconfig) {

        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_individual_transcript_credentials(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_credentials(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_individual_transcript_credentials(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_credentials(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_individual_transcript_credentials(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individual_transcript_credentials(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('transcripts', '/get-individual-transcript-credentials/' + transcript.id + '/ALL',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_credential(credential_id) {
        navigate(`/credentials/ALL/${credential_id}`);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_transcript_details_credentials' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_transcript_details_credentials'>{t('Transcript credentials')}</div>
                    <div className='text--sm-regular'>{t('Credits are only available for valid credentials.')}</div>
                </div>
            </div>

            <Table id='transcript_credentials' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Transcript credentials')} downloadfunction={download_credentials}
                        onChange={populate_credentials}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='confirmation_status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell field='issued_date' datatype='date' filtertype='date'>{t('ISSUED')}</Table.HeaderCell>
                            {!!transcript.additional_table_field &&
                                <Table.HeaderCell field='additional_table_field' datatype='text' filtertype='text'>{transcript.additional_table_field.translate ? t(transcript.additional_table_field.label) : transcript.additional_table_field.label}</Table.HeaderCell>
                            }
                            {transcript.display_credits === 'YES' &&
                                <Table.HeaderCell field='credit_value' datatype='number' filtertype='number'>{t('CREDIT')}</Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_credentials.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <div className={'badge text--sm-medium  ' +
                                        (   item.verification_status === 'VALID' ?
                                                'badge--green'
                                            : item.verification_status === 'INVALID' ?
                                                'badge--red'
                                            :  ['PENDING', 'IN PROGRESS'].includes(item.verification_status) ?
                                                'badge--yellow'
                                            : 'badge--gray')}>
                                        {item.confirmation_status_display}
                                    </div>
                                </Table.Cell>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' onClick={() => onClick_credential(item.id)}>{item.credential_name}</Button>
                                </Table.Cell>
                                <Table.Cell>{datelib.epoch_to_date_string(item.issued_date)}</Table.Cell>
                                {!!transcript.additional_table_field &&
                                    <Table.Cell>{item.additional_table_field}</Table.Cell>
                                }
                                {transcript.display_credits === 'YES' &&
                                    <Table.Cell>{item.verification_status === 'VALID'
                                                    ?  item.credit_value?.toFixed(2)
                                                    : '0.00'
                                                }
                                    </Table.Cell>
                                }
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

        </div>
    );
}

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import 'i18n';

import './cmp_confirmation.css';


function CMP_CONFIRMATION({ display = false, title, message, positive_option, negative_option, onConfirm, onCancel }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){
            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_confirmation'));
        }
    }, [display]);


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            open={display}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_confirmation'
            id='mdl_confirmation'
            className='cmp_confirmation__wrapper'
            >
            <div className='cmp_confirmation__icon'>
                <Icon name='warning_circle' alt='' />
            </div>
            <div className='cmp_confirmation__text'>
                <div className='text--lg-medium cmp_confirmation__title' id='hdr_confirmation'>
                    {title}
                </div>
                <div className='text--sm-regular cmp_confirmation__message'>
                    {message}
                </div>
                <div className='modal-footer-buttons'>
                    <Button className='primary' onClick={onConfirm}>{positive_option || t('Yes')}</Button>
                    <Button className='secondary' onClick={onCancel}>{negative_option || t('No')}</Button>
                </div>
            </div>
        </Modal>
    )
}

CMP_CONFIRMATION.propTypes = {
    display: propTypes.bool,
    title: propTypes.oneOfType([ propTypes.string, propTypes.element ]),
    message: propTypes.oneOfType([ propTypes.string, propTypes.element ]),
    positive_option: propTypes.string,
    negative_option: propTypes.string,
    onConfirm: propTypes.func.isRequired,
    onCancel: propTypes.func.isRequired
};

export default CMP_CONFIRMATION;
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { Button } from 'semantic-ui-react';
import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';

import './tab_history.css';


export default function TAB_HISTORY({ credential, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_history, set_history ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'lastupdated', sortorder: 'descending' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_docviewer_open, set_docviewer_open ] = useState(false);
    const [ var_docviewer_id, set_docviewer_id ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'HISTORY') {
            populate_history();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_history').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_history(populateconfig) {
        set_loading(true);
        set_loadingerror(false);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_history(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_history(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_history(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_history(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_history(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('credentials', '/get-history/' + credential.id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_document(id) {
        set_focuselement(document.activeElement);
        set_docviewer_id(id);
        set_docviewer_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm card__credential_history' id='crd_history' tabIndex='0'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_credhistory' className='text--xl-medium'>{t('History')}</div>
                    </div>
                </div>

                <Table id='history' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('History')} downloadfunction={download_records}
                        downloadconfig={[
                            {
                                'field': 'lastupdated',
                                'datatype': 'datetime',
                                'label': t('DATE')
                            },
                            {
                                'field': 'individual_name',
                                'datatype': 'text',
                                'label': t('HOLDER')
                            },
                            {
                                'field': 'status',
                                'datatype': 'text',
                                'label': t('STATUS')
                            },
                            {
                                'field': 'confirmation_status',
                                'datatype': 'text',
                                'label': t('VERIFICATION')
                            },
                            {
                                'field': 'issued_date',
                                'datatype': 'date',
                                'label': t('ISSUED')
                            },
                            {
                                'field': 'expires_date',
                                'datatype': 'date',
                                'label': t('EXPIRES')
                            },
                            {
                                'field': 'submittedfilename',
                                'datatype': 'text',
                                'label': t('DOCUMENT')
                            }
                        ]}
                        onChange={populate_history}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='lastupdated' datatype='datetime' filtertype='date'>{t('DATE')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('DETAILS')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_history.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='cell__datetime'>{datelib.epoch_to_datetime_string(item.lastupdated, true)}</Table.Cell>
                                <Table.Cell id='credential__history__details'>
                                    <div className='cell__detail'>
                                        <div className='cell__detail__item'>
                                            <div className='text--sm-medium'>{t('Holder')}:</div>
                                            <div className='text--sm-regular'>{item.individual_name}</div>
                                        </div>
                                        <div className='cell__detail__item'>
                                            <div className='text--sm-medium'>{t('Status')}:</div>
                                            <div className='text--sm-regular'>{t(item.status === 'ACTIVE' ? 'Active' : 'Inactive')}</div>
                                        </div>
                                        <div className='cell__detail__item'>
                                            <div className='text--sm-medium'>{t('Verification')}:</div>
                                            <div className='text--sm-regular'>{t(item.confirmation_status)}</div>
                                        </div>
                                        {item.issued_date &&
                                            <div className='cell__detail__item'>
                                                <div className='text--sm-medium'>{t('Issued')}:</div>
                                                <div className='text--sm-regular'>{datelib.epoch_to_date_string(item.issued_date)}</div>
                                            </div>
                                        }
                                        {item.expires_date &&
                                            <div className='cell__detail__item'>
                                                <div className='text--sm-medium'>{t('Expires')}:</div>
                                                <div className='text--sm-regular'>{datelib.epoch_to_date_string(item.expires_date)}</div>
                                            </div>
                                        }
                                        {item.submittedfilename &&
                                            <div className='cell__detail__item'>
                                                <div className='text--sm-medium'>{t('Document')}:</div>
                                                <Button className='quaternary' onClick={() => onClick_document(item.id)}>{item.submittedfilename}</Button>
                                            </div>
                                        }
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

                {/***** MODAL: DOC VIEWER *******************************************************************************/}

                <MDL_DOCVIEWER
                    targetid={var_docviewer_id}
                    classification='credential-revision'
                    is_open={var_docviewer_open}
                    onClose={() => set_docviewer_open(false)}
                />

                {/***** END MODAL: DOC VIEWER ***************************************************************************/}

            </div>
        </>
    );
}
import React, { useState } from 'react';
import propTypes from 'prop-types';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';

import './crd_mfa.css';


function CRD_MFA({ sso_enabled, is_mfa_set }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const [ var_display_confirm_reset, set_display_confirm_reset ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_reset_mfa() {
        return API.put('admin', '/put-reset-mfa');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_mfa() {
        if (is_mfa_set) {
            set_display_confirm_reset(true);
        } else {
            auth.iam.setup_mfa();
        }
    }

    async function onConfirm_resetMFA() {
        await API_put_reset_mfa();
        auth.iam.setup_mfa();
    }


    // RENDER APP ======================================================================================================

    return (
        sso_enabled !== null &&
            <div className='card rounded-lg shadow-sm' id='crd__mfa'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_mfa' className='text--xl-medium'>
                            {t('Multi-factor authentication (MFA)')}
                            {is_mfa_set && <div className='badge text--sm-medium badge--green'>{t('Enabled')}</div>}
                        </div>
                        <div className='text--sm-regular'>
                            {sso_enabled
                                ? t('MFA is disabled as your organization employs single sign-on.')
                                : t('Enhance security by activating multi-factor authentication for your account with an authenticator app.')
                            }
                        </div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                            <Button className={'secondary' + (sso_enabled ? ' disabled' : '')} disabled={sso_enabled} onClick={onClick_mfa}>
                                {is_mfa_set ? t('Change authenticator') : t('Enable MFA')}
                            </Button>
                    </div>
                </div>

                <div className='card__footer--mobilebtns'>
                    <Button className={'secondary' + (sso_enabled ? ' disabled' : '')} disabled={sso_enabled} onClick={onClick_mfa}>
                        {is_mfa_set ? t('Change authenticator') : t('Enable MFA')}
                    </Button>
                </div>

                <CMP_CONFIRMATION
                    display={var_display_confirm_reset}
                    title={t('Change authenticator')}
                    message={
                        <>
                            <div>{t('Only one authenticator app can be used with Credivera at a time. If you change the app used for MFA, the original app will be invalidated.')}</div>
                            <div>{t('This action cannot be undone.')}</div>
                        </>}
                    positive_option={t('Continue')}
                    negative_option={t('Cancel')}
                    onConfirm={onConfirm_resetMFA}
                    onCancel={() => set_display_confirm_reset(false)}
                    />
            </div>

    );
}

CRD_MFA.propTypes = {
    sso_enabled: propTypes.bool,
    is_mfa_set: propTypes.bool
};

export default CRD_MFA;

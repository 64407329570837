import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import 'i18n';

import MDL_INDIVIDUALS from './mdl_individuals/mdl_individuals';
import MDL_ASSIGNED from './mdl_assigned/mdl_assigned';
import MDL_VALID from './mdl_valid/mdl_valid';
import MDL_PENDING from './mdl_pending/mdl_pending';
import MDL_ASSIGN_REQUIREMENT from './mdl_assign_requirement/mdl_assign_requirement';



export default function CRD_REQUIREMENTS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'requirement' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_requirementstatus, set_requirementstatus ] = useState('ACTIVE');

    const [ var_mdl_requirement_id, set_mdl_requirement_id ] = useState('')
    const [ var_mdl_individuals_open, set_mdl_individuals_open ] = useState(false);
    const [ var_mdl_assigned_open, set_mdl_assigned_open ] = useState(false);
    const [ var_mdl_valid_open, set_mdl_valid_open ] = useState(false);
    const [ var_mdl_pending_open, set_mdl_pending_open ] = useState(false);
    const [ var_mdl_title, set_mdl_title ] = useState('');
    const [ var_mdl_requirement, set_mdl_requirement ] = useState(false);

    const [ var_mdl_assignrequirement_open, set_mdl_assignrequirement_open ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_requirements({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_requirementstatus]);

    useEffect(() => {
        if (!var_mdl_individuals_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_individuals_open]);

    useEffect(() => {
        if (!var_mdl_assigned_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_assigned_open]);

    useEffect(() => {
        if (!var_mdl_valid_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_requirements_all').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_valid_open]);

    useEffect(() => {
        if (!var_mdl_pending_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_requirements_all').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_pending_open]);

    useEffect(() => {
        if (!var_mdl_assignrequirement_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_assignrequirement_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirements(populateconfig, focuselement_id) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_requirements(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_requirements(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
            document.getElementById(var_focuselement)?.focus();
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_requirements_all').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        } else if (var_focuselement?.id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${var_focuselement.id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_requirements_all').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_requirements(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_requirements(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_requirements(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_requirements(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-org-requirements/' + var_requirementstatus,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_requirementstatus(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_requirementstatus(status);
    }

    function onClick_requirement(id) {
        navigate('/org/requirements/' + id);
    }

    function onClick_createrequirement() {
        navigate('/org/requirements/create');
    }

    function onClick_count(title, id, requirement, count) {
        set_focuselement(document.activeElement);
        if (count > 0) {
            set_mdl_requirement_id(id);
            set_mdl_title(title);
            set_mdl_requirement(requirement);
            if (['Valid', 'Invalid', 'Expiring'].includes(title)) {
                set_mdl_valid_open(true);
            } else if (title === 'Pending') {
                set_mdl_pending_open(true);
            } else if (['Missing', 'Requested'].includes(title)) {
                set_mdl_assigned_open(true);
            } else {
                set_mdl_individuals_open(true);
            }
        }
    }

    function onClick_assignrequirement(id, requirement) {
        set_focuselement(document.activeElement);
        set_mdl_requirement_id(id);
        set_mdl_requirement(requirement);
        set_mdl_assignrequirement_open(true);
    }

    function onChange_assign_requirement(focuselement_id) {
        populate_requirements(null, focuselement_id);
        set_mdl_assignrequirement_open(false);
    }

    function onClose_mdl_individuals(focuselement_id) {
        set_mdl_individuals_open(false);
        focuselement_id && document.getElementById(focuselement_id)?.focus();
    }

    function onClose_mdl_assigned(focuselement_id) {
        set_mdl_assigned_open(false);
        focuselement_id && document.getElementById(focuselement_id)?.focus();
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_requirements_all' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_requirements' className='text--xl-medium'>{t('All requirements')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button className='primary' onClick={onClick_createrequirement}>{t('Create requirement')}</Button>
                    </div>
                </div>

                <Table id='requirements' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('requirements')} downloadfunction={download_requirements}
                       onChange={populate_requirements}>
                    <Table.OptionsButtons>
                        <TOGGLE value={var_requirementstatus} onChange={onClick_requirementstatus} options={[{value: 'ACTIVE', text: t('Active')}, {value: 'DRAFT', text: t('Draft')}, {value: 'ARCHIVE', text: t('Inactive')}]} />
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='requirement' datatype='text' filtertype='text'>{t('REQUIREMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='assigned_count' datatype='number' filtertype='number'>{t('INDIVIDUALS')}</Table.HeaderCell>
                            <Table.HeaderCell field='valid_count' datatype='number' filtertype='number'>{t('VALID')}</Table.HeaderCell>
                            <Table.HeaderCell field='invalid_count' datatype='number' filtertype='number'>{t('INVALID')}</Table.HeaderCell>
                            <Table.HeaderCell field='expiring_count' datatype='number' filtertype='number'>{t('EXPIRING')}</Table.HeaderCell>
                            <Table.HeaderCell field='pending_count' datatype='number' filtertype='number'>{t('PENDING')}</Table.HeaderCell>
                            <Table.HeaderCell field='missing_count' datatype='number' filtertype='number'>{t('MISSING')}</Table.HeaderCell>
                            <Table.HeaderCell field='requested_count' datatype='number' filtertype='number'>{t('REQUESTED')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_requirements.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className={var_requirementstatus !== 'DRAFT' ? 'text--anchor' : ''} >
                                    <Button className='tertiary' onClick={var_requirementstatus !== 'DRAFT' ? () => onClick_requirement(item.id) : null}>
                                        {item.requirement}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>
                                    <div className={item.assigned_count > 0 ? 'text--anchor' : ''}>
                                        <Button id={`btn_individuals_${item.id}`} className='tertiary' onClick={() => onClick_count('Individuals', item.id, item.requirement, item.assigned_count)} aria-disabled={item.assigned_count === 0 && "true"}>
                                            {item.assigned_count}
                                        </Button>
                                        {var_requirementstatus !== 'DRAFT' &&
                                        <Button id={`btn_assign_requirement_${item.id}`} className='tertiary' onClick={() => onClick_assignrequirement(item.id, item.requirement)} >
                                            <Icon name='plus_circle' className='icon__addindividual color--primary-500' alt={t('Assign requirement')} />
                                        </Button>
                                        }
                                    </div>
                                </Table.Cell>
                                <Table.Cell className={item.valid_count > 0 ? 'text--anchor' : ''} >
                                    <Button className='tertiary' onClick={() => onClick_count('Valid', item.id, item.requirement, item.valid_count)} aria-disabled={item.valid_count === 0 && "true"}>
                                        {item.valid_count}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className={item.invalid_count > 0 ? 'text--anchor' : ''} >
                                    <Button className='tertiary' onClick={() => onClick_count('Invalid', item.id, item.requirement, item.invalid_count)} aria-disabled={item.invalid_count === 0 && "true"}>
                                        {item.invalid_count}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className={item.expiring_count > 0 ? 'text--anchor' : ''} >
                                    <Button className='tertiary' onClick={() => onClick_count('Expiring', item.id, item.requirement, item.expiring_count)} aria-disabled={item.expiring_count === 0 && "true"}>
                                        {item.expiring_count}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className={item.pending_count > 0 ? 'text--anchor' : ''} >
                                    <Button className='tertiary' onClick={() => onClick_count('Pending', item.id, item.requirement, item.pending_count)} aria-disabled={item.pending_count === 0 && "true"}>
                                        {item.pending_count}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className={item.missing_count > 0 ? 'text--anchor' : ''} >
                                    <Button id={`btn_missing_${item.id}`} className='tertiary' onClick={() => onClick_count('Missing', item.id, item.requirement, item.missing_count)} aria-disabled={item.missing_count === 0 && "true"}>
                                        {item.missing_count}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className={item.requested_count > 0 ? 'text--anchor' : ''} >
                                    <Button id={`btn_requested_${item.id}`} className='tertiary' onClick={() => onClick_count('Requested', item.id, item.requirement, item.requested_count)} aria-disabled={item.requested_count === 0 && "true"}>
                                        {item.requested_count}
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button className='primary' onClick={onClick_createrequirement}>{t('Create requirement')}</Button>
                </div>

            </div>

            <MDL_INDIVIDUALS
                display={var_mdl_individuals_open}
                onClose={onClose_mdl_individuals}
                title={var_mdl_title}
                requirement_name={var_mdl_requirement}
                requirement_id={var_mdl_requirement_id}
                requirement_status={var_requirementstatus}
                onChange={populate_requirements}
            />

            <MDL_ASSIGNED
                display={var_mdl_assigned_open}
                onClose={onClose_mdl_assigned}
                title={var_mdl_title}
                requirement_name={var_mdl_requirement}
                requirement_id={var_mdl_requirement_id}
                requirement_status={var_requirementstatus}
                onChange={populate_requirements}
            />

            <MDL_VALID
                display={var_mdl_valid_open}
                onClose={() => set_mdl_valid_open(false)}
                title={var_mdl_title}
                requirement_name={var_mdl_requirement}
                requirement_id={var_mdl_requirement_id}
                requirement_status={var_requirementstatus}
                onChange={populate_requirements}
            />

            <MDL_PENDING
                display={var_mdl_pending_open}
                onClose={() => set_mdl_pending_open(false)}
                title={var_mdl_title}
                requirement_name={var_mdl_requirement}
                requirement_id={var_mdl_requirement_id}
                requirement_status={var_requirementstatus}
                onChange={populate_requirements}
            />

            <MDL_ASSIGN_REQUIREMENT
                display={var_mdl_assignrequirement_open}
                onClose={() => set_mdl_assignrequirement_open(false)}
                requirement_name={var_mdl_requirement}
                requirement_id={var_mdl_requirement_id}
                onChange={onChange_assign_requirement}
            />

        </>
    )

}
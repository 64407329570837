import React from 'react';
import propTypes from 'prop-types';
import Icon from 'components/cmp_icon';
import { useTranslation } from 'react-i18next';
import '../i18n';

function CMP_CREDENTIALSTATUS ({ verification_status, confirmation_status, clickfor_reportcert = false, has_customsecurity = 'NO' }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const containerstyle = {display: 'flex', alignItems: 'center'};

    //  event functions ------------------------------------------------------------------------------------------------

    // RENDER ==========================================================================================================

    return (
        <div className='cmp_confirmation' style={containerstyle}>
            <div>
                {verification_status === 'VALID' && confirmation_status === 'SELF VERIFIED'
                    ? <Icon name='credential' className='icon__status color--blue-500' alt={t('status icon')} />
                    : verification_status === 'VALID'
                    ? <Icon name='credential' className='icon__status color--green-500' alt={t('status icon')} />
                    : verification_status === 'INVALID'
                    ? <Icon name='credential' className='icon__status color--red-600' alt={t('status icon')} />
                    : (verification_status === 'PENDING' || verification_status === 'IN PROGRESS')
                    ? <Icon name='credential' className='icon__status color--yellow-500' alt={t('status icon')} />
                    : null
                }
            </div>
            {confirmation_status !== null &&
                <div className='cmp_confirmation_status_txt'>{t(confirmation_status)}</div>
            }
        </div>
    )
}

CMP_CREDENTIALSTATUS.propTypes = {
    verification_status: propTypes.string,
    confirmation_status: propTypes.string,
    clickfor_reportcert: propTypes.bool,
    id: propTypes.string,
    has_customsecurity: propTypes.string
};

export default CMP_CREDENTIALSTATUS;

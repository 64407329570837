import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import 'i18n';

import './crd_reca.css';


export default function CRD_RECA() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_can_generate, set_can_generate ] = useState(false);
    const [ var_records, set_records ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'report_date' });


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect( () => {
        populate_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_data() {

        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        try {
            let results = await API_get_download_reports();
            set_can_generate(results.report_in_progress === 'NO');
            set_records(results.reports_ready);
            set_totalrows(results.reports_ready.length);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_download_reports() {
        return API.get('reports', '/get-download-reports/reca_missing_by_brokerage');
    }

    function API_get_download_report(id) {
        return API.get('reports', `/get-download-report/${id}`);
    }

    function API_post_reca_missing_by_brokerage() {
        return API.post('reports', '/post-reca-missing-by-brokerage');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_generate() {
        await API_post_reca_missing_by_brokerage();
        set_can_generate(false);
    }

    async function onClick_report(id) {
        let response = await API_get_download_report(id);
        if (response.status === 'SUCCESS') {
            const a = document.createElement('a');
            a.href = response.url;
            a.click();
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_reports_reca_members'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--sm-regular'>{t('This report highlights RECA members affiliated with a brokerage who are not yet part of the organization.')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button className='primary' onClick={onClick_generate} disabled={!var_can_generate}>{t('Generate report')}</Button>
                    </div>
                </div>

                {!var_can_generate && var_ready &&
                    <Message warning
                        icon={<Icon name='warning' className='icon' />}
                        header={t('A report is being generated. You will receive a notification email when it’s ready for download. Please note that report generation will be temporarily unavailable until it’s completed.')}
                    />
                }

                <Table id='RECAmembers' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={false} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={() => {}}
                       downloadname={t('RECA members')} downloadfunction={() => {}}
                       onChange={() => {}} hide_tablecontrols={true}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='report_date' datatype='text' filtertype='text'>{t('REPORT')}</Table.HeaderCell>
                            <Table.HeaderCell field='create_individual' datatype='text' filtertype='text'>{t('GENERATED BY')}</Table.HeaderCell>
                            <Table.HeaderCell field='expires' datatype='text' filtertype='option'>{t('EXPIRES')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_records.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell >
                                    <Button className='text--anchor' onClick={() => onClick_report(item.id)}>
                                        {item.download_filename.substring(0, item.download_filename.lastIndexOf('.'))}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.create_individual}</Table.Cell>
                                <Table.Cell>{datelib.epoch_to_datetime_string(item.expires, true)}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button className='primary' onClick={onClick_generate} disabled={!var_can_generate}>{t('Generate report')}</Button>
                </div>
            </div>
        </>
    )

}

import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Processing from 'components/cmp_processing';
import 'i18n';

import CMP_VERIFICATION_TEMPLATE from 'components/cmp_verification_template/cmp_verification_template';

import './mdl_theme_preview.css';

function MDL_THEME_PREVIEW({ verification_template, is_open, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const [ var_theme_details, set_theme_details ] = useState({});
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open) {
            set_theme_details({});
            set_processing(false);
            populate_theme_details();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (is_open) {
            // focus trapping
            let modal = document.getElementById('crd_verification_template_details_preview');
            modal.querySelector('.modal__content').focus();
            modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    let elements = modal.querySelectorAll('.modal__content, #btn_preview_close');
                    let firstelement = elements[0];
                    let lastelement = elements[elements.length - 1];
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [is_open]);


    //  async functions ------------------------------------------------------------------------------------------------


    async function populate_theme_details() {
        try {
            set_processing(true);
            let results = await API_get_verification_template_theme_details();
            set_theme_details(results);
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------


    function API_get_verification_template_theme_details() {
        return API.get('verifiable-credentials', '/get-verification-template-theme-details/' + verification_template.verification_template_theme_id);
    }


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            onClose={onClose}
            id='crd_verification_template_details_preview'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_preview'
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_preview'>{t('Template preview')}</div>
                </div>
            </div>
            <div className='modal__content' tabIndex='0'>
                <CMP_VERIFICATION_TEMPLATE
                    display_type='modal'
                    template={{
                        issuer_id: var_theme_details.issuer_id,
                        logo: var_theme_details.logo,
                        title: verification_template.title,
                        description: verification_template.description,
                        background_color: var_theme_details.background_color,
                        text_color: var_theme_details.text_color,
                        logo_classification: var_theme_details.logo_classification
                    }}
                />
            </div>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button id='btn_preview_close' className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>
            <Processing display={var_processing} processingtext={t('Processing')} />
        </Modal>
    );

}

MDL_THEME_PREVIEW.propTypes = {
    verification_template: propTypes.object.isRequired,
    is_open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired
};

export default MDL_THEME_PREVIEW;
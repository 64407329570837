import React, { useEffect } from 'react';

import CRD_DETAILS from './crd_details/crd_details';



export default function TAB_DETAILS({ transcript }) {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            <CRD_DETAILS transcript={transcript} />
        </>
    )
};

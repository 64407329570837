import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from 'components/cmp_icon';
import Logo from 'components/cmp_logo';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TABS from 'components/cmp_tabs/cmp_tabs';
import TAB_INFO from './tab_info/tab_info';
import TAB_CREDENTIALS from './tab_credentials/tab_credentials';
import TAB_REQUIREMENTS from './tab_requirements/tab_requirements';
import TAB_ASSIGNMENTS from './tab_assignments/tab_assignments';
import TAB_SHARING from './tab_sharing/tab_sharing';

import './individual.css';

export default function INDIVIDUAL() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_tab, set_tab ] = useState('INFO');
    const [ var_individual, set_individual ] = useState({});
    const [ var_assignment, set_assignment ] = useState(null);
    const [ var_loading, set_loading] = useState(true);


    //  event listeners ------------------------------------------------------------------------------------------------
    useEffect(() => {
        window.scrollTo(0, 0);
        populate_individual();
        populate_assignment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individual() {
        set_loading(true);
        try {
            let individual = await API_get_indv_details();
            set_individual(individual);
        } catch (e) {
            if (e.response?.status < 500) {
                // Suppress the logging for client error responses ie: 4xx
            } else {
                console.log(e);
            }
        } finally {
            set_loading(false);
        }
    }

    async function populate_assignment() {
        if (!window.location.pathname.startsWith('/assignments')) return;
        set_loading(true);
        try {
            set_assignment(await API_get_assignment());
        } catch (e) {
            console.log(e);
        } finally {
            set_loading(false);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_details() {
        return API.get('org-indv', '/get-org-individual/' + params.id + (params.locationid ? '/' + params.locationid : '') );
    }

    function API_get_assignment() {
        return API.get('indv', '/get-assignments/' + params.locationid, { queryStringParameters: { tz: datelib.timezone }});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_activetab){
        set_tab(var_activetab);
    }

    function onClick_back() {
        navigate(-1);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page' id='individual'>
            <CMP_BREADCRUMB
                breadcrumb_elements={
                    (window.location.pathname.startsWith('/assignments') && var_assignment)
                        ? [
                            ...auth.has_orgaccess('ORG-LOC-ADMIN', 'menu') ? [{ path: '/assignments', text: t('Assignments') }] : [],
                            { path: '/assignments/' + params.locationid, text: var_assignment.location_name },
                            { path: '/assignments/' + params.locationid + '/' + params.tab,
                                text: params.tab === 'DAILYCHECKS'
                                ? t('Daily check')
                                : params.tab === 'DOCUMENTS'
                                ? t('Documents')
                                : params.tab === 'INDIVIDUALS'
                                ? t('Individuals')
                                : params.tab === 'REQUIREMENTS'
                                ? t('Requirements')
                                : null
                            }
                        ]
                        : (window.location.pathname.startsWith('/individuals') && auth.has_orgaccess('ORG-INDV-ADMIN', 'menu'))
                        ? [{ path: '/individuals', text: t('Individuals') }]
                        : []
                }
                current_page_text={(var_individual.firstname || '') + ' ' + (var_individual.lastname || '')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header'>
                    <h1 className='display--sm-regular'>{var_individual.firstname || ''} {var_individual.lastname || ''}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>

                    <div className='individual'>
                        <div className='individual__org__logo'>
                            <Logo targetid={var_individual.organization_id} classification='logo' filename={var_individual.organization_logo} backup_text={var_individual.organization_name} alt={var_individual.organization_name} />
                        </div>
                        <div className='individual__details'>
                            <div className='individual__avatar'>
                            </div>
                            <div className='text--xl-bold'>{
                                var_individual.firstname || var_individual.lastname
                                ? (`${var_individual.firstname || ''} ${var_individual.lastname || ''}`)
                                : (!var_loading && t('Profile'))
                            }
                            </div>
                            {(var_individual.job_title && var_individual.job_title !== '') &&
                            <div className='text--sm-regular'>
                                {var_individual.job_title}
                            </div>
                            }
                        </div>
                    </div>
                </div>

            <TABS activetab={var_tab} details_card={true} onClick={onClick_tab} aria_label={t('Individual profile')} id='tabs_workerprofile'>
                <TABS.TAB name='INFO' label={t('Info')} />
                <TABS.TAB name='CREDENTIALS' display={var_individual.org_has_credentials === 'YES'} label={t('Credentials')} />
                <TABS.TAB name='REQUIREMENTS' display={var_individual.org_has_requirements === 'YES'} label={t('Requirements')} />
                <TABS.TAB name='ASSIGNMENTS' display={var_individual.org_has_assignments === 'YES'} label={t('Assignments')} />
                <TABS.TAB name='SHARING' display={var_individual.has_share_access === true} label={t('Sharing')} />
            </TABS>

            {var_tab === 'INFO' &&
               <TAB_INFO individual={var_individual} onChange={populate_individual} isLoading={var_loading}/>
            }

                {var_tab === 'CREDENTIALS' &&
                    <TAB_CREDENTIALS individual={var_individual} location_id={params.locationid} activetab={var_tab} />
                }

                {var_tab === 'REQUIREMENTS' &&
                    <TAB_REQUIREMENTS location_id={params.locationid} individual_id={params.id} activetab={var_tab} />
                }

                {var_tab === 'ASSIGNMENTS' &&
                    <TAB_ASSIGNMENTS individual_id={params.id} location_id={params.locationid} activetab={var_tab} />
                }

                {var_tab === 'SHARING' &&
                    <TAB_SHARING individual_id={params.id} location_id={params.locationid} activetab={var_tab} />
                }
            </div>
        </div>
    )
};

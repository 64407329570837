import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_ADD_CREDENTIAL from './mdl_add_credential/mdl_add_credential'
import Processing from 'components/cmp_processing';
import 'i18n';

import './crd_approved.css';



export default function CRD_APPROVED({ requirement }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_addcredential_open, set_addcredential_open ] = useState(false);
    const [ var_deletecredential_id, set_deletecredential_id ] = useState('');
    const [ var_deletecredential_open, set_deletecredential_open ] = useState(false);
    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'credential' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        requirement.id && populate_credentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement.id]);

    useEffect(() => {
        if (!var_addcredential_open && var_focuselement?.id === 'btn_addcredential') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addcredential_open]);

    useEffect(() => {
        if (!var_deletecredential_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_reqdetails_approvedcreds').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_deletecredential_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_requirement_historical(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_credentials(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_requirement_historical(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_credentials(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_requirement_historical(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function delete_requirement_historical() {
        set_focuselement(document.getElementById('crd_reqdetails_approvedcreds'));
        set_deletecredential_open(false);
        set_processing(true);
        try {
            await API_delete_requirement_historical();
            populate_credentials();
        } catch(e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirement_historical(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-requirement-historical/' + requirement.id + '/VALID',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_delete_requirement_historical() {
        return API.del('requirements', '/delete-requirement-historical/' + var_deletecredential_id);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_addcredential(){
        set_focuselement(document.activeElement);
        set_save_error(null);
        set_addcredential_open(true);
    }

    function onClick_deletecredential(id){
        set_focuselement(document.activeElement);
        set_save_error(null);
        set_deletecredential_id(id);
        set_deletecredential_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_reqdetails_approvedcreds' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('Pre-approved credentials')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    <Button id='btn_addcredential' className='primary' onClick={onClick_addcredential}>{t('Add credential')}</Button>
                </div>
            </div>
            <div className='card__content'>
                {var_save_error &&
                    <Message
                        error icon={<Icon name='error' className='icon' />}
                        header={var_save_error}
                    />
                }

                <Table  id='credentials'
                        loading={var_loading} ready={var_ready}
                        loadingerror={var_loadingerror} lockcolumns={0}
                        refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig}
                        populatefilterfunction={populate_filters}
                        downloadname={t('credentials')}
                        downloadfunction={download_credentials}
                        onChange={populate_credentials}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='issuer' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                            <Table.HeaderCell field='credential' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_credentials.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>{item.issuer}</Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    <div>{item.credential}</div>
                                    <Button className='tertiary' onClick={() => onClick_deletecredential(item.id)}>
                                        <Icon name='delete' className='color--primary-500'  />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button id='btn_addcredential' className='primary' onClick={onClick_addcredential}>{t('Add credential')}</Button>
                </div>

                <CMP_CONFIRMATION
                    display={var_deletecredential_open}
                    title={t('Remove credential')}
                    message={
                        <div style={{flexDirection: 'column'}}>
                            <div style={{marginBottom: '0.5rem'}}>{t('Removing the pre-approval of') + ' ' + var_credentials.find(item => item.id === var_deletecredential_id)?.credential + ' ' +  t('will impact all holders of this credential who have been assigned this requirement.')}</div>
                            <div style={{marginBottom: '0.5rem'}}>{t('This may impact the individuals ability to work.')}</div>
                        </div>
                    }
                    positive_option={t('Remove')}
                    negative_option={t('Cancel')}
                    onConfirm={delete_requirement_historical}
                    onCancel={() => set_deletecredential_open(false)}
                />

                <MDL_ADD_CREDENTIAL
                    display={var_addcredential_open}
                    onClose={() => set_addcredential_open(false)}
                    requirement_id={requirement.id}
                    onChange={populate_credentials}
                />


            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import auth from 'libs/auth-lib';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TABS from 'components/cmp_tabs/cmp_tabs';
import TAB_DETAILS from './tab_details/tab_details';
import TAB_NETWORK from './tab_network/tab_network';



export default function PROFILE() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_tab, set_tab ] = useState('DETAILS');


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        if (params.tab) {
            set_tab(params.tab.toUpperCase());
        } else {
            set_tab('DETAILS');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }]}
                current_page_text={t('Profile')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{t('Profile')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <TABS activetab={var_tab} onClick={onClick_tab} aria_label={t('Profile')} id='tabs_profile'>
                    <TABS.TAB name='DETAILS' label={t('Details')} />
                    <TABS.TAB name='AFFILIATES' label={t('Affiliates')} display={auth.has_orgaccess('ORG-NETWORK', 'read')} />
                </TABS>

                {var_tab === 'DETAILS' &&
                    <TAB_DETAILS activetab={var_tab} />
                }

                {var_tab === 'AFFILIATES' &&
                    <TAB_NETWORK activetab={var_tab} />
                }
            </div>
        </div>
    )
};
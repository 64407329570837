import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import Icon from 'components/cmp_icon';
import Logo from 'components/cmp_logo';
import { useTranslation } from 'react-i18next';

import './cmp_assignment_card.css';
import { Button, Message } from 'semantic-ui-react';
import CMP_QRCODE from 'components/cmp_qrcode/cmp_qrcode';



function CMP_ASSIGNMENT_CARD({ name, address, organization_id, organization_name, logo, status, assignment_status, assignment_id, organization_host, organization_qraccess, cardtype, onClick, onKeyDown, index }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_qrdisplay, set_qrdisplay ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!var_qrdisplay && var_focuselement?.id === 'btn_qrdisplay') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    },[var_qrdisplay]);



    //  functions ------------------------------------------------------------------------------------------------------

    return cardtype === 'FULL' ? render_full() : render_compact();

    function render_full() {
        return (
            <div className={`assignment assignmentcard assignmentcard-${assignment_status.toLowerCase().replace(' ', '')}`} onClick={onClick}>
                <div className='assignment__details_wrapper'>

                    <div className='assignment__logo'>
                        <Logo targetid={organization_id} classification='logo' filename={logo} credivera_on_missing={true} credivera_on_error={true}
                            ready={!!organization_id} alt={organization_name} />
                    </div>
                    <div className='assignment__details'>
                        <div className='assignment__name_address'>
                            <div className='text--xl-bold'>
                                {name}
                            </div>
                            {address && address.length > 0 &&
                            <div>
                                <Icon name='assignments' className='assignment__icon' alt={t('Assignment address')} />
                                <div className='address__text'>{address}</div>
                            </div>
                            }
                            <div className='assignment__badge'>
                                {
                                    assignment_status === 'READY' ?
                                        <div className='badge text--sm-medium badge--green'>
                                            <Icon name='checkmark' className='badge__icon--left' alt='' />
                                            {t('Conditions met')}
                                        </div>
                                    : assignment_status === 'NOT READY' ?

                                        <div className='badge text--sm-medium badge--red'>
                                            <Icon name='error' className='badge__icon--left' alt='' />
                                            {t('Conditions not met')}
                                        </div>

                                    : null
                                }
                            </div>
                        </div>
                        {organization_qraccess === 'YES' &&
                            <Button id='btn_qrdisplay' className='secondary text--anchor qrbutton' onClick={() => {set_qrdisplay(true); set_focuselement(document.activeElement);}}>
                                {t('QR code')}
                                <Icon name='qr_code' className='badge__icon--right' alt='' />
                            </Button>
                        }
                    </div>
                    {(assignment_status === 'NOT ASSIGNED' && status !== 'DRAFT') &&
                        <Message
                            warning
                            content={t('You are not currently part of this assignment. If assigned, you must fulfill all mandatory requirements.')}
                        />
                    }
                    {status === 'DRAFT' &&
                        <Message
                            warning
                            content={t('This assignment is in draft status.')}
                        />
                    }
                </div>
                <CMP_QRCODE
                    display={var_qrdisplay}
                    name={name}
                    qr_value={organization_host + '/login?location_id=' + assignment_id}
                    title={t('QR access code')}
                    content={t('Scan the QR code to access the assignment.')}
                    onClose={() => set_qrdisplay(false)}
                />
            </div>
        );
    }

    function render_compact() {
        return (
            <div id={`Card${index}`} tabIndex='0' role='link' className={`launchcard ${assignment_status === 'READY' ? 'launchcard--green' : 'launchcard--red'}`} onClick={onClick} onKeyDown={onKeyDown}>
                <div className='launchcard__icon'>
                    {assignment_status === 'READY' ?
                        <Icon name='checkmark__whiteBG' alt='' />
                        :
                        <Icon name='alert__whiteBG' alt='' />
                    }
                </div>
                <div className='launchcard__text'>
                    <div className='text--md-medium'>{name}</div>
                    <div className='text--sm-regular'>{organization_name}</div>
                    <div className='text--sm-regular'>{assignment_status === 'READY' ? t('Conditions met') : t('Conditions not met')}</div>
                </div>
                <div className='launchcard__chevron'>
                    <Icon name='chevron_right' alt='' />
                </div>
            </div>
        );
    }
}

CMP_ASSIGNMENT_CARD.propTypes = {
    name: propTypes.string.isRequired,
    address: propTypes.string,
    organization_id: propTypes.string.isRequired,
    organization_name: propTypes.string.isRequired,
    logo: propTypes.string,
    assignment_status: propTypes.oneOf([ 'READY', 'NOT READY', 'NOT ASSIGNED' ]).isRequired,
    status: propTypes.oneOf([ 'ACTIVE', 'DRAFT', 'ARCHIVE' ]).isRequired,
    cardtype: propTypes.oneOf([ 'FULL', 'COMPACT' ]).isRequired,
    onClick: propTypes.func,
    onKeyDown: propTypes.func,
    index: propTypes.number
};

export default CMP_ASSIGNMENT_CARD;
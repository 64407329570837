import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Popup, Form } from 'semantic-ui-react';
import { CHECKBOX } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import 'i18n';


export default function CMP_AFFILIATE_STATUS({ affiliate_details, is_open, onClose, onOpen, onChange, onMessage }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_popup, set_popup ] = useState(null);
    const [ var_statuspopup_value, set_statuspopup_value ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const POPUP_ID = (`mdl_affiliate_status_${affiliate_details?.id}`);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open) {
            set_popup(document.querySelector(`#${POPUP_ID}`));
        } else if (!is_open && var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_popup) {
            //  create list of focusable elements within the popup
            let elements = var_popup.querySelectorAll('form, input[type="checkbox"]:not([disabled]), textarea:not([disabled]), button:not([disabled])');
            let firstelement = elements[0];
            let lastelement = elements[elements.length - 1];

            //  set focus to first element within the popup
            firstelement.focus({ preventScroll: true });

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_popup.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_popup]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function resend_invite() {
        set_processing(true);
        try {
            await API_post_resend_network_invite();
            onChange && onChange(var_focuselement?.id, 'SUCCESS', t('Invitation resent successfully.'));
        }  catch (e) {
            console.log(e);
        }
        set_processing(false);
    }

    async function edit_invitation(status) {
        set_processing(true);
        try {
            await API_put_org_network(status);
            onChange && onChange(var_focuselement?.id);
        } catch(e) {
            console.log(e);
        }
        set_processing(false);
    }

    async function cancel_invitation() {
        set_processing(true);
        try {
            await API_delete_network_invite();
            onChange && onChange(var_focuselement?.id);
        } catch(e) {
            console.log(e);
        }
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_resend_network_invite() {
        return API.post('org-profile', '/post-resend-network-invite/' + affiliate_details.id);
    }

    function API_put_org_network(status) {
        return API.put('org-profile', '/put-org-network/' + affiliate_details.id + '/' + status);
    }

    function API_delete_network_invite() {
        return API.del('org-profile', '/delete-network-invite/' + affiliate_details.id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_status() {
        set_focuselement(document.activeElement);
    }

    function onClick_updatestatus() {
        onMessage(null, null);
        switch (var_statuspopup_value) {
            case 'RESEND':
                resend_invite();
                break;
            case 'ACCEPT':
                edit_invitation('CONNECTED');
                break;
            case 'REJECT':
                edit_invitation('REJECTED');
                break;
            case 'CANCEL':
                cancel_invitation();
                break;
            default:
                onMessage('ERROR', t('Please select a status'));
                break;
        }
    }

    // RENDER APP ======================================================================================================

    return (
        (affiliate_details.status === 'Connected')

        ?   <div id={`btn_${POPUP_ID}`} tabIndex='0' className='badge text--sm-medium badge--green'>
                {affiliate_details.status_display}
            </div>
        :
        <Popup trigger={
            <Button id={`btn_${POPUP_ID}`} onClick={onClick_status} type='button' className={'badge text--sm-medium ' + ((affiliate_details.status === 'Pending response' || affiliate_details.status === 'Requested') ? 'badge--yellow' : affiliate_details.status === 'Rejected' ? 'badge--red' : 'badge--gray')}>
                {affiliate_details.status_display}
                <Icon name='badge_dropdown' className='badge__icon' />
            </Button>}
                content={
                    is_open
                        ?   <div className='badge__popup shadow-lg rounded-md' id={POPUP_ID} aria-modal='true' role='dialog' aria-labelledby={`hdr_${POPUP_ID}`} aria-label={t('Affiliate status modal')}>
                                <div id={`hdr_${POPUP_ID}`} className='text--md-medium' aria-label={t('Update affiliate status')}>{t('Change status')}</div>
                                <Form tabIndex='0' aria-labelledby={`hdr_${POPUP_ID}`}>
                                    {affiliate_details.status === 'Rejected' ?
                                    <>
                                        <div className='badge__popup__option'>
                                            <CHECKBOX radio name='changestatus' label={t('Resend invitation')} value='RESEND' checked={var_statuspopup_value === 'RESEND'} onChange={() => set_statuspopup_value('RESEND')}/>
                                        </div>
                                        <div className='badge__popup__option'>
                                            <CHECKBOX radio name='changestatus' label={t('Cancel invitation')} value='CANCEL' checked={var_statuspopup_value === 'CANCEL'} onChange={() => set_statuspopup_value('CANCEL')}/>
                                        </div>
                                    </>
                                    : affiliate_details.status === 'Pending response' && affiliate_details.assignment_name !== 'NA' ?
                                        <>
                                            <div className='badge__popup__option'>
                                                <CHECKBOX radio name='changestatus' label={t('Resend invitation')} value='RESEND' checked={var_statuspopup_value === 'RESEND'} onChange={() => set_statuspopup_value('RESEND')}/>
                                            </div>
                                            <div className='badge__popup__option'>
                                                <CHECKBOX radio name='changestatus' label={t('Cancel invitation')} value='CANCEL' checked={var_statuspopup_value === 'CANCEL'} onChange={() => set_statuspopup_value('CANCEL')}/>
                                            </div>
                                        </>
                                    : affiliate_details.status === 'Requested' ?
                                            <>
                                                <div className='badge__popup__option'>
                                                    <CHECKBOX radio name='changestatus' label={t('Accept invitation')} value='ACCEPT' checked={var_statuspopup_value === 'ACCEPT'} onChange={() => set_statuspopup_value('ACCEPT')}/>
                                                </div>
                                                <div className='badge__popup__option'>
                                                    <CHECKBOX radio name='changestatus' label={t('Reject invitation')} value='REJECT' checked={var_statuspopup_value === 'REJECT'} onChange={() => set_statuspopup_value('REJECT')}/>
                                                </div>
                                        </>
                                    : null
                                    }
                                    <Button type='button' className='badge__popup__button secondary' onClick={onClick_updatestatus}>{t('Update')}</Button>
                               </Form>
                               <Processing display={var_processing} processingtext='' />
                           </div>
                       : null
               }
               on='click'
               open={is_open}
               onOpen={() => onOpen(affiliate_details.id)}
               onClose={onClose}
               hideOnScroll
               position='bottom left' />
    );
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import { Form, Modal, Button } from 'semantic-ui-react';
import 'i18n';
import { FORM_SELECT, FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';


export default function MDL_ADD_CREDENTIAL({ display, onClose, requirement, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_selected_issuer, set_selected_issuer ] = useState(null);
    const [ var_issuer_options, set_issuer_options ] = useState([]);
    const [ var_selected_credentials, set_selected_credentials ] = useState([]);
    const [ var_credential_options, set_credential_options ] = useState([]);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);

    const [ var_errors, set_errors ] = useState([]);
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('#form_cr8requirement_approvedcreds, button:not([disabled]), input[type="text"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            // reset and populate issuers
            set_modal(document.querySelector('#mdl_add_approvedcreds'));
            set_selected_credentials([]);
            set_credential_options([]);
            set_selected_issuer(null);
            set_errors([]);
            populate_issuer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        var_selected_issuer && populate_credentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_selected_issuer]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_issuer() {
        let results = await API_get_issuer_list();
        set_issuer_options(results.map(item => ({ value: item.id, text: item.name })));
    }

    async function populate_credentials() {
        let results = await API_get_preapproval_credential_list(var_selected_issuer);
        if (results) {
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: item.name,
                disabled: requirement.approved.some(credential => credential.credential_id === item.id) || requirement.rejected.some(credential => credential.credential_id === item.id),
                issuer_name: item.issuer_name,
                issuer_id: item.issuer_id
            }));
            set_credential_options(transformed);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_issuer_list() {
        return API.get('credentials', '/get-issuer-list');
    }

    function API_get_preapproval_credential_list() {
        return API.get('requirements', '/get-preapproval-credential-list/' + var_selected_issuer);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_issuer(event, { value }) {
        set_selected_issuer(value);
        if (var_errors.length > 0) {
            const errors = var_errors.filter(error => error.property !== 'issuer')
            set_errors(errors);
        }
    }

    function onChange_credential(value) {
        set_selected_credentials(value);
        if (var_errors.length > 0) {
            const errors = var_errors.filter(error => error.property !== 'credentials')
            set_errors(errors);
        }
    }

    function onClick_add() {
        let updated_requirement = { ...requirement };
        let errors = [];
        // validation
        if (!var_selected_issuer) {
            errors.push({property: 'issuer', description: t('Please select an issuer')});
        } else if (var_selected_credentials.length === 0) {
            errors.push({property: 'credentials', description: t('Please select at least one credential')});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        let selected_credentials = var_credential_options.filter(item => var_selected_credentials.includes(item.value));
        for (let credential of selected_credentials) {
            updated_requirement.approved = [...updated_requirement.approved,
                {
                    credential_id: credential.value,
                    credential_name: credential.primary_text,
                    issuer_id: credential.issuer_id,
                    issuer_name: credential.issuer_name
                }
            ];
        }

        onChange(updated_requirement);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_add_approvedcreds'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_cr8requirement_approved'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_cr8requirement_approved'>{t('Select credentials')}</div>
                </div>
            </div>

            <Form className='modal__content' tabIndex='0' id='form_cr8requirement_approvedcreds' aria-labelledby='hdr_cr8requirement_approved'>
                <FORM_SELECT
                    property='issuer'
                    label={t('Issuer')}
                    value={var_selected_issuer || ''}
                    onChange={onChange_issuer}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('Issuer')}
                    options={var_issuer_options}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_SELECT_RADIO
                    name='credentials'
                    property='credentials'
                    label={t('Credential')}
                    value={var_selected_credentials}
                    options={var_credential_options}
                    single_or_multiple='MULTIPLE'
                    onChange={onChange_credential}
                    placeholder={t('Search')}
                    disabled={requirement.rejected.some(item => item.issuer_id === var_selected_issuer && !item.credential_id)}
                    errors={var_errors}
                    aria_label={t('Select the credentials that should be automatically accepted to fulfill this requirement.')}
                />
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

        </Modal>
    );

}
import React, { useEffect, useState } from 'react';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { CHECKBOX } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';



function MDL_EDIT_PERMISSION({ is_open, credential, onClose, selected_permission_id, selected_permission, role_individual, role_individual_permission, onChange, permission_options }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const [ var_permissions, set_permissions ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_modal, set_modal ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open && selected_permission) {
            // reset and populate options
            set_modal(document.querySelector('#mdl_edit_permission'));
            set_save_error(null);
            populate_permission();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open, selected_permission]);

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    function populate_permission() {
        let permissions = {};
        permission_options.forEach(item => permissions[item.code] = JSON.parse(selected_permission).includes(item.code));
        set_permissions(permissions);
    }

    function update_access() {
        if (credential.permissions.filter(item => item.access.includes('security')).length === 1 &&
            credential.permissions.find(item => item.access.includes('security')).id === selected_permission_id &&
            !var_permissions.security) {
            set_save_error(t('This credential requires at least one role or individual with permission to modify its security settings.'));
            return;
        }
        onChange(var_permissions);
    }

    function onChange_permissions(permission_item) {
        let permissions = {...var_permissions};
        permissions[permission_item] = !permissions[permission_item];
        set_permissions(permissions);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_edit_permission'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_edit_permission'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_edit_permission'>{t('Edit permissions')}</div>
                    <div className='text--sm-regular'>{role_individual}</div>
                </div>
                <div className='modal__header__right'>
                    <div className='modal__header__closeicon color--primary-500'>
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                    </div>
                </div>
            </div>

            <Form className='modal__content' tabIndex='0' aria-labelledby='hdr_edit_permission'>
                {var_save_error &&
                    <Message error header={var_save_error} />
                }
                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>{t('Permissions')}</div>
                    <div className='detailsgroup__value column text--sm-regular'>
                    {permission_options.map(item =>
                        <CHECKBOX name='updatepermission' key={'checkbox-' + item.code} label={item.description} value={item.code} checked={var_permissions[item.code]} onChange={() => onChange_permissions(item.code)}
                        disabled={item.code === 'read' ||
                            (role_individual_permission === 'Manager' && item.code !== 'view attachment') ||
                            ((role_individual_permission === 'Admin' || role_individual_permission === 'Global Admin') && item.code === 'security')} />
                    )}
                    </div>
                </div>
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={update_access}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>
        </Modal>
    );
}

export default MDL_EDIT_PERMISSION;
import React from 'react';
import propTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { FORM_TEXTAREA } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './content_attestation.css';

/*******************************************************************
 * Note, this renders the form inputs for parent <Form> element within mdl_prequalifer.js
 *******************************************************************/
function CONTENT_ATTESTATION({ prequalifier, errors, credentialstatus, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');



    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event, { name, value }) {
        let updated = { ...prequalifier };
        updated[name] = value;
        onChange(updated, name);
    }


    // RENDER APP ======================================================================================================

    return (
        <div id='content_attestation' className='content__wrapper'>
            {(credentialstatus === 'ACTIVE' && prequalifier.prequalifier_id !== undefined)
                ? <Message warning
                    icon={<Icon name='warning' className='warning-icon--yellow' />}
                    content={t('Modifying this qualifier will require all holders of this credential to complete the updated attestation. All ‘valid’ credentials will change to ‘In-progress’ until the attestation has been completed. This action can not be undone.')}
                  />
                : credentialstatus === 'ACTIVE'
                ? <Message warning
                    icon={<Icon name='warning' className='warning-icon--yellow' />}
                    content={t('Adding this qualifier will require all holders of this credential to complete the new attestation. All ‘valid’ credentials will change to ‘In-progress’ until the attestation has been completed. This action can not be undone.')}
                  />
                : null
            }

            <div className='content__description text--sm-medium'>{t('This qualifier enables capturing a declaration from an individual.')}</div>

            <FORM_TEXTAREA
                property='short_description'
                label={t('Description')}
                value={prequalifier.short_description}
                onChange={onChange_input}
                placeholder={t('Description')}
                maxLength={200}
                disabled={false}
                errors={errors}
                description={t('The description is displayed to the user upon claiming the credential. It serves as a guide, providing context and clear instructions on how to fulfill the qualifying requirement successfully.')}
            />

            <FORM_TEXTAREA
                property='long_description'
                label={t('Detailed description')}
                value={prequalifier.long_description}
                onChange={onChange_input}
                placeholder={t('Detailed description')}
                maxLength={1000}
                disabled={false}
                errors={errors}
                description={t('The detailed description offers supplementary information about the attestation being requested. It is presented to the individual when they opt to complete the attestation process, providing a clearer understanding of the acknowledgement they are being asked to make.')}
            />

            <FORM_TEXTAREA
                property='attestation_text'
                label={t('Checkbox label')}
                value={prequalifier.attestation_text}
                onChange={onChange_input}
                placeholder={t('Checkbox label')}
                maxLength={200}
                disabled={false}
                errors={errors}
                description={t('An explicit acknowledgement of the attestation is captured through a provided checkbox.')}
            />

        </div>
    );

}

CONTENT_ATTESTATION.propTypes = {
    prequalifier: propTypes.object,
    errors: propTypes.arrayOf(propTypes.object),
    credentialstatus: propTypes.string,
    onChange: propTypes.func
};

export default CONTENT_ATTESTATION;
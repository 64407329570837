import React from 'react';

import CRD_DETAILS from './crd_details/crd_details';
import CRD_CREDENTIALS from './crd_credentials/crd_credentials';
import CRD_CREDENTIALSATTACHMENTS from './crd_credentialsAttachments/crd_credentialsAttachments';

import './profile.css';


export default function CRD_PROFILE({ individual, report_info, token }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
            <div className='profile_wrapper'>
                <CRD_DETAILS
                    var_detail={individual.details}
                    report_info={report_info}
                />
                <div className='profile_content'>
                    {individual.credentials &&
                        <CRD_CREDENTIALS
                            credentials={individual.credentials}
                            token={token}
                        />
                    }
                    {individual.credentials &&
                        <CRD_CREDENTIALSATTACHMENTS
                            credentials={individual.credentials}
                            token={token}
                        />
                    }
                </div>
            </div>
    )
};

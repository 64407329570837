import React, { useEffect } from 'react';

import CRD_CREDENTIALS from './crd_credentials/crd_credentials';



export default function TAB_CREDENTIALS({ transcript }) {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            <CRD_CREDENTIALS transcript={transcript} />
        </>
    )
};

import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Button, Modal, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import 'i18n';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';

import './cmp_oid4vc_credential_offer.css';


function CMP_OID4VC_CREDENTIAL_OFFER({ display, title, instructions_line1, instructions_line2, instructions_line3, error_message, target, processing = false, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const [ var_modal, set_modal ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {
            //  set focus to first element within the modal
            var_modal.querySelector('.modal__content').focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    let elements = var_modal.querySelectorAll('.modal__content, button:not([disabled])');
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === elements[0]) {
                            elements[elements.length - 1].focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === elements[elements.length - 1]) {
                            elements[0].focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#cmp_oid4vc_credential_offer'));
        }
    }, [display]);


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='cmp_oid4vc_credential_offer'>

            <div className='modal__header'>
                <div>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium'>{title}</div>
                    </div>
                </div>
            </div>

            <div className='modal__content'>
                {error_message &&
                    <Message error
                        icon={<Icon name='error' className='message__error__icon' />}
                        header={error_message}
                    />
                }
                <div className='modal__content__text text--sm-regular'>
                    {instructions_line1 && <div>{instructions_line1}</div>}
                    {instructions_line2 && <div>{instructions_line2}</div>}
                    {instructions_line3 && <div>{instructions_line3}</div>}
                </div>
                {target &&
                    <QRCodeSVG className='qr_svg'
                        value={target}
                        size={200}
                    />
                }
            </div>

            <div className='modal__footer'>
                <div>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>
            <Processing display={processing} processingtext={t('Processing')} />
        </Modal>
    );

}

CMP_OID4VC_CREDENTIAL_OFFER.propTypes = {
    display: propTypes.bool.isRequired,
    title: propTypes.string,
    instructions_line1: propTypes.string,
    instructions_line2: propTypes.string,
    instructions_line3: propTypes.string,
    error_message: propTypes.string,
    target: propTypes.string,
    processing: propTypes.bool,
    onClose: propTypes.func
};

export default CMP_OID4VC_CREDENTIAL_OFFER;

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import API from 'libs/api-lib';

function Processing({ display, processingtext = 'Processing', batch_id, onComplete, style }) {
    //  variable declarations ------------------------------------------------------------------------------------------
    const [ var_progress, set_progress ] = useState(0);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_progress(0);
        if (batch_id) {
            const interval = setInterval(() => {
                check_status();
            }, 1500); // 1.5 seconds
            return () => clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batch_id]);

    //  functions ------------------------------------------------------------------------------------------------------

    async function check_status() {
        if (var_progress >= 100) return;

        let result = await API_get_batch_process_status();
        set_progress(Math.floor(result.processed_items / result.total_items * 100));
        if (result.processed_items === result.total_items) {
            onComplete && onComplete(result.total_items, result.result_count);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_batch_process_status() {
        return API.get('admin', '/get-batch-process-status/' + batch_id);
    }

    // RENDER APP ======================================================================================================


    return (
        <>
            {display &&
                <div className='preloader__wrapper rounded-lg' style={style}>
                    <div className='preloader__text text--xs-bold'>
                        <div>{processingtext}</div>
                        {batch_id && <div>{var_progress}%</div>}
                    </div>
                    <div className='spinner__wrapper'>
                        <div className='spinner'></div>
                    </div>
                </div>
            }
        </>
    );
}

Processing.propTypes = {
    display: propTypes.bool.isRequired,
    processingtext: propTypes.string,
    batch_id: propTypes.string,
    onComplete: propTypes.func
};

export default Processing;
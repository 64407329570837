import React, { useState, useEffect } from 'react';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Processing from 'components/cmp_processing';
import form_helper from 'libs/form-lib';

import './mdl_update_expiry.css';

import { FORM_DATEPICKER, CHECKBOX } from 'components/cmp_form/cmp_form';

export default function MDL_UPDATE_EXPIRY({
                                    display,
                                    onClose,
                                    onLinkUpdate,
                                    report,
                                    report_category,
                                    individual_id,
                                    assignment_id
                                }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_expiry_timestamp, set_expiry_timestamp ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_donot_expire, set_donot_expire ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(()=>{
        if (display) {
            // reset variables
            set_focuselement(document.activeElement);
            set_modal(document.querySelector('#mdl_update_expiry'));
            set_expiry_timestamp(null);
            set_donot_expire(false);
            set_save_error(false);
            set_errors([]);
            report && report.expiry_timestamp && set_expiry_timestamp(datelib.epoch_to_utcmoment(report.expiry_timestamp)._d);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[display]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function update_expiry() {
        set_processing(true);
        try {
            await API_put_external_report();
            onLinkUpdate(var_focuselement?.id);
            onClose();
        } catch (exception) {
            console.log(exception);
            set_save_error(t('An error has occurred.'));
        } finally {
            set_processing(false);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------


    function API_put_external_report() {
        if (assignment_id) {
            return API.put('external-report', '/put-external-report/' + report.id + '/' + report_category + '/' + individual_id + '/' + assignment_id,
            {
                body: {
                    expiry_timestamp: var_donot_expire || !var_expiry_timestamp ? null : datelib.date_to_epoch(var_expiry_timestamp)
                }
            });
        } else {
            return API.put('external-report', '/put-external-report/' + report.id + '/' + report_category + (individual_id ? '/' + individual_id : ''),
            {
                body: {
                    expiry_timestamp: var_donot_expire || !var_expiry_timestamp ? null : datelib.date_to_epoch(var_expiry_timestamp)
                }
            });
        }
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_save() {
        let errors = [];
        //validation
        if (!var_donot_expire && var_expiry_timestamp) {
            let date_validation = form_helper.validate_date(var_expiry_timestamp, datelib.date_to_midnight_date(new Date()), null);
            if (date_validation.is_less_than_minimum) {
                errors.push({ property: 'expiry_timestamp', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
            } else if (date_validation.is_greater_than_maximum) {
                errors.push({ property: 'expiry_timestamp', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
            }
        }
        set_errors(errors);
        if (errors.length > 0) return;
        update_expiry();
    }

    function onChange_date(e, { value }) {
        set_expiry_timestamp(value);
        set_errors([]);
    }

    function onChange_donot_expire() {
        set_donot_expire(!var_donot_expire);
        set_errors([]);
    }


    // RENDER APP ======================================================================================================


    return (
        <Modal
            dimmer={'inverted'}
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            className='mdl__update_expiry'
            id='mdl_update_expiry'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_update_expiry'
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_update_expiry'>{t('Edit expiry date')}</div>
                </div>
            </div>

            <Form className='modal__content' tabIndex='0' aria-labelledby='hdr_update_expiry'>
                {var_save_error &&
                    <Message error
                        header={var_save_error}
                    />
                }

                <div style={{ flexDirection: 'column' }}>
                    <div className='text--sm-medium'>{t('Expiry date')}</div>
                    <FORM_DATEPICKER
                        property='expiry_timestamp'
                        value={var_expiry_timestamp}
                        onChange={onChange_date}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('YYYY-MM-DD')}
                        minproperty={datelib.date_to_midnight_date(new Date())}
                        maxproperty={null}
                        disabled={var_donot_expire}
                        errors={var_errors}
                    />
                </div>
                <CHECKBOX name='donot_expire' label={t('Remove expiry date')} checked={var_donot_expire} onChange={onChange_donot_expire} />

            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}
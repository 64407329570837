
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import form_helper from 'libs/form-lib';
import { Button, Message } from 'semantic-ui-react';
import { FORM_TEXTAREA, FORM_TOGGLE, INPUT, CHECKBOX } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from 'components/cmp_icon';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_ADD_CREDENTIAL from './mdl_addcredential/mdl_addcredential';

import './content_credential.css';

/*******************************************************************
 * Note, this renders the form inputs for parent <Form> element within mdl_prequalifer.js
 *******************************************************************/
function CONTENT_CREDENTIAL({ prequalifier, errors, credentialstatus, has_verifiable_credential_feature, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_last_new_id, set_last_new_id ] = useState('0');
    const [ var_addcredential_open, set_addcredential_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_deletecredential_id, set_deletecredential_id ] = useState(null);
    const [ var_deletecredential_open, set_deletecredential_open ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!prequalifier.creditbased) {
            let updated = { ...prequalifier };
            updated.creditbased = !!updated.credits_required ? 'YES' : 'NO';
            onChange(updated);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_addcredential_open && var_focuselement?.id === 'btn_addcredential') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    },[var_addcredential_open]);

    useEffect(() => {
        if (!var_deletecredential_open && var_focuselement?.id === 'selectcredential') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    },[var_deletecredential_open]);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event, { name, value }) {
        let updated = { ...prequalifier };
        updated[name] = value;
        let property_names = [ name ];
        if (name === 'is_verifiable_presentation') {
            if (updated.preapprovals.some(item => item.id)) {
                if (updated.deleted_preapprovals === undefined) {
                    updated.deleted_preapprovals = [];
                }
                updated.preapprovals.filter(item => item.id).forEach(item => updated.deleted_preapprovals.push(item.id));
            }
            updated.preapprovals = [];
            property_names.push('creditbased');
            property_names.push('credits_required');
            property_names.push('credit_value');
            property_names.push('credit_threshold');
            property_names.push('preapprovals');
        }
        if (name === 'creditbased') {
            property_names.push('credits_required');
            property_names.push('credit_value');
            property_names.push('credit_threshold');
        }
        onChange(updated, property_names);
    }

    function onChange_save_credit_value() {
        let updated = { ...prequalifier };
        updated.save_credit_value = updated.save_credit_value === 'YES' ? 'NO' : 'YES';
        onChange(updated, ['save_credit_value', 'credit_threshold']);
    }

    function onChange_creditsrequired(event, { value }) {
        let updated = { ...prequalifier };
        value = form_helper.format_decimal_string(value, 3, 2);
        updated.credits_required = value.length === 0 ? null : value;
        onChange(updated, ['credits_required', 'credit_threshold']);
    }

    function onChange_credentialvalue(event, value, id) {
        let updated = { ...prequalifier };
        value = form_helper.format_decimal_string(value, 3, 2);
        updated.preapprovals.find(item => item.id === id).credit_value = value.length === 0 ? null : value;
        onChange(updated, ['credit_value', 'credit_threshold']);
    }

    function onDelete_credential(id) {
        set_focuselement(document.getElementById('selectcredential'));
        set_deletecredential_id(id);
        set_deletecredential_open(true);
    }

    function onConfirm_delete() {
        let updated = { ...prequalifier };
        updated.preapprovals = updated.preapprovals.filter(item => item.id !== var_deletecredential_id);
        if (var_deletecredential_id.length === 36) {
            if (updated.deleted_preapprovals === undefined) {
                updated.deleted_preapprovals = [];
            }
            updated.deleted_preapprovals.push(var_deletecredential_id);
        }
        onChange(updated, ['preapprovals', 'credit_value']);
        set_deletecredential_open(false);
    }

    function onClick_addcredential() {
        set_focuselement(document.activeElement);
        set_addcredential_open(true);
    }

    function onCredentialAdded(issuer_id, issuer_name, credential_ids, credential_list) {
        let last_id = Number(var_last_new_id);
        let updated = { ...prequalifier };
        for (let credential_id of credential_ids) {
            last_id++;
            let credential = credential_list.find(item => item.id === credential_id);
            updated.preapprovals.push({ id: String(last_id), issuer_id, issuer_name, credential_id, credential_name: credential.name, credit_value: credential.credit_value, footprint_credit_value: credential.footprint_credit_value });
        }
        set_last_new_id(last_id);
        onChange(updated, ['preapprovals', 'credit_threshold']);
        set_addcredential_open(false);
    }



    // RENDER APP ======================================================================================================

    return (
        <div id='content_credential' className='content__wrapper'>

            {(credentialstatus === 'ACTIVE' && prequalifier.prequalifier_id !== undefined && prequalifier.is_verifiable_presentation === 'YES')
                ? <Message warning
                    icon={<Icon name='warning' className='warning-icon--yellow' />}
                    content={t(`Modifying the credential is disabled as changing this pre-qualifier won't affect the verification status of past holders. To ensure consistent updates to both current and future holders, create a new pre-qualifier and then remove the old one.`)} />
                : (credentialstatus === 'ACTIVE' && prequalifier.prequalifier_id !== undefined)
                ? <Message warning
                    icon={<Icon name='warning' className='warning-icon--yellow' />}
                    content={t(`Modifying this qualifier may require some holders to submit a new credential. 'Valid' credentials will change to 'In-progress' for those impacted until a new credential has been submitted. This action can not be undone.`)}
                  />
                : credentialstatus === 'ACTIVE'
                ? <Message warning
                    icon={<Icon name='warning' className='warning-icon--yellow' />}
                    content={t(`Adding this qualifier will require holders to submit a credential. 'Valid' credentials will change to 'In-progress' for those until a credential has been submitted. This action can not be undone.`)}
                  />
                : null
            }

            {errors.some(item => item.property === 'credit_threshold') &&
                <Message error
                    icon={<Icon name='error' className='icon' />}
                    content={errors.find(item => item.property === 'credit_threshold').description}
                />
            }

            <div className='content__description text--sm-medium'>{t('This qualifier allows for specifying another credential as a pre-requisite for obtaining this credential.')}</div>

            {has_verifiable_credential_feature === 'YES' &&
                <FORM_TOGGLE
                    label={t('Verifiable presentation')}
                    value={prequalifier.is_verifiable_presentation}
                    options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                    onChange={(value) => onChange_input(null, { name: 'is_verifiable_presentation', value })}
                    description={t(`A verifiable presentation (VP) is a digital proof that someone has specific credentials, but without capturing all of the private details contained within them. It's like showing a key piece of information from a document without sharing the entire content.\n\nThis option cannot be removed after publishing.`)}
                    disabled={credentialstatus === 'ACTIVE' && prequalifier.prequalifier_id !== undefined}
                />

            }
            {prequalifier.is_verifiable_presentation === 'NO' &&
                <>
                    <FORM_TOGGLE
                        label={t('Credit-based')}
                        value={prequalifier.creditbased}
                        options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                        onChange={(value) => onChange_input(null, { name: 'creditbased', value })}
                        description={t('A credit-based pre-qualifier necessitates the submission of a specific number of credentials to fulfill a predetermined credit threshold. Upon meeting this threshold, the pre-qualifier will be automatically marked as complete.')}
                        instructions=''
                        disabled={credentialstatus === 'ACTIVE' && prequalifier.prequalifier_id !== undefined}
                    />
                    {prequalifier.creditbased === 'YES' &&
                        <div className='detailsgroup__subgroup rounded-lg'>
                            {errors.some(item => item.property === 'credits_required') &&
                                <Message error
                                    icon={<Icon name='error' className='icon' />}
                                    content={errors.find(item => item.property === 'credits_required').description}
                                />
                            }
                            <div className='detailsgroup__subgroup__description text--sm-regular'>{t('Please indicate the exact number of credits that must be submitted to fulfill this pre-qualifier requirement.')}</div>
                            <div className='detailsgroup__subgroup__content'>
                                <div id='grp__required_credits' className='detailsgroup__subgroup__content__item'>
                                    <div className='text--sm-medium'>{t('Required credits')}</div>
                                    <INPUT
                                        property='credits_required'
                                        value={[null, undefined].includes(prequalifier.credits_required) ? '' : String(prequalifier.credits_required)}
                                        onChange={onChange_creditsrequired}
                                        placeholder='Number'
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </>
            }

            <div className='detailsgroup'>
                <div className='detailsgroup__label text--sm-medium'>{t('Credential')}</div>
                <div className='field detailsgroup__value'>
                    {prequalifier.is_verifiable_presentation === 'YES'
                        ?
                        <div className='detailsgroup__description text--sm-regular'>
                            {t('Add a verifiable credential that is approved to meet this qualifying requirement. All other credentials will be rejected.')}
                        </div>
                        :
                        <div className='detailsgroup__description text--sm-regular'>
                            {t('Add credentials that are approved to meet this qualifier. All other credentials will be rejected.')}
                        </div>
                    }
                    {prequalifier.is_verifiable_presentation === 'NO' && prequalifier.creditbased === 'YES' &&
                        <div className='detailsgroup__description text--sm-regular credit__based__note'>
                            <span className='text--sm-bold'>{t('Note') + ': '}</span>{t('By default, each credential will have a value of 1 credit unless the issuer specifies otherwise. You can modify this value after adding the credential.')}
                        </div>
                    }
                    <div className='dropzone__fileupload credentials__wrapper'>
                        <Icon name='credential2' className='icon__upload' />
                        <div>
                            <Button id='btn_addcredential' type='button' className='tertiary button__browse' disabled={prequalifier.is_verifiable_presentation === 'YES' && prequalifier.preapprovals.length !== 0} onClick={onClick_addcredential}>
                                {prequalifier.is_verifiable_presentation === 'YES' ? t('Add approved credential') : t('Add approved credentials')}
                            </Button>
                        </div>

                        {errors.some(item => item.property === 'credit_value') &&
                            <Message error
                                icon={<Icon name='error' className='icon' />}
                                content={errors.find(item => item.property === 'credit_value').description}
                            />
                        }

                        {prequalifier.is_verifiable_presentation === 'NO' && prequalifier.creditbased === 'YES' && prequalifier.preapprovals.length > 0 &&
                            <div className='credentials__wrapper__header text--sm-medium'>
                                <div>{t('Credential')}</div>
                                <div>{t('Credit value')}</div>
                            </div>
                        }
                        {prequalifier.preapprovals.map(item =>
                            <div className={'credentials__item cell__icon--right' + ((prequalifier.is_verifiable_presentation === 'NO' && prequalifier.creditbased === 'YES') ? ' creditbased' : '' )} key={item.id}>
                                <div className='credentials__item_text'>
                                    <div className='text--xs-medium'>{item.credential_name}</div>
                                    <div className='text--xs-regular'>{item.issuer_name}</div>
                                </div>
                                {prequalifier.is_verifiable_presentation === 'NO' && prequalifier.creditbased === 'YES' &&
                                    <div className='credential__item__value'>
                                        <INPUT
                                            property='credit_value'
                                            value={item.credit_value === null ? '' : String(item.credit_value)}
                                            onChange={(e, { value }) => onChange_credentialvalue(e, value, item.id)}
                                            placeholder='1'
                                        />
                                    </div>
                                }
                                {(credentialstatus !== 'ACTIVE' || prequalifier.prequalifier_id === undefined || prequalifier.is_verifiable_presentation !== 'YES') &&
                                    <Icon name='delete' className='color--primary-500' onClick={() => onDelete_credential(item.id)}/>
                                }
                            </div>
                        )}
                    </div>
                    {errors.some(item => item.property === 'preapprovals') &&
                        <div className='field__feedback error'>
                            <div className='description text--sm-regular'>{errors.find(item => item.property === 'preapprovals').description}</div>
                        </div>
                    }
                    {(prequalifier.is_verifiable_presentation === 'NO' && prequalifier.creditbased === 'YES') &&
                        <CHECKBOX name='save_credit_value' label={t('Save credit values. Any future changes to a credential\'s credit value by the issuer will not impact the values specified here.')} checked={prequalifier.save_credit_value === 'YES'} onChange={onChange_save_credit_value} />
                    }
                </div>
            </div>

            <FORM_TEXTAREA
                property='title'
                label={t('Description')}
                value={prequalifier.title}
                onChange={onChange_input}
                placeholder={t('Description')}
                maxLength={200}
                disabled={false}
                errors={errors}
                description={t('The description is displayed to the user upon claiming the credential. It serves as a guide, providing context and clear instructions on how to fulfill the qualifying requirement successfully.')}
            />



            <CMP_CONFIRMATION
                display={var_deletecredential_open}
                title={t('Remove credential')}
                message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>{t('Are you sure you want to remove this credential.')}</div>
                    </div>
                }
                positive_option={t('Remove')}
                negative_option={t('Cancel')}
                onConfirm={onConfirm_delete}
                onCancel={() => set_deletecredential_open(false)}
            />

            <MDL_ADD_CREDENTIAL
                display={var_addcredential_open}
                prequalifier={prequalifier}
                onCredentialAdded={onCredentialAdded}
                onClose={() => set_addcredential_open(false)}
            />

        </div>
    );

}

CONTENT_CREDENTIAL.propTypes = {
    prequalifier: propTypes.object,
    errors: propTypes.arrayOf(propTypes.object),
    credentialstatus: propTypes.string,
    onChange: propTypes.func
};

export default CONTENT_CREDENTIAL;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Processing from 'components/cmp_processing';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import Icon from 'components/cmp_icon';
import 'i18n';



export default function CRD_ASSIGNMENTS({ requirement_id }) {

//  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'assignment_name' });
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_addassignment_open, set_addassignment_open ] = useState(false);
    const [ var_deletelocationrequirement_open, set_deletelocationrequirement_open ] = useState(false);
    const [ var_deletelocationrequirement_id, set_deletelocationrequirement_id ] = useState(null);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);

    const [ var_processing, set_processing ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_records();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_addassignment_open && var_focuselement?.id === 'btn_addassignment') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addassignment_open]);

    useEffect(() => {
        if (!var_deletelocationrequirement_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_reqdetails_assigments').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_deletelocationrequirement_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig) {
        set_message_text(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try{
            let results = await API_get_org_requirement_assignments(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_assignments(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e){
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_requirement_assignments(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_requirement_assignments(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_delete_loc_requirement() {
        return API.del('requirements', '/delete-loc-requirement/' + var_deletelocationrequirement_id);
    }

    function API_get_org_requirement_assignments(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-org-requirement-assignments/' + requirement_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_assignment(id) {
        navigate('/assignments/' + id);
    }

    function onClick_addassignment() {
        //set_focuselement(document.activeElement);
        //set_addassignment_open(true);
        console.warn('Add assignment is not yet implemented');
    }

    function onClick_deleteassignment(locationrequirement_id) {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_deletelocationrequirement_id(locationrequirement_id);
        set_deletelocationrequirement_open(true);
    }

    async function onConfirm_delete() {
        set_message_text(null);
        set_processing(true);
        let error = false;
        try {
            await API_delete_loc_requirement();
        } catch (e) {
            error = true;
            set_message_text(t('There was a problem saving.  Please try again later'));
            set_message_type('ERROR');
        }
        set_processing(false);
        set_focuselement(document.getElementById('crd_reqdetails_assigments'));
        set_deletelocationrequirement_open(false);
        if (!error) {
            populate_records();
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_reqdetails_assigments' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Assignments')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_addassignment' className='primary' onClick={onClick_addassignment}>{t('Add assignment')}</Button>
                    </div>
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='assignments' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={false} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('assignments')} downloadfunction={download_records}
                       onChange={populate_records}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='assignment_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='assignment_code' datatype='text' filtertype='text'>{t('CODE')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization_name' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                            <Table.HeaderCell field='businessunit' datatype='text' filtertype='text'>{t('BUSINESS UNIT')}</Table.HeaderCell>
                            <Table.HeaderCell field='city' datatype='text' filtertype='text'>{t('CITY')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_assignments.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell >
                                    <Button className='tertiary' onClick={() => onClick_assignment(item.id)}>
                                        <Table.CellContentTwoLine primary_text={<div className='text--anchor'>{item.assignment_name}</div>} secondary_text={item.default_assignment === 'YES' ? t('Default assignment') : ''} />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.assignment_code}</Table.Cell>
                                <Table.Cell>{item.organization_name}</Table.Cell>
                                <Table.Cell>{item.businessunit}</Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    <div>{item.city}</div>
                                    <Button className='tertiary' onClick={() => onClick_deleteassignment(item.locationrequirement_id)}>
                                        <Icon name='delete' className='color--primary-500'  />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button id='btn_addassignment' className='primary' onClick={onClick_addassignment}>{t('Add assignment')}</Button>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>

            <CMP_CONFIRMATION
                display={var_deletelocationrequirement_open}
                title={t('Remove assignment')}
                message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>{t('Removing this assignment may impact individuals ability to work on this assignment and your compliance reports.')}</div>
                        <div>{t('This action can not be undone.')}</div>
                    </div>
                }
                positive_option={t('Remove')}
                negative_option={t('Cancel')}
                onConfirm={onConfirm_delete}
                onCancel={() => set_deletelocationrequirement_open(false)}
            />

        </>

    )
}
import React, { useEffect } from 'react';

import CRD_PACKAGES from './crd_packages/crd_packages';



export default function TAB_PACKAGES() {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <CRD_PACKAGES />
        </>
    )
};

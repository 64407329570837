import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import Icon from 'components/cmp_icon';
import lookup from 'libs/lookup-lib';

function CMP_CREDENTIAL_STATUS_ICON({ confirmation_status, is_self_verified = false, className }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_confirmation_status_info, set_confirmation_status_info ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (confirmation_status) {
            populate_credential_status_info();
        }
    }, [confirmation_status]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credential_status_info() {
        set_confirmation_status_info(await lookup.confirmation_status.get_item(confirmation_status.toUpperCase()));
    }

    // RENDER APP ======================================================================================================

    return var_confirmation_status_info
        ? (
            <Icon
                name={(is_self_verified === true || is_self_verified === 'YES') ? var_confirmation_status_info.self_verified_icon_name : var_confirmation_status_info.default_icon_name}
                className={`color--${(is_self_verified === true || is_self_verified === 'YES') ? var_confirmation_status_info.self_verified_icon_color : var_confirmation_status_info.default_icon_color} ${className}`}
                alt={var_confirmation_status_info.name_init_cap} />
        ) : null;
}

CMP_CREDENTIAL_STATUS_ICON.propTypes = {
    confirmation_status: propTypes.string.isRequired,
    is_self_verified: propTypes.oneOfType([ propTypes.string, propTypes.bool ]).isRequired,
    className: propTypes.string
};

export default CMP_CREDENTIAL_STATUS_ICON;
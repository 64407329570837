import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'i18n';

import Icon from 'components/cmp_icon';
import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TABS from 'components/cmp_tabs/cmp_tabs';
import TAB_TEMPLATES from './tab_templates/tab_templates';
import TAB_THEMES from './tab_themes/tab_themes';



export default function VERIFICATIONS() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_tab, set_tab ] = useState('TEMPLATES');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        if (params.has('tab')) {
            set_tab(params.get('tab').toUpperCase());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }



    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }]}
                current_page_text={t('Verification templates')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{t('Verification templates')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <TABS activetab={var_tab} onClick={onClick_tab} aria_label={t('Verification')} id='tabs_verification'>
                    <TABS.TAB name='TEMPLATES' label={t('Templates')} />
                    <TABS.TAB name='THEMES' label={t('Themes')} />
                </TABS>

                {var_tab === 'TEMPLATES' &&
                    <TAB_TEMPLATES activetab={var_tab} />
                }

                {var_tab === 'THEMES' &&
                    <TAB_THEMES activetab={var_tab} />
                }
            </div>
        </div>
    )
};
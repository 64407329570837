import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { FORM_SELECT, FORM_INPUT, FORM_TEXTAREA } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './content_survey.css';

/*******************************************************************
 * Note, this renders the form inputs for parent <Form> element within mdl_prequalifer.js
 *******************************************************************/
function CONTENT_SURVEY({ prequalifier, survey_list, errors, credentialstatus, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_survey_options, set_survey_options ] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_survey_options.length === 0)
        set_survey_options(survey_list.filter(item => item.status === 'ACTIVE' || item.id === prequalifier.organization_survey_id).map(item => ({ value: item.id, text: item.name })));
    }, [survey_list]);


    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event, { name, value }) {
        let updated = { ...prequalifier };
        updated[name] = value;
        onChange(updated, name);
    }


    // RENDER APP ======================================================================================================

    return (
        <div id='content_survey' className='content__wrapper'>
            {(credentialstatus === 'ACTIVE' && prequalifier.prequalifier_id !== undefined)
                ? <Message warning
                    icon={<Icon name='warning' className='warning-icon--yellow' />}
                    content={t('Modifying this qualifier will require all holders of this credential to complete the updated survey. All ‘valid’ credentials will change to ‘In-progress’ until the survey has been completed. This action can not be undone.')}
                  />
                : credentialstatus === 'ACTIVE'
                ? <Message warning
                    icon={<Icon name='warning' className='warning-icon--yellow' />}
                    content={t('Adding this qualifier will require all holders of this credential to complete the new survey. All ‘valid’ credentials will change to ‘In-progress’ until the survey has been completed. This action can not be undone.')}
                  />
                : null
            }

            <div className='content__description text--sm-medium'>{t('This qualifier enables completion of a survey by an individual.')}</div>

            <FORM_SELECT
                property='organization_survey_id'
                label={t('Survey')}
                value={prequalifier.organization_survey_id}
                onChange={onChange_input}
                placeholder={t('Select')}
                options={var_survey_options}
                errors={errors}
            />

            <FORM_INPUT
                property='title'
                label={t('Title')}
                value={prequalifier.title}
                onChange={onChange_input}
                placeholder={t('Title')}
                maxLength={200}
                errors={errors}
                description={t('The title of the modal window where the survey is presented.')}
            />

            <FORM_TEXTAREA
                property='description'
                label={t('Description')}
                value={prequalifier.description}
                onChange={onChange_input}
                placeholder={t('Description')}
                maxLength={200}
                disabled={false}
                errors={errors}
                description={t('The description is displayed to the user upon claiming the credential. It serves as a guide, providing context and clear instructions on how to fulfill the qualifying requirement successfully.')}
            />

        </div>
    );

}

CONTENT_SURVEY.propTypes = {
    prequalifier: propTypes.object,
    survey_list: propTypes.arrayOf(propTypes.shape({
        id: propTypes.string.isRequired,
        name: propTypes.string.isRequired,
        status: propTypes.oneOf([ 'ACTIVE', 'ARCHIVE' ]).isRequired
    })),
    errors: propTypes.arrayOf(propTypes.object),
    credentialstatus: propTypes.string,
    onChange: propTypes.func
};

export default CONTENT_SURVEY;
import React, { useState, useEffect } from 'react';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import form_helper from 'libs/form-lib';

import { FORM_DATEPICKER, FORM_SELECT_RADIO, FORM_TOGGLE, FORM_TEXTAREA } from 'components/cmp_form/cmp_form';

export default function MDL_CREATE_TEMP_LINK({
                                    display,
                                    onClose,
                                    onLinkCreated,
                                    credential_id,
                                    individual_id,
                                    assignment_id
                                }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_link, set_link ] = useState({});
    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_selected_credentials, set_selected_credentials] = useState(credential_id ? [credential_id] : []);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(false);
    const [ var_description, set_description ] = useState(null);
    const [ var_credential_attachments, set_credential_attachments ] = useState('YES');
    const [ var_disable_create, set_disable_create ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){
            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="search"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(()=>{
        if (display) {
            set_modal(document.querySelector('#mdl_linkshare'));
            set_selected_credentials(credential_id ? [credential_id] : []);
            set_errors([]);
            set_save_error(false);
            set_description(null);
            set_credential_attachments('YES');
            set_link({});
            set_disable_create(false);
            populate_credentials();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[display]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials(){
        try {
            let results = await API_get_credentials();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: <div>{item.credential} {item.customsecurity === 'YES' && <Icon name='lock' className='icon__lock color--gray-400' alt={t('lock icon')} />}</div>,
                secondary_text: item.customsecurity === 'YES' ? t('Sharing disabled due to security restrictions') : t(item.confirmation_status),
                disabled: item.customsecurity === 'YES',
                additional_search_values: [item.credential]
            }));

            if (transformed.length === 0) {
                set_disable_create(true);
                set_errors([{ property: 'no_credentials', description: t('There are no available credentials to include.') }]);
            }
            set_credentials(transformed)
        } catch (e) {
            console.log(e);
        }
    }

    async function create_link_report(link) {
        set_processing(true);
        try {
            await API_post_external_report(link);
            onLinkCreated();
            onClose();
        } catch (exception) {
            console.log(exception);
            set_save_error(t('An error has occurred.'));
        } finally {
            set_processing(false);
        }
    }

    async function onClick_create() {
        let link = { ...var_link };
        let errors = [];
        // validation
        if (!var_selected_credentials || var_selected_credentials.length === 0) {
            errors.push({property: 'credentials', description: t('Please select at least one credential.')});
        } else {
            link.credential_ids = var_selected_credentials;
        }
        if (link.expiry_timestamp) {
            let date_validation = form_helper.validate_date(link.expiry_timestamp, datelib.date_to_midnight_date(new Date()), null);
            if (date_validation.is_less_than_minimum) {
                errors.push({ property: 'expiry_timestamp', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
            } else if (date_validation.is_greater_than_maximum) {
                errors.push({ property: 'expiry_timestamp', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
            }
        }
        set_errors(errors);
        if (errors.length > 0) return;

        link.report_type = individual_id ? 'ORG_INDIVIDUAL_PROFILE_TEMP_LINK' : 'INDIVIDUAL_PROFILE_TEMP_LINK';

        // cleanse properties
        link.expiry_timestamp = var_link.expiry_timestamp ? datelib.date_to_epoch(var_link.expiry_timestamp) : null;
        link.description = var_description;
        link.has_attachments = var_credential_attachments;
        form_helper.cleanse_string_property(link, 'description');
        create_link_report(link);
    }

    //  API calls ------------------------------------------------------------------------------------------------------


    function API_get_credentials(){
        if (individual_id) {
            return API.get('credentials', '/get-credentials-list/' + individual_id + (assignment_id ? '/' + assignment_id : ''),
            {
                queryStringParameters: {
                    tz: datelib.timezone
                }
            });
        } else {
            return API.get('credentials', '/get-credentials-list',
            {
                queryStringParameters: {
                    tz: datelib.timezone
                }
            });
        }
    }

    function API_post_external_report(profile) {
        if (individual_id) {
            return API.post('external-report', '/post-external-report/' + individual_id + (assignment_id ? '/' + assignment_id : ''),
            {
                body: profile
            });
        } else {
            return API.post('external-report', '/post-external-report',
                {
                    body: profile
                });
        }
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_credentials(value) {
        set_selected_credentials(value);
        set_errors([]);
    }

    function onChange_date(e, { value }) {
        let link = { ...var_link };
        link.expiry_timestamp = value;
        set_link(link);
        set_errors(var_errors.filter(error => error.property !== 'expiry_timestamp'));
    }

    function onChange_description(event) {
        set_description(event.target.value);
    }

    // RENDER APP ======================================================================================================


    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            className='mdl_linkshare'
            id='mdl_linkshare'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_workerprofile_sharing_link'
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_workerprofile_sharing_link'>{t('Temporary link')}</div>
                    <div className='text--sm-regular'>
                        {t('Select credentials to share.')}
                    </div>
                </div>
            </div>

            <Form className='modal__content' id='form_workerprofile_sharing_link' aria-labelledby='hdr_workerprofile_sharing_link' tabIndex='0'>
                {var_save_error &&
                    <Message error
                        header={var_save_error}
                    />
                }

                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_errors[0].property === 'no_credentials' ? var_errors[0].description : t('There are some errors with your inputs.')}
                    />
                }

                <FORM_SELECT_RADIO
                    name='credentials'
                    property='credentials'
                    label={t('Credentials')}
                    value={var_selected_credentials}
                    options={var_credentials}
                    single_or_multiple='MULTIPLE'
                    onChange={onChange_credentials}
                    placeholder={t('Search')}
                    disabled={false}
                    errors={var_errors}
                    aria_label={t('Select the credentials you want to share.')}
                />

                <FORM_TOGGLE
                    name='credential_attachments'
                    label={t('Attachments')}
                    description={t('Include credential attachments')}
                    options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                    value={var_credential_attachments}
                    onChange={(value) => set_credential_attachments(value)}
                />

                <FORM_TEXTAREA
                    property='description'
                    label={t('Description (optional)')}
                    value={var_description}
                    onChange={onChange_description}
                    placeholder={t('Description')}
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_DATEPICKER
                    property='expiry_timestamp'
                    label={t('Expiry (optional)')}
                    value={var_link.expiry_timestamp}
                    onChange={onChange_date}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('YYYY-MM-DD')}
                    minproperty={datelib.date_to_midnight_date(new Date())}
                    maxproperty={null}
                    disabled={false}
                    errors={var_errors}
                />

            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_create} disabled={var_disable_create}>{t('Create link')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import datelib from 'libs/date-lib';
import { FORM_SELECT, FORM_SELECT_RADIO, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';

import Icon from 'components/cmp_icon';

import './mdl_add_requirement.css';


function MDL_ADD_REQUIREMENT({ display, onClose, assignment, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_requirement_or_package, set_requirement_or_package ] = useState('REQUIREMENT');
    const [ var_requirement_options, set_requirement_options ] = useState([]);
    const [ var_package_options, set_package_options ] = useState([]);
    const [ var_requirements_load_status, set_requirements_load_status ] = useState('NONE');
    const [ var_packages_load_status, set_packages_load_status ] = useState('NONE');
    const [ var_selected_options, set_selected_options ] = useState([]);
    const [ var_all_individuals, set_all_individuals ] = useState('NO');
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display && assignment.location_id) {
            // reset and populate options
            set_requirement_or_package('REQUIREMENT');
            set_requirement_options([]);
            set_package_options([]);
            set_requirements_load_status('NONE');
            set_packages_load_status('NONE');
            set_all_individuals('NO');
            set_selected_options([]);
            set_loadingerror(false);
            set_errors([]);
            set_processing(false);
            populate_requirement_options();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, assignment.location_id]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirement_options() {
        set_requirements_load_status('LOADING');
        try {
            let results = await API_get_loc_available_requirements();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: item.name,
                secondary_text: item.already_added === 'YES' ? t('Already added') : null,
                disabled: item.already_added === 'YES',
                has_preapproved: item.has_preapproved
            }));
            set_requirement_options(transformed);
            if (results.length === 0) {
                set_errors([{description: t('There are no available requirements to add')}])
            }
        } catch (e) {
            console.log(e);
            set_errors([{description: t('There was a problem loading.  Please try again later')}]);
            set_loadingerror(true);
        }
        set_requirements_load_status('LOADED');
    }

    async function populate_package_options() {
        set_packages_load_status('LOADING');
        try {
            let results = await API_get_loc_available_packages();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: item.name,
                secondary_text: item.already_added === 'YES' ? t('Already added') : item.requirements,
                disabled: item.already_added === 'YES'
            }));
            set_package_options(transformed);
            if (results.length === 0) {
                set_errors([{description: t('There are no available packages to add')}])
            }
        } catch (e) {
            console.log(e);
            set_errors([{description: t('There was a problem loading.  Please try again later')}]);
            set_loadingerror(true);
        }
        set_packages_load_status('LOADED');
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_available_requirements() {
        return API.get('requirements', '/get-loc-available-requirements/' + assignment.location_id);
    }

    function API_get_loc_available_packages() {
        return API.get('requirements', '/get-loc-available-packages/' + assignment.location_id);
    }

    function API_post_loc_requirement() {
        return API.post('requirements', '/post-loc-requirement', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: {
                location_id: assignment.location_id,
                requirement_ids: var_requirement_or_package === 'REQUIREMENT' ? var_selected_options : null,
                package_ids: var_requirement_or_package === 'PACKAGE' ? var_selected_options : null,
                all_individuals: var_all_individuals
        }});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_requirement_or_package(e, data) {
        set_requirement_or_package(data.value);
        set_errors([]);
        set_selected_options([]);
        if (data.value === 'REQUIREMENT' && var_requirements_load_status === 'NONE') {
            populate_requirement_options();
        } else if (data.value === 'PACKAGE' && var_packages_load_status === 'NONE') {
            populate_package_options();
        }
    }

    function onChange_options(value) {
        set_selected_options(value);
        set_errors([]);
    }

    async function onClick_add() {
        if (var_selected_options.length === 0) {
            set_errors([{property: 'requirements', description: t(`Please select at least one ${var_requirement_or_package.charAt(0) + var_requirement_or_package.substring(1).toLowerCase()} to add`)}]);
            return;
        }

        set_processing(true);
        try {
            await API_post_loc_requirement();
            let options = var_requirement_or_package === 'REQUIREMENT' ? var_requirement_options : var_package_options;
            let selected_names = var_selected_options.map(item => options.find(option => option.value === item).primary_text);
            onChange && onChange(var_selected_options.length, selected_names.sort().join(', '));
        } catch (e) {
            console.log(e);
            set_errors([{description: t('There was a problem saving.  Please try again later')}]);
        }
        set_processing(false);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer={'inverted'}
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            className='modal__add_requirement'>
            <div className='modal__header'>
                <div style={ { width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium' id='hdr_assignment_addreq'>{t('Add requirement')}</div>
                    </div>
                    <div className='modal__header__right'>
                        <div className="modal__header__closeicon color--primary-500">
                            <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                        </div>
                    </div>
                </div>
            </div>
            <Form className='modal__content center padding--lg' id='form_assignment_addreq' aria-labelledby='hdr_assignment_addreq'>
                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_errors[0].description} />
                }
                <FORM_SELECT 
                    label={t('Type')} 
                    value={var_requirement_or_package} 
                    options={[{ value: 'REQUIREMENT', text: t('Single requirement') }, { value: 'PACKAGE', text: t('Package of requirements') }]} 
                    onChange={onChange_requirement_or_package} 
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}/>

                {((var_requirement_or_package === 'REQUIREMENT' && var_requirements_load_status === 'LOADED') || (var_requirement_or_package === 'PACKAGE' && var_packages_load_status === 'LOADED')) &&
                    !var_loadingerror &&
                        <>
                        <FORM_SELECT_RADIO
                            name='requirement'
                            property='requirement'
                            label={t('Requirement')}
                            value={var_selected_options}
                            options={var_requirement_or_package === 'REQUIREMENT' ? var_requirement_options : var_package_options}
                            single_or_multiple='MULTIPLE'
                            onChange={onChange_options}
                            placeholder={t('Search')}
                            disabled={false}
                            errors={var_errors}
                            aria_label={t('Select requirements to add to the assignment.')}
                        />
                        <FORM_TOGGLE
                            label={t('Mandatory')}
                            description={t('Selecting \'Yes\' will make the chosen requirements mandatory and automatically assign them to all individuals on this assignment.')}
                            value={var_all_individuals}
                            onChange={set_all_individuals}
                            options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                        />
                        {var_requirement_or_package === 'REQUIREMENT' && var_requirement_options.some(item => var_selected_options.includes(item.value) && item.has_preapproved === 'NO') &&
                                    <div className='no_preapproved_message field__feedback error text--sm-regular'>{t('One or more of the selected requirements do not have any pre-approved credentials so automatic validation is not possible.  Pre-approved credentials can be added in the Requirements Setup page')}</div>
                        }
                        </>
                }
            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

export default MDL_ADD_REQUIREMENT;
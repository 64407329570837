import React from 'react';
import { useParams } from 'react-router-dom';

import CRD_VERIFICATION from './crd_verification/crd_verification';

export default function VERIFICATION() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const params = useParams();


    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <CRD_VERIFICATION token={params.token} />
    );

};

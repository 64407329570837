import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Message } from 'semantic-ui-react';
import CMP_QRCODE from 'components/cmp_qrcode/cmp_qrcode';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import 'i18n';
import Processing from 'components/cmp_processing';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';


export default function CRD_TEMPLATES() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_templates, set_templates ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_templatestatus, set_templatestatus ] = useState('ACTIVE');

    const [ var_qrdisplay, set_qrdisplay ] = useState(false);
    const [ var_qr_verification_template, set_qr_verification_template ] = useState({});

    const [ var_deletetemplate_open, set_deletetemplate_open ] = useState(false);
    const [ var_deletetemplate_id, set_deletetemplate_id ] = useState(null);
    const [ var_create_template_open, set_create_template_open ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_default_theme, set_default_theme ] = useState({});
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_templates({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_templatestatus]);

    useEffect(() => {
        if (!var_qrdisplay && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_qrdisplay]);

    useEffect(() => {
        if (!var_deletetemplate_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_deletetemplate_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_templates(populateconfig, focuselement_id) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_verification_templates(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_templates(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
            document.getElementById(var_focuselement)?.focus();
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_all_templates').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        } else if (var_focuselement?.id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${var_focuselement.id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_all_templates').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_verification_templates(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_templates(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_verification_templates(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function delete_template() {
        set_deletetemplate_open(false);
        set_processing(true);
        try {
            await API_delete_verification_template();
            populate_templates();
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_verification_templates(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('verifiable-credentials', '/get-verification-templates/' + var_templatestatus,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }

    function API_get_verification_template_default_theme() {
        return API.get('verifiable-credentials', '/get-verification-template-default-theme');
    }

    function API_post_verification_template_theme() {
        return API.post('verifiable-credentials', '/post-verification-template-theme', { body: var_default_theme });
    }

    function API_delete_verification_template() {
        return API.del('verifiable-credentials', '/delete-verification-template/' + var_deletetemplate_id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_createtemplate() {
        try {
            let results = await API_get_verification_template_default_theme();
            set_default_theme(results);
            if (results.is_first_template !== 'YES') {
                navigate('/org/verifications/create');
            } else {
                set_create_template_open(true);
            }
        } catch (e) {
            console.log(e);
        }
    }

    function onClick_templatestatus(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_templatestatus(status);
    }

    function onClick_template(id) {
        navigate('/org/verifications/' + id);
    }

    function onClick_url(url) {
        window.open(url);
    }

    function onClick_downloadqr(verification_template) {
        set_focuselement(document.activeElement);
        set_qr_verification_template(verification_template);
        set_qrdisplay(true);
    }

    function onClick_deletetemplate(id) {
        set_focuselement(document.activeElement);
        set_save_error(null);
        set_deletetemplate_id(id);
        set_deletetemplate_open(true);
    }

    function onConfirm_deletetemplate() {
        delete_template();
    }

    async function onConfirm_create_template() {
        try {
            await API_post_verification_template_theme();
            navigate('/org/verifications/create');
        } catch (e) {
            console.log(e);
        }
    }

    function onCancel_create_template() {
        set_create_template_open(false);
        navigate(`/org/verifications/themes/create`);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_all_templates' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_templates' className='text--xl-medium'>{t('Verification templates')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_createtemplate' className='primary' onClick={onClick_createtemplate}>{t('Create template')}</Button>
                    </div>
                </div>

                {var_save_error &&
                    <Message error
                        header={var_save_error}
                    />
                }

                <Table id='templates' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('Verification templates')} downloadfunction={download_templates}
                       onChange={populate_templates}>
                    <Table.OptionsButtons>
                        <TOGGLE value={var_templatestatus} onChange={onClick_templatestatus} options={[{value: 'ACTIVE', text: t('Published')}, {value: 'DRAFT', text: t('Draft')}, {value: 'ARCHIVE', text: t('Unpublished')}]} />
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('TEMPLATE')}</Table.HeaderCell>
                            <Table.HeaderCell field='url' datatype='text' filtertype='text'>{t('URL')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('QR CODE')}</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_templates.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' onClick={() => onClick_template(item.id)}>
                                        {item.name}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' disabled={var_templatestatus !== 'ACTIVE'} onClick={() => onClick_url(item.url)}>
                                        {item.url}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className='cell__icon'>
                                    <Button className='tertiary' disabled={var_templatestatus !== 'ACTIVE'} onClick={() => onClick_downloadqr(item)}>
                                        <Icon name='export' className={var_templatestatus !== 'ACTIVE' ? 'color-gray-100': 'color--primary-500'} alt={t('download icon')}/>
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className='cell__icon--left--right'>
                                    <Button className='tertiary' onClick={() => onClick_deletetemplate(item.id)}>
                                        <Icon name='delete' className='color--primary-500'/>
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button id='btn_createtemplate' className='primary' onClick={onClick_createtemplate}>{t('Create template')}</Button>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>

            <CMP_QRCODE
                display={var_qrdisplay}
                name={var_qr_verification_template.name}
                qr_value={var_qr_verification_template.url}
                title={t('QR code')}
                content={t('Download this QR code to include in emails or other materials used for requesting verifications.')}
                onClose={() => set_qrdisplay(false)}
            />

            <CMP_CONFIRMATION display={var_deletetemplate_open} title={t('Delete verification template')} message={
                <div style={{flexDirection: 'column'}}>
                    <div>{t('Are you sure you want to delete the {{name}} template? This action cannot be undone.', { name: var_templates.find(item => item.id === var_deletetemplate_id)?.name })}</div>
                </div>
            } positive_option={t('Delete')} negative_option={t('Cancel')} onConfirm={onConfirm_deletetemplate} onCancel={() => set_deletetemplate_open(false)} />

            <CMP_CONFIRMATION
                display={var_create_template_open}
                title={t('Missing template theme')}
                message={t('To create a verification template, you need a theme. A default theme will be created and applied to the template. You can customize this theme at any time or create additional themes for your templates.')}
                positive_option={t('Continue')}
                negative_option={t('Customize theme')}
                onConfirm={onConfirm_create_template}
                onCancel={onCancel_create_template}
            />

        </>

    )
}
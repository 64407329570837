import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';
import auth from 'libs/auth-lib';
import { Modal, Message, Button } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import CMP_REQUIREMENT_ACCEPTANCE from 'components/cmp_requirement_acceptance/cmp_requirement_acceptance';
import CMP_MDL_CERTIFICATE from 'components/cmp_mdl_certificate/cmp_mdl_certificate';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';

export default function MDL_COUNT_DETAILS({ display, onClose, assignment, requirement_name, locationrequirement_id, status, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_details, set_details ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    const [ var_deleteindividualrequirement_open, set_deleteindividualrequirement_open ] = useState(false);
    const [ var_deleteindividualrequirement_id, set_deleteindividualrequirement_id ] = useState(null);

    const [ var_canaccept, set_canaccept ] = useState(false);
    const [ var_acceptreject_id, set_acceptreject_id ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_certificate_open, set_certificate_open ] = useState(false);
    const [ var_selected_credential_id, set_selected_credential_id ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_details([]);
            set_totalrows(0);
            set_ready(false);
            set_loading(true);
            set_loadingerror(false);
            set_processing(false);
            set_modal(document.querySelector('#mdl_count_details'));
            set_refreshtable(!var_refreshtable);
            populate_records({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
            set_canaccept(auth.has_access(assignment, 'ORG-REQ', 'accept'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        if (!var_certificate_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('modal__content').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_certificate_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, focuselement_id) {
        set_message_text(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_loc_requirement_count_details(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_details(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('.modal__content').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_loc_requirement_count_details(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_loc_requirement_count_details(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_del_loc_requirement_assignment() {
        return API.del('requirements', '/delete-loc-requirement-assignments/' + assignment.location_id + '/' + var_deleteindividualrequirement_id);
    }

    function API_get_loc_requirement_count_details(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-loc-requirement-count-details/' + locationrequirement_id + '/' + status,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_pending(individualrequirement_id) {
        set_message_text(null);
        set_acceptreject_id(individualrequirement_id);
    }

    function onClick_individual(id) {
        navigate(`/assignments/${assignment.location_id}/REQUIREMENTS/individual/${id}`);
    }

    function onDelete_requirement(target) {
        set_message_text(null);
        set_deleteindividualrequirement_id(target);
        set_deleteindividualrequirement_open(true);
    }

    function onClick_credential(id) {
        set_focuselement(document.activeElement);
        set_selected_credential_id(id);
        set_certificate_open(true);
    }

    async function onConfirm_delete() {
        set_message_text(null);
        set_processing(true);
        try {
            await API_del_loc_requirement_assignment();
        } catch (e) {
            set_message_text(t('There was a problem saving.  Please try again later'));
        }
        populate_records();
        set_processing(false);
        set_deleteindividualrequirement_open(false);
        onChange && onChange();
    }

    function onChange_acceptance(focuselement_id) {
        set_acceptreject_id(null);
        populate_records(null, focuselement_id);
        onChange && onChange(focuselement_id);
    }

    function onKeyDown(e) {
        if (e.key === 'Escape') {
            onClose();
        }
    }

    function onClose_requirement_acceptance() {
        set_acceptreject_id(null);
        set_message_text(null);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <Modal
                dimmer='inverted'
                onClose={onClose}
                open={display}
                closeOnEscape={false}
                closeOnDimmerClick={true}
                className='mdl_count_details'
                onKeyDown={onKeyDown}
                id='mdl_count_details'
                aria-modal='true'
                role='dialog'
                aria-labelledby='hdr_count_details'
                >
                <div className='modal__header'>
                    <div style={ { width: '100%' }}>
                        <div className='modal__header__left'>
                            <div id='hdr_count_details' className='text--xl-medium'>{status ? t(status.substring(0, 1) + status.substring(1).toLowerCase()) : ''}</div>
                            <div className='text--sm-regular'>{requirement_name}</div>
                        </div>
                    </div>
                </div>
                <div className='modal__content center padding--lg' id='modal__content' tabIndex='0' aria-labelledby='hdr_count_details'>

                    {var_message_text &&
                        <Message error
                            icon={<Icon name='error' className='icon' />}
                            header={var_message_text} />
                    }

                    <Table id='requirements' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                            populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                            downloadname={t('Requirements')} downloadfunction={download_records}
                            onChange={populate_records}>

                        {render_table_header()}

                        <Table.Body>
                            {var_details.map(item => render_table_row(item))}
                        </Table.Body>

                    </Table>
                </div>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                    </div>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />

            </Modal>
            <CMP_CONFIRMATION
                display={var_deleteindividualrequirement_open}
                title={t('Remove Requirement')}
                positive_option={t('Remove')}
                negative_option={t('Cancel')}
                onConfirm={onConfirm_delete}
                onCancel={() => set_deleteindividualrequirement_open(false)}
                message={t("Removing this requirement may impact an individual's ability to work on this assignment and your compliance reports.")}
            />

            <CMP_MDL_CERTIFICATE
                credential_id={var_selected_credential_id}
                verifiable_credential={null}
                is_open={var_certificate_open}
                onClose={() => set_certificate_open(false)}
            />
        </>
    );

    function render_table_header() {
        if (['INDIVIDUALS', 'REQUESTED', 'MISSING'].includes(status)) {
            return (
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                        <Table.HeaderCell field='job_title' datatype='text' filtertype='text'>{t('JOB TITLE')}</Table.HeaderCell>
                        <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                        {status === 'INDIVIDUALS' &&
                            <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                        }
                    </Table.Row>
                </Table.Header>
            );
        }
        if (['VALID', 'INVALID', 'EXPIRING'].includes(status)) {
            return (
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                        <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                        <Table.HeaderCell field='job_title' datatype='text' filtertype='text'>{t('JOB TITLE')}</Table.HeaderCell>
                        <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                        <Table.HeaderCell field='issuer_name' datatype='text' filtertype='text'>{t('ISSUED BY')}</Table.HeaderCell>
                        <Table.HeaderCell field='expires' datatype='date' filtertype='date'>{t('EXPIRES')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )
        }
        if (status === 'PENDING') {
            return (
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('STATUS')}</Table.HeaderCell>
                        <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                        <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                        <Table.HeaderCell field='job_title' datatype='text' filtertype='text'>{t('JOB TITLE')}</Table.HeaderCell>
                        <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                        <Table.HeaderCell field='issuer_name' datatype='text' filtertype='text'>{t('ISSUED BY')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            );
        }
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
        );
    }

    function render_table_row(item) {
        if (['REQUESTED', 'MISSING'].includes(status)) {
            return (
                <Table.Row key={item.individualrequirement_id}>
                    <Table.Cell>
                        <CMP_VERIFIED_IDENTITY_DISPLAY
                            individual_name={item.individual_name}
                            onClick_individual={() => onClick_individual(item.individual_id)}
                            verified_identity_credential_id={item.identity_verification_credential_id}
                        />
                    </Table.Cell>
                    <Table.Cell>{item.job_title}</Table.Cell>
                    <Table.Cell className='cell__icon--right'>
                        {item.organization}
                        {item.requirementassignment_id &&
                            <Button className='tertiary' onClick={() => onDelete_requirement(item.individualrequirement_id)}>
                                <Icon name='delete' className='icon__delete__table'/>
                            </Button>
                        }
                    </Table.Cell>
                </Table.Row>
            );
        }
        if (status === 'INDIVIDUALS') {
            return (
                <Table.Row key={item.individualrequirement_id}>
                <Table.Cell>
                    <CMP_VERIFIED_IDENTITY_DISPLAY
                        individual_name={item.individual_name}
                        onClick_individual={() => onClick_individual(item.individual_id)}
                        verified_identity_credential_id={item.identity_verification_credential_id}
                    />
                </Table.Cell>
                <Table.Cell>{item.job_title}</Table.Cell>
                <Table.Cell>{item.organization}</Table.Cell>
                <Table.Cell className='cell__icon--right'>
                    <div className={'badge text--sm-medium  ' +
                        (   item.status === 'Valid' || item.status === 'Expiring' ?
                            'badge--green'
                            : item.status === 'Expired' || item.status === 'Invalid' || item.status === 'Missing' ?
                                'badge--red'
                                : item.status === 'Pending' ?
                                    'badge--yellow'
                                    : item.status === 'Requested' ?
                                        'badge--gray'
                                        : 'badge--gray')}>
                        {item.status_display}
                    </div>
                    {item.requirementassignment_id &&
                        <Button className='tertiary' onClick={() => onDelete_requirement(item.individualrequirement_id)} >
                            <Icon name='delete' className='icon__delete__table' />
                        </Button>
                    }
                </Table.Cell>
            </Table.Row>
            )
        }
        if (['VALID', 'INVALID', 'EXPIRING'].includes(status)) {
            return (
                <Table.Row key={item.individualrequirement_id} >
                    <Table.Cell>
                        <CMP_VERIFIED_IDENTITY_DISPLAY
                            individual_name={item.individual_name}
                            onClick_individual={() => onClick_individual(item.individual_id)}
                            verified_identity_credential_id={item.identity_verification_credential_id}
                        />
                    </Table.Cell>
                    <Table.Cell className='text--anchor'>
                        <Button className='tertiary' onClick={() => onClick_credential(item.individualcredential_id)} >
                            {item.credential_name}
                            {item.credential_name && item.has_customsecurity === 'YES' && <Icon name='lock' className='icon__lock color--gray-400' alt={t('lock icon')} />}
                        </Button>
                    </Table.Cell>
                    <Table.Cell>{item.job_title}</Table.Cell>
                    <Table.Cell>{item.organization}</Table.Cell>
                    <Table.Cell>{item.issuer_name}</Table.Cell>
                    <Table.Cell className='cell__datetime cell__icon--right'>
                        {datelib.epoch_to_date_string(item.expires)}
                        {item.requirementassignment_id &&
                            <Button className='tertiary' onClick={() => onDelete_requirement(item.individualrequirement_id)} >
                                <Icon name='delete' className='icon__delete__table' />
                            </Button>
                        }
                    </Table.Cell>
                </Table.Row>
            )
        }
        if (status === 'PENDING') {
            return (
                <Table.Row key={item.individualrequirement_id} >
                    <Table.Cell>
                        {
                            <CMP_REQUIREMENT_ACCEPTANCE
                                requirement={item}
                                can_accept={var_canaccept}
                                manage_history_access={auth.has_access(assignment, 'ORG-REQ', 'manage history')}
                                is_open={item.individualrequirement_id  === var_acceptreject_id}
                                onClose={onClose_requirement_acceptance}
                                locationrequirement_id={locationrequirement_id}
                                onOpen={() => onClick_pending(item.individualrequirement_id)}
                                onChange={(focuselement_id) => onChange_acceptance(focuselement_id)}
                                onMessage={(type, text) => set_message_text(text)}
                            />
                        }
                    </Table.Cell>
                    <Table.Cell>
                        <CMP_VERIFIED_IDENTITY_DISPLAY
                            individual_name={item.individual_name}
                            onClick_individual={() => onClick_individual(item.individual_id)}
                            verified_identity_credential_id={item.identity_verification_credential_id}
                        />
                    </Table.Cell>
                    <Table.Cell className='text--anchor'>
                        <Button className='tertiary' onClick={() => onClick_credential(item.individualcredential_id)} >
                            {item.credential_name}
                            {item.credential_name && item.has_customsecurity === 'YES' && <Icon name='lock' className='icon__lock color--gray-400' alt={t('lock icon')} />}
                        </Button>
                    </Table.Cell>
                    <Table.Cell>{item.job_title}</Table.Cell>
                    <Table.Cell>{item.organization}</Table.Cell>
                    <Table.Cell className='cell__icon--right'>
                        {item.issuer_name}
                        {item.requirementassignment_id &&
                            <Button className='tertiary' onClick={() => onDelete_requirement(item.individualrequirement_id)}>
                                <Icon name='delete' className='icon__delete__table'  />
                            </Button>
                        }
                    </Table.Cell>
                </Table.Row>
            );
        }
        return (
            <Table.Row key={item.individualrequirement_id}>
                <Table.Cell />
            </Table.Row>
        );
    }
}
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import form_helper from 'libs/form-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import config from 'config';
import Processing from 'components/cmp_processing';
import Icon from 'components/cmp_icon';
import { FORM_SELECT } from 'components/cmp_form/cmp_form';



export default function CRD_LANGUAGE({ activetab, reset_authentication }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_settings, set_settings ] = useState({ default_language: 'en' }); // default to en until data is loaded from DB
    const [ var_updated_settings, set_updated_settings ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_mode, set_mode ] = useState('');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);

    const language_options = Object.keys(config.language).map(key => ({ key, text: config.language[key].text, value: key }));



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'SETTINGS') {
            populate_settings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_settings() {
        set_errors([]);
        let default_language = await API_get_indv_settings();
        set_settings({ default_language });
        set_mode('VIEW');
    }

    async function save_settings(settings) {
        set_processing(true);
        try {
            await API_put_indv_settings(settings);
            set_settings(settings);
            reset_authentication();
            set_mode('VIEW');
        } catch (exception) {
            console.log(exception);
            set_save_error(t('An error has occurred'));
        } finally {
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_settings() {
        return API.get('indv', '/get-indv-settings');
    }

    function API_put_indv_settings(settings) {
        return API.put('indv', '/put-indv-settings', {
            body: settings
        });
    }

    function onClick_save() {
        if (var_processing) return;

        let settings = {...var_updated_settings};
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(settings.default_language)) {
            errors.push({ property: 'default_language', description: t('Language is required.') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties

        save_settings(settings);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_errors([]);
        set_save_error(null);
        set_updated_settings({...var_settings});
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_mode('VIEW');
    }

    function onChange_language(e, { value }) {
        let settings = {...var_updated_settings};
        settings.default_language = value;
        set_updated_settings(settings);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd__language'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div id='hdr_language' className='text--xl-medium'>{t('Language')}</div>
                    <div className='text--sm-regular'>{t('Set your preferred system language.')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    {var_mode === 'VIEW' &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {var_mode === 'VIEW' ?
                <>
                <div className='card__content'>
                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--md-medium'>{t('Language')}</div>
                        <div className='detailsgroup__value text--sm-regular'>{(language_options.find(language => language.key === var_settings.default_language)).text}</div>
                    </div>
                </div>
                <div className='card__footer--mobilebtns'>
                    <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                </div>
                </>
            : var_mode === 'EDIT' ?
                <>
                <Form className='card__content'>
                    {var_save_error &&
                        <Message
                            error header={var_save_error}
                        />
                    }

                    {var_errors.length > 0 &&
                        <Message
                            error icon={<Icon name='error' className='icon' />}
                            header={t('There are some errors with your inputs')}
                        />
                    }

                    <FORM_SELECT
                        property='default_language'
                        label={t('Language')}
                        value={var_updated_settings.default_language || 'English'}
                        onChange={onChange_language}
                        placeholder={t('Language')}
                        options={language_options}
                        disabled={false}
                        errors={var_errors}
                        description=''
                    />

                </Form>
                <div className='card__footer'>
                    <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />
                </>
            :
                null
            }
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import 'i18n';

import CRD_DETAILS from './crd_details/crd_details';
import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';

export default function VERIFICATION_THEME_DETAILS() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_theme, set_theme ] = useState({});



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        populate_theme_details();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_theme_details() {
        try {
            let results = await API_get_verification_template_theme_details();
            set_theme(results);
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_verification_template_theme_details() {
        return API.get('verifiable-credentials', '/get-verification-template-theme-details/' + params.theme_id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }, { path: '/org/verifications', text: t('Verification templates') }]}
                current_page_text={var_theme.name || ''}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{var_theme.name}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <CRD_DETAILS theme={var_theme} onChange_theme={populate_theme_details} />

            </div>
        </div>
    )
};
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import 'i18n';

import MDL_ADDORGANIZATION from './mdl_addorganization/mdl_addorganization';
import MDL_ASSIGNMENTS from './mdl_assignments/mdl_assignments';
import MDL_ADDASSIGNMENTS from './mdl_addassignment/mdl_addassignment';
import CMP_AFFILIATE_STATUS from 'components/cmp_affiliate_status/cmp_affiliate_status';


export default function CRD_NETWORKS() {

//  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_orgs, set_orgs ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'organization' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);

    const [ var_statuspopup_id, set_statuspopup_id ] = useState(null);

    const [ var_mdl_addorganization_open, set_mdl_addorganization_open ] = useState(false);
    const [ var_mdl_assignments_open, set_mdl_assignments_open ] = useState(false);
    const [ var_mdl_addassignments_open, set_mdl_addassignments_open ] = useState(false);
    const [ var_organization_organization_id, set_organization_organization_id ] = useState('');
    const [ var_organization_affiliate_status, set_organization_affiliate_status ] = useState('');
    const [ var_organization_name, set_organization_name ] = useState('');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_organizations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_mdl_addorganization_open && var_focuselement?.id === 'btn_addorganization') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_addorganization_open]);

    useEffect(() => {
        if (!var_mdl_assignments_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_assignments_open]);

    useEffect(() => {
        if (!var_mdl_addassignments_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_addassignments_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_organizations(populateconfig, focuselement_id, message_type, message_text) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);
        set_message_type(message_type ?? null);
        set_message_text(message_text ?? null);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_networks(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_orgs(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);

        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_all_affiliateorgs').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }

        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_networks(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_organizations(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_networks(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_networks(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('org-profile', '/get-org-networks',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_addorg() {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_mdl_addorganization_open(true);
    }

    function onClick_assignments(id, organization_name, affiliate_status) {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_organization_organization_id(id);
        set_organization_affiliate_status(affiliate_status);
        set_organization_name(organization_name);
        set_mdl_assignments_open(true);
    }

    function onClick_addassignments(id, organization_name) {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_organization_organization_id(id);
        set_organization_name(organization_name);
        set_mdl_addassignments_open(true);
    }

    async function onChange_add_assingment(focuselement_id) {
        await populate_organizations(null, focuselement_id);
        set_message_type('SUCCESS');
        set_message_text(t('Assignment(s) added.'))
    }

    async function onChange_add_organization() {
        await populate_organizations();
        set_message_type('SUCCESS');
        set_message_text(t('Your affiliate invitation has been sent.'))
    }

    function onClose_mdl_assignments(focuselement_id) {
        set_mdl_assignments_open(false);
        focuselement_id && document.getElementById(focuselement_id)?.focus();
    }

    function onClick_status(id) {
        set_message_text(null);
        set_statuspopup_id(id);
    }

    function onChange_updatestatus(focuselement_id, message_type, message_text) {
        set_statuspopup_id(null);
        populate_organizations(null, focuselement_id, message_type, message_text);
    }

    function onClose_affiliate_status() {
        set_statuspopup_id(null);
        set_message_text(null);
        set_message_type(null);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_all_affiliateorgs' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_affiliates' className='text--xl-medium'>{t('Affiliated organizations')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_addorganization' className='primary' onClick={onClick_addorg}>{t('Add organization')}</Button>
                    </div>
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='organizations' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('Affiliated organizations')} downloadfunction={download_organizations}
                       onChange={populate_organizations}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='assignment_name' datatype='text' filtertype='text'>{t('ASSIGNMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_orgs.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>{item.organization}</Table.Cell>
                                <Table.Cell>
                                    <div className={item.assignment_name !== 'None' ? 'text--anchor' : ''}>
                                        <Button id={`btn_assignments_${item.id}`} className='tertiary' disabled={item.assignment_name === 'None'} aria-disabled={item.assignment_name === 'None'} onClick={() => onClick_assignments(item.id, item.organization, item.affiliate_status)}>{item.assignment_name}</Button>
                                        {item.affiliate_status === 'Contractor' &&
                                            <Button id={`btn_add_assignments_${item.id}`} className='tertiary' onClick={() => onClick_addassignments(item.id, item.organization)} >
                                                <Icon name='plus_circle' className='icon__addindividual color--primary-500' alt={t('Add assignment')} />
                                            </Button>
                                        }
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    {
                                        <CMP_AFFILIATE_STATUS
                                            affiliate_details={item}
                                            is_open={item.id === var_statuspopup_id}
                                            onClose={onClose_affiliate_status}
                                            onOpen={onClick_status}
                                            onChange={onChange_updatestatus}
                                            onMessage={(type, text) => { set_message_type(type); set_message_text(text); }}
                                        />
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button id='btn_addorganization' className='primary' onClick={onClick_addorg}>{t('Add organization')}</Button>
                </div>

            </div>

            <MDL_ADDORGANIZATION
                display={var_mdl_addorganization_open}
                onChange={onChange_add_organization}
                onClose={() => set_mdl_addorganization_open(false)}
            />

            <MDL_ASSIGNMENTS
                display={var_mdl_assignments_open}
                onClose={onClose_mdl_assignments}
                onChange={populate_organizations}
                organization_organization_id={var_organization_organization_id}
                organization_name={var_organization_name}
                affiliate_status={var_organization_affiliate_status}
            />

            <MDL_ADDASSIGNMENTS
                display={var_mdl_addassignments_open}
                onClose={() => set_mdl_addassignments_open(false)}
                onChange={onChange_add_assingment}
                organization_organization_id={var_organization_organization_id}
            />

        </>

    )
}

import React, { useState } from 'react';
import API from 'libs/api-lib';
import form_helper from 'libs/form-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_INPUT, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import 'i18n';



export default function CRD_DETAILS({ requirement_package, onChange_package }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_updated_package, set_updated_package ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    async function update_package(packagedata) {
        set_processing(true);
        try {
            await API_put_package_details(packagedata);
            onChange_package();
            set_mode('VIEW');
        } catch(e) {
            console.log(e);
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_package_details(packagedata) {
        return API.put('requirements', '/put-package-details/' + requirement_package.id,
            {
                body: packagedata
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_updated_package({...requirement_package});
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_errors([]);
        set_save_error(null);
        set_mode('VIEW');
    }

    function onClick_save() {
        set_save_error(null);
        if (var_processing) return;

        let packagedata = {...var_updated_package};
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(packagedata.name)) {
            errors.push({ property: 'name', description: t('Name is required') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        form_helper.cleanse_string_property(packagedata, 'name');
        set_updated_package(packagedata);
        update_package(packagedata);
    }

    function onChange_input(event) {
        set_save_error(null);
        let packagedata = {...var_updated_package};
        packagedata[event.target.name] = event.target.value;
        set_updated_package(packagedata);
    }

    function onChange_status(value) {
        set_save_error(null);
        set_updated_package({ ...var_updated_package, status: value });
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_pkgdetails_details' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('Package details')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    {var_mode === 'VIEW' &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {var_mode === 'VIEW' ?
                <>
                    <div className='card__content'>
                        <FORM_DETAILSGROUP label={t('Status')} value={requirement_package.status === 'ACTIVE' ? t('Active') : t('Inactive')} type='badge' badgecolor={requirement_package.status === 'ACTIVE' ? 'badge--green' : 'badge--gray'} />
                        <FORM_DETAILSGROUP label={t('Name')} value={requirement_package.name} />
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    </div>

                </>
                : var_mode === 'EDIT' ?
                    <>
                        <Form className='card__content'>

                            {var_save_error &&
                                <Message error header={var_save_error} />
                            }

                            {var_errors.length > 0 &&
                                <Message
                                    error icon={<Icon name='error' className='icon' />}
                                    header={t('There are some errors with your inputs')}
                                />
                            }

                            <FORM_TOGGLE
                                label={t('Status')}
                                value={var_updated_package.status}
                                options={[{ value: 'ACTIVE', text: t('Active')}, {value: 'ARCHIVE', text: t('Inactive') }]}
                                onChange={onChange_status}
                            />

                            <FORM_INPUT
                                property='name'
                                label={t('Name')}
                                value={var_updated_package.name}
                                onChange={onChange_input}
                                placeholder={t('Name')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />
                        </Form>

                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                            <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                        </div>
                    </>

                : null
            }

            <Processing display={var_processing} processingtext={t('Processing')} />

        </div>
    );
}
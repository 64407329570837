import React, { useState } from 'react';
import API from 'libs/api-lib';
import form_helper from 'libs/form-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_INPUT, FORM_TEXTAREA, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import 'i18n';



export default function CRD_DETAILS({ requirement, onChange_requirement }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_updated_requirement, set_updated_requirement ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------

    async function update_requirement(updated_requirement) {
        set_processing(true);
        try {
            await API_put_requirement_details(updated_requirement);
        } catch(e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
        onChange_requirement();
        set_mode('VIEW');
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_requirement_details(updated_requirement) {
        return API.put('requirements2', '/put-org-requirement/' + requirement.id,
            {
                body: updated_requirement
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_updated_requirement({...requirement});
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_errors([]);
        set_save_error(null);
        set_mode('VIEW');
    }

    function onClick_save() {
        if (var_processing) return;

        let updated_requirement = {...var_updated_requirement};
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(updated_requirement.name)) {
            errors.push({ property: 'name', description: t('Name is required') });
        }

        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(updated_requirement, 'name');
        form_helper.cleanse_string_property(updated_requirement, 'description');

        set_updated_requirement(updated_requirement);
        update_requirement(updated_requirement);
    }

    function onChange_input(event) {
        let requirement = {...var_updated_requirement};
        requirement[event.target.name] = event.target.value;
        set_updated_requirement(requirement);
        if (var_errors.length > 0) {
            let errors = var_errors.filter(error => error.property !== event.target.name)
            set_errors(errors);
        }
    }

    function onChange_requirement_status(status) {
        let requirement = {...var_updated_requirement};
        requirement.status = status;
        set_updated_requirement(requirement);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_reqdetails_details' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_reqdetails_details'>{t('Requirement details')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    {var_mode === 'VIEW' &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {requirement.status === 'DRAFT' &&
                <Message warning
                    icon={<Icon name='warning' className='icon' alt={t('warning icon')} />}
                    header={t('Pre-qualifications must be added to this requirement before it can be published')}
                />
            }

            {var_mode === 'EDIT'
                ?
                <>
                    <Form className='card__content' id='form_reqdetails_details' aria-labelledby='hdr_reqdetails_details'>

                        {var_save_error &&
                            <Message error header={var_save_error} />
                        }

                        {var_errors.length > 0 &&
                            <Message
                                error icon={<Icon name='error' className='icon' />}
                                header={t('There are some errors with your inputs')}
                            />
                        }

                        <FORM_TOGGLE
                            label={t('Status')}
                            value={var_updated_requirement.status}
                            options={[{value: 'ACTIVE', text: t('Published')}, (requirement?.status === 'DRAFT' ? { value: 'DRAFT', text: t('Draft') } : { value: 'INACTIVE', text: t('Unpublished') })]}
                            onChange={onChange_requirement_status}
                            disabled={requirement?.status === 'DRAFT'}
                        />

                        <FORM_INPUT
                            property='name'
                            label={t('Name')}
                            value={var_updated_requirement.name}
                            onChange={onChange_input}
                            placeholder={t('Name')}
                            maxLength={100}
                            disabled={false}
                            errors={var_errors}
                        />

                        <FORM_TEXTAREA
                            property='description'
                            label={t('Description (optional)')}
                            value={var_updated_requirement.description}
                            onChange={onChange_input}
                            placeholder={t('Description')}
                            maxLength={500}
                            disabled={false}
                            errors={var_errors}
                        />

                    </Form>

                    <div className='card__footer'>
                        <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                        <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                    </div>

                </>
                :
                <>
                    <div className='card__content'>
                        <FORM_DETAILSGROUP label={t('Status')} value={requirement.status === 'ACTIVE' ? t('Published') : requirement.status === 'DRAFT' ? t('Draft') : t('Unpublished')} type='badge' badgecolor={requirement.status === 'ACTIVE' ? 'badge--green' : requirement.status === 'DRAFT' ? 'badge--yellow' : 'badge--gray'}/>
                        <FORM_DETAILSGROUP label={t('Name')} value={requirement.name} />
                        <FORM_DETAILSGROUP label={t('Description')} value={requirement.description} />
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={() => onClick_edit()}>{t('Edit')}</Button>
                    </div>

                </>

            }

            <Processing display={var_processing} processingtext={t('Processing')} />

        </div>
    );
}
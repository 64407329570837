import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button } from 'semantic-ui-react';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';
import auth from 'libs/auth-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';

import MDL_OVERRIDE from './mdl_override/mdl_override';


export default function TAB_DAILYCHECKS({ assignment, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_dailychecks, set_dailychecks ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_status, set_status ] = useState('PASS');
    const [ var_canoverride, set_canoverride ] = useState(false);
    const [ var_override_id, set_override_id ] = useState(null);
    const [ var_override_open, set_override_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'DAILYCHECKS' && assignment.location_id) {
            set_refreshtable(!var_refreshtable);
            populate_records();
            set_canoverride(auth.has_access(assignment, 'ORG-DAILY', 'override'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, assignment.location_id]);

    useEffect(() => {
        if (activetab === 'DAILYCHECKS' && assignment.location_id) {
            set_refreshtable(!var_refreshtable);
            populate_records({ ...var_populateconfig, offset: 0, filter: {} }, var_status); // repopulate using offset 0 and no filter
            set_focuselement(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_status]);

    useEffect(() => {
        if (!var_override_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
            } else {
                document.querySelector('#crd_dailycheck').focus();
            }
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_override_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, status) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        if (status) {
            set_status(status);
        } else {
            status = var_status;
        }

        try {
            let results = await API_get_loc_dailychecks(limit, offset, sortby, sortorder, filter, status);
            set_totalrows(results.totalrows);
            set_dailychecks(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);

            if (var_focuselement?.id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${var_focuselement.id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_dailycheck').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
            }

        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_loc_dailychecks(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, var_status, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_loc_dailychecks(limit, offset, sortby, sortorder, filter, var_status)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_dailychecks(limit, offset, sortby, sortorder, filter, status, filtername) {
        return API.post('dailycheck', '/get-loc-dailychecks/' + assignment.location_id + '/' + status,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_status(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_status(status);
    }

    function onClick_individual(id) {
        navigate(`/assignments/${assignment.location_id}/DAILYCHECKS/individual/${id}`);
    }

    function onClick_override(event, id) {
        event.stopPropagation();
        set_focuselement(document.activeElement);
        set_override_id(id);
        set_override_open(true);
    }

    function onOverride() {
        populate_records();
        set_override_open(false);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_dailycheck' tabIndex='0'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div id='hdr_daily_check' className='text--xl-medium'>{t('Daily Check')}</div>
                </div>
            </div>

            <Table id='dailychecks' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname={t('Daily Check')} downloadfunction={download_records}
                    onChange={populate_records}>

                <Table.OptionsButtons>
                    <TOGGLE value={var_status} options={[{value: 'PASS', text: t('Pass')}, {value: 'FAIL', text: t('Failed')}, {value: 'MISSING', text: t('Missing')}]} onChange={onChange_status} />
                </Table.OptionsButtons>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                        <Table.HeaderCell field='email' datatype='text' filtertype='text'>{t('EMAIL')}</Table.HeaderCell>
                        <Table.HeaderCell field='job_title' datatype='text' filtertype='text'>{t('JOB TITLE')}</Table.HeaderCell>
                        <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                        {var_status === 'PASS' &&
                            <Table.HeaderCell field='mgr_note' datatype='text' filtertype='text'>{t('COMMENT')}</Table.HeaderCell>
                        }
                        {var_canoverride && var_status === 'FAIL' &&
                            <Table.HeaderCell></Table.HeaderCell>
                        }
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {var_dailychecks.map(item =>
                        <Table.Row key={item.individual_id} >
                            <Table.Cell className='text--anchor'>
                                <Button className='tertiary' onClick={() => onClick_individual(item.individual_id)}>
                                    {item.individual_name}
                                </Button>
                            </Table.Cell>
                            <Table.Cell>{item.email}</Table.Cell>
                            <Table.Cell>{item.job_title}</Table.Cell>
                            <Table.Cell className={(!var_canoverride || var_status === 'MISSING') ? 'cell__icon--right' : undefined}>
                                <Table.CellContentTwoLine secondary_text={t(item.organization)} display_chevron={!var_canoverride || var_status === 'MISSING'} />
                            </Table.Cell>
                            {var_status === 'PASS' &&
                                <Table.Cell className='cell__icon--right'>
                                    <Table.CellContentTwoLine secondary_text={t(item.mgr_note)} display_chevron={true} />
                                </Table.Cell>
                            }
                            {var_canoverride && var_status === 'FAIL' &&
                                <Table.Cell>
                                    <Button className='tertiary' onClick={(event) => onClick_override(event, item.id)}>{t('Override')}</Button>
                                </Table.Cell>
                            }
                        </Table.Row>
                    )}
                </Table.Body>

            </Table>

        <MDL_OVERRIDE individualdailycheck_id={var_override_id} display={var_override_open} onClose={() => set_override_open(false)} onOverride={onOverride} />

        </div>
    );

}
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import { SELECT, TOGGLE } from 'components/cmp_form/cmp_form';
import CMP_MDL_CERTIFICATE from 'components/cmp_mdl_certificate/cmp_mdl_certificate';
import datelib from 'libs/date-lib';
import { Button } from 'semantic-ui-react';
import 'i18n';

import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';


export default function TAB_CREDENTIALS({ assignment, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_status, set_status ] = useState('ALL');
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_certificate_open, set_certificate_open ] = useState(false);
    const [ var_selected_credential_id, set_selected_credential_id ] = useState(null);

    const [ var_credentialstatus, set_credentialstatus ] = useState('ALL');

    const [ var_docviewer_id, set_docviewer_id ] = useState(null);
    const [ var_docviewer_open, set_docviewer_open ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'CREDENTIALS' && assignment.location_id) {
            set_refreshtable(!var_refreshtable);
            populate_records({ ...default_populateconfig, limit: 10, sortby: 'individual_name' }, var_credentialstatus);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, assignment.location_id]);

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_records({ ...var_populateconfig, offset: 0, filter: {} }, var_credentialstatus); // repopulate using offset 0 and no filter
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_credentialstatus]);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_credentials').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);

    useEffect(() => {
        if (!var_certificate_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_credentials').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_certificate_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, status) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        if (status) {
            set_status(status);
        } else {
            status = var_status;
        }

        try {
            let results = await API_get_loc_credentials(limit, offset, sortby, sortorder, filter, status);
            set_totalrows(results.totalrows);
            set_credentials(results.results === undefined ? [] : results.results);
            set_loadingerror(false);
            set_loading(false);
            set_ready(true);

            if (var_focuselement?.id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${var_focuselement.id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_credentials').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
            }
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_loc_credentials(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, var_status, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_loc_credentials(limit, offset, sortby, sortorder, filter, var_status)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_credentials(limit, offset, sortby, sortorder, filter, status, filtername) {
        return API.post('credentials', '/get-loc-credentials/' + assignment.location_id + '/' + status,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    tz: datelib.timezone,
                    filtername: filtername
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_status(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_credentialstatus(status);
    }

    function onClick_document(id) {
        set_focuselement(document.activeElement);
        set_docviewer_id(id);
        set_docviewer_open(true);
    }

    function onClick_credential(id) {
        set_focuselement(document.activeElement);
        set_selected_credential_id(id);
        set_certificate_open(true);
    }

    function onClick_individual(id) {
        navigate(`/assignments/${assignment.location_id}/INDIVIDUALS/individual/${id}`);
    }

    // RENDER APP ======================================================================================================

    return (
        <>

            <div className='card rounded-lg shadow-sm tab_credentials__container' id='crd_credentials' tabIndex='0'>

            <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_credentials' className='text--xl-medium'>{t('Credentials')}</div>
                    </div>
                </div>

                <Table id='credentials' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Credentials')} downloadfunction={download_records}
                        onChange={populate_records}>

                    <Table.OptionsButtons>
                        <div className='table__controls--mobile' style={{ width: '19.125rem' }}>
                            <SELECT value={var_status} onChange={(e, data) => onChange_status(data.value)}
                                options={[
                                    { value: 'ALL', text: t('All') },
                                    { value: 'VALID', text: t('Valid') },
                                    { value: 'PENDING', text: t('Pending') },
                                    { value: 'EXPIRING', text: t('Expiring') },
                                    { value: 'INVALID', text: t('Invalid') },
                                    { value: 'IN_PROGRESS', text: t('In progress') }
                                ]} />
                        </div>
                        <div className='table__controls--desktop'>
                            <TOGGLE className='' value={var_status} onChange={onChange_status}
                                options={[
                                        { value: 'ALL', text: t('All') },
                                        { value: 'VALID', text: t('Valid') },
                                        { value: 'PENDING', text: t('Pending') },
                                        { value: 'EXPIRING', text: t('Expiring') },
                                        { value: 'INVALID', text: t('Invalid') },
                                        { value: 'IN_PROGRESS', text: t('In progress') }
                                ]} />
                        </div>
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='verification_status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('HOLDER')}</Table.HeaderCell>
                            <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell field='issuer' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                            <Table.HeaderCell field='issued' datatype='date' filtertype='date'>{t('ISSUED')}</Table.HeaderCell>
                            <Table.HeaderCell field='expires' datatype='date' filtertype='date'>{t('EXPIRES')}</Table.HeaderCell>
                            <Table.HeaderCell field='submittedfilename' datatype='text' filtertype='text'>{t('ATTACHMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='confirmation_status_display' datatype='text' filtertype='option'>{t('CONFIRMATION')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_credentials.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <div className={'badge text--sm-medium  ' +
                                        (   item.confirmation_status === 'Expired' ?
                                                'badge--red'
                                            : item.verification_status === 'Valid' ?
                                                'badge--green'
                                            : item.verification_status === 'Invalid' ?
                                                'badge--red'
                                            :  ['Pending', 'In Progress'].includes(item.verification_status) ?
                                                'badge--yellow'
                                            : 'badge--gray')}>
                                        {item.verification_status_display}
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <CMP_VERIFIED_IDENTITY_DISPLAY
                                        individual_name={item.individual_name}
                                        onClick_individual={() => onClick_individual(item.individual_id)}
                                        verified_identity_credential_id={item.identity_verification_credential_id}
                                    />
                                </Table.Cell>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' onClick={() => onClick_credential(item.id)} >
                                        {item.credential_name}
                                        {item.has_customsecurity === 'YES' && <Icon name='lock' className='icon__lock color--gray-400' alt={t('Credential has security restrictions')} />}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.issuer}</Table.Cell>
                                <Table.Cell>{datelib.epoch_to_date_string(item.issued)}</Table.Cell>
                                <Table.Cell>{datelib.epoch_to_date_string(item.expires)}</Table.Cell>
                                <Table.Cell>
                                    {item.submittedfilename &&
                                        <Button className='tertiary' onClick={() => onClick_document(item.id)}>
                                            <Icon name='attachment' className='icon__attachment' alt={t('Preview attachment')} />
                                        </Button>
                                    }
                                </Table.Cell>
                                <Table.Cell>{item.confirmation_status_display}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

            </div>

            {/***** MODAL: DOC VIEWER *******************************************************************************/}

            <MDL_DOCVIEWER
                targetid={var_docviewer_id}
                classification='credential'
                is_open={var_docviewer_open}
                onClose={() => set_docviewer_open(false)}
            />

            {/***** END MODAL: DOC VIEWER ***************************************************************************/}

            <CMP_MDL_CERTIFICATE
                credential_id={var_selected_credential_id}
                verifiable_credential={null}
                is_open={var_certificate_open}
                onClose={() => set_certificate_open(false)}
            />

        </>
    );

}
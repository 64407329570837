import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TABS from 'components/cmp_tabs/cmp_tabs';
import TAB_DETAILS from './tab_details/tab_details';
import TAB_CREDENTIALS from './tab_credentials/tab_credentials';
import TAB_SHARING from './tab_sharing/tab_sharing'

export default function TRANSCRIPT_DETAILS() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_tab, set_tab ] = useState('DETAILS');
    const [ var_transcript, set_transcript ] = useState({});



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_tab === 'DETAILS') {
            window.scrollTo(0, 0);
            populate_transcript_details();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_tab]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_transcript_details() {
        try {
            let results = await API_get_transcript_details();
            set_transcript(results);
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_transcript_details() {
        return API.get('transcripts', '/get-individual-transcript-details/' + params.transcriptid);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }


    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: '/transcripts', text: t('Transcripts') }]}
                current_page_text={var_transcript.transcript_name || ''}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{var_transcript.name}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <TABS activetab={var_tab} onClick={onClick_tab} aria_label={t('Transcript')} id='tabs_transcript_details'>
                    <TABS.TAB name='DETAILS' label={t('Transcript')} />
                    <TABS.TAB name='CREDENTIALS' label={t('Credentials')} />
                    <TABS.TAB name='SHARING' label={t('Sharing')} />
                </TABS>

                {var_tab === 'DETAILS' &&
                    <TAB_DETAILS transcript={var_transcript} />
                }

                {var_tab === 'CREDENTIALS' &&
                    <TAB_CREDENTIALS transcript={var_transcript} />
                }

                {var_tab === 'SHARING' &&
                    <TAB_SHARING transcript={var_transcript}/>
                }
            </div>

        </div>
    )
};
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';

import Icon from 'components/cmp_icon';

function MDL_REQUEST_VERIFICATION({ is_open, onClose, onChange, available_credits }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_selected_individuals, set_selected_individuals ] = useState([]);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (is_open) {
            // reset and populate options
            set_modal(document.querySelector('#mdl_request_verification'));
            set_individuals([]);
            set_selected_individuals([]);
            set_loading(true);
            set_loadingerror(false);
            set_errors([]);
            set_processing(false);
            populate_individual_options();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individual_options() {
        try {
            let results = await API_get_individuals_to_request_verification();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: item.individual_name,
                secondary_text: item.is_requested === 'YES' ? item.status : item.email,
                disabled: item.is_requested === 'YES'
            }));
            set_individuals(transformed);
            if (results.every(item => item.disabled)) {
                set_errors([{description: t('There are no available individuals to request verifications for')}])
            }
        } catch (e) {
            console.log(e);
            set_errors([{description: t('There was a problem loading.  Please try again later')}]);
            set_loadingerror(true);
        }
        set_loading(false);
    }

    async function request_verifications() {
        if (var_processing) return;
        set_processing(true);
        try {
            await API_post_verification_request();
            onChange && onChange();
        } catch (e) {
            set_errors([{description: t('There was a problem saving.  Please try again later')}]);
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individuals_to_request_verification() {
        return API.get('identity-verification', '/get-individuals-to-request-verification');
    }

    function API_post_verification_request() {
        return API.post('identity-verification', '/post-verification-request', {
            body: {
                individual_ids: var_selected_individuals
            }});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_individuals(value) {
        set_selected_individuals(value);
        set_errors([]);
    }

    async function onClick_request() {
        if (var_selected_individuals.length === 0) {
            set_errors([{ description: t('Please select at least one individual') }]);
            return;
        } else if (var_selected_individuals.length > available_credits) {
            set_errors([{ description: t('Sorry, you don\'t have enough available credits to select more individuals.') }]);
            return;
        }
        await request_verifications();
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer={'inverted'}
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={false}
            id='mdl_request_verification'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_request_verification'>
            <div className='modal__header'>
                <div style={ { width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium' id='hdr_request_verification'>{t('Request verification')}</div>
                    </div>
                </div>
            </div>
            <Form className='modal__content' tabIndex='0' id='request_verification_form' aria-labelledby='hdr_request_verification'>
                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_errors[0].description} />
                }
                {!var_loading && !var_loadingerror &&
                    <FORM_SELECT_RADIO
                        name='individuals'
                        property='individuals'
                        label={t('Individuals')}
                        value={var_selected_individuals}
                        options={var_individuals}
                        single_or_multiple='MULTIPLE'
                        onChange={onChange_individuals}
                        placeholder={t('Search')}
                        errors={var_errors}
                        aria_label={t('Select the individuals you would like to request verifications for.')}
                    />
                }
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_request} disabled={var_individuals.length === 0}>{t('Request')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

export default MDL_REQUEST_VERIFICATION;
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import datelib from 'libs/date-lib';
import 'i18n';

import CMP_ASSIGNMENT_CARD from 'components/cmp_assignment_card/cmp_assignment_card';


export default function CMP_ASSIGNMENTS({ refresh }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_assignments, set_assignments ] = useState([]);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_assignments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_assignments() {
        try {
            let results = await API_get_assignments();
            set_assignments(results);
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_assignments() {
        return API.get('indv', '/get-assignments', { queryStringParameters: { tz: datelib.timezone }});
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_assignment(id) {
        navigate('/assignments/' + id);
    }

    function onKeyDown(event, id) {
        if (event.key === 'Enter') {
            onClick_assignment(id);
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            {var_assignments[0] &&
                <>
                    <div className='launchcard__header text--md-regular'>{t('Your assignments')}</div>
                    <nav aria-label={t('Assignments')}>
                        <div className='launchcard__wrapper' role='navigation' id='assignments'>
                            {var_assignments.map((item, index) =>
                                <CMP_ASSIGNMENT_CARD key={item.location_id} name={item.location_name} address={item.location_address}
                                    organization_id={item.organization_id} organization_name={item.organization_name} logo={item.logo}
                                    status='ACTIVE' assignment_status={item.assignment_status} cardtype='COMPACT' onClick={() => onClick_assignment(item.location_id)} onKeyDown={(e) => onKeyDown(e, item.location_id)} index={index}/>
                            )}

                            {/* Future item
                            <div className='launchcard add_assignment' onClick={onClick_addassignment}>
                                <div className='launchcard__text'>
                                    <div className='text--md-medium'>Add assignment</div>
                                </div>
                            </div>
                            */}
                        </div>
                    </nav>
                </>
            }
        </>
    );
}

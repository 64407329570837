import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message, Form } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/cmp_icon';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import form_helper from 'libs/form-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Processing from 'components/cmp_processing';
import 'i18n';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_ADD_CREDENTIAL from './mdl_add_credential/mdl_add_credential'

import './crd_rejected.css';



export default function CRD_REJECTED({ step, onChange_step, requirement, onChange_requirement, onChange_processing, display_processing }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_opened, set_opened ] = useState(false)
    const [ var_save_error, set_save_error ] = useState()
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_addcredential_open, set_addcredential_open ] = useState(false);

    const [ var_delete_id, set_delete_id ] = useState('');
    const [ var_delete_confirmation_open, set_delete_confirmation_open ] = useState(false);

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'credential' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_credentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement.rejected]);

    useEffect(() => {
        if(step === 'REJECTED') {
            set_opened(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        set_save_error(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement]);

    useEffect(() => {
        if (!var_addcredential_open && var_focuselement?.id === 'btn_addcredential') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_addcredential_open]);

    useEffect(() => {
        if (!var_delete_confirmation_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_rejected-credentials').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_delete_confirmation_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_requirement(updated_requirement) {
        onChange_processing(true);
        try {
            let requirement_id = await API_post_requirement(updated_requirement);
            navigate('/org/requirements/' + requirement_id);
        } catch (e) {
            console.log(e);
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
        onChange_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_requirement(updated_requirement) {
        return API.post('requirements', '/post-requirement',
            {
                queryStringParameters: {
                    tz: datelib.timezone
                },
                body: updated_requirement
            });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function populate_credentials() {
        set_totalrows(requirement.rejected.length);
        set_ready(true);
    }

    function onClick_edit() {
        onChange_step('REJECTED');
    }

    function onClick_addcredential() {
        set_focuselement(document.activeElement);
        set_save_error(false);
        set_addcredential_open(true);
    }

    function onClick_delete_rejected(id) {
        set_focuselement(document.activeElement);
        set_save_error(false);
        set_delete_id(id);
        set_delete_confirmation_open(true);
    }

    function onConfirm_delete() {
        let updated_requirement = { ...requirement };
        updated_requirement.rejected = requirement.rejected.filter(credential => credential.credential_id !== var_delete_id && credential.issuer_id !== var_delete_id);
        onChange_requirement(updated_requirement);
        set_focuselement(document.getElementById('crd_rejected-credentials'));
        set_delete_confirmation_open(false);
    }

    function onChange_rejected_credentials(updated_requirement) {
        onChange_requirement(updated_requirement);
        set_addcredential_open(false);
    }

    function onClick_save() {
        set_save_error(false);
        let updated_requirement = {...requirement};
        if (!form_helper.validate_required_string(requirement.name)) {
            set_save_error(t('Missing required field from Step 1'));
            return;
        }
        form_helper.cleanse_string_property(updated_requirement, 'name');
        form_helper.cleanse_string_property(updated_requirement, 'description');
        onChange_requirement(updated_requirement);

        insert_requirement(updated_requirement);
    }



    // RENDER APP ======================================================================================================

    return (
        <div id='crd_rejected-credentials' className='card rounded-lg shadow-sm' tabIndex='0'>

            <div className='card__header'>
                <div className='card__header__icon'>
                    {(var_opened && step !== 'REJECTED') ?
                        <Icon name='checkmark' className='icon__checkmark' alt='checkmark icon' />
                        :
                        <div className={'card__header__icon__step text--xl-bold' + (var_opened ? ' icon--dark_gray' : ' icon--light_gray')}>3</div>
                    }
                </div>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('Rejected credentials')}</div>
                    {(!var_opened || step === 'REJECTED') &&
                        <div className='text--sm-regular'>{t('Select credentials that are not accepted to meet this requirement.  This step is optional.')}</div>
                    }
                </div>
                <div className='card__header__right card__header__btns'>
                    {(var_opened && step !== 'REJECTED') &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {(step !== 'REJECTED' && var_opened) ?
                <>
                    <div id='create_credential__access' className='card__content'>
                        {var_save_error &&
                            <Message error header={var_save_error} />
                        }
                        <Table  id='credentials'
                                loading={false} ready={var_ready}
                                loadingerror={false} lockcolumns={0}
                                refresh={var_refreshtable} totalrows={var_totalrows}
                                populateconfig={var_populateconfig}
                                populatefilterfunction={() => null}
                                downloadname={t('credentials')}
                                downloadfunction={() => null}
                                onChange={populate_credentials}
                                hide_tablecontrols={true}>

                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell field='issuer' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                                    <Table.HeaderCell field='primary_text' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {requirement.rejected.map(item =>
                                    <Table.Row key={item.credential_id || item.issuer_id}>
                                        <Table.Cell>{item.issuer_name}</Table.Cell>
                                        <Table.Cell>{item.credential_name}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    </div>
                </>
                : step === 'REJECTED' ?
                    <>
                        <Form className='card__content'>

                            {var_save_error &&
                                <Message error header={var_save_error} />
                            }

                            <Table id='credentials'
                                   loading={false} ready={var_ready}
                                   loadingerror={false} lockcolumns={0}
                                   refresh={var_refreshtable} totalrows={var_totalrows}
                                   populateconfig={var_populateconfig}
                                   populatefilterfunction={() => null}
                                   downloadname={t('credentials')}
                                   downloadfunction={() => null}
                                   onChange={populate_credentials}
                                   hide_tablecontrols={true}>

                                <Table.OptionsButtons>
                                    <Button id='btn_addcredential' className='secondary' onClick={onClick_addcredential}>{t('Add credential')}</Button>
                                </Table.OptionsButtons>

                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell field='issuer' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                                        <Table.HeaderCell field='primary_text' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {requirement.rejected.map(item =>
                                        <Table.Row key={item.credential_id || item.issuer_id}>
                                            <Table.Cell>{item.issuer_name}</Table.Cell>
                                            <Table.Cell className='cell__icon--right'>
                                                <div>{item.credential_name}</div>
                                                <Button className='tertiary' onClick={() => onClick_delete_rejected(item.credential_id || item.issuer_id)} >
                                                    <Icon name='delete' className='color--primary-500' />
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>

                            <CMP_CONFIRMATION
                                display={var_delete_confirmation_open}
                                title={requirement.rejected.some(item => item.issuer_id === var_delete_id) ? t('Remove issuer') : t('Remove credential')}
                                message={
                                <div style={{flexDirection: 'column'}}>
                                    <div style={{marginBottom: '0.5rem'}}>
                                        {requirement.rejected.some(item => item.issuer_id === var_delete_id)
                                        ? t('Are you sure you want to remove') + ' ' + requirement.rejected.find(item => item.issuer_id === var_delete_id)?.issuer_name + ' ' + t('from the rejected list?')
                                        : t('Are you sure you want to remove') + ' ' + requirement.rejected.find(item => item.credential_id === var_delete_id)?.credential_name + ' ' + t('from the rejected list?')
                                        }
                                        </div>
                                </div>
                            }
                                positive_option={t('Remove')}
                                negative_option={t('Cancel')}
                                onConfirm={onConfirm_delete}
                                onCancel={() => set_delete_confirmation_open(false)}
                            />

                            <MDL_ADD_CREDENTIAL
                                display={var_addcredential_open}
                                onClose={() => set_addcredential_open(false)}
                                requirement={requirement}
                                onChange={onChange_rejected_credentials}
                            />

                            <div className='card__footer'>
                                <Button type='submit' className='primary' onClick={onClick_save}>{t('Save requirement')}</Button>
                            </div>
                        </Form>
                    </>
                    : null
            }

            <Processing display={display_processing} processingtext={t('Processing')} />

        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup, Form } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Processing from 'components/cmp_processing';
import Icon from 'components/cmp_icon';
import { CHECKBOX } from 'components/cmp_form/cmp_form';
import 'i18n';

export default function CMP_INDIVIDUAL_TYPE({ item, current_type, is_open, onClose, onOpen, onChange, onMessage }) {


    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_popup, set_popup ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_individual_typepopup_value, set_individual_typepopup_value ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);



    const POPUP_ID = (`mdl_individual_type_${item?.id}`);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open) {
            set_individual_typepopup_value(item.individual_type);
            set_popup(document.querySelector(`#${POPUP_ID}`));
        } else if (var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_popup) {
            //  create list of focusable elements within the popup
            let elements = var_popup.querySelectorAll('form, input[type="checkbox"]:not([disabled]), textarea:not([disabled]), button:not([disabled])');
            let firstelement = elements[0];
            let lastelement = elements[elements.length - 1];

            //  set focus to first element within the popup
            firstelement.focus({ preventScroll: true });

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_popup.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_popup]);


    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_org_indv_details(organization_individual_id, permission_id, individual_type, job_title) {
        return API.put('org-indv', '/put-org-indv-details/' + organization_individual_id,
            {
                body: {
                    permission_id: permission_id,
                    individual_type: individual_type,
                    job_title: job_title
                }
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_updateindividual_type(item) {
        onMessage(null, null);
        if (current_type === var_individual_typepopup_value) {
            onChange(var_focuselement?.id);
            return;
        }

        if(!var_individual_typepopup_value) {
            onMessage('ERROR', t('Please select a type.'));
            return;
        }
        set_processing(true);
        try {
            await API_put_org_indv_details(item.organization_individual_id, item.permission_id, var_individual_typepopup_value, item.job_title);
        } catch(e) {
            console.log(e);
        }

        set_processing(false);
        onChange(var_focuselement?.id);
    }

    function onClick_type(){
        set_focuselement(document.activeElement);
    }


    // RENDER APP ======================================================================================================

    return (
        <Popup
            trigger={
                <Button
                    onClick={onClick_type}
                    id={`btn_${POPUP_ID}`}
                    className={'badge text--sm-medium  ' +
                    (   item.individual_type === 'USER' ? 'badge--solid_gray'
                        : item.individual_type === 'GUEST' ? 'badge--solid_pink'
                        : 'badge--gray')}
                    type='button'
                >
                    {item.individual_type_display.substring(0, 1) + item.individual_type_display.substring(1).toLowerCase()}
                    <Icon name='badge_dropdown' className='badge__icon' />
                </Button>
            }
            content={
                is_open ?
                    <div className='badge__popup shadow-lg rounded-md' id={POPUP_ID} aria-modal='true' role='dialog' aria-labelledby={`hdr_${POPUP_ID}`}>
                        <div id={`hdr_${POPUP_ID}`} className='text--md-medium' aria-label={t('Change individual type')}>{t('Change type')}</div>
                        <Form tabindex='0' aria-labelledby={`hdr_${POPUP_ID}`}>
                            <div className='badge__popup__option'>
                                <CHECKBOX radio name='updateindividual_type' label={t('User')} value='USER' checked={var_individual_typepopup_value === 'USER'} onChange={() => set_individual_typepopup_value('USER')} />
                            </div>
                            <div className='badge__popup__option'>
                                <CHECKBOX radio name='updateindividual_type' label={t('Guest')} value='GUEST' checked={var_individual_typepopup_value === 'GUEST'} onChange={() => set_individual_typepopup_value('GUEST')} />
                            </div>
                            <Button type='button' className='badge__popup__button secondary' onClick={() => onClick_updateindividual_type(item)}>{t('Update')}</Button>

                        </Form>
                        <Processing display={var_processing} processingtext='' />
                        </div>
                    : null
            }
            on='click'
            open={is_open}
            onOpen={() => onOpen(item.id)}
            onClose={onClose}
            hideOnScroll
            position='bottom left' />
    );
}
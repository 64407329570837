import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { SELECT_RADIO } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from 'components/cmp_icon';

import './mdl_addassignment.css';



export default function MDL_ADDASSIGNMENT({ display, onClose, organization_organization_id, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_selected_assignments, set_selected_assignments ] = useState([]);
    const [ var_error, set_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display && organization_organization_id) {
            // reset and populate options
            set_focuselement(document.activeElement);
            set_modal(document.querySelector('#mdl_addassignment'));
            set_assignments([]);
            set_selected_assignments([]);
            set_error(null);
            set_processing(false);
            populate_assignments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, organization_organization_id]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_assignments() {
        try {
            let results = await API_get_available_network_assignments();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: item.location_name,
                disabled: item.is_networked === 'YES'
            }));
            set_assignments(transformed);
        } catch (e) {
            console.log(e);
        }
    }

    async function add_assignment() {
        set_processing(true);
        try {
            await API_post_network_assignment();
            onChange && onChange(var_focuselement?.id);
            onClose();
        } catch (exception) {
            console.log(exception);
            set_error(t('An unknown error has occurred. Please try again.'));
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_available_network_assignments() {
        return API.get('org-profile', '/get-available-network-assignments/' + organization_organization_id);
    }

    function API_post_network_assignment(){
        return API.post('org-profile', '/post-network-assignment/' + organization_organization_id,
            {
                body: { location_ids: var_selected_assignments }
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_assignments(value) {
        set_selected_assignments(value);
        set_error(null);
    }

    function onClick_add() {
        set_error(null);
        if (var_selected_assignments.length === 0) {
            set_error(t('Please select at least one assignment to add'));
            return;
        }

        add_assignment();
    }



    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_addassignment'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_add_assignment'>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_add_assignment'>{t('Add assignment')}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_add_assignment' tabIndex='0' aria-labelledby='hdr_add_assignment'>
                {var_error &&
                    <Message error
                        icon={<Icon name='error' className='icon' alt={t('alert icon')} />}
                        header={var_error} />
                }
                <SELECT_RADIO
                    name='holders'
                    value={var_selected_assignments}
                    options={var_assignments}
                    single_or_multiple='MULTIPLE'
                    onChange={onChange_assignments}
                    aria_label={t('Select the assignments you want to share with this organization')}
                />
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add} disabled={var_assignments.length === 0}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

}
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './tab_prequalifiers.css';
import MDL_ATTESTATION from './mdl_attestation/mdl_attestation';
import MDL_CREDENTIAL from './mdl_credential/mdl_credential';
import MDL_CREDIT_CREDENTIAL from './mdl_credit_credential/mdl_credit_credential';
import CMP_DOCUMENTACKNOWLEDGE from 'components/cmp_documentacknowledge/cmp_documentacknowledge';
import MDL_SURVEY from './mdl_survey/mdl_survey';
import CMP_VP_REQUEST from 'components/cmp_vp_request/cmp_vp_request';

export default function TAB_PREQUALIFIERS({ credential, onChange, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_prequalifiers, set_prequalifiers ] = useState([]);
    const [ var_selected_prequalifier, set_selected_prequalifier ] = useState(null);
    const [ var_selected_prequalifier_type, set_selected_prequalifier_type ] = useState(null);
    const [ var_selected_prequalifier_text, set_selected_prequalifier_text ] = useState(null);
    const [ var_document_id, set_document_id ] = useState(null);
    const [ var_document_requires_acknowledgement, set_document_requires_acknowledgement ] = useState(false);
    const [ var_presentation_request, set_presentation_request ] = useState(null);
    const [ var_presentation_request_preapproval_info, set_presentation_request_preapproval_info ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'PREQUALIFIERS' && credential.id) {
            populate_prequalifiers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, credential.id]);

    useEffect(() => {
        if(!var_selected_prequalifier_type && var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_selected_prequalifier_type]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_prequalifiers() {
        set_prequalifiers(await API_get_credential_prequalifiers());
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_prequalifiers() {
        return API.get('credentials', '/get-credential-prequalifiers/' + credential.id, { queryStringParameters: { tz: datelib.timezone }});
    }

    function API_get_credential_prequalifier_pres_req(id) {
        return API.get('credentials', '/get-credential-prequalifier-presentation-request/' + id);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_qualifier(id, text) {
        set_focuselement(document.activeElement);
        set_selected_prequalifier(id);
        set_selected_prequalifier_type(var_prequalifiers.find(item => item.individualprequalifier_id === id).prequalifier_type);
        set_selected_prequalifier_text(text);
    }

    function onClick_credit_qualifier(id, text) {
        set_focuselement(document.activeElement);
        set_selected_prequalifier(id);
        set_selected_prequalifier_type('CREDENTIAL-CREDIT');
        set_selected_prequalifier_text(text);
    }

    function onClick_document(id) {
        set_focuselement(document.activeElement);
        set_document_id(var_prequalifiers.find(item => item.individualprequalifier_id === id).prequalifier_id);
        set_document_requires_acknowledgement(var_prequalifiers.find(item => item.individualprequalifier_id === id).status !== 'VALID');
        onClick_qualifier(id, null);
    }

    async function onClick_presentation_prequalifier(id) {
        set_focuselement(document.activeElement);
        let results = await API_get_credential_prequalifier_pres_req(id);
        set_presentation_request(results.presentation_request);
        set_presentation_request_preapproval_info(results.info);
        set_selected_prequalifier_type('VERIFIABLE-PRESENTATION');
    }

    function onChange_qualifier() {
        populate_prequalifiers();
        set_selected_prequalifier_type(null);
        onChange && onChange();
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm card__individual_prequalifiers' id='crd_prequalifier' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('Pre-qualifications')}</div>
                    <div className='text--sm-regular'>{t('The pre-qualification requirements must be completed for this credential to be valid.')}</div>
                </div>
            </div>

            {credential.prequalifier_status === 'IN PROGRESS' &&
                <Message warning
                    icon={<Icon name='warning' className='icon' />}
                    header={t('Pre-qualifications for this credential are incomplete.')}
                />
            }

            <div className='card__content'>
                {var_prequalifiers.map((item, index) => {
                    switch (item.prequalifier_type) {
                        case 'ATTESTATION':
                            return render_attestation(item, index);
                        case 'CREDENTIAL':
                            return render_credential(item, index);
                        case 'CREDENTIAL-CREDIT':
                            return render_credit_credential(item, index);
                        case 'DOCUMENT':
                            return render_document(item, index);
                        case 'SURVEY':
                            return render_survey(item, index);
                        default:
                            throw new Error('Not implemented');
                    }
                })}
            </div>

            <MDL_ATTESTATION display={var_selected_prequalifier_type === 'ATTESTATION'} onClose={() => set_selected_prequalifier_type(null)}
                individualprequalifier_id={var_selected_prequalifier} onChange={onChange_qualifier} />
            <MDL_CREDENTIAL display={var_selected_prequalifier_type === 'CREDENTIAL'} onClose={() => set_selected_prequalifier_type(null)}
                individualprequalifier_id={var_selected_prequalifier} prequalifier_name={var_selected_prequalifier_text} onChange={onChange_qualifier} />
            <MDL_CREDIT_CREDENTIAL display={var_selected_prequalifier_type === 'CREDENTIAL-CREDIT'} onClose={() => set_selected_prequalifier_type(null)}
                individualcredential_id={credential.id} prequalifier_id={var_selected_prequalifier} prequalifier_name={var_selected_prequalifier_text} onChange={onChange_qualifier} />
            <CMP_DOCUMENTACKNOWLEDGE
                is_open={var_selected_prequalifier_type === 'DOCUMENT'}
                onAcknowledgement={onChange_qualifier}
                onClose={() => set_selected_prequalifier_type(null)}
                document_type='PREQUALIFIER-DOCUMENT'
                document_id={var_document_id}
                secondary_id={var_selected_prequalifier}
                show_acknowledge={var_document_requires_acknowledgement} />
            <MDL_SURVEY display={var_selected_prequalifier_type === 'SURVEY'} onClose={() => set_selected_prequalifier_type(null)}
                individualprequalifier_id={var_selected_prequalifier} onChange={onChange_qualifier} />
            <CMP_VP_REQUEST display={var_selected_prequalifier_type === 'VERIFIABLE-PRESENTATION'} onClose={() => set_selected_prequalifier_type(null)}
                presentation_request={var_presentation_request} requested_credential={var_presentation_request_preapproval_info} onComplete={onChange_qualifier} />
        </div>
    )

    function render_attestation(item, index) {
        return (
            <div key={item.individualprequalifier_id} className='detailsgroup'>
                <div>
                    {item.status === 'VALID'
                        ? <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                        : <div><Icon name='warning_circle' className='prequalifiers_icon warning-icon--yellow' alt={t('status icon')} /></div>
                    }
                    <div className='prequalifier_text'>
                        <div className='text--sm-regular'>{item.short_description}</div>
                        {item.status === 'VALID' &&
                            <div className='text--xs-regular'>
                                {`${t('Completed')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}${item.expires_date ? ' | ' + t('Expires') + ' ' + datelib.epoch_to_date_string(item.expires_date) : ''}`}
                            </div>
                        }
                    </div>
                </div>
                <div className='prequalifiers_actionbutton'><Button id={`btn_pre_${index}`} className='secondary' onClick={() => onClick_qualifier(item.individualprequalifier_id, null)}>{item.status === 'VALID' ? t('View') : t('Acknowledge')}</Button></div>
            </div>
        );
    }

    function render_credential(item, index) {
        return (
            <div key={item.individualprequalifier_id} className='detailsgroup'>
                <div>
                    {item.status === 'VALID'
                        ? <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                        : item.status === 'PENDING'
                        ? <div><Icon name='eye_circle' className='prequalifiers_icon color--yellow-500' alt={t('status icon')} /></div>
                        : <div><Icon name='warning_circle' className='prequalifiers_icon warning-icon--yellow' alt={t('status icon')} /></div>
                    }
                    <div className='prequalifier_text'>
                        <div className='text--sm-regular'>{item.short_description}</div>
                        {item.additional_text && item.additional_text !== 'Verifiable credential' ?
                            <div className='text--xs-regular'>{`${item.additional_text} ${t('submitted')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}`}</div>
                        : item.additional_text === 'Verifiable credential' ?
                            <div className='text--xs-regular'>{`${t('Verifiable credential submitted')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}`}</div>
                        :   null
                        }
                    </div>
                </div>
                <div className='prequalifiers_actionbutton'><Button id={`btn_pre_${index}`} className='secondary' onClick={() => JSON.parse(item.additional_data).is_verifiable_presentation === 'YES'
                    ? onClick_presentation_prequalifier(item.individualprequalifier_id)
                    : onClick_qualifier(item.individualprequalifier_id, item.short_description)}>{item.additional_text ? t('Change credential') : t('Submit credential')}</Button></div>
            </div>
        );
    }

    function render_credit_credential(item, index) {
        return (
            <div key={item.prequalifier_id} className='detailsgroup'>
                <div>
                    {item.status === 'VALID'
                        ? <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                        : item.status === 'PENDING'
                        ? <div><Icon name='eye_circle' className='prequalifiers_icon color--yellow-500' alt={t('status icon')} /></div>
                        : <div><Icon name='warning_circle' className='prequalifiers_icon warning-icon--yellow' alt={t('status icon')} /></div>
                    }
                    <div className='prequalifier_text'>
                        <div className='text--sm-regular'>{item.short_description}</div>
                        <div className={'badge text--sm-medium' + (item.credits_obtained >= item.credits_required ? ' badge--green' : ' badge--yellow')}>
                            {`${item.credits_obtained}/${item.credits_required} ${item.credits_required === 1 ? t('credit') : t('credits')}`}
                        </div>

                        {item.submitted_credentials.map(submitted =>
                            <div key={submitted.individual_prequalifier_credential_id} className='prequalifier_credential_detail text--xs-regular'>
                                {`${submitted.credential_name} (${submitted.status === 'EXPIRED' ? t('Expired') : `${submitted.credit_value} ${submitted.credit_value === 1 ? t('credit') : t('credits')}`}) ${t('submitted ')} ${datelib.epoch_to_datetime_string(submitted.submitted_date, true)}`}
                            </div>
                        )}

                        {item.status !== 'VALID' && item.submitted_credentials.length > 0 &&
                            <div onClick={() => onClick_credit_qualifier(item.prequalifier_id, item.short_description)} className='prequalifier_change_credential text--xs-regular'>
                                {t('Change credential')}
                            </div>}
                    </div>
                </div>
                <div className='prequalifiers_actionbutton'><Button id={`btn_pre_${index}`} className='secondary' onClick={() => onClick_credit_qualifier(item.prequalifier_id, item.short_description)}>{item.status === 'VALID' ? t('Change credential') : t('Submit credential')}</Button></div>
            </div>
        );
    }

    function render_document(item, index) {
        return (
            <div key={item.individualprequalifier_id} className='detailsgroup'>
                <div>
                    {item.status === 'VALID'
                        ? <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                        : <div><Icon name='warning_circle' className='prequalifiers_icon warning-icon--yellow' alt={t('status icon')} /></div>
                    }
                    <div className='prequalifier_text'>
                        <div className='text--sm-regular'>{item.short_description}</div>
                        {item.status === 'VALID' &&
                            <div className='text--xs-regular'>
                                {`${t('Completed')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}${item.expires_date ? ' | ' + t('Expires') + ' ' + datelib.epoch_to_date_string(item.expires_date) : ''}`}
                            </div>
                        }
                    </div>
                </div>
                <div className='prequalifiers_actionbutton'><Button id={`btn_pre_${index}`} className='secondary' onClick={() => onClick_document(item.individualprequalifier_id)}>{item.status === 'VALID' ? t('View') : t('Acknowledge')}</Button></div>
            </div>
        );
    }

    function render_survey(item, index) {
        return (
            <div key={item.individualprequalifier_id} className='detailsgroup'>
                <div>
                    {item.status === 'VALID'
                        ? <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                        : <div><Icon name='warning_circle' className='prequalifiers_icon warning-icon--yellow' alt={t('status icon')} /></div>
                    }
                    <div className='prequalifier_text'>
                        <div className='text--sm-regular'>{item.short_description}</div>
                        {item.status === 'VALID' &&
                            <div className='text--xs-regular'>
                                {`${t('Completed')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}`}
                            </div>
                        }
                    </div>
                </div>
                {item.status !== 'VALID' &&
                    <div className='prequalifiers_actionbutton'><Button id={`btn_pre_${index}`} className='secondary' tabIndex='0' onClick={() => onClick_qualifier(item.individualprequalifier_id, null)}>{t('Open survey')}</Button></div>
                }
            </div>
        );
    }
}

import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import API from 'libs/api-lib';
import Logo from 'components/cmp_logo';
import datelib from 'libs/date-lib';
import config from 'config';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import 'i18n';

import './cmp_transcript.css';



function CMP_TRANSCRIPT({ display_type = 'page', transcript, focuselement, activetab, ready = true, credentials, user_entered_fields  }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 100, sortby: 'credential_name', sortorder: 'ascending' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (focuselement && activetab === 'PREVIEW') {
            document.getElementById(focuselement)?.focus();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);

    useEffect(() => {
        transcript.id && !credentials && ready && populate_credentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcript.id]);

    useEffect(() => {
        if (credentials) {
            set_credentials(credentials);
            set_totalrows(credentials.length)
            set_loading(false);
            set_ready(true);
        }
    }, [credentials])

    //  async functions ------------------------------------------------------------------------------------------------


    async function populate_credentials(populateconfig) {

        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_individual_transcript_credentials(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_credentials(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individual_transcript_credentials(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('transcripts', `/get-individual-transcript-credentials/${transcript.id}/VALID`,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_issuer() {
        transcript.website.toLowerCase().startsWith('https://') ? window.open(transcript.website) : window.open('https://' + transcript.website);
    }


    // RENDER ==========================================================================================================
    return (
        <div id='cmp_transcript' className={`cmp_transcript transcript__${display_type}`}>
            {ready &&
                <>
                    {(transcript.issuer_logo && transcript.issuer_logo !== '') &&
                        <div className='transcript__header'>
                            <Logo targetid={transcript.issuer_id} classification='logo' filename={transcript.issuer_logo} backup_text={transcript.issuer_name} alt={t('Issued by') + ' ' + transcript.issuer_name} />
                        </div>
                    }

                    <div className='transcript__body' style={{ backgroundColor: transcript.background_color, color: transcript.text_color }}>

                        <div className='transcript__body__name' style={{ color: transcript.header_color }}>
                            <div className='display--md-bold' >{transcript.transcript_name}</div>
                        </div>

                        <div className='transcript__body__holder' style={{ color: transcript.header_color }}>
                            <div className='text--sm-regular' style={{ marginBottom: '0.75rem' }}>
                                {t('Issued to')}
                            </div>
                            <div className='display--sm-medium' >{transcript.individual_name}</div>
                        </div>

                        {transcript.custom_fields &&
                            <div className='transcript__additional_fields'>
                                {transcript.custom_fields.sort((a, b) => a.order - b.order).map((item, index) =>
                                    <React.Fragment key={'additional_field_' + index}>
                                        <div className='transcript__body__datagroup'>
                                            <div className='text--sm-medium label'>{item.translate_label ? t(item.label) : item.label}:</div>
                                            <div className='text--sm-regular'>
                                                {item.datatype === 'date'
                                                    ? datelib.epoch_to_date_string(item.value)
                                                    : item.datatype === 'datetime'
                                                        ? datelib.epoch_to_datetime_string(item.value, true)
                                                        : !user_entered_fields
                                                            ? item.value
                                                            : Object.keys(user_entered_fields).some(field => field === item.name)
                                                                ? user_entered_fields[item.name]
                                                                : item.value
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        }

                        <div className='transcript__body__datagroup'>
                            <div className='text--sm-medium label'>{t('Issuer')}:</div>
                            {!transcript.website
                                ?
                                <div className='text--sm-regular'>{transcript.issuer_name}</div>
                                :
                                <Button className='tertiary issuer_link' onClick={onClick_issuer} style={{ color: transcript.link_color }}>
                                    {transcript.issuer_name}
                                </Button>
                            }
                        </div>

                    </div>

                    <Table id='transcript_credentials' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={() => null}
                        downloadname={t('Transcript credentials')} downloadfunction={() => null}
                        onChange={() => null}
                        hide_tablecontrols={true}>

                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell field='confirmation_status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                                <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                                {transcript.display_credential_issued_date === 'YES' &&
                                    <Table.HeaderCell field='issued_date' datatype='date' filtertype='date'>{t('ISSUED')}</Table.HeaderCell>
                                }
                                {!!transcript.additional_table_field &&
                                    <Table.HeaderCell field='additional_table_field' datatype='text' filtertype='text'>{transcript.additional_table_field.translate ? t(transcript.additional_table_field.label) : transcript.additional_table_field.label}</Table.HeaderCell>
                                }
                                {!!transcript.additional_table_field_2 &&
                                    <Table.HeaderCell field='additional_table_field_2' datatype='text' filtertype='text'>{transcript.additional_table_field_2.translate ? t(transcript.additional_table_field_2.label) : transcript.additional_table_field_2.label}</Table.HeaderCell>
                                }
                                {transcript.display_credits === 'YES' &&
                                    <Table.HeaderCell field='credit_value' datatype='number' filtertype='number'>{t('CREDIT')}</Table.HeaderCell>
                                }
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {var_credentials.map(item =>
                                <Table.Row key={item.id}>
                                    <Table.Cell>
                                        <div className='badge text--sm-medium badge--green'>
                                            {item.confirmation_status_display}
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>{item.credential_name}</Table.Cell>
                                    {transcript.display_credential_issued_date === 'YES' &&
                                        <Table.Cell>{datelib.epoch_to_date_string(item.issued_date)}</Table.Cell>
                                    }
                                    {!!transcript.additional_table_field &&
                                        <Table.Cell>{item.additional_table_field}</Table.Cell>
                                    }
                                    {!!transcript.additional_table_field_2 &&
                                        <Table.Cell>{item.additional_table_field_2}</Table.Cell>
                                    }
                                    {transcript.display_credits === 'YES' &&
                                        <Table.Cell>{item.verification_status === 'VALID'
                                                        ?  item.credit_value?.toFixed(2)
                                                        : '0.00'
                                                    }
                                        </Table.Cell>
                                    }
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                    {transcript.customization?.total_credits_field &&
                        <div className='credits_total_wrapper'>
                            <div className='credits_total text--md-bold'>{transcript.customization.total_credits_field.translate ? t(transcript.customization.total_credits_field.total_credits_label) : transcript.customization.total_credits_field.total_credits_label} {var_credentials.length > 0 ? var_credentials.reduce((accumulator, { credit_value })=> accumulator + credit_value, 0).toFixed(2) : '0.00'}</div>
                        </div>
                    }

                    {transcript.footer_details &&
                        <div className='transcript__footer'>
                            { transcript.footer_details.fields.sort((a, b) => a.order - b.order).map((item) =>
                                <React.Fragment key={`footer-${item.order}`}>
                                    {item.type === 'text'
                                        ? <div>{item.translate ? t(item.value) : item.value}</div>
                                        : item.type === 'image'
                                        ? <img className='footer_image' src={item.url} alt={item.alt} />
                                        : null
                                    }
                                </React.Fragment>
                            )}
                        </div>
                    }

                    <div className='transcript__credivera_footer'>
                        <div className='text--xs-regular'>{t('Powered by')}</div>
                        <img src={`${config.images.url}${config.images.assets.Credivera_Horizontal_Blue}${config.images.stage}`} alt={t('Credivera logo')}/>
                    </div>
                </>
            }
        </div>
    );
}

CMP_TRANSCRIPT.propTypes = {
    display_type: propTypes.oneOf([ 'page', 'modal', 'inline' ]),
    transcript: propTypes.object.isRequired,
    focuselement: propTypes.object,
    activetab: propTypes.string,
    ready: propTypes.bool,
    user_entered_fields: propTypes.object,
    credentials: propTypes.array
};

export default CMP_TRANSCRIPT;
import React, { useEffect, useState } from 'react';
import auth from 'libs/auth-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';

import CRD_ASSIGNMENTS from './cmp_assignments/cmp_assignments';
import CRD_ALL_ASSIGNMENTS from './crd_all_assignments/crd_all_assignments';
import CMP_NOTIFICATIONS from 'components/cmp_notifications/cmp_notifications';
import MDL_ONBOARDING from './mdl_onboarding/mdl_onboarding';


export default function DASHBOARD() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const [ var_assignmentcards_refresh, set_assignmentcards_refresh ] = useState(false);
    const [ var_onboarding_open, set_onboarding_open ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        if (sessionStorage.getItem('onboarding') && sessionStorage.getItem('onboarding') === 'true') {
            set_onboarding_open(true);
        }
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_notifications() {
        set_assignmentcards_refresh(!var_assignmentcards_refresh);
    }


    // RENDER APP ======================================================================================================

    return(
        <div className='page' id='dashboard'>
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background2'>
                    <h1 style={{ display: 'flex' }} className='display--sm-regular'>{t('Hello')}, {auth.firstname}!</h1>

                    <CRD_ASSIGNMENTS refresh={var_assignmentcards_refresh} />

                </div>


                <CMP_NOTIFICATIONS assignment={null} refresh={true} onChange={onChange_notifications} />

                {auth.all_assignments &&
                    <CRD_ALL_ASSIGNMENTS />
                }

                <MDL_ONBOARDING
                    display={var_onboarding_open}
                    onClose={() => set_onboarding_open(false)}
                />
            </div>

        </div>
    )
};

import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Form, Button, Message } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import {FORM_SELECT_RADIO, FORM_TOGGLE} from 'components/cmp_form/cmp_form';
import CMP_FILEUPLOAD from 'components/cmp_fileupload/cmp_fileupload';
import { useTranslation } from 'react-i18next';
import 'i18n';

import Icon from 'components/cmp_icon';

import './mdl_add_document.css';


function MDL_ADD_DOCUMENT({ display, onClose, assignment, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_addtype, set_addtype ] = useState('EXISTING');
    const [ var_acknowledgement_required, set_acknowledgement_required ] = useState('YES');
    const [ var_file_details, set_file_details ] = useState(null);
    const [ var_fileupload_status, set_fileupload_status ] = useState(null);
    const [ var_existingdocuments, set_existingdocuments ] = useState([]);
    const [ var_selected_existingdocument, set_selected_existingdocument ] = useState(null);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display && assignment.location_id) {
            // reset
            set_addtype('EXISTING');
            set_acknowledgement_required('YES');
            set_file_details(null);
            set_fileupload_status('RESET');
            set_existingdocuments([]);
            set_selected_existingdocument(null);
            // populate existing documents
            populate_existing_documents();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, assignment.location_id]);

    useEffect(() => {
        if (var_fileupload_status === 'UPLOAD COMPLETE') {
            add_document();
        } else if (var_fileupload_status === 'UPLOAD FAIL') {
            set_errors([{description: t('There was an error uploading the document')}]);
            set_processing(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload_status]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_existing_documents() {
        try {
            set_processing(true);
            let results = await API_get_existing_documents();
            let transformed = results.map(item => ({
                value: item.document_id,
                primary_text: item.document_name,
                secondary_text: item.attached_to_location === 'YES'
                    ? t('Already added to assignment')
                    : item.ack_req === 'YES' ? t('Requires acknowledment')
                    : null,
                disabled: item.attached_to_location === 'YES'
            }));
            set_existingdocuments(transformed);
        } catch (e) {
            console.log(e);
            set_errors([{description: t('There was a problem.  Please try again later')}]);
        }
        set_processing(false);
    }

    async function add_document() {
        try {
            await API_post_loc_document();
            let document_name = var_addtype === 'EXISTING'
                ? var_existingdocuments.find(item => item.value === var_selected_existingdocument).primary_text
                : var_file_details.display_name;
            onChange && onChange(document_name);
        } catch (e) {
            console.log(e);
            set_errors([{description: t('There was a problem saving.  Please try again later')}]);
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_existing_documents() {
        return API.get('documents', '/get-existing-documents/' + assignment.location_id);
    }

    function API_post_loc_document() {
        return API.post('documents', '/post-loc-document', { body: {
            location_id: assignment.location_id,
            type: var_addtype,
            document_id: var_addtype === 'EXISTING' && var_selected_existingdocument,
            name: var_addtype === 'NEW' && var_file_details.display_name,
            filename: var_addtype === 'NEW' && var_file_details.filename,
            ack_req: var_addtype === 'NEW' && var_acknowledgement_required
        }});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_document(details) {
        set_errors([]);
        set_file_details(details);
    }

    function onClick_add() {
        if (var_addtype === 'EXISTING' && var_selected_existingdocument === null) {
            set_errors([{property: 'filename', description: t(`Please select a file to add.`)}]);
            return;
        }
        if (var_addtype === 'NEW' && var_fileupload_status !== 'UPLOAD READY') {
            set_errors([{property: 'filename', description: t(`Please select a file to add.`)}]);
            return;
        }
        set_processing(true);

        if (var_addtype === 'EXISTING') {
            add_document();
        } else {
            set_fileupload_status('START UPLOAD');
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer={'inverted'}
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}>

            <div className='modal__header'>
                <div style={ { width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium' id='hdr_assignment_adddoc'>{t('Add document')}</div>
                    </div>
                    <div className='modal__header__right'>
                        <div className="modal__header__closeicon color--primary-500">
                            <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                        </div>
                    </div>
                </div>
            </div>

            <Form className='modal__content' id='form_assignment_adddoc' aria-labelledby='hdr_assignment_adddoc'>
                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_errors[0].description} />
                }
                <FORM_TOGGLE label={t('Type')} value={var_addtype} options={[{value: 'EXISTING', text: t('Existing')}, {value: 'NEW', text: t('New')}]} onChange={set_addtype} />
                {var_addtype === 'EXISTING' &&
                    <FORM_SELECT_RADIO
                        name='existingdocuments'
                        property='existingdocuments'
                        label={t('Document')}
                        value={var_selected_existingdocument}
                        options={var_existingdocuments}
                        single_or_multiple='SINGLE'
                        onChange={set_selected_existingdocument}
                        placeholder={t('Search')}
                        disabled={false}
                        errors={var_errors}
                        aria_label={t('Select a document to add to the assignment.')}
                    />
                }
                {var_addtype === 'NEW' &&
                    <FORM_TOGGLE label={t('Acknowledgement')} value={var_acknowledgement_required} options={[{value: 'YES', text: t('Required')}, {value: 'NO', text: t('Not required')}]} onChange={set_acknowledgement_required} />
                }
                <div className={'detailsgroup' + (var_addtype === 'EXISTING' ? ' display-none' : '')}>
                    <div className='detailsgroup__label text--sm-medium detailsgroup__label_document'>{t('Document')}</div>
                        <CMP_FILEUPLOAD
                                var_status={var_fileupload_status}
                                set_status={set_fileupload_status}
                                onChange_details={onChange_document} single_or_multiple='SINGLE'
                                uploaddirectory='documents' max_size={10} allowed_fileextensions={['pdf']}
                                instructions={t('Only PDF file type is accepted')}
                        />
                </div>
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add}>{t('Add document')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

export default MDL_ADD_DOCUMENT;
import API from 'libs/api-lib';
import { Message } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Icon from 'components/cmp_icon';
import Logo from 'components/cmp_logo';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Processing from 'components/cmp_processing';
import QRCode from 'qrcode.react';

import CRD_PROFILE from './crd_profile/profile';

import './assignment.css';


export default function REPORT_PROFILE() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const params = useParams();

    const [ var_linkstatus, set_linkstatus ] = useState('');
    const [ var_processing, set_processing ] = useState(true);
    const [ var_report_info, set_report_info ] = useState([]);
    const [ var_individuals, set_individuals ] = useState([]);

    let url = window.location.href;
    let arr = url.split('/');
    const hostname = arr[0] + '//' + arr[2];
    const qrURL = hostname + '/report/location/' + params.token;


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_report_linkstatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_report_linkstatus() {
        set_processing(true);
        try {
            let results = await API_get_assignment_report();
            set_linkstatus('ACTIVE');
            set_report_info(results.report_info);
            set_individuals(results.individuals_list);
        } catch (e) {
            console.log(e);
            if (e.response.status === 410) {
                set_linkstatus('INACTIVE');
            } else if (e.response.status === 404) {
                set_linkstatus('NOTFOUND');
            }
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_assignment_report() {
        return API.get('external-report', '/get-assignment-report/' + params.token, {
            queryStringParameters: {
                tz: datelib.timezone
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================


    return (
        <div className='report_content' id='pg_reportprofile'>
            <div id='content' tabIndex='0'>

                {!var_processing &&
                    <div className='report_wrapper'>
                        {var_report_info && var_linkstatus === 'ACTIVE' &&
                            <div className='profile_report_card'>
                                <div className='profile_report_header'>
                                    <div className='profile_report_header_top'>
                                        <div className='profile_report_detail'>
                                            {var_report_info.organization_logo && var_report_info.organization_logo !== '' &&
                                                <div className='profile_report_org_info'>
                                                    <div className='profile_report_logo'>
                                                        <Logo targetid={var_report_info.organization_id} classification='logo' filename={var_report_info.organization_logo} alt={t('organization logo')} />
                                                    </div>
                                                </div>
                                            }
                                            <div className='profile_report_title'>
                                                <div className='report_title'>{var_report_info.organization_name}</div>
                                            </div>
                                            <div className='profile_report_title'>
                                                <div className='report_title'>{var_report_info.assignment_name}</div>
                                            </div>
                                            <ul className='location_report_info'>
                                                {var_report_info?.code &&
                                                <li>
                                                    <div className='location_report_info_th'>{t('CODE')}</div>
                                                    <div className='location_report_info_td'>{var_report_info.code}</div>
                                                </li>
                                                }
                                                {var_report_info?.city &&
                                                <li>
                                                    <div className='location_report_info_th'>{t('CITY')}</div>
                                                    <div className='location_report_info_td'>{var_report_info.city}</div>
                                                </li>
                                                }
                                                {var_report_info?.province &&
                                                <li>
                                                    <div className='location_report_info_th'>{t('PROVINCE')}</div>
                                                    <div className='location_report_info_td'>{var_report_info.province}</div>
                                                </li>
                                                }
                                                {var_report_info?.country &&
                                                <li>
                                                    <div className='location_report_info_th'>{t('COUNTRY')}</div>
                                                    <div className='location_report_info_td'>{var_report_info.country}</div>
                                                </li>
                                                }
                                            </ul>
                                        </div>
                                        <div className='report_qrcode'>
                                            <QRCode
                                                className='qrcode'
                                                id='qr-gen'
                                                value={qrURL}
                                                size={120}
                                                level={'H'}
                                                includeMargin={false}
                                            />
                                        </div>
                                    </div>

                                    <div className='profile_list'>
                                    {var_individuals.map((individual, i) =>
                                    <div className={'profile' + i} key={i}>
                                    <CRD_PROFILE
                                            individual={individual}
                                            report_info={var_report_info}
                                            token={params.token}>
                                    </CRD_PROFILE>
                                    </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }

                {!var_processing && (var_linkstatus === 'INACTIVE' || var_linkstatus === 'NOTFOUND') &&
                    <div className='report_wrapper'>
                        <div>
                            <Message
                                error
                                icon={<Icon name='error' className='icon' />}
                                header={t('A valid or active assignment report could not be found. Please check the link again or the individual you received it from.')}
                            />
                        </div>
                    </div>
                }

                <Processing display={var_processing} processingtext={t('Processing')} />
            </div>
        </div>
    );
};

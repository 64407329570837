
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { FORM_FILEUPLOAD, FORM_TEXTAREA } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './content_document.css';

/*******************************************************************
 * Note, this renders the form inputs for parent <Form> element within mdl_prequalifer.js
 *******************************************************************/
function CONTENT_DOCUMENT({ prequalifier, errors, credentialstatus, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_fileupload_status, set_fileupload_status ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_fileupload_status) {
            onChange({ ...prequalifier, fileupload_status: var_fileupload_status });
        }
    }, [var_fileupload_status]);


    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_file(details) {
        onChange({ ...prequalifier, filename: details.filename, submitted_filename: details.display_name });
    }

    function onChange_input(event, { name, value }) {
        let updated = { ...prequalifier };
        updated[name] = value;
        onChange(updated, name);
    }

    function onChange_fileselect() {
        onChange({ ...prequalifier }, 'filename');
    }

    function onChange_fileupload_status(new_status) {
        set_fileupload_status(new_status);
    }


    // RENDER APP ======================================================================================================

    return (
        <div id='content_document' className='content__wrapper'>

            {(credentialstatus === 'ACTIVE' && prequalifier.prequalifier_id !== undefined)
                ? <Message warning
                    icon={<Icon name='warning' className='warning-icon--yellow' />}
                    content={t('Modifying this qualifier will require all holders of this credential to acknowledge the updated document. All ‘valid’ credentials will change to ‘In-progress’ until the acknowledgement has been completed. This action can not be undone.')}
                  />
                : credentialstatus === 'ACTIVE'
                ? <Message warning
                    icon={<Icon name='warning' className='warning-icon--yellow' />}
                    content={t('Adding this qualifier will require all holders of this credential to acknowledge the new document. All ‘valid’ credentials will change to ‘In-progress’ until the acknowledgement has been completed. This action can not be undone.')}
                  />
                : null
            }

            <div className='content__description text--sm-medium'>
                {t('This qualifier enables verification that an individual has read and accepted the contents of a document.')}
            </div>

            <FORM_FILEUPLOAD
                label={t('Document')}
                property='filename'
                onChange={onChange_file}
                onChange_upload={onChange_fileselect}
                single_or_multiple='SINGLE'
                var_fileupload_status={prequalifier.fileupload_status}
                set_fileupload_status={onChange_fileupload_status}
                uploaddirectory='prequalifierdocument'
                allowed_fileextensions={['pdf']}
                instructions={t('Only PDF file type is accepted')}
                errors={errors}
            />

            <FORM_TEXTAREA
                property='description'
                label={t('Description')}
                value={prequalifier.description}
                onChange={onChange_input}
                placeholder={t('Description')}
                maxLength={200}
                disabled={false}
                errors={errors}
                description={t('The description is displayed to the user upon claiming the credential. It serves as a guide, providing context and clear instructions on how to fulfill the qualifying requirement successfully.')}
            />

        </div>
    );

}

CONTENT_DOCUMENT.propTypes = {
    prequalifier: propTypes.object,
    errors: propTypes.arrayOf(propTypes.object),
    credentialstatus: propTypes.string,
    onChange: propTypes.func
};

export default CONTENT_DOCUMENT;
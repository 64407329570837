import config from 'config';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { Buffer } from 'buffer';

const auth = {
    // general features
    authentication: null,
    get_authentication: function() {
            if (!this.authentication) {
                let sessionAuthentication = sessionStorage.getItem('authentication');
                sessionAuthentication && (this.authentication = JSON.parse(sessionAuthentication));
            }
            return this.authentication;
        },
    set_authentication: function (auth) { sessionStorage.setItem('authentication', JSON.stringify(auth)); this.authentication = auth; },
    clear: function() { this.authentication = null; },
    clear_cognito_sessions: function() {
        for (let key of Object.keys(localStorage)) {
            if (key.startsWith('CognitoIdentityServiceProvider')) {
                localStorage.removeItem(key);
            }
        }
    },
    remove: function() { this.clear(); sessionStorage.removeItem('authentication'); },
    get is_loggedin() { return !!this.get_authentication(); },
    logout: async function(navigate) {
        // clear session storage
        sessionStorage.clear();
        this.clear();
        Window.$is_unlocked = null;
        Window.$kek = null;
        // Log out of the iam
        let success =  await this.iam.logout();
        if (success && navigate) {
            navigate('/login');
        }
    },


    // user attributes
    get id() { return get_property(this, 'id'); },
    get firstname() { return get_property(this, 'firstname'); },
    get lastname() { return get_property(this, 'lastname'); },
    get email() { return get_property(this, 'email'); },
    get phone() { return get_property(this, 'phone'); },
    get language() { return get_property(this, 'language'); },
    get orgaccess() { return get_property(this, 'orgaccess'); },
    get custom_logo() { return get_property(this, 'custom_logo'); },
    get logo_organization_id() { return get_property(this, 'logo_organization_id'); },
    get passphrase_parameters() { return get_property(this, 'passphrase_parameters'); },

    // misc
    get menu_transcripts() { return get_flag(this, 'menu_transcripts'); },
    get menu_documents() { return get_flag(this, 'menu_documents'); },
    get menu_requirements() { return get_flag(this, 'menu_requirements'); },
    get menu_requirements2() { return get_flag(this, 'menu_requirements2'); },
    get all_assignments() { return get_flag(this, 'all_assignments'); },

    // access
    has_access: function(assignment, module, access) { return check_access(this, assignment, module, access); },
    has_orgaccess: function(module, access) { return check_orgaccess(this, module, access); },
    orgaccess_organization_ids: function(module, access) { return get_orgaccess_organization_ids(this, module, access); },

    // superadmin organization details
    organization_default_language: function(module, access) { return get_organization_default_language(this, module, access) },

    // parsing info from back end
    parse_authentication_result: async function(result) {
        let parsed_auth = {};
        parsed_auth.id = result.id;
        parsed_auth.firstname = result.firstname;
        parsed_auth.lastname = result.lastname;
        parsed_auth.email = result.email;
        parsed_auth.phone = result.phone;
        parsed_auth.language = result.default_language;
        parsed_auth.menu_transcripts = result.menu_transcripts;
        parsed_auth.menu_documents = result.menu_documents;
        parsed_auth.menu_requirements = result.menu_requirements;
        parsed_auth.menu_requirements2 = result.menu_requirements2;
        parsed_auth.all_assignments = result.all_assignments;
        parsed_auth.orgaccess = result.orgaccess;
        parsed_auth.custom_logo = result.custom_logo;
        parsed_auth.logo_organization_id = result.logo_organization_id;
        parsed_auth.passphrase_parameters = result.passphrase_parameters;
        return parsed_auth;
    },

    iam: {
        login: async function(options, onLogin, onSessionStorageError) {
            let keycloak_config;
            let current_url = new URL(window.location.href);
            if (current_url.searchParams.has('code')) {
                // we're being redirected back from keycloak
                if (!!sessionStorage.getItem('keycloak_state') && sessionStorage.getItem('keycloak_state') === current_url.searchParams.get('state')) {
                    sessionStorage.removeItem('keycloak_state');
                    // looks good, now get an access token
                    keycloak_config = JSON.parse(localStorage.getItem('keycloak_config'));
                    let token_params = {
                        code: current_url.searchParams.get('code'),
                        grant_type: 'authorization_code',
                        client_id: config.iam.client_id,
                        redirect_uri: iam_redirect_uri
                    };
                    let token_response = await axios.post(
                        keycloak_config.token_endpoint,
                        token_params,
                        { headers: { 'content-type': 'application/x-www-form-urlencoded' }});
                    if (this.persist_token(token_response.data, true)) {
                        options = JSON.parse(sessionStorage.getItem('login_options'));
                        sessionStorage.removeItem('login_options');
                        onLogin && onLogin({ ...options, new_login: true });
                    }
                    return;
                } else {
                    let kcstate = current_url.searchParams.get('state');
                    console.error(`Saved state ${sessionStorage.getItem('keycloak_state')} does not match returned state ${kcstate}`);
                    // if we have the invite token in the state, but we don't have the state in session storage then likely the session storage was cleared
                    // or the browser was switched between alpha and keycloak.
                    // if there has already been 10 attempts then show an error to prevent an infinite loop
                    // otherwise redirect back to keycloak to verify that the user has a proper keycloak session and prevent CSRF
                    // (Note: this was added due to some iPhone users losing session storage.)
                    if (kcstate.length === 78) {
                        onSessionStorageError();
                        return;
                    } else if (kcstate.length === 77) {
                        let inviteid = kcstate.substring(39, 75);
                        let attempt = Number(kcstate.substring(76));
                        await this.recheck_invite(inviteid, attempt);
                        return;
                    }
                }
            } else if (auth.is_loggedin) {
                onLogin && onLogin({ ...options, new_login: false });
                return;
            }
            // get configuration from iam server
            keycloak_config = (await axios.get(`${config.iam.url}/realms/${config.iam.realm}/.well-known/uma2-configuration`)).data;
            localStorage.setItem('keycloak_config', JSON.stringify(keycloak_config));
            // create some variables
            // store the invite token, if it exists, in the state as it gets passed back from Keycloak, in case session storage is lost
            let state = uuid() + (options.invite?.inviteid ? ('-i-' + options.invite.inviteid + '-1') : '');
            let nonce = uuid();
            sessionStorage.setItem('keycloak_state', state);
            // generate URL
            let endpoint = options.invite?.status === 'NEW USER' ? `${config.iam.url}/realms/${config.iam.realm}/protocol/openid-connect/registrations` : keycloak_config.authorization_endpoint;
            let url = new URL(endpoint);
            url.searchParams.append('client_id', config.iam.client_id);
            url.searchParams.append('redirect_uri', iam_redirect_uri);
            url.searchParams.append('state', state);
            url.searchParams.append('response_type', 'code');
            url.searchParams.append('scope', 'openid');
            url.searchParams.append('nonce', nonce);
            // add search params for options
            if (!!options.invite) {
                url.searchParams.append('email', options.invite.email);
                url.searchParams.append('firstname', options.invite.firstname);
                url.searchParams.append('lastname', options.invite.lastname);
                url.searchParams.append('terms', options.invite.termsofservice);
                // replace the invite object with a new object containing just the id
                options.invite = { inviteid: options.invite.inviteid };
                options.is_invite = true;
            } else if (!!options.qrdata) {
                if (options.qrdata.guest === 'YES') {
                    url.searchParams.append('registration', 1);
                    if (options.qrdata.subcontractor === 'YES') {
                        url.searchParams.append('subcontractor', 1);
                    }
                }
                url.searchParams.append('location', options.qrdata.name);
                if (options.qrdata.logo) {
                    url.searchParams.append('logo', options.qrdata.logo_path);
                }
                url.searchParams.append('terms', options.qrdata.termsofservice);
                options.is_qrcode = true;
            }
            if (!!options.sso) {
                url.searchParams.append('kc_idp_hint', options.sso);
                options.is_sso = true;
            }
            // save options for use after login
            options.login_options = true;
            sessionStorage.setItem('login_options', JSON.stringify(options));
            // send user to keycloak
            window.location.assign(url);
        },
        recheck_invite: async function(inviteid, attempt) {
            let keycloak_config = (await axios.get(`${config.iam.url}/realms/${config.iam.realm}/.well-known/uma2-configuration`)).data;
            localStorage.setItem('keycloak_config', JSON.stringify(keycloak_config));
            let state = uuid() + '-i-' + inviteid + '-' + ++attempt;
            let nonce = uuid();
            sessionStorage.setItem('keycloak_state', state);
            // generate URL
            let endpoint = keycloak_config.authorization_endpoint;
            let url = new URL(endpoint);
            url.searchParams.append('client_id', config.iam.client_id);
            url.searchParams.append('redirect_uri', iam_redirect_uri);
            url.searchParams.append('state', state);
            url.searchParams.append('response_type', 'code');
            url.searchParams.append('scope', 'openid');
            url.searchParams.append('nonce', nonce);
            // save options for use after login
            let options = { invite: { inviteid }, is_invite: true, login_options: true };
            sessionStorage.setItem('login_options', JSON.stringify(options));
            // send user back to keycloak to verify session
            window.location.assign(url);
        },
        logout: async function() {
            if (!localStorage.getItem('iam_access_token')) return true;

            // copy local storage items to local variables
            let keycloak_config = JSON.parse(localStorage.getItem('keycloak_config'));
            let refresh_token = localStorage.getItem('iam_refresh_token');
            let id_token = localStorage.getItem('iam_id_token');
            let sso_logout = localStorage.getItem('iam_sso_logout');
            // clear iam storage items
            localStorage.removeItem('iam_access_token');
            localStorage.removeItem('iam_expires_epoch');
            localStorage.removeItem('iam_refresh_expires_epoch');
            localStorage.removeItem('iam_refresh_token');
            localStorage.removeItem('iam_scope');
            localStorage.removeItem('iam_id_token');
            localStorage.removeItem('iam_sso_logout');
            if (sso_logout === 'YES' && id_token) {
                // redirect to keycloak to logout
                let url = new URL(keycloak_config.end_session_endpoint);
                url.searchParams.append('id_token_hint', id_token);
                url.searchParams.append('post_logout_redirect_uri', iam_redirect_uri);
                window.location.assign(url);
                return false;
            } else {
                try {
                    // log out of keycloak through backchannel
                    await axios.post(keycloak_config.end_session_endpoint, {
                        client_id: config.iam.client_id,
                        refresh_token: refresh_token
                    }, {
                        headers: { 'content-type': 'application/x-www-form-urlencoded' }
                    });
                } catch {}
                return true;
            }
        },
        change_password: function() {
            let keycloak_config = JSON.parse(localStorage.getItem('keycloak_config'));
            let url = new URL(keycloak_config.authorization_endpoint);
            url.searchParams.append('client_id', config.iam.client_id);
            url.searchParams.append('redirect_uri', `${window.location.protocol}//${window.location.host}/profile`);
            url.searchParams.append('response_type', 'code');
            url.searchParams.append('scope', 'openid');
            url.searchParams.append('kc_action', 'UPDATE_PASSWORD');
            url.searchParams.append('ui_locales', auth.language ? auth.language.i18n.split('-')[0] : 'en');
            window.location.assign(url);
        },
        setup_mfa: function() {
            let keycloak_config = JSON.parse(localStorage.getItem('keycloak_config'));
            let url = new URL(keycloak_config.authorization_endpoint);
            url.searchParams.append('client_id', config.iam.client_id);
            url.searchParams.append('redirect_uri', `${window.location.protocol}//${window.location.host}/profile/settings`);
            url.searchParams.append('response_type', 'code');
            url.searchParams.append('scope', 'openid');
            url.searchParams.append('kc_action', 'CONFIGURE_TOTP');
            url.searchParams.append('ui_locales', auth.language ? auth.language.i18n.split('-')[0] : 'en');
            window.location.assign(url);
        },
        get status() {
            if (!localStorage.getItem('iam_access_token')) return 'NOT LOGGED IN';
            if (Number(localStorage.getItem('iam_refresh_expires_epoch')) < Date.now()) return 'EXPIRED';
            return 'LOGGED IN';
        },
        refresh_token: async function() {
            if (!localStorage.getItem('iam_refresh_token')) return;
            if (Number(localStorage.getItem('iam_refresh_expires_epoch')) < Date.now()) {
                // if refresh token is expired
                return;
            }
            let keycloak_config = JSON.parse(localStorage.getItem('keycloak_config'));
            let token_response = await axios.post(keycloak_config.token_endpoint, {
                client_id: config.iam.client_id,
                grant_type: 'refresh_token',
                refresh_token: localStorage.getItem('iam_refresh_token')
            }, {
                headers: { 'content-type': 'application/x-www-form-urlencoded' }
            });
            this.persist_token(token_response.data);
        },
        get_access_token: async function() {
            let iam_access_token = localStorage.getItem('iam_access_token'), iam_expires_epoch = localStorage.getItem('iam_expires_epoch'),
                iam_refresh_expires_epoch = localStorage.getItem('iam_refresh_expires_epoch');
            if (!iam_access_token) {
                // if not logged in
                return null;
            }
            if (iam_refresh_expires_epoch < Date.now()) {
                // if refresh token has expired
                return null;
            }
            if (iam_expires_epoch < Date.now() || iam_refresh_expires_epoch < (Date.now() + (5*60*1000))) {
                // if the access token is expired or the refresh token is about to expire
                // then get a new token
                await this.refresh_token();
                iam_access_token = localStorage.getItem('iam_access_token');
            }
            return iam_access_token;
        },
        persist_token: function(token, is_login = false) {
            if (!token?.access_token) return false;
            localStorage.setItem('iam_access_token', token.access_token);
            localStorage.setItem('iam_expires_epoch', Date.now() + (token.expires_in * 1000) - 20000); // save token expiry as 20 seconds less to give time to refresh
            localStorage.setItem('iam_refresh_expires_epoch', Date.now() + (token.refresh_expires_in * 1000) - 20000);
            localStorage.setItem('iam_refresh_token', token.refresh_token);
            localStorage.setItem('iam_scope', token.scope);
            if (token.id_token) {
                localStorage.setItem('iam_id_token', token.id_token);
            }
            // check access token has sso_logout flag (only when logging in, not refreshes)
            if (is_login) {
                let token_body = token.access_token.split('.')[1];
                // change to base64 if base64url
                token_body = token_body.replace(/-/g, '+').replace(/_/g, '/');
                while (token_body.length % 4 !== 0) {
                    token_body += '=';
                }
                let token_json = Buffer.from(token_body, 'base64').toString();
                let sso_logout = JSON.parse(token_json).sso_logout;
                if (sso_logout === 'YES') {
                    localStorage.setItem('iam_sso_logout', 'YES');
                }
            }
            return true;
        },

        oauth: async function(state, code, redirect_uri) {
            if (sessionStorage.getItem('state') !== state) return;
            sessionStorage.removeItem('state');
            let exchange = await iam_query('/realms/credivera/protocol/openid-connect/token',
                `client_id=alpha&grant_type=authorization_code&code=${code}&redirect_uri=${redirect_uri}`);
            return this.persist_token(exchange);
        }
    }
};

//#region internal functions

const get_property = (auth, property_name) => {
    let authentication = auth.get_authentication();
    return authentication && authentication[property_name];
}

const get_flag = (auth, flag_name) => {
    let authentication = auth.get_authentication();
    return authentication ? authentication[flag_name] === 'YES' : false;
}

const check_access = (auth, assignment, module, module_access) => {
    let authentication = auth.get_authentication();
    if (!authentication || !assignment || !assignment.module_access) return false;
    return assignment.module_access.some(item => item.some(mod => mod.module_code === module && mod.access.some(access => access === module_access)));
}

const check_orgaccess = (auth, module, module_access) => {
    let authentication = auth.get_authentication();
    if (!authentication) return false;
    return authentication.orgaccess.some(item => item.module_access.some(mod => mod.module_code === module && mod.access.some(access => access === module_access)));
}

const get_organization_default_language = (auth, module, module_access) => {
    let authentication = auth.get_authentication();
    if (!authentication) return null;
    return authentication.orgaccess.filter(item => item.module_access.some(mod => mod.module_code === module && mod.access.some(access => access === module_access)))[0]?.organization_language;
}

const get_orgaccess_organization_ids = (auth, module, module_access) => {
    let authentication = auth.get_authentication();
    if (!authentication) return [];
    return authentication.orgaccess.filter(item => item.module_access.some(mod => mod.module_code === module && mod.access.some(access => access === module_access))).map(item => item.organization_id);
}

const iam_redirect_uri = `${window.location.protocol}//${window.location.host}/login`;

const iam_query = async(url, body) => {
    try {
        let options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: body,
            url: `https://${config.iam.host}${url}`
        };
        let response = await axios(options);
        return response.data;
    } catch {
        return {};
    }
};

//#endregion

export default auth;
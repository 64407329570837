import React, { useEffect } from 'react';
import CMP_TRANSCRIPT_SHARING from 'components/cmp_transcript_sharing/cmp_transcript_sharing';


export default function TAB_SHARING({ transcript }) {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            <CMP_TRANSCRIPT_SHARING transcript={transcript} />
        </>
    )
};

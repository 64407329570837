import React, { useEffect } from 'react';

import CRD_REQUIREMENTS from './crd_requirements/crd_requirements.js';



export default function TAB_REQUIREMENTS() {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            <CRD_REQUIREMENTS />
        </>
    )
};

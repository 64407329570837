import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';
import './certificate.css';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Processing from 'components/cmp_processing';

import CMP_CERTIFICATE from 'components/cmp_certificate/cmp_certificate';
import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';

export default function REPORT_CERTIFICATE() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const params = useParams();

    const [ var_certificate, set_certificate ] = useState({});
    const [ var_certificate_ready, set_certificate_ready ] = useState(false);
    const [ var_linkstatus, set_linkstatus ] = useState('');
    const [ var_processing, set_processing ] = useState(null);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        populatecertificate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populatecertificate() {
        set_processing(true);
        try {
            let certificate = await API_get_credential();
            if (certificate) {
                set_linkstatus('ACTIVE');
                set_certificate(certificate);
                set_certificate_ready(true);
            } else {
                set_linkstatus('NOTFOUND');
            }
        } catch (e) {
            console.log(e);
            if (e.response?.status === 410) {
                set_linkstatus('INACTIVE');
            } else if (e.response?.status === 404) {
                set_linkstatus('NOTFOUND');
            }
        } finally {
            set_processing(false);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential() {
        if (!params.credential_id) {
            return API.get('external-report', '/get-credential/' + params.token + '/' + (params.credentialid || '0'), {
                queryStringParameters: {
                    tz: datelib.timezone,
                }
            });
        } else {
            return API.get('external-report', '/get-credential/' + params.token + '/' + params.report_id + '/' + params.credential_id, {
                queryStringParameters: {
                    tz: datelib.timezone,
                }
            });
        }
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_document() {
        set_mdl_docviewer_open(true);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='digitalcertificate_wrapper shadow-lg'>
            <div id='content' tabIndex='0'>
                {!var_processing &&
                    <CMP_CERTIFICATE certificate={var_certificate} ready={var_certificate_ready} linkstatus={var_linkstatus} onClick_document={onClick_document} />
                }

                {!var_processing && (var_linkstatus === 'INACTIVE' || var_linkstatus === 'NOTFOUND') &&
                    <div className='report_wrapper'>
                        <div>
                            <Message
                                error
                                icon={<Icon name='error' className='icon' />}
                                header={t('A valid or active report certificate could not be found. Please check the link again or the individual you received it from.')}
                            />
                        </div>
                    </div>
                }

                {/***** MODAL: DOCUMENT VIEW ************************************************************************/}

                <MDL_DOCVIEWER
                    isreport={true}
                    token={params.token}
                    targetid={params.credential_id ? var_certificate.report_id : var_certificate.reportcredential_id}
                    credential_id={params.credential_id}
                    classification='credential'
                    is_open={var_mdl_docviewer_open}
                    onClose={() => set_mdl_docviewer_open(false)}
                />

                {/***** END MODAL: DOCUMENT VIEW *********************************************************************/}


                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>

        </div>
    )
};
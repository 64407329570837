import React from 'react';
import { useTranslation } from 'react-i18next';
import 'i18n';
import './terms.css'

export default function TERMS(appProps) {

    const { t } = useTranslation('public');

// RENDER APP ======================================================================================================
    return (
        <div className='page' id='terms'>
            <div id='content' tabIndex='0'>
                <div className='credivera__header'>
                    <div className='logo_header'>
                        <img src='/assets/Credivera Horizontal White.svg' alt={t('Credivera logo')}/>
                    </div>
                </div>
                <div className='content-wrapper'>
                    <div className='title'>
                        <div className='text--xl-bold'>{t('End User Terms And Conditions')}</div>
                    </div>
                    <div className='content-title'>
                        <div className='text--xl-bold'>{t('1.0 Overview')}</div>
                        <div className='sub-title text--lg-bold'>
                            {t('Please review the following sections carefully. They contain important details regarding your rights when accessing our products.')}
                        </div>
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('1.1')}</div>
                        {`${t('These End User Terms and Conditions (the “Terms”) constitute a binding contract between you and TerraHub Technologies Inc. (“TerraHub”).')}
                        ${t('By purchasing or using TerraHub’s proprietary system (the “TerraHub System”), you hereby agree to be bound by the terms and conditions set forth in the Terms. If you do not agree with any such term or condition, you may not use the TerraHub System.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('1.2')}</div>
                        {`${t('The TerraHub System is licensed to you for use strictly in accordance with the Terms.')}
                        ${t('TerraHub may modify the Terms  at any time in its sole discretion. If the Terms are modified, TerraHub will post a copy of the amended Terms.')}
                        ${t('You should therefore visit this page periodically to review these Terms.If you do not agree to, or cannot comply with, the Terms as amended, you may not use the TerraHub System.')}
                        ${t('You will be deemed to have accepted any amendments to these Terms if you continue to use the TerraHub System after any amendments to the Terms are posted.')}`}
                    </div>
                    <div className='content-title'>
                        <div className='text--xl-bold'>{t('2.0 Using the Services')}</div>
                        <div className='sub-title text--lg-bold'>
                            {t('The TerraHub System has a number of features. You will generally need an account to access this system. This is to protect you and to protect us.  Here are the details:')}
                        </div>
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('2.1')}</div>
                        {`${t('Subject to the conditions and limitations outlined in the Terms, the TerraHub System allows you to verify, store and share digital credentials, self-assessments and tests (the “Services”).')}
                        ${t('Your company may request for you to share this information during the term of your employment or your engagement.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('2.2')}</div>
                        {`${t('In order to access and use the TerraHub System, you will need to sign up for, open and maintain an account (an “Account”).')}
                        ${t('During the registration process, TerraHub and its partners may collect your name, address, email address and username. Your information may be used by TerraHub:')}`}
                    </div>
                    <div className='bullet-points'>
                        <ul>
                            <li>{t('to verify your eligibility to create and to maintain your Account;')}</li>
                            <li>{t('to ensure that your Account, including your username, is unique;')}</li>
                            <li>{t('to enable TerraHub and its partners to provide you with the Services;')}</li>
                            <li>{t('to enable TerraHub and its partners to provide certain security and privacy safeguards;')}</li>
                            <li>{t('to deal with security, debugging and technical support issues;')}</li>
                            <li>{t('to protect ourselves and others from abuse; and')}</li>
                            <li>{t('to comply with law.')}</li>
                        </ul>
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('2.3')}</div>
                        {t('You agree at all times to: (a) provide accurate, current and complete information about yourself as prompted by our registration form or otherwise; and (b) maintain and update your information (including your e-mail address and contact information) to keep it accurate, current and complete.')}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('2.4')}</div>
                        {`${t('You acknowledge that you are responsible for safeguarding the password you use to access the Services and for any activities or actions under your Account.')}
                        ${t('You agree to notify us immediately of any unauthorized access to or use of your Account or any other breach of security.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('2.5')}</div>
                        {t('You further acknowledge that if any information provided by you is untrue, inaccurate, not current or incomplete, or if at any time you are not in compliance with the Terms, that we may immediately suspend or terminate your Account and your ability to use the TerraHub System')}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('2.6')}</div>
                        {`${t('TerraHub hereby disclaims any and all responsibility or liability for any unauthorized use of your Account.')}
                        ${t('You are entirely responsible for maintaining the confidentiality of your username and password and for any and all activities that are conducted through your Account.')}`}
                    </div>
                    <div className='content-title'>
                        <div className='text--xl-bold'>{t('3.0 License Grant And Use Restrictions')}</div>
                        <div className='sub-title text--lg-bold'>{t("TerraHub and other third parties own the information on the TerraHub System. You can only use it in limited ways.  Here's the official explanation:")}</div>
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('3.1')}</div>
                        {`${t('The TerraHub System is licensed, not sold.')}
                        ${t('Subject to the restrictions set forth in Section 3.2 below, TerraHub grants you a personal, revocable, non-exclusive, non-transferable, limited right to use the TerraHub System on a device(s) owned or controlled by you, and to access and use the TerraHub System on such device solely for your personal use, strictly in accordance with the terms and conditions of these Terms, TerraHub\'s Privacy Policy, and all applicable laws.')}
                        ${t('You represent, warrant and agree that you are using the TerraHub System solely for your own personal use and not for redistribution or transfer of any kind.')}`}`
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('3.2')}</div>
                        {`${t('The content contained on the TerraHub System, such as text, graphics, images, audio, video and other material, as well as the domain names, tagline, organization and user look-and-feel (collectively, the “Content”), is protected by copyright, trademark and other such laws in Canada, the United States and other foreign countries, and is owned or controlled by TerraHub or by third parties that have licensed their Content to TerraHub.')}
                        ${t('Unauthorized use of the Content may violate copyright, trademark, and other laws.')}
                        ${t('Where the TerraHub System is configured to enable the download of particular Content, you may download one copy of such Content to a single device for your personal, non-commercial use only, provided that')}:
                        ${t('(a) you retain all copyright and other proprietary notices contained in the original Content.')}
                        ${t('You shall not: (a) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the TerraHub System;')}
                        ${t('(b) make any modification, adaptation, improvement, enhancement, translation or derivative work from the TerraHub System;')}
                        ${t('(c) violate any applicable laws in connection with your access or use of the TerraHub System;')}
                        ${t('(d) remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of TerraHub System or its affiliates, partners, or suppliers;')}
                        ${t('(e) use the TerraHub System in a manner that derives revenue directly from the TerraHub System, or use the TerraHub System for any other purpose for which it is not designed or intended;')}
                        ${t('(f) distribute the System to other devices not owned by you;')}
                        ${t('(g) make the TerraHub System available over a network or other environment permitting access or use by multiple devices or users at the same time;')}
                        ${t('(h) use the TerraHub System for creating a product, service or software that is, directly or indirectly, competitive with or in any way a substitute for any services, product or software offered by TerraHub;')}
                        ${t('(i) use any proprietary information, interfaces or other intellectual property of TerraHub, or its affiliates, partners, or suppliers in the design, development, manufacture, licensing or distribution of any applications, accessories or devices for use with the TerraHub System;')}
                        ${t('(j) circumvent, disable or tamper with any security-related components or other protective measures applicable to the TerraHub System or your device;')}
                        ${t('(k) reproduce, archive, retransmit, distribute, disseminate, sell, lease, rent, exchange, modify, broadcast, synchronize, publicly perform, publish, publicly display, make available to third parties, transfer or circulate the TerraHub System;')}
                        ${t('or (l) use the Content in a manner that suggests an association with any of our products, services or brands.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('3.2')}</div>
                        {t('Any use of the Services or the TerraHub System not specifically permitted under these Terms is strictly prohibited.')}
                    </div>
                    <div className='content-title'>
                        <div className='text--xl-bold'>{t('4.0 Security')}</div>
                        <div className='sub-title text--lg-bold'>{t('We take reasonable steps to make sure the Services are secure, but cannot guarantee it. Here are the details:')}</div>
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('4.1')}</div>
                        {t('TerraHub makes commercially reasonable efforts to keep the Services and the TerraHub System secure and to protect the data you transmit to use through the Services. However, information sent or received over the Internet is inherently vulnerable.')}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('4.2')}</div>
                        {`${t('TerraHub cannot and does not make any representation nor warranty concerning security of any communication or any information sent to, from or through the Services or the TerraHub System.')}
                        ${t('TerraHub has no liability for any loss or damage you suffer as a result of the interception of, or unauthorized access to or use of, data transmitted via the Services or the TerraHub System.')}`}
                    </div>
                    <div className='content-title'>
                        <div className='text--xl-bold'>{t('5.0 Termination')}</div>
                        <div className='sub-title text--lg-bold'>{t('You can terminate your Account by letting us know. Here\'s some more information about this process:')}</div>
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('5.1')}</div>
                        {t('Your Account will remain registered until you make a request for termination by emailing')}
                        <a className='link' href='mailto:support@terrahub.ca'>{t('support@terrahub.ca.')}</a>
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('5.2')}</div>
                        {t('Upon the termination of these Terms, you shall cease all use of the TerraHub System and TerraHub shall archive your information from the TerraHub System.')}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('5.3')}</div>
                        {`${t('You understand and agree that TerraHub will not be liable to you or any third party for compensation, indemnity, or damages of any sort as a result of suspending or terminating the Terms in accordance with its terms and conditions.')}
                        ${t('Any suspension or termination of the Terms will be without prejudice to any other right or remedy TerraHub may have, now or in the future.')}`}
                    </div>
                    <div className='content-title'>
                        <div className='text--xl-bold'>{t('6.0 Disclaimer of Warranties')}</div>
                        <div className='text--lg-bold'>{t('We try to make sure the information accessible through the Services is accurate and complete, but we don’t guarantee it.  Your use of the Services is at your own risk. Here\'s the official explanation:')}</div>
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('6.1')}</div>
                        {t('You acknowledge and agree that the TerraHub System, including all content contained therein, and the services are provided on an “as is” and “as available” basis, and that your use of or reliance upon the TerraHub System and the Services is at your sole risk and discretion.')}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('6.2')}</div>
                       {`${t('Terrahub, its affiliates, licensors, and partners hereby disclaim any and all representations, warranties and guaranties regarding the TerraHub System and the Services, whether oral, express, implied or statutory, and whether arising by law, statute, usage of trade, custom, course of dealing or performance of the parties, or the nature or context of these Terms, and including, without limitation, the implied warranties of merchantability, quality, fitness for a particular purpose, title, and non-infringement.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('6.3')}</div>
                        {`${t('TerraHub, its affiliates, licensors, and partners make no warranty that: (a) the TerraHub System or the Services will meet your requirements;')}
                        ${t('(b) the TerraHub System or the Services will be uninterrupted, accurate, reliable, timely, secure, free from viruses or other harmful components or error-free; or (c) any errors in the TerraHub System or services will be corrected.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('6.3')}</div>
                        {t('You acknowledge that neither TerraHub, nor its affiliates, licensors, and suppliers have any obligation to correct any errors or otherwise support or maintain the TerraHub System.')}
                    </div>
                    <div className='content-title'>
                        <div className='text--xl-bold'>{t('7.0 Limitation of Liability')}</div>
                        <div className='sub-title text--lg-bold'>{t('We are not liable if you experience any issues with respect to the TerraHub System or the Services.  Here are the details:')}</div>
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('7.1')}</div>
                        {t('Your use of the TerraHub System is at your own risk. If you are dissatisfied with these Terms, your sole remedy is to discontinue use of the TerraHub System.')}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('7.2')}</div>
                        {`${t('Under no circumstances shall TerraHub be liable for any event(s), condition(s) or circumstance(s) that: (a) prevents, delays or restricts, in whole or in substantial part, the performance by TerraHub of some or all of its obligations hereunder;')}
                        ${t('and (b) are beyond the reasonable control of TerraHub, including, for example, any acts or omissions of a telecommunications carrier whose facilities are used to establish the necessary connections for TerraHub to provide the Services, or any suppliers of hosting, communication, software platforms or other components of the Services.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('7.3')}</div>
                        {`${t('Except to the extent prohibited by law, and in addition to the rights of TerraHub set out elsewhere in the Terms, in no event will TerraHub or any third parties mentioned on the TerraHub System be liable for any damages whatsoever (including, but not limited to, incidental, exemplary, and consequential damages, lost profits, or damages resulting from lost data or business interruption) resulting from the distribution, use or inability to use or access the TerraHub System or the Services, and the content, whether based on warranty, contract, tort, or any other legal theory, and whether or not TerraHub is advised of the possibility of such damages.')}
                        ${t('Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you, in which case such exclusion or limitation applies to the fullest extent allowable under the applicable law.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('7.4')}</div>
                        {t('TerraHub is not responsible or liable for the content or production of any content created during or as a result of the provision of the Services.')}
                    </div>
                    <div className='content-title'>
                        <div className='text--xl-bold'>{t('8.0 Indemnification')}</div>
                        <div className='sub-title text--lg-bold'>{t('You are responsible for what you do. Here\'s what that means')}</div>
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('8.1')}</div>
                        {`${t('You shall indemnify, defend and hold harmless the TerraHub, its affiliates, licensors, and partners, and all of their employees, directors, officers, volunteers, agents, representatives, successors and assigns from and against any claim, proceeding, loss, damage, fine, penalty, interest and expense (including fees for lawyers and other professional advisors on a full indemnity basis) arising out of or in connection with the following:')}
                        ${t('(a) your breach of the Terms; (b) your violation of applicable laws; (c) your negligent acts or omissions or willful misconduct; or (d) your violation of the rights of a third part.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('8.2')}</div>
                        {t('You will promptly notify TerraHub in writing of any third-party claim arising out of or in connection with your access to or use of the TerraHub System or the Services.')}
                    </div>
                    <div className='content-title'>
                        <div className='text--xl-bold'>{t('9.0 Miscellaneuos')}</div>
                        <div className='sub-title text--lg-bold'>{t('Here is some general information about some of the other terms that will govern your use of the TerraHub System:')}</div>
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('9.1')}</div>
                        {`${t('Governing Law and Venue. The Terms shall be deemed to have been made in the Province Alberta and shall accordingly be governed by and construed in accordance with the laws in the Province of Alberta,')}
                        ${t('excluding its conflicts of law principles. The courts located in the Province of Alberta shall have the non-exclusive jurisdiction to adjudicate any dispute arising out of or relating to the Terms, and you hereby consent to the non-exclusive jurisdiction of such courts.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('9.2')}</div>
                        {`${t('Severability.')}
                        ${t('If any provision of the Terms is held to be invalid or unenforceable with respect to a party, the remainder of the Terms, or the application of such provision to persons other than those to whom it is held invalid or unenforceable shall not be affected and each remaining provision of the Terms shall be valid and enforceable to the fullest extent permitted by law.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('9.3')}</div>
                        {`${t('Waiver. Except as provided herein, the failure to exercise a right or require performance of an obligation under the Terms shall not affect a party\'s ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute waiver of any subsequent breach.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('9.4')}</div>
                        {`${t('Third Party Beneficiaries. Except as explicitly provided in the Terms, nothing contained herein is intended or shall be construed to confer upon any person (other than the parties hereto) any rights, benefits or remedies of any kind or character, or to create any obligations or liabilities of a party to any such person.')}`}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('9.5')}</div>
                        {t('Acknowledgement. You acknowledge that you have been given the opportunity to obtain independent legal advice and have either obtained such advice or waived the need for such advice. You hereby voluntarily accept the Terms.')}
                    </div>
                    <div className='sub-content'>
                        <div className='numbered'>{t('9.6')}</div>
                        {t('Entire Agreement. The Terms constitute the entire agreement with respect to the use of the TerraHub System licensed hereunder and supersedes all prior or contemporaneous understandings regarding such subject matter.')}
                    </div>
                    <div className='content-title'>
                        <div className='text--xl-bold'>{t('10.0 Contact Information')}</div>
                        <div className='text--lg-bold'>{t('Please do not hesitate to contact us if you have any questions about these Terms.')}</div>
                    </div>
                    <div className='sub-content'>
                        <p>
                            {`${t('All questions and other communications regarding our product or the Services should be directed to')} `}
                            <a href='mailto:support@terrahub.ca'>{t('support@terrahub.ca')}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import CRD_SECURITY from './crd_security/crd_security';
import CRD_PERMISSIONS from './crd_permissions/crd_permissions';



export default function TAB_SECURITY({ credential_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_has_security, set_has_security ] = useState('');

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        populate_access();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_access() {
        try {
            let result = await API_get_credential_has_customsecurity();
            set_has_security(result);
        } catch(e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_has_customsecurity() {
        return API.get('security', '/get-credential-has-customsecurity/' + credential_id);
    }

    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            <CRD_SECURITY credential_id={credential_id} has_security={var_has_security} onChange={set_has_security} />
            {var_has_security === 'YES' && <CRD_PERMISSIONS credential_id={credential_id} />}
        </>
    )
};

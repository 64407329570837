import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message, Form } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Processing from 'components/cmp_processing';
import 'i18n';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_ADD_CREDENTIAL from './mdl_add_credential/mdl_add_credential';

import './crd_approved.css';



export default function CRD_APPROVED({ step, onChange_step, requirement, onChange_requirement, display_processing }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_opened, set_opened ] = useState(false)
    const [ var_errors, set_errors ] = useState([]);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_addcredential_open, set_addcredential_open ] = useState(false);

    const [ var_deletecredential_id, set_deletecredential_id ] = useState('');
    const [ var_deletecredential_open, set_deletecredential_open ] = useState(false);

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'credential' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_credentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement.approved]);

    useEffect(() => {
        set_errors([]);
        if (step === 'APPROVED') {
            set_opened(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (!var_addcredential_open && var_focuselement?.id === 'btn_addcredential') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addcredential_open]);

    useEffect(() => {
        if (!var_deletecredential_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_preapproved-credentials').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_deletecredential_open]);



    //  async functions ------------------------------------------------------------------------------------------------
    function populate_credentials() {
        set_totalrows(requirement.approved.length);
        set_ready(true);
    }



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        onChange_step('APPROVED');
    }

    function onClick_addcredential() {
        set_focuselement(document.activeElement);
        set_addcredential_open(true);
    }

    function onClick_deletecredential(id) {
        set_focuselement(document.activeElement);
        set_deletecredential_id(id);
        set_deletecredential_open(true);
    }

    function onConfirm_deletecredential() {
        let updated_requirement = { ...requirement };
        updated_requirement.approved = requirement.approved.filter(credential => credential.credential_id !== var_deletecredential_id);
        onChange_requirement(updated_requirement);
        set_focuselement(document.getElementById('crd_preapproved-credentials'));
        set_deletecredential_open(false);
    }

    function onClick_nextstep() {
        onChange_step('REJECTED');
        document.getElementById('crd_rejected-credentials').focus();
    }

    function onChange_preaproved_credentials(updated_requirement) {
        onChange_requirement(updated_requirement);
        set_addcredential_open(false);
    }



    // RENDER APP ======================================================================================================

    return (
        <div id='crd_preapproved-credentials' className='card rounded-lg shadow-sm' tabIndex='0'>

        <div className='card__header'>
                <div className='card__header__icon'>
                    {(var_opened && step !== 'APPROVED') ?
                        <Icon name='checkmark' className='icon__checkmark' alt='checkmark icon' />
                        :
                        <div className={'card__header__icon__step text--xl-bold' + (var_opened ? ' icon--dark_gray' : ' icon--light_gray')}>2</div>
                    }
                </div>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('Pre-approved credentials')}</div>
                    {(!var_opened || step === 'APPROVED') &&
                        <div className='text--sm-regular'>{t('Select credentials that are pre-approved to meet this requirement. This step is optional.')}</div>
                    }
                </div>
                <div className='card__header__right card__header__btns'>
                    {(var_opened && step !== 'APPROVED') &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {(step !== 'APPROVED' && var_opened) ?
                <>
                    <div id='create_credential__access' className='card__content'>
                        <Table  id='credentials'
                                loading={false} ready={var_ready}
                                loadingerror={false} lockcolumns={0}
                                refresh={var_refreshtable} totalrows={var_totalrows}
                                populateconfig={var_populateconfig}
                                populatefilterfunction={() => null}
                                downloadname={t('credentials')}
                                downloadfunction={() => null}
                                onChange={populate_credentials}
                                hide_tablecontrols={true}>

                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell field='issuer_name' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                                    <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {requirement.approved.map(item =>
                                    <Table.Row key={item.credential_id}>
                                        <Table.Cell>{item.issuer_name}</Table.Cell>
                                        <Table.Cell>{item.credential_name}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    </div>
                </>
                : step === 'APPROVED' ?
                    <>
                        <Form className='card__content'>

                            {var_errors.length > 0 &&
                                <Message
                                    error icon={<Icon name='error' className='icon' />}
                                    header={t('There are some errors with your inputs')}
                                />
                            }

                            <Table id='credentials'
                                   loading={false} ready={var_ready}
                                   loadingerror={false} lockcolumns={0}
                                   refresh={var_refreshtable} totalrows={var_totalrows}
                                   populateconfig={var_populateconfig}
                                   populatefilterfunction={() => null}
                                   downloadname={t('credentials')}
                                   downloadfunction={() => null}
                                   onChange={populate_credentials}
                                   hide_tablecontrols={true}>

                                <Table.OptionsButtons>
                                    <Button id='btn_addcredential' type='button' className='secondary' onClick={onClick_addcredential}>{t('Add credential')}</Button>
                                </Table.OptionsButtons>

                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell field='issuer_name' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                                        <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {requirement.approved.map(item =>
                                        <Table.Row key={item.credential_id}>
                                            <Table.Cell>{item.issuer_name}</Table.Cell>
                                            <Table.Cell className='cell__icon--right'>
                                                <div>{item.credential_name}</div>
                                                <Button className='tertiary' onClick={() => onClick_deletecredential(item.credential_id)} >
                                                    <Icon name='delete' className='color--primary-500' />
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>

                            <CMP_CONFIRMATION
                                display={var_deletecredential_open}
                                title={t('Remove credential')}
                                message={
                                <div style={{flexDirection: 'column'}}>
                                    <div style={{marginBottom: '0.5rem'}}>{t('Are you sure you want to remove') + ' ' + requirement.approved.find(item => item.credential_id === var_deletecredential_id)?.credential_name + ' ' + t('from the pre-approved list?')}</div>
                                </div>
                            }
                                positive_option={t('Remove')}
                                negative_option={t('Cancel')}
                                onConfirm={onConfirm_deletecredential}
                                onCancel={() => set_deletecredential_open(false)}
                            />

                            <MDL_ADD_CREDENTIAL
                                display={var_addcredential_open}
                                onClose={() => set_addcredential_open(false)}
                                requirement={requirement}
                                onChange={onChange_preaproved_credentials}
                            />

                            <div className='card__footer'>
                                <Button type='button' className='primary' onClick={onClick_nextstep}>{t('Continue to step 3')}</Button>
                            </div>
                        </Form>
                    </>
                    : null
            }

            <Processing display={display_processing} processingtext='' />

        </div>
    );
}
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import { Button, Message } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import CMP_CREDENTIAL_STATUS_ICON from 'components/cmp_credential_status_icon/cmp_credential_status_icon';
import 'i18n';

import MDL_SCANQR from './mdl_scanqr/mdl_scanqr';
import CMP_ADDCREDENTIAL from 'components/cmp_addcredential/cmp_addcredential';

import './crd_credentials.css';



export default function CRD_CREDENTIALS({ category, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'credential_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_addcredential_open, set_addcredential_open ] = useState(false);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);
    const [ var_message_credential, set_message_credential ] = useState(null);

    const [ var_scanqr_open, set_scanqr_open ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_credentials({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_archive, category, Window.$is_unlocked]);

    useEffect(() => {
        if (!var_addcredential_open && var_focuselement?.id === 'btn_addcredential') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    },[var_addcredential_open]);

    useEffect(() => {
        if (!var_scanqr_open && var_focuselement?.id === 'btn_qrcode') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    },[var_scanqr_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials(populateconfig, archive) {
        set_message_text(null);
        set_message_credential(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        if (archive) {
            set_archive(archive);
        } else {
            archive = var_archive
        }

        try {
            if (Window.$is_unlocked) {
                let results = await API_get_credentials(limit, offset, sortby, sortorder, archive, filter);
                set_totalrows(results.totalrows);
                set_credentials(results.results === undefined ? [] : results.results);
                set_loading(false);
                set_loadingerror(false);
                set_ready(true);

                if (var_focuselement?.id) {
                    let observer = new MutationObserver(function() {
                        let focuselement = document.querySelector(`#${var_focuselement.id}`);
                        if (document.contains(focuselement)) {
                            focuselement.focus();
                            observer.disconnect();
                        } else {
                            document.querySelector('#crd_credentials').focus();
                            observer.disconnect();
                        }
                    });
                    observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
                }
            }
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_credentials(var_populateconfig.maxfilteritems, 0, filtername, sortorder, var_archive, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_credentials(limit, offset, sortby, sortorder, var_archive, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credentials(limit, offset, sortby, sortorder, archive, filter, filtername) {
        return API.post('credentials', '/get-credentials/' + category + '/' + (archive === 'YES' ? 'ARCHIVE' : 'ACTIVE'),
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_credentialstatus(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_archive(status);
    }

    function onClick_credential(credentialid) {
        if (!credentialid) return;

        set_message_text(null);
        set_message_credential(null);
        navigate('/credentials/' + category + '/' + credentialid);
    }

    function onClick_addcredential() {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_addcredential_open(true);
    }

    function onChange_addcredential(issuer_name, credential_name, credential_category_id, individual_credential_id) {
        if (category !== 'ALL' && credential_category_id !== category) {
            navigate('/credentials/' + credential_category_id.toLowerCase());
        } else {
            populate_credentials();
        }
        set_addcredential_open(false);
        onChange();
        set_message_type('SUCCESS');
        set_message_text(<div style={{ display: 'inline' }}><span className='text--anchor'>{credential_name}</span> {t('from')} {issuer_name} {t('has been added')}</div>);
        set_message_credential(individual_credential_id);
    }

    function onChange_scanqr() {
        populate_credentials();
        set_scanqr_open(false);
        onChange();
        set_message_type('SUCCESS');
        set_message_text(t('The credential has been added.'));
    }

    function onClick_scanqr() {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_scanqr_open(true);
    }

    function onKeyDown(event, id) {
        if (event.key === 'Enter') {
            onClick_credential(id);
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_credentials' tabIndex='0'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_credentials' className='text--xl-medium'>{category === 'ALL' ? t('All credentials') : t(category)}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_addcredential' className='primary' onClick={onClick_addcredential}>{t('Add credential')}</Button>
                        {!!auth.passphrase_parameters && <Button id='btn_qrcode' className='secondary' onClick={onClick_scanqr}><Icon name='qr_code' className='button_icon'/></Button>}
                    </div>
                </div>

                {var_message_text &&
                    <Message id='Msg1' tabIndex='0' role='link'
                        success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text}
                        onClick={()=>onClick_credential(var_message_credential)}
                        onKeyDown={(e)=>onKeyDown(e, var_message_credential)} />
                }

                <Table id='credentials' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Credentials')} downloadfunction={download_records}
                        onChange={populate_credentials}>
                    <Table.OptionsButtons>
                        <TOGGLE value={var_archive} onChange={onClick_credentialstatus} options={[{value: 'NO', text: t('Active')}, {value: 'YES', text: t('Inactive')}]} />
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className='cell__icon' field='confirmation_status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell field='issuer_name' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                            <Table.HeaderCell field='issued_date' datatype='date' filtertype='date'>{t('ISSUED')}</Table.HeaderCell>
                            <Table.HeaderCell field='expiry_date' datatype='date' filtertype='date'>{t('EXPIRES')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_credentials.map(item =>
                            <Table.Row key={item.id} onClick={() => onClick_credential(item.id)}>
                                <Table.Cell className='cell__icon cell--center'>
                                    <CMP_CREDENTIAL_STATUS_ICON confirmation_status={item.confirmation_status} is_self_verified={item.is_self_verified} />
                                </Table.Cell>
                                <Table.Cell>
                                    <Table.CellContentTwoLine
                                        primary_text={
                                            <div>
                                                {item.credential_name}
                                                {item.has_customsecurity === 'YES' && <Icon name='lock' className='icon__lock color--gray-400' alt={t('lock icon')} />}
                                            </div>
                                        }
                                        secondary_text={item.confirmation_status_display}
                                        />

                                </Table.Cell>
                                <Table.Cell>
                                    {item.issuer_name}
                                </Table.Cell>
                                <Table.Cell>
                                    {datelib.epoch_to_date_string(item.issued_date)}
                                </Table.Cell>
                                <Table.Cell className={'cell__icon--right'}>
                                    <Table.CellContentTwoLine
                                        secondary_text={datelib.epoch_to_date_string(item.expiry_date)}
                                        display_chevron={true} />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button id='btn_addcredential' className='primary' onClick={onClick_addcredential}>{t('Add credential')}</Button>
                    {!!auth.passphrase_parameters && <Button id='btn_qrcode' className='secondary' onClick={onClick_scanqr}><Icon name='qr_code' className='button_icon'/></Button>}
                </div>

            </div>

            <MDL_SCANQR
                display={var_scanqr_open}
                onClose={() => set_scanqr_open(false)}
                onChange={onChange_scanqr}
            />

            <CMP_ADDCREDENTIAL display={var_addcredential_open} onClose={() => set_addcredential_open(false)} onChange={onChange_addcredential} default_credential_category={category} />

        </>
    );
}
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import CRD_DETAILS from './crd_details/crd_details';
import CRD_APPROVED from './crd_approved/crd_approved';
import CRD_REJECTED from './crd_rejected/crd_rejected';



export default function REQUIREMENT_CREATE() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_step, set_step ] = useState('DETAILS');
    const [ var_requirement, set_requirement ] = useState({ approved: [], rejected: [] });
    const [ var_processing, set_processing ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }, { path: '/org/requirements', text: t('Requirements') }]}
                current_page_text={t('Create requirement')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background2'>
                    <h1 className='display--sm-regular'>{t('Create requirement')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <CRD_DETAILS
                    step={var_step}
                    onChange_step={set_step}
                    requirement={var_requirement}
                    onChange_requirement={set_requirement}
                    display_processing={var_processing}
                />

                <CRD_APPROVED
                    step={var_step}
                    onChange_step={set_step}
                    requirement={var_requirement}
                    onChange_requirement={set_requirement}
                    display_processing={var_processing}
                />

                <CRD_REJECTED
                    step={var_step}
                    onChange_step={set_step}
                    requirement={var_requirement}
                    onChange_requirement={set_requirement}
                    onChange_processing={set_processing}
                    display_processing={var_processing}
                />
            </div>
        </div>
    )
};
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { SELECT_RADIO } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from 'components/cmp_icon';

import './mdl_addorganization.css';



export default function MDL_ADDORGANIZATION({ display, onClose, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_organizations, set_organizations ] = useState([]);
    const [ var_selected_organizations, set_selected_organizations ] = useState([]);
    const [ var_error, set_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            // reset and populate options
            set_modal(document.querySelector('#mdl_addorganization'));
            set_organizations([]);
            set_selected_organizations([]);
            set_error(null);
            set_processing(false);
            populate_organizations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_organizations() {
        try {
            let results = await API_get_available_network_organizations();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: item.name,
                secondary_text: item.is_networked === 'YES' ? t('Already linked as an affiliate.') : null,
                disabled: item.is_networked === 'YES'
            }));
            set_organizations(transformed);
        } catch (e) {
            console.log(e);
        }
    }

    async function add_organizations(profile) {
        set_processing(true);
        try {
            await API_post_org_network(profile);
            onChange && onChange();
            onClose();
        } catch (exception) {
            console.log(exception);
            set_error(t('An unknown error has occurred. Please try again.'));
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_available_network_organizations() {
        return API.get('org-profile', '/get-available-network-organizations');
    }

    function API_post_org_network() {
        return API.post('org-profile', '/post-org-network',
            {
                body: { organization_ids: var_selected_organizations }
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_organizations(value) {
        set_selected_organizations(value);
        set_error(null);
    }

    function onClick_add() {
        if (var_processing) return;

        if (var_selected_organizations.length === 0) {
            set_error(t('Please select at least one organization'));
        } else {
            add_organizations();
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_addorganization'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_addorg'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_addorg'>{t('Add organization')}</div>
                </div>
            </div>

            <Form className='modal__content' tabIndex='0' id='form_addorg' aria-labelledby='hdr_addorg'>
                {var_error &&
                    <Message error
                        icon={<Icon name='error' className='icon' alt={t('alert icon')} />}
                        header={var_error} />
                }
                <SELECT_RADIO
                    name='holders'
                    value={var_selected_organizations}
                    options={var_organizations}
                    single_or_multiple='MULTIPLE'
                    onChange={onChange_organizations}
                    aria_label={t('Select the organizations you want to invite for affiliation')}
                />
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add} disabled={var_organizations.length === 0}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')}/>

        </Modal>
    );

}
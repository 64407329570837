import React, { useState, useEffect } from 'react';
import API from 'libs/api-lib';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import datelib from 'libs/date-lib';
import auth from 'libs/auth-lib';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import CMP_ASSIGNMENT_CARD from 'components/cmp_assignment_card/cmp_assignment_card';
import TAB_OVERVIEW from './tab_overview/tab_overview';
import TAB_DOCUMENTS from './tab_documents/tab_documents';
import TAB_REQUIREMENTS from './tab_requirements/tab_requirements';
import TAB_INDIVIDUALS from './tab_individuals/tab_individuals';
import TAB_DAILYCHECKS from './tab_dailychecks/tab_dailychecks';
import TAB_CREDENTIALS from './tab_credentials/tab_credentials';
import TAB_SHARING from './tab_sharing/tab_sharing';
import TAB_SETTINGS from './tab_settings/tab_settings';
import TABS from 'components/cmp_tabs/cmp_tabs';
import Icon from 'components/cmp_icon';
import './assignment.css';


export default function ASSIGNMENT() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_tab, set_tab ] = useState('OVERVIEW');
    const [ var_assignment, set_assignment ] = useState({});


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        if (params.tab) {
            set_tab(params.tab.toUpperCase());
        } else {
            set_tab('OVERVIEW');
        }
        populate_assignment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.locationid]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_assignment() {
        set_assignment(await API_get_assignment());
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_assignment() {
        return API.get('indv', '/get-assignments/' + params.locationid, { queryStringParameters: { tz: datelib.timezone }});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='page' id='assignments'>
            <CMP_BREADCRUMB
                breadcrumb_elements={auth.has_orgaccess('ORG-LOC-ADMIN', 'menu') ? [{ path: '/assignments', text: t('Assignments') }] : []}
                current_page_text={var_assignment.location_name || ''}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header'>
                    <h1 className='display--sm-regular'>{var_assignment.location_name}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                    {var_assignment.location_id &&
                        <CMP_ASSIGNMENT_CARD name={var_assignment.location_name} address={var_assignment.location_address}
                            organization_id={var_assignment.organization_id} organization_name={var_assignment.organization_name} logo={var_assignment.logo} status={var_assignment.status}
                            assignment_status={var_assignment.assignment_status} cardtype='FULL' assignment_id={var_assignment.location_id} organization_host={var_assignment.host}
                            organization_qraccess ={var_assignment.organization_qraccess} />
                    }
                </div>

                <nav aria-label={t('Assignment')}>
                <TABS activetab={var_tab} details_card={true} onClick={onClick_tab} id='tabs_assignment'>
                    <TABS.TAB name='OVERVIEW' label={t('Overview')} />
                    <TABS.TAB name='INDIVIDUALS' label={t('Individuals')} display={auth.has_access(var_assignment, 'ORG-INDV', 'read')}/>
                    <TABS.TAB name='DAILYCHECKS' label={t('Daily check')} display={auth.has_access(var_assignment, 'ORG-DAILY', 'read')}/>
                    <TABS.TAB name='DOCUMENTS' label={t('Documents')} display={(auth.has_access(var_assignment, 'INDV-DOC', 'document') || auth.has_access(var_assignment, 'ORG-DOC', 'read'))}/>
                    <TABS.TAB name='REQUIREMENTS' label={t('Requirements')} display={(auth.has_access(var_assignment, 'INDV-REQ', 'requirement') || auth.has_access(var_assignment, 'ORG-REQ', 'read'))}/>
                    <TABS.TAB name='CREDENTIALS' label={t('Credentials')} display={auth.has_access(var_assignment, 'ORG-CRED', 'read')}/>
                    <TABS.TAB name='SHARING' label={t('Sharing')} display={auth.has_access(var_assignment, 'ORG-LOC', 'share')}/>
                    <TABS.TAB name='SETTINGS' label={t('Settings')} display={auth.orgaccess_organization_ids('ORG-LOC-ADMIN', 'read').includes(var_assignment.organization_id)}/>
                </TABS>
                </nav>

                {var_tab === 'OVERVIEW' &&
                    <TAB_OVERVIEW assignment={var_assignment} onChange={populate_assignment} activetab={var_tab} onChangeTab={onClick_tab} />
                }

                {var_tab === 'DOCUMENTS' &&
                    <TAB_DOCUMENTS assignment={var_assignment} onChange={populate_assignment} activetab={var_tab} />
                }

                {var_tab === 'REQUIREMENTS' &&
                    <TAB_REQUIREMENTS assignment={var_assignment} onChange={populate_assignment} activetab={var_tab} />
                }

                {var_tab === 'INDIVIDUALS' &&
                    <TAB_INDIVIDUALS assignment={var_assignment} activetab={var_tab} />
                }

                {var_tab === 'DAILYCHECKS' &&
                    <TAB_DAILYCHECKS assignment={var_assignment} activetab={var_tab} />
                }

                {var_tab === 'CREDENTIALS' &&
                    <TAB_CREDENTIALS assignment={var_assignment} activetab={var_tab} />
                }

                {var_tab === 'SHARING' &&
                    <TAB_SHARING assignment={var_assignment} />
                }

                {var_tab === 'SETTINGS' &&
                    <TAB_SETTINGS assignment={var_assignment} activetab={var_tab} onChange={populate_assignment} />
                }

            </div>

        </div>
    )
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Processing from 'components/cmp_processing';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_ADD_REQUIREMENT from './mdl_addrequirement/mdl_addrequirement';



export default function CRD_REQUIREMENTS({ requirement_package_id, requirement_package_name }) {

//  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_processing, set_processing ] = useState(false);

    const [ var_deleterequirement_open, set_deleterequirement_open ] = useState(false);
    const [ var_deleterequirement_id, set_deleterequirement_id ] = useState(null);
    const [ var_addrequirement_open, set_addrequirement_open ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (requirement_package_id) {
            set_refreshtable(!var_refreshtable);
            populate_requirements();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement_package_id]);

    useEffect(() => {
        if (!var_addrequirement_open && var_focuselement?.id === 'btn_addrequirement') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addrequirement_open]);

    useEffect(() => {
        if (!var_deleterequirement_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_pkgdetails_requirements').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_deleterequirement_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirements(populateconfig) {
        set_save_error(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_package_requirements(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_requirements(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_package_requirements(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_requirements(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_package_requirements(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function delete_requirement() {
        set_focuselement(document.getElementById('crd_pkgdetails_requirements'));
        set_deleterequirement_open(false);
        set_processing(true);
        try {
            await API_delete_package_requirement();
            populate_requirements();
        } catch(e) {
            set_save_error(t('An unknown error has occurred. Please try again.'));
            console.log(e);
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_package_requirements(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-package-requirements/' + requirement_package_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_delete_package_requirement() {
        return API.del('requirements', '/delete-package-requirement/' + var_deleterequirement_id);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onDelete_requirement(id) {
        set_focuselement(document.activeElement);
        set_save_error(null);
        set_deleterequirement_id(id);
        set_deleterequirement_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_pkgdetails_requirements' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Requirements')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_addrequirement' className='primary' onClick={() => {set_focuselement(document.activeElement); set_addrequirement_open(true)}}>{t('Add requirement')}</Button>
                    </div>
                </div>

                {var_save_error &&
                    <Message error
                        header={var_save_error}
                    />
                }

                <Table id='requirements' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('requirements')} downloadfunction={download_requirements}
                       onChange={populate_requirements}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('REQUIREMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='description' datatype='text' filtertype='text'>{t('DESCRIPTION')}</Table.HeaderCell>
                            <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_requirements.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>{item.name}</Table.Cell>
                                <Table.Cell>{item.description}</Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    {
                                        item.status === 'ACTIVE' ?
                                            <div className='badge text--sm-medium badge--green'>
                                                {item.status_display}
                                            </div>
                                            :
                                            <div className='badge text--sm-medium badge--gray'>
                                                {item.status_display}
                                            </div>
                                    }
                                    <Button className='tertiary' onClick={() => onDelete_requirement(item.id)}>
                                        <Icon name='delete' className='color--primary-500' />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button id='btn_addrequirement' className='primary' onClick={() => set_addrequirement_open(true)}>{t('Add requirement')}</Button>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>

            <CMP_CONFIRMATION display={var_deleterequirement_open} title={t('Remove requirement')} message={
                <div style={{flexDirection: 'column'}}>
                    <div>{t('Are you sure you want to remove the requirement of') + ' ' + var_requirements.find(item => item.id === var_deleterequirement_id)?.name + ' ' + t('from this package?')}</div>
                </div>
            } positive_option={t('Remove')} negative_option={t('Cancel')} onConfirm={delete_requirement} onCancel={() => set_deleterequirement_open(false)} />

            <MDL_ADD_REQUIREMENT
                display={var_addrequirement_open}
                onClose={() => set_addrequirement_open(false)}
                requirement_package_name={requirement_package_name}
                requirement_package_id={requirement_package_id}
                onChange={populate_requirements}
            />


        </>

    )
}
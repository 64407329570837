import React, { useState, useEffect } from 'react';
import API from 'libs/api-lib';
import { Modal, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './mdl_survey.css';


export default function MDL_SURVEY({ display, onClose, onChange, individualprequalifier_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_survey, set_survey ] = useState({});
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {
            let query_selector = '.modal__content, button:not([disabled]), input:not([disabled]), label[role="radio"], textarea';
            //  set focus to first element within the modal
            var_modal.querySelector(query_selector).focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    let elements = var_modal.querySelectorAll(query_selector);
                    let firstelement = elements[0];
                    let lastelement = elements[elements.length - 1];
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_survey'));
            set_survey({});
            load_survey();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function load_survey() {
        set_survey(await API_get_credential_prequalifier_survey());
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_prequalifier_survey() {
        return API.get('credentials', '/get-credential-prequalifier-survey/' + individualprequalifier_id);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_Close() {
        onChange && onChange();
        onClose();
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClick_Close}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_survey'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_survey'
            className={var_survey.survey_data ? 'survey_loaded' : undefined}
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_survey'>{var_survey.title}</div>
                </div>
            </div>
            <div className='modal__content' tabIndex='0'>
                {var_survey.survey_data &&
                    <iframe
                        title={var_survey.title}
                        src={`https://www.cognitoforms.com/f/${var_survey.survey_data.account_id}/${var_survey.survey_data.form_id}?entry=${encodeURIComponent(JSON.stringify(var_survey.survey_data.data))}`}
                        width='100%'
                        height='100%'
                        frameBorder='0'
                    />}
            </div>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='secondary' onClick={onClick_Close}>{t('Close')}</Button>
                </div>
            </div>
        </Modal>
    );
}
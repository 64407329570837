import React from 'react';
import config from 'config';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import 'i18n';



export default function CONTENT_ONBOARD_1({ onChangeContent, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');



    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_skip() {
        sessionStorage.setItem('onboarding', false);
        onClose();
    }

    function onClick_next() {
        onChangeContent('ONBOARD_2');
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <img src={`${config.images.url}${config.images.assets.onboarding_1}${config.images.stage}`} alt={t('onboarding header image')} />

            <div className='progress__indicator__wrapper'>
                <div className='progress__indicator active'></div>
                <div className='progress__indicator'></div>
                <div className='progress__indicator'></div>
            </div>

            <div className='modal__content center padding--lg' tabIndex='0'>
                <div className='display--xs-bold'>{t('Welcome to Credivera')}</div>
                <div className='text--sm-regular'>{t('We are the world’s first secure and open exchange for verifiable credentials. With Credivera, you can effortlessly manage your credentials, securely share them, and monitor their verification status.')}</div>

                <div className='modal__footer'>
                        <Button className='primary' onClick={onClick_next}>{t('Next')}</Button>
                        <Button className='secondary' onClick={onClick_skip}>{t('Skip')}</Button>
                </div>
            </div>
        </>
    );

}

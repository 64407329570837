import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import 'i18n';
import Processing from 'components/cmp_processing';

import './crd_verification_credits.css';

export default function CRD_VERIFICATION_CREDITS({ credits, processing }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_verification_credits' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_verification_credits' className='text--xl-medium'>{t('Verification credits')}</div>
                    </div>
                </div>
                {   Object.keys(credits).length === 0
                    ? null
                    : credits.available <= 0
                    ? <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={
                            <div className='warning_message'>
                                {t('You have no remaining verification credits. To purchase more, please') + ' '}
                                <a href='https://helpcenter.credivera.com/en/knowledge/kb-tickets/new' target='_blank' rel='noopener noreferrer'>
                                    {t('contact us')}
                                </a>
                                {'.'}
                            </div>
                            }
                        />
                    : (credits.allocated + credits.used) / (credits.available + credits.allocated + credits.used) >= 0.9
                    ? <Message warning
                        icon={<Icon name='warning' className='icon' />}
                        header={
                            <div className='warning_message'>
                                {t('You have used 90% of your verification credits. To purchase more, please') + ' '}
                                <a href='https://helpcenter.credivera.com/en/knowledge/kb-tickets/new' target='_blank' rel='noopener noreferrer'>
                                    {t('contact us')}
                                </a>
                                {'.'}
                            </div>
                            }
                        />
                    : null
                }
                <div className='verification_credits_wrapper'>
                    <div className='credits_pie_chart'>
                        <PieChart
                            data={[
                                { value: credits.available, color: 'var(--color-blue-500)' },
                                { value: credits.allocated, color: 'var(--color-yellow-500)' },
                                { value: credits.used, color: 'var(--color-red-500)' }
                            ]}
                        />
                        <div className='chart_labels'>
                            <div className='label_wrapper'>
                                <div className='label_rectangle available'></div>
                                {t('Available')}
                            </div>
                            <div className='label_wrapper'>
                                <div className='label_rectangle allocated'></div>
                                {t('Allocated')}
                            </div>
                            <div className='label_wrapper'>
                                <div className='label_rectangle used'></div>
                                {t('Used')}
                            </div>
                        </div>
                    </div>

                    <div className='credit_display_wrapper'>
                        <div className='credit_display shadow-sm'>
                            <div className='text--sm-regular'>{t('Available credits')}</div>
                            <div className='text--sm-medium credit_number'>{credits.available}</div>
                        </div>
                        <div className='credit_display shadow-sm'>
                            <div className='text--sm-regular'>{t('Allocated credits')}</div>
                            <div className='text--sm-medium credit_number'>{credits.allocated}</div>
                        </div>
                        <div className='credit_display shadow-sm'>
                            <div className='text--sm-regular'>{t('Used credits')}</div>
                            <div className='text--sm-medium credit_number'>{credits.used}</div>
                        </div>
                    </div>
                </div>
                <Processing display={processing} processingtext={t('Processing')} />
            </div>
        </>

    )
}
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Message, Form } from 'semantic-ui-react';
import form_helper from 'libs/form-lib';
import Icon from 'components/cmp_icon';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Processing from "components/cmp_processing";
import 'i18n';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_ADD_REQUIREMENT from './mdl_add_requirement/mdl_add_requirement';

import './crd_requirements.css';



export default function CRD_APPROVED({ step, onChange_step, requirement_package, onChange_package, onChange_processing, display_processing }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_opened, set_opened ] = useState(false)
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_addrequirement_open, set_addrequirement_open ] = useState(false);

    const [ var_deleterequirement_id, set_deleterequirement_id ] = useState('');
    const [ var_deleterequirement_open, set_deleterequirement_open ] = useState(false);

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'name' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_requirements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement_package.requirements]);

    useEffect(() => {
        if(step === 'REQUIREMENTS') {
            set_opened(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (!var_addrequirement_open && var_focuselement?.id === 'btn_addrequirement') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addrequirement_open]);

    useEffect(() => {
        if (!var_deleterequirement_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_package-requirements').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_deleterequirement_open]);



    //  async functions ------------------------------------------------------------------------------------------------
    function populate_requirements() {
        set_totalrows(requirement_package.requirements.length);
        set_ready(true);
    }

    async function insert_package(updated_package) {
        onChange_processing(true);
        try {
            let package_id = await API_post_package(updated_package);
            navigate('/org/packages/' + package_id);
        } catch (exception) {
            console.log(exception);
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
        onChange_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_package(body) {
        return API.post('requirements', '/post-package', { body });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_save_error(null);
        onChange_step('REQUIREMENTS');
    }

    function onClick_addrequirement() {
        set_focuselement(document.activeElement);
        set_save_error(null);
        set_addrequirement_open(true);
    }

    function onClick_deleterequirement(id) {
        set_focuselement(document.activeElement);
        set_save_error(null);
        set_deleterequirement_id(id);
        set_deleterequirement_open(true);
    }

    function onConfirm_deleterequirement() {
        let updated_package = {...requirement_package};
        updated_package.requirements = updated_package.requirements.filter(item => item.id !== var_deleterequirement_id);
        onChange_package(updated_package);
        set_focuselement(document.getElementById('crd_package-requirements'));
        set_deleterequirement_open(false);
    }

    function onClick_save() {
        set_save_error(null);
        let updated_package = {...requirement_package};
        if (!form_helper.validate_required_string(updated_package.name)) {
            set_save_error(t('Missing required field from Step 1'));
            return;
        }

        form_helper.cleanse_string_property(updated_package, 'name');

        onChange_package(updated_package);
        insert_package(updated_package);
    }



    // RENDER APP ======================================================================================================

    return (
        <div id='crd_package-requirements' className='card rounded-lg shadow-sm' tabIndex='0'>

            <div className='card__header'>
                <div className='card__header__icon'>
                    {(var_opened && step !== 'REQUIREMENTS') ?
                        <Icon name='checkmark' className='icon__checkmark' alt='checkmark icon' />
                        :
                        <div className={'card__header__icon__step text--xl-bold' + (var_opened ? ' icon--dark_gray' : ' icon--light_gray')}>2</div>
                    }
                </div>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('Requirements')}</div>
                    {(!var_opened || step === 'REQUIREMENTS') &&
                        <div className='text--sm-regular'>{t('Select the requirements you want included in this package.')}</div>
                    }
                </div>
                <div className='card__header__right card__header__btns'>
                    {(var_opened && step !== 'REQUIREMENTS') &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {var_save_error &&
                <Message error
                    header={var_save_error}
                />
            }

            {(step !== 'REQUIREMENTS' && var_opened) ?
                <>
                    <div className='card__content'>
                        <Table id='requirements'
                               loading={false} ready={var_ready}
                               loadingerror={false} lockcolumns={0}
                               refresh={var_refreshtable} totalrows={var_totalrows}
                               populateconfig={var_populateconfig}
                               populatefilterfunction={() => null}
                               downloadname={t('requirements')}
                               downloadfunction={() => null}
                               onChange={populate_requirements}
                               hide_tablecontrols={true}>

                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('REQUIREMENT')}</Table.HeaderCell>
                                    <Table.HeaderCell field='description' datatype='text' filtertype='text'>{t('DESCRIPTION')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {requirement_package.requirements.map(item =>
                                    <Table.Row key={item.id}>
                                        <Table.Cell>{item.name}</Table.Cell>
                                        <Table.Cell>{item.description}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    </div>
                </>
                : step === 'REQUIREMENTS' ?
                    <>
                        <Form className='card__content'>

                            <Table id='requirements'
                                   loading={false} ready={var_ready}
                                   loadingerror={false} lockcolumns={0}
                                   refresh={var_refreshtable} totalrows={var_totalrows}
                                   populateconfig={var_populateconfig}
                                   populatefilterfunction={() => null}
                                   downloadname={t('requirements')}
                                   downloadfunction={() => null}
                                   onChange={populate_requirements}
                                   hide_tablecontrols={true}>

                                <Table.OptionsButtons>
                                    <Button id='btn_addrequirement' type='button' className='secondary' onClick={onClick_addrequirement}>{t('Add requirement')}</Button>
                                </Table.OptionsButtons>

                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('REQUIREMENT')}</Table.HeaderCell>
                                        <Table.HeaderCell field='description' datatype='text' filtertype='text'>{t('DESCRIPTION')}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {requirement_package.requirements.map(item =>
                                        <Table.Row key={item.id}>
                                            <Table.Cell>{item.name}</Table.Cell>
                                            <Table.Cell className='cell__icon--right'>
                                                <div>{item.description}</div>
                                                <Button className='tertiary' onClick={() => onClick_deleterequirement(item.id)}>
                                                    <Icon name='delete' className='color--primary-500'  />
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>

                            <CMP_CONFIRMATION
                                display={var_deleterequirement_open}
                                title={t('Remove requirement')}
                                message={
                                    <div style={{flexDirection: 'column'}}>
                                        <div style={{marginBottom: '0.5rem'}}>{t('Are you sure you want to remove') + ' ' + requirement_package.requirements.find(item => item.id === var_deleterequirement_id)?.name + ' ' + t('from this package?')}</div>
                                    </div>
                                }
                                positive_option={t('Remove')}
                                negative_option={t('Cancel')}
                                onConfirm={onConfirm_deleterequirement}
                                onCancel={() => set_deleterequirement_open(false)}
                            />

                            <MDL_ADD_REQUIREMENT
                                display={var_addrequirement_open}
                                onClose={() => set_addrequirement_open(false)}
                                requirement_package={requirement_package}
                                onChange_package={onChange_package}
                            />

                            <div className='card__footer'>
                                <Button type='submit' className='primary' onClick={onClick_save}>{t('Save package')}</Button>
                            </div>

                        </Form>
                    </>
                    : null
            }

            <Processing display={display_processing} processingtext={t('Processing')} />

        </div>
    );
}
import React from 'react';
import API from 'libs/api-lib';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import datelib from 'libs/date-lib';
import 'i18n';

import './crd_linkedin.css';


export default function CRD_LINKEDIN( { credential }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------
    async function get_certification_url() {
        try {
            return await API_get_certification_url();
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------
    function API_get_certification_url() {
        return API.post('external-report', '/post-external-report/',
        {
            body: { report_type: 'INDIVIDUAL_CREDENTIAL_LINKEDIN', credential_ids: [credential.id] }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------


    async function onClick_linkedin() {
        // get all session storage items into a local variable and clear session storage
        let session_items = Object.keys(sessionStorage).map(key => ({ key, value: sessionStorage.getItem(key) }));
        sessionStorage.clear();

        // open an empty window
        const tab = window.open('about:blank');

        // now restore the session storage from the local variable
        session_items.forEach(item => sessionStorage.setItem(item.key, item.value));

        let certificate_url_data = await get_certification_url();

        if (certificate_url_data) {
            let issueMonth = credential.issued ? datelib.epoch_to_utcmoment(credential.issued).format('M') : '';
            let issueYear = credential.issued ? datelib.epoch_to_utcmoment(credential.issued).format('YYYY') : '';
            let expireMonth = credential.expires ? datelib.epoch_to_utcmoment(credential.expires).format('M') : '';
            let expireYear = credential.expires ? datelib.epoch_to_utcmoment(credential.expires).format('YYYY') : '';

            tab.location = 'https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME/public/certificate/&name='
                + encodeURIComponent(credential.credential_name)
                +   (
                        certificate_url_data.linkedin_id
                            ? '&organizationId=' + encodeURIComponent(certificate_url_data.linkedin_id)
                            : '&organizationName=' + encodeURIComponent(credential.issuer_name)
                    )
                + '&issueYear=' + issueYear + '&issueMonth=' + issueMonth + '&expirationYear=' + expireYear + '&expirationMonth=' + expireMonth
                + '&certUrl=' + encodeURIComponent(certificate_url_data.report_url)
                + '&certId=' + encodeURIComponent(credential.id);

            tab.focus();
        } else {
            tab.close();
        }
    }


    // RENDER APP ======================================================================================================

    return (
        !credential ?
        null
        :
        <div className='card rounded-lg shadow-sm' id='crd_linkedin' tabIndex='0'>
            <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_credlinkedin' className='text--xl-medium'>{t('LinkedIn')}</div>
                        <div className='text--sm-regular'>{t('Share this credential to LinkedIn to make it publicly viewable on your profile.')}</div>
                    </div>
                </div>

                <div className='card__content'>
                    <Button className='primary' id='btn_shareonlinkedin' onClick={onClick_linkedin}>{t('Share to LinkedIn')}</Button>
                </div>
        </div>
    )
}
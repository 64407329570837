import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { CHECKBOX, FORM_DATEPICKER, FORM_INPUT, FORM_SELECT, SELECT_RADIO } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import form_helper from 'libs/form-lib';
import lookup from 'libs/lookup-lib';
import CMP_FILEUPLOAD from 'components/cmp_fileupload/cmp_fileupload';
import Icon from 'components/cmp_icon';

import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';

function MDL_ADD_HOLDER({ is_open, onClose, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_individual_options, set_individual_options ] = useState([]);
    const [ var_selected_individuals, set_selected_individuals ] = useState([]);
    const [ var_confirmation_status_options, set_confirmation_status_options ] = useState([]);
    const [ var_credential, set_credential ] = useState({});
    const [ var_fileupload_status, set_fileupload_status] = useState(null);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_selected_assignment, set_selected_assignment ] = useState('ALL');
    const [ var_assignment_options, set_assignment_options ] = useState([]);
    const [ var_all_individuals, set_all_individuals ] = useState(false);
    const [ var_disable_add, set_disable_add ] = useState(false);
    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_selected_credential, set_selected_credential ] = useState(null);
    const [ var_credential_options, set_credential_options ] = useState([]);
    const [ var_credential_footprint, set_credential_footprint ] = useState({});
    const [ var_modal, set_modal ] = useState(null);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled]), input[type="search"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (is_open) {
            // reset and populate options
            set_modal(document.querySelector('#mdl_add_holder'));
            set_individuals([]);
            set_individual_options([]);
            set_selected_individuals([]);
            set_credential({});
            set_errors([]);
            set_processing(false);
            set_selected_assignment('ALL');
            set_all_individuals(false);
            populate_confirmation_status_options();
            set_fileupload_status('RESET');
            set_disable_add(false);
            set_credentials([]);
            set_selected_credential(null);
            set_credential_options([]);
            set_credential_footprint({});
            if (auth.has_orgaccess('ORG-LOC-ADMIN', 'read')) {
                populate_assignments();
            }
            populate_credentials();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_fileupload_status === 'UPLOAD COMPLETE') {
            add_holders();
        } else if (var_fileupload_status === 'UPLOAD FAIL') {
            set_errors([{ description: t('There was a problem saving.  Please try again later') }]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload_status]);

    useEffect(() => {
        if (is_open && var_credential_footprint.id) {
            set_all_individuals(false);
            populate_individuals();
            set_selected_individuals([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_selected_assignment, var_credential_footprint]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals() {
        try {
            let results = await API_get_individuals_to_add_to_credential();
            set_individuals(results);
            let transformed = results.map(item => ({
                value: item.id,
                primary_text:
                        <CMP_VERIFIED_IDENTITY_DISPLAY
                            individual_name={item.individual_name}
                            verified_identity_credential_id={item.identity_verification_credential_id}
                            display_nonclickable_icon={true}
                        />,
                secondary_text: item.email
            }));
            set_individual_options(transformed);
            if (transformed.length === 0) {
                set_disable_add(true);
                set_errors([{ property: 'no_individuals', description: t('There are no individuals to add to this credential.') }]);
            } else {
                set_disable_add(false);
            }
        } catch (e) {
            set_errors([{ description: t('There was a problem loading.  Please try again later') }]);
            console.log(e);
        }
    }

    async function populate_assignments() {
        try {
            let results = await API_get_assignment_list_for_org();
            results = [{ value: 'ALL', text: t('All assignments') }].concat(results);
            set_assignment_options(results);
        } catch (e) {
            set_errors([{ description: t('There was a problem loading.  Please try again later') }]);
            console.log(e);
        }
    }

    async function populate_credentials() {
        try {
            let results = await API_get_org_credential_list();
            set_credentials(results);
            let transformed = results.map(item => ({
                value: item.id,
                text: item.name
            }));
            set_credential_options(transformed);
            if (transformed.length === 0) {
                set_disable_add(true);
                set_errors([{ property: 'no_credentials', description: t('There are no credentials to add holders to.') }]);
            } else {
                set_disable_add(false);
            }
        } catch (e) {
            set_errors([{ description: t('There was a problem loading.  Please try again later') }]);
            console.log(e);
        }
    }

    async function add_holders() {
        set_processing(true);

        let detailed_selected_individuals = var_individuals.filter(item => var_selected_individuals.includes(item.id));
        try {
            for (let individual of detailed_selected_individuals) {
                let credential = {
                    credential_id: var_credential_footprint.id,
                    confirmation_status: var_credential.confirmation_status,
                    issued: var_credential.issued ? datelib.localdate_to_utc_midnight_epoch(var_credential.issued) : null,
                    expires: var_credential.expires ? datelib.localdate_to_utc_midnight_epoch(var_credential.expires) : null,
                    credential_number: form_helper.cleanse_string(var_credential.credential_number),
                    individual_id: individual.id,
                    firstname: individual.firstname,
                    lastname: individual.lastname,
                    email: individual.email,
                    filename: var_credential.filename,
                    submittedfilename: var_credential.submittedfilename
                };
                await API_post_credential(credential);
            }
            onChange && onChange();
        } catch (e) {
            set_errors([{description: t('There was a problem saving.  Please try again later')}]);
            console.log(e);
        }
        set_processing(false);
    }

    async function populate_confirmation_status_options() {
        set_confirmation_status_options(await lookup.confirmation_status.get_select_options());
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individuals_to_add_to_credential() {
        return API.get('org-indv', `/get-individuals-to-add-to-credential/${var_credential_footprint.id}` + (var_selected_assignment !== 'ALL' ? `/${var_selected_assignment}` : ''));
    }

    function API_get_assignment_list_for_org() {
        return API.get('location', '/get-assignment-list-for-org');
    }

    function API_get_org_credential_list() {
        return API.get('credentials', '/get-org-credential-list');
    }

    function API_post_credential(credential) {
        return API.post('credentials', '/post-credential', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: credential
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_individuals(value) {
        set_selected_individuals(value);
        set_errors([]);
    }

    function onChange_assignment(e, { name, value }) {
        set_selected_assignment(value);
        set_errors([]);
    }

    function onChange_credential(e, { name, value }) {
        set_selected_credential(value);
        set_credential_footprint(var_credentials.find(item => item.id === value));
        set_errors([]);
    }

    function onChange_input(e, { name, value }) {
        set_errors([]);
        let credential = {...var_credential};
        switch (name) {
            case 'issued':
                if (value === credential.issued) return;
                credential.issued = value
                if (value !== null && var_credential_footprint.expires === 'YES' && var_credential_footprint.expires_unit) {
                    credential.expires =
                        datelib.utcmoment_to_local_midnight_date(
                            datelib.localdate_to_utc_midnight_moment(value).add(var_credential_footprint.expires_amount, var_credential_footprint.expires_unit + 's')
                        );
                }
                break;
            default:
                credential[name] = value;
                break;
        }
        set_credential(credential);
    }

    async function onClick_add() {
        let errors = [];
        if (var_selected_individuals.length === 0 && !var_all_individuals) {
            errors.push({property: 'holders', description: t('Please select at least one individual to add')});
        }
        if (var_credential_footprint.issuedrequired === 'YES' && !var_credential.issued) {
            errors.push({property: 'issued', description: t('Issued is required')});
        }
        if (var_credential_footprint.expires === 'YES' && !var_credential.expires) {
            errors.push({property: 'expires', description: t('Expires is required')});
        }
        if (var_credential_footprint.credential_number_required === 'YES' && !form_helper.validate_required_string(var_credential.credential_number)) {
            errors.push({ property: 'credential_number', description: var_credential_footprint.credential_number_label + ' ' + t('is required') });
        }
        if (!var_credential.confirmation_status && var_credential_footprint.has_prequalifier !== 'YES') {
            errors.push({property: 'confirmation_status', description: t('Confirmation is required')});
        }
        let date_validation;
        if (var_credential.issued) {
            date_validation = form_helper.validate_date(var_credential.issued, null, var_credential.expires);
            if (date_validation.is_less_than_minimum) {
                errors.push({ property: 'issued', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
            } else if (date_validation.is_greater_than_maximum) {
                errors.push({ property: 'issued', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
            }
        }
        if (var_credential.expires) {
            date_validation = form_helper.validate_date(var_credential.expires, var_credential.issued);
            if (date_validation.is_less_than_minimum) {
                errors.push({ property: 'expires', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
            } else if (date_validation.is_greater_than_maximum) {
                errors.push({ property: 'expires', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
            }
        }
        set_errors(errors);
        if (errors.length > 0) { return; }

        if ((var_credential_footprint.credential_number_required === 'YES' || var_selected_individuals.length === 1) && var_credential_footprint.has_prequalifier !== 'YES') {
            set_processing(true);
            try {
                if (var_fileupload_status === 'UPLOAD READY') {
                    set_fileupload_status('START UPLOAD');
                } else {
                    await add_holders();
                }
            } catch (e) {
                set_errors([{ description: t('There was a problem saving.  Please try again later') }]);
            }
        } else {
            await add_holders();
        }

    }

    function onChange_document(details) {
        set_credential({...var_credential, filename: details.filename, submittedfilename: details.display_name});
    }

    function onChange_all_individuals() {
        set_errors([]);
        if (!var_all_individuals) {
            set_selected_individuals(var_individuals.map(item => item.id));
        } else {
            set_selected_individuals([]);
        }
        set_all_individuals(!var_all_individuals);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_add_holder'
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_creddetails_addholder'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_creddetails_addholder'>{t('Add holder')}</div>
                    <div className='text--sm-regular'>{t('Select individuals to add as holders of this credential.')}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_creddetails_addholder' aria-labelledby='hdr_creddetails_addholder' tabIndex='0'>
                {var_errors.length === 1
                    ? <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_errors[0].description} />
                    : var_errors.length > 1
                    ? <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={t('There are some errors with your inputs')} />
                    : null
                }
                <div>
                    <FORM_SELECT
                        property='credential'
                        label={t('Credential')}
                        value={var_selected_credential || ''}
                        onChange={onChange_credential}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('Select')}
                        options={var_credential_options}
                        disabled={false}
                        errors={var_errors}
                    />
                </div>
                {auth.has_orgaccess('ORG-LOC-ADMIN', 'read') &&
                <div>
                    <FORM_SELECT
                        property='assignment'
                        label={t('Assignment')}
                        value={var_selected_assignment || ''}
                        onChange={onChange_assignment}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('Select')}
                        options={var_assignment_options}
                        disabled={false}
                        errors={var_errors}
                        description={t('Choose an assignment to select individuals from.')}
                    />
                </div>
                }
                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>{t('Individual')}</div>
                    <Form.Field>
                        <SELECT_RADIO
                            name='holders'
                            value={var_selected_individuals}
                            options={var_individual_options}
                            single_or_multiple={var_credential_footprint.credential_number_required === 'YES' ? 'SINGLE': 'MULTIPLE'}
                            onChange={onChange_individuals}
                            errors={var_errors}
                            disabled={var_all_individuals}
                            aria_label={t('Select individuals to add as holders of this credential')}
                        />
                    </Form.Field>
                </div>
                {var_credential_footprint.credential_number_required !== 'YES' &&
                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'></div>
                        <CHECKBOX
                            name='all_individuals'
                            label={t('Select all individuals')}
                            checked={var_all_individuals}
                            onChange={onChange_all_individuals}
                            disabled={var_individual_options.length === 0}
                        />
                    </div>
                }
                {var_credential_footprint.has_prequalifier !== 'YES' &&
                    <FORM_DATEPICKER
                        property='issued'
                        label={t('Issued')}
                        value={var_credential.issued || ''}
                        onChange={(e, {value}) => onChange_input(e, {name: 'issued', value})}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('YYYY-MM-DD')}
                        minproperty={null}
                        maxproperty={var_credential.expires}
                        errors={var_errors}
                    />
                }
                {var_credential_footprint.expires === 'YES' && var_credential_footprint.has_prequalifier !== 'YES' &&
                    <FORM_DATEPICKER
                        property='expires'
                        label={t('Expires')}
                        value={var_credential.expires || ''}
                        onChange={(e, { value }) => onChange_input(e, {name: 'expires', value })}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('YYYY-MM-DD')}
                        minproperty={var_credential.issued}
                        maxproperty={null}
                        errors={var_errors}
                    />
                }
                {var_credential_footprint.credential_number_required === 'YES' &&
                    <FORM_INPUT
                        property='credential_number'
                        label={t(var_credential_footprint.credential_number_label)}
                        value={var_credential.credential_number || ''}
                        placeholder={var_credential_footprint.credential_number_label}
                        onChange={onChange_input}
                        maxLength={100}
                        errors={var_errors}
                    />
                }
                {(var_credential_footprint.credential_number_required === 'YES' || var_selected_individuals.length === 1) && var_credential_footprint.has_prequalifier !== 'YES' &&
                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'>{t('Document')}</div>
                        <CMP_FILEUPLOAD
                            var_status={var_fileupload_status}
                            set_status={set_fileupload_status}
                            onChange_details={onChange_document}
                            single_or_multiple='MULTIPLE'
                            uploaddirectory='certificate' max_size={10} allowed_fileextensions={['pdf', 'png', 'jpg', 'jpeg']}
                            instructions={t('Upload any supporting documents. Only PDF, JPG, and PNG file types are accepted.')} />
                    </div>
                }
                {var_credential_footprint.has_prequalifier !== 'YES' &&
                    <FORM_SELECT
                        property='confirmation_status'
                        name=''
                        label={t('Confirmation')}
                        value={var_credential.confirmation_status || ''}
                        onChange={onChange_input}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('Select')}
                        options={var_confirmation_status_options}
                        errors={var_errors}
                    />
                }
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add} disabled={var_disable_add}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

export default MDL_ADD_HOLDER;
import React, { useState, useEffect } from 'react';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import config from 'config';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import form_helper from 'libs/form-lib';

import { FORM_DATEPICKER, FORM_INPUT, FORM_SELECT, FORM_TEXTAREA } from 'components/cmp_form/cmp_form';

export default function MDL_CREATE_EMAIL_LINK({
                                    display,
                                    onClose,
                                    onLinkCreated,
                                    transcript
                                }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_link, set_link ] = useState({ report_options: {} });
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(false);
    const [ var_email_language, set_email_language ] = useState(null);
    const [ var_disable_create, set_disable_create ] = useState(false);

    const [ var_modal, set_modal ] = useState(null);
    const [ var_modal_close_on_escape, set_modal_close_on_escape] = useState(true);

    const language_options = Object.keys(config.language).map(key => ({ key, text: config.language[key].text, value: key }));

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, input[type="search"]:not([disabled]), input[type="checkbox"]:not([disabled]), button:not([disabled]), textarea:not([disabled]), select:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal])

    useEffect(()=>{
        if (display) {

            set_modal(document.querySelector('#mdl_emailshare'));

            set_link({ report_options: {} });
            set_errors([]);
            set_save_error(false);
            set_processing(false);
            set_email_language(null);
            set_disable_create(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[display]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function create_email_report(body) {
        set_processing(true);
        try {
            await API_post_transcript_report(body);
            onLinkCreated();
            onClose();
        } catch (exception) {
            console.log(exception);
            set_save_error(t('An error has occurred'));
        } finally {
            set_processing(false);
        }
    }

    async function onClick_create() {
        let link = { ...var_link };
        let errors = [];
        // validation
        if (!link.emails || link.emails.split(',').filter(email => !form_helper.validate_email_address(email)).length !== 0) {
            errors.push({property: 'emails', description: t('Please provide a valid email address.')});
        }
        if (!var_email_language) {
            errors.push({property: 'email_language', description: t('Please select a language for the email')});
        }
        if (link.expiry_timestamp) {
            let date_validation = form_helper.validate_date(link.expiry_timestamp, datelib.date_to_midnight_date(new Date()));
            if (date_validation.is_less_than_minimum) {
                errors.push({ property: 'expiry_timestamp', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
            } else if (date_validation.is_greater_than_maximum) {
                errors.push({ property: 'expiry_timestamp', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
            }
        }
        for (let custom_field of transcript.custom_fields) {
            if (custom_field.required && custom_field.user_entered && !form_helper.validate_required_string(link.report_options[custom_field.name])) {
                errors.push({property: custom_field.name, description: `${custom_field.label} ${t('is required')}.`});
            }
        }
        set_errors(errors);
        if (errors.length > 0) return;

        link.report_type = 'INDIVIDUAL_TRANSCRIPT_EMAIL';

        // cleanse properties
        form_helper.cleanse_string_property(link, 'emails');
        link.expiry_timestamp = var_link.expiry_timestamp ? datelib.date_to_epoch(var_link.expiry_timestamp) : null;
        link.emails = link.emails.split(',');
        link.email_language = var_email_language;
        form_helper.cleanse_string_property(link, 'description');
        create_email_report(link);
    }

    //  API calls ------------------------------------------------------------------------------------------------------


    function API_post_transcript_report(body) {
        return API.post('external-report', `/post-transcript-report/${transcript.id}`,
            {
                body: body
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        let link = { ...var_link };
        if (['emails', 'email_language', 'description', 'expiry_timestamp'].includes(event.target.name)) {
            link[event.target.name] = event.target.value;
        } else {
            link.report_options[event.target.name] = event.target.value;
        }
        set_link(link);
        if (var_errors.length > 0) {
            set_errors(var_errors.filter(error => error.property !== event.target.name));
        }
    }

    function onChange_date(e, { value }) {
        let link = { ...var_link };
        link.expiry_timestamp = value;
        set_link(link);
        set_errors(var_errors.filter(error => error.property !== 'expiry_timestamp'));
    }

    function onChange_language(e, { value }) {
        set_email_language(value);
        if (var_errors.length > 0) {
            set_errors(var_errors.filter(error => error.property !== 'email_language'));
        }
    }

    // RENDER APP ======================================================================================================


    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            className='mdl_emailshare'
            id='mdl_emailshare'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_transcript_sharing_email'
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_transcript_sharing_email'>{t('Email')}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_transcript_sharing_email' aria-labelledby='hdr_transcript_sharing_email' tabIndex='0'>
                {var_save_error &&
                    <Message error
                        header={var_save_error}
                    />
                }

                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={t('There are some errors with your inputs.')}
                    />
                }

                {transcript.custom_fields.filter(item => item.user_entered).map(item =>
                    <FORM_INPUT
                        property={item.name}
                        label={item.translate ? t(item.label) : item.label}
                        description={item.translate ? t(item.description) : item.description}
                        value={var_link.report_options[item.name]}
                        onChange={onChange_input}
                        placeholder={item.translate ? t(item.label) : item.label}
                        maxLength={500}
                        disabled={false}
                        errors={var_errors}
                        key={item.name}
                    />
                )}

                <FORM_INPUT
                    property='emails'
                    label={t('Share with')}
                    value={var_link.emails}
                    onChange={onChange_input}
                    placeholder={t('Email address, comma separated')}
                    maxLength={500}
                    disabled={false}
                    errors={var_errors}
                    type='email'
                    multiple
                />

                <FORM_SELECT
                    property='email_language'
                    label={t('Email language')}
                    value={var_email_language || ''}
                    onChange={onChange_language}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('Select')}
                    options={language_options}
                    errors={var_errors}
                />

                <FORM_TEXTAREA
                    property='description'
                    label={t('Description (optional)')}
                    value={var_link.description}
                    onChange={onChange_input}
                    placeholder={t('Description')}
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_DATEPICKER
                    property='expiry_timestamp'
                    label={t('Expiry (optional)')}
                    value={var_link.expiry_timestamp}
                    onChange={onChange_date}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('YYYY-MM-DD')}
                    minproperty={datelib.date_to_midnight_date(new Date())}
                    maxproperty={null}
                    disabled={false}
                    errors={var_errors}
                />

            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_create} disabled={var_disable_create}>{t('Send email')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import Icon from '../cmp_icon';
import { useTranslation } from 'react-i18next';
import 'i18n';


import './cmp_verified_identity_display.css';

import CMP_MDL_CERTIFICATE from 'components/cmp_mdl_certificate/cmp_mdl_certificate';


export default function CMP_VERIFIED_IDENTITY_DISPLAY({ individual_name, onClick_individual, verified_identity_credential_id, display_nonclickable_icon = false }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_certificate_open, set_certificate_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_icon() {
        set_focuselement(document.activeElement);
        set_certificate_open(true)
    }

    function onClose_modal() {
        set_certificate_open(false);
        var_focuselement.focus();
    }

    // RENDER APP ======================================================================================================

    return (
        <>
        {verified_identity_credential_id
            ?
            <div id='cmp_verified_identity_display'>
                {onClick_individual ?
                    <Button className='text--anchor tertiary btn_individual_name' onClick={onClick_individual} >
                        {individual_name}
                    </Button>
                :
                    <div style={{ marginRight: '0.2rem' }}>
                        {individual_name}
                    </div>
                }
                {display_nonclickable_icon
                    ?
                    <Icon
                        name='checkmark_verified_identity'
                        className='icon__verified_identity'
                        alt={t('Verified identity')}
                    />
                    :
                    <>
                        <Button className='tertiary checkmark_verified_identity' onClick={onClick_icon} >
                            <Icon
                                name='checkmark_verified_identity'
                                className='icon__verified_identity'
                                alt={t('Verified identity')}
                            />
                        </Button>
                        <CMP_MDL_CERTIFICATE
                            credential_id={verified_identity_credential_id}
                            verifiable_credential={null}
                            is_open={var_certificate_open}
                            onClose={onClose_modal}
                        />
                    </>
                }
            </div>
            : onClick_individual
                ?
                <Button className='text--anchor tertiary' onClick={onClick_individual} >
                    {individual_name}
                </Button>
                :
                <div>
                    {individual_name}
                </div>
        }
        </>
    )
}
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Message } from 'semantic-ui-react';
import { decrypt, unwrap } from 'libs/vault-lib';
import 'i18n';
import { Buffer } from 'buffer';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import Processing from 'components/cmp_processing';
import Icon from 'components/cmp_icon';

import { TOGGLE } from 'components/cmp_form/cmp_form';
import VC_VIEWER from './vc_viewer/vc_viewer';
import VP_VIEWER from './vp_viewer/vp_viewer';
import CMP_CERTIFICATE from 'components/cmp_certificate/cmp_certificate';
import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';

import './cmp_mdl_certificate.css';


/*******************************************************************
 * Note this modal is using in the credential details as well as create credential
 *******************************************************************/
function CMP_MDL_CERTIFICATE({ is_open, onClose, credential, credential_id, verifiable_credential }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const params = useParams();

    const [ var_tab, set_tab ] = useState('PREVIEW');
    const [ var_tab_options, set_tab_options ] = useState([]);
    const [ var_plaintext_vc, set_plaintext_vc ] = useState('');
    const [ var_vc_type, set_vc_type ] = useState('');
    const [ var_docviewer_open, set_docviewer_open ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_certificate, set_certificate ] = useState({});
    const [ var_processing, set_processing ] = useState(false);
    const [ var_display_error, set_display_error ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, .text--anchor, #supporting_document, .vpviewer__item, button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        set_tab('PREVIEW');
        if (is_open) {
            set_modal(document.querySelector('#mdl_credentialcert'));
            if (verifiable_credential?.vc){
                decrypt_vc();
            }
            let tab_options = [{ value: 'PREVIEW', text: t('Preview') }];
            if (verifiable_credential?.vc) {
                tab_options.push({ value: 'PROOF', text: t('Proof details') });
            }
            if (credential?.verifiable_presentations && credential.verifiable_presentations.length > 0) {
                tab_options.push({ value: 'VERIFIABLE PRESENTATION', text: t('Presentations') });
            }
            set_tab_options(tab_options);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open, credential]);

    useEffect(() => {
        if (is_open) {
            set_modal(document.querySelector('#mdl_credentialcert'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open, credential, var_tab_options, var_tab]);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement?.id === 'mdl_certificate_content') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);

    useEffect(() => {
        if (var_tab_options.length > 1) {
            set_focuselement('toggle_1');
        }
    }, [var_tab_options]);

    useEffect(() => {
        if (is_open && credential_id) {
            populate_certificate();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open, credential_id]);

    useEffect(() => {
        if (!is_open) {
            set_certificate({});
            set_display_error(null);
        }
    }, [is_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function decrypt_vc() {
        let kek = Window.$kek;
        if (!kek) return;
        let { key, key_algorithm, wrapping_algorithm } = JSON.parse(sessionStorage.getItem('encryption_key'));
        let decryption_key = await unwrap(key, key_algorithm, kek, wrapping_algorithm);
        let plaintext = await decrypt(verifiable_credential.vc, verifiable_credential.iv, verifiable_credential.encryption_algorithm, decryption_key);
        set_plaintext_vc(plaintext);

        if (plaintext.includes('@context')) {
            set_vc_type('JSON-LD');
        } else {
            let decoded = Buffer.from(plaintext.split('.')[0], 'base64')?.toString();
            try {
                let header = JSON.parse(decoded);
                if (header.typ === 'JWT') {
                    set_vc_type('JWT');
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    async function populate_certificate() {
        set_processing(true);
        try {
            let certificate = await API_get_internal_credential();
            if (certificate) {
                set_certificate(certificate);
            } else {
                set_display_error(t('You do not have permission to view this credential, or it may not exist.'));
            }
        } catch (e) {
            console.log(e);
        } finally {
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_internal_credential() {
        let location_string = params.locationid ? `/${params.locationid}` : '';
        return API.get('external-report', `/get-internal-credential/${credential_id}${location_string}`, {
            queryStringParameters: {
                tz: datelib.timezone,
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_set_tab(state, toggle_id) {
        set_tab(state);
        set_focuselement(toggle_id);
    }

    function onClick_document(){
        set_focuselement(document.getElementById('mdl_certificate_content'));
        set_docviewer_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <Modal
            className='mdl_certificate_preview'
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_credentialcert'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_certificate'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_certificate'>{t('Certificate')}</div>
                </div>
            </div>

            <div id='mdl_certificate_content' className='modal__content' tabIndex='0'>
                {var_display_error &&
                    <div className='error__wrapper'>
                        <Message
                            error
                            header={var_display_error}
                            icon={<Icon name='error' className='icon' />}
                        />
                    </div>
                }
                {var_tab_options.length > 1 &&
                    <div className='tab__wrapper'>
                        <TOGGLE value={var_tab} onChange={onClick_set_tab} options={var_tab_options} />
                    </div>
                }
                {(var_tab === 'PREVIEW' && (!!credential || Object.keys(var_certificate).length > 0)) &&
                    <CMP_CERTIFICATE
                        certificate={credential || var_certificate}
                        verifiable_credential={verifiable_credential}
                        tab={set_tab}
                        ready={!!credential || Object.keys(var_certificate).length !== 0}
                        linkstatus={'ACTIVE'}
                        onClick_document={onClick_document}
                        focus_element={var_focuselement}
                        activetab={var_tab}
                    />
                }
                { var_tab === 'PROOF' && var_plaintext_vc &&
                    <VC_VIEWER
                        verifiable_credential={var_plaintext_vc}
                        type={var_vc_type}
                        activetab={var_tab}
                        var_focuselement={var_focuselement}
                    />
                }
                { var_tab === 'VERIFIABLE PRESENTATION' &&
                    <VP_VIEWER
                        verifiable_presentations={credential?.verifiable_presentations}
                        var_focuselement={var_focuselement}
                    />
                }
            </div>
                <MDL_DOCVIEWER
                    targetid={credential_id || credential?.id}
                    classification='credential'
                    is_open={var_docviewer_open}
                    onClose={() => set_docviewer_open(false)}
                />
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

CMP_MDL_CERTIFICATE.propTypes = {
    is_open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    credential: propTypes.object,
    credential_id: propTypes.string
};

export default CMP_MDL_CERTIFICATE;

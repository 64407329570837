import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import { Button, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import datelib from 'libs/date-lib';
import 'i18n';

import './cmp_credential_sharing.css';
import MDL_CREATE_EMAIL_LINK from './mdl_create_email_link/mdl_create_email_link';
import MDL_CREATE_TEMP_LINK from './mdl_create_temp_link/mdl_create_temp_link';
import MDL_UPDATE_EXPIRY from './mdl_update_expiry/mdl_update_expiry';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';



export default function CMP_CREDENTIAL_SHARING( { credential, individual_id, assignment_id } ){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_email_reports, set_email_reports ] = useState([]);
    const [ var_email_totalrows, set_email_totalrows ] = useState(0);
    const [ var_email_refreshtable, set_email_refreshtable ] = useState(false);
    const [ var_email_populateconfig, set_email_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'expiry_timestamp', sortorder: 'descending' });
    const [ var_link_reports, set_link_reports ] = useState([]);
    const [ var_link_totalrows, set_link_totalrows ] = useState(0);
    const [ var_link_refreshtable, set_link_refreshtable ] = useState(false);
    const [ var_link_populateconfig, set_link_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'expiry_timestamp', sortorder: 'descending' });
    const [ var_email_ready, set_email_ready ] = useState(false);
    const [ var_link_ready, set_link_ready ] = useState(false);
    const [ var_email_loading, set_email_loading ] = useState(true);
    const [ var_email_loadingerror, set_email_loadingerror ] = useState(false);
    const [ var_link_loading, set_link_loading ] = useState(true);
    const [ var_link_loadingerror, set_link_loadingerror ] = useState(false);
    const [ var_modal_email_open, set_modal_email_open ] = useState(false);
    const [ var_modal_link_open, set_modal_link_open ] = useState(false);
    const [ var_modal_update_open, set_modal_update_open ] = useState(false);
    const [ var_selected_report, set_selected_report ] = useState(null);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_archive_id, set_archive_id ] = useState(null);
    const [ var_archive_type, set_archive_type ] = useState(null);
    const [ var_link_notification, set_link_notification ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_email_reports();
        populate_link_reports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var_link_notification && setTimeout(() => set_link_notification(false), 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_link_notification]);

    useEffect(() => {
        if (!var_modal_email_open && var_focuselement?.id === 'btn_createemail') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_modal_email_open]);

    useEffect(() => {
        if (!var_modal_link_open && var_focuselement?.id === 'btn_createlink') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_modal_link_open]);

    useEffect(() => {
        if (!var_mdl_confirmation_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_confirmation_open]);

    useEffect(() => {
        if (!var_modal_update_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_modal_update_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_email_reports(populateconfig, focuselement_id) {

        set_email_ready(false);
        set_email_loadingerror(false);
        set_email_loading(true);
        populateconfig && set_email_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_email_populateconfig;
        try {
            let results = await API_get_external_reports(limit, offset, sortby, sortorder, filter, null, individual_id ? 'ORG_INDIVIDUAL_PROFILE_EMAIL' : 'INDIVIDUAL_PROFILE_EMAIL');
            set_email_totalrows(results.totalrows);
            set_email_reports(results.results === undefined ? [] : results.results);
            set_email_loading(false);
            set_email_ready(true);
        } catch (e) {
            set_email_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_email').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_email_filters(filtername, sortorder) {
        try {
            return (await API_get_external_reports(var_email_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername, individual_id ? 'ORG_INDIVIDUAL_PROFILE_EMAIL' : 'INDIVIDUAL_PROFILE_EMAIL')).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_email_reports(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_email_populateconfig;
            return (await API_get_external_reports(limit, offset, sortby, sortorder, filter, null, individual_id ? 'ORG_INDIVIDUAL_PROFILE_EMAIL' : 'INDIVIDUAL_PROFILE_EMAIL')).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function populate_link_reports(populateconfig, focuselement_id) {
        set_link_ready(false);
        set_link_loadingerror(false);
        set_link_loading(true);
        populateconfig && set_link_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_link_populateconfig;
        try {
            let results = await API_get_external_reports(limit, offset, sortby, sortorder, filter, null, individual_id ? 'ORG_INDIVIDUAL_PROFILE_TEMP_LINK' : 'INDIVIDUAL_PROFILE_TEMP_LINK');
            set_link_totalrows(results.totalrows);
            set_link_reports(results.results === undefined ? [] : results.results);
            set_link_loading(false);
            set_link_loadingerror(false);
            set_link_ready(true);
        } catch (e) {
            set_link_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_templink').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_link_filters(filtername, sortorder) {
        try {
            return (await API_get_external_reports(var_link_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername, individual_id ? 'ORG_INDIVIDUAL_PROFILE_TEMP_LINK' : 'INDIVIDUAL_PROFILE_TEMP_LINK')).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_link_reports(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_link_populateconfig;
            return (await API_get_external_reports(limit, offset, sortby, sortorder, filter, null, individual_id ? 'ORG_INDIVIDUAL_PROFILE_TEMP_LINK' : 'INDIVIDUAL_PROFILE_TEMP_LINK')).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function archive_external_report() {
        try {
            await API_archive_external_reports(var_archive_id);
            if (var_archive_type === 'email') {
                populate_email_reports();
            } else {
                populate_link_reports();
            }
        } catch(e) {
            console.log(e);
        } finally {
            set_focuselement(document.getElementById(var_archive_type === 'email' ? 'crd_email' : 'crd_templink'));
            set_mdl_confirmation_open(false);
            set_archive_id(null);
            set_archive_type(null);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_external_reports(limit, offset, sortby, sortorder, filter, filtername, type){
        if (individual_id) {
            return API.post('external-report', '/get-org-individual-reports/' + type + '/' + (assignment_id ? individual_id + '/' + assignment_id : individual_id),
                {
                    queryStringParameters: {
                        limit: limit,
                        offset: offset,
                        sortby: sortby,
                        sortorder: sortorder,
                        filtername: filtername
                    },
                    body: filter
                }
            );
        } else {
            return API.post('external-report', '/get-external-reports/' + type + (credential ? '/' + credential.id : ''),
                {
                    queryStringParameters: {
                        limit: limit,
                        offset: offset,
                        sortby: sortby,
                        sortorder: sortorder,
                        filtername: filtername
                    },
                    body: filter
                }
            );
        }

    }

    function API_archive_external_reports(id) {
        if (individual_id) {
            return API.put('external-report', '/put-archive/' + id + '/ORG_INDIVIDUAL/' + (assignment_id ? individual_id + '/' + assignment_id : individual_id));
        } else {
            return API.put('external-report', '/put-archive/' + id + '/INDIVIDUAL');
        }
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_archive(event, id, type) {
        set_focuselement(document.activeElement);
        event.stopPropagation()
        set_archive_id(id);
        set_archive_type(type);
        set_mdl_confirmation_open(true);
    }

    function onClick_link(link) {
        navigator.clipboard.writeText(link);
        set_link_notification(true);
    }

    async function onLinkUpdate(focuselement_id) {
        if (var_selected_report && var_selected_report.link_address) {
            await populate_link_reports(null, focuselement_id);
        } else {
            await populate_email_reports(null, focuselement_id);
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <>
        <div className='card rounded-lg shadow-sm' id='crd_email' tabIndex='0'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div id='hdr_credemail' className='text--xl-medium'>{t('Email')}</div>
                </div>
            </div>

            <div style={{ flexDirection: 'column', marginBottom: '0.75rem' }}>

                <div className='hr' />
                <div className='text--sm-regular'>
                    {credential
                        ? t('Share this credential with specific individuals by email. An email with a link to this credential will be sent directly to their email address and will remain active until you deactivate it.')
                        : individual_id
                            ? t('Share this profile with specific individuals by email. Choose the credentials you\'d like to share and an email with a link to this profile will be sent directly to their email address. The link will remain active until it expires or you deactivate it.')
                            : t('Share your profile with specific individuals by email. Choose the credentials you\'d like to share and an email with a link to your profile will be sent directly to their email address.')
                    }
                </div>
                {(!credential && !individual_id) &&
                    <div className='text--sm-regular'>
                        {t('The link will remain active until it expires or you deactivate it.')}
                    </div>
                }
                <div>
                    <Button id='btn_createemail' className='primary' onClick={() => {set_modal_email_open(true); set_focuselement(document.activeElement);}}>{t('Create email')}</Button>
                </div>

                <Table id='email' loading={var_email_loading} ready={var_email_ready} loadingerror={var_email_loadingerror} lockcolumns={0} refresh={var_email_refreshtable} totalrows={var_email_totalrows}
                    populateconfig={var_email_populateconfig} populatefilterfunction={populate_email_filters}
                    downloadname={t('Email shared links')} downloadfunction={download_email_reports}
                    onChange={populate_email_reports}>

                    <Table.Header>
                        <Table.Row>
                        <Table.HeaderCell field='email_addresses' datatype='text' filtertype='text'>{t('SHARED WITH')}</Table.HeaderCell>
                            {!credential && <Table.HeaderCell field='credentials' datatype='text' filtertype='text'>{t('CREDENTIALS')}</Table.HeaderCell>}
                            <Table.HeaderCell field='description' datatype='text' filtertype='text'>{t('DESCRIPTION')}</Table.HeaderCell>
                            <Table.HeaderCell field='expiry_timestamp' datatype='date' filtertype='date'>{t('EXPIRY')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_email_reports.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>{item.email_addresses}</Table.Cell>
                                {!credential && <Table.Cell>{item.credentials}</Table.Cell>}
                                <Table.Cell>{item.description}</Table.Cell>
                                <Table.Cell  className='cell__datetime cell__icon--right text--anchor'>
                                    <Button id={`btn_edit_expiry_${item.id}`} className='tertiary' onClick={() => { set_selected_report(item); set_modal_update_open(true); set_focuselement(document.activeElement); }}>
                                        {datelib.epoch_to_date_string(item.expiry_timestamp, true) ?? t('No expiry')}
                                    </Button>
                                    <Button className='tertiary' onClick={(event) => onClick_archive(event, item.id, 'email')}>
                                        <Icon  name='delete' className='icon__delete__table' alt={t('Delete link')} />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
                <MDL_CREATE_EMAIL_LINK
                    display={var_modal_email_open}
                    onClose={() => set_modal_email_open(false)}
                    onLinkCreated={populate_email_reports}
                    credential_id={credential ? credential.id : null}
                    individual_id={individual_id}
                    assignment_id={assignment_id}
                />
            </div>
        </div>

        <div className='card rounded-lg shadow-sm' id='crd_templink' tabIndex='0'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div id='hdr_credlink' className='text--xl-medium'>{t('Temporary link')}</div>
                </div>
            </div>

            <div style={{ flexDirection: 'column', marginBottom: '0.75rem' }}>

                <div className='hr' />
                <div className='text--sm-regular'>
                    {credential
                        ? t('Anyone you share this link with will be able to view this credential for the specified time after which it will no longer be accessible.')
                        : individual_id
                            ? t('Share this profile with a temporary link. Choose the credentials you\'d like to share and anyone with this link will be able to view this profile until it expires or you deactivate it.')
                            : t('Share your profile with a temporary link. Choose the credentials you\'d like to share and anyone with this link will be able to view your profile until it expires or you deactivate it.')
                    }
                </div>
                <div>
                    <Button id='btn_createlink' className='primary' onClick={() => { set_modal_link_open(true); set_link_notification(false); set_focuselement(document.activeElement);}}>{t('Create link')}</Button>
                </div>

                {var_link_notification &&
                    <Message success={true} icon={<Icon name='checkmark' className='icon' />} header={t('Link has been copied to clipboard') + '.'} />
                }

                <Table id='links' loading={var_link_loading} ready={var_link_ready} loadingerror={var_link_loadingerror} lockcolumns={0} refresh={var_link_refreshtable} totalrows={var_link_totalrows}
                    populateconfig={var_link_populateconfig} populatefilterfunction={populate_link_filters}
                    downloadname={t('Temporary links')} downloadfunction={download_link_reports}
                    onChange={populate_link_reports}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='link_address' datatype='text' filtertype='text'>{t('LINK')}</Table.HeaderCell>
                            {!credential && <Table.HeaderCell field='credentials' datatype='text' filtertype='text'>{t('CREDENTIALS')}</Table.HeaderCell>}
                            <Table.HeaderCell field='description' datatype='text' filtertype='text'>{t('DESCRIPTION')}</Table.HeaderCell>
                            <Table.HeaderCell field='expiry_timestamp' datatype='date' filtertype='date'>{t('EXPIRY')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_link_reports.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' onClick={() => onClick_link(item.link_address)} >
                                        {t('Copy link')}
                                        <Icon name='copy' className='icon__copy' alt={t('copy icon')} />
                                    </Button>
                                </Table.Cell>
                                {!credential && <Table.Cell>{item.credentials}</Table.Cell>}
                                <Table.Cell>{item.description}</Table.Cell>
                                <Table.Cell className='cell__datetime cell__icon--right text--anchor'>
                                    <Button id={`btn_edit_expiry_${item.id}`} className='tertiary' onClick={() => { set_selected_report(item); set_modal_update_open(true); set_focuselement(document.activeElement); }} >
                                        {datelib.epoch_to_date_string(item.expiry_timestamp, true) ?? t('No expiry')}
                                    </Button>
                                    <Button className='tertiary' onClick={(event) => onClick_archive(event, item.id, 'link')}>
                                        <Icon name='delete' className='icon__delete__table' alt={t('Delete link')} />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <MDL_CREATE_TEMP_LINK
                    display={var_modal_link_open}
                    onClose={() => set_modal_link_open(false)}
                    onLinkCreated={populate_link_reports}
                    credential_id={credential ? credential.id : null}
                    individual_id={individual_id}
                    assignment_id={assignment_id}
                />

                <CMP_CONFIRMATION
                    display={var_mdl_confirmation_open}
                    title={t('Delete confirmation')}
                    message={
                        <div style={{flexDirection: 'column'}}>
                            <div style={{marginBottom: '0.5rem'}}>{t('Deleting this link will disable viewing of your profile by the individuals you have shared it with.')}</div>
                            <div>{t('This action can not be undone.')}</div>
                        </div>}
                    positive_option={t('Delete')}
                    negative_option={t('Cancel')}
                    onConfirm={archive_external_report}
                    onCancel={() => set_mdl_confirmation_open(false)}
                />
            </div>
        </div>
        <MDL_UPDATE_EXPIRY
            display={var_modal_update_open}
            onClose={() => { set_modal_update_open(false); set_selected_report(null) }}
            onLinkUpdate={(focuselement_id) => onLinkUpdate(focuselement_id)}
            report={var_selected_report}
            report_category={individual_id ? 'ORG_INDIVIDUAL' : 'INDIVIDUAL'}
            individual_id={individual_id}
            assignment_id={assignment_id}
        />
        </>
    )

}

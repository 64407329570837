import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from "components/cmp_icon";

import CONTENT_SCAN_1 from './content_scan_1/content_scan_1';
import CONTENT_SCAN_2 from './content_scan_2/content_scan_2';
import CONTENT_SCAN_3 from './content_scan_3/content_scan_3';

import './mdl_scanqr.css';



export default function MDL_SCANQR({ display, onChange, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_processing, set_processing ] = useState(false);
    const [ var_content, set_content ] = useState('SCAN_1');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display) {
            set_content('SCAN_1');
        }
    }, [display]);

    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_add() {
        onClose();
    }


    // RENDER APP ======================================================================================================


    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_scanqr'>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium'>{t('Scan QR code')}</div>
                </div>
                <div className='modal__header__right'>
                    <div className='modal__header__closeicon color--primary-500'>
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                    </div>
                </div>
            </div>

            {var_content === 'SCAN_1' &&
                <CONTENT_SCAN_1 onChangeContent={set_content} onChange={onChange} onClose={onClose} onChangeProcessing={set_processing}/>
            }

            {var_content === 'SCAN_2' &&
                <>
                    <div className='text--xs-bold TEMP_DEV_NOTE'>** ADD CREDENTIAL OPTION **</div>
                    <CONTENT_SCAN_2 set_content={set_content} onClose={onClose}/>
                    <div className='text--xs-bold TEMP_DEV_NOTE'>** SHARE CREDENTIAL OPTION **</div>
                    <CONTENT_SCAN_3 set_content={set_content} onClose={onClose}/>
                </>
            }

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    {var_content === 'SCAN_2' &&
                        <Button className='primary' onClick={onClick_add}>{t('Add')}</Button>
                    }
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>
            <Processing display={var_processing} processingtext='Processing' />
        </Modal>
    );
}
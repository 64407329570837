import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import { Form } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { DATEPICKER } from 'components/cmp_form/cmp_form';
import { Button } from 'semantic-ui-react';
import Moment from 'moment';
import 'i18n';

import './crd_reca.css';


export default function CRD_RECA() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_reca, set_reca ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'change_date' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    const [ var_startdate, set_startdate ] = useState();
    const [ var_enddate, set_enddate ] = useState();

    const [ var_errors, set_errors ] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!var_startdate) {
            set_startdate(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
        }
        if (!var_enddate) {
            set_enddate(new Date());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let errors = [];
        if (var_startdate && var_enddate && Moment(var_enddate).format('YYYY-MM-DD') < Moment(var_startdate).format('YYYY-MM-DD')) {
            errors.push({property: 'enddate', description: t(`To date cannot be before From date`)});
        }
        if (!var_startdate) {
            errors.push({property: 'startdate', description: t(`From date is required`)});
        }
        if (!var_enddate) {
            errors.push({property: 'enddate', description: t(`To date is required`)});
        }
        set_errors(errors);

        if (var_startdate && var_enddate && Moment(var_startdate).format('YYYY-MM-DD') <= Moment(var_enddate).format('YYYY-MM-DD')) {
            set_refreshtable(!var_refreshtable);
            populate_records({ ...var_populateconfig, offset: 0, filter: {} }, var_startdate, var_enddate);
        } else {
            set_totalrows(0);
            set_reca([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_startdate, var_enddate]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, startdate = var_startdate, enddate = var_enddate) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_post_reca_report(limit, offset, sortby, sortorder, filter, startdate, enddate);
            set_totalrows(results.totalrows);
            set_reca(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_post_reca_report(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, var_startdate, var_enddate, filtername)).results;
        } catch (e) {
            console.log(e);
        }

    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_post_reca_report(limit, offset, sortby, sortorder, filter, var_startdate, var_enddate)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_reca_report(limit, offset, sortby, sortorder, filter, startdate, enddate, filtername) {
        return API.post('reports', `/post-reca-report`,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone,
                    startdate: Moment(startdate).format('YYYY-MM-DD'),
                    enddate: Moment(enddate).format('YYYY-MM-DD')
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_startdate(e, { value }) {
        set_startdate(value);
    }

    function onChange_enddate(e, { value }) {
        set_enddate(value);
    }

    function onClick_individual(id) {
        navigate(`/individuals/${id}`);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_reports_reca'>
                <Table id='RECAlicenses' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('RECA licenses')} downloadfunction={download_records}
                        onChange={populate_records}>
                    <Table.OptionsButtons>
                        <Form className='reca_optionbuttons' id='form_reports_reca' aria-labelledby='hdr_reports_reca'>
                            <div className='datepicker_wrapper'>
                                <div className='datepicker_label text--sm-medium'>{t('From')}</div>
                                <DATEPICKER
                                    property='startdate'
                                    value={var_startdate}
                                    onChange={onChange_startdate}
                                    placeholder={t('YYYY-MM-DD')}
                                    minproperty={null}
                                    maxproperty={null}
                                    errors={var_errors}
                                />
                            </div>
                            <div className='datepicker_wrapper'>
                                <div className='datepicker_label text--sm-medium'>{t('To')}</div>
                                <DATEPICKER
                                    property='enddate'
                                    value={var_enddate}
                                    onChange={onChange_enddate}
                                    placeholder={t('YYYY-MM-DD')}
                                    minproperty={null}
                                    maxproperty={null}
                                    errors={var_errors}
                                />
                            </div>
                        </Form>
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='reca_id' datatype='text' filtertype='text'>{t('RECA ID')}</Table.HeaderCell>
                            <Table.HeaderCell field='field_changed' datatype='text' filtertype='option'>{t('FIELD CHANGED')}</Table.HeaderCell>
                            <Table.HeaderCell field='new_value' datatype='text' filtertype='text'>{t('NEW VALUE')}</Table.HeaderCell>
                            <Table.HeaderCell field='previous_value' datatype='text' filtertype='text'>{t('PREVIOUS VALUE')}</Table.HeaderCell>
                            <Table.HeaderCell field='change_date' datatype='datetime' filtertype='date'>{t('CHANGE DATE')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_reca.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell >
                                    <Button className='tertiary' onClick={() => onClick_individual(item.individual_id)}>
                                        <Table.CellContentTwoLine primary_text={<div className='text--anchor'>{item.individual_name}</div>} secondary_text={item.email} />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.reca_id}</Table.Cell>
                                <Table.Cell>{item.field_changed}</Table.Cell>
                                <Table.Cell>{item.new_value}</Table.Cell>
                                <Table.Cell>{item.previous_value}</Table.Cell>
                                <Table.Cell>{datelib.epoch_to_datetime_string(item.change_date, true)}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>
        </>
    )

}

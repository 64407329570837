import React, { useEffect } from 'react';

import CRD_DETAILS from './crd_details/crd_details';
import CRD_SETTINGS from './crd_settings/crd_settings';



export default function TAB_SETTINGS({ assignment, activetab, onChange  }) {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            <CRD_DETAILS
                location_id={assignment.location_id}
                location_organization_id={assignment.organization_id}
                activetab={activetab} onChange={onChange}
            />
            <CRD_SETTINGS
                location_id={assignment.location_id}
                location_organization_id={assignment.organization_id}
                activetab={activetab}
                onChange={onChange}
            />
        </>
    )
};
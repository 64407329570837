import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import 'i18n';

import MDL_NETWORK_DETAILS from './mdl_network_details/mdl_network_details';



export default function CRD_ASSIGNMENTS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'location_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_assignmentstatus, set_assignmentstatus ] = useState('ACTIVE');
    const [ var_networked_organizations_mdl_open, set_networked_organizations_mdl_open ] = useState(false);
    const [ var_location_id, set_location_id ] = useState('')
    const [ var_focuselement, set_focuselement ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_assignments({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_assignmentstatus]);

    useEffect(() => {
        if (!var_networked_organizations_mdl_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_all_assignments').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_networked_organizations_mdl_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_assignments(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_all_org_assignments(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_assignments(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
            if (var_focuselement?.id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${var_focuselement.id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_all_assignments').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
            }
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_all_org_assignments(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_assignments(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_all_org_assignments(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------
    function API_get_all_org_assignments(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('location', '/get-all-org-assignments',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    status: var_assignmentstatus
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_assignmentstatus(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_assignmentstatus(status);
    }

    function onClick_assignment(id) {
        navigate('/assignments/' + id);
    }

    function onClick_networked_organizations(location_id, networked_organizations) {
        if (networked_organizations > 0) {
            set_focuselement(document.activeElement);
            set_location_id(location_id);
            set_networked_organizations_mdl_open(true);
        }
    }

    function onClick_createassignment() {
        navigate('/org/assignments/create');
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_all_assignments' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_assignments' className='text--xl-medium'>{t('All assignments')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button className='primary' onClick={onClick_createassignment}>{t('Create assignment')}</Button>
                    </div>
                </div>

                <Table id='assignments' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Assignments')} downloadfunction={download_assignments}
                        onChange={populate_assignments}>
                    <Table.OptionsButtons>
                        <TOGGLE value={var_assignmentstatus} onChange={onClick_assignmentstatus} options={[{value: 'ACTIVE', text: t('Active')}, {value: 'DRAFT', text: t('Draft')}, {value: 'ARCHIVE', text: t('Inactive')}]} />
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='location_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                            <Table.HeaderCell field='code' datatype='text' filtertype='text'>{t('CODE')}</Table.HeaderCell>
                            <Table.HeaderCell field='businessunit' datatype='text' filtertype='text'>{t('BUSINESS UNIT')}</Table.HeaderCell>
                            <Table.HeaderCell field='networked_organizations' datatype='number' filtertype='number'>{t('AFFILIATED ORGS')}</Table.HeaderCell>
                            <Table.HeaderCell field='city' datatype='text' filtertype='text'>{t('CITY')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_assignments.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <Button className='tertiary' onClick={() => onClick_assignment(item.id)}>
                                        <Table.CellContentTwoLine primary_text={<div className='text--anchor'>{item.location_name}</div>} secondary_text={item.default_org === 'YES' ? t('Default assignment') : ''} />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.organization}</Table.Cell>
                                <Table.Cell>{item.code}</Table.Cell>
                                <Table.Cell>{item.businessunit}</Table.Cell>
                                <Table.Cell className={item.networked_organizations > 0 ? 'text--anchor' : ''} >
                                    <Button className='tertiary' onClick={() => onClick_networked_organizations(item.id, item.networked_organizations)} aria-disabled={item.networked_organizations === 0 && "true"}>
                                        {item.networked_organizations}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.city}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button className='primary' onClick={onClick_createassignment}>{t('Create assignment')}</Button>
                </div>
            </div>

            <MDL_NETWORK_DETAILS display={var_networked_organizations_mdl_open}
                onClose={() => set_networked_organizations_mdl_open(false)}
                location_id={var_location_id}
            />
        </>
    )
}
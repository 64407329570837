import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_EDIT_PERMISSION from './mdl_edit_permission/mdl_edit_permission';
import MDL_ADD_PERMISSION from './mdl_add_permission/mdl_add_permission';
import CMP_WARNING from 'components/cmp_warning/cmp_warning';
import 'i18n';



export default function CRD_PERMISSIONS({ credential_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_permissions, set_permissions ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'role_individual' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_deletepermission_id, set_deletepermission_id ] = useState(null);
    const [ var_deletepermission_name, set_deletepermission_name ] = useState('');
    const [ var_deletepermission_open, set_deletepermission_open ] = useState(false);

    const [ var_editpermission_open, set_editpermission_open ] = useState(false);
    const [ var_addpermission_open, set_addpermission_open ] = useState(false);
    const [ var_selected_permission, set_selected_permission ] = useState({});
    const [ var_cmp_warning_open, set_cmp_warning_open ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_permissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_deletepermission_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_creddetails_permissions').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_deletepermission_open]);

    useEffect(() => {
        if (!var_editpermission_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_editpermission_open]);

    useEffect(() => {
        if (!var_addpermission_open && var_focuselement?.id === 'btn_addpermission') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addpermission_open]);

    useEffect(() => {
        if (!var_cmp_warning_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_creddetails_permissions').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_cmp_warning_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_permissions(populateconfig, focuselement_id){
        set_save_error(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_credential_custom_security(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_permissions(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_creddetails_permissions').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }

        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_credential_custom_security(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_permissions(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_credential_custom_security(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function delete_permission() {
        set_processing(true);
        try {
            let result = await API_delete_custom_security();
            if (result.success) {
                populate_permissions();
            } else {
                set_cmp_warning_open(true);
            }
            set_deletepermission_id(null);
            set_deletepermission_name(null);
        } catch(e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_custom_security(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('security', '/get-credential-custom-security/' + credential_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_delete_custom_security() {
        return API.del('security', '/delete-custom-security/' + var_deletepermission_id);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_addpermission(){
        set_focuselement(document.activeElement);
        set_save_error(null);
        set_addpermission_open(true);
    }

    function onClick_deletepermission(id, name){
        set_focuselement(document.activeElement);
        set_save_error(null);
        if (var_permissions.filter(item => item.access.includes('security')).length === 1 &&
            var_permissions.find(item => item.access.includes('security')).id === id) {
            set_cmp_warning_open(true);
            return;
        }
        set_deletepermission_id(id);
        set_deletepermission_name(name);
        set_deletepermission_open(true);
    }

    function onConfirm_deletepermission(){
        delete_permission();
        set_focuselement(document.getElementById('crd_creddetails_permissions'));
        set_deletepermission_open(false);
    }

    function onClick_editpermission(permission) {
        set_focuselement(document.activeElement);
        set_save_error(null);
        set_selected_permission(permission);
        set_editpermission_open(true);
    }

    function onChange_editpermission(focuselement_id) {
        populate_permissions(null, focuselement_id);
        set_editpermission_open(false);
    }

    function onChange_addpermission() {
        populate_permissions();
        set_addpermission_open(false);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_creddetails_permissions' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('Permissions')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                        <Button id='btn_addpermission' className='primary' onClick={() => onClick_addpermission()}>{t('Add role or individual')}</Button>
                    </div>
            </div>
            <div id='credential_details_security' className='card__content'>
                {var_save_error &&
                    <Message error header={var_save_error}/>
                }

                {/*<div className='tbl__permissions detailsgroup__value text--sm-regular'>*/}
                    <Table  id='permissions'
                            loading={var_loading} ready={var_ready}
                            loadingerror={var_loadingerror} lockcolumns={0}
                            refresh={var_refreshtable} totalrows={var_totalrows}
                            populateconfig={var_populateconfig}
                            populatefilterfunction={populate_filters}
                            downloadname={t('Permissions')}
                            downloadfunction={download_permissions}
                            onChange={populate_permissions}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell field='role_individual' datatype='text' filtertype='text'>{t('ROLE/INDIVIDUAL')}</Table.HeaderCell>
                                <Table.HeaderCell field='read_access_display' datatype='text' filtertype='option'>{t('VIEW')}</Table.HeaderCell>
                                <Table.HeaderCell field='view_attachment_access_display' datatype='text' filtertype='option'>{t('ATTACHMENTS')}</Table.HeaderCell>
                                <Table.HeaderCell field='write_access_display' datatype='text' filtertype='option'>{t('ASSIGN')}</Table.HeaderCell>
                                <Table.HeaderCell field='security_access_display' datatype='text' filtertype='option'>{t('SECURITY')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {var_permissions.map(item =>
                                <Table.Row key={item.id}>
                                    <Table.Cell className='text--anchor'>
                                        <Button id={`btn_edit_permissions_${item.id}`} className='tertiary' onClick={() => onClick_editpermission(item)}>
                                            {item.role_individual}
                                        </Button>
                                    </Table.Cell>
                                    <Table.Cell className='cell__icon cell--center'>
                                        {item.read_access === 'YES' ?  <Icon name='checkmark' className='color--green-500' alt={t('Yes')}/> : null}
                                    </Table.Cell>
                                    <Table.Cell className='cell__icon cell--center'>
                                        {item.view_attachment_access === 'YES' ? <Icon name='checkmark' className='color--green-500' alt={t('Yes')}/> : null}
                                    </Table.Cell>
                                    <Table.Cell className='cell__icon cell--center'>
                                        {item.write_access === 'YES' ? <Icon name='checkmark' className='color--green-500' alt={t('Yes')}/> : null}
                                    </Table.Cell>
                                    <Table.Cell className={item.security_access === 'YES' ? 'cell__icon--left--right' : 'cell__icon--right'}>
                                        {item.security_access === 'YES' ?
                                            <Icon name='checkmark' className='color--green-500' alt={t('Yes')}/>
                                            :
                                            null
                                        }
                                        <Button className='tertiary' onClick={() => onClick_deletepermission(item.id, item.role_individual)}>
                                            <Icon name='delete' className='color--primary-500'/>
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                    <div className='tbl__permissions__legend'>
                        <div className='tbl__permissions__legend__item'><div className='text--sm-bold'>{t('View')}:&nbsp;</div>{t('Allow viewing of the credential')}</div>
                        <div className='tbl__permissions__legend__item'><div className='text--sm-bold'>{t('Attachments')}:&nbsp;</div>{t('Allow viewing of supporting attachments')}</div>
                        <div className='tbl__permissions__legend__item'><div className='text--sm-bold'>{t('Assign')}:&nbsp;</div>{t('Allow assigning of the credential to an individual  (Admin, Global Admin and Superadmin only)')}</div>
                        <div className='tbl__permissions__legend__item'><div className='text--sm-bold'>{t('Security')}:&nbsp;</div>{t('Allow permission changes to the credential (Superadmin only)')}</div>
                    </div>
                </div>
            {/*</div>*/}

            <Processing display={var_processing} modal={true} processingtext={t('Processing')} />

            <CMP_CONFIRMATION
                display={var_deletepermission_open}
                title={t('Remove access')}
                message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>{t('Are you sure you want to remove access for') + ' ' + var_deletepermission_name + ' ' + t('to this credential') + '? ' + t('This action can not be undone.')}</div>
                </div>
            }
            positive_option={t('Remove')}
            negative_option={t('Cancel')}
            onConfirm={onConfirm_deletepermission}
            onCancel={() => set_deletepermission_open(false)}
        />

        <CMP_WARNING
            display={var_cmp_warning_open}
            title={t('Remove access')}
            message={t('This credential requires at least one role or individual with permission to modify its security settings.')}
            onClose={() => set_cmp_warning_open(null)}
        />

        <MDL_EDIT_PERMISSION
            is_open={var_editpermission_open}
            onClose={() => set_editpermission_open(false)}
            credential_id={credential_id}
            role_individual={var_selected_permission.role_individual}
            permission={var_selected_permission.permission}
            selected_permissions={var_selected_permission.access}
            onChange={onChange_editpermission}
            custom_security_id={var_selected_permission.id}
            permission_options={[
                {code: 'read', description: t('View: Allow viewing of the credential')},
                {code: 'view attachment', description: t('Attachments: Allow viewing of supporting attachments')},
                {code: 'write', description: t('Assign: Allow assigning of the credential to an individual  (Admin, Global Admin and Superadmin only)')},
                {code: 'security', description: t('Security: Allow permission changes to the credential (Superadmin only)')}
            ]}
        />

        <MDL_ADD_PERMISSION
            is_open={var_addpermission_open}
            title={t('Permissions')}
            subtext={t('Specify the level of control a role or individual has for this credential.')}
            targetentity_id={credential_id}
            permission_options={[
                {code: 'read', description: t('View: Allow viewing of the credential')},
                {code: 'view attachment', description: t('Attachments: Allow viewing of supporting attachments')},
                {code: 'write', description: t('Assign: Allow assigning of the credential to an individual  (Admin, Global Admin and Superadmin only)')},
                {code: 'security', description: t('Security: Allow permission changes to the credential (Superadmin only)')}
            ]}
            onClose={() => set_addpermission_open(false)}
            onChange={onChange_addpermission}
        />
    </div>
    );
}
import React from 'react';
import propTypes from 'prop-types';
import { Button, Modal, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import 'i18n';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';

import './cmp_vc_authenticator.css';


function CMP_VC_AUTHENTICATOR({ display, title, instructions_line1, instructions_line2, instructions_line3, error_message, disable_open_button=false, target, verification_code, processing=false, onClose }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_target() {
        window.open(target.url);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer={'inverted'}
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            className='cmp_vc_authenticator'
            >

            <div className='modal__header'>
                <div style={ { width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium'>{title}</div>
                    </div>
                    <div className='modal__header__right'>
                        <div className="modal__header__closeicon color--primary-500">
                            <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='modal__content'>
                {error_message &&
                    <Message error
                        icon={<Icon name='error' className='message__error__icon' />}
                        header={error_message}
                    />
                }
                <div className='modal__content__text text--sm-regular' style={{flexDirection: 'column'}}>
                    {instructions_line1 && <div>{instructions_line1}</div>}
                    {instructions_line2 && <div>{instructions_line2}</div>}
                    {instructions_line3 && <div>{instructions_line3}</div>}
                </div>
                {target?.url &&
                <QRCodeSVG className='qr_svg'
                    value={target.url}
                    size={200}
                    onClick={onClick_target}
                />
                }
                {verification_code?.length > 0 &&
                <>
                <div className='modal__content__text text--sm-medium verificationcode_label'>{t('Verification code')}</div>
                <div className='text--md-regular verificationcode_value'>{verification_code}</div>
                </>
                }
            </div>

            <div className='modal__footer'>
                <div>
                    <Button className='primary btn_authenticator' disabled={disable_open_button} onClick={onClick_target}>{t('Open Microsoft Authenticator')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>
            <Processing display={processing} processingtext={t('Processing')} />
        </Modal>
    );

}

CMP_VC_AUTHENTICATOR.propTypes = {
    display: propTypes.bool.isRequired,
    title: propTypes.string,
    instructions_line1: propTypes.string,
    instructions_line2: propTypes.string,
    instructions_line3: propTypes.string,
    error_message: propTypes.string,
    disable_open_button: propTypes.bool,
    target: propTypes.object,
    verification_code:  propTypes.string,
    processing: propTypes.bool,
    onClose: propTypes.func
};

export default CMP_VC_AUTHENTICATOR;

import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import config from 'config';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';
import { INPUT } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';

import './cmp_identity_verification.css';

export default function CMP_IDENTITY_VERIFICATION({ display, onClose, request_id, phone_number }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_state, set_state ] = useState(null);
    const [ var_phone_number, set_phone_number ] = useState('');
    const [ var_timeout, set_timeout ] = useState(null);
    const [ var_status_title, set_status_title ] = useState(null);
    const [ var_status_message, set_status_message ] = useState(null);
    const [ var_credential_name, set_credential_name ] = useState(null);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        let interval = setInterval(check_request_status, 1000);
        return () => clearInterval(interval);
    });

    useEffect(() => {
        if (display) {
            set_phone_number(phone_number ? phone_number.replace(/\D/g, '') : ''); // strip out all non-digits from the phone number
            set_errors([]);
            set_modal(document.querySelector('#mdl__cmp_identity_verification'));
            check_request_status(true);
        }
    }, [ display, phone_number ]);

    useEffect(() => {
        if (var_modal) {
            //  set focus to first element within the modal
            var_modal.querySelector('.modal__content').focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    let elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled])');
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === elements[0]) {
                            elements[elements.length - 1].focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === elements[elements.length - 1]) {
                            elements[0].focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [ var_modal ]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function check_request_status(is_first_load = false) {
        if (!display || !request_id) return; // can't check status
        if (var_state !== 'WAITING' && !is_first_load) return; // not in a state requiring check
        let result = await(API_get_individual_request_status());
        if (is_first_load) {
            switch (result?.status) {
                case 'REQUESTED':
                case 'EXPIRED':
                case 'CANCELLED':
                    set_state('FORM');
                    break;
                case 'REQUEST SENT':
                    set_state('WAITING');
                    break;
                    default:
                        console.error('Invalid status');
                        set_state(null);
                        break;
            }
        } else {
            switch (result?.status) {
                case 'REQUEST SENT':
                case 'PENDING':
                    // still need to wait
                    break;
                case 'COMPLETED':
                    if (result.result === 'SUCCESS') {
                        set_credential_name(result.credential_name);
                    }
                    set_state(result.result);
                    break;
                case 'DECLINED':
                    set_status_title(t('Request declined'));
                    set_status_message(t('You have declined to complete the verification request.'));
                    set_state('MESSAGE');
                    break;
                case 'EXPIRED':
                    set_status_title(t('Request expired'));
                    set_status_message(t('The verification request has expired, please start again.'));
                    set_state('MESSAGE');
                    break;
                case 'TERMINATE PENDING':
                case 'TERMINATED':
                    set_status_title(t('Request cancelled'));
                    set_status_message(t('The verification request has been cancelled.'));
                    set_state('MESSAGE');
                    break;
                default:
                    console.error('Invalid status');
                    set_state(null);
                    break;
            }
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individual_request_status() {
        return API.get('identity-verification', '/get-individual-request-status/' + request_id);
    }

    function API_put_individual_request_process(phone_number) {
        return API.put('identity-verification', '/put-individual-request-process/' + request_id, {
            body: { phone_number }
        });
    }

    function API_put_individual_request_cancel() {
        return API.put('identity-verification', '/put-individual-request-cancel/' + request_id);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(e, { value }) {
        set_phone_number(value);
        set_errors([]);
    }

    async function onClick_continue() {
        // validation
        let phone_number = var_phone_number.replace(/\D/g, '');
        if (phone_number.length < 10) {
            set_errors([ { property: 'phone_number', description: t('Please enter a valid full phone number.') }]);
            return;
        }

        set_state('PROCESSING');
        try {
            let expiry_details = await API_put_individual_request_process(phone_number);
            let expires_unit = expiry_details.expires_unit === 'minutes' ? t('minutes') : expiry_details.expires_unit === 'hour' ? t('hour') : expiry_details.expires_unit === 'hours' ? t('hours')
                : expiry_details.expires_unit === 'day' ? t('day') : expiry_details.expires_unit === 'days' ? t('days') : null; // assume between minutes and days
            set_timeout(`${expiry_details.expires_amount} ${expires_unit}`);
            set_state('WAITING');
        } catch {
            set_errors([ { property: 'form', description: t('Unable to process request, please verify your phone number is complete and valid.') }]);
            set_state('FORM');
        }
    }

    async function onClick_cancel() {
        set_state('CANCELLING');
        try {
            await API_put_individual_request_cancel();
        } catch {}
        onClose('CANCEL');
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl__cmp_identity_verification'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium'>{t('Verify your identity')}</div>
                </div>
            </div>

            {var_state === 'FORM'
                ? render_form(false)
                : var_state === 'PROCESSING'
                ? render_form(true)
                : var_state === 'WAITING'
                ? render_waiting(false)
                : var_state === 'CANCELLING'
                ? render_waiting(true)
                : var_state === 'MESSAGE'
                ? render_message()
                : var_state === 'SUCCESS'
                ? render_success()
                : var_state === 'FAILED'
                ? render_failure()
                : render_form(true)}

        </Modal>
    );

    function render_form(processing) {
        return (
            <>
                <Form className='modal__content' tabIndex='0' id='identity_verification_form'>
                    {var_errors.some(item => item.property === 'form') &&
                        <Message error
                            icon={<Icon name='error' className='icon' />}
                            header={var_errors.find(item => item.property === 'form').description}
                        />
                    }
                    <div className='text--sm-regular'>{t('To verify your identity, you require the following:')}</div>
                    <div className='flex-row'>
                        <div><Icon name='government_id' className='form-icon' /></div>
                        <div className='flex-col'>
                            <div className='text--sm-medium'>{t('An official government-issued ID')}</div>
                            <div>{t('You’ll need a valid government-issued ID, like a driver’s license or passport, for verification. Make sure the name on your ID matches the name in your Credivera profile.')}</div>
                        </div>
                    </div>
                    <div className='flex-row'>
                        <div><Icon name='mobile_device' className='form-icon' /></div>
                        <div className='flex-col'>
                            <div className='text--sm-medium'>{t('A mobile device')}</div>
                            <div>{t('You’ll need your mobile device to capture an image of your government-issued ID, followed by a selfie to confirm your identity.')}</div>
                        </div>
                    </div>
                    <div className='flex-row'>
                        <div><Icon name='phone_keypad' className='form-icon' /></div>
                        <div className='flex-col'>
                            <div className='text--sm-medium'>{t('Enter your phone number')}</div>
                            <div>{t('Provide your mobile phone number, then click ‘Continue’. You’ll receive a link to begin the verification process.')}</div>
                            <div>
                                <INPUT
                                    property='phone_number'
                                    value={var_phone_number}
                                    onChange={onChange_input}
                                    placeholder='x-xxx-xxx-xxxx'
                                    maxLength={100}
                                    disabled={false}
                                    errors={var_errors} />
                            </div>
                        </div>
                    </div>
                    {render_powered_by()}
                </Form>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='primary' onClick={onClick_continue}>{t('Continue')}</Button>
                        <Button className='secondary' onClick={() => onClose('INITIAL')}>{t('Cancel')}</Button>
                    </div>
                </div>

                <Processing display={processing} processingtext={t('Processing')} />
            </>
        );
    }

    function render_waiting(processing) {
        return (
            <>
                <div className='modal__content' tabIndex='0' id='identity_verification_form'>
                    <Icon name='hourglass_circle' className='primary-icon gray' />
                    <div className='text--xl-medium as-center'>{t('Waiting for verification')}</div>
                    <div>{t('Click the link sent to your phone to start the verification process.') + ' ' + t('Please note that the link will expire in') + ' ' + var_timeout + '.'}</div>
                    <div>{t('Please stay on this screen until your verification is complete.')}</div>
                    {render_powered_by()}
                </div>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='secondary' onClick={onClick_cancel}>{t('Cancel verification')}</Button>
                    </div>
                </div>

                <Processing display={processing} processingtext={t('Processing')} />
            </>
        );
    }

    function render_message() {
        return (
            <>
                <div className='modal__content' tabIndex='0' id='identity_verification_form'>
                    <div className='text--xl-medium as-center'>{var_status_title}</div>
                    <div>{var_status_message}</div>
                    {render_powered_by()}
                </div>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='secondary' onClick={() => onClose('OTHER')}>{t('Close')}</Button>
                    </div>
                </div>
            </>
        );
    }

    function render_success() {
        return (
            <>
                <div className='modal__content' tabIndex='0' id='identity_verification_form'>
                    <Icon name='checkmark' className='primary-icon color--green-500' />
                    <div className='text--xl-medium as-center'>{t('Your identity has been verified!')}</div>
                    <span>
                        <span>{t('Your profile has been updated accordingly. Additionally, we have issued you a')} </span>
                        <span className='color--red-600'>{var_credential_name}</span>
                        <span> {t('credential to confirm your successful identity verification.')}</span>
                    </span>
                    {render_powered_by()}
                </div>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='secondary' onClick={() => onClose('COMPLETE')}>{t('Close')}</Button>
                    </div>
                </div>
            </>
        );
    }

    function render_failure() {
        return (
            <>                                      
                <div className='modal__content' tabIndex='0' id='identity_verification_form'>
                    <Icon name='xicon_solid' className='primary-icon color--red-600' />
                    <div className='text--xl-medium as-center'>{t('Your identity could not be verified.')}</div>
                    <div>{t('Unfortunately, your identity could not be verified. Your administrator will contact you with next steps.')}</div>
                    {render_powered_by()}
                </div>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='secondary' onClick={() => onClose('COMPLETE')}>{t('Close')}</Button>
                    </div>
                </div>
            </>
        );
    }

    function render_powered_by() {
        return (
            <div className='flex-col'>
                <div className='text--xs-regular'>{t('Powered by')}</div>
                <img src={`${config.images.url}${config.images.assets.AuthenticID_logo}${config.images.stage}`} alt={t('AuthenticID')} />
            </div>
        );
    }

}

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import 'i18n';
import Processing from 'components/cmp_processing';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';


export default function CRD_THEMES() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_themes, set_themes ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'is_default', sortorder: 'asc' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_delete_theme_open, set_delete_theme_open ] = useState(false);
    const [ var_delete_theme_id, set_delete_theme_id ] = useState(null);
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_themes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_delete_theme_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_delete_theme_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_themes(populateconfig, focuselement_id) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_verification_themes(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_themes(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
            document.getElementById(var_focuselement)?.focus();
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_all_themes').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        } else if (var_focuselement?.id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${var_focuselement.id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_all_themes').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_verification_themes(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_themes(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_verification_themes(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function delete_theme() {
        set_focuselement(document.getElementById('crd_all_themes'));
        set_delete_theme_open(false);
        set_processing(true);
        try {
            await API_delete_theme();
            populate_themes();
        }  catch (e) {
            console.log(e);
        }
        set_processing(false);
        set_delete_theme_id(null);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_verification_themes(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('verifiable-credentials', '/get-verification-themes',
        {
            queryStringParameters: {
                limit: limit,
                offset: offset,
                sortby: sortby,
                sortorder: sortorder,
                filtername: filtername
            },
            body: filter
        });
    }

    function API_delete_theme() {
        return API.del('verifiable-credentials', '/delete-theme/' + var_delete_theme_id);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_create_theme() {
        navigate('/org/verifications/themes/create');
    }

    function onClick_theme(id) {
        navigate(`/org/verifications/themes/${id}`);
    }


    function onClick_copy(theme) {
    }

    function onClick_delete_theme(id) {
        set_focuselement(document.activeElement);
        set_save_error(null);
        set_delete_theme_id(id);
        set_delete_theme_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_all_themes' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_themes' className='text--xl-medium'>{t('Verification template themes')}</div>
                        <div className='text--sm-regular'>{t('Customize the appearance of your verification template. Themes can be reused across multiple templates.')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_create_theme' className='primary' onClick={onClick_create_theme}>{t('Create theme')}</Button>
                    </div>
                </div>

                {var_save_error &&
                    <Message error
                             header={var_save_error}
                    />
                }

                <Table id='themes' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('Verification template themes')} downloadfunction={download_themes}
                       onChange={populate_themes}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('THEME')}</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_themes.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' onClick={() => onClick_theme(item.id)}>
                                        {item.name}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className='cell__icon--left--right'>
                                    <Button className='tertiary' onClick={() => onClick_copy(item)}>
                                        <Icon name='duplicate' className='color--primary-500' alt={t('Copy theme')}/>
                                    </Button>
                                    <Button className={'tertiary' + (item.is_default === 'YES' ? ' disabled' : '')} disabled={item.is_default === 'YES'} onClick={() => onClick_delete_theme(item.id)}>
                                        <Icon name='delete' className='color--primary-500' alt={t('Delete theme')} />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button id='btn_create_theme' className='primary' onClick={onClick_create_theme}>{t('Create theme')}</Button>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>

            <CMP_CONFIRMATION display={var_delete_theme_open} title={t('Delete theme')} message={
                <div style={{flexDirection: 'column'}}>
                    <div>{t('Deleting this theme will permanently remove it from the system. Any templates currently utilizing this theme will revert back to the default theme.')}</div>
                    <div style={{ marginTop: '1rem' }}>{t('This action cannot be undone.')}</div>
                </div>
            } positive_option={t('Delete')} negative_option={t('Cancel')} onConfirm={delete_theme} onCancel={() => set_delete_theme_open(false)} />

        </>

    )
}
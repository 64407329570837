import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './crd_credentialsAttachments.css';

import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';
import config from 'config';

export default function CRD_CREDENTIALSATTACHMENTS({ credentials, token }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const [ var_storage_endpoint, set_storage_endpoint ] = useState(null);

    const [ var_mdl_docviewer_open, set_mdl_docviewer_open ] = useState(false);
    const [ var_docviewerid, set_docviewerid ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        load_storage_endpoint();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function load_storage_endpoint() {
        set_storage_endpoint(await API.endpoint('storage'));
    }

    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_attachment(id) {
        set_docviewerid(id);
        set_mdl_docviewer_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
		{credentials && credentials.length !== 0 && credentials.filter(function(obj) { return (obj.credential_document && obj.credential_document.length > 0 && obj.credential_document !== 'No file selected...'); }).length > 0 &&
            <section className='profile_section'>

                <div className='profile_section_header'>{t('Credentials Attachments')}</div>

                <ul className='credentials_attachment_list'>
                    {credentials.map((item, i) =>
                        <>
                            {(item.credential_document && item.credential_document !== '' && item.credential_document !== 'No file selected...') &&
                                <li key={i}>
                                    <figure className='credentials_attachment_thumb' onClick={() => onClick_attachment(item.reportcredential_id)}>
                                    {item.credential_document.split('.').pop().toLowerCase() === 'pdf'
                                        ? <div id='attachment_pdf'><img src={`${config.images.url}${config.images.assets.pdf_document}${config.images.stage}`} alt={t('pdf')} /></div>
                                        : <div><img src={`${var_storage_endpoint}/token-credential/${token}/${item.reportcredential_id}/${item.credential_document_name}`} alt={item.credential_document_name}/></div>
                                    }
                                    <figcaption>{item.credential_name}</figcaption>
                                    </figure>
                                </li>
                            }
                        </>
                    )}
                </ul>

            </section>
        }

        {/***** MODAL: DOC VIEWER *******************************************************************************/}

        <MDL_DOCVIEWER
            targetid={var_docviewerid}
            classification='credential'
            is_open={var_mdl_docviewer_open}
            onClose={() => set_mdl_docviewer_open(false)}
            isreport={true}
            token={token}
        />

        {/***** END MODAL: DOC VIEWER ***************************************************************************/}

        </>
    );

}
import React from 'react';
import { useTranslation } from 'react-i18next';

import 'i18n';


export default function CRD_REQUIREMENTS2(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_requirements' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_requirements' className='text--xl-medium'>{t('Requirements')}</div>
                    </div>
                </div>
            </div>
        </>
    )

}

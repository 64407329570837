import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
import config from './config';

if (i18n) {
    i18n
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
        // learn more: https://github.com/i18next/i18next-http-backend
        .use(Backend)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            lng: 'en',
            fallbackLng: 'en',
            keySeparator: false,
            saveMissing: true,
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            react: {
                useSuspense: false,
                wait: true,
            },
            backend: {
                loadPath: config.api_translation.url + '/locales/{{lng}}/{{ns}}',
                addPath: config.api_translation.url + '/post-translation',
                parsePayload: function (namespace, key, fallbackValue) {
                    return {
                        area_name: namespace,
                        translation_key_code: key,
                        transactionby: (sessionStorage.getItem('authentication') ? JSON.parse(sessionStorage.getItem('authentication')).firstname + ' ' + JSON.parse(sessionStorage.getItem('authentication')).lastname : 'Anonymous')
                    }
                },
            }
        }, (err) => {
            if (err) return console.error(err)
        });
}

export default i18n;

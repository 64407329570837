import React, { useState, useEffect, createRef } from 'react';
import propTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import API from 'libs/api-lib';
import Dropzone from 'react-dropzone';
import jsPDF from 'jspdf'
import Icon from 'components/cmp_icon';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './cmp_fileupload.css';


function CMP_FILEUPLOAD({ var_status, set_status, current_filename, onChange_details, single_or_multiple,
    uploaddirectory, max_size, allowed_fileextensions, instructions, onChange_upload, onDelete, onProcess, ...other_props }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_files, set_files ] = useState([]);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_file_to_validate, set_file_to_validate ] = useState(null);
    const [ var_validation_blob, set_validation_blob ] = useState(null);

    const ref_dropzone = createRef();

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_status === 'START UPLOAD') {
            if (var_files.length === 1) {
                uploadfile(var_files[0]);
            } else {
                generate_pdf_from_images();
            }
        } else if (var_status === 'RESET') {
            set_files([]);
            set_errors([]);
        } else if (var_status === 'START PROCESSING' && var_files.length > 0) {
            let reader = new FileReader();
            reader.onload = function (e) {
                onProcess(e.target.result);
            };
            reader.readAsBinaryString(var_files[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_status]);

    useEffect(() => {
        set_status(var_files.length === 0 ? 'NONE' : 'UPLOAD READY');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_files]);

    //  functions ------------------------------------------------------------------------------------------------------

    async function uploadfile(file) {
        set_status('UPLOAD IN PROGRESS');
        try {
            let filename = uuidv4() + '.' + file.name.split('.').pop();
            onChange_details && onChange_details({ filename, display_name: file.name, file_object: file });
            await API_upload(file, filename);
            set_status('UPLOAD COMPLETE');
        } catch (e) {
            console.log(e);
            set_status('UPLOAD FAIL');
        }
    }

    function get_filetypes() {
        if (!allowed_fileextensions) return [];

        let filetypes = {};
        allowed_fileextensions.forEach(item => {
            switch (item) {
                case 'pdf':
                    filetypes['application/pdf'] = ['.pdf'];
                    break;
                case 'png':
                    filetypes['image/png'] = ['.png'];
                    break;
                case 'jpg':
                    filetypes['image/jpg'] = ['.jpg'];
                    break;
                case 'jpeg':
                    filetypes['image/jpeg'] = ['.jpeg'];
                    break;
                case 'xlsx':
                    filetypes['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] = ['.xlsx'];
                    break;
                case 'xls':
                    filetypes['application/vnd.ms-excel'] = ['.xls'];
                    break;
                case 'csv':
                    filetypes['text/csv'] = ['.csv'];
                    break;
                default:
                    throw new Error('Unknown file extension');
            };
        });
        return filetypes;
    }

    function bytes_display(value, unit = 'B') {
        let units = ['B', 'KB', 'MB', 'GB'];
        if (value >= 1024) {
            return bytes_display(value / 1024, units[units.indexOf(unit) + 1]);
        }
        return (value >= 100 ? Math.round(value) : Number(value.toPrecision(3))).toString() + ' ' + unit;
    }

    function generate_pdf_from_images() {
        const pdf = new jsPDF();
        // get size of page
        let page_width = pdf.internal.pageSize.getWidth();
        let page_height = pdf.internal.pageSize.getHeight();
        // delete the first default page
        pdf.deletePage(1);
        let images_to_load = var_files.map(item => URL.createObjectURL(item));
        add_image_to_pdf(images_to_load, pdf, page_width, page_height);
    }

    function add_image_to_pdf(images_to_load, pdf, page_width, page_height) {
        if (images_to_load.length === 0) {
            let pdf_blob = pdf.output('blob');
            let file = new File([pdf_blob], 'filename.pdf');
            uploadfile(file);
            return;
        }
        let url = images_to_load.shift();
        const img = new Image();
        img.src = url;
        img.onload = function() {
            const widthRatio = page_width / img.naturalWidth;
            const heightRatio = page_height / img.naturalHeight;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            pdf.addPage();
            pdf.addImage(
                img.src,
                (img.type === 'image/png' ? 'PNG' : 'JPEG'),
                // Image is positioned on top left corner
                0,
                0,
                img.naturalWidth * ratio,
                img.naturalHeight * ratio
            );
            add_image_to_pdf(images_to_load, pdf, page_width, page_height);
        };
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    async function API_upload(file, filename) {
        await API.upload(file, filename);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onDrop(files) {
        onChange_upload && onChange_upload(files);
        set_errors([]);
        if (var_files.length === 1 && single_or_multiple === 'SINGLE') return;

        if (var_files.length === 0 && single_or_multiple === 'SINGLE' && files?.length === 1 && files[0].type.startsWith('image')) {
            set_validation_blob(URL.createObjectURL(files[0]));
            set_file_to_validate(files[0]);
            return;
        } else {
            if (var_files.length === 1 && !var_files[0].type.startsWith('image')) {
                set_errors([t('If selecting multiple files, only JPG and PNG file types are accepted.')]);
                return;
            }
            let newfiles = [...var_files];
            let filesize = 0;
            newfiles.forEach(item => filesize += item.size);
            files.forEach((item) => {
                if ((filesize + item.size) > (max_size * 1024 * 1024)) {
                    set_errors([`${t('File size larger than')} ${max_size} ${t('MB')}. ${t('Please reduce the file size and try again')}`]);
                } else if (newfiles.length > 0 && item.type === 'application/pdf') {
                    set_errors([t('If selecting multiple files, only JPG and PNG file types are accepted.')]);
                } else if (newfiles.length === 1 && newfiles.type === 'application/pdf') {
                    set_errors([t('If selecting multiple files, only JPG and PNG file types are accepted.')]);
                } else {
                    newfiles.push(item);
                    filesize += item.size;
                }
            });
            set_files(newfiles);
        }
    }

    function onDropRejected(rejections) {
        if (var_files.length === 1 && single_or_multiple === 'SINGLE') return;

        let error_code = (rejections && rejections.length > 0 && rejections[0].errors && rejections[0].errors.length > 0) ? rejections[0].errors[0].code : null;
        if (error_code === 'file-invalid-type') {
            set_errors([t('You have selected an invalid file type')]);
        } else if (error_code === 'file-too-large') {
            set_errors([`${t('File size larger than')} ${max_size} ${t('MB')}. ${t('Please reduce the file size and try again')}`]);
        }
    }

    function onClick_openDropzone() {
        if (single_or_multiple === 'MULTIPLE' || var_files.length === 0) {
            ref_dropzone.current.open()
        }
    }

    function onClick_deletefile(index) {
        set_file_to_validate(null);
        set_errors([]);
        let files = [...var_files];
        files.splice(index, 1);
        set_files(files);
        onDelete && onDelete(files);
    }

    function onError_img() {
        set_files([]);
        set_file_to_validate(null);
        set_errors([t('The selected image is invalid.')]);
    }


    // RENDER APP ======================================================================================================
    return (
        <div className='fileupload__wrapper' {...other_props}>

            <Dropzone ref={ref_dropzone} onDrop={onDrop} onDropRejected={onDropRejected} multiple={single_or_multiple === 'MULTIPLE'} accept={get_filetypes()} maxSize={max_size * 1024 * 1024} noClick noKeyboard>
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div className={'dropzone__fileupload' + (var_errors.length > 0 ? ' error' : '')} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Icon name='upload' className='icon__upload' alt='' />
                            <div>{t('Drag and Drop your files here or')}<Button type='button' className='tertiary button__browse' onClick={onClick_openDropzone}>{t('browse files')}</Button></div>
                            {var_files.map((item, index) =>
                                <div className='selected_file' key={'file_' + index}>
                                    <div style={{ width: '90%' }}>
                                        <div className='text--xs-medium selected_file_name'>{item.name}</div>
                                        <div className='text--xs-regular'>{bytes_display(item.size)}</div>
                                    </div>
                                    <Button type='button' id={'btn_delete_logo_' + item.logo_id} className='tertiary btn_delete_logo' onClick={() => onClick_deletefile(index)} >
                                        <Icon name='delete' className='icon__delete__table color--primary-500'/>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </section>
                )}
            </Dropzone>

            {single_or_multiple === 'SINGLE' && var_file_to_validate &&
                <img style={{ display: 'none' }} src={var_validation_blob} alt='' onError={onError_img} onLoad={() => set_files([var_file_to_validate])} />
            }

            {var_errors.length > 0 &&
                <div className='error_row'>
                    {var_errors.map((item, i) => <div key={'error_' + i}>{item}</div>)}
                </div>
            }
            {instructions && <div className='fileupload__instructions'>{instructions}</div>}
        </div>
    );
}

CMP_FILEUPLOAD.propTypes = {
    var_status: propTypes.string,
    set_status: propTypes.func,
    current_filename: propTypes.string,
    onChange_details: propTypes.func,
    single_or_multiple: propTypes.oneOf(['SINGLE', 'MULTIPLE']),
    uploaddirectory: propTypes.string,
    max_size: propTypes.number,
    allowed_fileextensions: propTypes.arrayOf(propTypes.string),
    instructions: propTypes.string,
    onProcess: propTypes.func
};

export default CMP_FILEUPLOAD;
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_CERTIFICATE from 'components/cmp_certificate/cmp_certificate';
import './mdl_certificate_preview.css';

/*******************************************************************
 * Note this modal is using in the credential details as well as create credential
 *******************************************************************/
function MDL_CERTIFICATE_PREVIEW({ is_open, onClose, credential }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_preview_credential, set_preview_credential ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open && !!credential) {
            create_preview_credential();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open, credential]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function create_preview_credential() {
        let preview_credential = {
            verification_status: 'VALID',
            confirmation_status: 'STATUS',
            individual_name: t('FirstName LastName'),
            credential_name: credential.name,
            issuer_name: credential.issuer_name,
            issuer_id: credential.issuer_id,
            issued: 'YYYY-MM-DD',
            expires: credential.expires === 'YES' ? 'YYYY-MM-DD' : null,
            credential_document: 'file',
            issuer_logo: credential.issuer_logo,
            verifying_organization_id: credential.verifying_organization_id,
            verification_id: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
            credential_description: credential.description,
            credential_skills: credential.skills,
            credential_logo: credential.imagefile_object ? null : credential.submittedfilename,
            credential_id: credential.id,
            background_color: credential.background_color,
            header_color: credential.header_color,
            text_color: credential.text_color,
            link_color: credential.link_color,
            description_color: credential.description_color
        }
        if (credential.imagefile_object) {
            let base64 = await new Promise((resolve, reject) => {
                let fileReader = new FileReader();
                fileReader.readAsDataURL(credential.imagefile_object)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject(error);
                }
            });
            set_preview_credential({ ...preview_credential, credential_logo: base64});
        } else {
            set_preview_credential(preview_credential);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    // RENDER APP ======================================================================================================

    return (
        <Modal
            className='mdl_certificate_preview'
            dimmer={'inverted'}
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium'>{t('Certificate preview')}</div>
                </div>
                <div className='modal__header__right'>
                    <div className="modal__header__closeicon color--primary-500">
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                    </div>
                </div>
            </div>

            <div className='modal__content'>
                <CMP_CERTIFICATE certificate={var_preview_credential || {}} ready={!!var_preview_credential} linkstatus={'ACTIVE'} />
            </div>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>

        </Modal>
    );
}

MDL_CERTIFICATE_PREVIEW.propTypes = {
    is_open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    credential: propTypes.object
};

export default MDL_CERTIFICATE_PREVIEW;
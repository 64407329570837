import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import datelib from 'libs/date-lib';
import auth from 'libs/auth-lib';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_DOCUMENTACKNOWLEDGE from 'components/cmp_documentacknowledge/cmp_documentacknowledge';



export default function CMP_DOCUMENTS_INDIVIDUAL({ activetab, assignment, onChange, onChangeView, var_focuselement, set_focuselement }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_documents, set_documents ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'status_display', sortorder: 'descending' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    const [ var_docviewer_open, set_docviewer_open ] = useState(false);
    const [ var_docviewer_id, set_docviewer_id ] = useState(null);
    const [ var_docviewer_showacknowledge, set_docviewer_showacknowledge ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'DOCUMENTS-INDIVIDUAL') {
            populate_documents();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_documents').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_documents(populateconfig) {
        set_loading(true);
        set_loadingerror(false);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_assignment_documents(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_documents(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
            if (var_focuselement?.id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${var_focuselement.id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_documents').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
            }
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_assignment_documents(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_documents(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_assignment_documents(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function onAcknowledge_document() {
        try {
            await populate_documents();
            set_docviewer_open(false);
            set_docviewer_id(null);
            onChange && onChange();
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_assignment_documents(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('documents', '/get-assignment-documents/' + assignment.location_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_view(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        onChangeView('ALL');
    }

    function onClick_document(id, status) {
        set_focuselement(document.activeElement);
        set_docviewer_id(id);
        set_docviewer_showacknowledge(status === 'Missing acknowledgement');
        set_docviewer_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_documents' tabIndex='0'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_documents' className='text--xl-medium'>{t('Documents')}</div>
                    </div>
                </div>

                <Table id='documents' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Documents')} downloadfunction={download_documents}
                        onChange={populate_documents}>

                    <Table.OptionsButtons>
                        {auth.has_access(assignment, 'ORG-DOC', 'read') &&
                            <TOGGLE value='INDIVIDUAL' options={[{value: 'INDIVIDUAL', text: t('Your documents')}, {value: 'ALL', text: t('All documents')}]} onChange={onClick_view} />
                        }
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className='cell__icon' field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('DOCUMENT')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_documents.map(item =>
                            <Table.Row key={item.id} onClick={() => onClick_document(item.id, item.status)}>
                                <Table.Cell className='cell__icon cell--center'>
                                    {
                                        item.status === 'Missing acknowledgement'
                                        ? <Icon name='error' className='color--red-600' alt={t('Missing acknowledgement')} />
                                        : item.status === 'Acknowledged'
                                        ? <Icon name='checkmark' className='color--green-500' alt={t('Acknowledged')} />
                                        : <></>
                                    }
                                </Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    <Table.CellContentTwoLine
                                        primary_text={item.name}
                                        secondary_text={
                                            (item.ack_req === 'YES' && !item.acknowledged_date)
                                                ? t('Missing acknowledgement')
                                                : item.ack_req === 'YES'
                                                    ? `${t('Acknowledged')} ${datelib.epoch_to_date_string(item.acknowledged_date, true)}`
                                                    : `${t('Added')} ${datelib.epoch_to_date_string(item.added_date, true)}`
                                        }
                                        display_chevron={true} />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

                <CMP_DOCUMENTACKNOWLEDGE
                    is_open={var_docviewer_open}
                    onAcknowledgement={onAcknowledge_document}
                    onClose={() => set_docviewer_open(false)}
                    document_id={var_docviewer_id}
                    show_acknowledge={var_docviewer_showacknowledge}
                />

            </div>
        </>
    )

}
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';

import 'react-datepicker/dist/react-datepicker.css';

function DATEPICKER({ property, value, onChange, onOpen, onClose, placeholder, minproperty, maxproperty, disabled = false, errors = [] }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const [ var_date_object, set_date_object ] = useState(null);
    const [ var_date_text, set_date_text ] = useState('');

    let error = errors.find(item => item.property === property);

    //  event listeners ------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (value && datelib.is_valid_date(value)) {
            set_date_object(value);
            set_date_text(value.toISOString().split('T')[0]);
        } else {
            set_date_object(null);
            set_date_text('');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_calendar(date, event) {
        if (event.target.tagName === 'INPUT') {
            return
        }
        if (datelib.is_valid_date(date)) {
            onClose && onClose();
            set_date_object(date);
            onChange && onChange(null, { value: date });
        }
    }

    function onChangeRaw_date(e) {
        let new_value = e.target.value;
        // Only accept numbers and dashes
        if (!/^[0-9-]*$/.test(new_value)) return;

        let formatted_value = datelib.format_date_text(new_value);
        set_date_text(formatted_value);

        // User typed full date
        if (formatted_value?.length >= 10) {
            let formatted_value_as_date = new Date(formatted_value + 'T00:00:00');
            if (datelib.is_valid_date(formatted_value_as_date)) {
                onChange && onChange(e, { value: formatted_value_as_date });
                set_date_object(formatted_value_as_date);
            }
        // User blanked input
        } else if (formatted_value?.length <= 0) {
            set_date_object(null);
            onChange && onChange(e, { value: null });
        }
    }

    function onBlur_input(e) {
        onClose && onClose();
        // Reset input to nothing if user typed invalid date
        if (var_date_text?.length < 10 || !datelib.is_valid_date(new Date(var_date_text + 'T00:00:00'))) {
            set_date_text('');
            set_date_object(null);
            onChange && onChange(e, { value: null });
        }
    }


    // RENDER ==========================================================================================================
    return (
        <Form.Field className={error ? 'error' : ''}>
            <DatePicker
                name={property}
                showIcon
                className='datepicker'
                onChange={(date, e) => onChange_calendar(date, e)}
                onChangeRaw={onChangeRaw_date}
                onBlur={onBlur_input}
                onFocus={() => onOpen && onOpen()}
                onInputClick={() => onOpen && onOpen()}
                onCalendarClose={() => onClose && onClose()}
                dateFormat={datelib.datepicker_format}
                isClearable={true}
                disabled={disabled}
                openToDate={datelib.get_datepicker_open_to_date(var_date_object, datelib.get_minimum_date(minproperty), datelib.get_maximum_date(maxproperty))}
                minDate={datelib.get_minimum_date(minproperty)}
                maxDate={datelib.get_maximum_date(maxproperty)}
                placeholderText={placeholder}
                selected={var_date_object}
                showYearDropdown
                allowSameDay={true}
                icon={<Icon name='calendar' className={'icon icon__calendar' + (disabled ? ' disabled' : '')} alt='calendar icon'/>}
            />
            {error &&
                <div className='field__feedback error'>
                    <div className='description text--sm-regular'>{error.description}</div>
                </div>
            }
        </Form.Field>
    );
}

DATEPICKER.propTypes = {
    property: propTypes.string,
    value: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(Date)]),
    onChange: propTypes.func,
    onOpen: propTypes.func,
    onClose: propTypes.func,
    placeholder: propTypes.string,
    minproperty: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(Date)]),
    maxproperty: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(Date)]),
    disabled: propTypes.bool,
    errors: propTypes.array
};

export default DATEPICKER;

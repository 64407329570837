import React from 'react';
import propTypes from 'prop-types';

function TABS({ activetab, details_card = false, id, aria_label = '', onClick, ...props }) {

    function onClick_tab(tab) {
        activetab !== tab && onClick && onClick(tab);
    }

    return (
        <nav aria-label={aria_label}>
            <div className={'tab' + (details_card ? ' tab--detailscard' : '')} role='navigation' id={id}>
                {props.children.filter(item => item.type.name === TAB.name).map((item, index) => {
                    return(
                        <TAB key={item.props.name} activetab={activetab} onClick={onClick_tab} tab_id={`Tab${index}`} {...item.props} />
                    );
                }
                )}
            </div>
        </nav>
    );
}

function TAB({ name, label, display = true, tab_id, ...props }) {
    let active = props.activetab === name;
    function onKeyDown(event, tab){
        if (event.key === 'Enter') {
            props.activetab !== tab && props.onClick(tab);
        }
    }

    return display
        ? (
            <div className={'tab__item text--md-bold' + (active ? ' current' : '')} onClick={() => props.onClick(name)} id={tab_id} tabIndex='0' role='button' onKeyDown={(e) => onKeyDown(e, name)}>{label}</div>
        )
        : null;
}

TABS.propTypes = {
    activetab: propTypes.string.isRequired,
    details_card: propTypes.bool,
    onClick: propTypes.func
};
TAB.propTypes = {
    name: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    display: propTypes.bool
};

TABS.TAB = TAB;

export default TABS;
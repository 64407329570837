import React, { useEffect } from 'react';

import CRD_DETAILS from './crd_details/crd_details';
import CRD_REQUIREMENTS from './crd_requirements/crd_requirements';



export default function TAB_DETAILS({ requirement_package, onChange_package }) {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            <CRD_DETAILS requirement_package={requirement_package} onChange_package={onChange_package} />
            <CRD_REQUIREMENTS requirement_package_id={requirement_package.id} requirement_package_name={requirement_package.name} />
        </>
    )
};

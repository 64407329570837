import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import API  from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import { decrypt, unwrap } from 'libs/vault-lib';
import { decodeJwt } from 'jose';
import jsonPath from 'jsonpath';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import { Button, Message, Modal, Form } from 'semantic-ui-react';
import { FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';
import auth from 'libs/auth-lib';
import { Buffer } from 'buffer';


/*********************************************************************************************************************************************************
 *
 * Currently only supports a single credential (input descriptor) and only supports filter of string/pattern
 * TODO: add support for groups, and support for different filters, etc.
 *
 *********************************************************************************************************************************************************/
function CMP_VP_REQUEST({ display, presentation_request, requested_credential, onClose, onComplete = false }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_credential_options, set_credential_options ] = useState([]);
    const [ var_selected_credential, set_selected_credential ] = useState(null);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_processing, set_processing ] = useState(true);
    const [ var_modal, set_modal ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal && !var_loading){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="checkbox"]:not([disabled]), a[href]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal, var_loading]);

    useEffect(() => {
        if (display && presentation_request) {
            // reset variables and re-populate
            set_credentials([]);
            set_credential_options([]);
            set_selected_credential(null);
            set_errors([]);
            set_loading(true);
            set_processing(true);
            populate_credentials();
            set_modal(document.querySelector('#mdl_submitcred'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, presentation_request]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials() {
        try {
            let results = await API_get_wallet_credentials();
            let decrypted_results = [];
            let approved_count = 0;
            for (let single_verifiable_credential of results) {
                let decrypted = await decrypt_vc(single_verifiable_credential);
                if (decrypted) {
                    decrypted.is_accepted = is_accepted(decrypted.vc_type, decrypted.payload);
                    decrypted.is_expired = is_expired(decrypted.vc_type, decrypted.payload);
                    if (decrypted.is_accepted && !decrypted.is_expired) {
                        decrypted_results.splice(approved_count, 0, decrypted);
                        approved_count++;
                    } else {
                        decrypted_results.push(decrypted);
                    }
                }
            }
            let accepted_credentials = decrypted_results.filter(item => item.is_accepted);
            set_credentials(accepted_credentials);
            if (accepted_credentials.length > 0) {
                let transformed = accepted_credentials.map((item, index) => ({
                    value: index.toString(),
                    primary_text: jsonPath.value(item, `$.payload${item.vc_type === 'JWT' ? '.vc' : ''}.credentialSubject.hasCredential.name`) || item.credential_name || '',
                    secondary_text: jsonPath.value(item, `$.payload${item.vc_type === 'JWT' ? '.vc' : ''}.credentialSubject.hasCredential.provider.name`),
                    secondary_text_link: jsonPath.value(item, `$.payload${item.vc_type === 'JWT' ? '.vc' : ''}.credentialSubject.hasCredential.provider.url`),
                    disabled: item.is_expired
                }));
                transformed.sort((a, b) => a.primary_text.toUpperCase() - b.primary_text.toUpperCase());
                set_credential_options(transformed);
            } else {
                // Currently, VPs only accept 1 VC, thus if the individual has none, show the single requested credential.
                set_credential_options([{ 
                    value: '0',
                    primary_text: requested_credential.credential_name,
                    secondary_text: requested_credential.issuer,
                    secondary_text_link: requested_credential.website,
                    disabled: true
                }]);
            }
        } catch (exception) {
            console.log(exception);
            set_errors([{ description: t('There was a problem loading your credentials.  Please try again later')} ]);
        } finally {
            set_loading(false);
            set_processing(false);
        }
    }

    async function decrypt_vc(verifiable_credential) {
        let kek = Window.$kek;
        if (!kek) return;
        let { key, key_algorithm, wrapping_algorithm } = JSON.parse(sessionStorage.getItem('encryption_key'));
        let decryption_key = await unwrap(key, key_algorithm, kek, wrapping_algorithm);
        let plaintext = await decrypt(verifiable_credential.vc, verifiable_credential.iv, verifiable_credential.encryption_algorithm, decryption_key);
        let vc_type;

        // get vc type
        if (plaintext.includes('@context')) {
            vc_type = 'JSON-LD';
        } else {
            let decoded = Buffer.from(plaintext.split('.')[0], 'base64')?.toString();
            try {
                let header = JSON.parse(decoded);
                if (header.typ === 'JWT') {
                    vc_type = 'JWT';
                }
            } catch (exception) {
                console.log(exception);
            }
        }
        if (!vc_type) return null;

        // get payload
        let payload = vc_type === 'JWT' ? decodeJwt(plaintext) : JSON.parse(JSON.stringify(JSON.parse(plaintext)));

        return { credential_name: verifiable_credential.credential_name, vc_type, vc: plaintext, payload };
    }

    function is_accepted(vc_type, verifiable_credential) {
        for (let constraint_field of presentation_request.claims.vp_token.presentation_definition.input_descriptors[0].constraints.fields) {
            let valid = false;
            for (let path of constraint_field.path) {
                let path_value = jsonPath.value(vc_type === 'JWT' ? verifiable_credential.vc : verifiable_credential, path);
                valid = !!path_value && (new RegExp(constraint_field.filter.pattern)).test(path_value);
                if (valid) {
                    break;
                }
            }
            if (!valid) return false;
        }
        return true;
    }

    function is_expired(vc_type, verifiable_credential) {
        let expiry_date = jsonPath.value(vc_type === 'JWT' ? verifiable_credential.vc : verifiable_credential, '$.credentialSubject.hasCredential.expires');
        if (!!expiry_date) return false;
        return Date.now() <= (new Date(expiry_date)).setHours(23, 59, 59, 999);
    }

    async function submit_presentation(verifiable_presentation) {
        set_processing(true);
        try {
            // First sign the presentation
            let signed = await API_sign_vp(verifiable_presentation);

            // TODO: right now sending to our endpoint, but should be sending to the url specified in the presentation request (in case request did not come from use. ie. scanned QR code)
            // but that means that we need to allow any site in our Content-Security-Policy
            let result = await API_verifiable_presentation_callback(signed);
            return { success: true, result };
        } catch (e) {
            console.log(e);
            set_errors([{description: t('There was a problem saving.  Please try again later')}]);
            return { success: false };
        } finally {
            set_processing(false);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_wallet_credentials() {
        return API.get('verifiable-credentials', '/get-wallet-credentials');
    }

    function API_sign_vp(payload) {
        return API.post('verifiable-credentials', '/sign-vp', {
            body: {
                did: payload.sub,
                payload
            }
        });
    }

    function API_verifiable_presentation_callback(verifiable_presentation) {
        return API.put('verifiable-credentials', '/verifiable-presentation-callback', {
            body: {
                vp: verifiable_presentation
            }
        });
    }


    // //  event functions ------------------------------------------------------------------------------------------------

    function onChange_credential(value) {
        set_selected_credential(value);
    }

    async function onClick_submit() {
        if (var_processing) return;

        // validation
        let errors = [];
        if (var_selected_credential === null) {
            errors.push({description: t('You must select a credential before submitting')});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        let verifiable_credential = var_credentials[Number(var_selected_credential)];
        let payload;

        if (verifiable_credential.vc_type === 'JWT') {
            let verifiable_presentation = {
                "@context": ['https://www.w3.org/2018/credentials/v1'],
                type: ['VerifiablePresentation'],
                verifiableCredential: [ verifiable_credential.vc ]
            };
            let now = Math.floor(Date.now() / 1000);
            payload = {
                iss: 'https://self-issued.me/v2/openid-vc',
                jti: presentation_request.jti,
                aud: presentation_request.client_id,
                nbf: now,
                iat: now,
                nonce: presentation_request.nonce,
                sub: auth.passphrase_parameters[0].did_uri,
                exp: Math.floor((new Date(now)).setFullYear((new Date(now)).getFullYear() + 2) / 1000),
                vp: verifiable_presentation
            };
        } else { // JSON-LD
            payload = {
                "@context": ['https://www.w3.org/2018/credentials/v1'],
                id: presentation_request.jti,
                type: ['VerifiablePresentation'],
                verifiableCredential: [verifiable_credential.payload]
            };
        }
        let results = await submit_presentation(payload);
        results.success && onComplete && onComplete(results.result);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_submitcred'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_submitcred'
        >
            <div className='modal__header'>
                <div style={ { width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium' id='hdr_submitcred'>{t('Submit credential')}</div>
                    </div>
                </div>
            </div>

            <Form className='modal__content center padding--lg' tabIndex='0' aria-labelledby='hdr_submitcred'>
                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' alt={t('alert icon')} />}
                        header={var_errors[0].description} />
                }

                {var_errors.length <= 0 && !var_loading && var_credentials.every(item => (item.is_expired || !item.is_accepted)) &&
                    <Message warning
                        icon={<Icon name='warning' className='icon' alt={t('warning icon')} />}
                        header={t('You do not have an approved credential to submit.')} />
                }

                {requested_credential &&
                    <div style={{ marginBottom: '1rem' }}>{requested_credential.title}</div>
                }
                <div>
                    <FORM_SELECT_RADIO name='credentials'
                        label={t('Approved credentials')}
                        value={var_selected_credential}
                        options={var_credential_options}
                        single_or_multiple='SINGLE'
                        onChange={onChange_credential} />
                </div>

            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    {(!var_loading && var_credentials.some(item => (!item.is_expired && item.is_accepted)))
                        ?
                            <>
                                <Button className='primary' onClick={onClick_submit}>{t('Submit')}</Button>
                                <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                            </>
                        :   <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                    }
                </div>
            </div>
            <Processing display={var_processing} processingtext={t('Processing')} />
        </Modal>
    );
}

CMP_VP_REQUEST.propTypes = {
    display: propTypes.bool.isRequired,
    presentation_request: propTypes.object,
    requested_credential: propTypes.shape({
        title: propTypes.string,
        credential_name: propTypes.string,
        issuer: propTypes.string,
        website: propTypes.string
    }),
    onClose: propTypes.func.isRequired,
    onComplete: propTypes.func
};

export default CMP_VP_REQUEST;
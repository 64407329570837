import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Popup, Form } from 'semantic-ui-react';
import { CHECKBOX } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import 'i18n';


export default function CMP_DOCUMENT_STATUS({ doc, status_display, is_open, onClose, can_archive, assignment_id, onOpen, onChange, onMessage }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_popup, set_popup ] = useState(null);
    const [ var_statuspopup_value, set_statuspopup_value ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const POPUP_ID = `mdl_document_status_${doc?.id}`;

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open) {
            set_statuspopup_value(doc.status);
            set_popup(document.querySelector(`#${POPUP_ID}`));
            set_focuselement(document.activeElement);
        } else if (!is_open && var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
        !is_open && onMessage(null, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_popup) {
            //  create list of focusable elements within the popup
            let elements = var_popup.querySelectorAll('form, input[type="checkbox"]:not([disabled]), button:not([disabled])');
            let firstelement = elements[0];
            let lastelement = elements[elements.length - 1];

            //  set focus to first element within the popup
            firstelement.focus({ preventScroll: true });

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_popup.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_popup]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function onClick_updatestatus() {
        set_processing(true);
        if (doc.status === var_statuspopup_value) {
            onMessage('ERROR', t('Please select a new status.'));
            set_processing(false);
            return;
        }
        onMessage(null, null);
        try {
            await API_put_archive();
            onChange && onChange(var_focuselement?.id);
        } catch (e) {
            onMessage('ERROR', t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_archive() {
        return API.put('documents', '/put-archive', {
            body: {
                document_id: doc.id,
                status: var_statuspopup_value,
                ...(!!assignment_id) && { location_id: assignment_id }
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_status() {
        set_focuselement(document.activeElement);
    }

    // RENDER APP ======================================================================================================

    return (
        <Popup
            trigger={
                <Button id={`btn_${POPUP_ID}`} onClick={onClick_status} type='button' className={'badge text--sm-medium ' + (doc.status === 'ACTIVE' ? 'badge--green' : 'badge--gray')}>
                    {status_display}
                    {can_archive &&
                        <Icon name='badge_dropdown' className='badge__icon' alt={t('Update status')} />
                    }
                </Button>
            }
            content={
                (is_open && can_archive)
                &&   <div id={POPUP_ID} className='badge__popup shadow-lg rounded-md' aria-modal='true' role='dialog' aria-labelledby={`hdr_${POPUP_ID}`}>
                        <div id={`hdr_${POPUP_ID}`} className='text--md-medium' aria-label={t('Update document status')}>{t('Update status')}</div>
                        <Form tabIndex='0' aria-labelledby={`hdr_${POPUP_ID}`}>
                            <div className='badge__popup__option'>
                                <CHECKBOX radio name='updatestatus' label={t('Active')} value='ACTIVE' checked={var_statuspopup_value === 'ACTIVE'} onChange={() => { set_statuspopup_value('ACTIVE'); onMessage(null, null); }} />
                            </div>
                            <div className='badge__popup__option'>
                                <CHECKBOX radio name='updatestatus' label={t('Inactive')} value='ARCHIVE' checked={var_statuspopup_value === 'ARCHIVE'} onChange={() => { set_statuspopup_value('ARCHIVE'); onMessage(null, null); }} />
                            </div>
                            <Button type='button' className='badge__popup__button secondary' onClick={onClick_updatestatus}>{t('Update')}</Button>
                        </Form>
                        <Processing display={var_processing} processingtext='' />
                    </div>
            }
            on='click'
            open={is_open}
            onOpen={() => onOpen(doc.id)}
            onClose={onClose}
            hideOnScroll
            position='bottom left' />
        );
}

import React from 'react';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import 'i18n';

import Icon from '../cmp_icon';

import './cmp_breadcrumb.css';

function CMP_BREADCRUMB({ breadcrumb_elements, current_page_text }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();

    //  event listeners ------------------------------------------------------------------------------------------------


    //  functions ------------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    // RENDER APP ======================================================================================================


    return (
        <div className='breadcrump_wrapper'>
            <nav aria-label={t('Breadcrumb')} id='breadcrumb'>
                <ol className='page__breadcrumb'>
                    <li className='page__breadcrumb__item'>
                        <Button onClick={() => navigate('/dashboard')} >
                            <Icon name='dashboard' className='page__breadcrumb__icon' alt={t('Dashboard')} />
                        </Button>
                    </li>
                    {breadcrumb_elements.map((item, index) =>
                        item.path ?
                        <li className='page__breadcrumb__item' key={'breadcrumb_element_' + index}>
                            <Button onClick={() => navigate(item.path)} >{item.text}</Button>
                        </li>
                        :
                        <li className='page__breadcrumb__item inactive' key={'breadcrumb_element_' + index}>{item.text}</li>
                    )}
                    <li className='page__breadcrumb__item current' aria-current='page'>{current_page_text}</li>
                </ol>
            </nav>
        </div>
    );
}

CMP_BREADCRUMB.propTypes = {
    breadcrumb_elements: propTypes.arrayOf(propTypes.exact({
        path: propTypes.string,
        text: propTypes.string.isRequired
    })).isRequired,
    current_page_text: propTypes.string.isRequired,
};

export default CMP_BREADCRUMB;
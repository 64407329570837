import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import Icon from 'components/cmp_icon';
import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import CMP_WARNING from 'components/cmp_warning/cmp_warning';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import auth from 'libs/auth-lib';
import { decrypt, encrypt, unwrap } from 'libs/vault-lib';

import CMP_NEWVERSION from 'components/cmp_credential_newversion/cmp_credential_newversion';
import CMP_MDL_CERTIFICATE from 'components/cmp_mdl_certificate/cmp_mdl_certificate';



export default function TAB_DETAILS({ credential, onChange, activetab, verifiable_credential }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_newversion_open, set_newversion_open ] = useState(false);
    const [ var_archive_confirmation_open, set_archive_confirmation_open ] = useState(false);
    const [ var_archive_confirmation_message_requirements, set_archive_confirmation_message_requirements ] = useState(null);
    const [ var_archive_confirmation_message_credentials, set_archive_confirmation_message_credentials ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_message_type, set_message_type ] = useState(null);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_cmp_warning_open, set_cmp_warning_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_docviewer_open, set_docviewer_open ] = useState(false);

    const [ var_view_certifcate, set_view_certificate ] = useState(false);

    const [ var_kek, set_kek ] = useState(Window.$kek);

    const [ var_display_claim, set_display_claim ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'DETAILS') {
            set_processing(false);
            set_message_type(null);
            set_message_text(null);
            set_display_claim(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);

    useEffect(() => {
        if (!var_newversion_open && var_focuselement?.id === 'btn_updatecredential') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_newversion_open]);

    useEffect(() => {
        if (!var_archive_confirmation_open && var_focuselement?.id === 'btn_archive') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_archive_confirmation_open]);

    useEffect(() => {
        if (!var_view_certifcate && var_focuselement?.id === 'btn_certificate_viewer') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_view_certifcate]);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement?.id === 'btn_documentviewer') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);

    useEffect(() => {
        if (!(credential.verifiable_credential === 'YES' && credential.status === 'ACTIVE'
        && credential.verification_status === 'VALID' && !!auth.passphrase_parameters && Window.$is_unlocked)) {
            return;
        }

        if (verifiable_credential.id) {
            verify_verifiablecredential();
        } else {
            set_display_claim('UNCLAIMED');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verifiable_credential]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function claim_jwt_verifiablecredential(subject_did) {
        set_processing(true);
        try {
            let results = await API_post_claim_verifiablecredential(credential.id, subject_did);
            if (results.vc) {
                let validated = await API_validate_jwt(results.vc);
                if (validated?.verified) {
                    let expiration = validated?.payload?.exp;
                    let { key, key_algorithm, wrapping_algorithm } = JSON.parse(sessionStorage.getItem('encryption_key'));
                    let encryption_key = await unwrap(key, key_algorithm, var_kek, wrapping_algorithm);
                    let encrypted = await encrypt(results.vc, encryption_key);
                    await API_put_wallet_credential(credential.id, encrypted, expiration);
                    onChange && onChange();
                    set_message_type('SUCCESS');
                    set_message_text(t('Credential upgrade complete.'));
                    set_display_claim(null);
                }
            }
        } catch (e) {
            console.log(e);
            set_message_type('ERROR');
            set_message_text(t('An error occurred while updating to the latest version. Please try again. If the issue persists, please contact our support team for further assistance.'));
        }
        set_processing(false);
    }

    async function claim_jsonld_verifiablecredential(subject_did) {
        set_processing(true);
        try {
            let results = await API_vcpaas_post_credential_issue(credential.id, subject_did);
            if (results.verifiableCredential) {
                //TODO: validate JSONLD VC
                let validated = results.verifiableCredential;
                let expiration = Math.floor(Date.parse(validated.expirationDate) / 1000);
                let { key, key_algorithm, wrapping_algorithm } = JSON.parse(sessionStorage.getItem('encryption_key'));
                let encryption_key = await unwrap(key, key_algorithm, var_kek, wrapping_algorithm);
                let encrypted = await encrypt(JSON.stringify(results.verifiableCredential), encryption_key);
                await API_put_wallet_credential(credential.id, encrypted, expiration);
                set_message_type('SUCCESS');
                set_message_text(t('Credential upgrade complete.'));
                set_display_claim(null);
                onChange && onChange();
            }
        } catch (e) {
            console.log(e);
            set_message_type('ERROR');
            set_message_text(t('An error occurred while updating to the latest version. Please try again. If the issue persists, please contact our support team for further assistance.'));
        }
        set_processing(false);
    }

    async function check_credential_issuer() {
        try {
            if (await API_get_credential_issuer_status() === 'ACTIVE') {
                set_newversion_open(true);
            } else {
                set_cmp_warning_open(true);
            }
        } catch(e) {
            console.log(e);
        }
    }

async function verify_verifiablecredential() {
    try {
        let { key, key_algorithm, wrapping_algorithm } = JSON.parse(sessionStorage.getItem('encryption_key'));
        let decryption_key = await unwrap(key, key_algorithm, Window.$kek, wrapping_algorithm);

        let plaintext = await decrypt(verifiable_credential.vc, verifiable_credential.iv, verifiable_credential.encryption_algorithm, decryption_key);

        let verified;
        if (plaintext.includes('@context')) { //JSONLD
            verified = await API_verify({verifiableCredential: plaintext});
        } else if (plaintext.includes('eyJ')) { //JWT
            verified = await API_verify({vc: plaintext});
        }

        if (verified?.errors?.includes('CREDENTIAL_STATUS_REVOKED')) {
            set_display_claim('REVOKED');
        } else if (verified?.errors?.includes('expirationDate')) {
            set_display_claim('EXPIRED');
        }
    } catch (e) {
        console.log(e);
    }
}

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_issuer_status() {
        return API.get('credentials', '/get-credential-issuer-status/' + credential.credential_id);
    }

    function API_get_usage() {
        return API.get('credentials', '/get-usage/' + credential.id);
    }

    function API_put_archive() {
        return API.put('credentials', '/put-archive', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: {
                individualcredential_id: credential.id,
                status: credential.status === 'ACTIVE' ? 'ARCHIVE' : 'ACTIVE'
            }
        });
    }

    function API_post_claim_verifiablecredential(id, did) {
        return API.post('integrations', '/microsoft-post-credential-issue', {
            body: {
                individualcredential_id: id,
                subject_did: did
            }
        });
    }

    function API_put_wallet_credential(id, credential, expiration_timestamp) {
        return API.put('verifiable-credentials', `/put-wallet-credential/${id}`, {
            body: {
                vc: credential.ciphertext,
                iv: credential.iv,
                encryption_algorithm: credential.encryption_algorithm,
                expiration_timestamp
            }
        });
    }

    function API_validate_jwt(jwt) {
        return API.post('verifiable-credentials', '/validate-jwt', {
            body: {
                jwt
            }
        });
    }

    function API_verify(data) {
        return API.post('verifiable-credentials', '/verify',
        {
            body: data
        });
    }

    function API_vcpaas_post_credential_issue(individualcredential_id, subject_did) {
        return API.post('integrations', '/vcpaas-post-credential-issue', {
            body: {
                individualcredential_id,
                subject_did
            }
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_update() {
        set_message_text(null);
        set_focuselement(document.activeElement);
        check_credential_issuer();
    }

    function onChange_newversion() {
        onChange && onChange();
        set_newversion_open(false);
        set_message_type('SUCCESS');
        set_message_text(t('The credential has been successfully updated'));
    }

    async function onClick_archive(confirmed) {
        set_message_text(null);
        document.activeElement?.id === 'btn_archive' && set_focuselement(document.activeElement);
        if (credential.status === 'ACTIVE' && !confirmed) {
            // if the credential is valid, check if it is used for any requirements
            let requirements, credentials = null;
            if (credential.verification_status === 'VALID') {
                // get usage
                let usage = await API_get_usage();

                // get unique requirements that have been accepted
                requirements = [...new Set(usage.filter(item => item.individualrequirement_status === 'VALID').map(item => item.requirement_name))];

                // get stacked credentials where this is used
                credentials = [...new Set(usage.filter(item => item.targetentity_type === 'CREDENTIAL').map(item => item.targetentity_name))].sort();
            }
            if (requirements && requirements.length > 0) {
                set_archive_confirmation_message_requirements(
                    <div style={{ display: 'inline' }}>{credentials?.length > 0 ? t('This action will cause') : t('Additionally, this action will cause')} <span className='text--sm-bold'>{requirements.join(', ')}</span> {t('to become unmet, which could affect your ability to work.')}</div>
                );
            }
            if (credentials && credentials.length > 0) {
                set_archive_confirmation_message_credentials(
                    <div style={{ display: 'inline' }}>{t('Additionally, this will make a pre-qualifier for')} <span className='text--sm-bold'>{credentials.join(', ')}</span> {t('to become unmet, resulting in a status change to ‘In-progress’ for the credential.')}</div>
                );
            }
            set_archive_confirmation_open(true);
            return;
        } else if (confirmed) {
            set_archive_confirmation_open(false);
        }

        set_processing(true);
        try {
            await API_put_archive();
            onChange && onChange();
            set_message_type('SUCCESS');
            set_message_text(t('The credential has been successfully updated'));
        } catch (e) {
            set_message_type('ERROR');
            set_message_text(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
    }

    function onClick_attachment() {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_docviewer_open(true);
    }

    function onClick_renewal() {
        set_message_text(null);
        credential.website.toLowerCase().startsWith('https://') ? window.open(credential.website) : window.open('https://' + credential.website);
    }

    function onKeyDown(event) {
        if (event.key === 'Enter') {
            onClick_renewal();
        }
    }

    function onClick_certificate(event) {
        event.preventDefault();
        set_focuselement(document.activeElement);
        set_view_certificate(true);
    }

    function onClick_claimcredential() {
        let passphrase_params = auth.passphrase_parameters[0];
        let kek = Window.$kek;
        if (!passphrase_params || !kek) return;
        set_kek(kek);
        if (credential.proof_type === 'JWT') {
            claim_jwt_verifiablecredential(passphrase_params.did_uri);
        } else if (credential.proof_type === 'JSONLD') {
            claim_jsonld_verifiablecredential(passphrase_params.did_uri);
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
        {var_message_text &&
            <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                header={var_message_text} />
        }

        { var_display_claim === 'UNCLAIMED' && render_unclaimed_card() }
        { var_display_claim === 'REVOKED' && render_revoked_card() }
        { var_display_claim === 'EXPIRED' && render_expired_card() }

        <div className='card rounded-lg shadow-sm' id='crd_details' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div id='hdr_creddetails' className='text--xl-medium'>{t('Details')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    {credential.prequalifier_status === 'N/A' && credential.can_update_details === 'YES' &&
                        <Button id='btn_updatecredential' className='primary' onClick={onClick_update}>{t('Update credential')}</Button>
                    }
                    {credential.status && !(credential.status !== 'ACTIVE' && credential.issued_external === 'YES') &&
                        <Button id='btn_archive' className='secondary' onClick={() => onClick_archive(false)}>{t(credential.status === 'ACTIVE' ? 'Deactivate' : 'Reactivate')}</Button>
                    }
                </div>
            </div>

            {credential.prequalifier_status === 'IN PROGRESS' &&
                <Message warning
                    icon={<Icon name='warning' className='icon' />}
                    header={t('Pre-qualifications for this credential are incomplete.')}
                />
            }


            <div className='card__content'>
                <Form>
                    {
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {render_view_item('individual_name', t('Holder'))}
                            {render_view_item('issuer_name', t('Issuer'))}
                            {render_view_item('confirmation_status', t('Confirmation status'), true, true)}
                            {credential.additional_fields &&
                                render_additional_fields()
                            }
                            {credential.credit_value &&
                                render_view_item('credit_value', t('Credit value'))
                            }
                            {credential.pii !== undefined &&
                                render_view_item('pii', t('PII'))
                            }
                            {credential.verification_status !== 'PENDING' && credential.prequalifier_status === 'N/A' && credential.is_self_verified !== 'YES'
                                ? render_view_item('verifying_organization_name', t('Verified by'))
                                : null
                            }
                            {(!credential.verified_transaction_id || credential.verification_status === 'PENDING')
                                ? null
                                : credential.prequalifier_status === 'N/A' && credential.is_self_verified !== 'YES'
                                ?
                                render_view_item('verified_transaction_id', t('Verification ID'))
                                :
                                render_view_item('verified_transaction_id', t('Transaction ID'))
                            }
                            {credential.prequalifier_status === 'N/A' &&
                                <div className='detailsgroup'>
                                    <div className='detailsgroup__label text--sm-medium'>{t('Attachment')}</div>
                                    <div className='detailsgroup__value text--sm-regular'>
                                        {credential.submittedfilename && <Button id='btn_documentviewer' className='tertiary' onClick={onClick_attachment}>{credential.submittedfilename}</Button>}
                                    </div>
                                </div>
                            }
                            <div className='detailsgroup'>
                                <div className='detailsgroup__label text--sm-medium'>{t('Certificate')}</div>
                                <div className='detailsgroup__value text--sm-regular'>
                                <Button id='btn_certificate_viewer' className='tertiary' onClick={onClick_certificate}>{t('View certificate')}</Button>
                                </div>
                            </div>
                        </div>
                    }
                </Form>


            </div>

            <div className='card__footer--mobilebtns'>
                {credential.prequalifier_status === 'N/A' && credential.can_update_details === 'YES' &&
                    <Button id='btn_updatecredential' className='primary' onClick={onClick_update}>{t('Update credential')}</Button>
                }
                <Button id='btn_archive' className='secondary' onClick={() => onClick_archive(false)}>{t(credential.status === 'ACTIVE' ? 'Deactivate' : 'Reactivate')}</Button>
            </div>

            <CMP_NEWVERSION display={var_newversion_open} onClose={() => set_newversion_open(false)} id={credential.id} onChange={onChange_newversion} />
            <CMP_CONFIRMATION display={var_archive_confirmation_open} onCancel={() => set_archive_confirmation_open(false)} title={t('Deactivate credential')}
                message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>
                            {`${t(`Deactivating this credential will cause any organization you have shared it with to lose access.`)} ${(!var_archive_confirmation_message_requirements && !var_archive_confirmation_message_credentials) ? t('This may impact your ability to work.') : ''}`}
                        </div>
                        {var_archive_confirmation_message_requirements &&
                            <div style={{marginBottom: '0.5rem'}}>{var_archive_confirmation_message_requirements}</div>
                        }
                        {var_archive_confirmation_message_credentials &&
                            <div style={{marginBottom: '0.5rem'}}>{var_archive_confirmation_message_credentials}</div>
                        }
                        <div>{t('You can re-activate this credential at any time.')}</div>
                    </div>} positive_option={t('Deactivate')} negative_option={t('Cancel')} onConfirm={() => onClick_archive(true)} />

            <CMP_WARNING
                display={var_cmp_warning_open}
                title={t('Inactive issuer')}
                message={
                    <div style={ { flexDirection: 'column' } }>
                        <div style={ { marginBottom: '0.5rem' } }>{t('Updates to this credential are not possible because the issuer is no longer active in the system.')}</div>
                        <div>{t('If you need assistance, please contact the issuer directly for support.')}</div>
                    </div>}
                onClose={() => set_cmp_warning_open(false)}
            />

            <CMP_MDL_CERTIFICATE
                credential={credential}
                verifiable_credential={verifiable_credential}
                is_open={var_view_certifcate}
                onClose={() => set_view_certificate(false)}
            />

            <MDL_DOCVIEWER
                targetid={credential.id}
                classification='credential'
                is_open={var_docviewer_open}
                onClose={() => set_docviewer_open(false)}
            />

            {/***** END MODAL: DOC VIEWER ***************************************************************************/}

            <Processing display={var_processing} processingtext={t('Processing')} />

        </div>
        </>
    )

    function render_view_item(property, name, translate = false, init_cap = false) {
        let value = credential[property];
        init_cap && value && (value = value.substring(0, 1).toUpperCase() + value.substring(1).toLowerCase());
        translate && value && (value = t(value));
        return (
            <div className='detailsgroup'>
                <div className='detailsgroup__label text--sm-medium'>{name}</div>
                <div className='detailsgroup__value text--sm-regular'>
                    {typeof(value) === 'number' ? value.toFixed(2) : value}
                    {property === 'issuer_name' && credential.website && credential.website.length > 0 && credential.expires &&
                        <div className='text--anchor' tabIndex='0' role='button' style={{ marginLeft: '1rem' }} onKeyDown={onKeyDown} onClick={onClick_renewal}>{t('Renew credential')}</div>
                    }
                </div>
            </div>
        );
    }

    function render_additional_fields() {
        return (
            <>
            {credential.additional_fields.sort((a, b) => a.order - b.order).map((item, index) =>
            <div className='detailsgroup' key={'additional_field_' + index}>
                <div className='detailsgroup__label text--sm-medium'>{item.translate_label ? t(item.label) : item.label}</div>
                <div className='detailsgroup__value text--sm-regular'>
                    {item.datatype === 'date'
                        ? datelib.epoch_to_date_string(item.value)
                        : item.datatype === 'datetime'
                            ? datelib.epoch_to_datetime_string(item.value, true)
                            : item.value
                    }
                </div>
            </div>
            )}
            </>
        )
    }

    function render_expired_card() {
        return(
            <div className='card rounded-lg shadow-sm'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('New version available')}</div>
                    </div>
                </div>
                <div className='card__content' style={{flexDirection: 'row'}}>
                    <div className='content__left'>
                        <div className='text--sm-regular'>{t('To maintain the advanced security, verification, and sharing capabilities for this credential get the latest version now.')}</div>
                    </div>
                    <div className='content__right'>
                        <Button className='primary' onClick={onClick_claimcredential}>{t('Get latest version')}</Button>
                    </div>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />
            </div>
        );
    }

    function render_revoked_card() {
        return(
            <div className='card rounded-lg shadow-sm'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('New verifiable credential available')}</div>
                    </div>
                </div>
                <div className='card__content' style={{flexDirection: 'row'}}>
                    <div className='content__left'>
                        <div className='text--sm-regular'>{t('The verifiable credential for this has been revoked. Get a new version now to retain advanced security, verification and sharing capabilities for this credential.')}</div>
                    </div>
                    <div className='content__right'>
                        <Button className='primary' onClick={onClick_claimcredential}>{t('Get new version')}</Button>
                    </div>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />
            </div>
        );
    }

    function render_unclaimed_card() {
        return(
            <div className='card rounded-lg shadow-sm'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Enhance the security of your credential')}</div>
                    </div>
                </div>
                <div className='card__content' style={{flexDirection: 'row'}}>
                    <div className='content__left'>
                        <div className='text--sm-regular'>{t('Unlock advanced security, verification, and sharing capabilities by upgrading your credential to a verifiable credential.')}</div>
                        <div className='text--sm-regular'>{t(`Click the ‘Upgrade credential’ button to start the upgrade process.`)}</div>
                    </div>
                    <div className='content__right'>
                        <Button className='primary' onClick={onClick_claimcredential}>{t('Upgrade credential')}</Button>
                    </div>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />
            </div>
        );
    }

}
import API from 'libs/api-lib';


/**
 *
 * @param {String} plaintext
 * @param {CryptoKey} key
 * @returns
 */
async function encrypt(plaintext, key) {
    let encryption_algorithm = 'AES-GCM';
    //IV should be 96 bits long as per https://developer.mozilla.org/en-US/docs/Web/API/AesGcmParams
    let iv = window.crypto.getRandomValues(new Uint8Array(12)); //12 bytes
    let ciphertext = await window.crypto.subtle.encrypt(
        {
            name: encryption_algorithm,
            iv: iv
        },
        key,
        new TextEncoder().encode(plaintext)
    );

    return {
        ciphertext: uint8array_to_base64(new Uint8Array(ciphertext)),
        iv: uint8array_to_base64(iv),
        encryption_algorithm
    };
};


/**
 *
 * @param {String} ciphertext
 * @param {String} iv
 * @param {String} encryption_algorithm
 * @param {CryptoKey} key
 * @returns
 */
async function decrypt(ciphertext, iv, encryption_algorithm, key) {
    let plaintext = await window.crypto.subtle.decrypt(
        {
            name: encryption_algorithm,
            iv: base64_to_uint8array(iv)
        },
        key,
        base64_to_uint8array(ciphertext)
    );

    return new TextDecoder().decode(plaintext);
};


/**
 * Generates an encryption key and encrypts it with the passphrase
 * @param {String} passphrase
 * @param {String} algorithm
 * @param {Number} iterations
 * @param {String} salt
 * @returns
 */
async function generate_encryption_key(passphrase, algorithm, iterations, salt) {
    let key_algorithm  = {name: 'AES-GCM', length: 256};
    let wrapping_algorithm = {name: 'AES-KW', length: 256};

    // Generate a new KeyPair for encryption
    let encryption_key = await window.crypto.subtle.generateKey(
        key_algorithm,
        true, //extractable
        ['encrypt', 'decrypt']
    );

    // Derive the key wrapping key from the passphrase
    let wrapping_key = await passphrase_to_wrapping_key(passphrase, algorithm, iterations, salt);

    // Wrap the encryption key
    let wrapped_key = await window.crypto.subtle.wrapKey(
        'raw',
        encryption_key,
        wrapping_key,
        wrapping_algorithm
    );
    // Return the encrypted and base64 encoded encryption key
    return {
        value: uint8array_to_base64(new Uint8Array(wrapped_key)),
        key_algorithm,
        wrapping_algorithm
    };
};


/**
 * Hashes a passphrase.
 * @param {String} passphrase
 * @param {String} algorithm optional. default 'SHA-256'
 * @param {String} salt optional - base64 string
 * @param {Number} iterations optional. default 100001
 * @returns
 */
async function hash(passphrase, algorithm, salt, iterations) {
    algorithm = algorithm || 'SHA-256';
    iterations = iterations || 100001;
    salt = salt ? base64_to_uint8array(salt) : window.crypto.getRandomValues(new Uint8Array(32)); //32 bytes

    let base_key = await passphrase_to_key(passphrase);

    let passphrase_hash = new Uint8Array(
        await window.crypto.subtle.deriveBits(
            {
                name: 'PBKDF2',
                hash: algorithm,
                iterations: iterations,
                salt: salt
            },
            base_key,
            128
    ));

    return {
        value: uint8array_to_base64(passphrase_hash),
        salt: uint8array_to_base64(salt),
        iterations,
        hash_algorithm: algorithm
    };
};


/**
 * Derives the Key Wrapping CryptoKey from a passphrase
 * @param {String} passphrase
 * @param {String} algorithm
 * @param {Number} iterations
 * @param {String} salt
 * @returns {Promise<CryptoKey>}
 */
async function passphrase_to_wrapping_key(passphrase, algorithm, iterations, salt) {
    const KEY_TYPE = { name: 'AES-KW', length: 256 };

    // import the passphrase as a CryptoKey
    let base_key = await passphrase_to_key(passphrase);

    // Derive a Key Wrapping CryptoKey using the passphrase key material
    return window.crypto.subtle.deriveKey(
        {
            name: 'PBKDF2',
            hash: algorithm,
            iterations: iterations,
            salt: base64_to_uint8array(salt)
        },
        base_key,
        KEY_TYPE,
        false, //extractable
        ['wrapKey', 'unwrapKey']
    );
};


/**
 *
 * @param {String} encryption_key
 * @param {AesGcmParams} encryption_key_algorithm
 * @param {AlgorithmIdentifier} wrapping_algorithm
 * @param {CryptoKey} wrapping_key
 * @returns
 */
async function unwrap(encryption_key, encryption_key_algorithm, wrapping_key, wrapping_algorithm) {
    return window.crypto.subtle.unwrapKey(
        'raw',
        base64_to_uint8array(encryption_key),
        wrapping_key,
        wrapping_algorithm,
        encryption_key_algorithm,
        false, //extractable
        ['encrypt', 'decrypt']
    );
}


/**
 * Verifies a passphrase.
 * @param {String} passphrase
 * @param {String} algorithm
 * @param {String} salt
 * @param {Number} iterations
 * @returns
 */
async function verify(passphrase, algorithm, salt, iterations) {
    let passphrase_hash = await hash(passphrase, algorithm, salt, iterations);
    let results;
    try {
        results = await API.get('identifiers', '/validate', {
            queryStringParameters: {
                passphrase: passphrase_hash.value
            },
        });
    } catch {
        return {verified: false}
    }

    return {
        verified: true,
        encryption_keys: results
    };
};

//#region Internal Functions


/**
 * Decodes a base64 string to Uint8Array
 * @param {string} base64data
 * @returns {Uint8Array}
 */
function base64_to_uint8array(base64data) {
    return Uint8Array.from(window.atob(base64data), (c) => c.charCodeAt(0));
};


/**
 * Generates a CryptoKey from a passphrase
 * @param {String} passphrase
 * @returns {Promise<CryptoKey>}
 */
async function passphrase_to_key(passphrase) {
    return window.crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(passphrase),
        'PBKDF2',
        false, //extractable
        ['deriveBits', 'deriveKey']
    );
};


/**
 * Encodes Uint8Array to base64 string
 * @param {Uint8Array} array
 * @returns {String}
 */
function uint8array_to_base64(array) {
    let binary = '';
    for (let i = 0; i < array.byteLength; i++) {
        binary += String.fromCharCode(array[i]);
    }
    return window.btoa(binary);
};

//#endregion

export {encrypt, decrypt, generate_encryption_key, hash, passphrase_to_wrapping_key, unwrap, verify};
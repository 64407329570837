import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Popup, Form } from 'semantic-ui-react';
import { CHECKBOX } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import 'i18n';


export default function CMP_INDIVIDUAL_STATUS({ individual, is_open, onClose, onOpen, onChange, onMessage }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_popup, set_popup ] = useState(null);
    const [ var_statuspopup_value, set_statuspopup_value ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const POPUP_ID = (`mdl_invite_status_${individual?.id}`);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open) {
            set_statuspopup_value(individual.individual_status === 'Active' ? 'ACTIVE' : individual.individual_status === 'Inactive' ? 'ARCHIVE' : '');
            set_popup(document.querySelector(`#${POPUP_ID}`));
        } else if (var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_popup) {
            //  create list of focusable elements within the popup
            let elements = var_popup.querySelectorAll('form, input[type="checkbox"]:not([disabled]), textarea:not([disabled]), button:not([disabled])');
            let firstelement = elements[0];
            let lastelement = elements[elements.length - 1];

            //  set focus to first element within the popup
            firstelement.focus({ preventScroll: true });

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_popup.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_popup]);


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    function API_post_indv_resendinvite(organization_individual_id) {
        return API.post('org-indv', '/post-indv-resendinvite/' + organization_individual_id);
    }

    function API_post_indv_cancelinvite(organization_individual_id) {
        return API.post('org-indv', '/post-indv-cancelinvite/' + organization_individual_id);
    }

    function API_put_org_indv_archive(organization_individual_id, status) {
        return API.put('org-indv', '/put-org-indv-archive/' + organization_individual_id + '/' + status);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_updatestatus(item) {
        onMessage(null, null);
        let current_value = item.individual_status;
        if (current_value === var_statuspopup_value) {
            onClose();
            return;
        }

        if (!var_statuspopup_value){
            onMessage('ERROR', t('Please select a status'));
            return;
        }

        set_processing(true);
        try {
            if (['Invited', 'Expired', 'Declined', 'Pending'].includes(item.individual_status)) {
                if (var_statuspopup_value === 'Resend') {
                    await API_post_indv_resendinvite(item.organization_individual_id);
                } else if (var_statuspopup_value === 'Cancel') {
                    await API_post_indv_cancelinvite(item.organization_individual_id);
                } else {
                    throw new Error('Invalid option');
                }
                onChange(var_focuselement?.id);
            } else {
                await API_put_org_indv_archive(item.organization_individual_id, var_statuspopup_value);
                onChange(var_focuselement?.id);
            }
        } catch(e) {
            console.log(e);
        }
        set_processing(false);
    }

    function onClick_status(){
        set_focuselement(document.activeElement);
    }

    // RENDER APP ======================================================================================================

    return (
            <Popup
                trigger={
                    <Button
                    onClick={onClick_status}
                    id={`btn_${POPUP_ID}`}
                    className={'badge text--sm-medium  ' +
                        (   individual.individual_status === 'Active' ? 'badge--green'
                            : individual.individual_status === 'Inactive' ? 'badge--gray'
                            : individual.individual_status === 'Pending' ? 'badge--yellow'
                            : (individual.individual_status === 'Expired' || individual.individual_status === 'Declined') ? 'badge--red'
                            : 'badge--gray')}
                    type='button'
                    >
                        {individual.individual_status_display}
                        <Icon name='badge_dropdown' className='badge__icon' />
                    </Button>
                }
                content={
                    is_open ?
                        <div className='badge__popup shadow-lg rounded-md' id={POPUP_ID} aria-modal='true' role='dialog' aria-labelledby={`hdr_${POPUP_ID}`} aria-label={t('Update invite status')}>
                            <div id={`hdr_${POPUP_ID}`} className='text--md-medium' aria-label={t('Change individual status')}>{t('Change status')}</div>
                            <Form tabIndex='0' aria-labelledby={`hdr_${POPUP_ID}`}>
                                {individual.individual_status === 'Invited' || individual.individual_status === 'Expired' || individual.individual_status === 'Declined' ?
                                <>
                                <div className='badge__popup__option'>
                                    <CHECKBOX radio name='updatestatus' label={t('Resend invitation')} value='Resend' checked={var_statuspopup_value === 'Resend'} onChange={() => set_statuspopup_value('Resend')} />
                                </div>
                                <div className='badge__popup__option'>
                                    <CHECKBOX radio name='updatestatus' label={t('Cancel invitation')} value='Cancel' checked={var_statuspopup_value === 'Cancel'} onChange={() => set_statuspopup_value('Cancel')} />
                                </div>
                                </>
                                : individual.individual_status === 'Pending' ?
                                <>
                                <div className='badge__popup__option'>
                                    <CHECKBOX radio name='updatestatus' label={t('Send invitation')} value='Resend' checked={var_statuspopup_value === 'Resend'} onChange={() => set_statuspopup_value('Resend')} />
                                </div>
                                <div className='badge__popup__option'>
                                    <CHECKBOX radio name='updatestatus' label={t('Cancel invitation')} value='Cancel' checked={var_statuspopup_value === 'Cancel'} onChange={() => set_statuspopup_value('Cancel')} />
                                </div>
                                </>
                                :
                                <>
                                <div className='badge__popup__option'>
                                    <CHECKBOX radio name='updatestatus' label={t('Active')} value='ACTIVE' checked={var_statuspopup_value === 'ACTIVE'} onChange={() => set_statuspopup_value('ACTIVE')} />
                                </div>
                                <div className='badge__popup__option'>
                                    <CHECKBOX radio name='updatestatus' label={t('Inactive')} value='ARCHIVE' checked={var_statuspopup_value === 'ARCHIVE'} onChange={() => set_statuspopup_value('ARCHIVE')} />
                                </div>
                                </>
                                }
                                <Button type='button' className='badge__popup__button secondary' onClick={() => onClick_updatestatus(individual)}>{t('Update')}</Button>
                            </Form>
                            <Processing display={var_processing} processingtext='' />

                        </div>
                        : null
                }
                on='click'
                open={is_open}
                onOpen={() => onOpen(individual.id)}
                onClose={onClose}
                hideOnScroll
                position='bottom left' />
    );
}
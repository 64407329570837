import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';

import { Modal, Button } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';

import './mdl_count_details.css';


export default function MDL_COUNT_DETAILS({ display, onClose, document_name, locationdocument_id, status, location_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_details, set_details ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display) {
            set_details([]);
            set_totalrows(0);
            set_ready(false);
            set_loading(true);
            set_loadingerror(false);
            set_refreshtable(!var_refreshtable);
            populate_records({ ...default_populateconfig, sortby: 'individual_name' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_loc_document_count_details(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_details(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_loc_document_count_details(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_loc_document_count_details(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_document_count_details(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('documents', '/get-loc-document-count-details/' + locationdocument_id + '/' + status,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------
    function onClick_individual(id) {
        navigate(`/assignments/${location_id}/DOCUMENTS/individual/${id}`);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer={'inverted'}
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            >
            <div className='modal__header'>
                <div style={ { width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium'>{status ? t(status.substring(0, 1) + status.substring(1).toLowerCase()) : ''}</div>
                        <div className='text--sm-regular'>{document_name}</div>
                    </div>
                    <div className='modal__header__right'>
                        <div className="modal__header__closeicon color--primary-500">
                            <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal__content'>
                <Table id='documents' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Documents')} downloadfunction={download_records}
                        onChange={populate_records}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='job_title' datatype='text' filtertype='text'>{t('JOB TITLE')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                            {status === 'ACKNOWLEDGED' &&
                                <Table.HeaderCell field='acknowledged_date' datatype='datetime' filtertype='date'>{t('TIME')}</Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_details.map(item =>
                            <Table.Row key={item.individual_id} >
                                <Table.Cell>
                                    <CMP_VERIFIED_IDENTITY_DISPLAY
                                        individual_name={item.individual_name}
                                        onClick_individual={() => onClick_individual(item.individual_id)}
                                        verified_identity_credential_id={item.identity_verification_credential_id}
                                    />
                                </Table.Cell>
                                <Table.Cell>{item.job_title}</Table.Cell>
                                {status === 'ACKNOWLEDGED' && <Table.Cell>{item.organization}</Table.Cell>}
                                <Table.Cell className={'cell__icon--right ' + (status === 'ACKNOWLEDGED' ? 'cell__datetime' : '')}>
                                    <Table.CellContentTwoLine secondary_text={status === 'ACKNOWLEDGED' ? datelib.epoch_to_datetime_string(item.acknowledged_date, true) : item.organization} display_chevron={true} />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>
            </div>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>
        </Modal>
    );

}
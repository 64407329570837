import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import 'i18n';

import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';
import MDL_ACKNOWLEDGED from './mdl_acknowledged/mdl_acknowledged';
import MDL_MISSING from './mdl_missing/mdl_missing';
import MDL_ASSIGNMENTS from './mdl_assignments/mdl_assignments';
import MDL_ADDASSIGNMENTS from './mdl_addassignment/mdl_addassignment';
import MDL_ADDDOCUMENT from './mdl_adddocument/mdl_adddocument';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import CMP_DOCUMENT_STATUS from 'components/cmp_document_status/cmp_document_status';


export default function CRD_DOCUMENTS() {

//  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_documents, set_documents ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);

    const [ var_mdl_document_id, set_mdl_document_id ] = useState('');
    const [ var_mdl_document, set_mdl_document ] = useState('');
    const [ var_mdl_acknowledged_open, set_mdl_acknowledged_open ] = useState(false);
    const [ var_mdl_missing_open, set_mdl_missing_open ] = useState(false);
    const [ var_mdl_assignments_open, set_mdl_assignments_open ] = useState(false);
    const [ var_mdl_addassignments_open, set_mdl_addassignments_open ] = useState(false);
    const [ var_mdl_adddocument_open, set_mdl_adddocument_open ] = useState(false);
    const [ var_delete_document_open, set_delete_document_open ] = useState(false);
    const [ var_delete_document_id, set_delete_document_id ] = useState(null);

    const [ var_docviewer_id, set_docviewer_id ] = useState(null);
    const [ var_docviewer_open, set_docviewer_open ] = useState(false);

    const [ var_documentstatus, set_documentstatus ] = useState('ACTIVE')
    const [ var_statuspopup_id, set_statuspopup_id ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_documents({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ var_documentstatus ]);

    useEffect(() => {
        if (!var_mdl_adddocument_open && var_focuselement?.id === 'btn_adddocument') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_adddocument_open]);

    useEffect(() => {
        if (!var_mdl_acknowledged_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_documents').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_acknowledged_open]);

    useEffect(() => {
        if (!var_mdl_missing_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_documents').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_missing_open]);

    useEffect(() => {
        if (!var_mdl_assignments_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_documents').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_assignments_open]);

    useEffect(() => {
        if (!var_mdl_addassignments_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_documents').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_addassignments_open]);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_documents').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);

    useEffect(() => {
        if (!var_delete_document_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_documents').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_delete_document_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_documents(populateconfig, reset_message = true, focuselement_id) {
        reset_message && set_message_text(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_document_counts(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_documents(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_documents').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        } else if (var_focuselement?.id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${var_focuselement.id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_documents').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_document_counts(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_document_counts(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_document_counts(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('documents', '/get-org-document-counts/' + var_documentstatus,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_delete_document() {
        return API.del('documents', '/delete-document/' + var_delete_document_id);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_documentstatus(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_documentstatus(status);
    }

    function onClick_adddocument() {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_mdl_adddocument_open(true);
    }

    function onClick_document(id) {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_docviewer_id(id);
        set_docviewer_open(true);
    }

    function onClick_acknowledged(id, document_ref, count) {
        set_focuselement(document.activeElement);
        if (count > 0) {
            set_message_text(null);
            set_mdl_document_id(id);
            set_mdl_document(document_ref);
            set_mdl_acknowledged_open(true);
        }
    }

    function onClick_missing(id, document_ref, count) {
        set_focuselement(document.activeElement);
        if (count > 0) {
            set_message_text(null);
            set_mdl_document_id(id);
            set_mdl_document(document_ref);
            set_mdl_missing_open(true);
        }
    }

    function onClick_assignments(id, document_ref) {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_mdl_document_id(id);
        set_mdl_document(document_ref);
        set_mdl_assignments_open(true);
    }

    function onClick_addassignments(id, document_ref) {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_mdl_document_id(id);
        set_mdl_document(document_ref);
        set_mdl_addassignments_open(true);
    }

    async function onAssignmentAdded(assignment_list) {
        await populate_documents();
        set_message_type('SUCCESS');
        set_message_text(`${t('The document has been added to')} ${assignment_list}.`);
        set_mdl_addassignments_open(false);
    }

    function onClick_status(id) {
        set_message_text(null);
        set_statuspopup_id(id);
    }

    async function onChange_updatestatus(focuselement_id) {
        set_statuspopup_id(null);
        populate_documents(null, false, focuselement_id);
    }

    function onDocumentAdded(name) {
        populate_documents(null, false);
        set_message_type('SUCCESS');
        set_message_text(name + ' ' + t('has been added.'));
    }

    function onDelete_document(id) {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_delete_document_id(id);
        set_delete_document_open(true);
    }

    async function onConfirm_delete() {
        set_focuselement(document.getElementById('crd_documents'));
        set_delete_document_open(false);
        try {
            await API_delete_document();
            populate_documents(null, false);
            set_message_type('SUCCESS');
            set_message_text(t('Document has been deleted.'));
        } catch (e) {
            set_message_text(t('There was a problem saving.  Please try again later'));
            set_message_type('ERROR');
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_documents' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_documents' className='text--xl-medium'>{t('Documents')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_adddocument' className='primary' onClick={onClick_adddocument}>{t('Add document')}</Button>
                    </div>
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='documents' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('documents')} downloadfunction={download_records}
                       onChange={populate_documents}>

                    <Table.OptionsButtons>
                        <TOGGLE value={var_documentstatus} onChange={onClick_documentstatus} options={[{value: 'ACTIVE', text: t('Active')}, {value: 'ARCHIVE', text: t('Inactive')}]} />
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='added_date' datatype='datetime' filtertype='date'>{t('ADDED')}</Table.HeaderCell>
                            <Table.HeaderCell field='acknowledged_count' datatype='number' filtertype='number'>{t('ACKNOWLEDGED')}</Table.HeaderCell>
                            <Table.HeaderCell field='missing_count' datatype='number' filtertype='number'>{t('MISSING')}</Table.HeaderCell>
                            <Table.HeaderCell field='location_name' datatype='text' filtertype='text'>{t('ASSIGNMENTS')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_documents.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    {
                                        <CMP_DOCUMENT_STATUS
                                            doc={item}
                                            status_display={item.status_display}
                                            can_archive={true}
                                            is_open={item.id === var_statuspopup_id}
                                            onClose={() => set_statuspopup_id(null)}
                                            onOpen={onClick_status}
                                            onChange={(focuselement_id) => onChange_updatestatus(focuselement_id)}
                                            onMessage={(type, text) => { set_message_type(type); set_message_text(text); }}
                                        />
                                    }
                                </Table.Cell>
                                <Table.Cell className='text--anchor' >
                                    <Button className='tertiary' onClick={() => onClick_document(item.id)}>
                                        {item.name}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell >{datelib.epoch_to_date_string(item.added_date, true)}</Table.Cell>
                                <Table.Cell className={item.acknowledged_count ? 'text--anchor' : ''}>
                                    {item.acknowledged_count === null ? 'N/A' :
                                        <Button className='tertiary' onClick={() => onClick_acknowledged(item.id, item.name, item.acknowledged_count)} aria-disabled={item.acknowledged_count === 0 && "true"}>
                                            {item.acknowledged_count}
                                        </Button>
                                    }
                                </Table.Cell>
                                <Table.Cell className={item.missing_count ? 'text--anchor' : ''}>
                                    {item.missing_count === null ? 'N/A' :
                                        <Button className='tertiary' onClick={() => onClick_missing(item.id, item.name, item.missing_count)} aria-disabled={item.missing_count === 0 && "true"}>
                                            {item.missing_count}
                                        </Button>
                                    }
                                </Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    <div className='text--anchor'>
                                        {item.location_name &&
                                            <Button className='tertiary' onClick={() => onClick_assignments(item.id, item.name)}>
                                                {item.location_name}
                                            </Button>
                                        }
                                        <Button className='tertiary' onClick={() => onClick_addassignments(item.id, item.name)} >
                                            <Icon name='plus_circle' className='icon__addindividual color--primary-500' alt={t('Add assignment to document')} />
                                        </Button>
                                    </div>
                                    <Button className='tertiary' onClick={() => onDelete_document(item.id)} >
                                        <Icon name='delete' className='color--primary-500' alt={t('Delete document')} />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button id='btn_adddocument' className='primary' onClick={onClick_adddocument}>{t('Add document')}</Button>
                </div>

            </div>

            <MDL_ADDDOCUMENT
                display={var_mdl_adddocument_open}
                onClose={() => set_mdl_adddocument_open(false)}
                onChange={onDocumentAdded}
            />

            <MDL_DOCVIEWER
                targetid={var_docviewer_id}
                classification='document'
                is_open={var_docviewer_open}
                onClose={() => set_docviewer_open(false)}
            />

            <MDL_ACKNOWLEDGED
                display={var_mdl_acknowledged_open}
                onClose={() => set_mdl_acknowledged_open(false)}
                document_name={var_mdl_document}
                document_id={var_mdl_document_id}
            />

            <MDL_MISSING
                display={var_mdl_missing_open}
                onClose={() => set_mdl_missing_open(false)}
                document_name={var_mdl_document}
                document_id={var_mdl_document_id}
            />

            <MDL_ASSIGNMENTS
                display={var_mdl_assignments_open}
                onClose={() => set_mdl_assignments_open(false)}
                onChange={populate_documents}
                document_name={var_mdl_document}
                document_id={var_mdl_document_id}
            />

            <MDL_ADDASSIGNMENTS
                display={var_mdl_addassignments_open}
                onClose={() => set_mdl_addassignments_open(false)}
                onAssignmentAdded={onAssignmentAdded}
                document_name={var_mdl_document}
                document_id={var_mdl_document_id}
            />

            <CMP_CONFIRMATION display={var_delete_document_open} title={t('Delete document')} message={
                <div style={{flexDirection: 'column'}}>
                    <div style={{marginBottom: '0.5rem'}}>{t('Deleting this document from the system will result in the loss of any associated data related to assignments, transactions, and acknowledgements.')}</div>
                    <div style={{marginBottom: '0.5rem'}}>{t('This action cannot be undone.')}</div>
                </div>
            } positive_option={t('Delete document')} negative_option={t('Cancel')} onConfirm={onConfirm_delete} onCancel={() => set_delete_document_open(false)} />

        </>

    )
}
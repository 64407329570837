import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import 'i18n';


export default function CRD_ALL_ASSIGNMENTS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'location_name' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_assignments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_assignments(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_assignments(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_assignments(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_assignments(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_assignments(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_assignments(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_assignments(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('location', '/get-all-assignments',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_assignment(location_id) {
        navigate('/assignments/' + location_id);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd__all_assignments' tabIndex='0'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_assignments' className='text--xl-medium'>{t('All assignments')}</div>
                    </div>
                </div>

                <Table id='assignments' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('All assignments')} downloadfunction={download_assignments}
                        onChange={populate_assignments}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='location_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='location' datatype='text' filtertype='text'>{t('LOCATION')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_assignments.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' onClick={() => onClick_assignment(item.id)}>{item.location_name}</Button>
                                </Table.Cell>
                                <Table.Cell>{item.location}</Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    <Table.CellContentTwoLine secondary_text={item.organization} display_chevron={true} />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

            </div>
        </>
    );
}
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'i18n';

import Icon from 'components/cmp_icon';
import './crd_categories.css';

export default function CRD_CATEGORIES({credentialrefresh, onRefreshComplete}) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_categories, set_categories ] = useState([
        {category:'BACKGROUND_CHECK_RESULT', credential_count:0},
        {category:'EDUCATION', credential_count:0},
        {category:'IDENTITY', credential_count:0},
        {category:'LICENSE_CERTIFICATION', credential_count:0},
        {category:'MEMBERSHIP', credential_count:0},
        {category:'OCCUPATIONAL_HEALTH_RESULT', credential_count:0}
    ]);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_categories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (credentialrefresh){
            populate_categories();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentialrefresh]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_categories() {
        set_categories((await API_get_category_counts()).filter(item => item.category !== 'ASSET_INSPECTION_REPORT'));
        onRefreshComplete();
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_category_counts() {
        return API.get('credentials', '/get-category-counts');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_category(category) {
        navigate('/credentials/' + category.toLowerCase());
    }

    function onKeyDown(event, category) {
        if (event.key === 'Enter') {
            onClick_category(category);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='text--md-regular'>{t('Credential categories')}</div>
            <nav aria-label={t('Credential categories')}>
                <div className='launchcard__wrapper' role='navigation' id='credential_categories'>
                    {var_categories.map((item, index) =>
                        <div id={`Card${index}`} tabIndex='0' role='link' key={item.category} className={'launchcard launchcard--' + (item.credential_count === 0 ? 'gray' : 'blue')} onClick={() => onClick_category(item.category)} onKeyDown={(e) => onKeyDown(e, item.category)}>
                            <div className='launchcard__icon'>
                                <Icon name={'credentialtype_' + item.category.toLowerCase()} alt='' />
                            </div>
                            <div className='launchcard__text'>
                                <div className='text--md-medium'>{t(item.category)}</div>
                                <div className='text--sm-regular'>{item.credential_count + ' ' + t(item.credential_count === 1 ? 'credential' : 'credentials')}</div>
                            </div>
                            <div className='launchcard__chevron'>
                                <Icon name='chevron_right' alt={t('chevron icon')} />
                            </div>
                        </div>
                    )}
                </div>
            </nav>
        </>
    );
}

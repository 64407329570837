import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';

import { useTranslation } from 'react-i18next';
import 'i18n';
import Processing from 'components/cmp_processing';

import CMP_TRANSCRIPT from 'components/cmp_transcript/cmp_transcript';

import './transcript.css';


export default function REPORT_TRANSCRIPT() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const params = useParams();

    const [ var_transcript, set_transcript ] = useState({});
    const [ var_transcript_ready, set_transcript_ready ] = useState(false);
    const [ var_linkstatus, set_linkstatus ] = useState('');
    const [ var_processing, set_processing ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        populate_transcript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_transcript() {
        set_processing(true);
        try {
            let transcript = await API_get_transcript()
            if (transcript) {
                set_linkstatus('ACTIVE');
                set_transcript(transcript);
                set_transcript_ready(true);
            } else {
                set_linkstatus('NOTFOUND');
            }
        } catch (e) {
            console.log(e);
            if (e.response?.status === 410) {
                set_linkstatus('INACTIVE');
            } else if (e.response?.status === 404) {
                set_linkstatus('NOTFOUND');
            }
        } finally {
            set_processing(false);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_transcript() {
        return API.get('external-report', '/get-transcript/' + params.token, {
            queryStringParameters: {
                tz: datelib.timezone,
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return(
        <div className='transcript_report_wrapper'>
            <div id='content' tabIndex='0'>
                {!var_processing && var_transcript_ready &&
                    <CMP_TRANSCRIPT display_type='page' transcript={var_transcript.transcript || {}} user_entered_fields={var_transcript.transcript?.report_options} ready={var_transcript_ready} credentials={var_transcript.credentials || []} />
                }

                {!var_processing && (var_linkstatus === 'INACTIVE' || var_linkstatus === 'NOTFOUND') &&
                    <div className='report_wrapper'>
                        <div>
                            <Message
                                error
                                icon={<Icon name='error' className='icon' />}
                                header={t('A valid or active report transcript could not be found. Please check the link again or the individual you received it from.')}
                            />
                        </div>
                    </div>
                }

                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>

        </div>
    )
};
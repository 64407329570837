import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';
import { Button } from 'semantic-ui-react';

import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';

import CMP_REQUIREMENT from 'components/cmp_requirement/cmp_requirement';
import CMP_MDL_CERTIFICATE from 'components/cmp_mdl_certificate/cmp_mdl_certificate';

export default function TAB_REQUIREMENTS({ location_id, individual_id, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'requirement_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_has_manage_credentials, set_has_manage_credentials ] = useState(false);
    const [ var_selected_individualrequirement, set_selected_individualrequirement ] = useState({});
    const [ var_updaterequirement_open, set_updaterequirement_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_certificate_open, set_certificate_open ] = useState(false);
    const [ var_selected_credential_id, set_selected_credential_id ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'REQUIREMENTS' && individual_id) {
            set_has_manage_credentials(false);
            set_selected_individualrequirement({});
            set_updaterequirement_open(false);
            set_refreshtable(!var_refreshtable);
            populate_records();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, individual_id]);

    useEffect(() => {
        if (!var_updaterequirement_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_workerprofile_requirements').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_updaterequirement_open]);

    useEffect(() => {
        if (!var_certificate_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_workerprofile_credentials').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_certificate_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, focuselement_id) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_requirements(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_requirements(results.results === undefined ? [] : results.results);
            set_has_manage_credentials(results.has_manage_credentials === 'YES');
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_workerprofile_requirements').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_requirements(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_requirements(limit, offset, sortby, sortorder, filter)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirements(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-indv-requirements/' + individual_id,
        {
            queryStringParameters: {
                limit: limit,
                offset: offset,
                sortby: sortby,
                sortorder: sortorder,
                filtername: filtername,
                tz: datelib.timezone,
                location_id: location_id
            },
            body: filter
        }
    );

    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_credential(id) {
        set_focuselement(document.activeElement);
        set_selected_credential_id(id);
        set_certificate_open(true);
    }

    function onClick_requirement(id){
        let requirement = var_requirements.find(item => item.individualrequirement_id === id);
        set_selected_individualrequirement(requirement);
        set_updaterequirement_open(true);
    }

    function onChange_requirements(focuselement_id) {
        set_selected_individualrequirement({});
        set_updaterequirement_open(false);
        populate_records(null, focuselement_id);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_workerprofile_requirements'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Requirements')}</div>
                    </div>
                </div>

                <div className='card__content'>
                    <Table id='requirements' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                            populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                            downloadname={t('Requirements')} downloadfunction={download_records}
                            onChange={populate_records}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                                <Table.HeaderCell field='requirement_name' datatype='text' filtertype='option'>{t('REQUIREMENT')}</Table.HeaderCell>
                                <Table.HeaderCell field='comments' datatype='text' filtertype='option'>{t('COMMENT')}</Table.HeaderCell>
                                <Table.HeaderCell field='credential' datatype='text' filtertype='option'>{t('CREDENTIAL')}</Table.HeaderCell>
                                <Table.HeaderCell field='issuedby' datatype='text' filtertype='option'>{t('ISSUER')}</Table.HeaderCell>
                                <Table.HeaderCell field='epochexpires' datatype='date' filtertype='date'>{t('EXPIRES')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {var_requirements.map(item =>
                                <Table.Row key={item.individualrequirement_id} >
                                    <Table.Cell className='cell__icon cell--center'>
                                        {
                                            item.status === 'Valid'
                                            ? <Icon name='checkmark' className='color--green-500' alt={t('status icon')} />
                                            : item.status === 'Expiring'
                                            ? <Icon name='warning_circle' className='color--green-500' alt={t('status icon')} />
                                            : item.status === 'Pending'
                                            ? <Icon name='eye_circle' className='color--yellow-500' alt={t('status icon')} />
                                            : <Icon name='error' className='color--red-600' alt={t('status icon')} />
                                        }
                                    </Table.Cell>
                                    <Table.Cell >
                                        <Button id={`btn_cmp_requirement_${item.individualrequirement_id}`} className='tertiary' onClick={() => { if (var_has_manage_credentials) { set_focuselement(document.activeElement); onClick_requirement(item.individualrequirement_id); }}}>
                                            <Table.CellContentTwoLine primary_text={<div className={var_has_manage_credentials ? 'text--anchor' : ''}>{item.requirement_name}</div>} secondary_text={item.status_display}/>
                                        </Button>
                                    </Table.Cell>
                                    <Table.Cell>{item.comments}</Table.Cell>
                                    <Table.Cell className='text--anchor'>
                                        <Button className='tertiary' onClick={() => onClick_credential(item.individualcredential_id)} >
                                            {item.credential}
                                            {item.credential && item.has_customsecurity === 'YES' && <Icon name='lock' className='icon__lock color--gray-400' alt={t('lock icon')} />}
                                        </Button>
                                    </Table.Cell>
                                    <Table.Cell>{item.issuedby}</Table.Cell>
                                    <Table.Cell className='cell__datetime'>{datelib.epoch_to_date_string(item.epochexpires)}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>

                    </Table>
                </div>
            </div>

            <CMP_REQUIREMENT
                individualrequirement_id={var_selected_individualrequirement.individualrequirement_id}
                requirement_id={var_selected_individualrequirement.requirement_id}
                requirement_name={var_selected_individualrequirement.requirement_name}
                requirement_status={var_selected_individualrequirement.status}
                assignedfrom_type={var_selected_individualrequirement.location_id ? 'LOCATION' : 'ORGANIZATION'}
                assignedfrom_id={var_selected_individualrequirement.location_id || var_selected_individualrequirement.organization_id}
                assignedfrom_name={var_selected_individualrequirement.location_name || var_selected_individualrequirement.organization_name}
                credential_name={var_selected_individualrequirement.credential}
                is_open={var_updaterequirement_open}
                onClose={() => set_updaterequirement_open(false)}
                onChange={(results, missing, focuselement_id) => onChange_requirements(focuselement_id)}
                individual_id={individual_id}
            />

            <CMP_MDL_CERTIFICATE
                credential_id={var_selected_credential_id}
                verifiable_credential={null}
                is_open={var_certificate_open}
                onClose={() => set_certificate_open(false)}
            />
        </>
    )
}

import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { FORM_FILEUPLOAD } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './mdl_upload_logo.css';

export default function MDL_UPLOAD_LOGO({ display, onClose, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_errors, set_errors ] = useState([]);
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_logo, set_logo ] = useState({});
    const [ var_fileupload_status, set_fileupload_status ] = useState(null);
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            // reset and populate options
            set_modal(document.querySelector('#mdl_add_logo'));
            set_errors([]);
            set_processing(false);
            set_save_error(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        if (var_fileupload_status === 'UPLOAD COMPLETE') {
            add_logo();
        } else if (var_fileupload_status === 'UPLOAD FAIL') {
            set_processing(false);
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload_status]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function add_logo() {
        try {
            await API_post_org_logo();
            onChange && onChange();
            onClose();
        } catch (e) {
            console.log(e);
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------


    function API_post_org_logo() {
        return API.post('org-profile', '/post-org-logo',
            {
                body: var_logo,
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_save() {
        if (var_fileupload_status === 'UPLOAD READY') {
            set_fileupload_status('START UPLOAD');
            set_processing(true);
        } else if (var_fileupload_status !== 'UPLOAD FAIL') {
            set_errors([{ property: 'logo', description: t('Please select a logo to upload') }]);
        }
    }

    function onChange_file(details) {
        set_logo({ new_logo: details.filename, submittedfilename: details.display_name });
    }




    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_add_logo'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_add_logo'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_add_logo'>{t('Upload logo')}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_add_logo' aria-labelledby='hdr_add_logo' tabIndex='0'>

                {var_save_error &&
                    <Message error header={var_save_error} />
                }

                <FORM_FILEUPLOAD
                    property='logo'
                    onChange={onChange_file}
                    single_or_multiple='SINGLE'
                    var_fileupload_status={var_fileupload_status}
                    set_fileupload_status={set_fileupload_status}
                    uploaddirectory='logo'
                    max_size={10}
                    allowed_fileextensions={['png', 'jpg', 'jpeg']}
                    instructions={t('Only JPG and PNG file types are accepted and should be a minimum of 355x50 pixels')}
                    errors={var_errors}
                />

            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

}

import React from 'react';

import CMP_CREDENTIAL_SHARING from 'components/cmp_credential_sharing/cmp_credential_sharing';


export default function TAB_SHARING(){

    //  variable declarations ------------------------------------------------------------------------------------------

    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <CMP_CREDENTIAL_SHARING />
    )

}

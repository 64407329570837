import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import datelib from 'libs/date-lib';
import API from 'libs/api-lib';
import { Form, Modal, Button, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import 'i18n';
import { FORM_SELECT, FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';


export default function MDL_ADD_CREDENTIAL({ display, onClose, requirement_id, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_selected_issuer, set_selected_issuer ] = useState(null);
    const [ var_issuer_options, set_issuer_options ] = useState([]);
    const [ var_selected_credentials, set_selected_credentials ] = useState([]);
    const [ var_credential_options, set_credential_options ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);

    const [ var_errors, set_errors ] = useState([]);
    const [ var_save_error, set_save_error ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display) {
            // reset and populate issuers
            set_selected_issuer(null);
            set_issuer_options([]);
            set_selected_credentials([]);
            set_credential_options([]);
            set_errors([]);
            set_save_error(false);
            populate_issuer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        if (var_selected_issuer) {
            populate_credentials();
        } else {
            set_credential_options([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_selected_issuer]);



    //  async functions ------------------------------------------------------------------------------------------------


    async function populate_issuer() {
        try {
            let results = await API_get_issuer_list();
            set_issuer_options(results.map(item => ({ value: item.id, text: item.name })));
        } catch(e) {
            console.log(e);
        }
    }

    async function populate_credentials() {
        try {
            let results = await API_get_preapproval_credential_list(var_selected_issuer);
            if (results) {
                let transformed = results.map(item => ({
                    value: item.id,
                    primary_text: item.name,
                    disabled: item.is_selected === 'YES',
                    issuer_id: item.issuer_id,
                    credential_id: item.id
                }));
                set_credential_options(transformed);
            }
        } catch(e) {
            console.log(e);
        }
    }

    async function insert_credentials(credentials) {
        set_processing(true);
        try {
            await API_post_org_requirement_historical(credentials);
            onChange();
            onClose();
        } catch(e) {
            set_save_error(true);
            console.log(e);
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------


    function API_get_issuer_list() {
        return API.get('credentials', '/get-issuer-list');
    }

    function API_get_preapproval_credential_list() {
        return API.get('requirements', '/get-preapproval-credential-list/' + var_selected_issuer + '/' + requirement_id);
    }

    function API_post_org_requirement_historical(credentials) {
        return API.post('requirements', '/post-org-requirement-historical/' + requirement_id,
        {
            queryStringParameters: { tz: datelib.timezone },
            body: { approved: credentials, rejected: [] }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------


    function onChange_issuer(event, { value }) {
        set_save_error(false);
        set_selected_issuer(value);
        if (var_errors.length > 0) {
            const errors = var_errors.filter(error => error.property !== 'issuer')
            set_errors(errors);
        }
    }

    function onChange_credential(value){
        set_save_error(false);
        set_selected_credentials(value);
        if (var_errors.length > 0) {
            const errors = var_errors.filter(error => error.property !== 'credentials')
            set_errors(errors);
        }
    }

    function onClick_add() {
        set_save_error(false);
        let errors = [];
        // validation
        if (!var_selected_issuer) {
            errors.push({property: 'issuer', description: t('Please select an issuer')});
        } else if (var_selected_credentials.length === 0) {
            errors.push({property: 'credentials', description: t('Please select at least one credential')});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        let credentials = var_credential_options.filter(item => var_selected_credentials.includes(item.value));
        insert_credentials(credentials);
    }



    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_add_premission'
            dimmer={'inverted'}
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium'>{t('Select credentials')}</div>
                </div>
                <div className='modal__header__right'>
                    <div className='modal__header__closeicon color--primary-500'>
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                    </div>
                </div>
            </div>

            <Form className='modal__content'>
                {var_save_error &&
                    <Message error
                        header={t('An unknown error has occurred. Please try again.')}
                    />
                }

                <FORM_SELECT
                    property='issuer'
                    label={t('Issuer')}
                    value={var_selected_issuer || ''}
                    onChange={onChange_issuer}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('Issuer')}
                    options={var_issuer_options}
                    disabled={false}
                    errors={var_errors}
                    description=''
                />

                <FORM_SELECT_RADIO
                    name='credentials'
                    property='credentials'
                    label={t('Credential')}
                    value={var_selected_credentials}
                    options={var_credential_options}
                    single_or_multiple='MULTIPLE'
                    onChange={onChange_credential}
                    placeholder={t('Search')}
                    disabled={false}
                    errors={var_errors}
                />

            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

}

import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { SELECT_RADIO } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from 'components/cmp_icon';

import './mdl_addassignment.css';


export default function MDL_ADDASSIGNMENT({ display, onClose, document_name, document_id, onAssignmentAdded }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_selected_assignments, set_selected_assignments ] = useState([]);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display && document_id) {
            // reset and populate options
            set_assignments([]);
            set_selected_assignments([]);
            set_errors([]);
            set_processing(false);
            populate_assignments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, document_id]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_assignments() {
        let assignments = await API_get_org_document_available_assignments();
        set_assignments(assignments.map(item => ({
            value: item.id,
            primary_text: item.location_name,
            secondary_text: item.attached_to_location === 'YES' ? t('Already added') : null,
            disabled: item.attached_to_location === 'YES'
        })));
    }

    async function assign_requirement() {
        set_processing(true);
        try {
            await API_post_loc_document();
            let assignment_list = var_assignments.filter(item => var_selected_assignments.includes(item.value)).map(item => item.primary_text).join(', ');
            onAssignmentAdded && onAssignmentAdded(assignment_list);
        } catch (exception) {
            console.log(exception);
            set_errors([{ description: 'An unexpected error occurred. Please try again.' }]);
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_document_available_assignments() {
        return API.get('documents', `/get-org-document-available-assignments/${document_id}`);
    }

    function API_post_loc_document() {
        return API.post('documents', '/post-loc-document', { body: {
            type: 'EXISTING',
            location_ids: var_selected_assignments,
            document_id
        }});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_assignments(value) {
        set_selected_assignments(value);
        set_errors([]);
    }

    function onClick_add() {
        set_errors([]);
        if (var_selected_assignments.length === 0) {
            set_errors([{ property: 'assignments', description: t('Please select at least one assignment to add') }]);
            return;
        }

        assign_requirement();
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_addassignment'
            dimmer={'inverted'}
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium'>{t('Add assignment to document')}</div>
                    <div className='text--sm-regular'>{document_name}</div>
                </div>
                <div className='modal__header__right'>
                    <div className="modal__header__closeicon color--primary-500">
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                    </div>
                </div>
            </div>

            <Form className='modal__content'>
                {var_errors.some(item => item.property === undefined) &&
                    <Message success={true} icon={<Icon name='error' className='icon' />}
                        header={var_errors.find(item => item.property === undefined).description} />
                }
                <SELECT_RADIO name='assignments' value={var_selected_assignments} options={var_assignments} single_or_multiple='MULTIPLE' onChange={onChange_assignments} errors={var_errors} />
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add} disabled={var_assignments.length === 0}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

}
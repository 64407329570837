import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Form, Input, Checkbox } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { useTranslation } from 'react-i18next';
import 'i18n';

import '../cmp_form.css';



function SELECT_RADIO({ name, value, options, single_or_multiple = 'SINGLE', disabled = false, onChange, errors, aria_label, aria_labelledby, id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_search_value, set_search_value ] = useState('');
    const [ var_throttled_search_value, set_throttled_search_value ] = useState('');
    const [ var_search_results, set_search_results ] = useState([]);
    const MIN_OPTIONS_FOR_SEARCH = 10;

    let error = errors ? errors.find(item => item.property === name) : null;

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        // reset
        set_search_value('');
        set_throttled_search_value('');
        set_search_results(options || []);
    }, [options]);

    useEffect(() => {
        if (var_throttled_search_value === '') {
            set_search_results(options || []);
        } else {
            const reg_exp = new RegExp(_.escapeRegExp(var_throttled_search_value), 'i');
            const is_match = (result) => (typeof result.primary_text === 'string' && reg_exp.test(result.primary_text)) || reg_exp.test(result.secondary_text) || result.additional_search_values?.some(value => reg_exp.test(value));
            set_search_results((options || []).filter(item => is_match(item)));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_throttled_search_value]);


    //  event functions ------------------------------------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttle_set_search_value = useCallback(_.throttle(value => set_throttled_search_value(value), 1000), []);

    function onChange_search(e) {
        set_search_value(e.target.value);
        throttle_set_search_value(e.target.value);
    }

    function onChange_checkbox(e, data) {
        let new_value;
        if (single_or_multiple === 'SINGLE') {
            new_value = data.value;
        } else if (data.checked) {
            new_value = [...value];
            new_value.push(data.value);
        } else {
            new_value = value.filter(item => item !== data.value);
        }
        onChange && onChange(new_value);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
        <div id={id} className='select_radio__container' tabIndex='0'>
            {single_or_multiple === 'SINGLE' ? render_single() : render_multiple()}
        </div>
        {error &&
            <div className='field__feedback error'>
                <div className='text--sm-regular'>{error.description}</div>
            </div>
        }
        </>
    );

    function render_single() {
        return (
            <>
                {options.length > MIN_OPTIONS_FOR_SEARCH && render_search_input()}
                <div className='select_radio__results' role='radiogroup' aria-label={aria_label ? aria_label : undefined} aria-labelledby={aria_labelledby ? aria_labelledby : undefined}>
                    {var_search_results.map((item, index) =>
                        <div className={`select_radio__item ${disabled || item.disabled ? 'disabled' : ''}`} key={'select_radio__itemkey_' + item.value}>
                            <Checkbox
                                radio
                                name={'select_radio__checkbox_' + name}
                                value={item.value}
                                checked={item.value === value}
                                onChange={onChange_checkbox}
                                disabled={disabled || item.disabled}
                                aria-labelledby={`radio_item_${index + 1}`}
                                />
                            <div className='select_radio__item__twoline'>
                                <div id={`radio_item_${index + 1}`} className='text--sm-regular'>
                                    {item.primary_text}
                                </div>
                                {item.secondary_text_link ?
                                    <a href={item.secondary_text_link} target='_blank' rel='noreferrer'>{item.secondary_text}</a>
                                    :
                                    <div className={(item.secondary_text_css ? item.secondary_text_css : 'text--xs-regular')}>
                                        {item.secondary_text}
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }

    function render_multiple() {
        return (
            <>
                {options.length > MIN_OPTIONS_FOR_SEARCH && render_search_input()}
                <div className='select_radio__results' role='group' aria-label={aria_label ? aria_label : undefined} aria-labelledby={aria_labelledby ? aria_labelledby : undefined}>
                    {var_search_results.map((item, index) =>
                        <div className={`select_radio__item ${disabled || item.disabled ? 'disabled' : ''}`} key={'select_radio__itemkey_' + item.value}>
                            <Checkbox
                                value={item.value}
                                checked={value.includes(item.value)}
                                onChange={onChange_checkbox}
                                disabled={disabled || item.disabled}
                                aria-labelledby={`radio_item_${index + 1}`}
                                />
                            <div className='select_radio__item__twoline'>
                                <div id={`radio_item_${index + 1}`} className='text--sm-regular'>
                                    {item.primary_text}
                                </div>
                                {item.secondary_text_link ?
                                    <a href={item.secondary_text_link} target='_blank' rel='noreferrer'>{item.secondary_text}</a>
                                    :
                                    <div className={(item.secondary_text_css ? item.secondary_text_css : 'text--xs-regular')}>
                                        {item.secondary_text}
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }

    function render_search_input() {
        return (
            <Form.Field>
                <Input
                    value={var_search_value}
                    onChange={onChange_search}
                    placeholder={t('Search')}
                    icon={<Icon name='search' className='icon icon__search' alt='' />}
                    className='select_radio__search'
                    type='search'
                    disabled={disabled}
                />
            </Form.Field>
        );
    }

}

SELECT_RADIO.propTypes = {
    name: propTypes.string.isRequired,
    value: propTypes.oneOfType([propTypes.string, propTypes.array]),
    options: propTypes.arrayOf(propTypes.shape({
        value: propTypes.string.isRequired,
        primary_text: propTypes.oneOfType([ propTypes.string, propTypes.element ]).isRequired,
        secondary_text: propTypes.string,
        secondary_text_css: propTypes.string,
        secondary_text_link: propTypes.string,
        disabled: propTypes.bool,
        additional_search_values: propTypes.arrayOf(propTypes.string)
    })),
    single_or_multiple: propTypes.oneOf(['SINGLE', 'MULTIPLE']),
    disabled: propTypes.bool,
    onChange: propTypes.func
};

export default SELECT_RADIO;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import 'i18n';

import MDL_ASSIGN_REQUIREMENT from '../../../requirements_list/tab_requirements/crd_requirements/mdl_assign_requirement/mdl_assign_requirement';
import CMP_MDL_CERTIFICATE from 'components/cmp_mdl_certificate/cmp_mdl_certificate';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';

export default function CRD_INDIVIDUALS({ requirement_id, requirement }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_mdl_assignrequirement_open, set_mdl_assignrequirement_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_certificate_open, set_certificate_open ] = useState(false);
    const [ var_selected_credential_id, set_selected_credential_id ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_individuals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_mdl_assignrequirement_open && var_focuselement?.id === 'btn_addindividual') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_assignrequirement_open]);

    useEffect(() => {
        if (!var_certificate_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_reqdetails_individuals').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_certificate_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_requirement_individuals(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_individuals(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_requirement_individuals(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_individuals(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_requirement_individuals(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_requirement_individuals(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-org-requirement-individuals/' + requirement_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_assignrequirement() {
        set_focuselement(document.activeElement);
        set_mdl_assignrequirement_open(true);
    }

    function onClick_individual(id) {
        navigate('/individuals/' + id);
    }

    function onClick_credential(id) {
        set_focuselement(document.activeElement);
        set_selected_credential_id(id);
        set_certificate_open(true);
    }

    function onChange_assign_requirement() {
        set_mdl_assignrequirement_open(false);
        populate_individuals();
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_reqdetails_individuals' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('All individuals')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    <Button id='btn_addindividual' className='primary' onClick={onClick_assignrequirement}>{t('Add individual')}</Button>
                </div>
            </div>

            <Table id='individuals' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname={t('Individuals')} downloadfunction={download_individuals}
                    onChange={populate_individuals}>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                        <Table.HeaderCell field='organization_name' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                        <Table.HeaderCell field='issuer_name' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                        <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                        <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {var_individuals.map(item =>
                        <Table.Row key={item.id}>
                            <Table.Cell>
                                <CMP_VERIFIED_IDENTITY_DISPLAY
                                        individual_name={item.individual_name}
                                        onClick_individual={() => onClick_individual(item.id)}
                                        verified_identity_credential_id={item.identity_verification_credential_id}
                                    />
                                </Table.Cell>
                            <Table.Cell>{item.organization_name}</Table.Cell>
                            <Table.Cell>{item.issuer_name}</Table.Cell>
                            <Table.Cell className='text--anchor' >
                                {item.credential_name &&
                                    <Button className='tertiary' onClick={() => onClick_credential(item.individualcredential_id)}>
                                        {item.credential_name}
                                        {item.credential_name && item.has_customsecurity === 'YES' &&
                                            <Icon name='lock' className='icon__lock color--gray-400' alt={t('lock icon')}/>}
                                    </Button>
                                }
                            </Table.Cell>
                            <Table.Cell>
                                <div className={'badge text--sm-medium  ' +
                                    (   (item.status === 'Valid' || item.status === 'Expiring') ?
                                        'badge--green'
                                        : (item.status === 'Invalid' || item.status === 'Missing') ?
                                            'badge--red'
                                            : item.status === 'Pending' ?
                                                'badge--yellow'
                                                :  item.status === 'Requested' ?
                                                    'badge--gray'
                                                    : 'badge--gray')}>
                                    {item.status_display}
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>

            <div className='card__footer--mobilebtns'>
                <Button id='btn_addindividual' className='primary' onClick={onClick_assignrequirement}>{t('Add individual')}</Button>
            </div>

            <MDL_ASSIGN_REQUIREMENT
                display={var_mdl_assignrequirement_open}
                onClose={() => set_mdl_assignrequirement_open(false)}
                requirement_name={requirement?.name}
                requirement_id={requirement_id}
                onChange={onChange_assign_requirement}
            />

            <CMP_MDL_CERTIFICATE
                credential_id={var_selected_credential_id}
                verifiable_credential={null}
                is_open={var_certificate_open}
                onClose={() => set_certificate_open(false)}
            />

        </div>
    );

}

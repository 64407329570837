import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import datelib from 'libs/date-lib';
import 'i18n';

import MDL_REQUEST_VERIFICATION from './mdl_request_verification/mdl_request_verification';
import CMP_IDENTITY_VERIFICATION_STATUS from 'components/cmp_identity_verification_status/cmp_identity_verification_status';


export default function CRD_IDENTITY_VERIFICATIONS({ available_credits, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_identity_verifications, set_identity_verifications ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_mdl_request_open, set_mdl_request_open ] = useState(false);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);

    const [ var_status, set_status ] = useState('ALL')
    const [ var_statuspopup_id, set_statuspopup_id ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_verification_requests({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_status]);

    useEffect(() => {
        if (!var_mdl_request_open && var_focuselement?.id === 'btn_request_verification') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_request_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_verification_requests(populateconfig, reset_message = true, focuselement_id) {
        reset_message && set_message_text(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_identity_verification_requests(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_identity_verifications(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_identity_verifications').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        } else if (var_focuselement?.id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${var_focuselement.id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_identity_verifications').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_identity_verification_requests(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_identity_verification_requests(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_identity_verification_requests(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('identity-verification', '/get-org-identity-verification-requests',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    status: var_status
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_status_toggle(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_status(status);
    }

    function onClose_status_popup() {
        set_statuspopup_id(null);
        set_message_text(null);
        set_message_type(null);
    }

    function onClick_status_popup(id) {
        set_message_text(null);
        set_statuspopup_id(id);
    }

    function onChange_updatestatus(focuselement_id) {
        set_statuspopup_id(null);
        populate_verification_requests(null, false, focuselement_id);
        onChange && onChange();
    }

    function onClick_request_verification() {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_mdl_request_open(true);
    }

    function onChange_request_verification() {
        populate_verification_requests();
        set_mdl_request_open(false);
        set_message_type('SUCCESS');
        set_message_text(t('The selected individuals have been added.'));
        onChange && onChange();
    }

    function onClick_individual(id) {
        navigate('/individuals/' + id);
    }

    function onKeyDown_individual(e, id) {
        if (e.key === 'Enter') {
            onClick_individual(id);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_identity_verifications' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_identity_verifications' className='text--xl-medium'>{t('Verifications')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button disabled={available_credits <= 0} id='btn_request_verification' className='primary' onClick={onClick_request_verification}>{t('Request verification')}</Button>
                    </div>
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='identity-verifications' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('Verifications')} downloadfunction={download_records}
                       onChange={populate_verification_requests}>

                    <Table.OptionsButtons>
                        <TOGGLE value={var_status} onChange={onClick_status_toggle} options={[{ value: 'ALL', text: t('All') }, { value: 'VERIFIED', text: t('Verified') }, { value: 'REQUESTED', text: t('Requested') }, { value: 'FAILED', text: t('Failed') }]} />
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='job_title' datatype='text' filtertype='text'>{t('TITLE')}</Table.HeaderCell>
                            <Table.HeaderCell field='verification_date' datatype='datetime' filtertype='date'>{t('VERIFICATION DATE')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_identity_verifications.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    {
                                        <CMP_IDENTITY_VERIFICATION_STATUS
                                            identity_verification={item}
                                            is_open={item.id === var_statuspopup_id}
                                            onClose={onClose_status_popup}
                                            onOpen={onClick_status_popup}
                                            onChange={onChange_updatestatus}
                                            onMessage={(type, text) => { set_message_type(type); set_message_text(text); }}
                                            has_available_credits={available_credits > 0}
                                        />
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <Button className='tertiary' onKeyDown={(e) => onKeyDown_individual(e, item.individual_id)} onClick={() => onClick_individual(item.individual_id)}>
                                        <Table.CellContentTwoLine
                                            primary_text={<div className='text--anchor'>{item.individual_name}</div>}
                                            secondary_text={item.email}
                                        />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.job_title}</Table.Cell>
                                <Table.Cell>{datelib.epoch_to_date_string(item.verification_date)}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button disabled={available_credits <= 0} id='btn_request_verification' className='primary' onClick={onClick_request_verification}>{t('Request verification')}</Button>
                </div>

                <MDL_REQUEST_VERIFICATION
                    is_open={var_mdl_request_open}
                    onClose={() => set_mdl_request_open(false)}
                    onChange={onChange_request_verification}
                    available_credits={available_credits}
                />

            </div>
        </>

    )
}
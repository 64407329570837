import React, { useEffect, useState } from 'react';
import { Button, Message, Modal, Form } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import { FORM_INPUT } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import { generate_encryption_key, hash } from 'libs/vault-lib';
import PasswordStrengthBar from 'react-password-strength-bar';

import './mdl_enablevc.css';


export default function MDL_ENABLEVC({ display, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_processing, set_processing ] = useState(false);
    const [ var_showpassword, set_showpassword ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);

    const [ var_message_type, set_message_type ] = useState(null);
    const [ var_message_text, set_message_text ] = useState(null);


    const [ var_passphrase, set_passphrase ] = useState('');
    const [ var_passscore, set_passscore ] = useState(0);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display) {
            set_passphrase('');
            set_message_text(null);
            set_errors([]);
        }
    }, [display]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function create_wallet() {
        set_processing(true);
        try {
            let hashed_passphrase = await hash(var_passphrase);
            let encryption_key = await generate_encryption_key(var_passphrase, hashed_passphrase.hash_algorithm, hashed_passphrase.iterations, hashed_passphrase.salt);
            await API_post_wallet(hashed_passphrase, encryption_key);
            onClose && onClose();
        } catch (exception) {
            set_message_type('WARNING');
            switch(exception?.response?.status) {
                case 400:
                    set_message_text(t('Please check your inputs and try again.'));
                    break;
                case 401:
                    set_message_text(t('Not Authorized'));
                    break;
                case 403:
                    set_message_text(t('Only one wallet can be setup per user'));
                    break;
                default:
                    set_message_type('ERROR');
                    set_message_text(t('There was a problem saving.  Please try again later'));
                    console.log(exception);
            }
        } finally {
            set_processing(false);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_wallet(passphrase_params, encryption_params) {
        return API.post('identifiers', '/wallet', {
            body: {
                passphrase: passphrase_params,
                encryption_key: encryption_params,
                type: 'ES256K'
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_continue() {
        let errors = [];
        // todo: better validation
        if (var_passscore < 3) {
            errors.push({property: 'passphrase', description: t("In order to proceed, your passphrase must be rated as 'Good' or stronger")});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        create_wallet();
    }

    function onChange_input(event) {
        set_errors([]);
        set_passphrase(event.target.value);
    }

    function onKeyPress(event) {
        if (event.charCode === 13) {
            onClick_continue();
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_enablevc'>
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium'>{t('We’ve upgraded your credential wallet')}</div>
                </div>
                <div className='modal__header__right'>
                    <div className='modal__header__closeicon color--primary-500'>
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                    </div>
                </div>
            </div>


            <Form className='modal__content center padding--lg'>
            {var_message_text &&
                <Message warning={var_message_type === 'WARNING'} error={var_message_type === 'ERROR'}
                    icon={<Icon name={var_message_type === 'WARNING' ? 'warning' : 'error'} className='icon' />}
                    content={var_message_text} />
            }

                <div className='content__description'>
                    <div className='text--sm-regular'>{t('We are excited to announce that we have enhanced the security of your wallet, providing an even higher level of protection for your valuable information.')}</div>
                    <div className='text--sm-regular'>{t('In addition to strengthening the security of your wallet, we have also expanded its capabilities to make it even more versatile. You can now store a wider range of credentials, giving you greater flexibility and control over your personal data.')}</div>
                    <div className='text--sm-medium'>{t('Secure your information.')}</div>
                    <div className='text--sm-regular'>{t('For added security, please create a unique passphrase that will be used only to access your wallet. This additional layer of security will help prevent any unauthorized access of your information.')}</div>
                </div>

                <FORM_INPUT
                    property='passphrase'
                    label={t('Passphrase')}
                    value={var_passphrase || null}
                    onChange={onChange_input}
                    onKeyPress={onKeyPress}
                    placeholder={t('Create a passphrase')}
                    maxLength={64}
                    type={var_showpassword ? 'text' : 'password'}
                    disabled={false}
                    errors={var_errors}
                    description=''
                    icon={<Icon name={var_showpassword ? 'eyeslash' : 'eye'} className='icon' onClick={() => set_showpassword(!var_showpassword)}/>}
                    autocomplete='new-password'
                    inputClassName='password-input'
                />

                <div className='strengthometer'>
                    <PasswordStrengthBar
                        className='password_bar'
                        password={var_passphrase}
                        scoreWords={[t('Very Weak'), t('Weak'), t('Fair'), t('Good'), t('Strong')]}
                        barColors={['#D1D5DB', '#DC2626', '#F97316', '#34D399', '#059669']}
                        shortScoreWord={t('Too Short')}
                        onChangeScore={set_passscore}
                        scoreWordClassName={`level-${var_passscore}`}
                    />
                </div>

                <div className='content__description'>
                    <div className='text--sm-medium'>{t('Your passphrase is the only way to unlock your wallet. If lost, your passphrase cannot be retrieved, so it is essential to remember it or store it safely.')}</div>
                </div>

            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_continue}>{t('Continue')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext='Processing' />

        </Modal>
    );
}
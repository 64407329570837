import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Button, Message, Modal } from 'semantic-ui-react';
import API  from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';

import './mdl_dailycheck.css';

import CRD_QUESTIONNAIRE from '../crd_questionnaire/crd_questionnaire';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';


function MDL_DAILYCHECK({ location_id, location_name, display_onsite, is_open, onClose }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_dailylimit_reached, set_dailylimit_reached ] = useState(false);
    const [ var_questionnaire, set_questionnaire ] = useState({});
    const [ var_questions, set_questions ] = useState([]);

    const [ var_consent, set_consent ] = useState(null);
    const [ var_complete, set_complete ] = useState(false);
    const [ var_passfail, set_passfail ] = useState(null);
    const [ var_quarantinedays, set_quarantinedays ] = useState(0);
    const [ var_onsite, set_onsite ] = useState('YES');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(true);
    const [ var_save_result, set_save_result ] = useState(null);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open) {
            // reset variables and re-populate
            set_dailylimit_reached(false);
            set_questionnaire({});
            set_questions([]);
            set_consent(null);
            set_save_result(null);
            set_complete(false);
            set_passfail(null);
            set_quarantinedays(0);
            set_onsite('YES');
            set_errors([]);
            set_processing(true);
            set_save_result(null);
            populate_questionnaire();
            set_mdl_confirmation_open(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_questionnaire() {
        try {
            let results = await API_get_questionnaire();
            set_dailylimit_reached(results.limit_reached === 'YES');
            if (results.limit_reached === 'NO') {
                set_questionnaire(results.questionnaire);
                set_questions(results.questions);
            }
            set_processing(false);
        } catch (e) {
            console.log(e);
        }
    }

    async function insert_dailycheck() {
        set_mdl_confirmation_open(false);
        set_processing(true);
        try {
            let check = {
                location_id,
                tz: datelib.utc_offset,
                consent: var_consent,
                status: var_passfail,
                quarantinedays: var_quarantinedays,
                onsite: display_onsite ? var_onsite : null
            };
            await API_post_dailycheck(check);
            var_consent !== 'NO' && set_save_result(var_passfail);
            var_consent === 'NO' && onClick_cancel();
        } catch (e) {
            console.log(e);
            set_errors([{description: t('There was a problem saving.  Please try again later')}]);
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_questionnaire() {
        return API.get('dailycheck', '/get-questionnaire/' + location_id, { queryStringParameters: { tz: datelib.timezone }});
    }

    function API_post_dailycheck(check) {
        return API.post('dailycheck', '/post-dailycheck', { body: check });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_submit() {
        if (var_processing) return;

        // validation
        let errors = [];
        if (var_questionnaire.guestconsent === 'YES' && var_questionnaire.individual_type === 'GUEST' && var_consent === null) {
            errors.push({property: 'consent', description: t('Please answer the guest consent question')})
        } else if (!var_complete) {
            errors.push({property: 'questions', description: t('Please answer all questions')});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        if (var_questionnaire.failwarning === 'YES' && var_passfail === 'FAIL') {
            set_mdl_confirmation_open(true);
        } else {
            insert_dailycheck();
        }
    }

    function onClick_cancel() {
        onClose && onClose(var_save_result);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer={'inverted'}
            onClose={onClick_cancel}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='cmp_dailycheck'>

            <div className='modal__header'>
                <div style={ { width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium'>{t('Daily check')}</div>
                        <div className='text--sm-regular'>{location_name + ': ' + datelib.epoch_to_date_string(Date.now() / 1000)}</div>
                    </div>
                    <div className='modal__header__right'>
                        <div className="modal__header__closeicon color--primary-500">
                            <Icon onClick={onClick_cancel} name='xicon_nocircle' className='' alt={t('Close')} />
                        </div>
                    </div>
                </div>
            </div>

            {
                var_dailylimit_reached
                    ? render_dailylimit_reached()
                    : var_questionnaire === null
                    ? null
                    : var_save_result === 'PASS'
                    ? render_complete_pass()
                    : var_save_result === 'FAIL'
                    ? render_complete_fail()
                    : render_questionnaire()
            }

            <CMP_CONFIRMATION
                display={var_mdl_confirmation_open}
                title={t('Daily check confirmation')}
                message={t(var_questionnaire.failwarningtext)}
                positive_option={t('YES')}
                negative_option={t('NO')}
                onConfirm={insert_dailycheck}
                onCancel={() => set_mdl_confirmation_open(false)}
            />

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

    function render_dailylimit_reached() {
        return (
            <>
                <div className='modal__content'>
                        <Message error
                            icon={<Icon name='error' className='icon' />}
                            header={t('You have already submitted a daily check for this assignment today.')}
                        />
                </div>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='secondary' onClick={onClick_cancel}>{t('Close')}</Button>
                    </div>
                </div>
            </>
        );
    }

    function render_questionnaire() {
        return (
            <>
                <div className='modal__content'>
                    {var_errors.length === 1 ?
                        <div className='error__wrapper'>
                            <Message error
                                icon={<Icon name='error' className='icon' />}
                                header={var_errors[0].description} />
                        </div>
                        : var_errors.length > 1 ?
                        <div className='error__wrapper'>
                            <Message error
                                icon={<Icon name='error' className='icon' />}
                                list={var_errors.map(item => item.description)} />
                        </div>
                        : null
                    }
                    {var_questionnaire.guestconsent === 'YES' && var_questionnaire.individual_type === 'GUEST' &&
                        <div className='question__wrapper'>
                            <div className='text--sm-medium'>{t('Guest Consent')}</div>
                            <div className='text--sm-medium'>{var_questionnaire.guestconsenttext}</div>
                            <TOGGLE value={var_consent} onChange={value => set_consent(value)} options={[{value: 'YES', text: t('Yes')}, {value: 'NO', text: t('No')}]} />
                            <div className='hr' />
                        </div>
                    }
                    {(var_consent === 'YES' || var_questionnaire.guestconsent !== 'YES' || var_questionnaire.individual_type !== 'GUEST') &&
                        <CRD_QUESTIONNAIRE
                            questions={var_questions}
                            indv_type={var_questionnaire.individual_type}
                            onChange_complete={set_complete}
                            onChange_passfail={set_passfail}
                            onChange_quarantinedays={set_quarantinedays}
                        />
                    }
                    {display_onsite &&
                        <div className='question__wrapper question__onsite'>
                            <div className='text--sm-medium'>{t('Where will you be working today?')}</div>
                            <TOGGLE value={var_onsite} onChange={value => set_onsite(value)} options={[{value: 'YES', text: t('Onsite')}, {value: 'NO', text: t('Remote')}]} />
                        </div>
                    }
                </div>

                <div className='modal__footer'>
                    <div className='modal__footer__text text--xs-medium'>
                        <div className='modal__footer__text__item'>{t('I understand that my answers could have a direct effect on the health and welfare of my colleagues and others onsite today.')}</div>
                        {var_questionnaire.organization_id === 'E781F687-E755-45A8-9420-63A7A0E1E55F' &&
                            <>
                                <div className='modal__footer__text__item'>{t('Privacy Notice: The Aware360 application asks everyone to respond individually “Yes” or “No” to the series of questions above, based on our criteria for attending the office or special event.  Your responses to each of these questions are not individually stored or transmitted to Nasdaq in any way.  Aware360 only transmits the aggregate outcome of your cumulative responses (“green” or “red”) to Nasdaq, which determines whether you can attend the office/event or not.')}</div>
                                <div className='modal__footer__text__item'>{t('To the extent that the information contained herein constitutes sensitive or special categories of personal data under applicable law, your submission of this form indicates that you hereby provide your explicit consent to our processing of this information for the limited purposes of providing for the health, safety, and welfare of Nasdaq employees, vendors and visitors in response to the coronavirus outbreak, which has been declared a global health emergency by the World Health Organization. The information provided may be processed by our vendors and affiliates for these purposes and, if required by applicable law or to the extent Nasdaq deems necessary in response to this virus outbreak, such information may be provided to government or other health agencies. This information will not be retained longer than necessary for these purposes. Please note, that if you do not provide your explicit consent to our processing of this information, you may be barred from visiting Nasdaq facilities during this outbreak.')}</div>
                                <div className='modal__footer__text__item'>
                                    {t('For employees / consultants: Personal data is processed in accordance with the relevant employee privacy notice posted ')}
                                    <a href='https://www.nasdaqomxintranet.com/Working+Here/Other+Policies+%26+Guidelines/Global+Policy+Directory/Associate+Privacy+Information/' target='_blank' rel='noopener noreferrer' >{t('here')}.</a>
                                </div>
                                <div className='modal__footer__text__item'>
                                    {t('For non-employees: Personal data is processed in accordance with the relevant non-employee privacy notice posted ')}
                                    <a href='https://www.nasdaq.com/privacy-statement' target='_blank' rel='noopener noreferrer' >{t('here')}.</a>
                                </div>
                            </>
                        }

                        {['FD794D9A-95AC-4DF5-B280-5C71846C3960', 'FB7CE330-CCE7-4A11-B50F-42F8AAB0B278'].includes(var_questionnaire.organization_id) &&
                            <>
                                <div className='modal__footer__text__item'>{t('By submitting this document, I acknowledge that the CANA COVID-19 expectations and provisions outlined below have been explained to me and I agree to adhere to them, specifically:')}</div>
                                <div className='modal__footer__text__item'>{t('1. All projects and work sites must provide access to hand washing or hand sanitizing stations.')}</div>
                                <div className='modal__footer__text__item'>{t('2. All frequently touched objects and surfaces must be cleaned and disinfected.')}</div>
                                <div className='modal__footer__text__item'>{t('3. Physical Distancing requirements of 2 meters/6 feet must be observed.')}</div>
                                <div className='modal__footer__text__item'>{t('4. Limit size of gatherings - conduct meetings in spaces that allow for physical distancing between meeting attendees and conduct virtual meetings whenever possible.')}</div>
                                <div className='modal__footer__text__item'>{t('5. Remind everyone to stay home when they are sick and report any confirmed COVID-19 diagnosis to CANA immediately.')}</div>
                                <div className='modal__footer__text__item'>{t('6. Communicate and adhere to basic illness prevention hygiene - post all required CANA COVID-19 signage and posters.')}</div>
                                <div className='modal__footer__text__item'>{t('I understand that the CANA has implemented policies and protocols in order to mitigate the spread of COVID-19. I have read and agree to abide by CANA’s policies and protocols for COVID-19 at all times while on CANA worksites, including head office.')}</div>
                                <div className='modal__footer__text__item'><a href='/assets/CANA COVID-19 Response Plan - 2020-12-17.pdf'>{t('CANA COVID-19 Response Plan')}</a></div>
                                <div className='modal__footer__text__item'>{t('By submitting this COVID declaration, I confirm that I have been provided access to, and understand the contents of, the COVID-19 hazard assessments pertaining to the CANA jobsite(s) where I am working, including head office as applicable, and that I agree to follow the outlined control measures at all times.')}</div>
                            </>
                        }

                    </div>

                    <div>
                        <Button className='primary' onClick={onClick_submit}>{t('Submit')}</Button>
                        <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                    </div>

                </div>
            </>
        );
    }

    function render_complete_pass() {
        return (
            <>
                <div className='modal__content'>
                    <div className='dailycheck__result'>
                        <Icon name='checkmark' className='icon color--green-500 icon__result' />
                        <div className='text--xl-medium'>{t('Daily check passed')}</div>
                        <div className='text--md-regular'>{t('You are cleared to work today')}</div>
                    </div>
                </div>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='secondary' onClick={onClick_cancel}>{t('Close')}</Button>
                    </div>
                </div>
            </>
        );
    }

    function render_complete_fail() {
        return (
            <>
                <div className='modal__content'>
                    <div className='dailycheck__result'>
                        <Icon name='error' className='icon icon_error icon__result' />
                        <div className='text--xl-medium'>{t('Daily check failed')}</div>
                        <div className='text--md-regular'>{t('You are not cleared to work today')}</div>
                    </div>
                </div>
                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='secondary' onClick={onClick_cancel}>{t('Close')}</Button>
                    </div>
                </div>
            </>
        );
    }
}

MDL_DAILYCHECK.propTypes = {
    location_id: propTypes.string,
    location_name: propTypes.string,
    display_onsite: propTypes.bool,
    is_open: propTypes.bool.isRequired,
    onClose: propTypes.func
};

export default MDL_DAILYCHECK;

import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import datelib from 'libs/date-lib';
import ReactJson from 'react-json-view';
import { useTranslation } from 'react-i18next';
import { decodeJwt } from 'jose';
import Icon from 'components/cmp_icon';
import 'i18n';

import './vp_viewer.css';


function VP_VIEWER ({ verifiable_presentations, var_focuselement, set_focuselement }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const [ var_selected_index, set_selected_index ] = useState(-1);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (verifiable_presentations && verifiable_presentations.length === 1) {
            set_selected_index(0);
        } else {
            set_selected_index(-1);
        }
        document.getElementById(var_focuselement)?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verifiable_presentations]);



    //  async functions ------------------------------------------------------------------------------------------------

    function decode_presentation(presentation) {
        let decoded;
        if (presentation.includes('@context')) {
            // JSON-LD
            decoded = JSON.parse(presentation);
        } else {
            // JWT
            decoded = decodeJwt(presentation);
            for (let index = 0; index < decoded.vp.verifiableCredential.length; index++) {
                decoded.vp.verifiableCredential[index] = decodeJwt(decoded.vp.verifiableCredential[index]);
            }
        }
        return decoded;
    }

    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_item(index) {
        set_selected_index(index === var_selected_index ? -1 : index);
    }

    function onKeyDown_item(e, index) {
        if (e.key === 'Enter') {
            onClick_item(index);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <div id='vpviewer__wrapper'>

            <div className='vpviewer__header'>

                <div className='text--xl-medium'>
                    {t('Verifiable presentations')}
                </div>
                <div className='text--sm-regular'>
                    {`${t('A verifiable presentation is a digital proof of having certain credentials or attributes without revealing the actual details.')} ${t('It allows you to share only necessary information for a specific purpose, giving you control over your personal data and privacy.')}`}
                </div>

            </div>

            {verifiable_presentations.map((item, index) =>
                <div key={index} className='vpviewer__item_wrapper' aria-expanded={index === var_selected_index}>
                    <div className='vpviewer__item' onClick={() => onClick_item(index)} onKeyDown={(e) => onKeyDown_item(e, index)} tabIndex='0'>
                        <div className='vpviewer__item_label'>
                            <div className='text--sm-medium'>{item.title}</div>
                            <div className='text--xs-regular'>{`${t('Submitted')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}`}</div>
                        </div>
                        {index === var_selected_index
                            ? <Icon name='chevron_up' className='accordion__chevron--right color--primary-500' />
                            : <Icon name='chevron_down' className='accordion__chevron--right color--primary-500' />
                        }
                    </div>
                    {index === var_selected_index &&
                        <div className='vpviewer__item_detail json__wrapper'>
                            <ReactJson
                                style={{ display: 'block' }}
                                name={false}
                                enableClipboard={false}
                                quotesOnKeys={false}
                                displayDataTypes={false}
                                theme='bright:inverted'
                                displayObjectSize={false}
                                collapsed={5}
                                src={decode_presentation(item.verifiable_presentation)}
                            />
                        </div>
                    }
                </div>
            )}

        </div>
    );
}

VP_VIEWER.propTypes = {
    verifiable_presentations: propTypes.arrayOf(propTypes.shape({
        submitted_date: propTypes.number,
        title: propTypes.string,
        verifiable_presentation: propTypes.string
    })).isRequired
};

export default VP_VIEWER;
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import { CHECKBOX, SELECT_RADIO, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import { useTranslation } from 'react-i18next';
import 'i18n';


export default function MDL_ADD_PERMISSION({ is_open, permission_options, onClose, onChange, credential, onChange_credential }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const [ var_permissions, set_permissions ] = useState({ 'read': true });
    const [ var_errors, set_errors ] = useState([]);
    const [ var_selected_individual, set_selected_individual ] = useState(null);
    const [ var_individual_options, set_individual_options ] = useState([]);
    const [ var_selected_role, set_selected_role ] = useState(null);
    const [ var_role_options, set_role_options ] = useState([]);
    const [ var_role_individual, set_role_individual ] = useState('ROLE');
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------


    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal])

    useEffect(() => {
        if (is_open) {
            // reset and populate options
            set_modal(document.querySelector('#mdl_add_permission'));
            set_selected_individual(null);
            set_selected_role(null);
            set_errors([]);
            set_permissions({ 'read': true });
            set_role_individual('ROLE');
            populate_roles();
            populate_individuals();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        var_role_individual === 'ROLE' ? set_selected_individual(null) : set_selected_role(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_role_individual]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals() {
        try {
            let results = await API_get_individuals_for_credential_security();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: item.individual_name,
                secondary_text: item.email,
                disabled: is_assigned(item.id),
                permission: item.permission
            }));
            set_individual_options(transformed);
        } catch(e) {
            console.log(e);
        }
    }

    async function populate_roles() {
        try {
            let results = await API_get_roles_for_credential_security();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: t(item.permission),
                secondary_text: is_assigned(item.id) ? t('Already has access') : '',
                disabled: is_assigned(item.id),
                permission: item.permission
            }));
            set_role_options(transformed);
        } catch(e) {
            console.log(e);
        }
    }

    function is_assigned(selected) {
        return credential.permissions && credential.permissions.some(item => item.id === selected);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individuals_for_credential_security() {
        return API.get('security', '/get-individuals-for-credential-security');
    }

    function API_get_roles_for_credential_security() {
        return API.get('security', '/get-roles-for-credential-security');
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function save_permissions() {
        let errors = [];
        // validation
        if (Object.keys(var_permissions).length === 0) {
            errors.push({property: 'updatepermission', description: t('Please select at least one permission')});
        }
        if (var_role_individual === 'ROLE' && !var_selected_role) {
            errors.push({property: 'role_options', description: t('Please select at least one role')});
        }
        if (var_role_individual === 'INDIVIDUAL' && !var_selected_individual) {
            errors.push({property: 'individual_options', description: t('Please select at least one individual')});
        }
        set_errors(errors);
        if (errors.length > 0) return;
        onChange_credential({...credential, permissions: [...credential.permissions, {
            access: JSON.stringify(permission_options.filter(item => var_permissions[item.code]).map(item => item.code)),
            id: var_role_individual === 'ROLE'
                    ? var_selected_role
                    : var_selected_individual,
            role_individual: var_role_individual === 'ROLE'
                    ? var_role_options.find(item => item.value === var_selected_role).primary_text
                    : var_individual_options.find(item => item.value === var_selected_individual).primary_text,
            accesstarget: var_role_individual === 'ROLE' ? 'PERMISSION' : 'INDIVIDUAL',
            permission: var_role_individual === 'ROLE'
                    ? var_role_options.find(item => item.value === var_selected_role).permission
                    : var_individual_options.find(item => item.value === var_selected_individual).permission
        }]});
        onChange && onClose();
    }

    function onChange_permissions(permission_item) {
        let permissions = {...var_permissions};
        permissions[permission_item] = !permissions[permission_item];
        set_permissions(permissions);
    }

    function onChange_selected(value) {
        set_permissions({ 'read': true });
        var_role_individual === 'ROLE' ? set_selected_role(value) : set_selected_individual(value);
        set_errors([]);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_add_permission'
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_credential_permissions'>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_credential_permissions'>{t('Permissions')}</div>
                    <div className='text--sm-regular'>{t('Specify the level of control a role or individual has for this credential.')}</div>
                </div>
            </div>

            <Form className='modal__content' tabIndex='0' id='form_cr8credential_permissions' aria-labelledby='hdr_credential_permissions'>
                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={t('There are some errors with your inputs.')}
                    />
                }
                <FORM_TOGGLE
                    label={t('Who')}
                    value={var_role_individual}
                    options={[{value: 'ROLE', text: t('Role')}, {value: 'INDIVIDUAL', text: t('Individual')}]}
                    onChange={set_role_individual}
                />
                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>{t('Select')}</div>
                    <Form.Field>
                    {var_role_individual === 'ROLE'
                        ? <SELECT_RADIO name='role_options' value={var_selected_role} options={var_role_options} single_or_multiple='SINGLE' onChange={onChange_selected} aria_label={t('Select the role you would like to specify permissions for.')} />
                        : <SELECT_RADIO name='individual_options' value={var_selected_individual} options={var_individual_options} single_or_multiple='SINGLE' onChange={onChange_selected} aria_label={t('Select the individuals you would like to specify permissions for.')} />
                    }
                    </Form.Field>
                </div>
                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>{t('Permissions')}</div>
                    <div className='detailsgroup__value column text--sm-regular' aria-label={t('Select the permissions for the role or individual.')} role='group'>
                    {permission_options.map(item =>
                        <CHECKBOX name='updatepermission' key={'checkbox-' + item.code} label={item.description} value={item.code} checked={var_permissions[item.code]} onChange={item.code !== 'read' ? () => onChange_permissions(item.code) : null}
                            disabled={item.code === 'read' ||
                            (var_role_options.some(item => (item.value === var_selected_role) && (item.permission === 'Manager')) && item.code !== 'view attachment') ||
                            (var_role_options.some(item => (item.value === var_selected_role) && (item.permission === 'Admin' || item.permission === 'Global Admin')) && item.code === 'security') ||
                            (var_individual_options.some(item => (item.value === var_selected_individual) && (item.permission === 'Manager')) && item.code !== 'view attachment') ||
                            (var_individual_options.some(item => (item.value === var_selected_individual) && (item.permission === 'Admin' || item.permission === 'Global Admin')) && item.code === 'security')} />
                    )}
                    </div>
                </div>
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={save_permissions}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>
        </Modal>
    )
}
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import form_helper from 'libs/form-lib';
import lookup from 'libs/lookup-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import { FORM_INPUT, SELECT, INPUT } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import CRD_IDENTITY_VERIFICATION from './crd_identity_verification/crd_identity_verification';

import './tab_profile.css';

import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';

export default function TAB_PROFILE({ activetab, reset_profile, reset_authentication }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_individual, set_individual ] = useState('');
    const [ var_updated_individual, set_updated_individual ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'PROFILE') {
            populate_individual();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individual() {
        set_errors([]);
        let record = await API_get_indv_details();
        set_individual(record);
    }

    async function save_individual(individual) {
        set_processing(true);
        try {
            await API_put_indv_details(individual);
            if((var_individual.firstname !== individual.firstname) || (var_individual.lastname !== individual.lastname)){
                reset_authentication();
                reset_profile();
            }
            set_individual({...var_updated_individual});
            set_mode('VIEW');
        } catch (exception) {
            console.log(exception);
            set_save_error('An error has occurred');
        } finally {
            set_processing(false);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_indv_details() {
        return API.get('indv', '/get-indv-details');
    }

    function API_put_indv_details(individual) {
        return API.put('indv', '/put-indv-details', { body: individual });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_updated_individual({...var_individual});
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_errors([]);
        set_save_error(null);
        set_mode('VIEW');
    }

    function onClick_save() {
        if (var_processing) return;

        let individual = {...var_updated_individual};
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(individual.firstname)) {
            errors.push({ property: 'firstname', description: t('First name is required.') });
        }
        if (!form_helper.validate_required_string(individual.lastname)) {
            errors.push({ property: 'lastname', description: t('Last name is required.') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(individual, 'lastname');
        form_helper.cleanse_string_property(individual, 'firstname');
        form_helper.cleanse_string_property(individual, 'phone');
        form_helper.cleanse_string_property(individual, 'country');
        form_helper.cleanse_string_property(individual, 'province');
        form_helper.cleanse_string_property(individual, 'city');

        set_updated_individual(individual);

        save_individual(individual);
    }

    function onChange_input(event) {
        let individual = {...var_updated_individual};
        individual[event.target.name] = event.target.value;
        set_updated_individual(individual);
    }

    function onChange_country(e, { value }) {
        let individual = { ...var_updated_individual };
        if (individual.country !== value) {
            individual.province = null;
        }
        individual.country = value;
        set_updated_individual(individual);
    }

    function onChange_region(e, { value }) {
        let individual = { ...var_updated_individual };
        individual.province = value;
        set_updated_individual(individual);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <CRD_IDENTITY_VERIFICATION />

            <div className='card rounded-lg shadow-sm' id='tab__profile'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_profile' className='text--xl-medium'>{t('Your information')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        {var_mode === 'VIEW' &&
                            <Button className='secondary' onClick={onClick_edit} disabled={var_individual.ssoenabled === 'YES'}>{t('Edit')}</Button>
                        }
                    </div>
                </div>

                {var_mode === 'VIEW' ?
                    <>
                    <div className='card__content'>
                        {render_view_item('name', 'Name')}
                        {render_view_item('phone', 'Phone number')}
                        {render_view_item('email', 'Email')}
                        {render_view_item('location', 'Location')}
                    </div>
                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={onClick_edit} disabled={var_individual.ssoenabled === 'YES'}>{t('Edit')}</Button>
                    </div>
                    </>
                : var_mode === 'EDIT' ?
                    <>
                    <Form className='card__content' id='form_edit_profile'>
                        {var_save_error &&
                            <Message error header={t(var_save_error)} />
                        }
                        {var_errors.length > 0 &&
                            <Message error
                                icon={<Icon name='error' className='icon' />}
                                header={t('There are some errors with your inputs')}
                            />
                        }

                        <div className='detailsgroup'>
                            <div className='detailsgroup__label text--sm-medium'>{t('Name')}</div>
                            <Form.Field className='detailsgroup__value name'>

                                <div className='name_wrapper'>
                                    <div className='text--sm-medium'>{t('First name')}</div>
                                    <INPUT
                                        property='firstname'
                                        label={t('First name')}
                                        value={var_updated_individual.firstname}
                                        onChange={onChange_input}
                                        placeholder={t('First name')}
                                        maxLength={100}
                                        disabled={false}
                                        errors={var_errors}
                                    />
                                </div>

                                <div className='name_wrapper'>
                                    <div className='text--sm-medium'>{t('Last name')}</div>
                                    <INPUT
                                        property='lastname'
                                        label={t('Last name')}
                                        value={var_updated_individual.lastname}
                                        onChange={onChange_input}
                                        placeholder={t('Last name')}
                                        maxLength={100}
                                        disabled={false}
                                        errors={var_errors}
                                    />
                                </div>
                            </Form.Field>
                        </div>

                        <FORM_INPUT
                            property='phone'
                            label={t('Phone number')}
                            value={var_updated_individual.phone}
                            onChange={onChange_input}
                            placeholder={t('Phone')}
                            maxLength={100}
                            disabled={false}
                            errors={var_errors}
                            description=''
                        />

                        <FORM_INPUT
                            property='email'
                            label={t('Email')}
                            value={var_updated_individual.email}
                            onChange={onChange_input}
                            placeholder={t('Email')}
                            maxLength={100}
                            disabled={true}
                            errors={var_errors}
                            description=''
                        />

                        <div className='detailsgroup'>
                            <div className='detailsgroup__label text--sm-medium'>{t('Location')}</div>
                            <Form.Field className='detailsgroup__value'>

                                <div className='text--sm-medium'>{t('Country')}</div>
                                <SELECT
                                    property='country'
                                    label={t('Country')}
                                    value={var_updated_individual.country || ''}
                                    onChange={onChange_country}
                                    placeholder={t('Select country')}
                                    options={lookup.country.get_country_options(t('Select region'))}
                                    disabled={false}
                                    errors={var_errors}
                                />

                                <div className='text--sm-medium' style={{ marginTop: '1rem' }}>{t('Province/State')}</div>
                                <SELECT
                                    property='province'
                                    label={t('Province/State')}
                                    value={var_updated_individual.province || ''}
                                    onChange={onChange_region}
                                    placeholder={t('Select region')}
                                    options={lookup.country.get_region_options(var_updated_individual.country, t('Select region'))}
                                    disabled={!var_updated_individual.country}
                                    errors={var_errors}
                                />

                                <div className='text--sm-medium' style={{ marginTop: '1rem' }}>{t('City')}</div>
                                <INPUT
                                    property='city'
                                    placeholder={t('City')}
                                    value={var_updated_individual.city}
                                    onChange={onChange_input}
                                    maxLength={100}
                                    disabled={false}
                                    errors={var_errors}
                                    description=''
                                />
                            </Form.Field>
                        </div>
                    </Form>

                    <div className='card__footer'>
                        <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                        <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                    </div>

                    <Processing display={var_processing} processingtext={t('Processing')} />
                    </>
                :
                    null
                }
            </div>
        </>
    )

    function render_view_item(property, name) {
        if (!var_individual) return null;
        return (
            <div className='detailsgroup'>
                <div className='detailsgroup__label text--md-medium'>{t(name)}</div>
                {property === 'location'
                    ? <div className='detailsgroup__value' style={{ flexDirection: 'column' }}>
                            <div className='text--sm-regular'>{var_individual.city}{var_individual.city && var_individual.province ? ', ' : ''}{var_individual.province}</div>
                            {(var_individual.country && var_individual.country !== '') && <div className='text--sm-regular'>{var_individual.country}</div>}
                      </div>
                    : property === 'email'
                    ? <div className='detailsgroup__value text--sm-regular truncate'>{var_individual.email || ''}</div>
                    : property === 'name'
                    ? <div className='detailsgroup__value name_field text--sm-regular'>
                        <CMP_VERIFIED_IDENTITY_DISPLAY
                            individual_name={`${var_individual.firstname || ''} ${var_individual.lastname || ''}`}
                            verified_identity_credential_id={var_individual.identity_verification_credential_id}
                        />
                    </div>
                    : <div className='detailsgroup__value text--sm-regular'>{var_individual[property] || ''}</div>
                }
            </div>
        );
    }

}

import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import API from 'libs/api-lib';
import { Button, Modal, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';
import Processing from 'components/cmp_processing';
import Icon from 'components/cmp_icon';
import 'i18n';

import './cmp_docviewer.css';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

function MDL_DOCVIEWER({
    targetid, classification,
    isreport = false, token = null, credential_id = null,
    is_open = false, onClose,
    show_acknowledge = false, onAcknowledgement }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_file_info, set_file_info ] = useState({});

    const [ var_filetype, set_filetype ] = useState('');
    const [ var_processing, set_processing ] = useState(true);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_error, set_error ] = useState(null);
    const [ var_bookmarks_open, set_bookmarks_open ] = useState(false);
    const [ var_rotate, set_rotate ] = useState(0);
    const [ var_viewerHeight, set_viewerHeight ] = useState(null);
    const [ var_viewerWidth, set_viewerWidth ] = useState(null);
    const [ var_scanpollcount, set_scanpollcount ] = useState(0);
    const [ var_img_load_error, set_img_load_error ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_document_loaded, set_document_loaded ] = useState(false);

    const toolbarPluginInstance = toolbarPlugin({ selectionModePlugin: {
        selectionMode: SelectionMode.Hand,
    } });
    const { Toolbar } = toolbarPluginInstance;
    const bookmarkPluginInstance = bookmarkPlugin();
    const { Bookmarks } = bookmarkPluginInstance;

    const MAX_POLL_COUNT = 100;


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), .rpv-selection-mode__grab, img#docPreviewImg');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            const document_viewer = document.getElementsByClassName('rpv-selection-mode__grab')[0];
            document_viewer && document_viewer.setAttribute('tabIndex', '0');

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (!is_open) {
            set_modal(null);
            set_document_loaded(false);
            set_file_info({});
            set_filetype(null);
            set_processing(true);
            set_ready(false);
            set_error(null);
            set_bookmarks_open(false);
            set_rotate(0);
            set_viewerHeight(null);
            set_viewerWidth(null);
            set_img_load_error(false);
        }
        is_open && targetid && get_file_info(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open, targetid]);

    useEffect(() => {
        if (var_scanpollcount > 0 && var_scanpollcount < MAX_POLL_COUNT) {
            setTimeout(() => get_file_info(false), 1500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_scanpollcount]);

    useEffect(() => {
        if (is_open && targetid && (var_document_loaded || ['INFECTED', 'SCANNING'].includes(var_file_info.scan_status))) {
            set_modal(document.querySelector('#mdl_document'));
        }
    }, [is_open, targetid, var_document_loaded, var_file_info.scan_status]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function get_file_info(firstload) {
        try {
            let file_info = await API_get_file_info();
            set_file_info(file_info);
            const file_ext = file_info.display_name.split('.').pop().toLowerCase();

            if (file_ext === 'pdf') {
                set_filetype('pdf');
            } else if (['png', 'jpeg', 'jpg', 'gif', 'bmp'].includes(file_ext)) {
                set_viewerHeight(window.screen.height * .55);
                set_filetype('image');
            } else {
                set_error(t('A preview of this document could not be created due its file format. To view it, please click the download button.'));
            }

            if (file_info.scan_status !== 'SCANNING' && file_info.scan_status !== 'INFECTED') {
                if (!file_info.url) {
                    set_error(t('Unable to open the file.  Please try again later.'));
                } else {
                    set_ready(true);
                }
            }

            set_scanpollcount(file_info.scan_status === 'SCANNING' ? (firstload ? 1 : (var_scanpollcount + 1)) : 0);
        } catch (exception) {
            console.log(exception);
            if (exception.response && exception.response.status === 410) {
                set_error(t('You do not have access to view this file.'));
            } else if (exception.response && exception.response.status === 404) {
                set_error(t('The file could not be found, it may have been deleted.'));
            }
        }
        set_processing(false);
    }

    async function S3_download_file() {
        let file_info = await API_get_file_info();
        if (file_info.url) {
            const a = document.createElement('a');
            a.href = file_info.url;
            a.click();
        }

        set_processing(false);
        set_ready(true);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_file_info() {
        return API.get('storage', isreport
        ? credential_id
            ? ('/get-token-file-info/' + classification + '/' + token + '/' +  targetid + '/' + credential_id)
            : ('/get-token-file-info/' + classification + '/' + token + '/' +  targetid)
        : ('/get-file-info/' + classification + '/' + targetid));
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_download() {
        set_processing(true);
        set_ready(false);
        S3_download_file();
    }

    function onClick_bookmarks() {
        set_bookmarks_open(!var_bookmarks_open);
    }

    function onClick_zoomin_image() {
        let imageWidth = document.getElementById('docPreviewImg').offsetWidth;
        document.getElementById('docPreviewImg').removeAttribute('height');
        set_viewerWidth(imageWidth + 20);
    }

    function onClick_zoomout_image() {
        let imageWidth = document.getElementById('docPreviewImg').offsetWidth;
        document.getElementById('docPreviewImg').removeAttribute('height');
        set_viewerWidth(imageWidth - 20);
    }

    function onClick_rotate() {
        if (var_filetype === 'image') {
            document.querySelector('#docPreviewImg').style.transform = `rotate(${var_rotate + 90}deg)`;
        }
        set_rotate((var_rotate + 90) % 360);
    }

    function onClick_search() {
        window.setTimeout(() => {
            // try to find the search input
            let element = document.getElementsByClassName('rpv-search__popover');
            if (element.length > 0) {
                element = element[0].getElementsByClassName('rpv-core__textbox');
                if (element.length > 0) {
                    // if found, add the enterkeyhint attribute
                    element[0].setAttribute('enterkeyhint', 'enter');
                }
            }
        }, 100);
    }

    function onError_loadpdf(error) {
        switch (error.name) {
            case 'InvalidPDFException':
                set_error(t('The document is invalid or corrupted'));
                break;
            case 'MissingPDFException':
                set_error(t('The document is missing'));
                break;
            default:
                console.log(error)
                set_error(t('Cannot load the document'));
                break;
        }
        return null;
    }

    async function onClick_acknowledge() {
        try {
            if (show_acknowledge) {
                set_processing(true);
                set_ready(false);
                await onAcknowledgement();
            }
        } catch (e) {
            console.log(e);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            className='cmp__docviewer'
            id='mdl_document'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_document'
        >
            <div className='modal__header'>
                <div style={ { width: '100%', wordBreak: 'break-word' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium' id='hdr_document'>{var_file_info.display_name}</div>
                    </div>
                </div>
                <div className='document__controls'>
                    {var_filetype === 'pdf' ? renderPdfToolbar() : var_filetype === 'image' ? renderImageToolbar() : renderOtherToolbar()}
                </div>
            </div>
            <div className='modal__content' tabIndex='0'>

                {var_error &&
                    <div className='error__wrapper'>
                        <Message error
                            icon={<Icon name='error' className='icon' alt='' />}
                            header={var_error}
                        />
                    </div>
                }

                {var_file_info.scan_status === 'INFECTED'
                    ?   <div className='error__wrapper'>
                            <Message error
                                icon={<Icon name='error' className='icon' alt='' />}
                                header={t('A preview of this document is not currently available. The system has detected a virus signature in this file.')}
                            />
                        </div>
                    : var_file_info.scan_status === 'SCANNING' && var_scanpollcount < MAX_POLL_COUNT
                    ?   <div className='error__wrapper'>
                            <Message error
                                icon={<Icon name='error' className='icon' alt='' />}
                                header={t('A preview of this document is not currently available. This file is currently being scanned for viruses by the system. Please wait...')}
                            />
                        </div>
                    : var_file_info.scan_status === 'SCANNING'
                    ?   <div className='error__wrapper'>
                            <Message error
                                icon={<Icon name='error' className='icon' alt='' />}
                                header={t('A preview of this document is not currently available. This file is currently being scanned for viruses by the system.')}
                            />
                        </div>
                    : var_filetype === 'image'
                    ?   <figure id='image_preview'>
                            <div id='imgPreviewContainer'>
                            {var_img_load_error
                            ?   <div className='error__wrapper'>
                                    <Message error
                                    icon={<Icon name='error' className='icon' alt='' />}
                                    header={t('Failed to load image file.')}
                                    />
                                </div>
                            :
                                <img
                                    onError={(e)=>{e.target.onerror = null;set_img_load_error(true)}}
                                    id='docPreviewImg'
                                    height={var_viewerHeight}
                                    width={var_viewerWidth}
                                    style={{maxWidth: '100%'}}
                                    className='contain'
                                    src={var_file_info.url}
                                    alt={t('image preview')}
                                    tabIndex='0'
                                    onLoad={() => set_document_loaded(true)}
                                />

                            }
                            </div>
                        </figure>
                    : var_filetype === 'pdf'
                    ?   <div className='pdf_preview' id='docPreviewPdf'>
                            <Worker workerUrl='/pdf/pdf.worker.js'>
                                <div className='pdf-container'>
                                    <div className={'pdf-bookmarks' + (var_bookmarks_open ? ' open' : '')}>
                                        <Bookmarks />
                                    </div>
                                    <div className={'pdf-viewer' + (var_bookmarks_open ? ' bookmarks-open' : '')}>
                                        <Viewer
                                            fileUrl={var_file_info.url}
                                            renderError={onError_loadpdf}
                                            plugins={[
                                                toolbarPluginInstance,
                                                bookmarkPluginInstance
                                            ]}
                                            onDocumentLoad={() => set_document_loaded(true)}
                                            onError={() => set_document_loaded(true)}
                                            on
                                        />
                                    </div>
                                </div>
                            </Worker>
                        </div>
                    : var_file_info.not_found
                    ? <div></div>
                    : null
                }

                {var_filetype === 'pdf' && renderPdfPagination()}

            </div>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    {targetid && classification !== 'credential' && show_acknowledge && var_ready &&
                        <Button className='primary' onClick={onClick_acknowledge}>{t('Acknowledge')}</Button>
                    }
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    )

    function renderPdfPagination() {
        return (<Toolbar>
            {(slots) => {
                const {
                    CurrentPageLabel,
                    NumberOfPages,
                    GoToNextPage,
                    GoToPreviousPage
                } = slots;
                return (
                    <div className='page-control'>
                        <CurrentPageLabel>
                            {(props) => (
                                <>
                                    {props.numberOfPages > 1 &&
                                    <>
                                        <Button.Group>
                                            <GoToPreviousPage>
                                                {(previousPageProps) => (
                                                    <Button disabled={(previousPageProps.isDisabled || !var_ready)} onClick={previousPageProps.onClick}>
                                                        <Icon name='chevron_left' className='page-arrow' alt={t('Previous page')} />
                                                    </Button>
                                                )}
                                            </GoToPreviousPage>
                                            <div className='page-label'><CurrentPageLabel /> / <NumberOfPages /></div>
                                            <GoToNextPage>
                                                {(nextPageProps) => (
                                                    <Button disabled={(nextPageProps.isDisabled || !var_ready)} onClick={nextPageProps.onClick}>
                                                        <Icon name='chevron_right' className='page-arrow' alt={t('Next page')} />
                                                    </Button>
                                                )}
                                            </GoToNextPage>
                                        </Button.Group>
                                    </>
                                    }
                                </>
                            )}
                        </CurrentPageLabel>
                    </div>
                );
            }}
        </Toolbar>
        );
    }

    function renderPdfToolbar() {
        return (<Toolbar>
            {(slots) => {
                const {
                    Rotate,
                    ShowSearchPopover,
                    ZoomIn,
                    ZoomOut
                } = slots;
                return (
                    <div>
                        <Button.Group>
                            <ZoomIn>
                                {(props) => (
                                    <Button disabled={props.isDisabled || !var_ready} onClick={props.onClick}>
                                        <Icon name='zoom_in' className='tablecontrol--primary-500' alt={t('Zoom in')} />
                                    </Button>
                                )}
                            </ZoomIn>
                            <ZoomOut>
                                {(props) => (
                                    <Button disabled={props.isDisabled || !var_ready} onClick={props.onClick}>
                                        <Icon name='zoom_out' className='tablecontrol--primary-500' alt={t('Zoom out')} />
                                    </Button>
                                )}
                            </ZoomOut>
                            <Rotate>
                                {(props) => (
                                    <Button disabled={props.isDisabled || !var_ready} onClick={props.onClick}>
                                        <Icon name='rotate' className='tablecontrol--primary-500' alt={t('Rotate clockwise')} />
                                    </Button>
                                )}
                            </Rotate>
                            <ShowSearchPopover>
                                {(props) => (
                                    <Button className='btn__pdf_search' disabled={props.isDisabled || !var_ready} onClick={(e) => { props.onClick(e); onClick_search(); }}>
                                        <Icon name='search' className='tablecontrol--primary-500' alt={t('Search')} />
                                    </Button>
                                )}
                            </ShowSearchPopover>
                            <Button disabled={!var_ready} onClick={onClick_bookmarks}>
                                <Icon name='bookmark' className='tablecontrol--primary-500' alt={t('Bookmarks')} />
                            </Button>
                            {var_file_info.scan_status === 'CLEAN' &&
                                <Button disabled={!var_ready} onClick={onClick_download}>
                                    <Icon name='download' className='tablecontrol--primary-500' alt={t('Download')} />
                                </Button>
                            }
                        </Button.Group>
                    </div>
                );
            }}
        </Toolbar>
        );
    }

    function renderImageToolbar() {
        return (
            <Button.Group>
                <Button disabled={!var_ready} onClick={onClick_zoomin_image}>
                    <Icon name='zoom_in' className='tablecontrol--primary-500' alt={t('Zoom in')} />
                </Button>
                <Button disabled={!var_ready} onClick={onClick_zoomout_image}>
                    <Icon name='zoom_out' className='tablecontrol--primary-500' alt={t('Zoom out')} />
                </Button>
                <Button disabled={!var_ready} onClick={onClick_rotate}>
                    <Icon name='rotate' className='tablecontrol--primary-500' alt={t('Rotate clockwise')} />
                </Button>
                {var_file_info.scan_status === 'CLEAN' &&
                    <Button disabled={!var_ready} onClick={onClick_download}>
                        <Icon name='download' className='tablecontrol--primary-500' alt={t('Download')} />
                    </Button>
                }
            </Button.Group>
        );
    }

    function renderOtherToolbar() {
        return (
            <>
                {var_file_info.scan_status === 'CLEAN' &&
                    <Button.Group>
                        <Button disabled={!var_ready} onClick={onClick_download}>
                            <Icon name='download' className='tablecontrol--primary-500' alt={t('Download')} />
                        </Button>
                    </Button.Group>
                }
            </>
        );
    }
}

MDL_DOCVIEWER.propTypes = {
    targetid: propTypes.string,
    classification: propTypes.oneOf(['credential', 'credential-revision', 'document', 'form', 'orgcredential', 'orgcredential-revision', 'prequalifier-document']),
    isreport: propTypes.bool,
    is_open: propTypes.bool,
    onClose: propTypes.func.isRequired,
    show_acknowledge: propTypes.bool,
    onAcknowledgement: propTypes.func
};

export default MDL_DOCVIEWER;
import React, { useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import 'i18n';

import './crd_security.css'



export default function CRD_SECURITY({ credential_id, has_security, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_updated_security, set_updated_security ] = useState(has_security);
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_processing, set_processing ] = useState(false);
    const [ var_restrict_warning, set_restrict_warning ] = useState('');


    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------

    async function update_security() {
        if (has_security === var_updated_security) {
            set_save_error(t('Cannot save with no changes.'));
            return;
        }
        set_processing(true);
        try {
            await API_put_has_customsecurity();
            onChange(var_updated_security);
        } catch(e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
        set_mode('VIEW');
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_has_customsecurity() {
        return API.put('security', '/put-credential-has-customsecurity/' + credential_id + '/' + var_updated_security);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_restrict_warning();
        set_save_error(null);
        set_updated_security(has_security);
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_save_error(null);
        set_mode('VIEW');
        set_updated_security(has_security);
        set_restrict_warning(null);
    }

    function onChange_has_security(value) {
        set_save_error(null);
        (value === 'NO' && has_security === 'YES')
            ? set_restrict_warning(t('Note: All currently defined permissions will be deleted. This action can not be undone.'))
            : set_restrict_warning(null);
        set_updated_security(value);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_creddetails_security' tabIndex='0'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_creddetail_security'>{t('Credential security')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    {var_mode === 'VIEW' &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {var_mode === 'VIEW' ?
                <>
                    <div id='credential_details_security' className='card__content'>
                        <FORM_DETAILSGROUP label={t('Restrict access')} value={has_security ? t(has_security.substring(0, 1) + has_security.substring(1).toLowerCase()) : ''} />
                    </div>
                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={() => onClick_edit()}>{t('Edit')}</Button>
                    </div>

                </>
                :
                <>
                    <Form className='card__content' id='form_creddetail_security' aria-labelledby='hdr_creddetail_security'>
                        {var_save_error &&
                            <Message error header={var_save_error}/>
                        }
                        <FORM_TOGGLE
                            label={t('Restrict access')}
                            value={var_updated_security}
                            options={[{value: 'YES', text: t('Yes')}, {value: 'NO', text: t('No')}]}
                            onChange={onChange_has_security}
                            description={t('Restrict who has access to view, manage and assign this credential.')}
                        />
                        {var_restrict_warning &&
                        <div className='field__feedback error'>
                            <div className='description color--red-600'>{var_restrict_warning}</div>
                        </div>
                        }
                    </Form>
                    <div className='card__footer'>
                        <Button className='primary' onClick={update_security}>{t('Save')}</Button>
                        <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                    </div>
                </>
            }

            <Processing display={var_processing} processingtext={t('Processing')} />

        </div>
    );
}
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import 'i18n';

import MDL_VERIFICATION_TEMPLATE_CREDENTIAL from './mdl_verification_template_credential/mdl_verification_template_credential';
import MDL_TEST_TEMPLATE from './mdl_test_template/mdl_test_template';

function CRD_CREDENTIALS({ activetab, verification_template, verification_template_credentials, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_mdl_verification_template_credential_open, set_mdl_verification_template_credential_open ] = useState(false);
    const [ var_verification_template_credential_id, set_verification_template_credential_id ] = useState('');
    const [ var_delete_verification_template_credential_open, set_delete_verification_template_credential_open ] = useState(false);
    const [ var_delete_verification_template_credential_id, set_delete_verification_template_credential_id ] = useState('');

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'credential' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    const [ var_processing, set_processing ] = useState(false);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_test_template_open, set_test_template_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'CREDENTIALS' && verification_template.id) {
            populate_credentials();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, verification_template]);

    useEffect(() => {
        if (!var_mdl_verification_template_credential_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_mdl_verification_template_credential_open]);

    useEffect(() => {
        if (!var_delete_verification_template_credential_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_delete_verification_template_credential_open]);

    useEffect(() => {
        if (!var_test_template_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_test_template_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function delete_verification_template_credential() {
        set_processing(true);
        try {
            await API_delete_verification_template_credential();
            onChange && onChange(var_focuselement?.id);
        } catch(e) {
            console.log(e);
            set_message_text('Could not delete verification template credential');
            set_message_type('ERROR');
        } finally {
            set_processing(false);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_delete_verification_template_credential() {
        return API.del('verifiable-credentials', '/delete-verification-template-credential/' + var_delete_verification_template_credential_id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_add_credential() {
        set_message_text('');
        set_message_type('');
        set_focuselement(document.activeElement);
        set_mdl_verification_template_credential_open(true);
    }

    function onClick_verification_template_credential(verification_template_credential_id) {
        set_message_text('');
        set_message_type('');
        set_focuselement(document.activeElement);
        set_verification_template_credential_id(verification_template_credential_id);
        set_mdl_verification_template_credential_open(true);
    }

    function onClick_delete_verification_template_credential(verification_template_credential_id) {
        set_message_text('');
        set_message_type('');
        set_focuselement(document.activeElement);
        set_delete_verification_template_credential_id(verification_template_credential_id);
        set_delete_verification_template_credential_open(true);
    }

    async function onConfirm_delete_verification_template_credential() {
        set_delete_verification_template_credential_open(false);
        await delete_verification_template_credential();
        onChange && await onChange();
        populate_credentials();
    }

    async function onChange_verification_template_credential() {
        onChange && await onChange();
        populate_credentials();
    }

    function populate_credentials() {
        set_totalrows(verification_template_credentials.length);
        set_ready(true);
    }

    function onClick_test_template() {
        set_focuselement(document.activeElement);
        set_test_template_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_verification_template_credentials' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Credentials for verification')}</div>
                        <div className='text--sm-regular'>{t('Add credentials and choose the attributes you want to verify.')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_add_credential' className='primary' disabled={!['ACTIVE', 'DRAFT'].includes(verification_template.status)} onClick={onClick_add_credential}>{t('Add credential')}</Button>
                        <Button id='btn_test_template' className='secondary' disabled={verification_template.status === 'ARCHIVE' || verification_template_credentials?.length === 0} onClick={onClick_test_template}>{t('Test template')}</Button>
                    </div>
                </div>

                <div className='card__content'>
                    {var_message_text &&
                        <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                            icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                            header={var_message_text} />
                    }

                    {verification_template_credentials.length === 0 && (verification_template.status === 'DRAFT' || verification_template.status === 'ARCHIVE') &&
                        <Message warning icon={<Icon name='warning' className='icon' />} header={t('Credentials must be added to this verification template before it can be published.')} />
                    }

                    <Table id='credentials'
                        loading={false} ready={var_ready}
                        loadingerror={false} lockcolumns={0}
                        refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig}
                        populatefilterfunction={() => null}
                        downloadname={t('Credentials')}
                        downloadfunction={() => null}
                        onChange={populate_credentials}
                        hide_tablecontrols={true}>

                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                                <Table.HeaderCell field='issuer' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {verification_template_credentials.map(item =>
                                <Table.Row key={item.id}>
                                    <Table.Cell>
                                        <Button className='tertiary text--anchor' onClick={() => onClick_verification_template_credential(item.id)}>{item.credential_name}</Button>
                                    </Table.Cell>
                                    <Table.Cell className='cell__icon--left--right'>
                                        {item.issuer_name}
                                        <Button
                                            className='tertiary'
                                            onClick={() => onClick_delete_verification_template_credential(item.id)}
                                            disabled={verification_template.status === 'ARCHIVE' || (verification_template.status === 'ACTIVE' && verification_template_credentials?.length === 1)}>
                                                <Icon name='delete' className='color--primary-500'/>
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>

                    <div className='card__footer--mobilebtns'>
                        <Button id='btn_mobile_add_credential' className='primary' disabled={!['ACTIVE', 'DRAFT'].includes(verification_template.status)} onClick={onClick_add_credential}>{t('Add credential')}</Button>
                        <Button id='btn_mobile_test_template' className='secondary' disabled={verification_template_credentials?.length === 0} onClick={onClick_test_template}>{t('Test template')}</Button>
                    </div>
                </div>
                <Processing display={var_processing} processingtext={t('Processing')} />
            </div>

            <MDL_VERIFICATION_TEMPLATE_CREDENTIAL
                display={var_mdl_verification_template_credential_open}
                onClose={() => { set_mdl_verification_template_credential_open(false); set_verification_template_credential_id(''); }}
                onChange={onChange_verification_template_credential}
                verification_template={verification_template}
                verification_template_credential_id={var_verification_template_credential_id}
            />

            <MDL_TEST_TEMPLATE
                is_open={var_test_template_open}
                onClose={() => set_test_template_open(false)}
                token={verification_template?.token}
            />

            <CMP_CONFIRMATION display={var_delete_verification_template_credential_open} title={t('Delete credential')} message={
                <div style={{flexDirection: 'column'}}>
                    <div>{t('Are you sure you want to delete the {{credential_name}} credential? This action cannot be undone.', { credential_name: verification_template_credentials.find(item => item.id === var_delete_verification_template_credential_id)?.credential_name })}</div>
                </div>
            } positive_option={t('Delete')} negative_option={t('Cancel')} onConfirm={onConfirm_delete_verification_template_credential} onCancel={() => set_delete_verification_template_credential_open(false)} />
        </>
    );

}

CRD_CREDENTIALS.propTypes = {
    activetab: propTypes.string.isRequired,
    verification_template: propTypes.any,
    onChange: propTypes.func
};

export default CRD_CREDENTIALS;
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';

import CMP_IDENTITY_VERIFICATION from 'components/cmp_identity_verification/cmp_identity_verification';

export default function CRD_IDENTITY_VERIFICATION() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_request_id, set_request_id ] = useState(null);
    const [ var_default_phone_number, set_default_phone_number ] = useState(null);
    const [ var_identity_verificaton_modal_open, set_identity_verification_modal_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_identity_verification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_identity_verification() {
        let data = await API_get_individual_request();
        set_request_id(data?.request_id);
        set_default_phone_number(data?.default_phone_number);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individual_request() {
        return API.get('identity-verification', '/get-individual-request');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_verifyIdentity() {
        set_identity_verification_modal_open(true);
    }

    function onClose_verifyIdentity(status /* INITIAL, COMPLETE, CANCEL */) {
        if (status !== 'INITIAL') {
            // if they started the process then the current request can no longer be used.  Set to null and check if there is another request for the user
            set_request_id(null);
            populate_identity_verification();
        }
        // set focus to the content as the button may not exist anymore
        document.getElementById('content').focus();
        set_identity_verification_modal_open(false);
    }



    // RENDER APP ======================================================================================================

    return !var_request_id ? null : (
        <>
            <div className='card rounded-lg shadow-sm' id='tab__profile_identity_verification'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_profile' className='text--xl-medium'>{t('Verify your identity')}</div>
                        <div className='text--sm-regular'>{t('Confirming your identity adds an extra layer of protection against identity theft, impersonation, and other fraudulent activities.')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button className='primary' onClick={onClick_verifyIdentity} disabled={var_identity_verificaton_modal_open}>{t('Verify identity')}</Button>
                    </div>
                </div>
                <div className='card__footer--mobilebtns'>
                    <Button className='primary' onClick={onClick_verifyIdentity} disabled={var_identity_verificaton_modal_open}>{t('Verify identity')}</Button>
                </div>
            </div>

            <CMP_IDENTITY_VERIFICATION display={var_identity_verificaton_modal_open} onClose={onClose_verifyIdentity} request_id={var_request_id} phone_number={var_default_phone_number} />
        </>
    );

}

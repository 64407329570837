import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import 'i18n';

import MDL_ENABLEVC from './mdl_enablevc/mdl_enablevc'

import './crd_enablevc.css';



export default function CRD_ENABLEVC ({ onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_enablevc_open, set_enablevc_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!var_enablevc_open && var_focuselement?.id === 'btn_getstarted') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_enablevc_open]);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_learnmore() {
        set_focuselement(document.activeElement);
        set_enablevc_open(true)
    }

    function onClose_enablevc() {
        set_enablevc_open(false);
        onClose && onClose();
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div id='crd_enablevc' className='card rounded-lg shadow-sm'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('We’ve enhanced the security of your wallet.')}</div>
                    </div>
                </div>

                <div className='card__content'>
                    <div className='content__description'>{t('We have made updates to enhance the security of your wallet to provide you with an even higher level of protection. Click ‘Get started’ and follow the instructions on the screen to upgrade now.')}</div>
                    <div className='card__content__btn_wrapper'>
                        <Button id='btn_getstarted' className='secondary' onClick={onClick_learnmore}>{t('Get started')}</Button>
                    </div>
                </div>
            </div>

            <MDL_ENABLEVC
                display={var_enablevc_open}
                onClose={onClose_enablevc}
            />

        </>
    );
}
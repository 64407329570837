import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from 'libs/api-lib';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';

import './download.css';
import 'i18n';


export default function DOWNLOAD() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    let params = useParams();

    const [ var_status, set_status ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        get_bulkdownload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function get_bulkdownload() {
        try {
            let result = await API_get_bulkdownload();
            set_status(result.status.trim());
            return result;
        } catch (exception) {
            set_status('ERROR');
            console.log(exception);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_bulkdownload() {
        return API.get('storage', '/get-bulkdownload/' + params.token);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_download() {
        // re-retrieve data to make sure link is fresh
        let result = await get_bulkdownload();
        if (result.status.trim() === 'SUCCESS') {
            const a = document.createElement('a');
            a.href = result.url;
            a.click();
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='download__wrapper'>
            <div id='content' tabIndex='0'>
                <div className='download__content rounded-md'>
                    {(var_status === 'NOT FOUND' || var_status === 'EXPIRED')
                        ? t('A valid or active download link could not be found. Please create a new one.')
                        : var_status === 'ERROR'
                        ? t('An unexpected error occurred. Please try again.')
                        : var_status === 'SUCCESS'
                        ? <Button className='primary' onClick={onClick_download}><Icon name='download' className='icon__download' alt={t('download icon')} />{t('Click to download your file')}</Button>
                        : null
                    }
                </div>
            </div>
        </div>
    );
}
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';

import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import CRD_DETAILS from './crd_details/crd_details';
import CRD_SETTINGS from './crd_settings/crd_settings';
import CRD_SECURITY from './crd_security/crd_security';



export default function CREDENTIAL_CREATE() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_step, set_step ] = useState('DETAILS');
    const [ var_credential, set_credential ] = useState({
        has_prequalifier: 'NO',
        indv_or_org: 'INDV',
        status: 'ACTIVE',
        verifiable_credential: 'NO',
        proof_type: 'JWT',
        expires: 'NO',
        restrict_claims: 'NO',
        auto_verify: 'NO',
        issuedrequired: 'NO',
        credential_number_required: 'NO',
        has_customsecurity: 'NO',
        credit_value: '1',
        has_credit_value: 'NO',
        contains_pii: 'NO',
        permissions: [] });

    const [ var_processing, set_processing ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_info();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_info() {
        try {
            let info = await API_get_create_catalogue_credential_info();
            set_credential({ ...var_credential, ...info });
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_create_catalogue_credential_info() {
        return API.get('credentials', '/get-create-catalogue-credential-info');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }, { path: '/org/credentials', text: t('Credentials') }]}
                current_page_text={t('Create credential')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background2'>
                    <h1 className='display--sm-regular'>{t('Create credential')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <CRD_DETAILS
                    step={var_step}
                    onChange_step={set_step}
                    credential={var_credential}
                    onChange_credential={set_credential}
                    onChange_processing={set_processing}
                    display_processing={var_processing}
                />

                <CRD_SETTINGS
                    step={var_step}
                    onChange_step={set_step}
                    credential={var_credential}
                    onChange_credential={set_credential}
                    display_processing={var_processing}
                />

                <CRD_SECURITY
                    step={var_step}
                    onChange_step={set_step}
                    credential={var_credential}
                    onChange_credential={set_credential}
                    onChange_processing={set_processing}
                    display_processing={var_processing}
                />

            </div>
        </div>
    )
};
import React, { useEffect } from 'react';

import CRD_DETAILS from './crd_details/crd_details';
import CRD_APPROVED from './crd_approved/crd_approved';
import CRD_REJECTED from './crd_rejected/crd_rejected';



export default function TAB_DETAILS({ requirement, onChange_requirement }) {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <>
            <CRD_DETAILS requirement={requirement} onChange_requirement={onChange_requirement} />
            <CRD_APPROVED requirement={requirement} />
            <CRD_REJECTED requirement={requirement} />
        </>
    )
};

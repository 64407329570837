import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import datelib from 'libs/date-lib';
import API from 'libs/api-lib';
import { Modal, Button } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import 'i18n';

import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';



export default function MDL_COUNT_DETAILS({ display, onClose, individual }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'credential_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_docviewer_id, set_docviewer_id ] = useState(null);
    const [ var_docviewer_open, set_docviewer_open ] = useState(false);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display) {
            set_refreshtable(!var_refreshtable);
            populate_credentials({ ...default_populateconfig, limit: 10, sortby: 'credential_name' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('mdl_content').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials(populateconfig) {
        set_message_text(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_holder_credentials(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_credentials(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_holder_credentials(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_credentials(limit, offset) {
        try {
            let {sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_holder_credentials(limit, offset, sortby, sortorder, filter)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_holder_credentials(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('credentials', '/get-org-holder-credentials/' + individual.id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_credential(id, has_readaccess){
        if (has_readaccess === 'YES') {
            set_message_text(null);
            navigate('/org/credentials/' + id);
        }
    }

    function onClick_document(id) {
        set_focuselement(document.activeElement);
        set_docviewer_id(id);
        set_docviewer_open(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer={'inverted'}
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium'>{individual.individual_name}</div>
                    <div className='text--sm-regular'>{t('Credentials')}</div>
                </div>
                <div className='modal__header__right'>
                    <div className="modal__header__closeicon color--primary-500">
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                    </div>
                </div>
            </div>

            <div id='mdl_content' className='modal__content' tabIndex='0'>
                <Table id='credentials' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('credentials')} downloadfunction={download_credentials}
                       onChange={populate_credentials}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell field='issued' datatype='date' filtertype='date'>{t('ISSUED')}</Table.HeaderCell>
                            <Table.HeaderCell field='expires' datatype='date' filtertype='date'>{t('EXPIRES')}</Table.HeaderCell>
                            <Table.HeaderCell field='submittedfilename' datatype='text' filtertype='text'>{t('ATTACHMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='verification_status_display' datatype='text' filtertype='option'>{t('VERIFICATION')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_credentials.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className={item.has_readaccess !== 'NO' ? ' text--anchor' : ''}>
                                    <Button className='tertiary' onClick={() => onClick_credential(item.credential_id, item.has_readaccess)} >
                                        {item.credential_name} {item.has_customsecurity === 'YES' ? <Icon name='lock' className='icon__lock color--gray-400' alt={t('add icon')} /> : ''}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{datelib.epoch_to_date_string(item.issued)}</Table.Cell>
                                <Table.Cell>{datelib.epoch_to_date_string(item.expires)}</Table.Cell>
                                <Table.Cell>
                                    {item.submittedfilename &&
                                        <Button className='tertiary' onClick={() => onClick_document(item.id)}>
                                            <div ><Icon name='attachment' className='icon__attachment' /></div>
                                        </Button>
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    {item.verification_status_display &&
                                        <div className={'badge text--sm-medium ' +
                                            (   item.verification_status === 'Valid'
                                                ? 'badge--green'
                                                : item.verification_status === 'Invalid'
                                                ? 'badge--red'
                                                : (item.verification_status === 'Pending'  || item.verification_status === 'In progress')
                                                ? 'badge--yellow'
                                                : item.verification_status === 'Expired'
                                                ? 'badge--gray'
                                                : 'badge--gray')}>
                                            {item.verification_status_display}
                                        </div>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>
            </div>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>

            {/***** MODAL: DOC VIEWER *******************************************************************************/}

            <MDL_DOCVIEWER
                targetid={var_docviewer_id}
                classification='credential'
                is_open={var_docviewer_open}
                onClose={() => set_docviewer_open(false)}
            />

            {/***** END MODAL: DOC VIEWER ***************************************************************************/}

        </Modal>
    );

}
import API from 'libs/api-lib';
import { Message } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Icon from 'components/cmp_icon';
import Logo from 'components/cmp_logo';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Processing from 'components/cmp_processing';
import QRCode from 'qrcode.react';

import CRD_CREDENTIALS from './crd_credentials/crd_credentials';
import CRD_CREDENTIALSATTACHMENTS from './crd_credentialsAttachments/crd_credentialsAttachments';

import './profile.css';


export default function REPORT_PROFILE() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const params = useParams();

    const [ var_linkstatus, set_linkstatus ] = useState('');
    const [ var_processing, set_processing ] = useState(true);
    const [ var_indv, set_indv ] = useState({});
    const [ var_credentials, set_credentials] = useState([]);

    let url = window.location.href;
    let arr = url.split('/');
    const hostname = arr[0] + '//' + arr[2];
    const qrURL = hostname + '/report/profile/' + params.token;


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_report_linkstatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_report_linkstatus() {
        set_processing(true);
        try {
            let results = await API_get_profile();
            set_linkstatus('ACTIVE');
            set_indv(results.individual);
            set_credentials(results.credentials)
        } catch (e) {
            console.log(e);
            if (e.response.status === 410) {
                set_linkstatus('INACTIVE');
            } else if (e.response.status === 404) {
                set_linkstatus('NOTFOUND');
            }
        } finally {
            set_processing(false);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_profile() {
        return API.get('external-report', '/get-profile/' + params.token, {
            queryStringParameters: {
                tz: datelib.timezone
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================


    return (
        <div className='report_content' id='pg_reportprofile'>

            <div id='content' tabIndex='0'>

                {!var_processing &&
                    <div className='report_wrapper'>
                        {var_indv && var_linkstatus === 'ACTIVE' &&
                            <div className='profile_report_card'>
                                <div className='profile_report_header'>
                                    <div className='profile_report_header_top'>
                                        <div className='profile_report_detail'>
                                            {var_indv.organization_logo && var_indv.organization_logo !== '' &&
                                                <div className='profile_report_org_info'>
                                                    <div className='profile_report_logo'>
                                                        <Logo targetid={var_indv.organization_id} classification='logo' filename={var_indv.organization_logo} alt={t('organization logo')} />
                                                    </div>
                                                </div>
                                            }
                                            <div className='profile_report_title'>
                                                <div className='report_title'>{var_indv.individual_name}</div>
                                            </div>
                                            <ul className='profile_report_info'>
                                                {var_indv.organization_name && var_indv.organization_name !== '' &&
                                                    <li>
                                                        <div className='profile_header_info_th'>{t('ORGANIZATION')}</div>
                                                        <div className='profile_header_info_td'>{var_indv.organization_name}</div>
                                                    </li>
                                                }
                                                {var_indv.email && var_indv.email !== '' &&
                                                    <li>
                                                        <div className='profile_header_info_th'>{t('EMAIL')}</div>
                                                        <div className='profile_header_info_td'>{var_indv.email}</div>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                        <div className='report_qrcode'>
                                            <QRCode
                                                className='qrcode'
                                                id='qr-gen'
                                                value={qrURL}
                                                size={120}
                                                level={'H'}
                                                includeMargin={false}
                                            />
                                        </div>
                                    </div>
                                    <CRD_CREDENTIALS
                                        credentials={var_credentials}
                                        token={params.token} />
                                    <CRD_CREDENTIALSATTACHMENTS
                                        credentials={var_credentials}
                                        token={params.token} />
                                </div>
                            </div>
                        }
                    </div>
                }

                {!var_processing && (var_linkstatus === 'INACTIVE' || var_linkstatus === 'NOTFOUND') &&
                    <div className='report_wrapper'>
                        <div>
                            <Message
                                error
                                icon={<Icon name='error' className='icon' />}
                                header={t('A valid or active report profile could not be found. Please check the link again or the individual you received it from.')}
                            />
                        </div>
                    </div>
                }

                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>

        </div>
    );
};

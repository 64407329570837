import React, { useState } from 'react';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import { encrypt, unwrap } from 'libs/vault-lib';
import form_helper from 'libs/form-lib';

import { FORM_INPUT } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import { Button, Message, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';

import QrReader from 'react-qr-reader';

export default function CONTENT_SCAN_1({ onChangeContent, onChange, onClose, onChangeProcessing }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_url, set_url ] = useState('');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing_scan, set_processing_scan ] = useState(false);

    const [ var_facing_mode, set_facing_mode ] = useState('environment');

    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------

    async function claim_credential(url) {
        let passphrase_params = auth.passphrase_parameters?.[0];
        let kek = Window.$kek;
        if (!passphrase_params || !kek) {
            set_errors([{description: t('There was a problem saving.  Please try again later')}]);
            return;
        }

        onChangeProcessing(true);
        try {
            let results = await API_claim_credential(url, passphrase_params.did_uri);
            if (results.vc) {
                let validated = await API_validate_jwt(results.vc);
                if (validated?.verified) {
                    let expiration = validated?.payload?.exp;
                    let { key, key_algorithm, wrapping_algorithm } = JSON.parse(sessionStorage.getItem('encryption_key'));
                    let encryption_key = await unwrap(key, key_algorithm, kek, wrapping_algorithm);
                    let encrypted = await encrypt(results.vc, encryption_key);
                    await API_put_wallet_credential(results.individual_credential_id, encrypted, results.kid, expiration);
                    onChange && onChange();
                }
            }
        } catch (e) {
            set_errors([{description: t('There was a problem saving.  Please try again later')}]);
            console.log(e);
        }
        onChangeProcessing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_claim_credential(url, subject_did) {
        return API.post('integrations', '/openidvc-post-credential/', {
            body: {
                url,
                subject_did
            }
        });
    }

    function API_put_wallet_credential(id, credential, kid, expiration_timestamp) {
        return API.put('verifiable-credentials', `/put-wallet-credential/${id}`, {
            body: {
                vc: credential.ciphertext,
                iv: credential.iv,
                encryption_algorithm: credential.encryption_algorithm,
                kid,
                expiration_timestamp
            }
        });
    }

    function API_validate_jwt(jwt) {
        return API.post('verifiable-credentials', '/validate-jwt', {
            body: {
                jwt
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        set_errors([]);
        set_url(event.target.value);
    }

    async function onClick_validate(url) {
        if (var_processing_scan) return;
        set_processing_scan(true);

        try {
            let errors = [];
            let url_object, request_uri;

            url = url ?? var_url;

            if (!form_helper.validate_required_string(url)) {
                errors.push({ property: 'url', description: t('URL is required') });
            } else {
                try {
                    url_object = new URL(url);
                } catch (e) {
                    errors.push({property: 'url', description: t('A valid URL is required')});
                }
            }

            if (errors.length === 0) {
                if (url_object.protocol === 'openid-vc:') {
                    try {
                        request_uri = new URL(url_object.searchParams.get('request_uri'));
                        if (!request_uri.pathname.includes('issuanceRequests')) errors.push({property: 'url', description: t('request_uri parameter is invalid')});
                    } catch (e) {
                        errors.push({property: 'url', description: t('request_uri parameter is missing')});
                    }
                } else {
                    errors.push({property: 'url', description: t('URL protocol is unsupported')});
                }
            }

            set_errors(errors);
            if (errors.length > 0) return;

            await claim_credential(url_object);
            // set_content('SCAN_2')
        } finally {
            set_processing_scan(false);
        }
    }

    function onScan_QR(data) {
        if (data) {
            set_url(data);
            onClick_validate(data);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <Form className='modal__content center padding--lg '>
            {var_errors.length > 0 && !var_errors[0].property &&
                <Message error
                    icon={<Icon name='error' className='icon' alt={t('alert icon')} />}
                    content={var_errors[0].description} />
            }
            <div className='content__description'>
                <div className='text--sm-regular'>{t('Align the QR code within the frame to scan.')}</div>
            </div>

            <div className='scanframe' onClick={() => { var_facing_mode === 'environment' ? set_facing_mode('user') : set_facing_mode('environment'); }}>
                <QrReader
                    delay={500}
                    onScan={(result) => onScan_QR(result)}
                    onError={(e) => {console.log(e);}}
                    style={{ width:'100%' }}
                    facingMode={var_facing_mode}
                />
            </div>

            <div className='content__description'>
                <div className='text--sm-regular'>{t('Or enter the QR code URL below.')}</div>
            </div>
            <div className='validate__wrapper'>
                <FORM_INPUT
                    property='url'
                    value={var_url || null}
                    onChange={onChange_input}
                    placeholder={t('URL')}
                    maxLength={1024}
                    disabled={false}
                    errors={var_errors}
                    description=''
                />
                <Button type='submit' className='primary' onClick={()=>onClick_validate()}>{t('Validate')}</Button>
            </div>

        </Form>
    );

}

import React, { useState, useEffect, createRef } from 'react';
import propTypes from 'prop-types';
import { Form, Dropdown, Popup } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';

function SELECT({ property, value, onChange, onOpen, onClose, placeholder, options, disabled = false, use_popup = true, errors = [], aria_label }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_is_open, set_is_open ] = useState(false);
    const [ var_search, set_search ] = useState(null);
    const [ var_scroll_event, set_scroll_event ] = useState(null);
    const options_ref = createRef();
    let error = errors.find(item => item.property === property);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        function onScroll(e) {
            set_scroll_event(e);
        }
        document.addEventListener('scroll', onScroll, true);
        return () => { document.removeEventListener('scroll', onScroll, true); }
    }, []);

    useEffect(() => {
        if (!var_is_open || !var_scroll_event?.target?.parentElement?.className) return;

        if (!var_scroll_event.target.parentElement.className.includes('dropdown_popup')) {
            set_is_open(false);
            onClose && onClose();
            let focus = document.querySelector(':focus');
            if (focus) {
                focus.blur();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ var_scroll_event, var_is_open ]);


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_dropdown(e, props) {
        onChange && onChange(e, props);
        let focus = document.querySelector(':focus');
        if (!focus) {
            set_is_open(false);
            onClose && onClose();
        }
    }

    function onFocus() {
        if (!disabled) {
            set_scroll_event(null);
            set_is_open(true);
            onOpen && onOpen();
            if (!value && options.some(item => !item.disabled)) {
                onChange && onChange(null, { name: property, value: options.find(item => !item.disabled).value });
            }
        }
    }

    async function onBlur(c, d) {
        await new Promise(resolve => setTimeout(resolve, 150));
        set_is_open(false);
        onClose && onClose();
        set_search(null);
    }

    function onSearchChange(e, { searchQuery }) {
        set_search(searchQuery);
    }

    function onKeyDown(e) {
        if (['Enter', 'Escape'].includes(e.key)) {
            let focus = document.querySelector(':focus');
            if (focus) {
                focus.blur();
            }
        } else if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
            options_ref.current.handleKeyDown(e);
        }
    }


    // RENDER ==========================================================================================================

    return (
        <Form.Field>
            { use_popup ?
                <Popup
                    trigger={
                        <Dropdown selection
                            id={property}
                            name={property}
                            value={value || ''}
                            onChange={onChange}
                            placeholder={placeholder || ''}
                            options={options}
                            disabled={disabled}
                            fluid={false}
                            search={!disabled}
                            icon={<Icon name='chevron_down' className='icon' />}
                            error={!!error}
                            open={false}
                            onSearchChange={onSearchChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            aria-label={aria_label}
                        />
                    }
                    content={
                        <Form.Field>
                            <Dropdown selection
                                ref={options_ref}
                                className='dropdown_popup'
                                id={property}
                                name={property}
                                value={value || ''}
                                onChange={onChange_dropdown}
                                placeholder={placeholder || ''}
                                options={options}
                                fluid={false}
                                search={!disabled}
                                icon={<Icon name='chevron_down' className='icon' />}
                                error={!!error}
                                open={true}
                                searchQuery={var_search || ''}
                            />
                        </Form.Field>
                    }
                    open={var_is_open}
                    position='bottom left'
                    on='click'
                />
            :
                <Dropdown selection
                    id={property}
                    name={property}
                    value={value || ''}
                    onChange={onChange}
                    placeholder={placeholder || ''}
                    options={options}
                    disabled={disabled}
                    fluid
                    search={!disabled}
                    icon={<Icon name='chevron_down' className='icon' />}
                    error={!!error}
                    aria-label={aria_label}
                />
            }
            {error &&
                <div className='field__feedback error'>
                    <div className='text--sm-regular'>{error.description}</div>
                </div>
            }
        </Form.Field>
    );
}

SELECT.propTypes = {
    property: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    onOpen: propTypes.func,
    onClose: propTypes.func,
    placeholder: propTypes.string,
    options: propTypes.array,
    disabled: propTypes.bool,
    use_popup: propTypes.bool,
    errors: propTypes.array,
    aria_label: propTypes.string
};

export default SELECT;
import React from 'react';

import './crd_details.css';
import { useTranslation } from 'react-i18next';
import 'i18n';

export default function CRD_DETAILS({var_detail, report_info}) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');


    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='profile_header'>
                <div className='profile_header_left'>
                    <div className='profile_header_title'>{var_detail.individual_name}</div>
                </div>
                <ul className='profile_header_info'>
                    {report_info && report_info.name && report_info.name !== '' &&
                        <li>
                                <div className='profile_header_info_th'>{t('ORGANIZATION')}</div>
                            <div className='profile_header_info_td'>{!report_info ? '' : report_info.organization_name}</div>
                        </li>
                    }
                </ul>
            </div>
        </>
    )
}

import React, { useState, useEffect } from 'react';
import auth from 'libs/auth-lib';

import CMP_REQUIREMENTS_INDIVIDUAL from './cmp_requirements_individual';
import CMP_REQUIREMENTS_ADMIN from './cmp_requirements_admin';

export default function TAB_REQUIREMENTS_ADMIN({ assignment, onChange, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_view, set_view ] = useState('LOADING');
    const [ var_focuselement, set_focuselement ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'REQUIREMENTS' && assignment.location_id) {
            if (auth.has_access(assignment, 'INDV-REQ', 'requirement')) {
                set_view('INDIVIDUAL');
            } else {
                set_view('ALL');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, assignment.location_id]);


    // RENDER APP ======================================================================================================

    return (
        <>
            {var_view === 'INDIVIDUAL'
                ? <CMP_REQUIREMENTS_INDIVIDUAL assignment={assignment} onChange={onChange} activetab={activetab} onChangeView={() => set_view('ALL')} var_focuselement={var_focuselement} set_focuselement={set_focuselement} />
                : var_view === 'ALL'
                ? <CMP_REQUIREMENTS_ADMIN assignment={assignment} onChange={onChange} activetab={activetab} onChangeView={() => set_view('INDIVIDUAL')} var_focuselement={var_focuselement} set_focuselement={set_focuselement} />
                : null
            }
        </>
    );
};
import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';

function TOGGLE({ disabled = false, value, onChange, options }) {

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_button(button_value, button_id) {
        button_value !== value && onChange && onChange(button_value, button_id);
    }


    // RENDER ==========================================================================================================

    return (
        <Button.Group>
            {options.map((item, index) =>
                <Button type='button' disabled={disabled} key={uuidv4()} className={value === item.value ? 'active' : ''} id={`toggle_${index + 1}`} onClick={() => onClick_button(item.value, `toggle_${index + 1}`)}>{item.text}</Button>
            )}
        </Button.Group>
    );
}

TOGGLE.propTypes = {
    disabled: propTypes.bool,
    value: propTypes.oneOfType([propTypes.string, propTypes.bool]),
    onChange: propTypes.func,
    options: propTypes.arrayOf(propTypes.exact({
        value: propTypes.oneOfType([propTypes.string, propTypes.bool]),
        text: propTypes.string
    })).isRequired,
    activeToggle: propTypes.string
};

export default TOGGLE;
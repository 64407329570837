import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Popup, Form } from 'semantic-ui-react';
import { CHECKBOX, TEXTAREA } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';
import Processing from 'components/cmp_processing';
import form_helper from 'libs/form-lib';
import 'i18n';


export default function CMP_REQUIREMENT_ACCEPTANCE({ requirement, is_open, onClose, can_accept, locationrequirement_id, onOpen, onChange, onMessage, manage_history_access }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_popup, set_popup ] = useState(null);
    const [ var_acceptreject_status, set_acceptreject_status ] = useState(null);
    const [ var_reject_comments, set_reject_comments ] = useState(null);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_display_reject_comments, set_display_reject_comments ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const POPUP_ID = (`mdl_requirement_status_${requirement?.id}`);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open) {
            set_popup(document.querySelector(`#${POPUP_ID}`));
            set_display_reject_comments(false);
            set_acceptreject_status(null);
            set_reject_comments(null);
            set_focuselement(document.activeElement);
        } else if (!is_open && var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_popup) {
            //  create list of focusable elements within the popup
            let elements = var_popup.querySelectorAll('form, input[type="checkbox"]:not([disabled]), textarea:not([disabled]), button:not([disabled])');
            let firstelement = elements[0];
            let lastelement = elements[elements.length - 1];

            //  set focus to first element within the popup
            firstelement.focus({ preventScroll: true });

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_popup.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_popup]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function onClick_acceptreject() {
        set_processing(true);
        onMessage(null, null);
        if (var_acceptreject_status === null) {
            onMessage('ERROR', t('Please select a status'));
            set_processing(false);
            return;
        }
        if (var_acceptreject_status.startsWith('REJECT') && !form_helper.validate_required_string(var_reject_comments)) {
            set_errors([{ property: 'reject_comments', description: t('Please enter a reason for rejecting the requirement') }]);
            set_processing(false);
            return;
        }

        set_processing(true);
        try {
            await API_post_acceptance();
            onChange && onChange(var_focuselement?.id);
        } catch (e) {
            onMessage('ERROR', t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_acceptance() {
        return API.post('requirements', '/post-acceptance', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: {
                locationrequirement_id,
                individualrequirement_id: requirement.individualrequirement_id,
                status: var_acceptreject_status,
                comments: var_acceptreject_status.startsWith('REJECT') ? form_helper.cleanse_string(var_reject_comments) : null
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_status() {
        set_focuselement(document.activeElement);
    }

    function onClick_return() {
        set_errors([]);
        set_reject_comments(null);
        set_display_reject_comments(false);
        set_acceptreject_status(null);
    }

    function onChange_reject_comments(e) {
        set_errors([]);
        set_reject_comments(e.target.value);
    }

    // RENDER APP ======================================================================================================

    return (
        (!can_accept || !requirement.credential_name)

        ?   <div className='badge text--sm-medium badge--yellow' tabIndex='0'>
                {t('Pending')}
            </div>
        :
        <Popup
            trigger={
                <Button id={`btn_${POPUP_ID}`} onClick={onClick_status} type='button' className='badge text--sm-medium badge--yellow'>
                    {t('Pending')}
                    <Icon name='badge_dropdown' className='badge__icon' />
                </Button>
            }
            content={
                is_open
                ?   <div className='badge__popup shadow-lg rounded-md' id={POPUP_ID} aria-modal='true' role='dialog' aria-labelledby={`hdr_${POPUP_ID}`}>
                        <div id={`hdr_${POPUP_ID}`} className='text--md-medium' aria-label={t('Update requirement status')}>
                            {!var_display_reject_comments
                            ? t('Update status')
                            : var_acceptreject_status === 'REJECT'
                            ? t('Reject this time only')
                            : var_acceptreject_status === 'REJECT-ALWAYS'
                            ? t('Reject always')
                            : t('Reject issuer always')}
                        </div>
                        <Form tabIndex='0' aria-labelledby={`hdr_${POPUP_ID}`}>
                            {!var_display_reject_comments ?
                                <>
                                    <div className='badge__popup__option'>
                                        <CHECKBOX radio name='approvereject'
                                                label={requirement.self_verified === 'YES' ? t('Accept') : t('Accept this time only')}
                                                value='APPROVE' checked={var_acceptreject_status === 'APPROVE'}
                                                onChange={() => set_acceptreject_status('APPROVE')}/>
                                    </div>
                                    {requirement.self_verified === 'NO' && manage_history_access &&
                                        <div className='badge__popup__option'>
                                            <CHECKBOX radio name='approvereject' label={t('Accept always')}
                                                    value='APPROVE-ALWAYS'
                                                    checked={var_acceptreject_status === 'APPROVE-ALWAYS'}
                                                    onChange={() => set_acceptreject_status('APPROVE-ALWAYS')}/>
                                        </div>
                                    }
                                    <div className='badge__popup__option'>
                                        <CHECKBOX radio name='approvereject'
                                                label={requirement.self_verified === 'YES' ? t('Reject') : t('Reject this time only')}
                                                value='REJECT' checked={var_acceptreject_status === 'REJECT'}
                                                onChange={() => {set_acceptreject_status('REJECT'); set_display_reject_comments(true)}}/>
                                    </div>
                                    {requirement.self_verified === 'NO' && manage_history_access &&
                                        <div className='badge__popup__option'>
                                            <CHECKBOX radio name='approvereject' label={t('Reject always')}
                                                    value='REJECT-ALWAYS'
                                                    checked={var_acceptreject_status === 'REJECT-ALWAYS'}
                                                    onChange={() => {set_acceptreject_status('REJECT-ALWAYS'); set_display_reject_comments(true)}}/>
                                        </div>
                                    }
                                    {requirement.self_verified === 'NO' && manage_history_access &&
                                        <div className='badge__popup__option'>
                                            <CHECKBOX radio name='approvereject' label={t('Reject issuer always')}
                                                    value='REJECT-ISSUER'
                                                    checked={var_acceptreject_status === 'REJECT-ISSUER'}
                                                    onChange={() => {set_acceptreject_status('REJECT-ISSUER'); set_display_reject_comments(true)}}/>
                                        </div>
                                    }
                                </>
                                :
                                <div className='badge__popup__suboption'>
                                    <TEXTAREA
                                        property='reject_comments'
                                        value={var_reject_comments || ''}
                                        onChange={onChange_reject_comments}
                                        placeholder={t('Reason')}
                                        maxLength={100}
                                        disabled={false}
                                        errors={var_errors}
                                    />
                                    <Button type='button' className='tertiary' onClick={onClick_return}>{t('Return to options')}</Button>
                                </div>
                            }
                            <div className='badge__popup__option'>
                                <Button type='button' className='badge__popup__button secondary' onClick={(event) => onClick_acceptreject(event)}>{t('Update')}</Button>
                            </div>
                        </Form>
                        <Processing display={var_processing} processingtext='' />
                    </div>
                : null
            }
            on='click'
            open={is_open}
            onOpen={() => onOpen(requirement.individualrequirement_id)}
            onClose={onClose}
            hideOnScroll
            position='bottom left' />
    );
}

import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { SELECT_RADIO } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from 'components/cmp_icon';

import './mdl_addrequirement.css';



function MDL_ADDREQUIREMENT({ display, onClose, requirement_package_name, requirement_package_id, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_selected_requirements, set_selected_requirements ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_save_error, set_save_error ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display && requirement_package_id) {
            // reset and populate options
            set_requirements([]);
            set_selected_requirements([]);
            set_processing(false);
            set_errors([]);
            set_save_error(null);
            populate_requirements();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, requirement_package_id]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirements() {
        try {
            let results = await API_get_requirements_to_add_to_package();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: item.name,
                secondary_text: item.is_assigned === 'YES' ? t('Already added to package') : null,
                disabled: item.is_assigned === 'YES'
            }));
            set_requirements(transformed);
        } catch(e) {
            console.log(e);
        }
    }

    async function add_requirement() {
        set_processing(true);
        try {
            await API_post_package_requirements();
            onChange();
            onClose();
        } catch(e) {
            console.log(e);
            set_save_error(t('An unknown error has occurred. Please try again.'));
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirements_to_add_to_package() {
        return API.get('requirements', '/get-requirements-to-add-to-package/' + requirement_package_id);
    }

    function API_post_package_requirements() {
        return API.post('requirements', '/post-package-requirements/' + requirement_package_id,
        {
            body: {
                requirement_ids: var_selected_requirements
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_requirements(value) {
        set_save_error(null);
        set_errors([]);
        set_selected_requirements(value);
    }

    async function onClick_add() {
        set_save_error(null);
        if (var_selected_requirements.length === 0) {
            set_errors([{ property: 'requirements', description: t('Please select at least one requirement to add') }]);
            return;
        }
        set_errors([]);

        await add_requirement();
    }



    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_addrequirement'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium'>{t('Add requirement')}</div>
                    <div className='text--sm-regular'>{requirement_package_name}</div>
                </div>
                <div className='modal__header__right'>
                    <div className='modal__header__closeicon color--primary-500'>
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                    </div>
                </div>
            </div>

            <Form className='modal__content'>
                {var_save_error &&
                    <Message error
                        header={var_save_error}
                    />
                }
                <SELECT_RADIO name='requirements' value={var_selected_requirements} options={var_requirements} single_or_multiple='MULTIPLE' onChange={onChange_requirements} errors={var_errors} />
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add} disabled={var_requirements.length === 0}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

export default MDL_ADDREQUIREMENT;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import { Modal, Button, Message } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import 'i18n';
import Processing from "components/cmp_processing";
import CMP_CONFIRMATION from "components/cmp_confirmation/cmp_confirmation";


export default function MDL_ASSIGNMENTS({ display, onClose, onChange, document_name, document_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'location_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_deleteassignment_open, set_deleteassignment_open ] = useState(false);
    const [ var_deleteassignment_id, set_deleteassignment_id ] = useState(null);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display) {
            set_refreshtable(!var_refreshtable);
            populate_records();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig) {
        set_message_text(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_document_locations(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_assignments(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_document_locations(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_document_locations(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function onConfirm_delete() {
        set_message_text(null);
        set_processing(true);

        try {
            await API_delete_location_document();
            set_message_type('SUCCESS');
            set_message_text(`${document_name} ${t('removed from')} ${var_assignments.find(item => item.id === var_deleteassignment_id).location_name}`);
            populate_records();
            onChange && onChange();
        } catch (exception) {
            set_message_type('ERROR');
            set_message_text(t('An unexpected error occurred. Please try again.'));
        }
        set_processing(false);
        set_deleteassignment_open(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_document_locations(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('documents', `/get-org-document-locations/${document_id}`,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_delete_location_document() {
        return API.del('documents', `/delete-location-document/${var_deleteassignment_id}`);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_assignment(assignment_id) {
        navigate(`/assignments/${assignment_id}`);
    }

    function onClick_deleteassignment(id) {
        set_message_text(null);
        set_deleteassignment_id(id);
        set_deleteassignment_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer={'inverted'}
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium'>{t('Assignments')}</div>
                    <div className='text--sm-regular'>{document_name}</div>
                </div>
                <div className='modal__header__right'>
                    <div className="modal__header__closeicon color--primary-500">
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                    </div>
                </div>
            </div>

            <div className='modal__content'>
                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='assignments' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('assignments')} downloadfunction={download_records}
                       onChange={populate_records}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='location_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='city' datatype='text' filtertype='text'>{t('CITY')}</Table.HeaderCell>
                            <Table.HeaderCell field='province' datatype='text' filtertype='text'>{t('PROV/STATE')}</Table.HeaderCell>
                            <Table.HeaderCell field='country' datatype='text' filtertype='text'>{t('COUNTRY')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_assignments.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='text--anchor' >
                                    <Button className='tertiary' onClick={() => onClick_assignment(item.location_id)}>
                                        {item.location_name}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.city}</Table.Cell>
                                <Table.Cell>{item.province}</Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    {item.country}
                                    <Button className='tertiary' onClick={() => onClick_deleteassignment(item.id)}>
                                        <Icon name='delete' className='color--primary-500' />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>
            </div>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

            <CMP_CONFIRMATION display={var_deleteassignment_open} title={t('Remove assignment')} message={
                <div style={{flexDirection: 'column'}}>
                    <div style={{marginBottom: '0.5rem'}}>{t('Are you sure you want to remove this assignment from the document')}</div>
                </div>
            } positive_option={t('Remove')} negative_option={t('Cancel')} onConfirm={onConfirm_delete} onCancel={() => set_deleteassignment_open(false)} />

        </Modal>
    );

}
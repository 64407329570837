import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'i18n';

import Icon from 'components/cmp_icon';
import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import CRD_DOCUMENTS from './crd_documents/crd_documents';



export default function DOCUMENTS(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const navigate = useNavigate();
    const { t } = useTranslation('public');

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page' id='documents'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[]}
                current_page_text={t('Documents')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{t('Documents')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>
                <CRD_DOCUMENTS />
            </div>
        </div>
    )
};

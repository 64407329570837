import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Form, Button, Message, Modal } from 'semantic-ui-react';
import API  from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import form_helper from 'libs/form-lib';

import './mdl_override.css';


function MDL_OVERRIDE({ individualdailycheck_id, display, onClose, onOverride }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_manager_note, set_manager_note ] = useState('');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(true);
    const [ var_modal, set_modal ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), textarea:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            // reset variables and re-populate
            set_modal(document.querySelector('#mdl_override_dailycheck'));
            set_manager_note('');
            set_errors([]);
            set_processing(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function process_override() {
        set_processing(true);
        try {
            await API_post_dailycheck_override();
            onOverride();
        } catch (e) {
            console.log(e);
            set_errors([{description: t('There was a problem saving.  Please try again later')}]);
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_dailycheck_override() {
        return API.post('dailycheck', '/post-dailycheck-override', { body: { id: individualdailycheck_id, manager_note: var_manager_note.trim() } });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_note(e) {
        set_manager_note(e.target.value.substring(0, 250));
    }

    function onClick_submit() {
        if (var_processing) return;

        // validation
        let errors = [];
        if (!form_helper.validate_required_string(var_manager_note)) {
            errors.push({property: 'manager_note', description: t('Override explanation is required.')});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        process_override();
    }

    function onClick_cancel() {
        onClose();
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClick_cancel}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            className='mdl_override__container'
            id='mdl_override_dailycheck'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_override_dailycheck'
        >
            <div className='modal__header'>
                <div style={ { width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium' id='hdr_override_dailycheck'>{t('Override Daily Check')}</div>
                    </div>
                </div>
            </div>
            <Form className='modal__content padding--lg' tabIndex='0' aria-labelledby='hdr_override_dailycheck'>
                {var_errors.length > 0 &&
                    <div className='error__wrapper'>
                        <Message error
                            icon={<Icon name='error' className='icon' />}
                            header={var_errors[0].description} />
                    </div>
                }
                <div className='mdl_override__body'>
                    <div className='text--sm-regular margin--lg'>{t("Overriding a failed Daily Check will set the result to 'pass'.  Please provide an explanation for the override.")}</div>
                    <div className='margin--lg'>
                        <Form.TextArea
                            value={var_manager_note}
                            onChange={onChange_note}
                            placeholder={t('Override explanation')}
                            maxLength={250}
                            error={var_errors.some(item => item.property === 'manager_note')}
                        />
                        <div>
                            {var_errors.some(item => item.property === 'manager_note') &&
                                <div className='field__feedback error'>
                                    <div className='description text--sm-regular'>{var_errors.find(item => item.property === 'manager_note').description}</div>
                                </div>
                            }
                            <div>{var_manager_note.length}/250</div>
                        </div>
                    </div>
                </div>
            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_submit}>{t('Override')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

MDL_OVERRIDE.propTypes = {
    individualdailycheck_id: propTypes.string,
    display: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    onOverride: propTypes.func.isRequired
};

export default MDL_OVERRIDE;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TAB_DETAILS from './tab_details/tab_details';



export default function PACKAGE_DETAILS() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_tab, set_tab ] = useState('DETAILS');
    const [ var_requirement_package, set_requirement_package ] = useState({});



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        populate_package();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_package() {
        try {
            let results = await API_get_package_details();
            set_requirement_package(results);
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_package_details() {
        return API.get('requirements', '/get-package-details/' + params.packageid);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }, { path: '/org/requirements', text: t('Requirements') }]}
                current_page_text={var_requirement_package.name || ''}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{var_requirement_package.name}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                {/*<TABS activetab={var_tab} onClick={onClick_tab}>*/}
                {/*    <TABS.TAB name='DETAILS' label={t('Details')} />*/}
                {/*    <TABS.TAB name='HOLDERS' label={t('Holders')} />*/}
                {/*    <TABS.TAB name='SECURITY' label={t('Security')} />*/}
                {/*</TABS>*/}

                {var_tab === 'DETAILS' &&
                    <TAB_DETAILS requirement_package={var_requirement_package} onChange_package={populate_package} />
                }

                {/*{var_tab === 'INDIVIDUALS' &&*/}
                {/*    <TAB_INDIVIDUALS/>*/}
                {/*}*/}
            </div>
        </div>
    )
};
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import { encrypt, unwrap } from 'libs/vault-lib';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'i18n';

import Icon from 'components/cmp_icon';
import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import CRD_CATEGORIES from './crd_categories/crd_categories';
import CRD_CREDENTIALS from './crd_credentials/crd_credentials';
import CRD_ENABLEVC from './crd_enablevc/crd_enablevc';

import CMP_UNLOCK_WALLET from 'components/cmp_unlock_wallet/cmp_unlock_wallet';
import CRD_NOTIFICATIONS from './crd_notifications/crd_notifications';


export default function CREDENTIALS(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_refreshcategories, set_refreshcategories ] = useState(false);
    const [ var_category_description, set_category_description ] = useState(null);

    const [ var_hasVCwallet, set_hasVCwallet ] = useState(false);
    const [ var_unlock_wallet_open, set_unlock_wallet_open ] = useState(false);

    const [ var_notifications, set_notifications ] = useState([]);

    const category = params.category.toUpperCase();

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!Window.$is_unlocked) {
            Window.$is_unlocked = (!auth.has_orgaccess('INDV-VCWALLET', 'wallet') || !auth.passphrase_parameters);
        }
        set_unlock_wallet_open(!Window.$is_unlocked);
        set_hasVCwallet(!!auth.passphrase_parameters);
    }, []);

    useEffect(() => {
        set_category_description(null);
        category !== 'ALL' && populate_catergory_description();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    useEffect(() => {
        set_hasVCwallet(!!auth.passphrase_parameters);
    }, [var_unlock_wallet_open]);

    useEffect(() => {
        category === 'ALL' && check_verifiable_credentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Window.$is_unlocked])

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_catergory_description(){

        try {
            let results = await API_get_category_description();
            set_category_description(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function check_verifiable_credentials() {
        let passphrase_params = auth.passphrase_parameters?.[0];
        let kek = Window.$kek;
        if (!passphrase_params || !kek) return;

        let verifiable_credentials = [];
        try {
            verifiable_credentials = await API_get_verifiable_credentials();
        } catch (e) {
            console.log(e);
            return;
        }

        let notifications = load_notifications(verifiable_credentials);
        set_notifications(notifications);

        let claimable_credentials = verifiable_credentials.filter(item => !item.walletentity_id);
        for (let credential of claimable_credentials) {
            if (await claim_verifiable_credential(credential, passphrase_params.did_uri, kek)) {
                notifications = notifications.filter(item => item.id !== credential.id);
                set_notifications(notifications);
            }
        }
    }

    async function claim_verifiable_credential(verifiable_credential, subject_did, kek) {
        try {
            if (verifiable_credential.proof_type === 'JWT'){
                let results = await API_post_claim_verifiablecredential(verifiable_credential.id, subject_did);
                if (results.vc) {
                    let validated = await API_validate_jwt(results.vc);
                    if (validated?.verified) {
                        let expiration = validated?.payload?.exp;
                        let { key, key_algorithm, wrapping_algorithm } = JSON.parse(sessionStorage.getItem('encryption_key'));
                        let encryption_key = await unwrap(key, key_algorithm, kek, wrapping_algorithm);
                        let encrypted = await encrypt(results.vc, encryption_key);
                        await API_put_wallet_credential(verifiable_credential.id, encrypted, expiration);
                    }
                }
            } else if (verifiable_credential.proof_type === 'JSONLD'){
                let results = await API_vcpaas_post_credential_issue(verifiable_credential.id, subject_did);
                if (results.verifiableCredential) {
                    //TODO: validate JSONLD VC
                    let validated = results.verifiableCredential;
                    let expiration = Math.floor(Date.parse(validated.expirationDate) / 1000);
                    let { key, key_algorithm, wrapping_algorithm } = JSON.parse(sessionStorage.getItem('encryption_key'));
                    let encryption_key = await unwrap(key, key_algorithm, kek, wrapping_algorithm);
                    let encrypted = await encrypt(JSON.stringify(results.verifiableCredential), encryption_key);
                    await API_put_wallet_credential(verifiable_credential.id, encrypted, expiration);
                }
            }
        } catch (e) {
            console.log(e);
            return false;
        }
        return true;
    }

    function load_notifications (verifiable_credentials) {
        let notifications = [];

        // Check each Verifiable Credential
        verifiable_credentials.forEach(credential => {
            if (!credential.walletentity_id || !credential.vc) {
                // Digital Credential available as a Verifiable Credential, but nothing currently linked
                notifications.push({
                    id: credential.id,
                    status: 'WARNING',
                    category: credential.credential_category_id,
                    notification: t('Enhance the security of your {{name}} credential.', { name: credential.name })
                });
            } else if (credential.walletentity_id && credential.expiration_timestamp < Math.floor(Date.now() / 1000)) {
                // credential expiry failed verification
                notifications.push({
                    id: credential.id,
                    status: 'WARNING',
                    category: credential.credential_category_id,
                    notification: t('A new version of {{name}} is available', { name: credential.name })
                });
            }
        });

        return notifications;
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_category_description(){
        return API.get('credentials', '/get-category-description/' + category);
    }

    function API_get_verifiable_credentials() {
        return API.get('verifiable-credentials', '/get-verifiable-credentials/', {
            queryStringParameters: {
                    tz: datelib.timezone
            }
        });
    }

    function API_post_claim_verifiablecredential(individualcredential_id, subject_did) {
        return API.post('integrations', '/microsoft-post-credential-issue', {
            body: {
                individualcredential_id,
                subject_did
            }
        });
    }

    function API_put_wallet_credential(id, credential, expiration_timestamp) {
        return API.put('verifiable-credentials', `/put-wallet-credential/${id}`, {
            body: {
                vc: credential.ciphertext,
                iv: credential.iv,
                encryption_algorithm: credential.encryption_algorithm,
                expiration_timestamp
            }
        });
    }

    function API_validate_jwt(jwt) {
        return API.post('verifiable-credentials', '/validate-jwt', {
            body: {
                jwt
            }
        });
    }

    function API_vcpaas_post_credential_issue(individualcredential_id, subject_did) {
        return API.post('integrations', '/vcpaas-post-credential-issue', {
            body: {
                individualcredential_id,
                subject_did
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    async function onClose_enablevc() {
        await appProps.reset_authentication();
        Window.$is_unlocked = !auth.passphrase_parameters;
        set_unlock_wallet_open(!Window.$is_unlocked);
    }

    function onClose_unlock_wallet() {
        set_unlock_wallet_open(false);
        if (!Window.$is_unlocked) {
            navigate('/dashboard');
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='page' id='credentials'>
            <CMP_BREADCRUMB
                breadcrumb_elements={category !== 'ALL' ? [{ path: '/credentials/all', text: t('Credentials') }] : []}
                current_page_text={category === 'ALL' ? t('Credentials') : t(category)}
            />
            <div id='content' tabIndex='0'>
                <div className={'page__header' + (category === 'ALL' ? ' page__header--background2' : ' page__header--background3')}>
                    <h1 className='display--sm-regular'>{t('Credentials')}</h1>

                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>

                    {var_category_description &&
                        <div className='text--sm-regular'>{var_category_description}</div>
                    }

                    {category === 'ALL' && Window.$is_unlocked &&
                        <CRD_CATEGORIES credentialrefresh={var_refreshcategories} onRefreshComplete={() => set_refreshcategories(false)} />

                    }
                </div>


                {auth.has_orgaccess('INDV-VCWALLET', 'wallet') && !var_hasVCwallet &&
                    <CRD_ENABLEVC
                        onClose={onClose_enablevc}
                    />
                }

                {category === 'ALL' && var_notifications.length > 0 &&
                    <CRD_NOTIFICATIONS
                        notifications={var_notifications}
                        totalrows={var_notifications.length}
                    />
                }

                <CRD_CREDENTIALS
                    category={category}
                    onChange={() => set_refreshcategories(true)}
                />

                <CMP_UNLOCK_WALLET
                    display={var_unlock_wallet_open}
                    reset_authentication={appProps.reset_authentication}
                    onClose={onClose_unlock_wallet}
                />
            </div>
        </div>
    );
};

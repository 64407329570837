import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import INPUT from './input';

function FORM_INPUT({ property, label, value, onChange, onKeyPress, placeholder, maxLength, disabled, errors, description, icon, type, autocomplete, multiple, aria_describedby = null, inputClassName }) {


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER ==========================================================================================================
    return (
        <div className='detailsgroup'>
            <div className='detailsgroup__label text--sm-medium'>{label}</div>
            <Form.Field className='detailsgroup__value'>
                {description && <div className='detailsgroup__description text--sm-regular'>{description}</div>}
                <INPUT
                    property={property}
                    value={value}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    disabled={disabled}
                    errors={errors}
                    icon={icon}
                    type={type}
                    autocomplete={autocomplete}
                    multiple={multiple}
                    aria_describedby={aria_describedby}
                    className={inputClassName}
                />
            </Form.Field>
        </div>
    );
}


FORM_INPUT.propTypes = {
    description: propTypes.string,
    property: propTypes.string,
    value: propTypes.string,
    onKeyPress: propTypes.func,
    onChange: propTypes.func,
    placeholder: propTypes.string,
    maxLength: propTypes.number,
    icon: propTypes.element,
    type: propTypes.string,
    autocomplete: propTypes.string,
    aria_describedby: propTypes.string,
    inputClassName: propTypes.string
};

export default FORM_INPUT;
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/cmp_icon';
import auth from 'libs/auth-lib';
import form_helper from 'libs/form-lib';
import Table, {default_populateconfig} from 'components/cmp_table/cmp_table';
import { FORM_DETAILSGROUP, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import 'i18n';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import CMP_WARNING from 'components/cmp_warning/cmp_warning';
import MDL_ADDPERMISSION from './mdl_add_permission/mdl_add_permission';
import MDL_EDIT_PERMISSION from './mdl_edit_permission/mdl_edit_permission';

import './crd_security.css'



export default function CRD_SECURITY({ step, onChange_step, credential, onChange_credential, onChange_processing, display_processing }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_opened, set_opened ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_addpermission_open, set_addpermission_open ] = useState(false);

    const [ var_deletepermission_id, set_deletepermission_id ] = useState('');
    const [ var_deletepermission_open, set_deletepermission_open ] = useState(false);

    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'role_individual' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(false);

    const [ var_editpermission_open, set_editpermission_open ] = useState(false);
    const [ var_selected_permission, set_selected_permission ] = useState({});
    const [ var_cmp_warning_open, set_cmp_warning_open ] = useState(false);

    const permission_options = [
        {code: 'read', description: t('View: Allow viewing of the credential')},
        {code: 'view attachment', description: t('Attachments: Allow viewing of supporting attachments')},
        {code: 'write', description: t('Assign: Allow assigning of the credential to an individual  (Admin, Global Admin and Superadmin only)')},
        {code: 'security', description: t('Security: Allow permission changes to the credential (Superadmin only)')}
    ]



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_permissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credential.permissions]);

    useEffect(() => {
        set_errors([]);
        if (step === 'ACCESS') {
            set_opened(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        set_save_error(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credential]);

    useEffect(() => {
        if (!var_addpermission_open && var_focuselement?.id === 'btn_addpermission') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addpermission_open]);

    useEffect(() => {
        if (!var_deletepermission_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_create3').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_deletepermission_open]);

    useEffect(() => {
        if (!var_editpermission_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_create3').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_editpermission_open]);

    useEffect(() => {
        if (!var_cmp_warning_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_create3').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_cmp_warning_open]);



    //  async functions ------------------------------------------------------------------------------------------------
    function populate_permissions() {
        set_totalrows(credential.permissions.length);
        set_ready(true);
    }

    async function insert_credential(updated_credential) {
        onChange_processing(true);
        try {
            let credential_id = await API_post_catalogue_credential(updated_credential);
            navigate('/org/credentials/' + credential_id);
        } catch (exception) {
            console.log(exception);
            set_save_error(t('An error has occurred'));
        } finally {
            onChange_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_catalogue_credential(body) {
        return API.post('credentials', '/post-catalogue-credential', { body });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        onChange_step('ACCESS');
    }

    function onChange_has_customsecurity(value) {
        let credential_updated = {...credential};
        credential_updated.has_customsecurity = value;
        if (credential_updated.has_customsecurity === 'YES') {
            credential_updated.permissions = [
                {   id: auth.id,
                    access: '["read","security"]',
                    role_individual: auth.email,
                    accesstarget: 'INDIVIDUAL'
                }];
        } else {
            credential_updated.permissions = [];
        }
        onChange_credential(credential_updated);
        if (var_errors.length > 0) {
            let errors = var_errors.filter(error => error.property !== 'has_customsecurity')
            set_errors(errors);
        }
    }

    function onConfirm_deletepermission() {
        let credential_updated = {...credential};
        credential_updated.permissions = credential_updated.permissions.filter(item => item.id !== var_deletepermission_id);
        onChange_credential(credential_updated);
        set_focuselement(document.getElementById('crd_create3'));
        set_deletepermission_open(false);
    }

    function onClick_save() {
        let errors = [];
        let updated_credential = {...credential};
        // reset fields if has pre-qualifiers
        if (updated_credential.has_prequalifier === 'YES') {
            updated_credential.expires = 'NO';
            updated_credential.expires_amount = null;
            updated_credential.expires_unit = null;
            updated_credential.auto_verify = 'NO';
            updated_credential.issuedrequired = 'NO';
            updated_credential.credential_number_required = 'NO';
            updated_credential.credential_number_label = null;
            updated_credential.contains_pii = 'NO';
        }

        //validation
        if (!form_helper.validate_required_string(updated_credential.name)) {
            set_save_error(t('Missing required field from Step 1'));
            return;
        }

        if (!updated_credential.indv_or_org ||
            !updated_credential.credential_category_id ||
            !updated_credential.status ||
            !updated_credential.verifiable_credential ||
            !updated_credential.expires ||
            (updated_credential.expires === 'YES' && !form_helper.validate_positive_number(updated_credential.expires_amount)) ||
            (updated_credential.expires === 'YES' && (!updated_credential.expires_unit)) ||
            !updated_credential.auto_verify ||
            !updated_credential.issuedrequired ||
            !updated_credential.credential_number_required ||
            (updated_credential.credential_number_required === 'YES' && !form_helper.validate_required_string(updated_credential.credential_number_label)) ||
            (updated_credential.has_credit_value === 'YES' && !form_helper.validate_positive_number(updated_credential.credit_value))) {
            set_save_error(t('Missing required fields from Step 2'));
            return;
        }

        if (!updated_credential.has_customsecurity) {
            errors.push({ property: 'has_customsecurity', description: t('This setting is required') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        form_helper.cleanse_string_property(updated_credential, 'name');
        form_helper.cleanse_string_property(updated_credential, 'code');
        form_helper.cleanse_string_property(updated_credential, 'description');
        form_helper.cleanse_string_property(updated_credential, 'skills');
        form_helper.cleanse_string_property(updated_credential, 'credential_number_label');
        updated_credential.credit_value = updated_credential.has_credit_value === 'YES' ? Number(updated_credential.credit_value) : null;

        onChange_credential(updated_credential);
        insert_credential(updated_credential);
    }

    function onClick_addpermission() {
        set_save_error(null);
        set_focuselement(document.activeElement);
        set_addpermission_open(true);
    }

    function onClick_deletepermission(id) {
        set_save_error(null);
        set_focuselement(document.activeElement);
        if (credential.permissions.filter(item => item.access.includes('security')).length === 1 &&
            credential.permissions.find(item => item.access.includes('security')).id === id) {
            set_cmp_warning_open(true);
            return;
        }
        set_deletepermission_id(id);
        set_deletepermission_open(true);
    }

    function onClick_editpermission(permission) {
        set_focuselement(document.activeElement);
        set_save_error(null);
        set_selected_permission(permission);
        set_editpermission_open(true);
    }

    function onChange_editpermission(edited_permission) {
        let credential_updated = {...credential};
        credential_updated.permissions[credential_updated.permissions.findIndex(item => item.id === var_selected_permission.id)].access =
            JSON.stringify(permission_options.filter(item => edited_permission[item.code]).map(item => item.code))
        onChange_credential(credential_updated);
        set_editpermission_open(false);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_create3'  tabIndex='0'>

            <div className='card__header'>
                <div className='card__header__icon'>
                    {(var_opened && step !== 'ACCESS') ?
                        <Icon name='checkmark' className='icon__checkmark' alt='checkmark icon' />
                        :
                        <div className={'card__header__icon__step text--xl-bold' + (var_opened ? ' icon--dark_gray' : ' icon--light_gray')}>3</div>
                    }
                </div>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_credential_security'>{t('Credential security')}</div>
                    {(!var_opened || step === 'ACCESS') &&
                        <div className='text--sm-regular'>{t('Specify who has access to view and manage this credential.')}</div>
                    }
                </div>
                <div className='card__header__right card__header__btns'>
                    {(var_opened && step !== 'ACCESS') &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {var_save_error &&
                <Message error
                    header={var_save_error}
                />
            }

            {(step !== 'ACCESS' && var_opened) ?
                <>
                    <div id='create_credential__access' className='card__content create_summary'>
                        <FORM_DETAILSGROUP label={t('Restrict access')} value={credential.has_customsecurity ? t(credential.has_customsecurity.substring(0, 1) + credential.has_customsecurity.substring(1).toLowerCase()) : ''} />
                        {credential.has_customsecurity === 'YES' &&
                            <div className='detailsgroup'>
                                <div className='tbl__permissions detailsgroup__value text--sm-regular'>
                                    <Table id='permissions'
                                           loading={false} ready={var_ready}
                                           loadingerror={false} lockcolumns={0}
                                           refresh={var_refreshtable} totalrows={var_totalrows}
                                           populateconfig={var_populateconfig}
                                           populatefilterfunction={() => null}
                                           downloadname={t('Permissions')}
                                           downloadfunction={() => null}
                                           onChange={populate_permissions}
                                           hide_tablecontrols={true}>

                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell field='role_individual' datatype='text' filtertype='text'>{t('ROLE/INDIVIDUAL')}</Table.HeaderCell>
                                                <Table.HeaderCell field='read' datatype='text' filtertype='option'>{t('VIEW')}</Table.HeaderCell>
                                                <Table.HeaderCell field='view_attachment' datatype='text' filtertype='option'>{t('ATTACHMENTS')}</Table.HeaderCell>
                                                <Table.HeaderCell field='write' datatype='text' filtertype='option'>{t('ASSIGN')}</Table.HeaderCell>
                                                <Table.HeaderCell field='security' datatype='text' filtertype='option'>{t('SECURITY')}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {credential.permissions.map(item =>
                                                <Table.Row key={item.id}>
                                                    <Table.Cell>{item.role_individual}</Table.Cell>
                                                    <Table.Cell className='cell__icon cell--center'>
                                                        {item.access.includes('read') ? <Icon name='checkmark' className='color--green-500' alt={t('status icon')}/> : null}
                                                    </Table.Cell>
                                                    <Table.Cell className='cell__icon cell--center'>
                                                        {item.access.includes('view attachment') ? <Icon name='checkmark' className='color--green-500' alt={t('status icon')}/> : null}
                                                    </Table.Cell>
                                                    <Table.Cell className='cell__icon cell--center'>
                                                        {item.access.includes('write') ? <Icon name='checkmark' className='color--green-500' alt={t('status icon')}/> : null}
                                                    </Table.Cell>
                                                    <Table.Cell className='cell__icon cell--center'>
                                                        {item.access.includes('security') ? <Icon name='checkmark' className='color--green-500' alt={t('status icon')}/> : null}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>
                                    </Table>

                                    <div className='tbl__permissions__legend'>
                                        <div className='tbl__permissions__legend__item'>{t('View: Allow viewing of the credential')}</div>
                                        <div className='tbl__permissions__legend__item'>{t('Attachments: Allow viewing of supporting attachments')}</div>
                                        <div className='tbl__permissions__legend__item'>{t('Assign: Allow assigning of the credential to an individual')}</div>
                                        <div className='tbl__permissions__legend__item'>{t('Security: Allow permission changes to the credential')}</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={() => onClick_edit()}>{t('Edit')}</Button>
                    </div>
                </>
                : step === 'ACCESS' ?
                    <>
                        <Form className='card__content' id='form_cr8credential_step3' aria-labelledby='hdr_credential_security'>

                            {var_errors.length > 0 &&
                                <Message
                                    error icon={<Icon name='error' className='icon' />}
                                    header={t('There are some errors with your inputs')}
                                />
                            }

                            <FORM_TOGGLE
                                label={t('Restrict access')}
                                value={credential.has_customsecurity}
                                options={[{value: 'YES', text: t('Yes')}, {value: 'NO', text: t('No')}]}
                                onChange={onChange_has_customsecurity}
                                description={t('Restrict who has access to view, manage and assign this credential.')}
                            />

                            {credential.has_customsecurity === 'YES' &&
                                <div className='detailsgroup'>
                                    <div className='detailsgroup__label text--sm-medium'>{t('Permissions')}</div>
                                    <div className='tbl__permissions detailsgroup__value text--sm-regular'>
                                        <Table  id='permissions'
                                                loading={false} ready={var_ready}
                                                loadingerror={false} lockcolumns={0}
                                                refresh={var_refreshtable} totalrows={var_totalrows}
                                                populateconfig={var_populateconfig}
                                                populatefilterfunction={() => null}
                                                downloadname={t('Permissions')}
                                                downloadfunction={() => null}
                                                onChange={populate_permissions}
                                                hide_tablecontrols={true}>

                                            <Table.OptionsButtons>
                                                <Button id='btn_addpermission' type='button' className='secondary' onClick={onClick_addpermission}>{t('Add role or individual')}</Button>
                                            </Table.OptionsButtons>

                                            <Table.Header>
                                                <Table.Row>
                                                <Table.HeaderCell field='role_individual' datatype='text' filtertype='text'>{t('ROLE/INDIVIDUAL')}</Table.HeaderCell>
                                                <Table.HeaderCell field='read' datatype='text' filtertype='option'>{t('VIEW')}</Table.HeaderCell>
                                                <Table.HeaderCell field='view_attachment' datatype='text' filtertype='option'>{t('ATTACHMENTS')}</Table.HeaderCell>
                                                <Table.HeaderCell field='write' datatype='text' filtertype='option'>{t('ASSIGN')}</Table.HeaderCell>
                                                <Table.HeaderCell field='security' datatype='text' filtertype='option'>{t('SECURITY')}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {credential.permissions.map(item =>
                                                    <Table.Row key={item.id}>
                                                        <Table.Cell className='text--anchor'>
                                                            <Button className='tertiary' onClick={() => onClick_editpermission(item)}>
                                                                {item.role_individual}
                                                            </Button>
                                                        </Table.Cell>
                                                        <Table.Cell className='cell__icon cell--center'>
                                                            {item.access.includes('read') ?  <Icon name='checkmark' className='color--green-500' alt={t('status icon')}/> : null}
                                                        </Table.Cell>
                                                        <Table.Cell className='cell__icon cell--center'>
                                                            {item.access.includes('view attachment') ? <Icon name='checkmark' className='color--green-500' alt={t('status icon')}/> : null}
                                                        </Table.Cell>
                                                        <Table.Cell className='cell__icon cell--center'>
                                                            {item.access.includes('write') ? <Icon name='checkmark' className='color--green-500' alt={t('status icon')}/> : null}
                                                        </Table.Cell>
                                                        <Table.Cell className={item.access.includes('security') ? 'cell__icon--left--right' : 'cell__icon--right'}>
                                                            {item.access.includes('security') ?
                                                                <Icon name='checkmark' className='color--green-500' alt={t('status icon')}/>
                                                                : null
                                                            }
                                                            <Button className='tertiary' onClick={() => onClick_deletepermission(item.id)}>
                                                                <Icon name='delete' className='color--primary-500'/>
                                                            </Button>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                            </Table.Body>
                                        </Table>

                                        <div className='tbl__permissions__legend'>
                                            <div className='tbl__permissions__legend__item'>{t('View: Allow viewing of the credential')}</div>
                                            <div className='tbl__permissions__legend__item'>{t('Attachments: Allow viewing of supporting attachments')}</div>
                                            <div className='tbl__permissions__legend__item'>{t('Assign: Allow assigning of the credential to an individual')}</div>
                                            <div className='tbl__permissions__legend__item'>{t('Security: Allow permission changes to the credential')}</div>
                                        </div>
                                    </div>

                                    <CMP_CONFIRMATION
                                        display={var_deletepermission_open}
                                        title={t('Remove access')}
                                        message={
                                            <div style={{flexDirection: 'column'}}>
                                                <div style={{marginBottom: '0.5rem'}}>
                                                    {t('Are you sure you want to remove') + ' ' + credential.permissions.find(item => item.id === var_deletepermission_id)?.role_individual + ' ' + t('access to this credential?')}
                                                </div>
                                            </div>
                                        }
                                        positive_option={t('Remove')}
                                        negative_option={t('Cancel')}
                                        onConfirm={onConfirm_deletepermission}
                                        onCancel={() => set_deletepermission_open(false)}
                                    />

                                    <CMP_WARNING
                                        display={var_cmp_warning_open}
                                        title={t('Remove access')}
                                        message={t('This credential requires at least one role or individual with permission to modify its security settings.')}
                                        onClose={() => set_cmp_warning_open(false)}
                                    />

                                    <MDL_ADDPERMISSION
                                        is_open={var_addpermission_open}
                                        onClose={() => set_addpermission_open(false)}
                                        onChange={populate_permissions}
                                        permission_options={permission_options}
                                        credential={credential}
                                        onChange_credential={onChange_credential}
                                    />

                                    <MDL_EDIT_PERMISSION
                                        is_open={var_editpermission_open}
                                        onClose={() => set_editpermission_open(false)}
                                        role_individual={var_selected_permission.role_individual}
                                        selected_permission={var_selected_permission.access}
                                        selected_permission_id={var_selected_permission.id}
                                        onChange={onChange_editpermission}
                                        permission_options={permission_options}
                                        credential={credential}
                                        role_individual_permission={var_selected_permission.permission}
                                    />

                                </div>
                            }

                            <div className='card__footer'>
                                <Button className='primary' onClick={onClick_save}>{t('Save credential')}</Button>
                            </div>
                        </Form>
                    </>
                : null
            }

            <Processing display={display_processing} processingtext={t('Processing')} />

        </div>
    );
}
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';
import auth from 'libs/auth-lib';
import 'i18n';

import MDL_DAILYCHECK from 'components/cmp_dailycheck/mdl_dailycheck/mdl_dailycheck';
import CMP_DOCUMENTACKNOWLEDGE from 'components/cmp_documentacknowledge/cmp_documentacknowledge';
import CMP_REQUIREMENT from 'components/cmp_requirement/cmp_requirement';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import CMP_ADDCREDENTIAL from 'components/cmp_addcredential/cmp_addcredential';
import CMP_IDENTITY_VERIFICATION from 'components/cmp_identity_verification/cmp_identity_verification';


export default function CMP_NOTIFICATIONS({ assignment, refresh, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_notifications, set_notifications ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'notification_level' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_selected_notification, set_selected_notification ] = useState({});
    const [ var_mdl_component_open, set_mdl_component_open ] = useState(null);
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_mdl_addcredential_open, set_mdl_addcredential_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!assignment || assignment.location_id) {
            populate_notifications();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh, assignment]);

    useEffect(() => {
        if (!var_mdl_component_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd__notifications').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_component_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_notifications(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_notifications(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_notifications(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_notifications(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_notifications(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_notifications(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_notifications(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('indv', '/get-notifications' + (assignment ? ('/' + assignment.location_id) : ''),
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_notification(notification) {
        set_focuselement(document.querySelector(`#${notification.notification_type}-${notification.item_id} button`));
        switch (notification.notification_type) {
            case 'dailycheck':
                set_selected_notification(notification);
                set_mdl_component_open('dailycheck');
                break;
            case 'onsite':
                navigate('/assignments/' + notification.nav_id);
                break;
            case 'requirement':
                set_selected_notification(notification);
                set_mdl_component_open('requirement');
                break;
            case 'document':
                set_selected_notification(notification);
                set_mdl_component_open('docviewer');
                break;
            case 'expired credential':
            case 'expiring credential':
            case 'new credential added':
            case 'credential':
                navigate(`/credentials/all/${notification.item_id}`);
                break;
            case 'credential prequalifier':
                navigate('/credentials/all/' + notification.item_id + '/prequalifiers');
                break;
            case 'identity_verification_request':
                set_selected_notification(notification);
                set_mdl_component_open('identity_verification');
                break;
            default:
                console.warn('Unhandled notification type');
                break;
        }
    }

    function onClose_dailycheck(new_status) {
        if (new_status) {
            populate_notifications();
            onChange && onChange('dailycheck');
        }
        set_mdl_component_open(null);
        set_selected_notification({});
    }

    function onClose_document() {
        set_mdl_component_open(null);
        set_selected_notification({});
    }

    function onChange_requirement({ updated }, missing) {
        if (missing === 'YES') {
            set_mdl_confirmation_open(true);
        }
        if (updated === 'YES') {
            populate_notifications();
            onChange && onChange('requirement');
        }
        set_mdl_component_open(null);
    }

    function onAcknowledge_document() {
        populate_notifications();
        set_mdl_component_open(null);
        set_selected_notification({});
        onChange && onChange('document');
    }

    function onConfirm_addcredential() {
        set_mdl_confirmation_open(false);
        set_mdl_addcredential_open(true);
    }

    function onChange_addcredential() {
        set_mdl_addcredential_open(false);
        populate_notifications();
        onChange && onChange('requirement');
    }

    /**
     * @param {('INITIAL'|'COMPLETE'|'CANCEL')} status
     * @returns
     */
    function onClose_verifyIdentity(status) {
        set_mdl_component_open(null);
        set_selected_notification({});
        if (status !== 'INITIAL') {
            // if they started the process then the current request can no longer be used
            populate_notifications();
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd__notifications' tabIndex='0'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_notifications' className='text--xl-medium'>{!assignment ? t('Notifications') : t('Your Notifications')}</div>
                    </div>
                </div>

                <Table id='notifications' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Notifications')} downloadfunction={download_notifications}
                        onChange={populate_notifications}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className='cell__icon' field='notification_level_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='title' datatype='text' filtertype='text'>{t('NOTIFICATION')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization_name' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_notifications.map(item =>
                            <Table.Row key={`${item.notification_type}-${item.item_id}`} id={`${item.notification_type}-${item.item_id}`} onClick={() => onClick_notification(item)}>
                                <Table.Cell className='cell__icon cell--center'>
                                    {
                                        item.notification_level === 'ERROR' && item.notification_type === 'expired credential'
                                        ? <Icon name='xicon_solid' className='color--red-600' alt={t('Expired')} />
                                        : item.notification_level === 'ERROR'
                                        ? <Icon name='error' className='color--red-600' alt={t('Error')} />
                                        : item.notification_level === 'WARNING' && item.notification_type === 'expiring credential'
                                        ? <Icon name='warning_circle' className='color--green-500' alt={t('Expiring')} />
                                        : item.notification_level === 'WARNING' && item.notification_type === 'credential prequalifier'
                                        ? <Icon name='warning_circle' className='color--yellow-500' alt={t('Credential prequalifier')} />
                                        : item.notification_level === 'WARNING'
                                        ? <Icon name='error' className='color--yellow-500' alt={t('Warning')} />
                                        : item.notification_level === 'INFO' && item.notification_type === 'new credential added'
                                        ? <Icon name='new' className='color--blue-500' alt={t('New credential')} />
                                        : item.notification_level === 'INFO'
                                        ? <Icon name='new' className='color--blue-500' alt={t('Info')} />
                                        : null
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <Table.CellContentTwoLine primary_text={item.title} secondary_text={item.subtext} display_chevron={false} />
                                </Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    <Table.CellContentTwoLine secondary_text={item.organization_name} display_chevron={true} />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

            </div>

            <MDL_DAILYCHECK
                location_id={var_selected_notification.item_id}
                location_name={var_selected_notification.assignment}
                display_onsite={auth.has_access(var_selected_notification, 'INDV-ONSITE', 'onsite')}
                is_open={var_mdl_component_open === 'dailycheck'}
                onClose={onClose_dailycheck}
            />

            <CMP_DOCUMENTACKNOWLEDGE
                is_open={var_mdl_component_open === 'docviewer'}
                onAcknowledgement={onAcknowledge_document}
                show_acknowledge={true}
                onClose={onClose_document}
                document_id={var_selected_notification.item_id}
            />

            <CMP_REQUIREMENT
                individualrequirement_id={var_selected_notification.item_id}
                requirement_id={var_selected_notification.item_secondary_id}
                requirement_name={var_selected_notification.subtext}
                requirement_status={null}
                assignedfrom_type={var_selected_notification.assignment === var_selected_notification.organization_name ? 'ORGANIZATION' : 'LOCATION'}
                assignedfrom_id={var_selected_notification.nav_id}
                assignedfrom_name={var_selected_notification.assignment}
                is_open={var_mdl_component_open === 'requirement'}
                onClose={() => set_mdl_component_open(null)}
                onChange={onChange_requirement}
            />

            <CMP_CONFIRMATION
                display={var_mdl_confirmation_open}
                title={t('Add a credential')}
                message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>{t('Would you like to add a credential to your wallet for') + ' ' + var_selected_notification.subtext + '?'}</div>
                        <div>{t('A credential may fulfill the requirement once it has been reviewed and validated by an administrator.')}</div>
                    </div>
                }
                positive_option={t('Add credential')}
                negative_option={t('Cancel')}
                onConfirm={onConfirm_addcredential}
                onCancel={() => set_mdl_confirmation_open(false)}
            />

            <CMP_ADDCREDENTIAL
                display={var_mdl_addcredential_open}
                onClose={() => set_mdl_addcredential_open(false)}
                onChange={onChange_addcredential}
                default_credential_category='ALL'
            />

            <CMP_IDENTITY_VERIFICATION
                display={var_mdl_component_open === 'identity_verification'}
                onClose={onClose_verifyIdentity}
                request_id={var_selected_notification.item_id}
                phone_number={auth.phone}
            />
        </>
    );
}
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Popup, Form } from 'semantic-ui-react';
import { CHECKBOX } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import 'i18n';


export default function CMP_INDIVIDUAL_PERMISSION({ item, permission_roles, current_permission, is_open, onClose, onOpen, onChange, onMessage }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_popup, set_popup ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_permissionpopup_value, set_permissionpopup_value ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const POPUP_ID = (`mdl_individual_permission_${item?.id}`);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open) {
            set_permissionpopup_value(item.permission_id);
            set_popup(document.querySelector(`#${POPUP_ID}`));
        } else if (var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_popup) {
            //  create list of focusable elements within the popup
            let elements = var_popup.querySelectorAll('form, input[type="checkbox"]:not([disabled]), textarea:not([disabled]), button:not([disabled])');
            let firstelement = elements[0];
            let lastelement = elements[elements.length - 1];

            //  set focus to first element within the popup
            firstelement.focus({ preventScroll: true });

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_popup.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_popup]);


    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_org_indv_details(organization_individual_id, permission_id, individual_type, job_title) {
        return API.put('org-indv', '/put-org-indv-details/' + organization_individual_id,
            {
                body: {
                    permission_id: permission_id,
                    individual_type: individual_type,
                    job_title: job_title
                }
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_updatepermission(item) {
        onMessage(null, null);
        if (current_permission === var_permissionpopup_value) {
            onClose();
            return;
        }

        if (!var_permissionpopup_value) {
            onMessage('ERROR', t('Please select a permission'));
            return;
        }
        set_processing(true);
        try {
            let result = await API_put_org_indv_details(item.organization_individual_id, var_permissionpopup_value, item.individual_type, item.job_title);
            if (result === 'IS SUPERADMIN IN ANOTHER ORGANIZATION') {
                onMessage('ERROR', item.individual_name + ' ' + t('is already an admin for another organization'));
                set_processing(false);
                return;
            }
        } catch(e) {
            console.log(e);
        }

        set_processing(false);
        onClose();
        onChange(var_focuselement?.id);
    }



    function onClick_permission_popup(){
        set_focuselement(document.activeElement);
    }


    // RENDER APP ======================================================================================================





    if (item.individual_type !== 'USER') {
        return (
            <div
                className={'badge text--sm-medium  ' +
                (   item.permission === 'Superadmin' ? 'badge--solid_blue'
                    : item.permission === 'Admin' ? 'badge--solid_green'
                    : item.permission === 'Manager' ? 'badge--solid_orange'
                    : item.permission === 'User' ? 'badge--solid_gray'
                    : 'badge--gray')}
            >
                {item.permission_display}
            </div>
        );
    }

    return (
        <Popup
            trigger={
                <div>
                    <Button
                     onClick={onClick_permission_popup}
                     id={`btn_${POPUP_ID}`}
                     className={'badge text--sm-medium  ' +
                        (   item.permission === 'Superadmin' ? 'badge--solid_blue'
                            : item.permission === 'Global Admin' ? 'badge--solid_purple'
                            : item.permission === 'Admin' ? 'badge--solid_green'
                            : item.permission === 'Manager' ? 'badge--solid_orange'
                            : item.permission === 'User' ? 'badge--solid_gray'
                            : 'badge--gray')}
                    type='button'
                    >
                        {item.permission_display}
                        <Icon name='badge_dropdown' className='badge__icon'/>
                    </Button>
                </div>
            }
            content={
                is_open ?
                    <div className='badge__popup shadow-lg rounded-md' id={POPUP_ID} aria-modal='true' role='dialog' aria-labelledby={`hdr_${POPUP_ID}`} aria-label={t('Update individual permission')}>
                        <div id={`hdr_${POPUP_ID}`} className='text--md-medium' aria-label={t('Change individual permission')}>{t('Change permission')}</div>
                        <Form tabindex='0' aria-labelledby={`hdr_${POPUP_ID}`}>
                            {permission_roles.map(role =>
                                <div className='badge__popup__option'>
                                    <CHECKBOX radio name='updatepermission' label={t(role.permission)} value={role.id} checked={var_permissionpopup_value === role.id} onChange={() => set_permissionpopup_value(role.id)} />
                                </div>)
                            }
                            <Button type='button' className='badge__popup__button secondary' onClick={() => onClick_updatepermission(item)}>{t('Update')}</Button>
                        </Form>
                        <Processing display={var_processing} processingtext='' />
                    </div>
                    : null
            }
            on='click'
            open={is_open}
            onOpen={() => onOpen(item.id)}
            onClose={onClose}
            hideOnScroll
            position='bottom left' />
    );
}
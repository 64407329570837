import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { FORM_FILEUPLOAD, FORM_SELECT_RADIO, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import Icon from 'components/cmp_icon';

import './mdl_adddocument.css';



export default function MDL_ADDDOCUMENT({ display, onClose, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_selected_assignments, set_selected_assignments ] = useState([]);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_document, set_document ] = useState({});
    const [ var_fileupload_status, set_fileupload_status ] = useState(null);
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            // reset and populate options
            set_modal(document.querySelector('#mdl_adddocument'));
            set_assignments([]);
            set_selected_assignments([]);
            set_errors([]);
            set_processing(false);
            set_save_error(null);

            let document_details = {};
            document_details.ack_req = 'NO';
            document_details.organizationdocument = 'NO';
            set_document(document_details);

            populate_assignments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        if (var_fileupload_status === 'UPLOAD COMPLETE') {
            add_document();
        } else if (var_fileupload_status === 'UPLOAD FAIL') {
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload_status]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_assignments() {
        let results = await API_get_org_document_available_assignments();
        if (results) {
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: item.location_name
            }));
            set_assignments(transformed);
        }
    }

    async function add_document() {
        set_processing(true);
        try {
            await API_post_org_document();
            onChange(var_document.submittedfilename);
            onClose();
        } catch (e) {
            console.log(e);
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_document_available_assignments() {
        return API.get('documents', '/get-org-document-available-assignments');
    }

    function API_post_org_document() {
        return API.post('documents', '/post-org-document',
            {
                body: {
                    ...var_document,
                    location_ids: var_selected_assignments
                }
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_assignments(value) {
        set_selected_assignments(value);
        set_errors([]);
    }

    function onClick_add() {
        if (var_document.organizationdocument === 'NO' && var_selected_assignments.length === 0) {
            set_errors([{property: 'assignments', description: t('Please select at least one assignment to add')}]);
            return;
        }
        if (var_fileupload_status === 'UPLOAD READY') {
            set_fileupload_status('START UPLOAD');
        } else if (var_fileupload_status !== 'UPLOAD FAIL') {
            set_errors([{property: 'document', description: t('Please select a document to upload')}]);
        }
    }

    function onChange_acknowledgement(value) {
        let document = {...var_document};
        document.ack_req = value;
        set_document(document);
    }

    function onChange_organizationdocument(value) {
        let document = {...var_document};
        document.organizationdocument = value;
        set_document(document);
        value === 'YES' && set_selected_assignments([]);
        set_errors([]);
    }

    function onChange_file(details) {
        set_document({...var_document, filename: details.filename, submittedfilename: details.display_name});
    }




    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_adddocument'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_adddocument'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_adddocument'>{t('Add document')}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_adddocument' aria-labelledby='hdr_adddocument' tabIndex='0'>

                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={t('There are some errors with your inputs.')}
                    />
                }

                {var_save_error &&
                    <Message error header={var_save_error} />
                }

                <FORM_TOGGLE
                    label={t('Acknowledgement')}
                    value={var_document.ack_req}
                    options={[
                        {value: 'YES', text: t('Required')},
                        {value: 'NO',text: t('Not required')}
                    ]}
                    onChange={onChange_acknowledgement}
                />

                <FORM_TOGGLE
                    label={t('Organization document')}
                    value={var_document.organizationdocument}
                    options={[
                        {value: 'YES', text: t('Yes')},
                        {value: 'NO',text: t('No')}
                    ]}
                    onChange={onChange_organizationdocument}
                />

                {var_document.organizationdocument === 'NO' &&
                    < FORM_SELECT_RADIO
                        name='assignments'
                        property='assignments'
                        label={t('Assignment')}
                        value={var_selected_assignments}
                        options={var_assignments}
                        single_or_multiple='MULTIPLE'
                        onChange={onChange_assignments}
                        placeholder={t('Search')}
                        disabled={false}
                        errors={var_errors}
                        aria_label={t('Select the assignments you want to add this document to.')}
                    />
                }

                <FORM_FILEUPLOAD
                    label={t('Document')}
                    property='document'
                    onChange={onChange_file}
                    onChange_upload={() => set_errors([])}
                    single_or_multiple='SINGLE'
                    var_fileupload_status={var_fileupload_status}
                    set_fileupload_status={set_fileupload_status}
                    uploaddirectory='documents'
                    allowed_fileextensions={['pdf']}
                    instructions={t('Only PDF file type is accepted')}
                    errors={var_errors}
                />

            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

}

import React, { useEffect, useState}  from 'react';
import API from 'libs/api-lib';
import { Button } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { useTranslation } from 'react-i18next';
import 'i18n';

import CMP_VC_AUTHENTICATOR from 'components/cmp_vc_authenticator/cmp_vc_authenticator';

import './crd_vc_issuance.css';



export default function CRD_VC_ISSUANCE( { credential }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_issuance_request, set_issuance_request ] = useState({});
    const [ var_addtowallet_open, set_addtowallet_open ] = useState(false);
    const [ var_addtowallet_processing, set_addtowallet_processing ] = useState(false);
    const [ var_addtowallet_step, set_addtowallet_step ] = useState({ title: '', instructions_line1: '', error_message: null });
    const [ var_disable_addtowallet_button, set_disable_addtowallet_button ] = useState(false);
    const [ var_sharetowallet_processing, set_sharetowallet_processing ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        // Ping callback API for state of VC wallet issuance
        if (var_addtowallet_open && var_issuance_request?.callback_id) {
            let repeat_ping_time = 5 * 1000; // Five seconds
            let interval = setInterval(() => {
                get_issuance_status();
            }, repeat_ping_time);
            return () => clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_issuance_request, var_addtowallet_open, var_addtowallet_step]);

    useEffect(() => {
        // Generate new QR after expiry
        if (var_addtowallet_open && var_issuance_request?.expiry) {
            let previous_QR_expiry = (var_issuance_request?.expiry - Math.floor(Date.now() / 1000)) * 1000;
            let interval = setInterval(async () => {
                set_addtowallet_processing(true);
                let issuance_request = await generate_QR();
                if (!issuance_request) {
                    console.error('Could not regenerate QR');
                }
                set_addtowallet_processing(false);
            }, previous_QR_expiry);
            return () => clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_issuance_request, var_addtowallet_open]);

    useEffect(() => {
        if (!var_addtowallet_open && var_focuselement?.id === 'btn_showqr') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    },[var_addtowallet_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function generate_QR() {
        let issuance_request;
        try {
            issuance_request = await API_post_credential_issuance();
            set_issuance_request(issuance_request);
        } catch (e) {
            console.error('Failed to generate QR');
            console.error(e);
        }
        return issuance_request;
    }

    async function get_issuance_status() {
        try {
            let result = await API_get_credential_issuance_callback();
            let requestStatus = result ? result.requestStatus : '';
            if (requestStatus === 'request_retrieved') { // User scanned the QR
                set_addtowallet_processing(true);
            } else if (requestStatus === 'issuance_successful') { // Credential was added to user's wallet
                onClose_hide_QR();
            } else if (requestStatus === 'issuance_error') {
                set_addtowallet_step({
                    ...var_addtowallet_step,
                    error_message: 'An error occurred while issuing this credential to your Authenticator. Please try again.',
                });
                set_addtowallet_processing(false);
            }
        } catch (e) {
            console.error('Failed to retrieve credential issuance status');
            console.error(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------
    function API_post_credential_issuance() {
        return API.post('credentials', '/post-credential-issuance/',
        {
            body: { individualcredential_id: credential.id }
        });
    }

    function API_get_credential_issuance_callback() {
        return API.get('credentials', `/get-credential-issuance-callback/${var_issuance_request.callback_id}`);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_show_QR() {
        set_sharetowallet_processing(true);
        set_focuselement(document.activeElement);
        let issuance_request = await generate_QR();
        let addtowallet_step = { title: 'Share to Authenticator' };
        if (issuance_request?.url) {
            addtowallet_step.instructions_line1 = 'Scan the QR code below with your mobile device. If viewing this on mobile, click the "Open Microsoft Authenticator" button.';
        } else {
            addtowallet_step.error_message = 'We are unable to generate the QR code at this time due to an unexpected error. Please close this window and try again.';
            set_disable_addtowallet_button(true);
        }
        set_addtowallet_step(addtowallet_step);
        set_addtowallet_open(true);
        set_sharetowallet_processing(false);
    }

    function onClose_hide_QR() {
        set_addtowallet_open(false);
        set_addtowallet_processing(false);
        set_issuance_request({});
        set_addtowallet_step({});
    }



    // RENDER APP ======================================================================================================

    return (
        !credential ?
        null
        :

        <div className='card rounded-lg shadow-sm'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('Microsoft Authenticator')}</div>
                    <div className='text--sm-regular'>{t('Share this credential to Authenticator.')}</div>
                </div>
            </div>

            <div className='card__content'>
                <Button className='primary' id='btn_showqr' onClick={onClick_show_QR}>{t('Share to Authenticator')}</Button>
            </div>

            <Processing display={var_sharetowallet_processing} processingtext={t('Processing')} />

            <CMP_VC_AUTHENTICATOR
                display={var_addtowallet_open}
                title={t(var_addtowallet_step.title)}
                instructions_line1={t(var_addtowallet_step.instructions_line1)}
                error_message={var_addtowallet_step.error_message && t(var_addtowallet_step.error_message)}
                disable_open_button={var_disable_addtowallet_button}
                target={var_issuance_request}
                processing={var_addtowallet_processing}
                onClose={onClose_hide_QR}
            />

        </div>
    )
}

import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useNavigate } from 'react-router-dom';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './tab_usage.css';

export default function TAB_USAGE({ credential, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_usage, set_usage ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'targetentity_name' });

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'USAGE' && credential.id) {
            populate_usage();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, credential]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_usage() {
        set_loading(true);
        set_loadingerror(false);

        try {
            let results = await API_get_usage();
            set_usage(results);
            set_loadingerror(false);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_usage() {
        return API.get('credentials', '/get-usage/' + credential.id);
    }


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_usage' tabIndex='0'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_credusage' className='text--xl-medium'>{t('Usage')}</div>
                    </div>
                </div>

                <Table  id='usage' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={false} totalrows={var_usage.length}
                        populateconfig={var_populateconfig} populatefilterfunction={() => null}
                        downloadname={t('Usage')} downloadfunction={() => null}
                        onChange={populate_usage}
                        hide_tablecontrols={true}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='targetentity_name' datatype='text' filtertype='text'>{t('DETAILS')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_usage.map(item =>
                            <Table.Row key={item.requirement_id + '-' + item.targetentity_id} onClick={item.targetentity_type === 'CREDENTIAL' ? () => navigate(`/credentials/ALL/${item.targetentity_id}`) : item.targetentity_type === 'LOCATION' ? () => navigate(`/assignments/${item.targetentity_id}`) : () => navigate('/requirements')}>
                                <Table.Cell className='cell__icon--right'>
                                    <Table.CellContentTwoLine
                                        secondary_text={
                                        (item.targetentity_type === 'CREDENTIAL')
                                            ? `${t('Submitted as a pre-qualifier for')} ${item.targetentity_name}`
                                            : `${t('Submitted to')} ${item.requirement_name} ${t('for')} ${item.targetentity_name}`
                                        }
                                        display_chevron={true}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

            </div>
        </>
    );
}
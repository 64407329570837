import React, { useState, useEffect } from 'react';
import { FORM_INPUT } from 'components/cmp_form/cmp_form';
import { Button, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';
import API from 'libs/api-lib';
import Processing from 'components/cmp_processing';


export default function CONTENT_FORGET_2({ onChangeContent }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_processing, set_processing ] = useState(false);
    const [ var_verificationcode, set_verificationcode ] = useState('');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_modal, set_modal ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('button:not([disabled]), input[type="text"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);


    useEffect(() => {
            set_modal(document.querySelector('#mdl_unlockvc'));
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function validate_token(token) {
        set_processing(true);
        try {
            let results = await API_get_wallet_token_validation(token);
            if (results.valid) {
                onChangeContent('FORGET_3');
            } else {
                set_errors([{ property: 'verificationcode', description: t('Invalid or expired verification code.') }]);
            }
        } catch (e) {
            console.log(e);
            set_errors([{ property: 'verificationcode', description: t('Invalid or expired verification code.') }]);
        } finally {
            set_processing(false);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_wallet_token_validation(token) {
        return API.get('identifiers', '/get-wallet-token-validation', {
            queryStringParameters: {
                token
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        set_errors([]);
        set_verificationcode(event.target.value);
    }

    function onClick_submit() {
        if (var_errors.length > 0) return;

        if (var_verificationcode.trim().length === 0) {
            set_errors([{ property: 'verificationcode', description: t('Verification code is required.') }]);
            return;
        }

        validate_token(var_verificationcode.trim());
    }

    function onClick_back() {
        onChangeContent('FORGET_1');
    }

    function onKeyPress(event) {
        if (event.charCode === 13) {
            onClick_submit();
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='modal__header'>
                <div className='modal__header__centre'>
                    <div className='display--xs-medium' id='hdr_unlock_wallet'>{t('Verification code')}</div>
                </div>
            </div>

            <Form className='modal__content center padding--lg' id='form_verification_code' aria-labelledby='hdr_unlock_wallet'>
                <div className='content__description'>
                    <div className='text--sm-regular'>{t('Please enter the verification code from the email to reset your wallet.')}</div>
                    <div className='text--sm-regular'>{t('This may result in the loss of some credentials, which will need to be added again.')}</div>
                </div>

                <FORM_INPUT
                    property='verificationcode'
                    label={t('Verification code')}
                    value={var_verificationcode || null}
                    onChange={onChange_input}
                    onKeyPress={onKeyPress}
                    placeholder=''
                    maxLength={64}
                    disabled={false}
                    errors={var_errors}
                    description=''
                />

                <Button type='button' className='primary' onClick={onClick_submit}>{t('Contine')}</Button>
                <Button type='button' className='tertiary' onClick={onClick_back}>{t('Back')}</Button>
            </Form>

            <Processing display={var_processing} processingtext='Processing' />
        </>
    );

}

import React, { useEffect,useState } from 'react';
import propTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import './cmp_unlock_wallet.css';

import CONTENT_UNLOCK from './content_unlock/content_unlock';
import CONTENT_FORGET_1 from './content_forget_1/content_forget_1';
import CONTENT_FORGET_3 from './content_forget_3/content_forget_3';
import CONTENT_FORGET_2 from './content_forget_2/content_forget_2';


function CMP_UNLOCK_WALLET({ display, onClose, reset_authentication }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const [ var_content, set_content ] = useState('UNLOCK');


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_content('UNLOCK');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);


    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_unlockvc'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_unlock_wallet'
        >

            {var_content === 'UNLOCK' &&
                <CONTENT_UNLOCK
                    onClose={onClose}
                    reset_authentication={reset_authentication}
                    onChangeContent={set_content}
                />
            }

            {var_content === 'FORGET_1' &&
                <CONTENT_FORGET_1
                    onChangeContent={set_content}
                />
            }

            {var_content === 'FORGET_2' &&
                <CONTENT_FORGET_2
                    onChangeContent={set_content}
                />
            }

            {var_content === 'FORGET_3' &&
                <CONTENT_FORGET_3
                    onChangeContent={set_content}
                    onClose={onClose}
                    reset_authentication={reset_authentication}
                />
            }
        </Modal>
    );
}

CMP_UNLOCK_WALLET.propTypes = {
    display: propTypes.bool.isRequired,
    onClose: propTypes.func,
    reset_authentication: propTypes.func
};

export default CMP_UNLOCK_WALLET;
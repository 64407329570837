import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Popup, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import 'i18n';


export default function CMP_IDENTITY_VERIFICATION_STATUS({ identity_verification, is_open, onClose, onOpen, onChange, onMessage, has_available_credits }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_popup, set_popup ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const POPUP_ID = (`mdl_identity_verification_status_${identity_verification?.id}`);

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open) {
            set_popup(document.querySelector(`#${POPUP_ID}`));
        } else if (var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (var_popup) {
            //  create list of focusable elements within the popup
            let elements = var_popup.querySelectorAll('form, input[type="checkbox"]:not([disabled]), button:not([disabled])');
            let firstelement = elements[0];
            let lastelement = elements[elements.length - 1];

            //  set focus to first element within the popup
            firstelement.focus({ preventScroll: true });

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_popup.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
    }, [var_popup]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function send_request() {
        set_processing(true);
        try {
            await API_post_verification_request();
            onMessage && onMessage('SUCCESS', t('Verification request sent.'));
            onChange && onChange(var_focuselement?.id);
        }  catch (e) {
            console.log(e);
        }
        set_processing(false);
    }

    async function cancel_request() {
        set_processing(true);
        try {
            await API_delete_verification_request();
            onMessage && onMessage('SUCCESS', t('Verification request canceled.'));
            onChange && onChange(var_focuselement?.id);
        } catch(e) {
            console.log(e);
        }
        set_processing(false);
    }

    function can_update() {
        return identity_verification.status !== 'DECLINED'
            && identity_verification.is_latest === 'YES'
            && (has_available_credits || (identity_verification.result !== 'SUCCESS' && identity_verification.result !== 'FAILED'));
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_verification_request() {
        return API.post('identity-verification', '/post-verification-request', {
            body: {
                individual_ids: [identity_verification.individual_id]
            }});
    }

    function API_delete_verification_request() {
        return API.del('identity-verification', '/delete-verification-request/' + identity_verification.id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_status() {
        set_focuselement(document.activeElement);
    }

    function onClick_updatestatus(statuspopup_value) {
        onMessage(null, null);
        switch (statuspopup_value) {
            case 'REQUEST':
                send_request();
                break;
            case 'CANCEL':
                cancel_request();
                break;
            default:
                break;
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <Popup trigger={
            <Button id={`btn_${POPUP_ID}`} onClick={onClick_status} type='button'
                className={'badge text--sm-medium ' + (identity_verification.result === 'SUCCESS' ? 'badge--green' : identity_verification.result === 'FAILED' ? 'badge--red' : 'badge--yellow')}
                disabled={!can_update()}>
                {identity_verification.status_display}
                {can_update() && <Icon name='badge_dropdown' className='badge__icon' />}
            </Button>}
                content={
                    (is_open && (identity_verification.status !== 'DECLINED' && (has_available_credits || (identity_verification.result !== 'SUCCESS' && identity_verification.result !== 'FAILED'))))
                        ?   <div className='badge__popup shadow-lg rounded-md' id={POPUP_ID} aria-modal='true' role='dialog' aria-labelledby={`hdr_${POPUP_ID}`} aria-label={t('Verification request status modal')}>
                                <div id={`hdr_${POPUP_ID}`} className='text--md-medium' aria-label={t('Update verification request status')}>{t('Change status')}</div>
                                <Form tabIndex='0' aria-labelledby={`hdr_${POPUP_ID}`}>
                                    {identity_verification.result === 'SUCCESS' ?
                                        <div className='badge__popup__option'>
                                            <Button className='tertiary' onClick={() => onClick_updatestatus('REQUEST')}>{t('Request another verification')}</Button>
                                        </div>
                                    : identity_verification.result === 'FAILED' ?
                                        <div className='badge__popup__option'>
                                            <Button className='tertiary' onClick={() => onClick_updatestatus('REQUEST')}>{t('Request another verification')}</Button>
                                        </div>
                                    :
                                        <div className='badge__popup__option'>
                                            <Button className='tertiary' onClick={() => onClick_updatestatus('CANCEL')}>{t('Cancel verification request')}</Button>
                                        </div>
                                    }
                               </Form>
                               <Processing display={var_processing} processingtext='' />
                           </div>
                       : null
               }
               on='click'
               open={is_open}
               onOpen={() => onOpen(identity_verification.id)}
               onClose={onClose}
               hideOnScroll
               position='bottom left' />
    );
}

import React, { useState, useEffect } from 'react';
import { Button, Message, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';
import PasswordStrengthBar from 'react-password-strength-bar';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import { generate_encryption_key, hash } from 'libs/vault-lib';
import { FORM_INPUT } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';



export default function CONTENT_FORGET_3({ onChangeContent, onClose, reset_authentication }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_processing, set_processing ] = useState(false);
    const [ var_passphrase, set_passphrase ] = useState('');
    const [ var_passscore, set_passscore ] = useState(0);
    const [ var_showpassword, set_showpassword ] = useState(false);

    const [ var_errors, set_errors ] = useState([]);
    const [ var_message_type, set_message_type ] = useState(null);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('button:not([disabled]), input[type="text"]:not([disabled]), input[type="password"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        set_modal(document.querySelector('#mdl_unlockvc'));
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function reset_wallet(did_id) {
        set_processing(true);

        try {
            await API_put_did_disabled(did_id);
            await reset_authentication();
            Window.$is_unlocked = null;
            Window.$kek = null;

            let hashed_passphrase = await hash(var_passphrase);
            let encryption_key = await generate_encryption_key(var_passphrase, hashed_passphrase.hash_algorithm, hashed_passphrase.iterations, hashed_passphrase.salt);
            await API_post_wallet(hashed_passphrase, encryption_key);
            await reset_authentication();
            onChangeContent('UNLOCK');
        } catch (exception) {
            set_message_type('WARNING');
            switch(exception?.response?.status) {
                case 400:
                    set_message_text(t('Please check your inputs and try again.'));
                    break;
                case 401:
                    set_message_text(t('Not Authorized'));
                    break;
                case 403:
                    set_message_text(t('Only one wallet can be setup per user'));
                    break;
                default:
                    set_message_type('ERROR');
                    set_message_text(t('There was a problem saving.  Please try again later'));
                    console.log(exception);
            }
        } finally {
            set_processing(false);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_wallet(passphrase_params, encryption_params) {
        return API.post('identifiers', '/wallet', {
            body: {
                passphrase: passphrase_params,
                encryption_key: encryption_params,
                type: 'ES256K'
            }
        });
    }

    function API_put_did_disabled(did_id) {
        return API.put('identifiers', `/did/${did_id}`, {
            body: {
                status: 'DISABLED'
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        set_errors([]);
        set_passphrase(event.target.value);
    }

    function onClick_submit() {
        let errors = [];
        if (var_passscore < 3) {
            errors.push({property: 'passphrase', description: t("In order to proceed, your passphrase must be rated as 'Good' or stronger")});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        if (!auth.passphrase_parameters[0].did_id) {
            set_message_type('ERROR');
            set_message_text(t('There was a problem saving.  Please try again later'));
        } else {
            reset_wallet(auth.passphrase_parameters[0].did_id);
        }

    }

    function onClick_cancel() {
        onClose();
    }

    function onKeyPress(event) {
        if (event.charCode === 13) {
            onClick_submit();
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='modal__header'>
                <div className='modal__header__centre'>
                    <div className='display--xs-medium'>{t('Create new passphrase')}</div>
                </div>
            </div>

            <Form className='modal__content center padding--lg' id='form_new_passphrase' aria-labelledby='hdr_unlock_wallet'>
                {var_message_text &&
                    <Message warning={var_message_type === 'WARNING'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'WARNING' ? 'warning' : 'error'} className='icon' />}
                        content={var_message_text} />
                }

                <div className='content__description'>
                    <div className='text--sm-regular'>{t('For added security, please create a unique passphrase that will be used only to access your wallet. This additional layer of security will help prevent any unauthorized access of your information.')}</div>
                </div>

                <FORM_INPUT
                    property='passphrase'
                    label='Passphrase'
                    value={var_passphrase || null}
                    onChange={onChange_input}
                    onKeyPress={onKeyPress}
                    placeholder={t('Create a passphrase')}
                    maxLength={64}
                    type={var_showpassword ? 'text' : 'password'}
                    disabled={false}
                    errors={var_errors}
                    description=''
                    icon={<Icon name={var_showpassword ? 'eyeslash' : 'eye'} className='icon' onClick={() => set_showpassword(!var_showpassword)}/>}
                    autocomplete='new-password'
                    aria_describedby='password_strength_message'
                    inputClassName='password-input'
                />

                <div className='strengthometer' id='password_strength_message' role='status' aria-live='polite'>
                    <PasswordStrengthBar
                        className='password_bar'
                        password={var_passphrase}
                        scoreWords={[t('Very Weak'), t('Weak'), t('Fair'), t('Good'), t('Strong')]}
                        barColors={['#D1D5DB', '#DC2626', '#F97316', '#34D399', '#059669']}
                        shortScoreWord='Too Short'
                        onChangeScore={set_passscore}
                        scoreWordClassName={`level-${var_passscore}`}
                    />
                </div>

                <div className='content__description'>
                    <div className='text--sm-medium'>{t('Your passphrase is the only way to unlock your wallet. If lost, your passphrase cannot be retrieved, so it is essential to remember it or store it safely.')}</div>
                </div>


                <Button type='button' className='primary' onClick={onClick_submit}>{t('Reset wallet')}</Button>
                <Button type='button' className='tertiary' onClick={onClick_cancel}>{t('Cancel')}</Button>
            </Form>

            <Processing display={var_processing} processingtext='Processing' />
        </>
    );

}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import datelib from 'libs/date-lib';
import Icon from 'components/cmp_icon';
import 'i18n';
import Processing from 'components/cmp_processing';

import MDL_CREATELINK from './mdl_createlink/mdl_createlink';
import MDL_UPDATE_EXPIRY from 'components/cmp_credential_sharing/mdl_update_expiry/mdl_update_expiry';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';

import './crd_sharing.css';



export default function CRD_SHARING({ assignment_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_sharing, set_sharing ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'expiry' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_processing, set_processing ] = useState(false);

    const [ var_modal_createlink_open, set_modal_createlink_open ] = useState(false);
    const [ var_link_notification, set_link_notification ] = useState(false);
    const [ var_selected_report, set_selected_report ] = useState(null);
    const [ var_modal_update_open, set_modal_update_open ] = useState(false);

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_delete_id, set_delete_id ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_shared_links();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var_link_notification && setTimeout(() => set_link_notification(false), 5000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_link_notification]);

    useEffect(() => {
        if (!var_modal_createlink_open && var_focuselement?.id === 'btn_createlink') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_modal_createlink_open]);

    useEffect(() => {
        if (!var_modal_update_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd__assignment_share').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_modal_update_open]);

    useEffect(() => {
        if (!var_mdl_confirmation_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd__assignment_share').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_confirmation_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_shared_links(populateconfig, focuselement_id) {
        set_loading(true);
        set_loadingerror(false);
        set_ready(false);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_shared_links(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_sharing(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd__assignment_share').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_shared_links(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_shared_links(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function delete_link() {
        set_focuselement(document.getElementById('crd__assignment_share'));
        set_mdl_confirmation_open(false);
        set_processing(true);
        try {
            await API_archive_external_report(var_delete_id);
            populate_shared_links();
        } catch(e) {
            console.log(e);
        }
        set_processing(false);
        set_delete_id(null);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_shared_links(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('location', '/get-shared-links/' + assignment_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_archive_external_report(id) {
        return API.put('external-report', '/put-archive/' + id + '/ASSIGNMENT');
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_delete(event, id) {
        set_focuselement(document.activeElement);
        event.stopPropagation()
        set_delete_id(id);
        set_mdl_confirmation_open(true);
    }

    function onClick_link(link) {
        navigator.clipboard.writeText(link);
        set_link_notification(true);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div id='crd__assignment_share' className='card rounded-lg shadow-sm' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_sharing' className='text--xl-medium'>{t('Share assignment')}</div>
                    </div>
                </div>

                <div className='text--sm-regular'>{t('Share this assignment with a temporary link. Choose the information you\'d like to share and anyone with this link will be able to view the assignment until it expires or you deactivate it.')}</div>

                <div className='card__header__btns'>
                    <Button id='btn_createlink' className='primary' onClick={() => {set_focuselement(document.activeElement); set_modal_createlink_open(true)}}>{t('Create link')}</Button>
                </div>

                {var_link_notification &&
                    <Message success={true} icon={<Icon name='checkmark' className='icon' alt='' />} header={t('Link has been copied to clipboard') + '.'} />
                }

                <Table id='assignments' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('Share assignment')} downloadfunction={download_records}
                       onChange={populate_shared_links}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='link' datatype='text' filtertype='text'>{t('LINK')}</Table.HeaderCell>
                            <Table.HeaderCell field='description' datatype='text' filtertype='text'>{t('DESCRIPTION')}</Table.HeaderCell>
                            <Table.HeaderCell field='expiry' datatype='datetime' filtertype='date'>{t('EXPIRY')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_sharing.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' onClick={() => onClick_link(item.link)} >
                                        {t('Copy link')}
                                        <Icon name='copy' className='icon__copy' alt='' />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.description}</Table.Cell>
                                <Table.Cell className='cell__datetime cell__icon--right text--anchor'>
                                    <Button id={`btn_edit_expiry_${item.id}`} className='tertiary' onClick={() => { set_selected_report(item); set_focuselement(document.activeElement);  set_modal_update_open(true); }} >
                                        {datelib.epoch_to_date_string(item.expiry, true) ?? t('No expiry')}
                                    </Button>
                                    <Button className='tertiary' onClick={(event) => onClick_delete(event, item.id)}>
                                        <Icon  name='delete' className='icon__delete__table' alt={t('Delete link')} />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button id='btn_createlink' className='primary' onClick={() => set_modal_createlink_open(true)}>{t('Create link')}</Button>
                </div>

                <MDL_CREATELINK
                    display={var_modal_createlink_open}
                    onClose={() => { set_modal_createlink_open(false) }}
                    assignment_id={assignment_id}
                    onChange={populate_shared_links}
                />

                <MDL_UPDATE_EXPIRY
                    display={var_modal_update_open}
                    onClose={() => { set_modal_update_open(false); set_selected_report(null); }}
                    onLinkUpdate={(focuselement_id) => populate_shared_links(null, focuselement_id)}
                    report={var_selected_report}
                    report_category='ASSIGNMENT'
                />

                <CMP_CONFIRMATION
                    display={var_mdl_confirmation_open}
                    title={t('Delete link')}
                    message={
                        <div style={{flexDirection: 'column'}}>
                            <div style={{marginBottom: '0.5rem'}}>{t('Deleting this link will disable viewing of the assignment by the individuals you have shared it with.')}</div>
                            <div>{t('This action can not be undone.')}</div>
                        </div>}
                    positive_option={t('Delete')}
                    negative_option={t('Cancel')}
                    onConfirm={delete_link}
                    onCancel={() => set_mdl_confirmation_open(false)}
                />

                <Processing display={var_processing} processingtext={t('Processing')} />


            </div>

        </>

    )

}

import config from 'config';
import auth from 'libs/auth-lib';
import axios, { AxiosError } from 'axios';

class API {
    get(apiName, path, init) {
        return send_request(this.endpoint(apiName), path, 'GET', init);
    }
    put(apiName, path, init) {
        return send_request(this.endpoint(apiName), path, 'PUT', init);
    }
    post(apiName, path, init) {
        return send_request(this.endpoint(apiName), path, 'POST', init);
    }
    del(apiName, path, init) {
        return send_request(this.endpoint(apiName), path, 'DELETE', init);
    }
    async upload(file, filename) {
        let access_token = await auth.iam.get_access_token();
        if (!access_token) {
            throw new Error('Not authenticated');
        }
        let form_data = new FormData();
        form_data.append('filename', filename);
        form_data.append('file', file);
        await axios({
            method: 'post',
            url: config.api_storage.url + '/upload',
            data: form_data,
            headers: {
                Authorization: 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    endpoint(apiName) {
        return config['api_' + apiName.replace(/-/g, '_')].url;
    }
}

/**
 * @param {Object} parameters
 * @returns {Object}
 */
function add_locale_to_request(parameters) {
    // if we already have locale in the request then do nothing
    if (parameters?.queryStringParameters?.locale) return;
    // determine the language based on user's preference if exists, otherwise the browser language
    let language = Object.keys(config.language).find(key => config.language[key].name === auth.language?.name);
    if (!language) {
        language = window.navigator.language.split('-')[0]?.toLowerCase();
        if (!language || !Object.keys(config.language).includes(language.toLowerCase())) {
            language = 'en';
        }
    }
    // add to parameters
    if (!parameters) {
        parameters = { queryStringParameters: { locale: language }};
    } else if (!parameters.queryStringParameters) {
        parameters.queryStringParameters = { locale: language };
    } else {
        parameters.queryStringParameters.locale = language;
    }
    return parameters;
}

async function send_request(base_path, path, method, parameters) {
    localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
    parameters = add_locale_to_request(parameters);
    let options = {
        method,
        url: base_path + path,
    };
    if (parameters && parameters.queryStringParameters) {
        options.params = parameters.queryStringParameters;
    }
    if (parameters && parameters.body) {
        options.data = parameters.body;
    }
    let access_token = await auth.iam.get_access_token();
    if (access_token) {
        options.headers = {
            Authorization: 'Bearer ' + access_token
        };
    }
    let response = await axios(options);
    if (response instanceof AxiosError) {
        let error = new Error();
        error.response = response;
    }
    return response.data;
}

let api = new API();

export default api;
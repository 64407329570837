import React from 'react';
import { Table, TableBody } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import CMP_CREDENTIAL_STATUS_ICON from 'components/cmp_credential_status_icon/cmp_credential_status_icon';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';

import './crd_credentials.css';
import CMP_CREDENTIALSTATUS from 'components/cmp_credentialstatus';

export default function CRD_CREDENTIALS({ credentials, token }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    //  event functions ------------------------------------------------------------------------------------------------
    function onClick_reportcertificate(reportcredential_id, has_customsecurity, confirmation_status, credential_id, report_id) {
        if (confirmation_status && has_customsecurity !== 'YES' && token) {
            credential_id
                ? window.open('/report/certificate/' + token + '/' + report_id + '/' + credential_id)
                : window.open('/report/certificate/' + token + '/' + reportcredential_id);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <section className='profile_section'>

                <div className='profile_section_header'>{t('Credentials')}</div>

                <div className='profile_section_body'>
                <div className='tablewrapper'>
                        <Table unstackable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('Status')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('Credential')}</Table.HeaderCell>
                                    <Table.HeaderCell className='td_issuedby'>{t('Issuer')}</Table.HeaderCell>
                                    <Table.HeaderCell className='td_issued'>{t('Issued')}</Table.HeaderCell>
                                    <Table.HeaderCell className='td_expires'>{t('Expires')}</Table.HeaderCell>
                                    <Table.HeaderCell className='td_verification'>{t('Confirmation')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <TableBody>
                                {credentials && credentials.length !== 0 && credentials.map((item, i) =>
                                    <Table.Row
                                        key={i}
                                        className={(item.verification_status === 'VALID' && item.confirmation_status !== 'SELF VERIFIED') ? 'self_verified' : ''}>
                                        <Table.Cell className='view_certificate mobile_view_display' textAlign='center'>
                                            <CMP_CREDENTIALSTATUS
                                                confirmation_status={item.confirmation_status}
                                                verification_status={item.verification_status}
                                                clickfor_reportcert={true}
                                                id={item.reportcredential_id}
                                                has_customsecurity={item.has_customsecurity}
                                                token={token}
                                            />
                                        </Table.Cell>
                                        <Table.Cell className='mobile_view_hide' textAlign='center'>
                                            <CMP_CREDENTIAL_STATUS_ICON confirmation_status={item.confirmation_status} is_self_verified={item.is_self_verified} className='icon__status' />
                                        </Table.Cell>
                                        <Table.Cell className='td_overflow td_credential text--anchor'
                                            onClick={() => onClick_reportcertificate(item.reportcredential_id, item.has_customsecurity, item.confirmation_status, item.id, item.report_id)}>
                                            {item.credential_name}
                                        </Table.Cell>
                                        <Table.Cell className='td_issuedby'>{item.issuer_name}</Table.Cell>
                                        <Table.Cell className='td_issued cell__datetime'>{item.issued && datelib.epoch_to_date_string(item.issued)}</Table.Cell>
                                        <Table.Cell className='td_expires cell__datetime'>{item.expires && datelib.epoch_to_date_string(item.expires)}</Table.Cell>
                                        <Table.Cell className='td_verification'>{item.confirmation_status_display}</Table.Cell>
                                    </Table.Row>
                                )}
                            </TableBody>
                        </Table>
                        {credentials.length === 0 &&
                            <div className='emptytable'>
                                <Icon name='alert' className='icon__status status-icon--yellow' alt={t('status icon')} />
                                {t('there are no credentials to display')}
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    );

}
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import form_helper from 'libs/form-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';
import auth from 'libs/auth-lib';
import { FORM_DETAILSGROUP, FORM_INPUT, FORM_TOGGLE, FORM_DATEPICKER } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import { useTranslation } from 'react-i18next';
import 'i18n';



export default function CRD_SETTINGS({ location_id, activetab, onChange, location_organization_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_assignment, set_assignment ] = useState({});
    const [ var_updated_assignment, set_updated_assignment ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_processing, set_processing ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'SETTINGS') {
            populate_settings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);



    //  async functions ------------------------------------------------------------------------------------------------


    async function populate_settings() {
        try {
            let results = await API_get_assignment_settings();
            set_assignment({ ...results,
                                startdate: results.startdate ? datelib.utcmoment_to_local_midnight_date(datelib.epoch_to_utcmoment(results.startdate)) : null,
                                enddate: results.enddate ? datelib.utcmoment_to_local_midnight_date(datelib.epoch_to_utcmoment(results.enddate )) : null,
                                default_org: !['YES', 'NO'].includes(results.default_org) ? 'NO' : results.default_org});
        } catch (e) {
            console.log(e);
        }
    }

    async function update_settings(updated_assignment) {
        set_processing(true);
        try {
            await API_put_assignment_settings(updated_assignment);
            populate_settings();
            onChange && onChange();
            set_mode('VIEW');
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_assignment_settings() {
        return API.get('location', '/get-assignment-settings/' + location_id);
    }

    function API_put_assignment_settings(updated_assignment) {
        return API.put('location', '/put-assignment-settings/' + location_id,
        { body: {
            ...updated_assignment,
            startdate: updated_assignment.startdate ? datelib.localdate_to_utc_midnight_epoch(updated_assignment.startdate) : null,
            enddate: updated_assignment.enddate ? datelib.localdate_to_utc_midnight_epoch(updated_assignment.enddate) : null
        }});
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_save_error(null);
        set_errors([]);
        set_updated_assignment({...var_assignment});
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_save_error(null);
        set_errors([]);
        set_mode('VIEW');
    }

    function onClick_save() {
        if (var_processing) return;
        set_save_error(null);
        let errors = [];
        let updated_assignment = {...var_updated_assignment};
        //validation
        let date_validation;
        if (updated_assignment.startdate) {
            date_validation = form_helper.validate_date(updated_assignment.startdate, null, updated_assignment.enddate);
            if (date_validation.is_less_than_minimum) {
                errors.push({ property: 'startdate', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
            } else if (date_validation.is_greater_than_maximum) {
                errors.push({ property: 'startdate', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
            }
        }
        if (updated_assignment.enddate) {
            date_validation = form_helper.validate_date(updated_assignment.enddate, updated_assignment.startdate);
            if (date_validation.is_less_than_minimum) {
                errors.push({ property: 'enddate', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
            } else if (date_validation.is_greater_than_maximum) {
                errors.push({ property: 'enddate', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
            }
        }
        set_errors(errors)
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(updated_assignment, 'area');
        form_helper.cleanse_string_property(updated_assignment, 'businessunit');
        form_helper.cleanse_string_property(updated_assignment, 'code');
        form_helper.cleanse_string_property(updated_assignment, 'costobject');

        set_updated_assignment(updated_assignment);
        update_settings(updated_assignment);
    }

    function onChange_input(e, { name, value }) {
        set_save_error(null);
        set_errors([]);
        let updated_assignment = {...var_updated_assignment};
        updated_assignment[name] = value;
        set_updated_assignment(updated_assignment);
    }

    function onChange_default(value) {
        set_save_error(null);
        set_errors([]);
        let updated_assignment = {...var_updated_assignment};
        updated_assignment.default_org = value;
        set_updated_assignment(updated_assignment);
    }




    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_settings' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div id='hdr_settings' className='text--xl-medium'>{t('Assignment settings')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    {var_mode === 'VIEW' && auth.orgaccess_organization_ids('ORG-LOC-ADMIN', 'write').includes(location_organization_id) &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {var_mode === 'VIEW' ?
                <>
                    <div className='card__content'>
                        <FORM_DETAILSGROUP label={t('Default assignment')} value={var_assignment.default_org && var_assignment.default_org.charAt(0).toUpperCase() + var_assignment.default_org.slice(1).toLowerCase()}/>
                        <FORM_DETAILSGROUP label={t('Business unit')} value={var_assignment.businessunit} />
                        <FORM_DETAILSGROUP label={t('Area')} value={var_assignment.area} />
                        <FORM_DETAILSGROUP label={t('Cost object')} value={var_assignment.costobject} />
                        <FORM_DETAILSGROUP label={t('Code')} value={var_assignment.code} />
                        <FORM_DETAILSGROUP label={t('Start date')} value={var_assignment.startdate && datelib.epoch_to_date_string(datelib.date_to_epoch(var_assignment.startdate))} />
                        <FORM_DETAILSGROUP label={t('End date')} value={var_assignment.enddate && datelib.epoch_to_date_string(datelib.date_to_epoch(var_assignment.enddate))} />
                    </div>

                    {auth.orgaccess_organization_ids('ORG-LOC-ADMIN', 'write').includes(location_organization_id) &&
                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={() => onClick_edit()}>{t('Edit')}</Button>
                    </div>
                    }

                </>
                : var_mode === 'EDIT' ?
                    <>
                        <Form className='card__content'>

                            {var_save_error &&
                                <Message error header={var_save_error} />
                            }
                            {var_errors.length > 0 &&
                                <Message error
                                    icon={<Icon name='error' className='icon' />}
                                    header={t('There are some errors with your inputs.')}
                                />
                            }

                            <FORM_TOGGLE
                                label={t('Default assignment')}
                                value={var_updated_assignment.default_org}
                                options={[{value: 'YES', text: t('Yes')}, {value: 'NO', text: t('No')}]}
                                onChange={onChange_default}
                                description={t('There can only be one default assignment for an organization. If one already exists this will override it to become the new default assignment.')}
                            />

                            <FORM_INPUT
                                property='businessunit'
                                label={t('Business unit')}
                                value={var_updated_assignment.businessunit}
                                onChange={onChange_input}
                                placeholder={t('Business unit')}
                                maxLength={100}
                                disabled={false}
                                errors={[]}
                            />

                            <FORM_INPUT
                                property='area'
                                label={t('Area')}
                                value={var_updated_assignment.area}
                                onChange={onChange_input}
                                placeholder={t('Area')}
                                maxLength={100}
                                disabled={false}
                                errors={[]}
                            />

                            <FORM_INPUT
                                property='costobject'
                                label={t('Cost object')}
                                value={var_updated_assignment.costobject}
                                onChange={onChange_input}
                                placeholder={t('Cost object')}
                                maxLength={100}
                                disabled={false}
                                errors={[]}
                            />

                            <FORM_INPUT
                                property='code'
                                label={t('Code')}
                                value={var_updated_assignment.code}
                                onChange={onChange_input}
                                placeholder={t('Code')}
                                maxLength={100}
                                disabled={false}
                                errors={[]}
                            />

                            <FORM_DATEPICKER
                                property='startdate'
                                label={t('Start date')}
                                value={var_updated_assignment.startdate || ''}
                                onChange={(e, { value }) => onChange_input(e, {name: 'startdate', value })}
                                placeholder={t('YYYY-MM-DD')}
                                minproperty={null}
                                maxproperty={var_updated_assignment.enddate}
                                errors={var_errors}
                            />

                            <FORM_DATEPICKER
                                property='enddate'
                                label={t('End date')}
                                value={var_updated_assignment.enddate || ''}
                                onChange={(e, { value }) => onChange_input(e, {name: 'enddate', value })}
                                placeholder={t('YYYY-MM-DD')}
                                minproperty={var_updated_assignment.startdate}
                                maxproperty={null}
                                errors={var_errors}
                            />

                        </Form>

                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                            <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                        </div>

                        <Processing display={var_processing} processingtext={t('Processing')} />

                    </>

                    : null

            }
        </div>
    );
}
//  OUTCOMES -----------------------------------------------------------------------------------------------------------
//
//  - displays a questionnaire
//
//  --------------------------------------------------------------------------------------------------------------------

import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import 'i18n';

import './crd_questionnaire.css';

function CRD_QUESTIONNAIRE ({questions, indv_type, onChange_complete, onChange_passfail, onChange_quarantinedays}) {
    //#region  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const [ var_internal_questions, set_internal_questions ] = useState([]);

    //#endregion


    //#region  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        set_internal_questions(questions.map(item => ({...item})));
    }, [questions, indv_type])

    useEffect(() => {
        let results = calculate_response();
        onChange_complete(!results.incomplete);
        onChange_passfail(results.passfail);
        onChange_quarantinedays(results.quarantinedays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_internal_questions]);

    //#endregion


    //#region  async functions ------------------------------------------------------------------------------------------------

    function calculate_response(dependent_on_question_id = null, response = null) {
        let fail = false;
        let quarantinedays = 0;
        let incomplete = false;

        var_internal_questions.filter(item => item.dependent_on_question_id === dependent_on_question_id && item.dependent_on_response === response &&
            (item.visible_to_individual_type === 'ALL' || item.visible_to_individual_type === indv_type)).forEach(item => {
            incomplete = !item.response || incomplete;
            if (item.response === 'YES' && item.fail_on_yes === 'YES') {
                fail = true;
                quarantinedays = Math.max(quarantinedays, item.yes_quarantine_days);
            } else if (item.response === 'NO' && item.fail_on_no === 'YES') {
                fail = true;
                quarantinedays = Math.max(quarantinedays, item.no_quarantine_days);
            } else {
                let results = calculate_response(item.id, item.response);
                fail = fail || results.passfail === 'FAIL';
                quarantinedays = Math.max(quarantinedays, results.quarantinedays);
                incomplete = results.incomplete || incomplete;
            }
        });
        let passfail = fail ? 'FAIL' : 'PASS';

        return ({passfail, quarantinedays, incomplete});
    }

    //#endregion


    //#region  API calls ------------------------------------------------------------------------------------------------------


    //#endregion


    //#region  event functions ------------------------------------------------------------------------------------------------

    function onClick_response(state, id) {
        let questions = var_internal_questions.map(item => ({...item}));
        questions.find(item => item.id === id).response = state;
        set_internal_questions(questions);
    }

    //#endregion


    //#region  RENDER APP ======================================================================================================
    return(
        <div id='crd_questionnaire' style={ {flexDirection: 'column'} }>
            {var_internal_questions.filter(item => item.dependent_on_question_id === null)
                .sort((a,b) => a.questionorder - b.questionorder)
                .map((item) => render_question(item))}
        </div>
    );
    //#endregion


    function render_question(question) {
        return (
            <React.Fragment key={question.id}>
            {(question.visible_to_individual_type === 'ALL' || question.visible_to_individual_type === indv_type) &&
                <div className='question__wrapper'>
                    <div className='text--sm-medium'>{question.question}</div>
                    <TOGGLE value={question.response} onChange={(state) => onClick_response(state, question.id)} options={[{value: 'YES', text: t('Yes')}, {value: 'NO', text: t('No')}]} />
                    {/*<div className='hr' />*/}
                </div>
            }
            {var_internal_questions.filter(item => item.dependent_on_question_id === question.id && item.dependent_on_response === question.response)
                .sort((a,b) => a.questionorder - b.questionorder)
                .map(item => render_question(item))}
            </React.Fragment>
        );
    }
}

CRD_QUESTIONNAIRE.propTypes = {
    questions: propTypes.array,
    indv_type: propTypes.oneOf(['GUEST', 'USER']),
    onChange_complete: propTypes.func,
    onChange_passfail: propTypes.func,
    onChange_quarantinedays: propTypes.func
};

export default CRD_QUESTIONNAIRE;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import API from 'libs/api-lib';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';

import 'i18n';



export default function CRD_PACKAGES() {

//  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_packages, set_packages ] = useState([]);
    const [ var_status, set_status ] = useState('ACTIVE');
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'package' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_packages({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_status]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_packages(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_packages(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_packages(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
            if (var_focuselement?.id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${var_focuselement.id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_requirements_package').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
            }
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_packages(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_packages(limit, offset) {

        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_packages(limit, offset, sortby, sortorder, filter)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_packages(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-org-packages/' + var_status,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_packagestatus(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_status(status);
    }

    function onClick_createpackage() {
        navigate('/org/package/create');
    }

    function onClick_package(id) {
        navigate('/org/packages/' + id);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_requirements_package' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_packages' className='text--xl-medium'>{t('Packages')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button className='primary' onClick={onClick_createpackage}>{t('Create package')}</Button>
                    </div>
                </div>

                <Table id='packages' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('packages')} downloadfunction={download_packages}
                       onChange={populate_packages}>
                    <Table.OptionsButtons>
                        <TOGGLE value={var_status} onChange={onClick_packagestatus} options={[{value: 'ACTIVE', text: t('Active')}, {value: 'ARCHIVE', text: t('Inactive')}]} />
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='package' datatype='text' filtertype='text'>{t('PACKAGE')}</Table.HeaderCell>
                            <Table.HeaderCell field='requirements' datatype='text' filtertype='text'>{t('REQUIREMENTS')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_packages.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' onClick={() => onClick_package(item.id)}>
                                        {item.package}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.requirements}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button className='primary' onClick={onClick_createpackage}>{t('Create package')}</Button>
                </div>

            </div>
        </>

    )
}
import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import './cmp_displayrows.css'


function CMP_DISPLAYROWS({ ready, offset, limit, totalrows }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const var_activepage = () => Math.floor(offset / limit) + 1;
    const var_displaystart = () => (var_activepage() - 1) * limit + 1;
    const var_displayend = () => var_activepage() * limit > totalrows ? totalrows : var_activepage() * limit;



    //  variable listeners ---------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <div className='displayrows text--sm-regular'>
            {totalrows > 0 && limit && ready &&
            <>
                {totalrows <= limit ?
                    <div>{t('Showing')} <div className='text--sm-bold'>{totalrows}</div> {totalrows !== 1 ? t('Results') : t('Result')}</div>
                    :
                    <div>{t('Showing')} <div className='text--sm-bold'>{var_displaystart()}</div>
                        {t('to')} <div className='text--sm-bold'>{var_displayend()}</div>
                        {t('of')} <div className='text--sm-bold'>{totalrows}</div> {t('Results')}</div>
                }
            </>
            }
        </div>
    );
}

CMP_DISPLAYROWS.propTypes = {
    offset: propTypes.number.isRequired,
    limit: propTypes.number.isRequired,
    ready: propTypes.bool.isRequired,
    totalrows: propTypes.number.isRequired
};

export default CMP_DISPLAYROWS;
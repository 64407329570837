import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import datelib from 'libs/date-lib';
import 'i18n';



export default function CRD_TRANSCRIPTS(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_transcripts, set_transcripts ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'transcript_name', sortorder: 'ascending' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect( () => {
        populate_transcripts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_transcripts(populateconfig) {

        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_all_transcripts(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_transcripts(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_all_transcripts(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_transcripts(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_all_transcripts(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_all_transcripts(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('transcripts', '/get-all-transcripts',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_transcript(transcript_id) {
        navigate(`/transcripts/${transcript_id}`);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_transcripts' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_transcripts' className='text--xl-medium'>{t('A record of your educational achievements')}</div>
                    </div>
                </div>

                <Table id='transcripts' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Transcripts')} downloadfunction={download_transcripts}
                        onChange={populate_transcripts}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='transcript_name' datatype='text' filtertype='text'>{t('TRANSCRIPT')}</Table.HeaderCell>
                            <Table.HeaderCell field='issuer_name' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                            <Table.HeaderCell field='issued' datatype='date' filtertype='date'>{t('ISSUED')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_transcripts.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' onClick={() => onClick_transcript(item.id)}>{item.transcript_name}</Button>
                                </Table.Cell>
                                <Table.Cell>{item.issuer_name}</Table.Cell>
                                <Table.Cell>{datelib.epoch_to_date_string(item.issued)}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>
        </>
    )

}

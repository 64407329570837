import React from 'react';
import propTypes from 'prop-types';
import auth from 'libs/auth-lib';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './crd_password.css';


function CRD_CHANGEPASSWORD({ sso_enabled }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    function onClick_change_password() {
        auth.iam.change_password();
    }

    // RENDER APP ======================================================================================================

    return (

        <div id='crd__password'>

            {sso_enabled !== null &&
                <div className='card rounded-lg shadow-sm'>
                    <div className='card__header'>
                        <div className='card__header__left text'>
                            <div id='hdr_password' className='text--xl-medium'>{t('Change password')}</div>
                            <div className='text--sm-regular'>{sso_enabled ? t('Password changes are disabled as your organization employs single sign-on.') : t('You will be redirected outside the application to change your password.')}</div>
                        </div>
                        <div className='card__header__right card__header__btns'>
                                <Button className={'secondary' + (sso_enabled ? ' disabled' : '')} disabled={sso_enabled} onClick={onClick_change_password}>{t('Change password')}</Button>
                        </div>
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className={'secondary' + (sso_enabled ? ' disabled' : '')} disabled={sso_enabled} onClick={onClick_change_password}>{t('Change password')}</Button>
                    </div>
                </div>
            }
        </div>
    );
}

CRD_CHANGEPASSWORD.propTypes = {
    sso_enabled: propTypes.bool
};

export default CRD_CHANGEPASSWORD;

import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';
import auth from 'libs/auth-lib';
import 'i18n';

import MDL_DAILYCHECK from 'components/cmp_dailycheck/mdl_dailycheck/mdl_dailycheck';
import CMP_NOTIFICATIONS from 'components/cmp_notifications/cmp_notifications';

import './tab_overview.css';



export default function TAB_OVERVIEW({ assignment, onChange, activetab, onChangeTab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_dailycheck_status, set_dailycheck_status ] = useState(null);
    const [ var_onsite_status, set_onsite_status ] = useState(null);
    const [ var_requirements_status, set_requirements_status ] = useState(null);
    const [ var_summary, set_summary ] = useState(null);
    const [ var_mdl_dailycheck_open, set_mdl_dailycheck_open ] = useState(false);

    const [ var_notifications_refresh, set_notifications_refresh ] = useState(true);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'OVERVIEW' && assignment.location_id) {
            set_notifications_refresh(!var_notifications_refresh);
            populate_summary();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, assignment.location_id]);

    useEffect(() => {
        if (auth.has_access(assignment, 'INDV-DAILY', 'daily')) {
            populate_dailycheck_status();
        } else if (auth.has_access(assignment, 'INDV-ONSITE', 'onsite')) {
            populate_onsite_status();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment.location_id]);

    useEffect(() => {
        if (auth.has_access(assignment, 'INDV-REQ', 'requirement')) {
            populate_requirements_status();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment.location_id]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_dailycheck_status() {
        set_dailycheck_status(await API_get_dailycheck_status());
    }

    async function populate_onsite_status() {
        set_onsite_status(await API_get_onsite_status());
    }

    async function populate_requirements_status() {
        set_requirements_status(await API_get_requirements_status());
    }

    async function populate_summary() {
        set_summary(auth.has_access(assignment, 'ORG-LOC', 'summary') ? await API_get_summary() : null);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_dailycheck_status() {
        return API.get('dailycheck', '/get-dailycheck-status/' + assignment.location_id, { queryStringParameters: { tz: datelib.timezone }});
    }

    function API_get_onsite_status() {
        return API.get('onsite', '/get-onsite-status/' + assignment.location_id, { queryStringParameters: { tz: datelib.timezone }});
    }

    function API_get_requirements_status() {
        return API.get('requirements', '/get-requirement-status/' + assignment.location_id, { queryStringParameters: { tz: datelib.timezone }});
    }

    function API_get_summary() {
        return API.get('location', '/get-summary/' + assignment.location_id, { queryStringParameters: { tz: datelib.timezone }});
    }

    function API_post_onsite_status(status) {
        return API.post('onsite', '/post-onsite', {
            body: {
                location_id: assignment.location_id,
                status
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_dailycheck() {
        // open modal for daily check
        set_mdl_dailycheck_open(true);
    }

    function onClose_dailycheck(new_status) {
        if (new_status) {
            // if status changed from pass to fail or fail/missing to pass, then need to update parent page
            if ((var_dailycheck_status === 'PASS') !== (new_status === 'PASS')) {
                onChange && onChange();
            }
            set_dailycheck_status(new_status);
            set_notifications_refresh(!var_notifications_refresh);
            populate_summary();
        }
        set_mdl_dailycheck_open(false);
    }

    async function onClick_onsite(status) {
        let update_parent = var_onsite_status === 'MISSING';
        set_onsite_status(status);
        await API_post_onsite_status(status);
        update_parent && onChange && onChange();
        set_notifications_refresh(!var_notifications_refresh);
        populate_summary();
    }

    function onClick_requirements() {
        // go to requirements tab
        onChangeTab('REQUIREMENTS');
    }

    function onClick_summaryitem(item, module, access) {
        auth.has_access(assignment, module, access) && onChangeTab(item);
    }

    function onChange_notifications(type) {
        switch (type) {
            case 'dailycheck':
                populate_dailycheck_status();
                break;
            case 'requirement':
                populate_requirements_status();
                break;
            default:
                break;
        }
        onChange && onChange();
        populate_summary();
    }

    function onKeyDown_requirements(e) {
        if (e.key === 'Enter') {
            onClick_requirements();
        }
    }

    function onKeyDown_daily_check(e) {
        if (e.key === 'Enter') {
            onClick_dailycheck();
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            {auth.has_access(assignment, 'ORG-LOC', 'summary') &&
                <div className='card rounded-lg shadow-sm' id='crd__assignment_overview_summary' tabIndex='0'>

                    <div className='card__header'>
                        <div className='card__header__left text'>
                            <div id='hdr_summary' className='text--xl-medium'>{assignment.status === 'DRAFT' ? t('Assignment summary') : (t('Today\'s Summary for') + ' ' + assignment.location_name)}</div>
                        </div>
                    </div>

                    {var_summary && var_summary.onsite &&
                        <div className='summary__wrapper' onClick={() => onClick_summaryitem('INDIVIDUALS', 'ORG-INDV', 'read')}>
                            <div className='summary__items'>
                                <div className='summary__text text--sm-medium'>{t('On-site')}</div>
                                <div>
                                    {display_summary_value('On-site', var_summary.onsite.onsite, '--color-green-500')}
                                    {display_summary_value('Remote', var_summary.onsite.remote, '--color-yellow-500')}
                                    {display_summary_value('Missing', var_summary.onsite.missing, '--color-red-500')}
                                </div>
                            </div>
                            <Button className='tertiary cell__icon__chevron'>
                                <Icon name='chevron_right' className='cell__icon__chevron cell__icon__chevron--right color--primary-500' alt='' />
                            </Button>
                        </div>
                    }

                    {var_summary && var_summary.dailycheck &&
                        <div className='summary__wrapper' onClick={() => onClick_summaryitem('DAILYCHECKS', 'ORG-DAILY', 'read')}>
                            <div className='summary__items'>
                                <div className='summary__text text--sm-medium'>{t('Daily check')}</div>
                                <div>
                                    {display_summary_value('Pass', var_summary.dailycheck.pass, '--color-green-500')}
                                    {display_summary_value('Missing', var_summary.dailycheck.missing, '--color-yellow-500')}
                                    {display_summary_value('Failed', var_summary.dailycheck.fail, '--color-red-500')}
                                </div>
                            </div>
                            <Button className='tertiary cell__icon__chevron'>
                                <Icon name='chevron_right' className='cell__icon__chevron cell__icon__chevron--right color--primary-500' alt='' />
                            </Button>
                        </div>
                    }

                    {var_summary && var_summary.documents &&
                        <div className='summary__wrapper' onClick={() => onClick_summaryitem('DOCUMENTS', 'ORG-DOC', 'read')}>
                            <div className='summary__items'>
                                <div className='summary__text text--sm-medium'>{t('Documents')}</div>
                                <div>
                                    {display_summary_value('Acknowledged', var_summary.documents.acknowledged, '--color-green-500')}
                                    {display_summary_value('Missing', var_summary.documents.missing, '--color-red-500')}
                                </div>
                            </div>
                            <Button className='tertiary cell__icon__chevron'>
                                <Icon name='chevron_right' className='cell__icon__chevron cell__icon__chevron--right color--primary-500' alt='' />
                            </Button>
                        </div>
                    }

                    {var_summary && var_summary.requirements &&
                        <div className='summary__wrapper' onClick={() => auth.has_access(assignment, 'INDV-REQ', 'requirement') ? onClick_summaryitem('REQUIREMENTS', 'INDV-REQ', 'requirement') : onClick_summaryitem('REQUIREMENTS', 'ORG-REQ', 'read')}>
                            <div className='summary__items'>
                                <div className='summary__text text--sm-medium'>{t('Requirements')}</div>
                                <div>
                                    {display_summary_value('Valid', var_summary.requirements.valid, '--color-green-500')}
                                    {display_summary_value('Pending', var_summary.requirements.pending, '--color-yellow-500')}
                                    {display_summary_value('Invalid', var_summary.requirements.invalid, '--color-red-500')}
                                    {display_summary_value('Requested', var_summary.requirements.requested, '--color-gray-500')}
                                </div>
                            </div>
                            <Button className='tertiary cell__icon__chevron'>
                                <Icon name='chevron_right' className='cell__icon__chevron cell__icon__chevron--right color--primary-500' alt='' />
                            </Button>
                        </div>
                    }

                    {var_summary && var_summary.credentials &&
                        <div className='summary__wrapper' onClick={() => onClick_summaryitem('CREDENTIALS', 'ORG-CRED', 'read')}>
                            <div className='summary__items'>
                                <div className='summary__text text--sm-medium'>{t('Credentials')}</div>
                                <div>
                                    {display_summary_value('Valid', var_summary.credentials.valid, '--color-green-500')}
                                    {display_summary_value('Pending', var_summary.credentials.pending, '--color-yellow-500')}
                                    {display_summary_value('In progress', var_summary.credentials.inprogress, '--color-yellow-500')}
                                    {display_summary_value('Invalid', var_summary.credentials.invalid, '--color-red-500')}
                                    {display_summary_value('Expiring', var_summary.credentials.expiring, '--color-gray-500')}
                                </div>
                            </div>
                            <Button className='tertiary cell__icon__chevron'>
                                <Icon name='chevron_right' className='cell__icon__chevron cell__icon__chevron--right color--primary-500' alt='' />
                            </Button>
                        </div>
                    }
                </div>
            }

            <div className='overview__wrapper'>
                {auth.has_access(assignment, 'INDV-DAILY', 'daily') &&
                    <div className='card rounded-lg shadow-sm crd__overview' onClick={onClick_dailycheck} id='crd_dailycheck' tabIndex='0' onKeyDown={onKeyDown_daily_check}>
                        <div className='card__header'>
                            <div className='card__header__left text'>
                                <div className='text--lg-medium'>
                                    {t('Daily check')}
                                    <Icon name='chevron_right' className='cell__icon__chevron cell__icon__chevron--right color--primary-500' alt='' />
                                </div>
                                <div className='text--sm-regular'>{t('Verify your ability to work')}</div>
                                {var_dailycheck_status &&
                                    <div className={'overview__details ' + (var_dailycheck_status === 'PASS' ? 'color--green-700' : 'color--red-600')}>
                                        <Icon name={'dailycheck_' + var_dailycheck_status.toLowerCase()} alt='' />
                                        {var_dailycheck_status === 'MISSING' ? t('Not submitted') : var_dailycheck_status === 'PASS' ? t('You are cleared to work today') : t('You are not cleared to work today')}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {!auth.has_access(assignment, 'INDV-DAILY', 'daily') && auth.has_access(assignment, 'INDV-ONSITE', 'onsite') &&
                    <div className='card rounded-lg shadow-sm crd__overview' id='crd_onsite' tabIndex='0'>
                        <div className='card__header'>
                            <div className='card__header__left text'>
                                <div className='text--lg-medium'>
                                    {t('I am working on-site today')}
                                </div>
                                {
                                    var_onsite_status === 'YES'
                                    ? <div className='text--sm-regular'>{t('Yes')}</div>
                                    : var_onsite_status === 'NO'
                                    ? <div className='text--sm-regular'>{t('No')}</div>
                                    : var_onsite_status === 'MISSING'
                                    ? <TOGGLE value='MISSING' onChange={onClick_onsite} options={[{value: 'YES', text: t('Yes')}, {value: 'NO', text: t('No')}]} />
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                }

                {(auth.has_access(assignment, 'INDV-REQ', 'requirement') || auth.has_access(assignment, 'ORG-REQ', 'read')) && assignment.status !== 'DRAFT' &&
                    <div className='card rounded-lg shadow-sm crd__overview' onClick={onClick_requirements} id='crd_requirements' tabIndex='0' onKeyDown={onKeyDown_requirements}>
                        <div className='card__header'>
                            <div className='card__header__left text'>
                                <div className='text--lg-medium'>
                                    {t('Requirements')}
                                    <Icon name='chevron_right' className='cell__icon__chevron cell__icon__chevron--right color--primary-500' alt='' />
                                </div>
                                <div className='text--sm-regular'>{t('Credentials required for this assignment')}</div>
                                {var_requirements_status &&
                                    <div className={'overview__details ' + (var_requirements_status === 'COMPLETE' ? 'color--green-700' : 'color--red-600')}>
                                        <Icon name={'requirements_' + var_requirements_status.toLowerCase()} alt='' />
                                        {var_requirements_status === 'COMPLETE' ? t('You are cleared to work') : t('You are missing credentials')}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

            {assignment.status !== 'DRAFT' &&
                <CMP_NOTIFICATIONS assignment={assignment} refresh={var_notifications_refresh} onChange={onChange_notifications} />
            }

            <MDL_DAILYCHECK
                location_id={assignment.location_id}
                location_name={assignment.location_name}
                display_onsite={auth.has_access(assignment, 'INDV-ONSITE', 'onsite')}
                is_open={var_mdl_dailycheck_open}
                onClose={onClose_dailycheck}
            />

        </>
    );

    function display_summary_value(label, value, color) {
        return (
            <div className='summary__item'>
                <div className='summary__item__value text--md-bold' style={{backgroundColor: 'var(' + color + ')'}}>{value}</div>
                <div className='summary__item__label text--xs-regular'>{t(label)}</div>
            </div>
        );
    }

}

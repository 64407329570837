import React, { useEffect, useState } from 'react';
import form_helper from 'libs/form-lib';
import { useNavigate } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';
import { FORM_DATEPICKER, FORM_DETAILSGROUP, FORM_INPUT, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import 'i18n';
import Processing from "components/cmp_processing";


export default function CRD_SETTINGS({ step, onChange_step, assignment, onChange_assignment, onChange_processing, display_processing }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_opened, set_opened ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (step === 'SETTINGS') {
            set_opened(true);
            set_save_error(null);
            set_errors([]);
            if (!assignment.default_org) {
                let updated_assignment = {...assignment};
                updated_assignment.default_org = 'NO';
                onChange_assignment(updated_assignment);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_assignment(updated_assignment) {
        onChange_processing(true);
        try {
            let assignment_id = await API_post_assignment(updated_assignment);
            navigate('/assignments/' + assignment_id.toUpperCase());
        } catch (exception) {
            console.log(exception);
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
        onChange_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_assignment(assignment) {
        return API.post('location', '/post-assignment',
        { body: {
            ...assignment,
            startdate: assignment.startdate ? datelib.localdate_to_utc_midnight_epoch(assignment.startdate) : null,
            enddate: assignment.enddate ? datelib.localdate_to_utc_midnight_epoch(assignment.enddate) : null
        }});
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        onChange_step('SETTINGS');
    }

    function onChange_default(value) {
        set_save_error(null);
        set_errors([]);
        let updated_assignment = {...assignment};
        updated_assignment.default_org = value;
        onChange_assignment(updated_assignment);
    }

    function onChange_input(e, { name, value }) {
        set_save_error(null);
        set_errors([]);
        let updated_assignment = {...assignment};
        updated_assignment[name] = value;
        onChange_assignment(updated_assignment);
    }

    function onClick_saveassignment() {
        set_save_error(null);
        let errors = [];
        let updated_assignment = {...assignment};
        //validation
        if (!form_helper.validate_required_string(updated_assignment.name)) {
            set_save_error(t('Missing required field from Step 1'));
            return;
        }
        let date_validation;
        if (updated_assignment.startdate) {
            date_validation = form_helper.validate_date(updated_assignment.startdate, null, updated_assignment.enddate);
            if (date_validation.is_less_than_minimum) {
                errors.push({ property: 'startdate', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
            } else if (date_validation.is_greater_than_maximum) {
                errors.push({ property: 'startdate', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
            }
        }
        if (updated_assignment.enddate) {
            date_validation = form_helper.validate_date(updated_assignment.enddate, updated_assignment.startdate);
            if (date_validation.is_less_than_minimum) {
                errors.push({ property: 'enddate', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
            } else if (date_validation.is_greater_than_maximum) {
                errors.push({ property: 'enddate', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
            }
        }
        set_errors(errors)
        if (errors.length > 0) return;

        form_helper.cleanse_string_property(updated_assignment, 'name');
        form_helper.cleanse_string_property(updated_assignment, 'address');
        form_helper.cleanse_string_property(updated_assignment, 'city');
        form_helper.cleanse_string_property(updated_assignment, 'area');
        form_helper.cleanse_string_property(updated_assignment, 'businessunit');
        form_helper.cleanse_string_property(updated_assignment, 'code');
        form_helper.cleanse_string_property(updated_assignment, 'costobject');

        insert_assignment(updated_assignment);
        //onChange_step('FEATURES') - to be used when we enable assignment specific features at a later date
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_create2' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__icon'>
                    {(var_opened && step !== 'SETTINGS') ?
                        <Icon name='checkmark' className='icon__checkmark' alt='checkmark icon' />
                        :
                        <div className='card__header__icon__step text--xl-bold icon--dark_gray'>2</div>
                    }
                </div>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_assignment_settings'>{t('Assignment settings')}</div>
                    {(!var_opened || step === 'SETTINGS') &&
                        <div className='text--sm-regular'>{t('Set additional details such as Start and End date for the assignment.')}</div>
                    }
                </div>
                <div className='card__header__right card__header__btns'>
                    {(var_opened && step !== 'SETTINGS') &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {(step !== 'SETTINGS' && var_opened) ?
                <>
                    <div className='card__content create_summary'>
                        <FORM_DETAILSGROUP label={t('Default assignment')} value={t(assignment.default_org.charAt(0).toUpperCase() + assignment.default_org.slice(1).toLowerCase())} />
                        <FORM_DETAILSGROUP label={t('Area')} value={assignment.area} />
                        <FORM_DETAILSGROUP label={t('Business unit')} value={assignment.businessunit} />
                        <FORM_DETAILSGROUP label={t('Code')} value={assignment.code} />
                        <FORM_DETAILSGROUP label={t('Cost object')} value={assignment.costobject} />
                        <FORM_DETAILSGROUP label={t('Start date')} value={assignment.startdate && datelib.epoch_to_date_string(datelib.date_to_epoch(assignment.startdate))} />
                        <FORM_DETAILSGROUP label={t('End date')} value={assignment.enddate && datelib.epoch_to_date_string(datelib.date_to_epoch(assignment.enddate))} />
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    </div>

                </>
                : step === 'SETTINGS' ?
                    <>
                        <Form className='card__content' id='add_cr8assignment_step2' aria-labelledby='hdr_assignment_settings'>

                            {var_save_error &&
                                <Message error
                                    header={var_save_error}
                                />
                            }
                            {var_errors.length > 0 &&
                                <Message error
                                    icon={<Icon name='error' className='icon' />}
                                    header={t('There are some errors with your inputs.')}
                                />
                            }

                            <FORM_TOGGLE
                                label={t('Default assignment')}
                                value={assignment.default_org}
                                options={[{value: 'YES', text: t('Yes')}, {value: 'NO', text: t('No')}]}
                                onChange={onChange_default}
                                description={t('There can only be one default assignment for an organization. If one already exists this will override it to become the new default assignment.')}
                            />

                            <FORM_INPUT
                                property='area'
                                label={t('Area')}
                                value={assignment.area}
                                onChange={onChange_input}
                                placeholder={t('Area')}
                                maxLength={100}
                                disabled={false}
                                errors={[]}
                            />

                            <FORM_INPUT
                                property='businessunit'
                                label={t('Business unit')}
                                value={assignment.businessunit}
                                onChange={onChange_input}
                                placeholder={t('Business unit')}
                                maxLength={100}
                                disabled={false}
                                errors={[]}
                            />

                            <FORM_INPUT
                                property='code'
                                label={t('Code')}
                                value={assignment.code}
                                onChange={onChange_input}
                                placeholder={t('Code')}
                                maxLength={100}
                                disabled={false}
                                errors={[]}
                            />

                            <FORM_INPUT
                                property='costobject'
                                label={t('Cost object')}
                                value={assignment.costobject}
                                onChange={onChange_input}
                                placeholder={t('Cost object')}
                                maxLength={100}
                                disabled={false}
                                errors={[]}
                            />

                            <FORM_DATEPICKER
                                property='startdate'
                                label={t('Start date')}
                                value={assignment.startdate || ''}
                                onChange={(e, { value }) => onChange_input(e, {name: 'startdate', value })}
                                placeholder={t('YYYY-MM-DD')}
                                minproperty={null}
                                maxproperty={assignment.enddate}
                                errors={var_errors}
                            />

                            <FORM_DATEPICKER
                                property='enddate'
                                label={t('End date')}
                                value={assignment.enddate || ''}
                                onChange={(e, { value }) => onChange_input(e, {name: 'enddate', value })}
                                placeholder={t('YYYY-MM-DD')}
                                minproperty={assignment.startdate}
                                maxproperty={null}
                                errors={var_errors}
                            />

                        </Form>
                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_saveassignment}>{t('Save assignment')}</Button>
                            {/*<Button className='primary' onClick={onClick_nextstep}>{t('Continue to step 3')}</Button> - to be used when we enable assignment specific features at a later date*/}
                        </div>

                    </>
                    : null
            }

            <Processing display={display_processing} processingtext={t('Processing')} />

        </div>
    );
}
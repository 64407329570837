import React, { useState, useEffect } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';
import API from 'libs/api-lib';
import Processing from 'components/cmp_processing';


export default function CONTENT_FORGET_1({ onChangeContent }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);


    useEffect(() => {
        set_modal(document.querySelector('#mdl_unlockvc'));
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function send_wallet_token() {
        set_processing(true);
        try {
            await API_post_wallet_token();
            onChangeContent('FORGET_2');
        } catch (e) {
            console.log(e);
        } finally {
            set_processing(false);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_wallet_token() {
        return API.post('identifiers', '/post-wallet-token');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_submit() {
        send_wallet_token();
    }

    function onClick_back() {
        onChangeContent('UNLOCK');
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='modal__header'>
                <div className='modal__header__centre'>
                    <div className='display--xs-medium' id='hdr_unlock_wallet'>{t('Forgot your passphrase?')}</div>
                </div>
            </div>

            <Form className='modal__content center padding--lg'>
                <div className='content__description'>
                    <div className='text--sm-regular'>{t('Your passphrase cannot be recovered or reset. To access your credential wallet, we need to reset your wallet. This may result in the loss of some credentials, which will need to be added again.')}</div>
                    <div className='text--sm-regular'>{t('For added security, we require a verification code to proceed with resetting your wallet. Click the button below to send the code to your registered email address.')}</div>
                </div>

                <Button type='submit' className='primary' onClick={onClick_submit}>{t('Email verification code')}</Button>
                <Button type='button' className='tertiary' onClick={onClick_back}>{t('Back')}</Button>
            </Form>

            <Processing display={var_processing} processingtext='Processing' />
        </>
    );

}

import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import ReactJson from 'react-json-view';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { decodeJwt, decodeProtectedHeader } from 'jose';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import 'i18n';

import './vc_viewer.css';



function VC_VIEWER ({ verifiable_credential, type, activetab, var_focuselement }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_processing, set_processing ] = useState(false);
    const [ var_payload, set_payload] = useState({});
    const [ var_verified, set_verified ] = useState(false);
    const [ var_verify_errors, set_verify_errors ] = useState([]);
    const [ var_header_props, set_header_props ] = useState({});


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'PROOF'){
            // reset variables and re-populate
            set_payload({});
            set_verified(false);
            set_verify_errors([]);
            set_header_props({});

            if (verifiable_credential.includes('@context')) {
                parse_jsonld();
            } else {
                let header = decodeProtectedHeader(verifiable_credential);
                if (header?.typ === 'JWT') {
                    decode_jwt()
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function decode_jwt() {
        set_processing(true);

        await verify({ vc: verifiable_credential });

        let payload = decodeJwt(verifiable_credential)
        set_payload(payload);
        set_header_props({
            issued: (new Date(datelib.epoch_to_utcmoment(payload.iat))).toISOString().split('.')[0] + 'Z',
            expires: (new Date(datelib.epoch_to_utcmoment(payload.exp))).toISOString().split('.')[0] + 'Z',
            issuer_did: payload.iss,
            subject_did: payload.sub
        });

        set_processing(false);
        document.getElementById(var_focuselement)?.focus();
    }

    async function parse_jsonld() {
        set_processing(true);

        await verify({ verifiableCredential: verifiable_credential });

        let payload = JSON.parse(verifiable_credential);
        set_payload(payload);
        set_header_props({
            issued: payload.issuanceDate,
            expires: payload.expirationDate,
            issuer_did: payload.issuer,
            subject_did: payload.credentialSubject?.id
        });

        set_processing(false);
        document.getElementById(var_focuselement)?.focus();
    }

    async function verify(data) {
        let response;

        try {
            response = await API_verify_credential(data);
        } catch (e) {
            console.log(e);
        }

        let verify_errors = response?.errors ?? [];
        set_verify_errors(verify_errors);

        let verified = (verify_errors.length === 0 && response?.checks?.includes('proof')) ?? false;
        set_verified(verified);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_verify_credential(data) {
        return API.post('verifiable-credentials', '/verify',
        {
            body: data
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_resolve_did(did) {
        let resolve_url;
        switch (true) {
            case did.startsWith('did:web'):
                resolve_url = 'https://resolver.credivera.io/#did=' + did;
                break;
            case did.startsWith('did:ion'):
                resolve_url = 'https://identity.foundation/ion/explorer/?did=' + did;
                break;
            default:
                resolve_url = 'https://resolver.credivera.io/#did=' + did;
        }
        window.open(resolve_url);
    }

    function onKeyDown_resolve_did(e, did) {
        if (e.key === 'Enter') {
            onClick_resolve_did(did);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <div id='vc__wrapper'>

            <div className='vc__header'>

                <div className='credential_status'>
                    {var_verified
                        ? <><Icon name='checkmark' className='icon__form color--green-500' alt={t('status icon')} />
                            <div className='text--xl-medium'>{t('Credential verified')}</div></>
                        : <><Icon name='xicon_solid' className='icon__form color--red-600' alt={t('status icon')} />
                            <div className='text--xl-medium'>{var_verify_errors.includes('credentialStatus') ? t('Credential revoked') : t('Invalid credential')}</div></>}

                </div>

                <div className='detailsgroup'>
                    <div className='detailsgroup__value text--sm-regular'>{`${t('Issued')}: ${var_header_props.issued}`}</div>
                    <div className='detailsgroup__value text--sm-regular'>{`${t('Expires')}: ${var_header_props.expires}`}</div>
                </div>

                <div className='detailsgroup'>
                    <div className='detailsgroup__value text--sm-regular text--anchor' role='link' onClick={() => onClick_resolve_did(var_header_props.issuer_did)} onKeyDown={(e) => onKeyDown_resolve_did(e, var_header_props.issuer_did)} tabIndex='0'>
                        {t('View issuer DID')}
                    </div>
                    <div className='detailsgroup__value text--sm-regular text--anchor' role='link' onClick={() => onClick_resolve_did(var_header_props.subject_did)} onKeyDown={(e) => onKeyDown_resolve_did(e, var_header_props.subject_did)} tabIndex='0'>
                        {t('View holder DID')}
                    </div>
                </div>

            </div>

            <div className='text--sm-medium'>{t('Proof details')}</div>

            <div className='json__wrapper'>
                <ReactJson
                    style={{display: 'block'}}
                    name={false}
                    enableClipboard={false}
                    quotesOnKeys={false}
                    displayDataTypes={false}
                    theme={'bright:inverted'}
                    displayObjectSize={false}
                    collapsed={5}
                    src={var_payload}
                />
            </div>

            <Processing display={var_processing} />

        </div>
    );
}

VC_VIEWER.propTypes = {
    verifiable_credential: propTypes.oneOfType([propTypes.string, propTypes.object]).isRequired,
    type: propTypes.string.isRequired,
    activetab: propTypes.string.isRequired
};

export default VC_VIEWER;
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import form_helper from 'libs/form-lib';
import lookup from 'libs/lookup-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_INPUT, FORM_SELECT, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import auth from 'libs/auth-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';



export default function CRD_DETAILS({ location_id, activetab, onChange, location_organization_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_assignment, set_assignment ] = useState({});
    const [ var_updated_assignment, set_updated_assignment ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'SETTINGS') {
            populate_details();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_details() {
        try {
            let results = await API_get_assignment_details();
            set_assignment(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function update_details(updated_assignment) {
        set_processing(true);
        try {
            await API_put_assignment_details(updated_assignment);
            populate_details();
            onChange && onChange();
            set_mode('VIEW');
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_assignment_details() {
        return API.get('location', '/get-assignment-details/' + location_id);
    }

    function API_put_assignment_details(updated_assignment) {
        return API.put('location', '/put-assignment-details/' + location_id, { body: updated_assignment });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_save_error(null);
        set_updated_assignment({...var_assignment});
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_errors([]);
        set_save_error(null);
        set_mode('VIEW');
    }

    function onClick_save() {
        if (var_processing) return;
        set_save_error(null);
        let updated_assignment = {...var_updated_assignment};
        let errors = [];
        //validation
        if (!form_helper.validate_required_string(updated_assignment.location_name)) {
            errors.push({ property: 'location_name', description: t('Please add a name.') });
        }
        set_errors(errors);
        if (errors.length > 0) return;
        // cleanse properties
        form_helper.cleanse_string_property(updated_assignment, 'location_name');
        form_helper.cleanse_string_property(updated_assignment, 'address');
        form_helper.cleanse_string_property(updated_assignment, 'province');
        form_helper.cleanse_string_property(updated_assignment, 'city');
        form_helper.cleanse_string_property(updated_assignment, 'country');

        set_updated_assignment(updated_assignment);
        update_details(updated_assignment);
    }

    function onChange_input(event) {
        set_save_error(null);
        let updated_assignment = {...var_updated_assignment};
        updated_assignment[event.target.name] = event.target.value;
        set_updated_assignment(updated_assignment);
        if (var_errors.length > 0) {
            let errors = var_errors.filter(error => error.property !== event.target.name)
            set_errors(errors);
        }
    }

    function onChange_assignment_status(status) {
        set_save_error(null);
        let updated_assignment = {...var_updated_assignment};
        updated_assignment.status = status;
        set_updated_assignment(updated_assignment);
    }

    function onChange_country(e, { value }) {
        set_save_error(null);
        let updated_assignment = { ...var_updated_assignment };
        if (updated_assignment.country !== value) {
            updated_assignment.province = null;
        }
        updated_assignment.country = value;
        set_updated_assignment(updated_assignment);
    }

    function onChange_region(e, { value }) {
        set_save_error(null);
        let updated_assignment = { ...var_updated_assignment };
        updated_assignment.province = value;
        set_updated_assignment(updated_assignment);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_details' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div id='hdr_details' className='text--xl-medium'>{t('Assignment details')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    {var_mode === 'VIEW' && auth.orgaccess_organization_ids('ORG-LOC-ADMIN', 'write').includes(location_organization_id) &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {var_mode === 'VIEW' ?
                <>
                    <div className='card__content'>
                        <FORM_DETAILSGROUP label={t('Status')} value={var_assignment.status === 'ACTIVE' ? t('Active') : var_assignment.status === 'DRAFT' ? t('Draft') :  t('Inactive')} type='badge' badgecolor={var_assignment.status === 'ACTIVE' ? 'badge--green' : var_assignment.status === 'DRAFT' ? 'badge--yellow' : 'badge--gray'}/>
                        <FORM_DETAILSGROUP label={t('Name')} value={var_assignment.location_name} />
                        <FORM_DETAILSGROUP label={t('Address')} value={var_assignment.address} />
                        <FORM_DETAILSGROUP label={t('Location')} value={
                            <div style={{ flexDirection: 'column' }}>
                                <div className='text--sm-regular'>{var_assignment.city}{var_assignment.city && var_assignment.province ? ', ' : ''}{var_assignment.province}</div>
                                {(var_assignment.country && var_assignment.country !== '') && <div className='text--sm-regular'>{var_assignment.country}</div>}
                            </div>
                        } />
                    </div>

                    {auth.orgaccess_organization_ids('ORG-LOC-ADMIN', 'write').includes(location_organization_id) &&
                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={() => onClick_edit()}>{t('Edit')}</Button>
                    </div>
                    }

                </>
                : var_mode === 'EDIT' ?
                    <>
                        <Form className='card__content'>

                            {var_save_error &&
                                <Message error header={var_save_error} />
                            }

                            {var_errors.length > 0 &&
                                <Message
                                    error icon={<Icon name='error' className='icon' />}
                                    header={t('There are some errors with your inputs')}
                                />
                            }

                            <FORM_TOGGLE
                                label={t('Status')}
                                value={var_updated_assignment.status}
                                options={var_assignment.status === 'DRAFT'
                                            ? [{ value: 'DRAFT', text: t('Draft') }, { value: 'ACTIVE', text: t('Active') }]
                                            : [{ value: 'ARCHIVE', text: t('Inactive') }, { value: 'ACTIVE', text: t('Active') }]}
                                onChange={onChange_assignment_status}
                            />

                            <FORM_INPUT
                                property='location_name'
                                label={t('Name (required)')}
                                value={var_updated_assignment.location_name}
                                onChange={onChange_input}
                                placeholder={t('Assignment name')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='address'
                                label={t('Address')}
                                value={var_updated_assignment.address}
                                onChange={onChange_input}
                                placeholder={t('Address')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_SELECT
                                property='country'
                                label={t('Country')}
                                value={var_updated_assignment.country || ''}
                                onChange={onChange_country}
                                placeholder={t('Select')}
                                options={lookup.country.get_country_options(t('Select country'))}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_SELECT
                                property='province'
                                label={t('Province/State')}
                                value={var_updated_assignment.province || ''}
                                onChange={onChange_region}
                                placeholder={t('Select')}
                                options={lookup.country.get_region_options(var_updated_assignment.country, t('Select region'))}
                                disabled={!var_updated_assignment.country}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='city'
                                label={t('City')}
                                value={var_updated_assignment.city}
                                onChange={onChange_input}
                                placeholder={t('City')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                        </Form>

                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                            <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                        </div>

                        <Processing display={var_processing} processingtext={t('Processing')} />

                    </>

                    : null

            }
        </div>
    );
}
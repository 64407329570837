import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import API from 'libs/api-lib';
import Icon from 'components/cmp_icon';
import { useTranslation } from 'react-i18next';
import 'i18n';
import config from 'config';

function Logo({ targetid, classification, filename, credivera_on_missing = false, credivera_on_error = false, message_on_virus_scan, message_on_infected, backup_text, ready = true, onClick, alt = 'image', api_service = 'storage', api_name = 'get-file-info', ...props }) {
    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_image_error, set_image_error ] = useState(false);
    const [ var_credivera_error, set_credivera_error ] = useState(false);
    const [ var_storage_endpoint, set_storage_endpoint ] = useState(null);
    const [ var_display_virus_scan_message, set_display_virus_scan_message ] = useState(false);
    const [ var_display_infected_message, set_display_infected_message ] = useState(false);

    let display_image_path = ready && !var_image_error && !!filename &&
        (filename.toLowerCase().startsWith('https://') || filename.toLowerCase().startsWith('http://') ||
            (filename.toLowerCase().startsWith('data') && !filename.match(/\.(jpg|jpeg|png|bmp|gif)$/i)));
    let display_image = ready && !var_image_error && var_storage_endpoint && !!filename && !(filename.toLowerCase().startsWith('https://') || filename.toLowerCase().startsWith('http://'));
    let display_credivera = ready && !var_credivera_error && ((credivera_on_missing && !filename) || (credivera_on_error && var_image_error));

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_endpoint();
    }, []);

    useEffect(() => {
        targetid && filename && message_on_virus_scan && get_file_info();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ targetid, filename ]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_endpoint() {
        set_storage_endpoint(await API.endpoint('storage'));
    }

    async function get_file_info() {
        try {
            let result = await API_get_file_info();
            if (result && result.scan_status === 'SCANNING') {
                set_display_virus_scan_message(true);
                return;
            } else if (result?.scan_status === 'INFECTED' && !!message_on_infected) {
                set_display_infected_message(true);
                return;
            }
        } catch(e) {
            console.log(e);
        }
        set_display_virus_scan_message(false);
        set_display_infected_message(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_file_info() {
        return API.get(api_service, `/${api_name}/${classification}/${targetid}${classification === 'logo' || classification === 'verification-template' ? `/${filename}` : ''}`);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onError(type) {
        if (type === 'image') {
            set_image_error(true);
        } else {
            set_credivera_error(true);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            {
                var_display_virus_scan_message
                ? <div style={{ alignItems: 'center', backgroundColor: '#F3F4F6', padding: '1rem' }}>
                    <Icon name='image' className='icon__image' alt='image icon' />
                    {t('The image cannot be displayed as it is undergoing a virus scan.')}
                  </div>
                : var_display_infected_message ?
                    <div style={{ alignItems: 'center', backgroundColor: '#F3F4F6', padding: '1rem' }}>
                        <Icon name='image' className='icon__image' alt='image icon' />
                        {message_on_infected}
                    </div>
                : display_image_path
                ? <img src={filename} alt={alt} {...props} onError={() => onError('image')} />
                : display_image
                ? <img src={`${var_storage_endpoint}/${classification}/${targetid}/${filename}`} alt={alt} onError={() => onError('image')} />
                : display_credivera
                ? <img src={`${config.images.url}${config.images.assets.Credivera_Horizontal_Blue}${config.images.stage}`} alt={alt} onError={() => onError('credivera')} />
                : (ready && backup_text)
                ? backup_text
                : null
            }
        </>
    );
}

Logo.propTypes = {
    targetid: propTypes.string,
    classification: propTypes.oneOf(['logo', 'course', 'verification-template']),
    ready: propTypes.bool,
    credivera_on_missing: propTypes.bool,
    credivera_on_error: propTypes.bool,
    backup_text: propTypes.string,
    onClick: propTypes.func,
    alt: propTypes.string,
    api_service: propTypes.string,
    api_name: propTypes.string
};

export default Logo;
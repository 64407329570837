import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import CMP_WARNING from 'components/cmp_warning/cmp_warning';
import MDL_PREQUALIFIER from './mdl_prequalifier/mdl_prequalifier';



function CRD_PREQUALIFICATIONS({ activetab, credential, has_verifiable_credential_feature, onChange }) {

//  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_records, set_records ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'prequalifier_type_text' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_total_records, set_total_records ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_mdl_prequalifier_open, set_mdl_prequalifier_open ] = useState(false);
    const [ var_mdl_prequalifier_id, set_mdl_prequalifier_id ] = useState(null);
    const [ var_deleteprequalifier_id, set_deleteprequalifier_id ] = useState(null);
    const [ var_deleteprequalifier_open, set_deleteprequalifier_open ] = useState(false);
    const [ var_addqualifier_open, set_addqualifier_open ] = useState(false);

    const [ var_cmp_warning_open, set_cmp_warning_open ] = useState(false);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'PREQUALIFICATIONS' && credential.id) {
            set_message_text(null);
            set_message_type(null);
            set_refreshtable(!var_refreshtable);
            populate_records();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, credential.id]);

    useEffect(() => {
        if (var_records && credential.status !== 'ACTIVE') {
            if (var_records.length === 0) {
                set_message_text(t('Pre-qualifications must be added to this credential before it can be published.'));
                set_message_type('WARNING');
            } else if (var_records.length > 0) {
                set_message_text(t('The credential is now ready for publication. To do so, navigate to the Details tab and publish the credential by editing the details.'));
                set_message_type('SUCCESS');
            }
        } else {
            set_message_text(null);
            set_message_type(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_records]);

    useEffect(() => {
        if (!var_mdl_prequalifier_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_prequalifier_open]);

    useEffect(() => {
        if (!var_addqualifier_open && var_focuselement?.id === 'btn_addprequalification') {
            var_focuselement.focus();
            !var_mdl_prequalifier_open && set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addqualifier_open]);

    useEffect(() => {
        if (!var_deleteprequalifier_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_creddetails_prequalifications').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_deleteprequalifier_open]);

    useEffect(() => {
        if (!var_cmp_warning_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_creddetails_prequalifications').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_cmp_warning_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, focuselement_id) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_prequalifiers(limit, offset, sortby, sortorder, filter);
            set_total_records(results.total_records);
            set_totalrows(results.totalrows);
            set_records(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_creddetails_prequalifications').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_prequalifiers(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_prequalifiers(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_prequalifiers(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('issuer', '/get-prequalifiers/' + credential.id, {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_put_prequalifier_archive(id, status) {
        return API.put('issuer', '/put-prequalifier-archive', {
            body: {
                prequalifier_id: id,
                status
            }
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_addprequalifier() {
        set_focuselement(document.activeElement);
        if (credential.status === 'ACTIVE') {
            set_addqualifier_open(true);
        } else {
            set_mdl_prequalifier_id(null);
            set_mdl_prequalifier_open(true);
        }
    }

    function onConfirm_add(){
        set_addqualifier_open(false);
        set_mdl_prequalifier_id(null);
        set_mdl_prequalifier_open(true);
    }

    function onClick_prequalifier(id) {
        set_mdl_prequalifier_id(id);
        set_focuselement(document.activeElement);
        set_mdl_prequalifier_open(true);
    }

    function onDelete_prequalifier(id) {
        set_focuselement(document.activeElement);
        if (credential.status === 'ACTIVE' && var_total_records === 1) {
            set_cmp_warning_open(true);
            return;
        }
        set_deleteprequalifier_id(id);
        set_deleteprequalifier_open(true);
    }

    function onConfirm_delete() {
        set_focuselement(document.getElementById('crd_creddetails_prequalifications'));
        API_put_prequalifier_archive(var_deleteprequalifier_id, 'ARCHIVE').then(() => {
            populate_records();
            onChange && onChange();
        });
        set_deleteprequalifier_open(false);
    }

    function onChange_prequalifier(focuselement_id) {
        populate_records(null, focuselement_id);
        onChange && onChange();
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_creddetails_prequalifications' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Pre-qualification requirements')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_addprequalification' className='primary' onClick={onClick_addprequalifier}>{t('Add pre-qualification')}</Button>
                    </div>
                </div>

                <div className='content__description text--sm-regular'>
                    {t('This credential has pre-qualification requirements, all of which must be met by the holder for the credential to be valid. A pre-qualification requirement can be an attestation, document acknowledgement, holding another valid credential or a combination thereof.')}
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} warning={var_message_type === 'WARNING'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'warning'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='prequalifiers' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('Prequalifications')} downloadfunction={download_records}
                       onChange={populate_records}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='prequalifier_type_text' datatype='text' filtertype='option'>{t('PRE-QUALIFICATION')}</Table.HeaderCell>
                            <Table.HeaderCell field='description' datatype='text' filtertype='text'>{t('DESCRIPTION')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_records.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='text--anchor'>
                                    <Button id={`btn_open_prequalifier_${item.id}`} className='tertiary' onClick={() => onClick_prequalifier(item.id)}>
                                        {item.prequalifier_type_text}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    {item.description}
                                    <Button className='tertiary' onClick={() => onDelete_prequalifier(item.id)}>
                                        <Icon name='delete' className='color--primary-500' />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button className='primary' onClick={onClick_addprequalifier}>{t('Add pre-qualification')}</Button>
                </div>

            </div>

            <CMP_WARNING
                display={var_cmp_warning_open}
                title={t('Remove pre-qualification')}
                message={t('Removing this pre-qualification is not possible as the credential requires at least one to be published.')}
                onClose={() => set_cmp_warning_open(false)}
            />

            <CMP_CONFIRMATION
                display={var_addqualifier_open}
                title={t('Add pre-qualification')}
                message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>{t('Adding a new qualifier will result in a change of status for all current holders of a ‘Valid’ credential to ‘In-progress.’ This change will remain in effect until the new pre-qualifier has been completed.')}</div>
                    </div>
                }
                positive_option={t('Continue')}
                negative_option={t('Cancel')}
                onConfirm={onConfirm_add}
                onCancel={() => set_addqualifier_open(false)}
            />

            <CMP_CONFIRMATION
                display={var_deleteprequalifier_open}
                title={t('Remove pre-qualification')}
                message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>{t('Are you sure you want to remove the qualifier type pre-qualification? This action can not be undone.')}</div>
                    </div>
                }
                positive_option={t('Remove')}
                negative_option={t('Cancel')}
                onConfirm={onConfirm_delete}
                onCancel={() => set_deleteprequalifier_open(false)}
            />

            <MDL_PREQUALIFIER
                display={var_mdl_prequalifier_open}
                credential_prequalifier_id={var_mdl_prequalifier_id}
                credential={credential}
                has_verifiable_credential_feature={has_verifiable_credential_feature}
                onChange={(focuselement_id) => onChange_prequalifier(focuselement_id)}
                onClose={() => set_mdl_prequalifier_open(false)}
            />
        </>
    )

}

CRD_PREQUALIFICATIONS.propTypes = {
    activetab: propTypes.string.isRequired,
    credential: propTypes.object,
    onChange: propTypes.func
};

export default CRD_PREQUALIFICATIONS;
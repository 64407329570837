import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import auth from './libs/auth-lib';
// general/login
import NOTFOUND from './containers/notfound/notfound';
import DOWNLOAD from './containers/download/download';
import LOGIN from './containers/login/login';
import INVITE from './containers/invite/invite';
import TERMS from './containers/terms/terms';
// individual pages
import DASHBOARD from './containers/dashboard/dashboard';
import PROFILE from './containers/profile/profile';
import CREDENTIALS from './containers/credentials/credentials';
import CREDENTIAL from './containers/credentials/credential/credential';
import TRANSCRIPTS from './containers/transcripts/transcripts-list/transcripts-list';
import TRANSCRIPT from './containers/transcripts/transcript-details/transcript-details';
import DOCUMENTS from './containers/documents/documents';
import REQUIREMENTS from './containers/requirements/requirements';
import REQUIREMENTS2 from './containers/requirements2/requirements';
// assignment
import ASSIGNMENT from './containers/assignments/assignment/assignment';
import INDIVIDUAL from './containers/individuals/individual/individual';
// org pages
import ASSIGNMENTS from './containers/org/assignments/assignments_list/assignments';
import ORGASSIGNMENT_CREATE from './containers/org/assignments/assignment_create/assignment_create';
import ORGCREDENTIALS from './containers/org/credentials/credentials';
import ORGCREDENTIAL from './containers/org/credentials/credential_details/credential_details';
import ORGCREDENTIAL_CREATE from './containers/org/credentials/credential_create/credential_create';
import DAILYCHECK from './containers/org/dailycheck/dailycheck';
import ORGDOCUMENTS from './containers/org/documents/documents_list/documents';
import INDIVIDUALS from './containers/individuals/individuals';
import ORGPROFILE from './containers/org/profile/profile';
import ORGPACKAGE from './containers/org/requirements/package_details/package_details';
import ORGREQUIREMENTS from './containers/org/requirements/requirements_list/requirements';
import ORGREQUIREMENTS2 from './containers/org/requirements2/requirements_list/requirements';
import ORGPACKAGE_CREATE from './containers/org/requirements/package_create/package_create';
import ORGREQUIREMENT_CREATE from './containers/org/requirements/requirement_create/requirement_create';
import ORGREQUIREMENT2_CREATE from './containers/org/requirements2/requirement_create/requirement_create';
import ORGREQUIREMENT from './containers/org/requirements/requirement_details/requirement_details';
import ORGREQUIREMENT2 from './containers/org/requirements2/requirement_details/requirement_details';
import ORGREPORT_COMPLIANCE from './containers/org/reports/compliance/compliance';
import ORGREPORT_DAILYCHECK from './containers/org/reports/dailycheck/dailycheck_list/dailycheck';
import ORGREPORT_ONSITE from './containers/org/reports/onsite/onsite';
import ORGREPORT_RECA_LICENSES from './containers/org/reports/reca_licenses/reca_list/reca';
import ORGREPORT_RECA_MEMBERS from './containers/org/reports/reca_members/reca_list/reca';
import ORGREPORT_CEE_TQ_HOLDERS from './containers/org/reports/aoa_credentials/cee_tq_holders/cee_tq_holders';
import ORGVERIFICATION from './containers/org/verifications/verification/verification';
import ORGVERIFICATIONS from './containers/org/verifications/verifications_list/verifications';
import ORGVERIFICATION_CREATE from './containers/org/verifications/verification_create/verification_create';
import ORGVERIFICATION_THEME from './containers/org/verifications/verification_theme_details/verification_theme_details';
import ORGVERIFICATION_THEME_CREATE from './containers/org/verifications/verification_theme_create/verification_theme_create';
import ORG_IDENTITY_VERIFICATION from './containers/org/identity_verification/identity_verification_list/identity_verification';
// external report pages
import REPORT_PROFILE from './containers/report/profile/profile';
import REPORT_CREDENTIAL from './containers/report/certificate/certificate';
import REPORT_ASSIGNMENT from './containers/report/assignment/assignment';
import REPORT_TRANSRCRIPT from './containers/report/transcript/transcript'
// other external pages
import VERIFICATION from './containers/verification/verification';


export default function Routes({ appProps }) {
    return (
        <RouterRoutes>
            <Route path='/' element={auth.is_loggedin ? <DASHBOARD {...appProps} /> : null} />
            {/* general/login */}
            <Route path='/download/:token' element={<DOWNLOAD {...appProps} />} />
            <Route path='/login' element={<LOGIN {...appProps} />} />
            <Route path='/login/*' element={<LOGIN {...appProps} />} />
            <Route path='/invite' element={<INVITE {...appProps} />} />
            <Route path='/terms' element={<TERMS {...appProps} />} />
            {/* individual pages */}
            {auth.is_loggedin && <Route path='/dashboard' element={<DASHBOARD {...appProps} />} />}
            {auth.is_loggedin && <Route path='/profile/:tab?' element={<PROFILE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/credentials/:category' element={<CREDENTIALS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/credentials/:category/:individualcredentialid/:tab?' element={<CREDENTIAL {...appProps} />} />}
            {auth.is_loggedin && <Route path='/transcripts' element={<TRANSCRIPTS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/transcripts/:transcriptid' element={<TRANSCRIPT {...appProps} />} />}
            {auth.is_loggedin && <Route path='/documents' element={<DOCUMENTS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/requirements' element={<REQUIREMENTS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/requirements2' element={<REQUIREMENTS2 {...appProps} />} />}
            {/* assignment */}
            {auth.is_loggedin && <Route path='/assignments/:locationid/:tab?' element={<ASSIGNMENT {...appProps} />} />}
            {auth.is_loggedin && <Route path='/individuals/:id/:locationid?' element={<INDIVIDUAL {...appProps} />} />}
            {auth.is_loggedin && <Route path='/assignments/:locationid/:tab/individual/:id' element={<INDIVIDUAL {...appProps} />} />}
            {/* org pages */}
            {auth.is_loggedin && <Route path='/assignments' element={<ASSIGNMENTS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/assignments/create' element={<ORGASSIGNMENT_CREATE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/credentials' element={<ORGCREDENTIALS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/credentials/create' element={<ORGCREDENTIAL_CREATE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/credentials/:credentialid/:tab?' element={<ORGCREDENTIAL {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/credentials/verifications' element={<ORGCREDENTIALS tab='verifications' {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/dailycheck' element={<DAILYCHECK {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/documents' element={<ORGDOCUMENTS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/individuals' element={<INDIVIDUALS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/profile/:tab?' element={<ORGPROFILE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/packages/:packageid' element={<ORGPACKAGE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/requirements' element={<ORGREQUIREMENTS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/requirements2' element={<ORGREQUIREMENTS2 {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/package/create' element={<ORGPACKAGE_CREATE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/requirements/create' element={<ORGREQUIREMENT_CREATE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/requirements2/create' element={<ORGREQUIREMENT2_CREATE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/requirements/:requirementid' element={<ORGREQUIREMENT {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/requirements2/:requirementid' element={<ORGREQUIREMENT2 {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/reports/compliance' element={<ORGREPORT_COMPLIANCE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/reports/dailycheck' element={<ORGREPORT_DAILYCHECK {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/reports/onsite' element={<ORGREPORT_ONSITE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/reports/reca_license_changes' element={<ORGREPORT_RECA_LICENSES {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/reports/reca_missing_by_brokerage' element={<ORGREPORT_RECA_MEMBERS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/reports/cee_tq_holders' element={<ORGREPORT_CEE_TQ_HOLDERS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/verifications/:verification_template_id' element={<ORGVERIFICATION {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/verifications' element={<ORGVERIFICATIONS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/verifications/create' element={<ORGVERIFICATION_CREATE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/verifications/themes/:theme_id' element={<ORGVERIFICATION_THEME {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/verifications/themes/create' element={<ORGVERIFICATION_THEME_CREATE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/org/identity_verification' element={<ORG_IDENTITY_VERIFICATION {...appProps} />} />}
            {/* external report pages */}
            <Route path='/report/certificate/:token/:report_id/:credential_id' element={<REPORT_CREDENTIAL {...appProps} />} />
            <Route path='/report/certificate/:token/:credentialid' element={<REPORT_CREDENTIAL {...appProps} />} />
            <Route path='/report/profile/:token' element={<REPORT_PROFILE {...appProps} />} />
            <Route path='/report/transcript/:token' element={<REPORT_TRANSRCRIPT {...appProps} />} />
            {/* Support for legacy links */}
            <Route path='/public/certificate/:token' element={<REPORT_CREDENTIAL {...appProps} />} />
            <Route path='/public/profile/:token' element={<REPORT_PROFILE {...appProps} />} />
            <Route path='/public/location/:token' element={<REPORT_ASSIGNMENT {...appProps} />} />
            <Route path='/report/location/:token' element={<REPORT_ASSIGNMENT {...appProps} />} />
            {/* other external pages */}
            <Route path='/verification/:token' element={<VERIFICATION {...appProps} />} />

            {/* fallback for not found */}
            <Route path='*' element={<NOTFOUND />} />
        </RouterRoutes>
    );
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import { Button } from 'semantic-ui-react';
import 'i18n';

import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';
import CMP_CREDENTIAL_STATUS from 'components/cmp_credential_status/cmp_credential_status';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';


export default function TAB_VERIFICATIONS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_verifications, set_verifications ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'credential_name' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);

    const [ var_docviewer_id, set_docviewer_id ] = useState(null);
    const [ var_docviewer_open, set_docviewer_open ] = useState(null);
    const [ var_verificationpopup_id, set_verificationpopup_id ] = useState(null);




    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_verifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_cred_verifications').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_verifications(populateconfig, focuselement_id, message_text = null) {
        set_message_text(message_text);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_verifications(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_verifications(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);

            if (focuselement_id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${focuselement_id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_cred_verifications').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
            }

        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_verifications(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_verifications(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_verifications(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('credentials', '/get-org-verifications',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_document(id) {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_docviewer_id(id);
        set_docviewer_open(true);
    }

    function onClick_credential_status(id) {
        set_message_text(null);
        set_verificationpopup_id(id);
    }

    function onClose_credential_status() {
        set_verificationpopup_id(null);
        set_message_text(null);
        set_message_type(null);
    }

    function onChange_credential_status(focuselement_id) {
        set_verificationpopup_id(null);
        populate_verifications(null, focuselement_id);
    }

    // RENDER APP ======================================================================================================

    return (!auth.has_orgaccess('ORG-ISSUER-ADMIN', 'verify') ? null :
        <>
            <div className='card rounded-lg shadow-sm' id='crd_cred_verifications' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_verifications' className='text--xl-medium'>{t('Verifications')}</div>
                    </div>
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='verifications' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('verifications')} downloadfunction={download_records}
                        onChange={populate_verifications}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='verification_status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='holder' datatype='text' filtertype='text'>{t('HOLDER')}</Table.HeaderCell>
                            <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell field='issuer_name' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                            <Table.HeaderCell field='issued' datatype='date' filtertype='date'>{t('ISSUED')}</Table.HeaderCell>
                            <Table.HeaderCell field='expires' datatype='date' filtertype='date'>{t('EXPIRES')}</Table.HeaderCell>
                            <Table.HeaderCell field='submittedfilename' datatype='text' filtertype='text'>{t('ATTACHMENT')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_verifications.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <CMP_CREDENTIAL_STATUS
                                        can_manage={false}
                                        can_verify={item.has_write_access === 'YES'}
                                        can_update={item.has_write_access === 'YES'}
                                        is_open={item.id === var_verificationpopup_id}
                                        credential={item}
                                        onChange={onChange_credential_status}
                                        onMessage={(type, text) => { set_message_type(type); set_message_text(text); }}
                                        onClose={onClose_credential_status}
                                        onOpen={onClick_credential_status}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <CMP_VERIFIED_IDENTITY_DISPLAY
                                        individual_name={item.holder}
                                        verified_identity_credential_id={item.identity_verification_credential_id}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Table.CellContentTwoLine primary_text={<div>{item.credential_name} {item.restrictedaccess === 'YES' ? <Icon name='lock' className='icon__lock color--gray-400' alt={t('lock icon')} /> : ''} </div>} secondary_text={item.credential_code} />
                                </Table.Cell>
                                <Table.Cell>{item.issuer_name}</Table.Cell>
                                <Table.Cell>{datelib.epoch_to_date_string(item.issued)}</Table.Cell>
                                <Table.Cell>{datelib.epoch_to_date_string(item.expires)}</Table.Cell>
                                <Table.Cell>
                                    {item.submittedfilename !== null &&
                                        <Button className='tertiary' onClick={() => onClick_document(item.id)}>
                                            <Icon name='attachment' className='icon__attachment' alt={t('Preview attachment')} />
                                        </Button>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>

            <MDL_DOCVIEWER
                targetid={var_docviewer_id}
                classification='credential'
                is_open={var_docviewer_open}
                onClose={() => set_docviewer_open(false)}
            />

        </>
    )

}
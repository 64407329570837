import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'i18n';


export default function CONTENT_SCAN_3({ set_content, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_code, set_code ] = useState('');
    const [ var_errors, set_errors ] = useState([]);

    const [ var_code1, set_code1 ] = useState('');
    const [ var_code2, set_code2 ] = useState('');
    const [ var_code3, set_code3 ] = useState('');
    const [ var_code4, set_code4 ] = useState('');

    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <div className='modal__content center padding--lg'>

            <div className='content__description'>
                <div className='text--sm-regular'>{t('Organization name is requesting you share the following credentials:')}</div>
            </div>

            <div className='dropzone__fileupload credentials__wrapper'>
                <div className='credentials__item'>
                    <div className='credentials__item_text'>
                        <div className='text--xs-medium'>Credential name</div>
                        <div className='text--xs-regular'>Issuer name</div>
                    </div>
                </div>

                <div className='credentials__item'>
                    <div className='credentials__item_text'>
                        <div className='text--xs-medium'>Credential name</div>
                        <div className='text--xs-regular'>Issuer name</div>
                    </div>
                </div>
            </div>

        </div>
    );

}

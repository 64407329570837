import React from 'react';
import propTypes from 'prop-types';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import 'i18n';

import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';


function CMP_DOCUMENTACKNOWLEDGE({ onAcknowledgement, onClose, document_type = 'DOCUMENT', document_id, secondary_id, is_open = false, show_acknowledge = false }) {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------

    async function onClick_documentacknowledge() {
        try {
            await API_post_documentacknowledge();
            onAcknowledgement && onAcknowledgement();
        } catch (e) {
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_documentacknowledge() {
        switch (document_type) {
            case 'DOCUMENT':
                return API.post('documents', '/post-acknowledgement/' + document_id);
            case 'PREQUALIFIER-DOCUMENT':
                return API.put('credentials', '/put-credential-prequalifier-document', {
                    queryStringParameters: { tz: datelib.timezone },
                    body: { individualprequalifier_id: secondary_id }
                });
            default:
                throw new Error('Unknown document type');
        }
    }

    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <MDL_DOCVIEWER
            targetid={document_id}
            classification={document_type.toLowerCase()}
            is_open={is_open}
            onClose={onClose}
            show_acknowledge={show_acknowledge}
            onAcknowledgement={onClick_documentacknowledge}
        />
    );
}

CMP_DOCUMENTACKNOWLEDGE.propTypes = {
    onAcknowledgement: propTypes.func,
    onClose: propTypes.func,
    document_type: propTypes.oneOf(['DOCUMENT', 'PREQUALIFIER-DOCUMENT']),
    document_id: propTypes.string,
    secondary_id: propTypes.string,
    is_open: propTypes.bool,
    show_acknowledge: propTypes.bool
};

export default CMP_DOCUMENTACKNOWLEDGE;
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import form_helper from 'libs/form-lib';
import lookup from 'libs/lookup-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_INPUT, FORM_SELECT } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import 'i18n';


export default function CRD_DETAILS({ activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_details, set_details ] = useState({});
    const [ var_updated_details, set_updated_details ] = useState({});
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'DETAILS') {
            populate_details();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, var_mode]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_details() {
        set_errors([]);
        try {
            let record = await API_get_org_details();
            set_details(record);
        } catch (e) {
            console.log(e);
            set_save_error(t('An error has occurred'));
        }
    }

    async function save_details(updated_details) {
        set_processing(true);
        try {
            await API_put_org_details(updated_details);
            set_details(updated_details);
            set_mode('VIEW');
        } catch (e) {
            console.log(e);
            set_save_error(t('An error has occurred'));
        } finally {
            set_processing(false);
        }
    }

    async function check_org_name_exists() {
        return (await API_get_org_name_exists() === 'YES');
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_details() {
        return API.get('org-profile', '/get-org-details');
    }

    function API_get_org_name_exists() {
        return API.get('org-profile', '/get-org-name-exists/' + encodeURI(var_updated_details.name));
    }

    function API_put_org_details(updated_details) {
        return API.put('org-profile', '/put-org-details', { body: updated_details});
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_updated_details({...var_details});
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_errors([]);
        set_save_error(null);
        set_mode('VIEW');
    }

    async function onClick_save() {
        if (var_processing) return;

        let details = {...var_updated_details};
        let errors = [];
        // validation
        if (var_details.demoaccount === 'YES') {
            if (!form_helper.validate_required_string(details.name)) {
                errors.push({ property: 'name', description: t('Name is required.') });
            } else if (!(/\bdemo\b/i.test(details.name))) {
                errors.push({property: 'name', description: t('The name of a demo organization must contain the word \'demo\'.')});
            } else if (details.name !== var_details.name && await check_org_name_exists()) {
                errors.push({property: 'name', description: t('An organization with this name already exists.')});
            }
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(details, 'name');
        form_helper.cleanse_string_property(details, 'address');
        form_helper.cleanse_string_property(details, 'city');
        form_helper.cleanse_string_property(details, 'postal');
        form_helper.cleanse_string_property(details, 'website');

        set_updated_details(details);
        save_details(details);

    }

    function onChange_input(event) {
        set_save_error(null);
        let updated_details = {...var_updated_details};
        updated_details[event.target.name] = event.target.value;
        set_updated_details(updated_details);
        if (var_errors.length > 0) {
            let errors = var_errors.filter(error => error.property !== event.target.name)
            set_errors(errors);
        }
    }

    function onChange_country(e, { value }) {
        set_save_error(null);
        let updated_details = { ...var_updated_details };
        if (updated_details.country !== value) {
            updated_details.province = null;
        }
        updated_details.country = value;
        set_updated_details(updated_details);
    }

    function onChange_region(e, { value }) {
        set_save_error(null);
        let updated_details = { ...var_updated_details };
        updated_details.province = value;
        set_updated_details(updated_details);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_profile_details' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_details'>{t('Organization details')}</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    {var_mode === 'VIEW' &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {var_mode === 'VIEW' ?
                <>
                    <div className='card__content'>
                        <FORM_DETAILSGROUP label={t('Name')} value={var_details.name} />
                        <FORM_DETAILSGROUP label={t('Address')} value={var_details.address} />
                        <FORM_DETAILSGROUP label={t('Location')} value={
                            <div style={{ flexDirection: 'column' }}>
                                <div className='text--sm-regular'>{var_details.city}{var_details.city && var_details.province ? ', ' : ''}{var_details.province}</div>
                                {(var_details.country && var_details.country !== '') && <div className='text--sm-regular'>{var_details.country}</div>}
                            </div>
                        } />
                        <FORM_DETAILSGROUP label={t('Postal/Zip')} value={var_details.postal} />
                        <FORM_DETAILSGROUP label={t('Website')} value={var_details.website} />
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={() => onClick_edit()}>{t('Edit')}</Button>
                    </div>

                </>
                : var_mode === 'EDIT' ?
                    <>
                        <Form className='card__content' id='form_profile_orgdetails' aria-labelledby='hdr_details'>

                            {var_save_error &&
                                <Message error header={var_save_error} />
                            }

                            {var_errors.length > 0 &&
                                <Message
                                    error icon={<Icon name='error' className='icon' />}
                                    header={t('There are some errors with your inputs')}
                                />
                            }

                            <FORM_INPUT
                                property='name'
                                label={t('Name')}
                                value={var_updated_details.name}
                                onChange={onChange_input}
                                placeholder={t('Organization name')}
                                maxLength={100}
                                disabled={var_details.demoaccount !== 'YES'}
                                errors={var_errors}
                                description={var_details.demoaccount !== 'YES'
                                    ? <div style={{ display: 'inline' }}>{t('To update your organization\'s name, please')} <a style={{ display: 'inline' }} target='_blank' rel='noreferrer' href='https://helpcenter.credivera.com/en/knowledge/kb-tickets/new'>{t('contact our support team')}</a>.</div>
                                    : null}
                            />

                            <FORM_INPUT
                                property='address'
                                label={t('Address')}
                                value={var_updated_details.address}
                                onChange={onChange_input}
                                placeholder={t('Address')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_SELECT
                                property='country'
                                label={t('Country')}
                                value={var_updated_details.country || ''}
                                onChange={onChange_country}
                                placeholder={t('Select')}
                                options={lookup.country.get_country_options(t('Select country'))}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_SELECT
                                property='province'
                                label={t('Province/State')}
                                value={var_updated_details.province || ''}
                                onChange={onChange_region}
                                placeholder={t('Select')}
                                options={lookup.country.get_region_options(var_updated_details.country, t('Select region'))}
                                disabled={!var_updated_details.country}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='city'
                                label={t('City')}
                                value={var_updated_details.city}
                                onChange={onChange_input}
                                placeholder={t('City')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='postal'
                                label={t('Postal/Zip Code')}
                                value={var_updated_details.postal}
                                onChange={onChange_input}
                                placeholder={t('Postal/Zip Code')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='website'
                                label={t('Website')}
                                value={var_updated_details.website}
                                onChange={onChange_input}
                                placeholder={t('Website')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                        </Form>

                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                            <Button className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                        </div>

                    </>

                    : null

            }

            <Processing display={var_processing} processingtext={t('Processing')} />

        </div>
    );
}

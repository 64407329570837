import React, { useState, useEffect } from 'react';
import { Button, Message, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';
import API from 'libs/api-lib';
import { FORM_INPUT } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';

import auth from 'libs/auth-lib';
import { verify, passphrase_to_wrapping_key } from 'libs/vault-lib';


export default function CONTENT_UNLOCK({ onClose, reset_authentication, onChangeContent }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_passphrase, set_passphrase ] = useState('');
    const [ var_showpassword, set_showpassword ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_wallet_locked, set_wallet_locked ] = useState(false);
    const [ var_remaining_attempts, set_remaining_attempts ] = useState(null);
    const [ var_max_attempts, set_max_attempts ] = useState(null);

    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        get_wallet_status();
        set_modal(document.querySelector('#mdl_unlockvc'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function get_wallet_status() {
        try {
            let results = await API_get_wallet_status();
            if (results.status === 'LOCKED') {
                set_remaining_attempts(null);
                set_wallet_locked(true);
            } else if (results.max_attempts !== results.remaining_attempts) {
                set_remaining_attempts(results.remaining_attempts);
                set_max_attempts(results.max_attempts);
            } else {
                set_remaining_attempts(null);
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    async function validate_passphrase() {
        set_processing(true);
        let passphrase_params = auth.passphrase_parameters[0];
        sessionStorage.removeItem('encryption_key');
        try {
            let { verified, encryption_keys } = await verify(var_passphrase, passphrase_params.hash_algorithm, passphrase_params.salt, passphrase_params.iterations);
            if (!verified) {
                Window.$is_unlocked = false;
                await get_wallet_status();
                let errors = [];
                errors.push({ property: 'passphrase', description: t('The passphrase is incorrect.') })
                set_errors(errors);
            } else {
                sessionStorage.setItem('encryption_key', JSON.stringify(encryption_keys[0]));
                let key_encryption_key = await passphrase_to_wrapping_key(var_passphrase, passphrase_params.hash_algorithm, passphrase_params.iterations, passphrase_params.salt);
                Window.$kek = key_encryption_key;
                Window.$is_unlocked = true;
                onClose && onClose();
            }
        } catch (exception) {
            console.log(exception);
        } finally {
            set_processing(false);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------


    function API_get_wallet_status() {
        return API.get('verifiable-credentials', '/get-wallet-status');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event, { value }) {
        set_errors([]);
        set_passphrase(value);
    }

    function onClick_submit() {
        let errors = [];
        if (var_passphrase.length === 0) {
            errors.push({ property: 'passphrase', description: t('Passphrase cannot be blank.') })
        }
        set_errors(errors);
        if (errors.length > 0) return;

        validate_passphrase();
    }

    function onClick_forgot() {
        onChangeContent('FORGET_1');
    }

    function onKeyPress(event) {
        if (event.charCode === 13) {
            onClick_submit();
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <div style={{ flexDirection: 'column' }}>
            <div className='modal__header'>
                <div className='modal__header__centre'>
                    <div className='display--xs-medium' id='hdr_unlock_wallet'>{t('Unlock credential wallet')}</div>
                    <div className='text--sm-regular'>{t('Enter your passphrase to unlock your wallet.')}</div>
                </div>
            </div>

            <Form className='modal__content center padding--lg ' tabIndex='0' id='form_passphrase' aria-labelledby='hdr_unlock_wallet'>

                {(var_wallet_locked || var_remaining_attempts) &&
                    <Message
                        error
                        aria-live='assertive'
                        role='alert'
                        icon={<Icon name='error' className='icon' alt={t('Alert')} />}
                        content={var_wallet_locked
                        ?
                            <div style={ { flexDirection: 'column' } }>
                                <div style={ { marginBottom: '0.5rem' } }>{t('Your wallet has been temporarily locked for 24 hours due to reaching the maximum number of invalid unlock attempts.')}</div>
                                <div style={ { display: 'inline' } }>{t('Please') + ' '} <a href='mailto:help@credivera.com'>{t('contact our support team')}</a> {' ' + t('if you require further assistance.')}</div>
                            </div>
                        :
                            <div style={ { flexDirection: 'column' } }>
                                <div style={ { marginBottom: '0.5rem' } } className='text--sm-bold'>{t('Remaining unlock attempts:') + ' ' + var_remaining_attempts}</div>
                                <div style={ { marginBottom: '0.5rem' } }>{t('After') + ' ' + var_max_attempts + ' ' + t('consecutive unsuccessful unlock attempts, your wallet will be locked.')}</div>
                            </div>}
                    />
                }

                <FORM_INPUT
                    property='passphrase'
                    label={t('Passphrase')}
                    value={var_passphrase || null}
                    onChange={onChange_input}
                    onKeyPress={onKeyPress}
                    placeholder=''
                    maxLength={64}
                    disabled={var_wallet_locked}
                    errors={var_errors}
                    description=''
                    type={var_showpassword ? 'text' : 'password'}
                    icon={<Icon name={var_showpassword ? 'eyeslash' : 'eye'} className='icon' onClick={() => set_showpassword(!var_showpassword)} />}
                    autocomplete='new-password'
                    inputClassName='password-input'
                />

                <Button type='button' className='primary' onClick={onClick_submit} disabled={var_wallet_locked}>{t('Submit')}</Button>
                <Button type='button' className='tertiary' onClick={onClick_forgot}>{t('Forgot your passphrase?')}</Button>
            </Form>

            <Processing display={var_processing} processingtext={t('Processing')} />
        </div>
    );

}

import React, { useEffect } from 'react';
import 'i18n';

import CRD_ASSIGNMENTS from './crd_assignments/crd_assignments';



export default function Assignments({ requirement_id, requirement}) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <CRD_ASSIGNMENTS requirement_id={requirement_id} requirement={requirement} />
    )
};

import React from 'react';
import propTypes from 'prop-types';
import { Input, Form } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import 'i18n';
import { useTranslation } from 'react-i18next';

function INPUT({ property, value, onChange, onKeyPress, placeholder, maxLength, disabled, errors, icon, disable_icon = false, type, autocomplete, multiple, aria_describedby = null, className }) {

    //  event functions ------------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    let error = errors ? errors.find(item => item.property === property) : undefined;



    // RENDER ==========================================================================================================
    return (
        <Form.Field>
            <Input
                name={property}
                value={value || ''}
                onChange={onChange}
                onKeyPress={onKeyPress}
                placeholder={placeholder || ''}
                maxLength={maxLength}
                disabled={disabled}
                type={type || 'text'}
                error={error !== undefined}
                icon={disable_icon ? null : error && !icon ? <Icon name='error' className='icon icon__field__error' /> : icon}
                autoComplete={autocomplete}
                multiple={multiple}
                aria-describedby={aria_describedby}
                className={className}
            />
            {error &&
                <div className='field__feedback error'>
                    <div className='description text--sm-regular'>{error.description}</div>
                </div>
            }
        </Form.Field>
    );
}

INPUT.propTypes = {
    property: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    onKeyPress: propTypes.func,
    placeholder: propTypes.string,
    maxLength: propTypes.number,
    icon: propTypes.element,
    type: propTypes.string,
    autocomplete: propTypes.string,
    aria_describedby: propTypes.string,
    className: propTypes.string
};

export default INPUT;
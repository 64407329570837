import api from './api-lib';
import auth from './auth-lib';

let loading = false;
let async_wait = async(ms) => new Promise(resolve => setTimeout(resolve, ms));
let confirmation_status_language = null;
let confirmation_status_data = [];
let countries = [
    {
        name: 'Canada',
        regions: [
            'Alberta',
            'British Columbia',
            'Manitoba',
            'New Brunswick',
            'Newfoundland and Labrador',
            'Northwest Territories',
            'Nova Scotia',
            'Nunavut',
            'Ontario',
            'Prince Edward Island',
            'Quebec',
            'Saskatchewan',
            'Yukon']
    },
    {
        name: 'United States',
        regions: [
            'Alabama',
            'Alaska',
            'Arizona',
            'Arkansas',
            'California',
            'Colorado',
            'Connecticut',
            'Delaware',
            'District of Columbia',
            'Florida',
            'Georgia',
            'Guam',
            'Hawaii',
            'Idaho',
            'Illinois',
            'Indiana',
            'Iowa',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Maine',
            'Maryland',
            'Massachusetts',
            'Michigan',
            'Minnesota',
            'Mississippi',
            'Missouri',
            'Montana',
            'Nebraska',
            'Nevada',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'New York',
            'North Carolina',
            'North Dakota',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Pennsylvania',
            'Puerto Rico',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Vermont',
            'Virginia',
            'Washington',
            'West Virginia',
            'Wisconsin',
            'Wyoming'
        ]
    }
];

async function load_confirmation_status_data() {
    while (loading) {
        await async_wait(25);
    }
    let current_language = auth.language ? auth.language.i18n.split('-')[0] : 'en';
    if (current_language !== confirmation_status_language || confirmation_status_data.length === 0) {
        loading = true;
        try {
            confirmation_status_data = await api.get('credentials', '/get-confirmation-status-data');
            confirmation_status_language = current_language;
        } finally {
            loading = false;
        }
    }
}

let lookup = {
    confirmation_status: {
        get_select_options: async({ verification_status = 'ALL', status = 'ACTIVE', init_cap = true, include_extended_data = false } = {}) => {
            await load_confirmation_status_data();
            return confirmation_status_data
                .filter(item => (verification_status === 'ALL' || item.verification_status === verification_status)
                    && (status === 'ALL' || item.status === status))
                .map(item => ({
                    value: item.confirmation_status,
                    text: init_cap ? item.name_init_cap : item.name,
                    ...(include_extended_data ? { verification_status: item.verification_status, status: item.status } : {})
                }));
        },
        get_name: async(confirmation_status, init_cap = true) => {
            await load_confirmation_status_data();
            return confirmation_status_data.find(item => item.confirmation_status === confirmation_status)?.[init_cap ? 'name_init_cap' : 'name'];
        },
        get_verification_status: async(confirmation_status) => {
            await load_confirmation_status_data();
            return confirmation_status_data.find(item => item.confirmation_status === confirmation_status)?.verification_status;
        },
        get_item: async(confirmation_status) => {
            await load_confirmation_status_data();
            let item = confirmation_status_data.find(item => item.confirmation_status === confirmation_status);
            return item ? { ...item } : null;
        },
        get_items: async() => {
            await load_confirmation_status_data();
            return confirmation_status_data.map(item => ({ ...item }));
        }
    },
    country: {
        get_country_options: (select_option_text = undefined) => {
            return [ ...(select_option_text === undefined ? [] : [{ value: null, text: select_option_text }]),
                ...countries.map(item => ({ value: item.name, text: item.name })) ];
        },
        get_region_options: (country_name, select_option_text = undefined) => {
            if (!country_name) return [];
            return [ ...(select_option_text === undefined ? [] : [{ value: null, text: select_option_text }]),
                ...countries.find(item => item.name === country_name).regions.map(item => ({ value: item, text: item })) ];
        }
    }
};

export default lookup;

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'core-js/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n';

import { BrowserRouter as Router } from 'react-router-dom';


createRoot(document.getElementById('root')).render(
    <Router>
        <App />
    </Router>
);

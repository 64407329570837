import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import 'i18n';

import MDL_INVITE from './mdl_invite/mdl_invite';
import MDL_INVITE_BULKERRORS from './mdl_invite_bulkerrors/mdl_invite_bulkerrors';

import CMP_INDIVIDUAL_STATUS from 'components/cmp_individual_status/cmp_individual_status';
import CMP_INDIVIDUAL_PERMISSION from 'components/cmp_individual_permission/cmp_individual_permission';
import CMP_INDIVIDUAL_TYPE from 'components/cmp_individual_type/cmp_individual_type';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';

import './crd_individuals.css';


export default function CRD_INDIVIDUALS(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_individualstatus, set_individualstatus ] = useState('ALL');

    const [ var_individual_typepopup_id, set_individual_typepopup_id ] = useState(null);

    const [ var_permissionpopup_id, set_permissionpopup_id ] = useState(null);

    const [ var_statuspopup_id, set_statuspopup_id ] = useState(null);

    const [ var_mdl_invite_open, set_mdl_invite_open ] = useState(false);
    const [ var_invite_bulkerrors_batchid, set_invite_bulkerrors_batchid ] = useState(null);
    const [ var_invite_bulkerrors_open, set_invite_bulkerrors_open ] = useState(false);

    const [ var_message_type, set_message_type ] = useState(null);
    const [ var_message_text, set_message_text ] = useState(null);

    const [ var_permission_roles, set_permission_roles ] = useState([]);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_individuals({ ...var_populateconfig, offset: 0, filter: {} });
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_individualstatus]);

    useEffect(() => {
        populate_permission_list();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_mdl_invite_open && var_focuselement?.id === 'btn_inviteindividual') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_invite_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals(populateconfig, focuselement_id){
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);
        set_message_text(null);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_individuals(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_individuals(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
            document.getElementById(var_focuselement)?.focus();
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_all_individuals').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        } else if (var_focuselement?.id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${var_focuselement.id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_all_individuals').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }

        set_loading(false);
        set_ready(true);
    }

    async function populate_permission_list(){
        try {
            let results = await API_get_permission_list();
            set_permission_roles(results);
            set_loadingerror(false);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_individuals(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_individuals(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_individuals(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_individuals(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('org-indv', '/get-org-individuals',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    status: var_individualstatus
                },
                body: filter
            }
        );
    }

    function API_get_permission_list() {
        return API.get('org-indv', '/get-permission-list');
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_individualstatus(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_individualstatus(status);
    }

    function onClick_individual(id) {
        set_message_text(null);
        navigate('/individuals/' + id);
    }

    function onClick_inviteindividual() {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_mdl_invite_open(true);
    }

    function onClick_individual_type(id) {
        set_message_text(null);
        set_individual_typepopup_id(id);
    }

    function onClick_permission(id) {
        set_message_text(null);
        set_permissionpopup_id(id);
    }

    function onClick_status(id) {
        set_message_text(null);
        set_statuspopup_id(id);
    }

    function onInvite(is_invite_suppressed) {
        populate_individuals();
        set_message_type('SUCCESS');
        is_invite_suppressed
            ? set_message_text(t('Individual added.'))
            : set_message_text(t('An invitation has been sent.'));
    }

    function onInviteBulk(batch_id, total_count, failure_count) {
        if (total_count > failure_count) {
            populate_individuals();
        }
        if (failure_count === 0) {
            set_message_type('SUCCESS');
            set_message_text(`${total_count} ${t('individuals have been successfully processed.')}`);
        } else {
            set_message_type('ERROR');
            set_message_text(render_bulk_invite_error_message(failure_count));
            set_invite_bulkerrors_batchid(batch_id);
        }
        set_mdl_invite_open(false);
    }


    function onClose_individual_status() {
        set_statuspopup_id(null);
        set_message_text(null);
        set_message_type(null);
    }

    function onChange_updatestatus(focuselement_id) {
        set_statuspopup_id(null);
        populate_individuals(null, focuselement_id);
    }


    function onChange_updatepermission(focuselement_id) {
        set_statuspopup_id(null);
        populate_individuals(null, focuselement_id);
    }

    function onClose_individual_permission() {
        set_permissionpopup_id(null);
        set_message_text(null);
        set_message_type(null);
    }

    function onChange_updatetype(focuselement_id){
        set_individual_typepopup_id(null);
        populate_individuals(null, focuselement_id);
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm crd__all_individuals' id='crd_all_individuals' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_individuals' className='text--xl-medium'>{t('All individuals')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_inviteindividual' className='primary' onClick={() => onClick_inviteindividual()}>{t('Invite individual')}</Button>
                    </div>
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='individuals' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Individuals')} downloadfunction={download_individuals}
                        onChange={populate_individuals}>
                    <Table.OptionsButtons>
                        <TOGGLE value={var_individualstatus} onChange={onClick_individualstatus} options={[{value: 'ALL', text: t('All')}, {value: 'ACTIVE', text: t('Active')}, {value: 'INVITED', text: t('Invited')}, {value: 'ARCHIVE', text: t('Inactive')}]}/>
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='individual_status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('NAME')}</Table.HeaderCell>
                            <Table.HeaderCell field='email' datatype='text' filtertype='text'>{t('EMAIL')}</Table.HeaderCell>
                            <Table.HeaderCell field='job_title' datatype='text' filtertype='text'>{t('TITLE')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                            {auth.has_orgaccess('ORG-GUEST', 'guest') && <Table.HeaderCell field='individual_type_display' datatype='text' filtertype='option'>{t('TYPE')}</Table.HeaderCell>}
                            <Table.HeaderCell field='permission_display' datatype='text' filtertype='option'>{t('PERMISSION')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_individuals.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    {
                                        <CMP_INDIVIDUAL_STATUS
                                            individual={item}
                                            is_open={item.id === var_statuspopup_id}
                                            onOpen={onClick_status}
                                            onClose={onClose_individual_status}
                                            onChange={(focuselement_id) => onChange_updatestatus(focuselement_id)}
                                            onMessage={(type, text) => { set_message_type(type); set_message_text(text); }}
                                        />
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <CMP_VERIFIED_IDENTITY_DISPLAY
                                        individual_name={item.individual_name}
                                        onClick_individual={() => onClick_individual(item.id)}
                                        verified_identity_credential_id={item.identity_verification_credential_id}
                                    />
                                </Table.Cell>
                                <Table.Cell>{item.email}</Table.Cell>
                                <Table.Cell>{item.job_title}</Table.Cell>
                                <Table.Cell>{item.organization}</Table.Cell>
                                {auth.has_orgaccess('ORG-GUEST', 'guest') && <Table.Cell>
                                    {
                                        <CMP_INDIVIDUAL_TYPE
                                            item={item}
                                            current_type={var_individuals.find(i => i.id === item.id).individual_type}
                                            is_open={item.id === var_individual_typepopup_id}
                                            onOpen={onClick_individual_type}
                                            onClose={() => set_individual_typepopup_id(null)}
                                            onChange={(focuselement_id) => onChange_updatetype(focuselement_id)}
                                            onMessage={(type, text) => { set_message_type(type); set_message_text(text); }}
                                            />
                                    }
                                    </Table.Cell>}
                                <Table.Cell>
                                    {
                                        <CMP_INDIVIDUAL_PERMISSION
                                            item={item}
                                            permission_roles={var_permission_roles}
                                            current_permission={var_individuals.find(i => i.id === item.id).permission_id}
                                            is_open={item.id === var_permissionpopup_id}
                                            onOpen={onClick_permission}
                                            onClose={onClose_individual_permission}
                                            onChange={(focuselement_id) => onChange_updatepermission(focuselement_id)}
                                            onMessage={(type, text) => { set_message_type(type); set_message_text(text); }}
                                            />
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button id='btn_inviteindividual' className='primary' onClick={() => onClick_inviteindividual()}>{t('Invite individual')}</Button>
                </div>

                <MDL_INVITE
                    display={var_mdl_invite_open}
                    onClose={() => set_mdl_invite_open(false)}
                    onChange={onInvite}
                    onChangeBulk={onInviteBulk}
                />

                <MDL_INVITE_BULKERRORS
                    display={var_invite_bulkerrors_open}
                    onClose={() => set_invite_bulkerrors_open(false)}
                    batch_id={var_invite_bulkerrors_batchid}
                />

            </div>
        </>
    )


    function render_bulk_invite_error_message(failure_count) {
        return (
            <div className='msg__bulk_invite_error'>
                <div>{`${t('An error occurred that prevented')} ${failure_count} ${t('from being processed.')}`}</div>
                <div className='text--anchor' onClick={() => set_invite_bulkerrors_open(true)}>{t('View individuals and details')}</div>
            </div>
        );
    }

}
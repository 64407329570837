import React from 'react';
import Icon from 'components/cmp_icon';
import Logo from 'components/cmp_logo';

import './cmp_verification_template.css';


function CMP_VERIFICATION_TEMPLATE({ display_type = 'page', template, ready = true, local_file }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    //  event listeners ------------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    // RENDER ==========================================================================================================
    return (
        <div className={`cmp_verification_template template__${display_type}`} style={{ backgroundColor: template.background_color }}>
                {ready &&
                    <>
                        {local_file ?
                            <div className='template__logo'>
                                <img src={window.URL.createObjectURL(local_file)} alt=''></img>
                            </div>
                        : (template.logo && template.logo !== '') &&
                            <div className='template__logo'>
                                <Logo targetid={template.issuer_id} classification={template.logo_classification} filename={template.logo} backup_text={template.issuer_name} />
                            </div>
                        }

                        <div className='template__body rounded-lg shadow-sm'>

                            <div className='template__body__name' style={{ color: template.text_color }}>
                                <div className='display--sm-medium' >{template.title}</div>
                            </div>

                            <div className='template_description' style={{ color: template.text_color }}>
                                <div className='text--sm-regular' style={{ color: 'unset' }}>{template.description}</div>
                            </div>

                            <Icon name='qr_code' className='qr_placeholder' alt='' />

                        </div>
                    </>
                }
        </div>
    );
}

export default CMP_VERIFICATION_TEMPLATE;
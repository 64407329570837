import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import form_helper from 'libs/form-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import { FORM_SELECT, FORM_INPUT, CHECKBOX, SELECT, INPUT } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import jsonPath from 'jsonpath';
import 'i18n';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import './mdl_verification_template_credential.css'

function MDL_VERIFICATION_TEMPLATE_CREDENTIAL({ display, onClose, onChange, verification_template, verification_template_credential_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_issuer_id, set_issuer_id ] = useState('');
    const [ var_issuer_info, set_issuer_info ] = useState({});
    const [ var_credential_id, set_credential_id ] = useState('');
    const [ var_credential_info, set_credential_info ] = useState({});
    const [ var_credential_schema_url, set_credential_schema_url ] = useState('');
    const [ var_credential_claims, set_credential_claims ] = useState([]);
    const [ var_credential_attributes, set_credential_attributes ] = useState([]);

    const [ var_issuer_list, set_issuer_list ] = useState([]);
    const [ var_credential_list, set_credential_list ] = useState([]);
    const [ var_require_input_credential_type, set_require_input_credential_type ] = useState(false);

    const [ var_select_credential_method, set_select_credential_method ] = useState('SELECT');
    const [ var_modal_close_on_escape, set_modal_close_on_escape] = useState(true);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_message_text, set_message_text ] = useState('');
    const [ var_message_type, set_message_type ] = useState('');
    const [ var_processing, set_processing ] = useState(false);

    const claim_operators = [
        { value: 'EXISTS', text: t('Exists'), allowed_types: ['STRING', 'NUMBER', 'DATE', 'ARRAY'] },
        { value: 'GREATERTHAN', text: t('Greater than'), allowed_types: ['NUMBER', 'DATE'] },
        { value: 'CONTAINS', text: t('Contains'), allowed_types: ['ARRAY'] },
        { value: 'EQUALS', text: t('Is equal to'), allowed_types: ['STRING', 'NUMBER', 'DATE'] },
        { value: 'NOT', text: t('Is not equal to'), allowed_types: ['STRING', 'NUMBER', 'DATE'] },
        { value: 'LESSTHAN', text: t('Less than'), allowed_types: ['NUMBER', 'DATE'] },
        { value: 'LIKE', text: t('Like'), allowed_types: ['STRING'] }
    ];

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display) {
            // reset variables
            set_errors([]);
            set_message_text('');
            set_select_credential_method('SELECT');
            set_require_input_credential_type(false);
            set_issuer_id('');
            set_issuer_info({});
            set_credential_info({});
            set_credential_id('');
            set_credential_schema_url('');
            set_credential_claims([]);
            // If user is trying to edit an existing input descriptor
            if (verification_template_credential_id) {
                populate_verification_template_credential();
            } else {
                populate_issuer_list();
            }
            // focus trapping
            let modal = document.getElementById('mdl_input_descriptor');
            modal.querySelector('.modal__content').focus();
            modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    let elements = modal.querySelectorAll('.modal__content, button');
                    let firstelement = elements[0];
                    let lastelement = elements[elements.length - 1];
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === firstelement) {
                            lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === lastelement) {
                            firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    // Fetch each issuer's credential list, but only populate the credential list with the selected issuer's list
    useEffect(() => {
        let cancelled = false;
        const populate_credential_list = async (issuer_id) => {
            if (!issuer_id) return;
            try {
                let results = await API_get_trusted_issuer_credential_list(issuer_id);
                if (!cancelled) {
                    set_credential_list(results.map(item => { return {...item, value: item.id, text: item.credential_name }}))
                }
            } catch (e) {
                console.log(e);
                if (!cancelled) {
                    set_message_text('There was a problem. Please try again later.');
                    set_message_type('ERROR');
                }
            }
        }
        populate_credential_list(var_issuer_id);
        return () => {
            cancelled = true;
        };
    }, [var_issuer_id]);

    // Fetch an issuer's credential metadata, but only populate the credential info with the selected credential
    useEffect(() => {
        let cancelled = false;
        const populate_credential_info = async (credential_id) => {
            if (!credential_id) return;
            let credential_info = var_credential_list.find(item => item.id === credential_id);
            let issuer_metadata_info;
            try {
                issuer_metadata_info = await API_get_trusted_issuer_metadata(credential_info.schema_url);
                if (!cancelled) {
                    if (issuer_metadata_info?.metadata) {
                        set_issuer_info(var_issuer_info => { return { ...var_issuer_info, did: issuer_metadata_info.did } });
                    }
                    let credential_schema = jsonPath.value(issuer_metadata_info.metadata, credential_info.schema_path);
                    if (!credential_schema) {
                        set_message_text('Could not get manifest for selected credential');
                        set_message_type('ERROR');
                    } else {
                        let new_credential_info = { ...credential_info };
                        let credential_attribute_options;
                        // OID4VC
                        if (credential_info.schema_url.includes('openid-credential-issuer')) {
                            new_credential_info.schema_credential_id = credential_schema.id ? credential_schema.id : credential_schema.scope;
                            new_credential_info.format = credential_schema.format;
                            let credential_types = credential_schema.credential_definition ? credential_schema.credential_definition.type : credential_schema.types;
                            new_credential_info.type = credential_types[credential_types.length - 1];
                            set_credential_info(new_credential_info);
                            credential_attribute_options = get_oid4vc_credential_attribute_options(issuer_metadata_info.metadata, credential_info.schema_path);
                            set_credential_attributes(credential_attribute_options);
                        // Entra
                        } else if (credential_info.schema_url.includes('manifest')) {
                            new_credential_info.schema_credential_id = issuer_metadata_info.metadata.id;
                            new_credential_info.format = 'jwt_vc_json'; // Entra doesn't list the format so use jwt_vc_json
                            if (issuer_metadata_info.metadata.types) {
                                new_credential_info.type = issuer_metadata_info.metadata.types[0];
                            }
                            set_credential_info(new_credential_info);
                            set_require_input_credential_type(true);
                            credential_attribute_options = get_entra_credential_attribute_options(issuer_metadata_info.metadata, credential_info.schema_path);
                            set_credential_attributes(credential_attribute_options);
                        } else {
                            set_message_text('Could not determine required credential information from manifest');
                            set_message_type('ERROR');
                        }
                        let identifier_attribute = credential_attribute_options?.find(item => item.value === 'hasCredential.identifier');
                        if (identifier_attribute && !var_credential_claims.find(item => item.claim_attribute === 'hasCredential.identifier')) {
                            set_credential_claims([{
                                id: uuid(),
                                claim_attribute: 'hasCredential.identifier',
                                claim_type: identifier_attribute.claim_type,
                                claim_path: identifier_attribute.claim_path,
                                operator: 'EQUALS',
                                claim_value: var_credential_id
                            }]);
                        }
                    }
                }
            } catch (e) {
                console.log(e);
                if (!cancelled) {
                    set_message_text('There was a problem. Please try again later.');
                    set_message_type('ERROR');
                    return;
                }
            }
        }
        populate_credential_info(var_credential_id);
        return () => {
            cancelled = true;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_credential_id]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_issuer_list() {
        try {
            let results = await API_get_trusted_issuer_list();
            set_issuer_list(results.map(item => { return {...item, value: item.id, text: item.name }}))
        } catch (e) {
            console.log(e);
            set_message_text('There was a problem. Please try again later.');
            set_message_type('ERROR');
        }
    }

    async function populate_verification_template_credential() {
        try {
            let { verification_template_credential, verification_template_credential_claims } = await API_get_verification_template_credential();
            let issuer_info = {
                name: verification_template_credential.issuer_name,
                did: verification_template_credential.issuer_did
            }
            set_issuer_info(issuer_info);
            set_issuer_list([{ ...issuer_info, value: issuer_info.name, text: issuer_info.name }]);
            let verification_template_credential_info = {
                id: verification_template_credential_id,
                credential_name: verification_template_credential.credential_name,
                schema_url: verification_template_credential.schema_url,
                schema_path: verification_template_credential.schema_path,
                schema_credential_id: verification_template_credential.schema_credential_id,
                format: verification_template_credential.credential_format,
                type: verification_template_credential.credential_type,
                allow_expired: verification_template_credential.allow_expired,
                allow_revoked: verification_template_credential.allow_revoked,
            }
            set_credential_info(verification_template_credential_info);
            set_credential_id(verification_template_credential_info.id);
            set_credential_list([{
                    ...verification_template_credential_info,
                    value: verification_template_credential_info.id,
                    text: verification_template_credential_info.credential_name
            }]);
            set_credential_claims(verification_template_credential_claims?.map(item => ({...item, claim_attribute: item.claim_path.substring(item.claim_path.indexOf('credentialSubject.') + 18) })) || []);
        } catch (e) {
            console.log(e);
            set_message_text('There was a problem. Please try again later.');
            set_message_type('ERROR');
        }
    }

    async function create_verification_template_credential(verification_template_credential) {
        set_processing(true);
        try {
            await API_post_verification_template_credential(verification_template_credential);
            onChange && await onChange();
            onClose();
        } catch (e) {
            console.log(e);
            set_message_text('An error has occurred.');
            set_message_type('ERROR');
        } finally {
            set_processing(false);
        }
    }

    async function update_verification_template_credential(verification_template_credential) {
        set_processing(true);
        try {
            await API_put_verification_template_credential(verification_template_credential);
            onChange && await onChange();
            onClose();
        } catch (e) {
            console.log(e);
            set_message_text('An error has occurred.');
            set_message_type('ERROR');
        } finally {
            set_processing(false);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_trusted_issuer_metadata(schema_url) {
        return API.get('verifiable-credentials', '/get-trusted-issuer-metadata/', { queryStringParameters: { schema_url } });
    }

    function API_get_trusted_issuer_credential_list(credential_issuer_id) {
        return API.get('verifiable-credentials', '/get-trusted-issuer-credential-list/' + credential_issuer_id);
    }

    function API_get_trusted_issuer_list() {
        return API.get('verifiable-credentials', '/get-trusted-issuer-list');
    }

    function API_get_verification_template_credential() {
        return API.get('verifiable-credentials', '/get-verification-template-credential/' + verification_template_credential_id);
    }

    function API_post_verification_template_credential(verification_template_credential) {
        return API.post('verifiable-credentials', '/post-verification-template-credential', { body: verification_template_credential });
    }

    function API_put_verification_template_credential(verification_template_credential) {
        return API.put('verifiable-credentials', '/put-verification-template-credential/' + verification_template_credential_id, { body: verification_template_credential });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onChange_select_issuer(e, { value }) {
        set_errors([]);
        set_message_text('');
        set_select_credential_method('SELECT');
        set_credential_info({});
        set_credential_id('');
        set_credential_claims([]);
        set_require_input_credential_type(false);
        let selected_issuer_info = var_issuer_list.find(item => item.id === value);
        set_issuer_id(selected_issuer_info.id);
        set_issuer_info(selected_issuer_info);
        set_credential_list([]);
    }

    async function onChange_select_credential(e, { value }) {
        set_errors([]);
        set_message_text('');
        set_require_input_credential_type(false);
        set_credential_id(value);
        set_credential_info({});
        set_credential_claims([]);
    }

    function onChange_input_schema_url(url) {
        set_errors([]);
        set_message_text('');
        set_issuer_id('');
        set_issuer_info({});
        set_credential_schema_url(url);
        set_credential_info({});
        set_credential_list([]);
        set_credential_claims([]);
    }

    function onChange_modify_credential_type(credential_type) {
        set_message_text('');
        set_credential_info({ ...var_credential_info, type: credential_type });
        set_errors(var_errors.filter(item => item.property !== 'credential_type'));
        if (!var_require_input_credential_type) {
            set_message_text('Changing the credential type could cause problems when verifying credentials.');
            set_message_type('WARNING');
        }
    }

    async function onClick_get_schema() {
        set_errors([]);
        set_message_text('');
        set_processing(true);
        try {
            let issuer_metadata_info = await API_get_trusted_issuer_metadata(var_credential_schema_url);
            // Validate issuer DID because the metadata is coming from an external source
            if (issuer_metadata_info.metadata) {
                set_issuer_info({ name: issuer_metadata_info.name, did: issuer_metadata_info.did });
                populate_credential_list_from_issuer_metadata(issuer_metadata_info.metadata, var_credential_schema_url);
            } else {
                set_errors([{property: 'schema_url', description: 'Could not get the manifest for this URL.'}]);
            }
        } catch (e) {
            console.log(e);
            set_errors([{property: 'schema_url', description: 'Invalid URL. Please check the URL is valid and hosts a valid manifest.'}]);
        } finally {
            set_processing(false);
        }
    }

    function onClick_toggle_credential_selection_method() {
        set_errors([]);
        set_message_text('');
        set_issuer_id('');
        set_issuer_info({});
        set_credential_info({});
        set_credential_id('');
        set_credential_schema_url('');
        set_credential_claims([]);
        set_select_credential_method(var_select_credential_method === 'SELECT' ? 'INPUT' : 'SELECT');
        set_credential_list([]);
        document.querySelector('#mdl_input_descriptor .modal__content').focus();
    }

    async function onClick_save() {
        if (var_processing) return;
        set_errors([]);
        set_message_text('');
        let errors = [];
        let verification_template_credential = {
            verification_template_id: verification_template.id,
            issuer_name: var_issuer_info.name,
            issuer_did: var_issuer_info.did,
            credential_name: var_credential_info.credential_name,
            schema_url: var_credential_info.schema_url,
            schema_path: var_credential_info.schema_path,
            schema_credential_id: var_credential_info.schema_credential_id,
            credential_format: var_credential_info.format,
            credential_type: var_credential_info.type,
            allow_expired: var_credential_info.allow_expired ? 'YES' : null,
            allow_revoked: var_credential_info.allow_revoked ? 'YES' : null,
            credential_claims: var_credential_claims
        }

        // validation
        if (!form_helper.validate_required_string(verification_template_credential.credential_type)) {
            errors.push({ property: 'credential_type', description: t('Credential type is required') });
        }
        var_credential_claims.forEach(claim => {
            if (!form_helper.validate_required_string(claim.claim_attribute)) {
                errors.push({ property: `claim_attribute_${claim.id}`, description: t('Claim attribute is required') });
                return;
            } else if (!form_helper.validate_required_string(claim.operator)) {
                errors.push({ property: `operator_${claim.id}`, description: t('Claim operator type is required') });
                return;
            } else if (claim.operator !== 'EXISTS' && !form_helper.validate_required_string(claim.claim_value)) {
                errors.push({ property: `claim_value_${claim.id}`, description: t('Claim value is required') });
                return;
            }
        });
        set_errors(errors);
        if (errors.length > 0) return;

        if (verification_template_credential_id) {
            verification_template_credential.id = verification_template_credential_id;
            await update_verification_template_credential(verification_template_credential)
        } else {
            await create_verification_template_credential(verification_template_credential);
        }
    }

    function onClick_cancel() {
        onClose();
    }

    function populate_credential_list_from_issuer_metadata(metadata, issuer_metadata_url) {
        let credential_list = [];
        // If Entra manifest URL then there is only one schema available
        if (metadata.display.claims) {
            credential_list.push({
                value: metadata.id,
                text: metadata.display?.card?.title ?? metadata.id,
                id: metadata.id,
                credential_name: metadata.display?.card?.title ?? metadata.id,
                schema_url: issuer_metadata_url,
                schema_path: '$.display.claims',
                schema_credential_id: metadata.id
            });
        // If OID4VC openid-credential-issuer URL then there could be multiple credentials
        } else if (metadata.credential_configurations_supported || metadata.credentials_supported) {
            // OID4VC draft 13
            for (let credential_id in metadata.credential_configurations_supported) {
                let display = metadata.credential_configurations_supported[credential_id].display?.find(item => item.locale?.startsWith(auth.language.i18n.split('-')[0]));
                credential_list.push({
                    value: credential_id,
                    text: display ? display.name : credential_id,
                    id: credential_id,
                    credential_name: display ? display.name : credential_id,
                    schema_url: issuer_metadata_url,
                    schema_path: `$.credential_configurations_supported['${credential_id}']`,
                    schema_credential_id: credential_id
                });
            }
            // OID4VC draft 12
            for (let index = 0; index < metadata.credentials_supported.length; index++) {
                let credential = metadata.credentials_supported[index];
                // If the credential is not already in the list
                if (!credential_list.find(item => item.id === credential.id)) {
                    let display = credential.display?.find(item => item.locale?.startsWith(auth.language.i18n.split('-')[0]));
                    credential_list.push({
                        value: credential.id,
                        text: display ? display.name : credential.id,
                        id: credential.id,
                        credential_name: display ? display.name : credential.id,
                        schema_url: issuer_metadata_url,
                        schema_path: `$.credentials_supported[${index}]`,
                        schema_credential_id: credential.id
                    });
                }
            }
        } else {
            set_message_text('Could not determine credentials from provided URL. Please check your metatadata and try again.');
            set_message_type('ERROR');
        }
        set_credential_list(credential_list);
        if (credential_list.length === 1) {
            set_credential_id(credential_list[0].value);
        }
    }

    function onClick_add_attribute() {
        set_credential_claims([ ...var_credential_claims, { id: uuid(), claim_attribute: null, operator: null, claim_value: null, claim_path: null }]);
    }

    function onClick_delete_attribute(claim_id) {
        set_credential_claims(var_credential_claims.filter(claim => claim.id !== claim_id));
    }

    function onChange_claims(name, value, id) {
        set_errors(var_errors.filter(error => error.property !== name));
        let credential_claims = [...var_credential_claims];
        let claim_to_change = credential_claims.find(claim => claim.id === id);
        switch (name) {
            case `claim_attribute_${id}`:
                claim_to_change.claim_attribute = value;
                claim_to_change.claim_type = var_credential_attributes.find(attribute => attribute.value === value).claim_type;
                claim_to_change.claim_path = var_credential_attributes.find(attribute => attribute.value === value).claim_path;
                claim_to_change.operator = null;
                claim_to_change.claim_value = null;
                break;
            case `operator_${id}`:
                claim_to_change.operator = value;
                if (value === 'EXISTS') {
                    claim_to_change.claim_value = null;
                }
                break;
            case `claim_value_${id}`:
                claim_to_change.claim_value = value;
                break;
            default:
                throw new Error('Unknown claim change');
        }
        set_credential_claims(credential_claims);
    }

    function get_oid4vc_credential_attribute_options(issuer_metadata, schema_path) {
        let vc_format = _.get(issuer_metadata, schema_path.slice(2) + '.format');
        let attributes_list = get_attributes(_.get(issuer_metadata, schema_path.slice(2) + '.credential_definition.credentialSubject'));
        return attributes_list.map(attribute => {
            let attribute_info = _.get(issuer_metadata, `${schema_path.slice(2)}.credential_definition.credentialSubject.${attribute}`);
            let attribute_label = get_attribute_label(attribute_info);
            return {
                value: attribute,
                text: attribute_label ? attribute_label : attribute,
                content: attribute_label ? render_attribute_option_twoline(attribute_label, attribute) : null,
                claim_path: `${vc_format === 'jwt_vc_json' ? '$.vc' : '$'}.credentialSubject.${attribute}`,
                claim_type: attribute_info.value_type.toUpperCase(),
            }
        });
    }

    function get_entra_credential_attribute_options(issuer_metadata, schema_path) {
        let attributes_object_list = _.get(issuer_metadata, schema_path.slice(2));
        let attributes_list = Object.keys(attributes_object_list).filter(key => attributes_object_list[key].type?.toLowerCase() === 'string');
        return attributes_list.map(attribute => {
            let attribute_info = _.get(issuer_metadata, schema_path.slice(2))[attribute];
            let attribute_label = get_attribute_label(attribute_info);
            return {
                value: attribute.slice(21),
                text: attribute_label ? attribute_label : attribute.slice(21),
                content: attribute_label ? render_attribute_option_twoline(attribute_label, attribute.slice(21)) : null,
                claim_path: attribute,
                claim_type: attribute_info.type.toUpperCase(),
            };
        });
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_input_descriptor'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_add_credential'
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_add_credential'>{verification_template_credential_id ? t('Edit credential') : t('Add credential')}</div>
                </div>
            </div>
            <Form className='modal__content' id='form_verification_templates_addcredential' tabIndex='0'>
                {var_message_text &&
                        <Message
                            error={var_message_type === 'ERROR'}
                            warning={var_message_type === 'WARNING'}
                            icon={<Icon name={var_message_type.toLowerCase()} className='icon' />}
                            header={var_message_text} />
                }

                {!var_message_text && var_errors.length > 0 &&
                    <Message error
                            icon={<Icon name='error' className='icon' />}
                            header={t('There are some errors with your inputs')}
                    />
                }
                <div id='select_credential_method'>
                    {render_select_credential_method()}
                </div>
                <div id='select_credential_attributes'>
                    <div className='text--lg-medium'>{t('Attributes')}</div>
                    <div className='text--sm-regular'>{t('Verify the following attributes of the credential.')}</div>
                    <div id='select_accepted_statuses'>
                        <CHECKBOX
                            name='accept_expired'
                            label={t('Accept expired credentials')}
                            checked={var_credential_info.allow_expired === 'YES' ? true : false}
                            onChange={(e, data) => set_credential_info({ ...var_credential_info, allow_expired: data.checked ? 'YES' : null })}
                            disabled={!var_credential_info.schema_credential_id}
                            errors={var_errors}
                        />
                        <CHECKBOX
                            name='accept_revoked'
                            label={t('Accept revoked credentials')}
                            checked={var_credential_info.allow_revoked === 'YES' ? true : false}
                            onChange={(e, data) => set_credential_info({ ...var_credential_info, allow_revoked: data.checked ? 'YES' : null })}
                            disabled={!var_credential_info.schema_credential_id}
                            errors={var_errors}
                        />
                    </div>
                    <div className='detailsgroup_wrapper'>
                        <div className='detailsgroup'>
                            <Form.Field className='detailsgroup__value'>
                                <SELECT
                                    value='type'
                                    disabled
                                    options={[{ value: 'type', text: t('Type') }]}
                                />
                            </Form.Field>
                        </div>
                        <div className='detailsgroup'>
                            <Form.Field className='detailsgroup__value'>
                                <SELECT
                                    value='CONTAINS'
                                    disabled
                                    options={[{ value: 'CONTAINS', text: t('Contains') }]}
                                />
                            </Form.Field>
                        </div>
                        <div className='detailsgroup'>
                            <Form.Field className='detailsgroup__value'>
                                <INPUT
                                    property='credential_type'
                                    value={var_credential_info.type}
                                    onChange={(event) => onChange_modify_credential_type(event.target.value)}
                                    maxLength={50}
                                    errors={var_errors}
                                    disabled={!var_credential_info.schema_credential_id}
                                />
                            </Form.Field>
                        </div>
                    </div>
                    {var_credential_claims.map(claim =>
                        <div className='detailsgroup_wrapper' key={claim.id}>
                            <div className='detailsgroup'>
                                <Form.Field className='detailsgroup__value'>
                                    <SELECT
                                        property={`claim_attribute_${claim.id}`}
                                        value={claim.claim_attribute}
                                        onOpen={() => set_modal_close_on_escape(false)}
                                        onClose={() => set_modal_close_on_escape(true)}
                                        onChange={(event, { name, value }) => onChange_claims(name, value, claim.id)}
                                        options={var_credential_attributes}
                                        errors={var_errors}
                                        placeholder={t('Select attribute')}
                                    />
                                </Form.Field>
                            </div>
                            <div className='detailsgroup'>
                                <Form.Field className='detailsgroup__value'>
                                    <SELECT
                                        property={`operator_${claim.id}`}
                                        value={claim.operator}
                                        onOpen={() => set_modal_close_on_escape(false)}
                                        onClose={() => set_modal_close_on_escape(true)}
                                        onChange={(event, { name, value }) => onChange_claims(name, value, claim.id)}
                                        options={claim_operators.filter(operator => !claim.claim_type || operator.allowed_types.includes(claim.claim_type))}
                                        errors={var_errors}
                                        disabled={!claim.claim_attribute}
                                        placeholder={t('Select condition')}
                                    />
                                </Form.Field>
                            </div>
                            {(claim.operator && claim.operator !== 'EXISTS') &&
                                <div className='detailsgroup'>
                                    <Form.Field className='detailsgroup__value'>
                                        <INPUT
                                            property={`claim_value_${claim.id}`}
                                            value={claim.claim_value}
                                            onChange={(event, { name, value }) => onChange_claims(name, value, claim.id)}
                                            maxLength={50}
                                            errors={var_errors}
                                        />
                                    </Form.Field>
                                </div>
                            }
                            <Button className='tertiary delete_icon' onClick={() => onClick_delete_attribute(claim.id)}>
                                <Icon name='delete' className='color--primary-500' alt={t('Delete attribute')} />
                            </Button>
                        </div>
                    )}
                </div>
                <Button type='button' className='tertiary' disabled={!var_credential_info.schema_credential_id || !var_credential_attributes.some(item => !item.disabled)} onClick={onClick_add_attribute}>{t('Add attribute')}</Button>
            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button id='btn_credentials_add_save' className='primary' disabled={!var_credential_info.schema_credential_id} onClick={onClick_save}>{t('Save')}</Button>
                    <Button id='btn_credentials_add_close' className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                </div>
            </div>
            <Processing display={var_processing} processingtext={t('Processing')} />
        </Modal>
    );

    function render_select_credential_method() {
        if (var_select_credential_method === 'SELECT') {
            return (<>
                <div className='text--lg-medium'>{t('Trusted issuers')}</div>
                <div className='text--sm-regular'>{t('Select a credential to verify from our trusted issuers list.')}</div>
                <div className='detailsgroup_wrapper'>
                    <FORM_SELECT
                        property='issuer'
                        label={t('Issuer')}
                        value={var_issuer_id}
                        onChange={onChange_select_issuer}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={verification_template_credential_id ? var_issuer_info?.name : t('Select issuer')}
                        options={var_issuer_list}
                        errors={var_errors}
                        disabled={verification_template_credential_id ? true : false}
                    />
                    <FORM_SELECT
                        property='credential'
                        label={t('Credential')}
                        value={var_credential_id}
                        onChange={onChange_select_credential}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={verification_template_credential_id ? var_credential_info?.credential_name : t('Select credential')}
                        options={var_credential_list}
                        disabled={(var_credential_list?.length === 0 || !var_issuer_id || verification_template_credential_id) ? true : false}
                        errors={var_errors}
                    />
                </div>
                {!verification_template_credential_id &&
                    <div className='btn_toggle_credential_selection_method_wrapper'>
                        <div className='text--sm-regular'>{t("If you can't find what you're looking for,")}</div>
                        <Button id='btn_toggle_credential_selection_method' type='button' className='tertiary' onClick={onClick_toggle_credential_selection_method}>{t('provide the URL to the credential manifest.')}</Button>
                    </div>
                }
            </>);
        } else {
            return (<>
                <div className='text--lg-medium'>{t('Credential manifest')}</div>
                <div className='text--sm-regular'>{t("Copy and paste the URL of the credential manifest into the field. Then, click 'Get manifest' and select the credential.")}</div>
                <div id='schema_url'>
                    <FORM_INPUT
                        property='schema_url'
                        placeholder={t('Manifest URL')}
                        value={var_credential_schema_url}
                        onChange={(event) => onChange_input_schema_url(event.target.value)}
                        maxLength={1000}
                        errors={var_errors}
                    />
                    <Button type='button' className='primary' disabled={!var_credential_schema_url} onClick={onClick_get_schema}>{t('Get manifest')}</Button>
                </div>
                <FORM_SELECT
                    property='credential'
                    label={t('Credential')}
                    value={var_credential_id}
                    onChange={onChange_select_credential}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('Select credential')}
                    options={var_credential_list}
                    disabled={var_credential_list.length === 0}
                    errors={var_errors}
                />
                <div className='btn_toggle_credential_selection_method_wrapper'>
                    <Button id='btn_toggle_credential_selection_method' type='button' className='tertiary' onClick={onClick_toggle_credential_selection_method}>{t('See issuer list')}</Button>
                </div>
            </>);
        }
    }

    function render_attribute_option_twoline(attribute_label, attribute) {
        return (
            <div className='dropdown__item__twoline'>
                <div className='text--sm-regular'>
                    {attribute_label}
                </div>
                <div className={'text--xs-regular'}>
                    {attribute}
                </div>
            </div>
        );
    }
}

function get_attributes(credentialSubject, path = '') {
    return Object.keys(credentialSubject).reduce((accumulator, attribute) => {
        if (typeof credentialSubject[attribute] === 'object' && !!credentialSubject[attribute] && !Object.hasOwn(credentialSubject[attribute], 'value_type')) {
            return [...accumulator, ...get_attributes(credentialSubject[attribute], path + attribute + '.')];
        }
        if (attribute.startsWith('@')) {
            return [...accumulator, path.slice(0, -1) + `['${attribute}']`];
        } else {
            return [...accumulator, path + attribute];
        }
    }, []);
};

/**
 * Find an attribute's potential user friendly label, first checks the
 * signed in user's preferred language, then English, and then first option
 * in the metadata if available.
 * @param {Object} attribute_info 
 * @returns {String}
 */
function get_attribute_label(attribute_info) {
    let attribute_label = null;

    if (attribute_info.display) { // OID4VC
        let preferred_display = attribute_info.display.find(item => item.locale === (auth.language.i18n) || item.locale?.startsWith(auth.language.i18n.split('-')[0]));
        let english_display = attribute_info.display.find(item => item.locale?.startsWith('en'));
        let default_display = attribute_info.display[0];
        attribute_label = preferred_display?.name || english_display?.name || default_display?.name
    } else if (attribute_info.label) { // Entra
        // For Entra, the language is passed during the manifest retrieval call as Accept-Language, so assume the label is correct
        attribute_label = attribute_info.label;
    }
    return attribute_label;
}

MDL_VERIFICATION_TEMPLATE_CREDENTIAL.propTypes = {
    display: propTypes.bool.isRequired,
    onClose: propTypes.func,
    onChange: propTypes.func
};

export default MDL_VERIFICATION_TEMPLATE_CREDENTIAL;

import React, { useEffect, useState } from 'react';
import form_helper from 'libs/form-lib';
import lookup from 'libs/lookup-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_INPUT, FORM_SELECT, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import 'i18n';
import Processing from "components/cmp_processing";


export default function CRD_DETAILS({ step, onChange_step, assignment, onChange_assignment, display_processing }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_opened, set_opened ] = useState(false)
    const [ var_errors, set_errors ] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_errors([]);
        if (step === 'DETAILS') {
            set_opened(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);


    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        onChange_step('DETAILS');
    }

    function onChange_input(event) {
        let updated_assignment = {...assignment};
        updated_assignment[event.target.name] = event.target.value;
        onChange_assignment(updated_assignment);
        if (var_errors.length > 0) {
            const errors = var_errors.filter(error => error.property !== event.target.name);
            set_errors(errors);
        }
    }

    function onClick_nextstep() {
        let updated_assignment = {...assignment};
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(updated_assignment.name)) {
            errors.push({property: 'name', description: t('Assignment name is required.')});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(updated_assignment, 'name');
        form_helper.cleanse_string_property(updated_assignment, 'address');
        form_helper.cleanse_string_property(updated_assignment, 'city');
        onChange_assignment(updated_assignment);

        onChange_step('SETTINGS');
    }

    function onChange_country(e, { value }) {
        let updated_assignment = { ...assignment };
        if (updated_assignment.country !== value) {
            updated_assignment.province = null;
        }
        updated_assignment.country = value;
        onChange_assignment(updated_assignment);
    }

    function onChange_region(e, { value }) {
        let updated_assignment = { ...assignment };
        updated_assignment.province = value;
        onChange_assignment(updated_assignment);
    }

    function onChange_assignment_status(status) {
        let updated_assignment = { ...assignment };
        updated_assignment.status = status;
        onChange_assignment(updated_assignment);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_create1' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__icon'>
                    {(var_opened && step !== 'DETAILS' && form_helper.validate_required_string(assignment.name)) ?
                        <Icon name='checkmark' className='icon__checkmark' alt='checkmark icon' />
                        :
                        <div className='card__header__icon__step text--xl-bold icon--dark_gray'>1</div>
                    }
                </div>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_assignment_details'>{t('Assignment details')}</div>
                    {(!var_opened || step === 'DETAILS') &&
                        <div className='text--sm-regular'>{t('Set the Name and Address for the assignment.')}</div>
                    }
                </div>
                <div className='card__header__right card__header__btns'>
                    {(var_opened && step !== 'DETAILS') &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {(step !== 'DETAILS' && var_opened) ?
                <>
                    <div className='card__content create_summary'>
                        <FORM_DETAILSGROUP label={t('Status')} value={assignment.status === 'ACTIVE' ? t('Active') : assignment.status === 'DRAFT' ? t('Draft') :  t('Inactive')} type='badge' badgecolor={assignment.status === 'ACTIVE' ? 'badge--green' : assignment.status === 'DRAFT' ? 'badge--yellow' : 'badge--gray'}/>
                        <FORM_DETAILSGROUP label={t('Name')} value={assignment.name} />
                        <FORM_DETAILSGROUP label={t('Address')} value={assignment.address} />
                        <FORM_DETAILSGROUP label={t('Location')} value={
                            <div style={{ flexDirection: 'column' }}>
                                <div className='text--sm-regular'>{assignment.city}{assignment.city && assignment.province ? ', ' : ''}{assignment.province}</div>
                                {(assignment.country && assignment.country !== '') && <div className='text--sm-regular'>{assignment.country}</div>}
                            </div>
                        } />
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    </div>

                </>
                : step === 'DETAILS' ?
                    <>
                        <Form className='card__content' id='add_cr8assignment_step1' aria-labelledby='hdr_assignment_details'>

                            {var_errors.length > 0 &&
                                <Message
                                    error icon={<Icon name='error' className='icon'/>}
                                    header={t('There are some errors with your inputs')}
                                />
                            }

                            <FORM_TOGGLE
                                label={t('Status')}
                                value={assignment.status}
                                options={[{ value: 'DRAFT', text: t('Draft') }, { value: 'ACTIVE', text: t('Active') }]}
                                onChange={onChange_assignment_status}
                            />

                            <FORM_INPUT
                                property='name'
                                label={t('Name (required)')}
                                value={assignment.name}
                                onChange={onChange_input}
                                placeholder={t('Assignment name')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='address'
                                label={t('Address')}
                                value={assignment.address}
                                onChange={onChange_input}
                                placeholder={t('Address')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_SELECT
                                property='country'
                                label={t('Country')}
                                value={assignment.country || ''}
                                onChange={onChange_country}
                                placeholder={t('Select')}
                                options={lookup.country.get_country_options(t('Select country'))}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_SELECT
                                property='province'
                                label={t('Province/State')}
                                value={assignment.province || ''}
                                onChange={onChange_region}
                                placeholder={t('Select')}
                                options={lookup.country.get_region_options(assignment.country, t('Select region'))}
                                disabled={!assignment.country}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='city'
                                label={t('City')}
                                value={assignment.city}
                                onChange={onChange_input}
                                placeholder={t('City')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                        </Form>
                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_nextstep}>{t('Continue to step 2')}</Button>
                        </div>

                    </>
                    : null
            }

            <Processing display={display_processing} processingtext='' />

        </div>
    );
}
import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

function COLORPICKER({ property, value, onChange, disabled = false, errors, ...props }) {

    //  event functions ------------------------------------------------------------------------------------------------
    let error = errors ? errors.find(item => item.property === property) : undefined;


    // RENDER ==========================================================================================================
    return (
        <Form.Field>
            <Form.Input
                type='color'
                name={property}
                value={value || ''}
                onChange={onChange}
                disabled={disabled}
                error={error !== undefined}
                { ...props }
            />
            {error &&
                <div className='field__feedback error'>
                    <div className='description text--sm-regular'>{error.description}</div>
                </div>
            }
        </Form.Field>
    );
}

COLORPICKER.propTypes = {
    property: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    disabled: propTypes.bool,
    errors: propTypes.array
};

export default COLORPICKER;
import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import { Modal, Form, Button, Message } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { FORM_SELECT, SELECT_RADIO, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';

import Icon from 'components/cmp_icon';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';

import './mdl_assign_requirement.css';


function MDL_ASSIGN_REQUIREMENT({ display, onClose, assignment, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_requirement_options, set_requirement_options ] = useState([]);
    const [ var_individual_options, set_individual_options ] = useState([]);
    const [ var_selected_location_requirement, set_selected_location_requirement ] = useState(null);
    const [ var_selected_individuals, set_selected_individuals ] = useState([]);
    const [ var_all_individuals, set_all_individuals ] = useState('NO');
    const [ var_requested_all_individuals, set_requested_all_individuals ] = useState('NO');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display && assignment.location_id) {
            // reset and populate options
            set_requirement_options([]);
            set_individual_options([]);
            set_selected_location_requirement(null);
            set_selected_individuals([]);
            set_errors([]);
            set_processing(false);
            set_all_individuals('NO');
            set_requested_all_individuals('NO');
            populate_requirement_options();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, assignment.location_id]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirement_options() {
        try {
            let requirements = await API_get_loc_requirements();
            let transformed = requirements.map(item => ({
                value: item.id,
                text: item.name,
                has_preapproved: item.has_preapproved
            }));
            set_requirement_options(transformed);
            if (requirements.length === 0) {
                set_errors([{ description: t('There are no available requirements to add') }])
            }
        } catch (e) {
            console.log(e);
            set_errors([{ description: t('There was a problem loading.  Please try again later') }]);
        }
    }

    async function populate_individual_options(locationrequirement_id) {
        try {
            if (!locationrequirement_id) return;

            let results = await API_get_loc_available_requirement_assignments(locationrequirement_id);
            let transformed = results.results.map(item => ({
                value: item.id,
                primary_text:
                            <CMP_VERIFIED_IDENTITY_DISPLAY
                                individual_name={item.individual_name}
                                verified_identity_credential_id={item.identity_verification_credential_id}
                                display_nonclickable_icon={true}
                            />,
                secondary_text: item.already_added === 'YES' ? t('Has requirement') : item.email,
                disabled: item.already_added === 'YES'
            }));
            set_individual_options(transformed);
            set_all_individuals(results.all_individuals);
            set_requested_all_individuals(results.all_individuals);

            if (!results.results.some(item => item.already_added === 'NO')) {
                set_errors([{ description: t('There are no available individuals to add to') }]);
            }
        } catch (e) {
            console.log(e);
            set_errors([{ description: t('There was a problem loading.  Please try again later') }]);
        }
    }

    function get_requirement_error() {
        if (var_errors.some(item => item.property === 'requirement')) return var_errors;
        if (!var_selected_location_requirement) return [];
        if (var_requirement_options.find(item => item.value === var_selected_location_requirement).has_preapproved === 'YES') return [];
        return [{ property: 'requirement', description: t('This requirement does not have any pre-approved credentials so automatic validation is not possible.  Pre-approved credentials can be added in the Requirements Setup page') }];
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_requirements() {
        return API.get('requirements', '/get-loc-requirements/' + assignment.location_id);
    }

    function API_get_loc_available_requirement_assignments(locationrequirement_id) {
        return API.get('requirements', '/get-loc-available-requirement-assignments/' + locationrequirement_id);
    }

    function API_post_loc_requirement_assignments() {
        return API.post('requirements', '/post-loc-requirement-assignments', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: {
                locationrequirement_id: var_selected_location_requirement,
                individual_ids: var_requested_all_individuals === 'YES' ? [] : var_selected_individuals,
                requested_all_individuals: var_requested_all_individuals
            }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_requirement(e, data) {
        set_selected_location_requirement(data.value);
        set_errors([]);
        set_selected_individuals([]);
        set_individual_options([]);
        populate_individual_options(data.value);
    }

    function onChange_individuals(value) {
        set_selected_individuals(value);
        set_errors([]);
    }

    function onChange_requested_all_individuals(value) {
        set_requested_all_individuals(value);

        // If the asssigment requirement already mandatory, set disabled perperty for each listed individual when Yes/No toggles
        if (var_all_individuals === 'YES') {
            let individual_options = [...var_individual_options];
            individual_options.forEach(item => item.disabled = value === 'YES');
            set_individual_options(individual_options);
        }
    }

    async function onClick_assign() {
        let errors = [];
        if (var_selected_location_requirement === null) {
            errors.push({ property: 'requirement', description: t('Please select a requirement.') });
        } else if (var_requested_all_individuals === 'NO' && var_selected_individuals.length === 0) {
            errors.push({ property: 'individuals', description: t(`Please select at least one individual to add`) });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        set_processing(true);
        try {
            await API_post_loc_requirement_assignments();
            onChange && onChange(var_selected_individuals.length, var_requirement_options.find(item => item.value === var_selected_location_requirement).text);
        } catch (e) {
            console.log(e);
            set_errors([{ description: t('There was a problem saving. Please try again later') }]);
        }
        set_processing(false);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer={'inverted'}
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}>

            <div className='modal__header'>
                <div style={{ width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium' id='hdr_assignment_assignreq'>{t('Assign requirement')}</div>
                        <div className='text--sm-regular'>{t('Select a requirement to add individuals to')}.</div>
                    </div>
                    <div className='modal__header__right'>
                        <div className="modal__header__closeicon color--primary-500">
                            <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                        </div>
                    </div>
                </div>
            </div>

            <Form className='modal__content' id='form_assignment_assignreq' aria-labelledby='hdr_assignment_assignreq'>
                {var_errors.length === 1
                    ? <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_errors[0].description} />
                    : var_errors.length > 1
                    ? <Message error
                        icon={<Icon name='error' className='icon' />}
                        list={var_errors.map(item => item.description)} />
                    : null
                }
                <FORM_SELECT
                    property={t('requirement')}
                    label={t('Requirement')}
                    placeholder={t('Select requirement')}
                    value={var_selected_location_requirement}
                    options={var_requirement_options}
                    onChange={onChange_requirement}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    errors={get_requirement_error()} />

                {var_selected_location_requirement &&
                    <>
                        {((var_all_individuals === 'NO' && auth.has_access(assignment, 'ORG-REQ', 'assign all')) || (var_all_individuals === 'YES' && auth.has_access(assignment, 'ORG-REQ', 'unassign all'))) &&
                            <FORM_TOGGLE label={t('Mandatory')} description={t('Selecting \'Yes\' will make this requirement mandatory and automatically assign it to all individuals on this assignment.')} value={var_requested_all_individuals}
                                onChange={onChange_requested_all_individuals} options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]} />
                        }
                        <div className='detailsgroup'>
                            <div className='detailsgroup__label text--sm-medium'>{t('Individual')}</div>
                            <Form.Field>
                                <SELECT_RADIO
                                    name='options'
                                    value={var_selected_individuals}
                                    options={var_individual_options}
                                    single_or_multiple='MULTIPLE' onChange={onChange_individuals}
                                    disabled={var_requested_all_individuals === 'YES'}
                                    aria_label={t('Select the individuals you would like to assign the requirement to.')} />
                            </Form.Field>
                        </div>
                    </>
                }
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' disabled={var_all_individuals === 'YES' && var_requested_all_individuals === 'YES'} onClick={onClick_assign}>{t('Assign')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>
            <Processing display={var_processing} processingtext={t('Processing')} />
        </Modal>
    );
}

export default MDL_ASSIGN_REQUIREMENT;
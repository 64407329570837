import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import 'i18n';
import Processing from 'components/cmp_processing';
import form_helper from 'libs/form-lib';
import { CHECKBOX, FORM_DATEPICKER, FORM_INPUT, FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';

import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';

export default function MDL_CREATELINK({ display, onClose, onChange, assignment_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_link, set_link ] = useState({});
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_selected_individuals, set_selected_individuals ] = useState([]);
    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_selected_credentials, set_selected_credentials ] = useState([]);
    const [ var_all_individuals, set_all_individuals ] = useState(false);
    const [ var_all_credentials, set_all_credentials ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_assignmentlink'));
            set_selected_individuals([]);
            set_link({});
            set_errors([]);
            set_credentials([]);
            set_selected_credentials([]);
            set_all_individuals(false);
            set_all_credentials(false);
            assignment_id && populate_individuals();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, assignment_id]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals() {
        try {
            let results = await API_get_individuals_to_add_to_link();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text:
                        <CMP_VERIFIED_IDENTITY_DISPLAY
                            individual_name={item.individual_name}
                            verified_identity_credential_id={item.identity_verification_credential_id}
                            display_nonclickable_icon={true}
                        />,
                secondary_text: item.email
            }));
            set_individuals(transformed);
        } catch (e) {
            set_errors([{ description: t('There was a problem loading.  Please try again later') }]);
            console.log(e);
        }
    }

    async function populate_credentials(all_individuals, selected_individuals) {
        set_selected_credentials([]);
        if (all_individuals || selected_individuals.length > 0) {
            try {
                let body = {
                    all_individuals: all_individuals,
                    individuals: selected_individuals,
                    tz: datelib.timezone
                };
                let results = await API_get_credentials_to_add_to_link(body);
                if (results.length === 0) {
                    set_errors([{ description: t('There are no available credentials to include') }]);
                }
                let transformed = results.map(item => ({
                    value: item.id,
                    primary_text: item.text
                }));
                set_credentials(transformed);
            } catch (e) {
                console.log(e);
            }
        }
    }

    async function create_assignment_link(link) {
        set_processing(true);
        try {
            let body = {
                ...link,
                individual_ids: var_all_individuals ? [] : var_selected_individuals,
                all_individuals: var_all_individuals ? 'YES' : 'NO',
                all_credentials: var_all_credentials ? 'YES' : 'NO',
                credential_ids: var_all_credentials ? [] : var_selected_credentials
            }

            await API_post_assignment_report(body);
            onChange && onChange();
            onClose();
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individuals_to_add_to_link() {
        return API.get('location', '/get-individuals-to-add-to-link/' + assignment_id);
    }

    function API_get_credentials_to_add_to_link(body) {
        return API.post('location', '/get-credentials-to-add-to-link/' + assignment_id, { body: body });
    }

    function API_post_assignment_report(body) {
        return API.post('external-report', '/post-assignment-report/' + assignment_id, { body: body });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        set_errors([]);
        let link = { ...var_link };
        link[event.target.name] = event.target.value;
        set_link(link);
    }

    function onChange_date(e, { value }) {
        set_errors([]);
        let link = { ...var_link };
        link.expiry_timestamp = value;
        set_link(link);
    }

    function onChange_all_individuals() {
        set_errors([]);
        if (var_all_individuals) {
            set_selected_individuals([]);
            set_selected_credentials([]);
            set_all_credentials(false);
        } else {
            set_selected_individuals(var_individuals.map(item => item.value));
        }
        populate_credentials(!var_all_individuals, []);
        set_all_individuals(!var_all_individuals);
    }

    function onChange_all_credentials() {
        set_errors([]);
        if (var_all_credentials) {
            set_selected_credentials([]);
        } else {
            set_selected_credentials(var_credentials.map(item => item.value));
        }
        set_all_credentials(!var_all_credentials);
    }

    function onChange_individuals(value) {
        set_errors([]);
        set_selected_individuals(value);
        populate_credentials(false, value);
    }

    function onChange_credentials(value) {
        set_errors([]);
        set_selected_credentials(value);
    }

    function onClick_create() {
        let link = { ...var_link };
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(link.description)) {
            errors.push({property: 'description', description: t('Please enter a description.')});
        }
        if (!var_selected_individuals || var_selected_individuals.length === 0) {
            errors.push({property: 'individuals', description: t('Please select at least one individual.')});
        }
        if (link.expiry_timestamp) {
            let date_validation = form_helper.validate_date(link.expiry_timestamp, datelib.date_to_midnight_date(new Date()), null);
            if (date_validation.is_less_than_minimum) {
                errors.push({ property: 'expiry_timestamp', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
            } else if (date_validation.is_greater_than_maximum) {
                errors.push({ property: 'expiry_timestamp', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
            }
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        link.expiry_timestamp = var_link.expiry_timestamp ? datelib.date_to_epoch(var_link.expiry_timestamp.setHours(23, 59, 59)) : null;
        form_helper.cleanse_string_property(link, 'description');
        create_assignment_link(link);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            id='mdl_assignmentlink'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_assignment_sharelink'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_assignment_sharelink'>{t('Assignment link')}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_assignment_sharelink' aria-labelledby='hdr_assignment_sharelink' tabIndex='0'>
                {var_errors.length === 1
                    ? <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_errors[0].description} />
                    : var_errors.length > 1
                    ? <Message error
                        icon={<Icon name='error' className='icon' />}
                        list={var_errors.map(item => item.description)} />
                    : null
                }

                <FORM_INPUT
                    property='description'
                    label={t('Description')}
                    value={var_link.description}
                    onChange={onChange_input}
                    placeholder={t('Description')}
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_SELECT_RADIO
                    name='individuals'
                    property='individuals'
                    label={t('Individuals')}
                    value={var_selected_individuals}
                    options={var_individuals}
                    single_or_multiple='MULTIPLE'
                    onChange={onChange_individuals}
                    placeholder={t('Search')}
                    disabled={var_all_individuals}
                    errors={var_errors}
                    description={t('Select individuals to include.')}
                    id='label_assignment_sharelink'
                    aria_labelledby='label_assignment_sharelink'
                />

                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'></div>
                    <CHECKBOX
                        name='allindividuals'
                        label={t('All individuals')}
                        checked={var_all_individuals}
                        onChange={onChange_all_individuals}
                    />
                </div>

                {(var_selected_individuals.length > 0 && var_credentials.length > 0) &&
                <>
                    <FORM_SELECT_RADIO
                        name='credentials'
                        property='credentials'
                        label={t('Credentials')}
                        value={var_selected_credentials}
                        options={var_credentials}
                        single_or_multiple='MULTIPLE'
                        onChange={onChange_credentials}
                        placeholder={t('Search')}
                        disabled={var_all_credentials}
                        errors={var_errors}
                        description={t('Select credentials to include.')}
                    />

                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'></div>
                        <CHECKBOX
                            name='allcredentials'
                            label={t('All credentials')}
                            checked={var_all_credentials}
                            onChange={onChange_all_credentials}
                        />
                    </div>
                </>
                }

                <FORM_DATEPICKER
                    property='expiry_timestamp'
                    label={t('Expiry (optional)')}
                    value={var_link.expiry_timestamp}
                    onChange={onChange_date}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('YYYY-MM-DD')}
                    minproperty={datelib.date_to_midnight_date(new Date())}
                    maxproperty={null}
                    disabled={false}
                    errors={var_errors}
                />

            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_create}>{t('Create link')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} modal={true} processingtext={t('Processing')} />

        </Modal>
    );

}
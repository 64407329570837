import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { Button, Message } from 'semantic-ui-react';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import datelib from 'libs/date-lib';
import auth from 'libs/auth-lib';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import 'i18n';

import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_COUNT_DETAILS from './mdl_count_details/mdl_count_details';
import MDL_ADD_DOCUMENT from './mdl_add_document/mdl_add_document';
import CMP_DOCUMENT_STATUS from 'components/cmp_document_status/cmp_document_status';



export default function CMP_DOCUMENTS_ADMIN({ activetab, assignment, onChange, onChangeView, var_focuselement, set_focuselement }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_documents, set_documents ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    const [ var_countdetails_open, set_countdetails_open ] = useState(false);
    const [ var_countdetails_document, set_countdetails_document ] = useState(null);
    const [ var_countdetails_id, set_countdetails_id ] = useState(null);
    const [ var_countdetails_status, set_countdetails_status ] = useState(null);
    const [ var_adddocument_open, set_adddocument_open ] = useState(false);
    const [ var_canarchive, set_canarchive ] = useState(false);
    const [ var_archivepopup_id, set_archivepopup_id ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);

    const [ var_docviewer_open, set_docviewer_open ] = useState(false);
    const [ var_docviewer_id, set_docviewer_id ] = useState(null);
    const [ var_delete_document_open, set_delete_document_open ] = useState(false);
    const [ var_selected_document, set_selected_document ] = useState({});



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'DOCUMENTS-ALL' && assignment.location_id) {
            set_message_text(null);
            set_refreshtable(!var_refreshtable);
            populate_records();
            set_selected_document({});
            set_canarchive(auth.has_access(assignment, 'ORG-DOC', 'archive'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, assignment.location_id]);

    useEffect(() => {
        if (!var_adddocument_open && var_focuselement?.id === 'btn_adddocument') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_adddocument_open]);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_documents').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);

    useEffect(() => {
        if (!var_delete_document_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_documents').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_delete_document_open]);

    useEffect(() => {
        if (!var_countdetails_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_documents').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_countdetails_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, focuselement_id) {
        set_message_text(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_loc_document_counts(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_documents(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_documents').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        } else if (var_focuselement?.id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${var_focuselement.id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_documents').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_loc_document_counts(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_loc_document_counts(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function onConfirm_delete() {
        set_message_text(null);
        set_focuselement(document.getElementById('crd_documents'));
        set_delete_document_open(false);
        set_processing(true);
        try {
            await API_delete_location_document();
            populate_records();
            set_message_type('SUCCESS');
            set_message_text(`${var_selected_document.name} ${t('removed from')} ${assignment.location_name}.`);
        } catch (exception) {
            set_message_type('ERROR');
            set_message_text(t('An unexpected error occurred. Please try again.'));
        }
        set_processing(false);
        set_selected_document({});
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_loc_document_counts(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('documents', '/get-loc-document-counts/' + assignment.location_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_delete_location_document() {
        return API.del('documents', `/delete-location-document/${var_selected_document.locationdocument_id}`);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_view(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        onChangeView('INDIVIDUAL');
    }

    function onClick_document(e, locationdocument_id, document_id) {
        set_message_text(null);
        set_focuselement(document.activeElement);
        if (e.target.tagName.toUpperCase() !== 'BUTTON' || e.target.innerText === '0') return;

        if (e.target.closest('td').cellIndex === 1) {
            set_docviewer_id(document_id);
            set_docviewer_open(true);
            return;
        }

        let statuses = ['ACKNOWLEDGED', 'MISSING'];
        let status = statuses[e.target.closest('td').cellIndex - 2];
        set_countdetails_document(e.target.closest('tr').cells[1].innerText);
        set_countdetails_id(locationdocument_id);
        set_countdetails_status(status);
        set_countdetails_open(true);
    }

    function onClick_adddocument() {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_adddocument_open(true);
    }

    async function onChange_adddocument(document_name) {
        await populate_records();
        set_adddocument_open(false);
        set_message_type('SUCCESS');
        set_message_text(`${document_name} ${t('added')}.`);
        onChange && onChange();
    }

    function onClick_archive(id) {
        set_message_text(null);
        if (!var_canarchive) return;
        set_archivepopup_id(id);
    }

    function onDelete_document(item) {
        set_focuselement(document.activeElement);
        set_selected_document(item);
        set_delete_document_open(true);
    }

    function onChange_document_status(focuselement_id) {
        set_archivepopup_id(null);
        populate_records(null, focuselement_id);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm card__documents_admin' id='crd_documents' tabIndex='0'>

            <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_documents' className='text--xl-medium'>{t('Documents')}</div>
                    </div>
                    {auth.has_access(assignment, 'ORG-DOC', 'insert') &&
                        <div className='card__header__right card__header__btns'>
                            <Button id='btn_adddocument' className='primary' onClick={onClick_adddocument}>{t('Add document')}</Button>
                        </div>
                    }
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' alt='' />}
                        header={var_message_text} />
                }

                <Table id='documents' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Documents')} downloadfunction={download_records}
                        onChange={populate_records}>

                    <Table.OptionsButtons>
                        {auth.has_access(assignment, 'INDV-DOC', 'document') &&
                            <TOGGLE value='ALL' options={[{value: 'INDIVIDUAL', text: t('Your documents')}, {value: 'ALL', text: t('All documents')}]} onChange={onClick_view} />
                        }
                        </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('DOCUMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='acknowledged_count' datatype='number' filtertype='number'>{t('ACKNOWLEDGED')}</Table.HeaderCell>
                            <Table.HeaderCell field='missing_count' datatype='number' filtertype='number'>{t('MISSING')}</Table.HeaderCell>
                            <Table.HeaderCell field='added_date' datatype='datetime' filtertype='date'>{t('ADDED')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_documents.map(item =>
                            <Table.Row key={item.locationdocument_id} >
                                <Table.Cell>
                                    {
                                        <CMP_DOCUMENT_STATUS
                                            doc={item}
                                            status_display={item.status_display}
                                            can_archive={var_canarchive}
                                            is_open={item.id === var_archivepopup_id}
                                            onClose={() => set_archivepopup_id(null)}
                                            assignment_id={assignment.id}
                                            onOpen={onClick_archive}
                                            onChange={(focuselement_id) => onChange_document_status(focuselement_id)}
                                            onMessage={(type, text) => { set_message_type(type); set_message_text(text); }}
                                        />
                                    }
                                </Table.Cell>
                                <Table.Cell className='text--anchor'>
                                    <Button className='tertiary' onClick={(e) => onClick_document(e, item.locationdocument_id, item.id)}>{item.name}</Button>
                                </Table.Cell>
                                <Table.Cell className={item.acknowledged_count > 0 && 'text--anchor'} >{item.acknowledged_count === null ? t('NA') :
                                    <Button className='tertiary' onClick={(e) => onClick_document(e, item.locationdocument_id, item.id)} aria-disabled={item.acknowledged_count === 0 && "true"}>
                                        {item.acknowledged_count}
                                    </Button>}
                                </Table.Cell>
                                <Table.Cell className={item.missing_count > 0 && 'text--anchor'}>{item.missing_count === null ? t('NA') :
                                    <Button className='tertiary' onClick={(e) => onClick_document(e, item.locationdocument_id, item.id)} aria-disabled={item.missing_count === 0 && "true"}>
                                        {item.missing_count}
                                    </Button>}
                                </Table.Cell>
                                <Table.Cell className='cell__datetime cell__icon--right'>
                                    {datelib.epoch_to_date_string(item.added_date)}
                                    {auth.orgaccess_organization_ids('ORG-DOC-ADMIN', 'delete from location').includes(assignment.organization_id) &&
                                        <Button className='tertiary' onClick={() => onDelete_document(item)}>
                                            <Icon name='delete' className='color--primary-500' />
                                        </Button>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

                {auth.has_access(assignment, 'ORG-DOC', 'insert') &&
                    <div className='card__footer--mobilebtns'>
                        <Button id='btn_adddocument' className='primary' onClick={onClick_adddocument}>{t('Add document')}</Button>
                    </div>
                }

                <Processing display={var_processing} processingtext={t('Processing')} />

                <MDL_DOCVIEWER
                    targetid={var_docviewer_id}
                    classification='document'
                    is_open={var_docviewer_open}
                    onClose={() => set_docviewer_open(false)}
                />

            </div>

            <CMP_CONFIRMATION
                display={var_delete_document_open} title={t('Remove document')}
                message={t('Are you sure you want to remove {{document_name}} from the assignment?', { document_name: var_selected_document.name })}
                positive_option={t('Remove')} negative_option={t('Cancel')} onConfirm={onConfirm_delete} onCancel={() => set_delete_document_open(false)}
            />

            <MDL_COUNT_DETAILS display={var_countdetails_open} onClose={() => set_countdetails_open(false)}
                document_name={var_countdetails_document} locationdocument_id={var_countdetails_id} status={var_countdetails_status} location_id={assignment.location_id} onChange={populate_records} />
            <MDL_ADD_DOCUMENT display={var_adddocument_open} onClose={() => set_adddocument_open(false)}
                assignment={assignment} onChange={onChange_adddocument} />
        </>
    );
}
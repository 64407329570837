import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';
import auth from 'libs/auth-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import { Button, Message } from 'semantic-ui-react';

import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';
import CMP_ADDCREDENTIAL from 'components/cmp_addcredential/cmp_addcredential';
import CMP_CREDENTIAL_STATUS from 'components/cmp_credential_status/cmp_credential_status';
import CMP_MDL_CERTIFICATE from 'components/cmp_mdl_certificate/cmp_mdl_certificate';


export default function TAB_CREDENTIALS({ individual, location_id, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'credential_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_credentials_status, set_credentials_status ] = useState('ACTIVE');
    const [ var_has_manage_credentials, set_has_manage_credentials ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_docviewer_id, set_docviewer_id ] = useState(null);
    const [ var_docviewer_open, set_docviewer_open ] = useState(false);

    const [ var_assignment, set_assignment ] = useState(null);
    const [ var_can_add_credential, set_can_add_credential ] = useState(false);
    const [ var_addcredential_open, set_addcredential_open ] = useState(false);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);
    const [ var_certificate_open, set_certificate_open ] = useState(false);
    const [ var_selected_credential_id, set_selected_credential_id ] = useState(null);
    const [ var_statuspopup_id, set_statuspopup_id ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'CREDENTIALS' && individual.id) {
            set_message_text(null);
            set_refreshtable(!var_refreshtable);
            populate_credentials({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
            populate_assignment();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_credentials_status, activetab, individual.id]);

    useEffect(() => {
        if (!var_addcredential_open && var_focuselement?.id === 'btn_addcredential') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addcredential_open]);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_workerprofile_credentials').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);

    useEffect(() => {
        if (!var_certificate_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_workerprofile_credentials').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_certificate_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials(populateconfig, focuselement_id) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_credentials(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_credentials(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
            // if admin is not coming from a location, then check if they have access to add credential
            if (!location_id) {
                set_can_add_credential(results.admin_can_add_credential === 'YES');
            }
            set_has_manage_credentials(results.has_manage_credentials === 'YES');

            if (focuselement_id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${focuselement_id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_workerprofile_credentials').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
            } else if (var_focuselement?.id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${var_focuselement.id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_workerprofile_credentials').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
            }

        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_credentials(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_credentials(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function populate_assignment() {
        if (location_id) {
            let assignment = await API_get_assignment();
            set_assignment(assignment);
            set_can_add_credential(auth.has_access(assignment, 'ORG-INDV', 'manage credential'));
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credentials(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('credentials', '/get-worker-credentials/' + individual.id + (location_id ? '/' + location_id : ''),
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone,
                    status: var_credentials_status
                },
                body: filter
            }
        );
    }

    function API_get_assignment() {
        return API.get('indv', '/get-assignments/' + location_id, { queryStringParameters: { tz: datelib.timezone }});
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_addcredential() {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_addcredential_open(true);
    }

    function onChange_addcredential(issuer_name, credential_name) {
        populate_credentials();
        set_addcredential_open(false);
        set_message_type('SUCCESS');
        set_message_text(`${credential_name} ${t('from')} ${issuer_name} ${t('has been added')}.`)
    };

    function onClick_credential(id) {
        set_focuselement(document.activeElement);
        set_selected_credential_id(id);
        set_certificate_open(true);
    }

    function onClick_document(id) {
        set_focuselement(document.activeElement);
        set_docviewer_id(id);
        set_docviewer_open(true);
    }

    function onChange_status(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_credentials_status(status);
    }

    function onClick_credential_status(id) {
        set_message_text(null);
        set_statuspopup_id(id);
    }

    function onClose_credential_status() {
        set_statuspopup_id(null);
        set_message_text(null);
        set_message_type(null);
    }

    function onChange_credential_status(focuselement_id) {
        set_statuspopup_id(null);
        populate_credentials(null, focuselement_id);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_workerprofile_credentials' tabIndex='0'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Credentials')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        {var_can_add_credential &&
                            <Button id='btn_addcredential' className='primary' disabled={individual.invitestatus === 'Archive'} onClick={() => onClick_addcredential()}>{t('Add credential')}</Button>
                        }
                    </div>
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                            icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                            header={var_message_text} />
                }

                <Table id='credentials' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Credentials')} downloadfunction={download_records}
                        onChange={populate_credentials}>

                    <Table.OptionsButtons>
                        {var_has_manage_credentials &&
                            <TOGGLE value={var_credentials_status} onChange={onChange_status} options={[{value: 'ACTIVE', text: t('Active')}, {value: 'ARCHIVE', text: t('Inactive')}]} />
                        }
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='verification_status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>{t('CREDENTIAL')}</Table.HeaderCell>
                            <Table.HeaderCell field='issuer_name' datatype='text' filtertype='text'>{t('ISSUER')}</Table.HeaderCell>
                            <Table.HeaderCell field='issued' datatype='date' filtertype='date'>{t('ISSUED')}</Table.HeaderCell>
                            <Table.HeaderCell field='expires' datatype='date' filtertype='date'>{t('EXPIRES')}</Table.HeaderCell>
                            <Table.HeaderCell field='submittedfilename' datatype='text' filtertype='text'>{t('ATTACHMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='confirmation_status_display' datatype='text' filtertype='option'>{t('CONFIRMATION')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_credentials.map(item =>
                            <Table.Row key={item.id} >
                                <Table.Cell>
                                    <CMP_CREDENTIAL_STATUS
                                        is_active={var_credentials_status === 'ACTIVE'}
                                        can_manage={item.has_manage_access === 'YES'}
                                        can_verify={item.has_verification_access === 'YES'}
                                        can_update={item.has_write_access === 'YES'}
                                        has_prequalifier={item.has_prequalifier === 'YES'}
                                        credential={item}
                                        is_open={item.id === var_statuspopup_id}
                                        onChange={onChange_credential_status}
                                        onMessage={(type, text) => { set_message_type(type); set_message_text(text); }}
                                        onClose={onClose_credential_status}
                                        onOpen={onClick_credential_status}
                                        assignment_id={location_id}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Button className='tertiary' onClick={() => onClick_credential(item.id)}>
                                        <Table.CellContentTwoLine
                                            primary_text={<div className='text--anchor'>{item.credential_name} {item.has_customsecurity === 'YES' && <Icon name='lock' className='icon__lock color--gray-400' alt={t('Credential has security restrictions')} />} </div>}
                                            secondary_text={item.code} />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.issuer_name}</Table.Cell>
                                <Table.Cell className='cell__datetime'>
                                    {datelib.epoch_to_date_string(item.issued)}
                                </Table.Cell>
                                <Table.Cell className='cell__datetime'>
                                    {datelib.epoch_to_date_string(item.expires)}
                                </Table.Cell>
                                <Table.Cell>
                                    {item.submittedfilename &&
                                        <Button className='tertiary' onClick={() => onClick_document(item.id)}><Icon name='attachment' className='icon__attachment'/></Button>
                                    }
                                </Table.Cell>
                                <Table.Cell>{item.confirmation_status_display}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

                {var_can_add_credential &&
                    <div className='card__footer--mobilebtns'>
                        <Button id='btn_addcredential' className='primary' disabled={individual.invitestatus === 'Archive'} onClick={() => onClick_addcredential()}>{t('Add credential')}</Button>
                    </div>
                }

            </div>

            {
                var_can_add_credential &&
                <CMP_ADDCREDENTIAL display={var_addcredential_open} onClose={() => set_addcredential_open(false)} onChange={onChange_addcredential} default_credential_category={'ALL'}
                    assignment={var_assignment} individual={individual} />
            }

            <MDL_DOCVIEWER
                targetid={var_docviewer_id}
                classification='credential'
                is_open={var_docviewer_open}
                onClose={() => set_docviewer_open(false)}
            />

            <CMP_MDL_CERTIFICATE
                credential_id={var_selected_credential_id}
                verifiable_credential={null}
                is_open={var_certificate_open}
                onClose={() => set_certificate_open(false)}
            />

        </>
    );
}
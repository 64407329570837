import React, { useEffect, useState}  from 'react';
import API from 'libs/api-lib';
import { Button } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { useTranslation } from 'react-i18next';
import 'i18n';

import CMP_OID4VC_CREDENTIAL_OFFER from 'components/cmp_oid4vc_credential_offer/cmp_oid4vc_credential_offer';

import './crd_oid4vc_issuance.css';


export default function CRD_OID4VC_ISSUANCE({ credential }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_offer, set_offer ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_addtowallet_open, set_addtowallet_open ] = useState(false);
    const [ var_addtowallet_error, set_addtowallet_error ] = useState(null);
    const [ var_addtowallet_processing, set_addtowallet_processing ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        // Ping status API for state of offer
        if (var_addtowallet_open && var_offer?.url && var_offer?.status !== 'ERROR') {
            let interval = setInterval(() => {
                get_offer_status();
            }, 3000); // 3 seconds
            return () => clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ var_offer, var_addtowallet_open ]);

    useEffect(() => {
        if (!var_addtowallet_open && var_focuselement?.id === 'btn_showqr') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    }, [var_addtowallet_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function create_offer() {
        try {
            let offer = await API_post_credential_oid4vc_offer();
            set_offer(offer);
            return offer;
        } catch (e) {
            console.error('Failed to generate QR');
            console.error(e);
        }
    }

    async function get_offer_status() {
        try {
            let status = await API_get_oid4vc_credential_offer_status();
            set_offer({ ...var_offer, status });
            if (status === 'ERROR') {
                set_addtowallet_error(t('An error occurred while issuing this credential to your Midy wallet. Please try again.'));
                set_addtowallet_processing(false);
            } else if (status === 'CREDENTIAL ISSUED') {
                onClose_hide_QR();
            } else if (status !== 'OFFER CREATED') {
                set_addtowallet_processing(true);
            }
        } catch (e) {
            console.error('Failed to retrieve credential issuance status');
            console.error(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_credential_oid4vc_offer() {
        return API.post('credentials', '/post-credential-oid4vc-offer/', {
            body: { individualcredential_id: credential.id }
        });
    }

    function API_get_oid4vc_credential_offer_status() {
        return API.get('credentials', `/get-oid4vc-credential-offer-status/${var_offer.id}`);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_show_QR() {
        set_processing(true);
        set_focuselement(document.activeElement);
        let offer = await create_offer();
        if (!offer?.url) {
            set_addtowallet_error(t('We are unable to generate the QR code at this time due to an unexpected error. Please close this window and try again.'));
        }
        set_addtowallet_open(true);
        set_processing(false);
    }

    function onClose_hide_QR() {
        set_addtowallet_open(false);
        set_addtowallet_processing(false);
        set_offer(null);
        set_addtowallet_error(null);
    }


    // RENDER APP ======================================================================================================

    if (!credential) return null;

    return (
        <div className='card rounded-lg shadow-sm'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>{t('Midy Wallet')}</div>
                    <div className='text--sm-regular'>{t('Share this credential to Midy.')}</div>
                </div>
            </div>

            <div className='card__content'>
                <Button className='primary' id='btn_showqr' onClick={onClick_show_QR}>{t('Share to Midy')}</Button>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

            <CMP_OID4VC_CREDENTIAL_OFFER
                display={var_addtowallet_open}
                title={t('Share to Midy Wallet')}
                instructions_line1={var_offer?.url ? t('Scan the QR code below with your Midy Wallet.') : null}
                error_message={var_addtowallet_error}
                target={var_offer?.url}
                processing={var_addtowallet_processing}
                onClose={onClose_hide_QR}
            />

        </div>
    )
}

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';
import 'i18n';

import CMP_DOCUMENTACKNOWLEDGE from 'components/cmp_documentacknowledge/cmp_documentacknowledge';
import MDL_DOCUMENT_ASSIGNMENTS from './mdl_document_assignments/mdl_document_assignments';



export default function CRD_DOCUMENTS(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_documents, set_documents ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'status_display', sortorder: 'descending' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_docviewer_open, set_docviewer_open ] = useState(false);
    const [ var_selected_document, set_selected_document ] = useState({});
    const [ var_docviewer_showacknowledge, set_docviewer_showacknowledge ] = useState(false);
    const [ var_assignment_mdl_open, set_assignment_mdl_open ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect( () => {
        populate_documents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_documents').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_documents(populateconfig) {

        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_all_documents(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_documents(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_all_documents(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_documents(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_all_documents(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function onAcknowledge_document() {
        try {
            await populate_documents();
            set_docviewer_open(false);
            set_selected_document({});
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_all_documents(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('documents', '/get-all-documents',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_document(document) {
        set_selected_document(document);
        set_docviewer_showacknowledge(document.status === 'Missing acknowledgement');
        set_docviewer_open(true);
    }

    function onClick_assignment_name(item) {
        if (!!item.assignment_id) {
            navigate(`/assignments/${item.assignment_id}`);
        }
        else {
            set_selected_document(item);
            set_assignment_mdl_open(true);
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_documents' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_documents' className='text--xl-medium'>{t('All documents')}</div>
                    </div>
                </div>

                <Table id='documents' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Documents')} downloadfunction={download_documents}
                        onChange={populate_documents}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className='cell__icon' field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='name' datatype='text' filtertype='text'>{t('DOCUMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization_name' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                            <Table.HeaderCell field='assignment_name' datatype='text' filtertype='text'>{t('ASSIGNMENT')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_documents.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='cell__icon cell--center'>
                                {
                                        item.status === 'Missing acknowledgement'
                                        ? <Icon name='error' className='color--red-600' alt={t('Missing acknowledgement')} />
                                        : item.status === 'Acknowledged'
                                        ? <Icon name='checkmark' className='color--green-500' alt={t('Acknowledged')} />
                                        : <></>
                                    }
                                </Table.Cell>
                                <Table.Cell >
                                    <Button className='tertiary' onClick={() => { set_focuselement(document.activeElement); onClick_document(item)}}>
                                        <Table.CellContentTwoLine
                                            primary_text={<div className='text--anchor'>{item.name}</div>}
                                            secondary_text={(item.ack_req === 'YES' && !item.acknowledged_date)
                                                    ? t('Missing acknowledgement')
                                                    : item.ack_req === 'YES'
                                                        ? `${t('Acknowledged')} ${datelib.epoch_to_date_string(item.acknowledged_date, true)}`
                                                        : `${t('Added')} ${datelib.epoch_to_date_string(item.added_date, true)}`} />
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>{item.organization_name}</Table.Cell>
                                <Table.Cell className='text--anchor' >
                                    <Button className='tertiary' onClick={() => { set_focuselement(document.activeElement); onClick_assignment_name(item); }}>{item.assignment_name}</Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <CMP_DOCUMENTACKNOWLEDGE
                    is_open={var_docviewer_open}
                    onAcknowledgement={onAcknowledge_document}
                    onClose={() => set_docviewer_open(false)}
                    document_id={var_selected_document.id}
                    show_acknowledge={var_docviewer_showacknowledge}
                />

                <MDL_DOCUMENT_ASSIGNMENTS
                    display={var_assignment_mdl_open}
                    onClose={() => set_assignment_mdl_open(false)}
                    document_id={var_selected_document.id}
                    document_name={var_selected_document.name}
                />
            </div>
        </>
    )

}

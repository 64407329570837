import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import datelib from 'libs/date-lib';
import 'i18n';

import Logo from 'components/cmp_logo';


import './cmp_credential_card.css';

function CMP_CREDENTIAL_CARD({ type = 'Credivera', credential }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        (credential && credential.id && type === 'Credivera') ?
        <div id='credentialcard' style={{ backgroundColor: credential.background_color, color: credential.text_color }}>
            <div className='credentialcard__top__wrapper'>
                <div className='credentialcard__logo'>
                    <Logo targetid={credential.issuer_id} classification='logo' filename={credential.logo} backup_text={credential.issuer_name} alt={t('Issued by') + ' ' + credential.issuer_name} />
                </div>
                <div className={'credentialcard__status text--sm-medium ' +
                    (credential.verification_status === 'INVALID' ? 'credentialcard__status--red'
                        : credential.verification_status === 'PENDING' ? 'credentialcard__status--yellow'
                        : credential.verification_status === 'IN PROGRESS' ? 'credentialcard__status--yellow'
                        : credential.confirmation_status === 'SELF VERIFIED' ? 'credentialcard__status--blue'
                        : 'credentialcard__status--green')}>
                    {credential.confirmation_status && t(credential.confirmation_status.substring(0, 1).toUpperCase() + credential.confirmation_status.substring(1).toLowerCase())}
                </div>
            </div>
            <div className='credentialcard__name text--xl-bold'>{credential.credential_name}</div>
            <div className='credentialcard__dates text--sm-bold'>
                {credential.issued && credential.prequalifier_status !== 'IN PROGRESS' && <div>{t('Issued')}: {
                    Number.isNaN(Number(credential.issued)) ? credential.issued : datelib.epoch_to_date_string(credential.issued)}</div>}
                {credential.expires && credential.prequalifier_status !== 'IN PROGRESS' && <div>{t('Expires')}: {
                    Number.isNaN(Number(credential.expires)) ? credential.expires : datelib.epoch_to_date_string(credential.expires)}</div>}
            </div>
        </div>
        : (credential && credential.id) ? // Entra
        <div id='credentialcard' className='credentialcard__entra' style={{ backgroundColor: credential.background_color, color: credential.text_color }}>
            <div className='credentialcard__top__wrapper'>
                <div className='credentialcard__entra__logo'>
                    {credential.logo ? <Logo targetid={credential.issuer_id} classification='logo' filename={credential.logo} backup_text={credential.issuer_name} alt={t('Issued by') + ' ' + credential.issuer_name} />
                        : <img src='https://my.credivera.com/assets/Credivera%20White%20Icon.png' alt={t('Issued by') + ' ' + credential.issuer_name} />
                    }
                </div>
                <div className='credentialcard__entra_credential_name text--sm-regular'>{credential.credential_name}</div>
            </div>
            <div className='credentialcard__entra_issuer text--sm-regular'>{credential.issuer_name}</div>
        </div>
        : null
    );
}

CMP_CREDENTIAL_CARD.propTypes = {
    type: propTypes.oneOf([ 'Credivera', 'Entra' ]),
    credential: propTypes.object
};

export default CMP_CREDENTIAL_CARD;

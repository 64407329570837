import React from 'react';
import config from 'config';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import 'i18n';



export default function CONTENT_ONBOARD_2({ onClose, onChangeContent }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');



    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_skip() {
        sessionStorage.setItem('onboarding', false);
        onClose();
    }

    function onClick_next() {
        onChangeContent('ONBOARD_3');
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <img src={`${config.images.url}${config.images.assets.onboarding_2}${config.images.stage}`} alt={t('onboarding header image')} />

            <div className='progress__indicator__wrapper'>
                <div className='progress__indicator'></div>
                <div className='progress__indicator active'></div>
                <div className='progress__indicator'></div>
            </div>

            <div className='modal__content center padding--lg' tabIndex='0'>
                <div className='display--xs-bold'>{t('Start by adding your credentials')}</div>
                <div className='text--sm-regular'>{t('You can easily add credentials from a network of over 10,000+ issuers within Credivera. Can’t find the issuer or credential you’re looking for? No problem, you can manually upload your credential.')}</div>

                <div className='modal__footer'>
                    <Button className='primary' onClick={onClick_next}>{t('Next')}</Button>
                    <Button className='secondary' onClick={onClick_skip}>{t('Skip')}</Button>
                </div>
            </div>
        </>
    );

}

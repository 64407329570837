import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Button, Message, Modal, Form } from 'semantic-ui-react';
import API  from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import { SELECT_RADIO, CHECKBOX, FORM_INPUT, FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';

import './cmp_requirement.css';



function CMP_REQUIREMENT({ individualrequirement_id, requirement_id, requirement_name, requirement_status, credential_name, individualcredential_id, assignedfrom_type, assignedfrom_id, assignedfrom_name, is_open = false, onClose, onChange, individual_id = null }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_credential_options, set_credential_options ] = useState([]);
    const [ var_selected_credential, set_selected_credential ] = useState(null);
    const [ var_missing, set_missing ] = useState('NO');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(true);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (is_open && requirement_id) {
            // reset variables and re-populate
            set_focuselement(document.activeElement);
            set_modal(document.querySelector('#mdl_submitcred'));
            set_credentials([]);
            set_credential_options([]);
            set_selected_credential(null);
            set_missing('NO');
            set_errors([]);
            set_processing(true);
            populate_credentials();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials() {
        try {
            let results = await API_get_credential_list();
            set_credentials(results.results);
            let transformed = results.results.map(item => ({
                value: item.id,
                primary_text:
                    <div>
                        {item.credential}
                        {item.has_customsecurity === 'YES' && <Icon name='lock' className='icon__lock color--gray-400' alt={t('lock icon')} />}
                    </div>,
                secondary_text: item.id === individualcredential_id
                    ? `${t('Currently submitted')}`
                    : item.disabled_by_security === 'YES'
                    ? t('Secured credential')
                    : item.history_status === 'INVALID'
                    ? `${t('Not accepted by')} ${results.organization_name}`
                    : (item.history_status === 'VALID' && item.verification_status === 'VALID')
                    ? t('Approved credential')
                    : t(item.confirmation_status),
                secondary_text_css: (item.history_status === 'VALID' && item.verification_status === 'VALID') ? 'text--xs-regular case--sentence color--green-500' : null,
                disabled: item.history_status === 'INVALID' || item.verification_status !== 'VALID' || item.id === individualcredential_id || item.disabled_by_security === 'YES'
            }));
            set_credential_options(transformed);
            set_processing(false);
            //If first row is an auto-accepted credential, then preselect
            set_selected_credential((results.results[0] && results.results[0].history_status === 'VALID' && results.results[0].verification_status === 'VALID') ? results.results[0].id : null);
        } catch (e) {
            console.log(e);
        }
    }

    async function update_requirement() {
        set_processing(true);
        try {
            let result = await API_put_requirement();
            return { success: true, result };
        } catch (e) {
            console.log(e);
            set_errors([{description: t('There was a problem saving.  Please try again later')}]);
            return { success: false };
        } finally {
            set_processing(false);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_list() {
        return API.get('requirements', '/get-credential-list/' + requirement_id + (individual_id ? ('/' + individual_id) : ''), { queryStringParameters: { tz: datelib.timezone }});
    }

    function API_put_requirement() {
        return API.put('requirements', '/put-requirement/' + individualrequirement_id + (individual_id ? ('/' + individual_id) : ''), { body: {
            missing: var_missing,
            individualcredential_id: var_missing === 'NO' ? var_selected_credential : null,
            organization_id: assignedfrom_type === 'ORGANIZATION' ? assignedfrom_id : null,
            location_id: assignedfrom_type === 'LOCATION' ? assignedfrom_id : null
        }});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_credential(value) {
        set_selected_credential(value);
    }

    function onChange_missing() {
        let missing = var_missing === 'NO';
        set_missing(missing ? 'YES' : 'NO');
        if (missing) {
            set_selected_credential(null);
        } else if (var_credentials[0] && var_credentials[0].verification_status === 'VALID' && var_credentials[0].history_status === 'VALID') {
            set_selected_credential(var_credentials[0].id);
        }
    }

    async function onClick_submit() {
        if ((var_processing || var_selected_credential === individualcredential_id) && var_missing === 'NO') return;

        // validation
        let errors = [];
        if (var_selected_credential === null && var_missing === 'NO') {
            errors.push({description: t('Please select a credential or indicate if you don\'t have a matching credential')});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        let results = await update_requirement();
        results.success && onChange && onChange(results.result, var_missing, var_focuselement?.id);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            className='cmp_requirement'
            id='mdl_submitcred'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_submitcred'
        >
            <div className='modal__header'>
                <div style={ { width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium' id='hdr_submitcred'>{requirement_status === 'Valid' ? t('Change credential') : t('Submit credential')}</div>
                    </div>
                </div>
            </div>

            <Form className='modal__content center padding--lg' tabIndex='0'>
                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' alt={t('alert icon')} />}
                        header={var_errors[0].description} />
                }

                {requirement_status === 'Valid' &&
                    <div className='text--sm-regular'>{t('Submit a new credential for')}
                        <span className='text--sm-bold'>&nbsp;{requirement_name}.</span>
                    </div>
                }

                {requirement_status !== 'Valid' &&
                    <div className='cmp_requirement__top_message text--sm-regular' id='label_assignment_submitcred'>{t('Select a credential that matches, or is the equivalent of, the')}
                            <span className='text--sm-bold'>&nbsp;{requirement_name}</span>
                            <span className='text--sm-regular'>&nbsp;{t('requirement for')}</span>
                            <span className='text--sm-bold'>&nbsp;{assignedfrom_name}.</span>
                    </div>
                }

                {requirement_status === 'Valid' &&
                    <FORM_INPUT
                        property='credential'
                        label={t('Current credential')}
                        value={credential_name}
                        onChange={null}
                        placeholder={t('Current credential')}
                        disabled={true}
                        errors={var_errors}
                    />
                }

                {requirement_status === 'Valid'
                ?
                <div>
                    <FORM_SELECT_RADIO
                        name='credentials'
                        property='credentials'
                        label={t('New credential')}
                        value={var_selected_credential}
                        options={var_credential_options}
                        single_or_multiple='SINGLE'
                        onChange={onChange_credential}
                        aria_labelledby='label_assignment_submitcred'
                        placeholder={t('Search')}
                        disabled={false}
                        errors={var_errors}
                    />
                </div>
                :
                <div>
                    <SELECT_RADIO name='credentials'
                        value={var_selected_credential}
                        options={var_credential_options}
                        single_or_multiple='SINGLE'
                        onChange={onChange_credential}
                        disabled={var_missing === 'YES'}
                        aria_labelledby='label_assignment_submitcred'/>
                </div>
                }

                {requirement_status !== 'Valid' &&
                <>
                    <CHECKBOX name='missing' label={t('I don\'t have a matching credential')} checked={var_missing === 'YES'} onChange={onChange_missing} />
                    {var_missing === 'YES' &&
                        <div className='text--sm-medium'>{t('The requirement status will be set to \'MISSING\'.  You can submit a credential to meet this requirement at any time.')}</div>
                    }
                </>
                }

            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_submit}>{t('Submit')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

CMP_REQUIREMENT.propTypes = {
    individualrequirement_id: propTypes.string,
    requirement_id: propTypes.string,
    requirement_name: propTypes.string,
    requirement_status: propTypes.string,
    credential_name: propTypes.string,
    individualcredential_id: propTypes.string,
    assignedfrom_type: propTypes.oneOf(['LOCATION', 'ORGANIZATION']),
    assignedfrom_id: propTypes.string,
    assignedfrom_name: propTypes.string,
    is_open: propTypes.bool,
    onClose: propTypes.func.isRequired,
    onChange: propTypes.func
};

export default CMP_REQUIREMENT;
import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import SELECT_RADIO from './select_radio';

function FORM_SELECT_RADIO({ property, label, value, onChange, placeholder, options, disabled = false, errors, description, name, single_or_multiple = 'SINGLE', id, aria_label, aria_labelledby }) {


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER ==========================================================================================================
    return (
        <div className='detailsgroup'>
            <div className='detailsgroup__label text--sm-medium'>{label}</div>
            <Form.Field className='detailsgroup__value'>
                {description && <div className='detailsgroup__description text--sm-regular' id={id ? id : undefined}>{description}</div>}
                <SELECT_RADIO
                    name={name}
                    property={property}
                    label={label}
                    value={value}
                    options={options}
                    single_or_multiple={single_or_multiple}
                    onChange={onChange}
                    placeholder={placeholder}
                    disabled={disabled}
                    errors={errors}
                    aria_label={aria_label}
                    aria_labelledby={aria_labelledby}
                />
            </Form.Field>
        </div>
    );
}

FORM_SELECT_RADIO.propTypes = {
    property: propTypes.string,
    label: propTypes.string,
    placeholder: propTypes.string,
    name: propTypes.string.isRequired,
    value: propTypes.oneOfType([propTypes.string, propTypes.array]),
    options: propTypes.arrayOf(propTypes.shape({
        value: propTypes.string.isRequired,
        primary_text: propTypes.oneOfType([ propTypes.string, propTypes.element ]).isRequired,
        secondary_text: propTypes.string,
        secondary_text_css: propTypes.string,
        secondary_text_link: propTypes.string,
        disabled: propTypes.bool,
        additional_search_values: propTypes.arrayOf(propTypes.string)
    })),
    single_or_multiple: propTypes.oneOf(['SINGLE', 'MULTIPLE']),
    disabled: propTypes.bool,
    onChange: propTypes.func
};

export default FORM_SELECT_RADIO;
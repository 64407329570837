import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import CRD_CREDENTIALS from './crd_credentials/crd_credentials';



function TAB_CREDENTIALS({ activetab, verification_template, verification_template_credentials, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'CREDENTIALS') {
            window.scrollTo(0, 0);
        }
    }, [ activetab ]);


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            <CRD_CREDENTIALS activetab={activetab} verification_template={verification_template} verification_template_credentials={verification_template_credentials} onChange={onChange} />
        </>
    )
}

TAB_CREDENTIALS.propTypes = {
    activetab: propTypes.string.isRequired,
    verification_template: propTypes.object.isRequired,
    onChange: propTypes.func
};

export default TAB_CREDENTIALS;

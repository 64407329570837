import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import 'i18n';


export default function CRD_NOTIFICATIONS({ notifications, totalrows }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_notifications, set_notifications ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'notification' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_records();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications])


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records() {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        try {
            set_totalrows(totalrows);
            set_notifications(notifications);
            set_loading(false);
            set_ready(true);
        }  catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_notification(item) {
        navigate('/credentials/ALL/' + item.id);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_vcnotifications' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Notifications')}</div>
                    </div>
                </div>

                <Table  id='notifications' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={() => null}
                        downloadname={t('Notifications')} downloadfunction={() => null}
                        onChange={populate_records} hide_tablecontrols={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className='cell__icon' field='status' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='notification' datatype='text' filtertype='text'>{t('NOTIFICATION')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_notifications.map(item =>
                            <Table.Row key={item.id} onClick={() => onClick_notification(item)}>
                                <Table.Cell className='cell__icon cell--center'>
                                    <Icon name='warning_circle' className='color--yellow-500' alt='' />
                                </Table.Cell>
                                <Table.Cell className={'cell__icon--right'}>
                                    <Table.CellContentTwoLine
                                        secondary_text={item.notification}
                                        display_chevron={true} />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

            </div>
        </>
    );
}
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TABS from 'components/cmp_tabs/cmp_tabs';
import TAB_DETAILS from './tab_details/tab_details';
import TAB_CREDENTIALS from './tab_credentials/tab_credentials';



export default function VERIFICATION() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();
    const params = useParams();

    const [ var_tab, set_tab ] = useState('DETAILS');
    const [ var_verification_template, set_verification_template ] = useState({});
    const [ var_verification_template_credentials, set_verification_template_credentials ] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (params.tab) {
            set_tab(params.tab.toUpperCase());
        } else {
            set_tab('DETAILS');
        }
        populate_verification_template();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ params.verification_template_id ]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_verification_template() {
        let result = await API_get_verification_template();
        set_verification_template(result.verification_template);
        set_verification_template_credentials(result.verification_template_credentials);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_verification_template() {
        return API.get('verifiable-credentials', '/get-verification-template/' + params.verification_template_id);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        // if user just created the template then return to the list page, otherwise just go back 1
        if (params.new === 'YES') {
            navigate('/org/verifications');
        } else {
            navigate(-1);
        }
    }

    function onClick_tab(tab) {
        set_tab(tab);
    }

    async function onChange_details() {
        await populate_verification_template();
    }

    // RENDER APP ======================================================================================================

    return (
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }, { path: '/org/verifications', text: t('Verification templates') }]}
                current_page_text={var_verification_template.name || ''}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{var_verification_template.name}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>

                <TABS activetab={var_tab} onClick={onClick_tab} aria_label={t('Verification Template')} id='tabs_verification_template'>
                    <TABS.TAB name='DETAILS' label={t('Details')} />
                    <TABS.TAB name='CREDENTIALS' label={t('Credentials')} />
                </TABS>

                {var_tab === 'DETAILS' &&
                    <TAB_DETAILS activetab={var_tab} verification_template={var_verification_template} verification_template_credentials={var_verification_template_credentials} onChange={onChange_details} />
                }
                {var_tab === 'CREDENTIALS' &&
                    <TAB_CREDENTIALS activetab={var_tab} verification_template={var_verification_template} verification_template_credentials={var_verification_template_credentials} onChange={onChange_details} />
                }
            </div>
        </div>
    )
};
import React from 'react';
import CRD_DETAILS from './crd_details/crd_details';
import CRD_LOGOS from './crd_logos/crd_logos';


export default function TAB_DETAILS({ activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <>
            <CRD_DETAILS activetab={activetab} />
            <CRD_LOGOS activetab={activetab} />
        </>
    );
}
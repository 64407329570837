import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import Icon from 'components/cmp_icon';
import { CHECKBOX } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';



function MDL_EDIT_PERMISSION({ is_open, onClose, credential_id, selected_permissions, role_individual, permission, onChange, permission_options, custom_security_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_permissions, set_permissions ] = useState({});

    const [ var_processing, set_processing ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open && credential_id && selected_permissions) {
            // reset and populate options
            set_focuselement(document.activeElement);
            set_modal(document.querySelector('#mdl_edit_permission'));
            set_processing(false);
            populate_permission();
            set_save_error(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open, credential_id]);

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    //  async functions ------------------------------------------------------------------------------------------------

    function populate_permission() {
        let permissions = {};
        permission_options.forEach(item => permissions[item.code] = JSON.parse(selected_permissions).includes(item.code));
        set_permissions(permissions);
    }

    async function update_access() {
        set_processing(true);
        try {
            let result = await API_put_custom_security();
            if (!result.success) {
                set_save_error(t(result.reason));
                set_processing(false);
                return;
            }
        } catch(e) {
            console.log(e);
        }
        set_processing(false);
        onChange && onChange(var_focuselement?.id);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_custom_security() {
        return API.put('security', '/put-custom-security',{
            body: {
                record: {
                    access: JSON.stringify(permission_options.filter(item => var_permissions[item.code]).map(item => item.code)),
                    id: custom_security_id,
                }
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_permissions(permission_item) {
        set_save_error(null);
        let permissions = {...var_permissions};
        permissions[permission_item] = !permissions[permission_item];
        set_permissions(permissions);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_edit_permission'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_creddetails_editpermissions'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_creddetails_editpermissions'>{t('Edit permissions')}</div>
                    <div className='text--sm-regular'>{role_individual}</div>
                </div>
            </div>

            <Form className='modal__content' tabIndex='0' id='form_creddetails_editpermissions' aria-labelledby='hdr_creddetails_editpermissions'>
                {var_save_error &&
                    <Message error header={var_save_error}/>
                }
                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>{t('Permissions')}</div>
                    <div className='detailsgroup__value column text--sm-regular'>
                    {permission_options.map(item =>
                        <CHECKBOX name='updatepermission' key={'checkbox-' + item.code} label={item.description} value={item.code} checked={var_permissions[item.code]} onChange={() => onChange_permissions(item.code)}
                        disabled={item.code === 'read' ||
                            (permission === 'Manager' && item.code !== 'view attachment') ||
                            ((permission === 'Admin' || permission === 'Global Admin') && item.code === 'security')} />
                    )}
                    </div>
                </div>
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={update_access}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

export default MDL_EDIT_PERMISSION;
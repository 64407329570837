import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import { Form, Modal, Button } from 'semantic-ui-react';
import 'i18n';
import './mdl_add_requirement.css';

import { SELECT_RADIO } from 'components/cmp_form/cmp_form';


export default function MDL_ADD_REQUIREMENT({ display, onClose, requirement_package, onChange_package }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_selected_requirements, set_selected_requirements ] = useState([]);
    const [ var_requirement_options, set_requirement_options ] = useState([]);

    const [ var_errors, set_errors ] = useState([]);
    const [ var_modal, set_modal ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="search"]:not([disabled]), input[type="checkbox"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_add_requirement'));
            set_selected_requirements([]);
            populate_requirements();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_requirements() {
        try {
            let results = await API_get_requirements_to_add_to_package();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text: item.name,
                secondary_text: is_assigned(item.id) ? t('Already added to package') : null,
                disabled: is_assigned(item.id)
            }));
            set_requirement_options(transformed);
        } catch(e) {
            console.log(e);
        }
    }

    function is_assigned(selected) {
        return requirement_package.requirements && requirement_package.requirements.some(item => item.id === selected);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirements_to_add_to_package() {
        return API.get('requirements', '/get-requirements-to-add-to-package');
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_requirements(value) {
        set_selected_requirements(value);
    }

    function add_requirements() {
        let errors = [];
        // validation
        if (Object.keys(var_selected_requirements).length === 0) {
            errors.push({property: 'requirements', description: t('Please select at least one requirement')});
        }

        set_errors(errors);
        if (errors.length > 0) return;
        onChange_package({...requirement_package, requirements: [...requirement_package.requirements, ...(var_requirement_options.filter(item =>
            var_selected_requirements.includes(item.value)).map(requirement => ({ id: requirement.value, name: requirement.primary_text})))]});
        onClose();
    }



    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_add_requirement'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_cr8package_requirements'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_cr8package_requirements'>{t('Select requirements')}</div>
                </div>
            </div>

            <Form className='modal__content' tabIndex='0' id='form_cr8package_requirements' aria-labelledby='hdr_cr8package_requirements'>
                <SELECT_RADIO
                    name='requirements'
                    property='requirements'
                    value={var_selected_requirements}
                    options={var_requirement_options}
                    single_or_multiple='MULTIPLE'
                    onChange={onChange_requirements}
                    placeholder={t('Search')}
                    disabled={false}
                    errors={var_errors}
                    aria_label={t('Select the requirements to include in this package')}
                />
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={add_requirements}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

        </Modal>
    );

}
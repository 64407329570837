import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';
import auth from 'libs/auth-lib';

import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import { Button, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';

import MDL_COUNT_DETAILS from './mdl_count_details/mdl_count_details';
import MDL_ADD_REQUIREMENT from './mdl_add_requirement/mdl_add_requirement';
import MDL_ASSIGN_REQUIREMENT from './mdl_assign_requirement/mdl_assign_requirement';



export default function TAB_REQUIREMENTS_ADMIN({ assignment, onChange, activetab, onChangeView, var_focuselement, set_focuselement  }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_requirements, set_requirements ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'requirement_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    const [ var_canarchive, set_canarchive ] = useState(false);
    const [ var_countdetails_open, set_countdetails_open ] = useState(false);
    const [ var_countdetails_requirement, set_countdetails_requirement ] = useState(null);
    const [ var_countdetails_id, set_countdetails_id ] = useState(null);
    const [ var_countdetails_status, set_countdetails_status ] = useState(null);
    const [ var_addrequirement_open, set_addrequirement_open ] = useState(false);
    const [ var_assignrequirement_open, set_assignrequirement_open ] = useState(false);
    const [ var_deletelocationrequirement_open, set_deletelocationrequirement_open ] = useState(false);
    const [ var_deletelocationrequirement_id, set_deletelocationrequirement_id ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'REQUIREMENTS' && assignment.location_id) {
            set_message_text(null);
            set_refreshtable(!var_refreshtable);
            populate_records();
            set_canarchive(auth.has_access(assignment, 'ORG-REQ', 'archive'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, assignment.location_id]);

    useEffect(() => {
        if (!var_addrequirement_open && var_focuselement?.id === 'btn_addrequirement') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addrequirement_open]);

    useEffect(() => {
        if (!var_assignrequirement_open && var_focuselement?.id === 'btn_assignrequirement') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_assignrequirement_open]);

    useEffect(() => {
        if (!var_countdetails_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_requirements').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_countdetails_open]);

    useEffect(() => {
        if (!var_deletelocationrequirement_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_requirements').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_deletelocationrequirement_open]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig) {
        set_message_text(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_requirements(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_requirements(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
            if (var_focuselement?.id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${var_focuselement.id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_requirements').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
            }
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_requirements(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_requirements(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_requirements(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('requirements', '/get-loc-requirement-counts/' + assignment.location_id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }

    function API_delete_loc_requirement() {
        return API.del('requirements', '/delete-loc-requirement/' + var_deletelocationrequirement_id);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_view(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        onChangeView('INDIVIDUAL');
    }

    function onClick_requirement(e, locationrequirement_id) {
        set_focuselement(document.activeElement);
        if (e.target.tagName.toUpperCase() !== 'BUTTON' || e.target.innerText === '0') return;

        let statuses = ['INDIVIDUALS', 'VALID', 'INVALID', 'EXPIRING', 'PENDING', 'MISSING', 'REQUESTED'];
        let status = statuses[e.target.closest('td').cellIndex - 1];
        set_countdetails_requirement(e.target.closest('tr').cells[0].innerText);
        set_countdetails_id(locationrequirement_id);
        set_countdetails_status(status);
        set_countdetails_open(true);
    }

    function onClick_addrequirement() {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_addrequirement_open(true);
    }

    function onClick_assignrequirement() {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_assignrequirement_open(true);
    }

    function onDelete_requirement(id) {
        set_message_text(null);
        set_focuselement(document.activeElement);
        set_deletelocationrequirement_id(id);
        set_deletelocationrequirement_open(true);
    }

    async function onConfirm_delete() {
        set_processing(true);
        await API_delete_loc_requirement();
        populate_records();
        set_processing(false);
        set_deletelocationrequirement_open(false);
        onChange && onChange();
    }

    async function onChange_addrequirement(count_added, requirement_names) {
        await populate_records();
        set_addrequirement_open(false);
        set_message_type('SUCCESS');
        set_message_text(`${requirement_names} ${t(count_added === 1 ? 'has been added' : 'have been added')}.`);
        onChange && onChange();
    }

    async function onChange_assignrequirement(count_added, requirement_name) {
        await populate_records();
        set_assignrequirement_open(false);
        set_message_type('SUCCESS');
        set_message_text(`${t(count_added === 1 ? 'Individual has been added to ' : 'Individuals have been added to')} ${requirement_name}.`);
        onChange && onChange();
    }

    async function onChange_countdetails() {
        populate_records();
        onChange && onChange();
    }



    // RENDER APP ======================================================================================================

    return (
        <>

            <div className='card rounded-lg shadow-sm' id='crd_requirements' tabIndex='0'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_requirements' className='text--xl-medium'>{t('Requirements')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        {auth.has_access(assignment, 'ORG-REQ', 'insert') &&
                            <Button id='btn_addrequirement' className='primary' onClick={onClick_addrequirement}>{t('Add requirement')}</Button>
                        }
                        {auth.has_access(assignment, 'ORG-REQ', 'assign') &&
                            <Button id='btn_assignrequirement' className='secondary' onClick={onClick_assignrequirement}>{t('Assign requirement')}</Button>
                        }
                    </div>
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' alt='' />}
                        header={var_message_text} />
                }

                <Table id='requirements' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Requirements')} downloadfunction={download_records}
                        onChange={populate_records}>

                    <Table.OptionsButtons>
                        {auth.has_access(assignment, 'INDV-REQ', 'requirement') &&
                            <TOGGLE value='ALL' options={[{value: 'INDIVIDUAL', text: t('Your requirements')}, {value: 'ALL', text: t('All requirements')}]} onChange={onClick_view} />
                        }
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='requirement_name' datatype='text' filtertype='text'>{t('REQUIREMENT')}</Table.HeaderCell>
                            <Table.HeaderCell field='individual_count' datatype='number' filtertype='number'>{t('INDIVIDUALS')}</Table.HeaderCell>
                            <Table.HeaderCell field='valid_count' datatype='number' filtertype='number'>{t('VALID')}</Table.HeaderCell>
                            <Table.HeaderCell field='invalid_count' datatype='number' filtertype='number'>{t('INVALID')}</Table.HeaderCell>
                            <Table.HeaderCell field='comingdue_count' datatype='number' filtertype='number'>{t('EXPIRING')}</Table.HeaderCell>
                            <Table.HeaderCell field='pending_count' datatype='number' filtertype='number'>{t('PENDING')}</Table.HeaderCell>
                            <Table.HeaderCell field='missing_count' datatype='number' filtertype='number'>{t('MISSING')}</Table.HeaderCell>
                            <Table.HeaderCell field='requested_count' datatype='number' filtertype='number'>{t('REQUESTED')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_requirements.map(item =>
                            <Table.Row key={item.locationrequirement_id} >
                                <Table.Cell> {item.requirement_name}</Table.Cell>
                                <Table.Cell className={(item.individual_count > 0 && 'text--anchor')}>
                                    <Button className='tertiary' onClick={(e) => onClick_requirement(e, item.locationrequirement_id)} aria-disabled={item.individual_count === 0 && "true"}>{item.individual_count}</Button>
                                </Table.Cell>
                                <Table.Cell className={(item.valid_count > 0 && 'text--anchor')}>
                                    <Button className='tertiary' onClick={(e) => onClick_requirement(e, item.locationrequirement_id)} aria-disabled={item.valid_count === 0 && "true"}>{item.valid_count}</Button>
                                </Table.Cell>
                                <Table.Cell className={(item.invalid_count > 0 && 'text--anchor')}>
                                    <Button className='tertiary' onClick={(e) => onClick_requirement(e, item.locationrequirement_id)} aria-disabled={item.invalid_count === 0 && "true"}>{item.invalid_count}</Button>
                                </Table.Cell>
                                <Table.Cell className={(item.comingdue_count > 0 && 'text--anchor')}>
                                    <Button className='tertiary' onClick={(e) => onClick_requirement(e, item.locationrequirement_id)} aria-disabled={item.comingdue_count === 0 && "true"}>{item.comingdue_count}</Button>
                                </Table.Cell>
                                <Table.Cell className={(item.pending_count > 0 && 'text--anchor')}>
                                    <Button className='tertiary' onClick={(e) => onClick_requirement(e, item.locationrequirement_id)} aria-disabled={item.pending_count === 0 && "true"}>{item.pending_count}</Button>
                                </Table.Cell>
                                <Table.Cell className={(item.missing_count > 0 && 'text--anchor')}>
                                    <Button className='tertiary' onClick={(e) => onClick_requirement(e, item.locationrequirement_id)} aria-disabled={item.missing_count === 0 && "true"}>{item.missing_count}</Button>
                                </Table.Cell>
                                <Table.Cell className={'cell__icon--right' + (item.requested_count > 0 ? ' text--anchor' : '')}>
                                        <Button className='tertiary' onClick={(e) => onClick_requirement(e, item.locationrequirement_id)} aria-disabled={item.requested_count === 0 && "true"}>
                                            {item.requested_count}
                                        </Button>
                                        {var_canarchive &&
                                            <Button className='tertiary' onClick={() => onDelete_requirement(item.locationrequirement_id)}>
                                                <Icon name='delete' className='color--primary-500' alt={t('Remove requirement')}/>
                                            </Button>
                                        }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

                <div className='card__footer--mobilebtns'>
                    {auth.has_access(assignment, 'ORG-REQ', 'insert') &&
                        <Button id='btn_addrequirement' className='primary' onClick={onClick_addrequirement}>{t('Add requirement')}</Button>
                    }
                    {auth.has_access(assignment, 'ORG-REQ', 'assign') &&
                        <Button id='btn_assignrequirement' className='secondary' onClick={onClick_assignrequirement}>{t('Assign requirement')}</Button>
                    }
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>


            <MDL_ADD_REQUIREMENT display={var_addrequirement_open} onClose={() => set_addrequirement_open(false)} assignment={assignment} onChange={onChange_addrequirement} />
            <MDL_ASSIGN_REQUIREMENT display={var_assignrequirement_open} onClose={() => set_assignrequirement_open(false)} assignment={assignment} onChange={onChange_assignrequirement} />
            <MDL_COUNT_DETAILS display={var_countdetails_open} onClose={() => set_countdetails_open(false)} assignment={assignment}
                requirement_name={var_countdetails_requirement} locationrequirement_id={var_countdetails_id} status={var_countdetails_status} onChange={onChange_countdetails} />
            <CMP_CONFIRMATION display={var_deletelocationrequirement_open} title={t('Remove requirement')} message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>{t("Removing this requirement may impact an individual's ability to work on this assignment and your compliance reports.")}</div>
                        <div>{t('This action can not be undone.')}</div>
                    </div>
                } positive_option={t('Remove')} negative_option={t('Cancel')} onConfirm={onConfirm_delete} onCancel={() => set_deletelocationrequirement_open(false)} />
        </>
    )

}